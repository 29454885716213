<template>
  <div>
    <el-form ref="tableForm" :model="tableForm" :rules="tableForm.formRules" :show-message="false">
      <div class="wg-box">
        <div class="header">
          <div class="multiple-fill-text">是否分次填报</div>
          <div class="multiple-fill-select">
            <el-form-item prop="afterFertilizerFillInMode" :rules="tableForm.formRules.afterFertilizerFillInMode" style="margin-bottom: 0" class="wg-form-item">
              <el-select
                size="small"
                v-model="tableForm.afterFertilizerFillInMode"
                :disabled="fullPlantCropModel.plantCrop.submitFlag"
                clearable
                @clear="handleFillInModeClear"
                @change="handleFillInModeChange"
              >
                <el-option v-for="option in fillInModeOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="button-list" v-if="!fullPlantCropModel.plantCrop.submitFlag" style="padding: 6px 0 0 10px">
            <el-button size="small" class="wg-button green" icon="el-icon-plus" @click="handleAdd" v-if="'MANY_TIMES' === tableForm.afterFertilizerFillInMode">增加追肥次数</el-button>
            <el-button
              size="small"
              class="wg-button red"
              icon="el-icon-delete"
              v-if="null !== tableForm.afterFertilizerFillInMode && 'NONE' !== tableForm.afterFertilizerFillInMode"
              @click="handleDelete"
            >
              删除
            </el-button>
          </div>
          <div class="wg-clear-fix"></div>
        </div>
        <div class="body">
          <div class="wg-table-box">
            <el-table ref="table" :data="tableForm.tableData" class="wg-table normal" border :max-height="380" style="width: 100%" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="40" v-if="!fullPlantCropModel.plantCrop.submitFlag && 'MANY_TIMES' === tableForm.afterFertilizerFillInMode" fixed></el-table-column>
              <el-table-column label="序号" prop="index" width="100" fixed></el-table-column>
              <el-table-column prop="fertilizationModeName" :label="'TOTAL' === tableForm.afterFertilizerFillInMode ? '主要施肥方式' : '施肥方式'" width="120" fixed>
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag">
                    <el-form-item :prop="'tableData.' + scope.$index + '.fertilizationMode'" :rules="tableForm.formRules.fertilizationMode">
                      <el-select
                        size="small"
                        v-model="scope.row.fertilizationMode"
                        clearable
                        @clear="handleRowValueClear('fertilizationMode', scope.row)"
                        @change="handleRowValueChange($event, 'fertilizationMode', scope.row)"
                      >
                        <el-option v-for="option in tableForm.filterData.fertilizationModeOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.fertilizationModeName }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="afterFertilizationDate" :label="'TOTAL' === tableForm.afterFertilizerFillInMode ? '首次追肥日期' : '追肥日期'" width="150">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.afterFertilizationDate'" :rules="tableForm.formRules.afterFertilizationDate">
                      <el-date-picker size="small" v-model="scope.row.afterFertilizationDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.afterFertilizationDate }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="afterFertilizationTimes" label="追肥次数" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode && 'TOTAL' === tableForm.afterFertilizerFillInMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.afterFertilizationTimes'" :rules="tableForm.formRules.afterFertilizationTimes">
                      <el-input size="small" v-model.number="scope.row.afterFertilizationTimes"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.afterFertilizationTimes }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="businessOrganicFertilizerDosage" label="商品有机肥用量" width="120">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.businessOrganicFertilizerDosage'" :rules="tableForm.formRules.businessOrganicFertilizerDosage">
                      <el-input size="small" v-model="scope.row.businessOrganicFertilizerDosage"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.businessOrganicFertilizerDosage }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="urea" label="尿素" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.urea'" :rules="tableForm.formRules.urea">
                      <el-input size="small" v-model="scope.row.urea"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.urea }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="ammoniumBicarbonate" label="碳铵" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.ammoniumBicarbonate'" :rules="tableForm.formRules.ammoniumBicarbonate">
                      <el-input size="small" v-model="scope.row.ammoniumBicarbonate"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.ammoniumBicarbonate }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="diammoniumPhosphate" label="磷酸二铵" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.diammoniumPhosphate'" :rules="tableForm.formRules.diammoniumPhosphate">
                      <el-input size="small" v-model="scope.row.diammoniumPhosphate"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.diammoniumPhosphate }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="potassiumChloride" label="氯化钾" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.potassiumChloride'" :rules="tableForm.formRules.potassiumChloride">
                      <el-input size="small" v-model="scope.row.potassiumChloride"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.potassiumChloride }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="potassiumSulphate" label="硫酸钾" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.potassiumSulphate'" :rules="tableForm.formRules.potassiumSulphate">
                      <el-input size="small" v-model="scope.row.potassiumSulphate"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.potassiumSulphate }}</div>
                </template>
              </el-table-column>
              <el-table-column label="复合肥（含配方肥）及养分%">
                <el-table-column prop="compoundFertilizerDosage" label="用量" width="100">
                  <template slot-scope="scope">
                    <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                      <el-form-item :prop="'tableData.' + scope.$index + '.compoundFertilizerDosage'" :rules="tableForm.formRules.compoundFertilizerDosage">
                        <el-input size="small" v-model="scope.row.compoundFertilizerDosage" @input="handleRowValueChange($event, 'compoundFertilizerDosage', scope.row)"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.compoundFertilizerDosage }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="compoundFertilizerN" label="N" width="100">
                  <template slot-scope="scope">
                    <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.compoundFertilizerDosage && '' !== scope.row.compoundFertilizerDosage">
                      <el-form-item :prop="'tableData.' + scope.$index + '.compoundFertilizerN'" :rules="tableForm.formRules.compoundFertilizerN">
                        <el-input size="small" v-model="scope.row.compoundFertilizerN"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.compoundFertilizerN }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="compoundFertilizerP2O5" label="P2O5" width="100">
                  <template slot-scope="scope">
                    <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.compoundFertilizerDosage && '' !== scope.row.compoundFertilizerDosage">
                      <el-form-item :prop="'tableData.' + scope.$index + '.compoundFertilizerP2O5'" :rules="tableForm.formRules.compoundFertilizerP2O5">
                        <el-input size="small" v-model="scope.row.compoundFertilizerP2O5"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.compoundFertilizerP2O5 }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="compoundFertilizerK2O" label="K2O" width="100">
                  <template slot-scope="scope">
                    <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.compoundFertilizerDosage && '' !== scope.row.compoundFertilizerDosage">
                      <el-form-item :prop="'tableData.' + scope.$index + '.compoundFertilizerK2O'" :rules="tableForm.formRules.compoundFertilizerK2O">
                        <el-input size="small" v-model="scope.row.compoundFertilizerK2O"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.compoundFertilizerK2O }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="水溶肥及养分%">
                <el-table-column prop="waterSolubleFertilizerDosage" label="用量" width="100">
                  <template slot-scope="scope">
                    <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                      <el-form-item :prop="'tableData.' + scope.$index + '.waterSolubleFertilizerDosage'" :rules="tableForm.formRules.waterSolubleFertilizerDosage">
                        <el-input size="small" v-model="scope.row.waterSolubleFertilizerDosage" @input="handleRowValueChange($event, 'waterSolubleFertilizerDosage', scope.row)"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.waterSolubleFertilizerDosage }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="waterSolubleFertilizerN" label="N" width="100">
                  <template slot-scope="scope">
                    <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.waterSolubleFertilizerDosage && '' !== scope.row.waterSolubleFertilizerDosage">
                      <el-form-item :prop="'tableData.' + scope.$index + '.waterSolubleFertilizerN'" :rules="tableForm.formRules.waterSolubleFertilizerN">
                        <el-input size="small" v-model="scope.row.waterSolubleFertilizerN"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.waterSolubleFertilizerN }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="waterSolubleFertilizerP2O5" label="P2O5" width="100">
                  <template slot-scope="scope">
                    <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.waterSolubleFertilizerDosage && '' !== scope.row.waterSolubleFertilizerDosage">
                      <el-form-item :prop="'tableData.' + scope.$index + '.waterSolubleFertilizerP2O5'" :rules="tableForm.formRules.waterSolubleFertilizerP2O5">
                        <el-input size="small" v-model="scope.row.waterSolubleFertilizerP2O5"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.waterSolubleFertilizerP2O5 }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="waterSolubleFertilizerK2O" label="K2O" width="100">
                  <template slot-scope="scope">
                    <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.waterSolubleFertilizerDosage && '' !== scope.row.waterSolubleFertilizerDosage">
                      <el-form-item :prop="'tableData.' + scope.$index + '.waterSolubleFertilizerK2O'" :rules="tableForm.formRules.waterSolubleFertilizerK2O">
                        <el-input size="small" v-model="scope.row.waterSolubleFertilizerK2O"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.waterSolubleFertilizerK2O }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="其他肥料1">
                <el-table-column prop="otherFertilizer1Name" label="名称" width="120">
                  <template slot-scope="scope">
                    <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                      <el-form-item :prop="'tableData.' + scope.$index + '.otherFertilizer1Id'" :rules="tableForm.formRules.otherFertilizer1Id">
                        <el-select size="small" v-model="scope.row.otherFertilizer1Id" @change="handleRowValueChange($event, 'otherFertilizer1Id', scope.row)">
                          <el-option v-for="option in tableForm.filterData.otherFertilizerOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.otherFertilizer1Name }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="otherFertilizer1Dosage" label="用量" width="120">
                  <template slot-scope="scope">
                    <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.otherFertilizer1Id">
                      <el-form-item :prop="'tableData.' + scope.$index + '.otherFertilizer1Dosage'" :rules="tableForm.formRules.otherFertilizer1Dosage">
                        <el-input size="small" v-model="scope.row.otherFertilizer1Dosage"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.otherFertilizer1Dosage }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="其他肥料2">
                <el-table-column prop="otherFertilizer2Name" label="名称" width="120">
                  <template slot-scope="scope">
                    <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                      <el-form-item :prop="'tableData.' + scope.$index + '.otherFertilizer2Id'" :rules="tableForm.formRules.otherFertilizer2Id">
                        <el-select size="small" v-model="scope.row.otherFertilizer2Id" @change="handleRowValueChange($event, 'otherFertilizer2Id', scope.row)">
                          <el-option v-for="option in tableForm.filterData.otherFertilizerOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.otherFertilizer2Name }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="otherFertilizer2Dosage" label="用量" width="120">
                  <template slot-scope="scope">
                    <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.otherFertilizer2Id">
                      <el-form-item :prop="'tableData.' + scope.$index + '.otherFertilizer2Dosage'" :rules="tableForm.formRules.otherFertilizer2Dosage">
                        <el-input size="small" v-model="scope.row.otherFertilizer2Dosage"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.otherFertilizer2Dosage }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import ChemicalFertilizerApi from '@/api/chemical-fertilizer-api'
import AfterFertilizerApi from '@/api/after-fertilizer-api'

export default {
  name: 'AfterFertilizer',
  props: {
    fullPlantCropModel: {type: Object, default: null}
  },
  data() {
    const validateFertilizationMode = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let fertilizationMode = this.tableForm.tableData[rowIndex].fertilizationMode
      if (null === fertilizationMode) {
        callback()
      } else {
        let row = this.tableForm.tableData[rowIndex]
        if (
          this.$utils.isEmpty(row.businessOrganicFertilizerDosage) &&
          this.$utils.isEmpty(row.urea) &&
          this.$utils.isEmpty(row.ammoniumBicarbonate) &&
          this.$utils.isEmpty(row.diammoniumPhosphate) &&
          this.$utils.isEmpty(row.potassiumChloride) &&
          this.$utils.isEmpty(row.potassiumSulphate) &&
          this.$utils.isEmpty(row.compoundFertilizerDosage) &&
          this.$utils.isEmpty(row.waterSolubleFertilizerDosage) &&
          null === row.otherFertilizer1Id &&
          null === row.otherFertilizer2Id
        ) {
          callback(new Error())
        } else {
          callback()
        }
      }
    }

    const validateCompoundFertilizerDosage = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let compoundFertilizerN = this.tableForm.tableData[rowIndex].compoundFertilizerN
      let compoundFertilizerP2O5 = this.tableForm.tableData[rowIndex].compoundFertilizerP2O5
      let compoundFertilizerK2O = this.tableForm.tableData[rowIndex].compoundFertilizerK2O
      if (this.$utils.isNotEmpty(compoundFertilizerN) && this.$utils.isNotEmpty(compoundFertilizerP2O5) && this.$utils.isNotEmpty(compoundFertilizerK2O)) {
        let total = Number(compoundFertilizerN) + Number(compoundFertilizerP2O5) + Number(compoundFertilizerK2O)
        if (0 === total) {
          callback(new Error())
        } else if (total > 100) {
          callback(new Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    const validateWaterSolubleFertilizerDosage = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let waterSolubleFertilizerN = this.tableForm.tableData[rowIndex].waterSolubleFertilizerN
      let waterSolubleFertilizerP2O5 = this.tableForm.tableData[rowIndex].waterSolubleFertilizerP2O5
      let waterSolubleFertilizerK2O = this.tableForm.tableData[rowIndex].waterSolubleFertilizerK2O
      if (this.$utils.isNotEmpty(waterSolubleFertilizerN) && this.$utils.isNotEmpty(waterSolubleFertilizerP2O5) && this.$utils.isNotEmpty(waterSolubleFertilizerK2O)) {
        let total = Number(waterSolubleFertilizerN) + Number(waterSolubleFertilizerP2O5) + Number(waterSolubleFertilizerK2O)
        if (0 === total) {
          callback(new Error())
        } else if (total > 100) {
          callback(new Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    const validateNumber = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^([0-9]\d*(\.\d*)?)$|^0\.\d*[1-9]\d*$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error())
        }
      } else {
        callback()
      }
    }

    return {
      fillInModeOptionList: [],
      tableForm: {
        afterFertilizerFillInMode: null,
        filterData: {
          otherFertilizerOptionList: [],
          fertilizationModeOptionList: []
        },
        formRules: {
          afterFertilizerFillInMode: [{required: true, trigger: 'change'}],
          afterFertilizationTimes: [{required: true, trigger: 'blur'}],
          afterFertilizationDate: [],
          businessOrganicFertilizerDosage: [
            {trigger: 'blur', validator: validateFertilizationMode},
            {trigger: 'blur', validator: validateNumber}
          ],
          urea: [
            {trigger: 'blur', validator: validateFertilizationMode},
            {trigger: 'blur', validator: validateNumber}
          ],
          ammoniumBicarbonate: [
            {trigger: 'blur', validator: validateFertilizationMode},
            {trigger: 'blur', validator: validateNumber}
          ],
          diammoniumPhosphate: [
            {trigger: 'blur', validator: validateFertilizationMode},
            {trigger: 'blur', validator: validateNumber}
          ],
          potassiumChloride: [
            {trigger: 'blur', validator: validateFertilizationMode},
            {trigger: 'blur', validator: validateNumber}
          ],
          potassiumSulphate: [
            {trigger: 'blur', validator: validateFertilizationMode},
            {trigger: 'blur', validator: validateNumber}
          ],
          compoundFertilizerDosage: [
            {trigger: 'blur', validator: validateFertilizationMode},
            {trigger: 'blur', validator: validateNumber}
          ],
          compoundFertilizerN: [
            {required: true, trigger: 'blur'},
            {trigger: 'blur', validator: validateNumber},
            {trigger: 'blur', validator: validateCompoundFertilizerDosage}
          ],
          compoundFertilizerP2O5: [
            {required: true, trigger: 'blur'},
            {trigger: 'blur', validator: validateNumber},
            {trigger: 'blur', validator: validateCompoundFertilizerDosage}
          ],
          compoundFertilizerK2O: [
            {required: true, trigger: 'blur'},
            {trigger: 'blur', validator: validateNumber},
            {trigger: 'blur', validator: validateCompoundFertilizerDosage}
          ],
          waterSolubleFertilizerDosage: [
            {trigger: 'blur', validator: validateFertilizationMode},
            {trigger: 'blur', validator: validateNumber}
          ],
          waterSolubleFertilizerN: [
            {required: true, trigger: 'blur'},
            {trigger: 'blur', validator: validateNumber},
            {trigger: 'blur', validator: validateWaterSolubleFertilizerDosage}
          ],
          waterSolubleFertilizerP2O5: [
            {required: true, trigger: 'blur'},
            {trigger: 'blur', validator: validateNumber},
            {trigger: 'blur', validator: validateWaterSolubleFertilizerDosage}
          ],
          waterSolubleFertilizerK2O: [
            {required: true, trigger: 'blur'},
            {trigger: 'blur', validator: validateNumber},
            {trigger: 'blur', validator: validateWaterSolubleFertilizerDosage}
          ],
          otherFertilizer1Id: [{trigger: 'change', validator: validateFertilizationMode}],
          otherFertilizer1Dosage: [
            {required: true, trigger: 'blur'},
            {trigger: 'blur', validator: validateNumber}
          ],
          otherFertilizer2Id: [{trigger: 'change', validator: validateFertilizationMode}],
          otherFertilizer2Dosage: [
            {required: true, trigger: 'blur'},
            {trigger: 'blur', validator: validateNumber}
          ],
          fertilizationMode: []
        },
        tableData: [],
        selectedRows: []
      }
    }
  },
  async created() {
    this.tableForm.afterFertilizerFillInMode = this.fullPlantCropModel.plantCrop.afterFertilizerFillInMode
    // fillInModeOptionList
    this.fillInModeOptionList.push({text: '分次填报', value: 'MANY_TIMES'})
    this.fillInModeOptionList.push({text: '合计填报', value: 'TOTAL'})
    this.fillInModeOptionList.push({text: '无追肥', value: 'NONE'})
    // otherFertilizerOptionList
    ChemicalFertilizerApi.getStubList().then(res => {
      res.data.forEach(el => {
        this.tableForm.filterData.otherFertilizerOptionList.push({text: el.name, value: el.id})
      })
    })
    // fertilizationModeOptionList
    this.tableForm.filterData.fertilizationModeOptionList.push({text: '撒施', value: 'MODE1'})
    this.tableForm.filterData.fertilizationModeOptionList.push({text: '冲施', value: 'MODE2'})
    this.tableForm.filterData.fertilizationModeOptionList.push({text: '条施', value: 'MODE3'})
    this.tableForm.filterData.fertilizationModeOptionList.push({text: '穴施', value: 'MODE4'})
    this.tableForm.filterData.fertilizationModeOptionList.push({text: '水肥一体化', value: 'MODE5'})
    this.tableForm.filterData.fertilizationModeOptionList.push({text: '机械深施', value: 'MODE7'})
    this.tableForm.filterData.fertilizationModeOptionList.push({text: '无人机施肥', value: 'MODE8'})
    this.tableForm.filterData.fertilizationModeOptionList.push({text: '其他', value: 'MODE6'})
    // init
    this.loadData()
  },
  methods: {
    handleAdd() {
      if (5 === this.tableForm.tableData.length && 'MANY_TIMES' === this.tableForm.afterFertilizerFillInMode) {
        this.$message({showClose: true, message: '最多增加五次追肥！', type: 'warning'})
        return
      }

      if (1 === this.tableForm.tableData.length && 'TOTAL' === this.tableForm.afterFertilizerFillInMode) {
        this.$message({showClose: true, message: '合计追肥填报仅限一次！', type: 'warning'})
        return
      }

      this.addEmptyData()
    },
    handleFillInModeClear() {
      this.tableForm.afterFertilizerFillInMode = null
      this.fullPlantCropModel.plantCrop.afterFertilizerFillInMode = null
      this.tableForm.tableData = []
    },
    handleFillInModeChange(selectedValue) {
      this.fullPlantCropModel.plantCrop.afterFertilizerFillInMode = selectedValue
      this.tableForm.tableData = []
      if ('MANY_TIMES' === selectedValue || 'TOTAL' === selectedValue) {
        this.tableForm.tableData.push({
          index: this.tableForm.tableData.length + 1,
          id: null,
          afterFertilizationTimes: null,
          afterFertilizationDate: null,
          businessOrganicFertilizerDosage: null,
          urea: null,
          ammoniumBicarbonate: null,
          diammoniumPhosphate: null,
          potassiumChloride: null,
          potassiumSulphate: null,
          compoundFertilizerDosage: null,
          compoundFertilizerN: null,
          compoundFertilizerP2O5: null,
          compoundFertilizerK2O: null,
          waterSolubleFertilizerDosage: null,
          waterSolubleFertilizerN: null,
          waterSolubleFertilizerP2O5: null,
          waterSolubleFertilizerK2O: null,
          otherFertilizer1Id: null,
          otherFertilizer1Name: null,
          otherFertilizer1Dosage: null,
          otherFertilizer2Id: null,
          otherFertilizer2Name: null,
          otherFertilizer2Dosage: null,
          fertilizationMode: null,
          fertilizationModeName: null
        })
      }
    },
    handleSelectionChange(rows) {
      this.tableForm.selectedRows = rows
    },
    handleDelete() {
      if ('MANY_TIMES' === this.tableForm.afterFertilizerFillInMode) {
        if (0 === this.tableForm.selectedRows.length) {
          this.$message({showClose: true, message: '请选择记录！', type: 'warning'})
          return
        }
      } else if ('TOTAL' === this.tableForm.afterFertilizerFillInMode) {
        if (1 !== this.tableForm.tableData.length) {
          this.$message({showClose: true, message: '无任何记录！', type: 'warning'})
          return
        }
      }

      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          if ('MANY_TIMES' === this.tableForm.afterFertilizerFillInMode) {
            let afterFertilizerModelList = []
            let rowList = []
            let index = 0
            for (let i = 0; i < this.tableForm.tableData.length; i++) {
              let row = this.tableForm.selectedRows.find(item => item.index == this.tableForm.tableData[i].index)
              if (undefined === row) {
                this.tableForm.tableData[i].index = ++index
                rowList.push(this.tableForm.tableData[i])
                if (null !== this.tableForm.tableData[i].id) {
                  let afterFertilizerModel = this.fullPlantCropModel.afterFertilizerList.find(item => item.id === this.tableForm.tableData[i].id)
                  afterFertilizerModelList.push(afterFertilizerModel)
                }
              } else {
                if (null !== row.id) {
                  await this.delete(row)
                }
              }
            }

            this.tableForm.tableData = rowList
            this.fullPlantCropModel.afterFertilizerList = afterFertilizerModelList
          }

          if ('TOTAL' === this.tableForm.afterFertilizerFillInMode && 1 === this.tableForm.tableData.length) {
            await this.delete(this.tableForm.tableData[0])
            await this.$emit('delete-success')
          }
        })
        .catch(() => {
          this.$message({showClose: true, message: '已取消删除！', type: 'info'})
        })
    },
    handleRowValueClear(field, row) {
      if ('fertilizationMode' === field) {
        row.afterFertilizationTimes = null
        row.afterFertilizationDate = null
        row.businessOrganicFertilizerDosage = null
        row.urea = null
        row.ammoniumBicarbonate = null
        row.diammoniumPhosphate = null
        row.potassiumChloride = null
        row.potassiumSulphate = null
        row.compoundFertilizerDosage = null
        row.compoundFertilizerN = null
        row.compoundFertilizerP2O5 = null
        row.compoundFertilizerK2O = null
        row.waterSolubleFertilizerDosage = null
        row.waterSolubleFertilizerN = null
        row.waterSolubleFertilizerP2O5 = null
        row.waterSolubleFertilizerK2O = null
        row.otherFertilizer1Id = null
        row.otherFertilizer1Dosage = null
        row.otherFertilizer2Id = null
        row.otherFertilizer2Dosage = null
      }
      if ('otherFertilizer1Id' === field) {
        row.otherFertilizer1Id = null
        row.otherFertilizer1Dosage = null
      }
      if ('otherFertilizer2Id' === field) {
        row.otherFertilizer2Id = null
        row.otherFertilizer2Dosage = null
      }
    },
    handleRowValueChange(value, field, row) {
      if ('businessOrganicFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.businessOrganicFertilizerDosage = null
        row.businessOrganicFertilizerPrice = null
      }
      if ('compoundFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.compoundFertilizerDosage = null
        row.compoundFertilizerN = null
        row.compoundFertilizerP2O5 = null
        row.compoundFertilizerK2O = null
      }
      if ('waterSolubleFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.waterSolubleFertilizerDosage = null
        row.waterSolubleFertilizerN = null
        row.waterSolubleFertilizerP2O5 = null
        row.waterSolubleFertilizerK2O = null
      }
    },
    async delete(row) {
      await AfterFertilizerApi.deleteById(row.id)
        .then(() => {
          this.$message({showClose: true, message: '记录【' + row.index + '】删除成功！', type: 'success'})
        })
        .catch(err => {
          this.$message({showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error'})
        })
    },
    loadData() {
      let index = 0
      let afterFertilizerModelList = this.fullPlantCropModel.afterFertilizerList
      this.tableForm.tableData = []
      afterFertilizerModelList.forEach(el => {
        this.tableForm.tableData.push({
          id: el.id,
          index: ++index,
          afterFertilizationTimes: el.afterFertilizationTimes,
          afterFertilizationDate: this.$utils.isEmpty(el.afterFertilizationDate) ? null : this.$moment(el.afterFertilizationDate).format('YYYY-MM-DD'),
          businessOrganicFertilizerDosage: el.businessOrganicFertilizerDosage,
          businessOrganicFertilizerPrice: el.businessOrganicFertilizerPrice,
          urea: el.urea,
          ammoniumBicarbonate: el.ammoniumBicarbonate,
          diammoniumPhosphate: el.diammoniumPhosphate,
          potassiumChloride: el.potassiumChloride,
          potassiumSulphate: el.potassiumSulphate,
          compoundFertilizerDosage: el.compoundFertilizerDosage,
          compoundFertilizerN: el.compoundFertilizerN,
          compoundFertilizerP2O5: el.compoundFertilizerP2O5,
          compoundFertilizerK2O: el.compoundFertilizerK2O,
          waterSolubleFertilizerDosage: el.waterSolubleFertilizerDosage,
          waterSolubleFertilizerN: el.waterSolubleFertilizerN,
          waterSolubleFertilizerP2O5: el.waterSolubleFertilizerP2O5,
          waterSolubleFertilizerK2O: el.waterSolubleFertilizerK2O,
          otherFertilizer1Id: el.otherFertilizer1Id,
          otherFertilizer1Name: el.otherFertilizer1Name,
          otherFertilizer1Dosage: el.otherFertilizer1Dosage,
          otherFertilizer2Id: el.otherFertilizer2Id,
          otherFertilizer2Name: el.otherFertilizer2Name,
          otherFertilizer2Dosage: el.otherFertilizer2Dosage,
          fertilizationMode: el.fertilizationMode,
          fertilizationModeName: el.fertilizationModeName
        })
      })

      if (0 === this.tableForm.tableData.length && null !== this.tableForm.afterFertilizerFillInMode && 'NONE' !== this.tableForm.afterFertilizerFillInMode) {
        this.addEmptyData()
      }
    },
    addEmptyData() {
      let index = this.tableForm.tableData.length
      this.tableForm.tableData.push({
        index: index + 1,
        id: null,
        afterFertilizationTimes: null,
        afterFertilizationDate: null,
        businessOrganicFertilizerDosage: null,
        urea: null,
        ammoniumBicarbonate: null,
        diammoniumPhosphate: null,
        potassiumChloride: null,
        potassiumSulphate: null,
        compoundFertilizerDosage: null,
        compoundFertilizerN: null,
        compoundFertilizerP2O5: null,
        compoundFertilizerK2O: null,
        waterSolubleFertilizerDosage: null,
        waterSolubleFertilizerN: null,
        waterSolubleFertilizerP2O5: null,
        waterSolubleFertilizerK2O: null,
        otherFertilizer1Id: null,
        otherFertilizer1Name: null,
        otherFertilizer1Dosage: null,
        otherFertilizer2Id: null,
        otherFertilizer2Name: null,
        otherFertilizer2Dosage: null,
        fertilizationMode: null,
        fertilizationModeName: null
      })
    },
    validateForm() {
      let result = true
      this.$refs.tableForm.validate(valid => {
        result = valid
      })

      return result
    },
    getAfterFertilizerModels() {
      let models = []

      for (let i = 0; i < this.tableForm.tableData.length; i++) {
        let row = this.tableForm.tableData[i]
        if (null === row.fertilizationMode) {
          continue
        }

        let model = {
          id: row.id,
          plantCropId: this.fullPlantCropModel.plantCrop.id,
          afterFertilizationTimes: this.$utils.isEmpty(row.afterFertilizationTimes) ? null : row.afterFertilizationTimes,
          afterFertilizationDate: this.$utils.isEmpty(row.afterFertilizationDate) ? null : row.afterFertilizationDate + ' 00:00:00',
          businessOrganicFertilizerDosage: this.$utils.isEmpty(row.businessOrganicFertilizerDosage) ? null : row.businessOrganicFertilizerDosage,
          urea: this.$utils.isEmpty(row.urea) ? null : row.urea,
          ammoniumBicarbonate: this.$utils.isEmpty(row.ammoniumBicarbonate) ? null : row.ammoniumBicarbonate,
          diammoniumPhosphate: this.$utils.isEmpty(row.diammoniumPhosphate) ? null : row.diammoniumPhosphate,
          potassiumChloride: this.$utils.isEmpty(row.potassiumChloride) ? null : row.potassiumChloride,
          potassiumSulphate: this.$utils.isEmpty(row.potassiumSulphate) ? null : row.potassiumSulphate,
          compoundFertilizerDosage: this.$utils.isEmpty(row.compoundFertilizerDosage) ? null : row.compoundFertilizerDosage,
          compoundFertilizerN: this.$utils.isEmpty(row.compoundFertilizerN) ? null : row.compoundFertilizerN,
          compoundFertilizerP2O5: this.$utils.isEmpty(row.compoundFertilizerP2O5) ? null : row.compoundFertilizerP2O5,
          compoundFertilizerK2O: this.$utils.isEmpty(row.compoundFertilizerK2O) ? null : row.compoundFertilizerK2O,
          waterSolubleFertilizerDosage: this.$utils.isEmpty(row.waterSolubleFertilizerDosage) ? null : row.waterSolubleFertilizerDosage,
          waterSolubleFertilizerN: this.$utils.isEmpty(row.waterSolubleFertilizerN) ? null : row.waterSolubleFertilizerN,
          waterSolubleFertilizerP2O5: this.$utils.isEmpty(row.waterSolubleFertilizerP2O5) ? null : row.waterSolubleFertilizerP2O5,
          waterSolubleFertilizerK2O: this.$utils.isEmpty(row.waterSolubleFertilizerK2O) ? null : row.waterSolubleFertilizerK2O,
          otherFertilizer1Id: row.otherFertilizer1Id,
          otherFertilizer1Dosage: this.$utils.isEmpty(row.otherFertilizer1Dosage) ? null : row.otherFertilizer1Dosage,
          otherFertilizer2Id: row.otherFertilizer2Id,
          otherFertilizer2Dosage: this.$utils.isEmpty(row.otherFertilizer2Dosage) ? null : row.otherFertilizer2Dosage,
          fertilizationMode: row.fertilizationMode
        }
        models.push(model)
      }

      return models
    }
  }
}
</script>
<style scoped>
.multiple-fill-text {
  float: left;
  margin: 5px 0 0 10px;
}

.multiple-fill-select {
  float: left;
  margin: 1px 0 0 10px;
  width: 120px;
}
</style>
