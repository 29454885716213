import httpRequest from '@/utils/http-request'

export default {
  deleteById(id) {
    return httpRequest.delete('/archived-plant-crops/' + id)
  },
  getOwnList(year, monitoringPlotId) {
    let config = {
      params: {
        year: year,
        monitoringPlotId: monitoringPlotId
      }
    }

    return httpRequest('/archived-own-plant-crops', config)
  }
}
