<template>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
    <div class="wg-box">
      <div class="body">
        <el-alert title="提示：" type="warning" description="编辑地块【所属用户】选择后不可修改！【管理人】是指管理该地块的人员并非地块拥有者！" :closable="false" show-icon></el-alert>
        <el-form ref="form" :model="form" :rules="form.rules" label-width="120px">
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="所属省份">
                <el-input v-model="form.provinceName" class="readonly" disabled></el-input>
              </el-form-item>
              <el-form-item label="所属地市">
                <el-input v-model="form.cityName" class="readonly" disabled></el-input>
              </el-form-item>
              <el-form-item label="所属区县">
                <el-input v-model="form.districtName" class="readonly" disabled></el-input>
              </el-form-item>
              <el-form-item label="详细位置" prop="detailedAddress">
                <el-input v-model="form.detailedAddress"></el-input>
              </el-form-item>
              <el-form-item label="地块代码">
                <el-input v-model="form.code" class="readonly" disabled></el-input>
              </el-form-item>
              <el-form-item label="地块名称" prop="name">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item label="所属用户" prop="farmerFullName">
                <el-input v-model="form.farmerFullName" disabled></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="farmerPhoneNumber">
                <el-input v-model="form.farmerPhoneNumber" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="经度（十进制）" prop="longitude">
                <el-input v-model="form.longitude" placeholder="格式：十进制"></el-input>
              </el-form-item>
              <el-form-item label="纬度（十进制）" prop="latitude">
                <el-input v-model="form.latitude" placeholder="格式：十进制"></el-input>
              </el-form-item>
              <el-form-item label="地块面积（亩）" prop="plotArea">
                <el-input v-model="form.plotArea"></el-input>
              </el-form-item>
              <el-form-item label="建立时间" prop="buildTime">
                <el-date-picker style="width: 100%" v-model="form.buildTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" :picker-options="pickerOptionsRule"></el-date-picker>
              </el-form-item>
              <el-form-item label="管理人" prop="managerFullName" :style="styles.other">
                <el-input v-model="form.managerFullName"></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="managerPhoneNumber" :style="styles.other">
                <el-input v-model="form.managerPhoneNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="wg-clear-fix"></div>
      </div>
      <div slot="footer" class="footer">
        <div class="button-list right">
          <el-button class="wg-button blank" @click="dialogVisible = false">取 消</el-button>
          <el-button class="wg-button" @click="handleSave">保 存</el-button>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import MonitoringPlotApi from '@/api/monitoring-plot-api'

export default {
  name: 'PlotModifyDialog',
  data() {
    const validatePhoneNumber = (rule, value, callback) => {
      if (!this.$utils.isEmpty(value)) {
        const regOfPhoneNumber = /^[1-9][0-9]{10}$/
        const regOfTelNumber = /^(0\d{2,3})-?(\d{7,8})$/
        if (regOfPhoneNumber.test(value)) {
          callback()
        } else if (regOfTelNumber.test(value)) {
          callback()
        } else {
          callback('电话号码输入格式错误！')
        }
      } else {
        callback()
      }
    }

    const validateLongitude = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        // eslint-disable-next-line no-useless-escape
        const reg = /^[\-\+]?(0(\.\d{1,8})?|([1-9](\d)?)(\.\d{1,8})?|1[0-7]\d{1}(\.\d{1,8})?|180(([.][0]{1,8})?))$/
        if (reg.test(value)) {
          callback()
        } else {
          callback('经度格式输入错误！')
        }
      } else {
        callback()
      }
    }

    const validateLatitude = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        // eslint-disable-next-line no-useless-escape
        const reg = /^[\-\+]?((0|([1-8]\d?))(\.\d{1,10})?|90(\.0{1,10})?)$/
        if (reg.test(value)) {
          callback()
        } else {
          callback('经度格式输入错误！')
        }
      } else {
        callback()
      }
    }

    const validatePlantArea = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^([1-9]\d*(\.\d*)?)$|^0\.\d*[1-9]\d*$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入数字！'))
        }
      } else {
        callback()
      }
    }
    return {
      dialogTitle: '编辑地块',
      dialogVisible: false,
      styles: {
        other: ''
      },
      params: {
        id: null,
        rowIndex: null
      },
      pickerOptionsRule: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      form: {
        rules: {
          detailedAddress: [{ required: true, message: '请填写详细地址！', trigger: 'blur' }],
          name: [{ required: true, message: '请填写监测地块名称！', trigger: 'blur' }],
          farmerFullName: [],
          farmerPhoneNumber: [{ trigger: 'blur', validator: validatePhoneNumber }],
          longitude: [{ trigger: 'blur', validator: validateLongitude }],
          latitude: [{ trigger: 'blur', validator: validateLatitude }],
          plotArea: [
            { required: true, message: '请填写地块面积！', trigger: 'blur' },
            { trigger: 'blur', validator: validatePlantArea }
          ],
          buildTime: [{ required: true, message: '请选择建立时间！', trigger: 'blur' }]
        },
        provinceName: null,
        cityName: null,
        districtName: null,
        detailedAddress: null,
        code: null,
        name: null,
        farmerFullName: null,
        farmerPhoneNumber: null,
        managerFullName: null,
        managerPhoneNumber: null,
        longitude: null,
        latitude: null,
        plotArea: null,
        buildTime: null
      }
    }
  },
  created() {
    let userLevelId = sessionStorage.getItem('levelId')
    if ('Professional' === userLevelId) {
      this.styles.other = 'display:none'
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return

        let updateModel = {
          id: this.params.id,
          detailedAddress: this.form.detailedAddress,
          name: this.form.name,
          buildTime: this.form.buildTime + ' 00:00:00',
          longitude: this.$utils.isEmpty(this.form.longitude) ? null : this.form.longitude,
          latitude: this.$utils.isEmpty(this.form.latitude) ? null : this.form.latitude,
          plotArea: this.form.plotArea,
          managerFullName: this.form.managerFullName,
          managerPhoneNumber: this.form.managerPhoneNumber
        }
        MonitoringPlotApi.update(updateModel)
          .then(() => {
            this.$message({ showClose: true, message: '记录【' + this.params.rowIndex + '】保存成功！', type: 'success' })
            this.$emit('update-success')
            this.dialogVisible = false
          })
          .catch(function (err) {
            this.$message({ type: 'error', message: '【' + err.data.code + '】' + err.data.message })
          })
      })
    },
    async open(id, rowIndex) {
      this.params.id = id
      this.params.rowIndex = rowIndex
      await MonitoringPlotApi.getById(id).then(res => {
        this.form.provinceName = res.data.provinceName
        this.form.cityName = res.data.cityName
        this.form.districtName = res.data.districtName
        this.form.detailedAddress = res.data.detailedAddress
        this.form.code = res.data.code
        this.form.name = res.data.name
        this.form.buildTime = this.$moment(res.data.buildTime).format('YYYY-MM-DD')
        this.form.longitude = res.data.longitude
        this.form.latitude = res.data.latitude
        this.form.plotArea = res.data.plotArea
        this.form.farmerFullName = res.data.farmerFullName
        this.form.farmerPhoneNumber = res.data.farmerPhoneNumber
        this.form.managerFullName = res.data.managerFullName
        this.form.managerPhoneNumber = res.data.managerPhoneNumber
      })

      this.dialogVisible = true
    }
  }
}
</script>
