import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/crop-growth-periods', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/crop-growth-periods/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/crop-growth-periods', updateModel)
  },
  getById(id) {
    return httpRequest.get('/crop-growth-periods/' + id)
  },
  getListByCropId(cropId) {
    return httpRequest.get('/crops/' + cropId + '/crop-growth-periods')
  }
}
