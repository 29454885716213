<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/" class="bread_crumb">
        <el-breadcrumb-item :to="{ path: '/user' }">用户管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/farmer-integration' }">农户积分</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <div class="wg-box no-header">
      <div class="body">
        <el-form :inline="true" :model="searchForm" size="small" label-width="120px">
          <el-form-item label="关键字：">
            <el-input v-model="searchForm.keyword"></el-input>
          </el-form-item>
          <el-form-item label="积分获得时间：">
            <el-date-picker v-model="searchForm.gainIntegrationTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :default-time="['00:00:00', '00:00:00']"> </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button class="wg-button" @click="handleSearch">搜索</el-button>
            <el-button class="wg-button blank" @click="handleReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="wg-clear-fix"></div>
    </div>
    <div class="wg-box no-header">
      <div class="body">
        <div class="wg-table-box">
          <el-table :data="tableData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)" class="wg-table normal" border>
            <el-table-column label="序号" prop="index" width="80" fixed></el-table-column>
            <el-table-column label="省份" prop="provinceName" :filters="filterData.provinceOptionList" :filter-method="handleProvinceFilter"></el-table-column>
            <el-table-column label="地市" prop="cityName" :filters="filterData.cityOptionList" :filter-method="handleCityFilter"></el-table-column>
            <el-table-column label="区县" prop="districtName" :filters="filterData.districtOptionList" :filter-method="handleDistrictFilter"></el-table-column>
            <el-table-column label="详细地址" prop="detailedAddress"></el-table-column>
            <el-table-column label="农户姓名" prop="fullName" sortable :sort-method="(a, b) => sortColumn(a, b, 'fullName')"></el-table-column>
            <el-table-column label="手机号" prop="phoneNumber"></el-table-column>
            <el-table-column label="农户性质" prop="farmerNatureName"></el-table-column>
            <el-table-column label="积分获得时间" prop="gainIntegrationTime" sortable :sort-method="(a, b) => sortColumn(a, b, 'gainIntegrationTime')"></el-table-column>
            <el-table-column label="累计获得积分" prop="integration" sortable></el-table-column>
          </el-table>
        </div>
        <div class="wg-pagination-box">
          <el-pagination background layout="prev, pager, next" :total="recordTotal" :page-size="pageSize" :current-page="pageIndex" @current-change="handlePagingChange"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FarmerIntegrationApi from '@/api/farmer-api'

export default {
  name: 'FarmerIntegration',
  data() {
    return {
      searchForm: {
        regionId: null,
        keyword: null,
        gainIntegrationTime: []
      },
      filterData: {
        provinceOptionList: [],
        cityOptionList: [],
        districtOptionList: []
      },
      tableData: [],
      recordTotal: 0,
      pageSize: 10,
      pageIndex: 1
    }
  },
  created() {
    let userLevelId = sessionStorage.getItem('levelId')
    if ('Province' === userLevelId) this.searchForm.regionId = sessionStorage.getItem('provinceId')
    if ('City' === userLevelId) this.searchForm.regionId = sessionStorage.getItem('cityId')
    if ('District' === userLevelId) this.searchForm.regionId = sessionStorage.getItem('districtId')

    this.bindTableData()
  },
  methods: {
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
    },
    handleSearch() {
      this.bindTableData()
    },
    handleReset() {
      this.searchForm.keyword = null
      this.searchForm.gainIntegrationTime = []
      this.bindTableData()
    },
    handleProvinceFilter(value, row) {
      return value === row.provinceId
    },
    handleCityFilter(value, row) {
      return value === row.cityId
    },
    handleDistrictFilter(value, row) {
      return value === row.districtId
    },
    sortColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1[fieldName], row2[fieldName])
    },
    bindTableData() {
      let startDate = null
      let endDate = null
      if (this.searchForm.gainIntegrationTime.length === 2) {
        startDate.startDate = this.searchForm.gainIntegrationTime[0]
        endDate.endDate = this.searchForm.gainIntegrationTime[1]
      }

      this.filterData.provinceOptionList = []
      this.filterData.cityOptionList = []
      this.filterData.districtOptionList = []
      FarmerIntegrationApi.getList(this.searchForm.regionId, this.searchForm.keyword, startDate, endDate)
        .then(res => {
          let provinceArray = []
          let cityArray = []
          let districtArray = []
          this.filterData.provinceOptionList = []
          this.filterData.cityOptionList = []
          this.filterData.districtOptionList = []
          this.tableData = []
          this.recordTotal = res.data.length
          this.pageIndex = 1
          let index = 0
          res.data.forEach(el => {
            if (undefined === provinceArray[el.provinceId]) provinceArray[el.provinceId] = el.provinceName
            if (undefined === cityArray[el.cityId]) cityArray[el.cityId] = el.cityName
            if (undefined === districtArray[el.districtId]) districtArray[el.districtId] = el.districtName

            this.tableData.push({
              index: ++index,
              id: el.id,
              provinceId: el.provinceId,
              provinceName: el.provinceName,
              cityId: el.cityId,
              cityName: el.cityName,
              districtId: el.districtId,
              districtName: el.districtName,
              detailedAddress: el.detailedAddress,
              fullName: el.fullName,
              phoneNumber: el.phoneNumber,
              farmerNatureName: el.farmerNatureName,
              gainIntegrationTime: el.gainIntegrationTime,
              integration: el.integration
            })
          })

          for (let key in provinceArray) this.filterData.provinceOptionList.push({ text: provinceArray[key], value: key })
          for (let key in cityArray) this.filterData.cityOptionList.push({ text: cityArray[key], value: key })
          for (let key in districtArray) this.filterData.districtOptionList.push({ text: districtArray[key], value: key })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped>
</style>