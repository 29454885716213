<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/user' }">用户管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <div v-loading="dataLoading" element-loading-text="拼命加载数据......" element-loading-spinner="el-icon-loading">
      <div class="wg-box">
        <div class="header" style="padding-top: 0px">
          <el-tabs class="wg-tabs" v-model="tabActivedName" @tab-click="handleTabsClick">
            <el-tab-pane name="all" label="全部"></el-tab-pane>
            <el-tab-pane name="noAudit">
              <span slot="label">
                未审核
                <el-badge :value="noAuditeCount"></el-badge>
              </span>
            </el-tab-pane>
            <el-tab-pane name="audited" label="已审核"></el-tab-pane>
            <el-tab-pane name="disabled" label="已停用"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="body">
          <div style="max-width: 1280px">
            <el-form :inline="true" :model="searchForm" size="small" label-width="100px">
              <el-form-item label="选择省份：" v-if="flags.provinceSelectShow">
                <el-select v-model="searchForm.provinceId" @change="handleProvinceSelect">
                  <el-option v-for="option in searchForm.filterData.provinceOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择地市：" v-if="flags.citySelectShow">
                <el-select v-model="searchForm.cityId" @change="handleCitySelect">
                  <el-option v-for="option in searchForm.filterData.cityOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择区县：" v-if="flags.districtSelectShow">
                <el-select v-model="searchForm.districtId">
                  <el-option v-for="option in searchForm.filterData.districtOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关键字：">
                <el-input v-model="searchForm.keyword"></el-input>
              </el-form-item>
              <el-form-item label="用户级别：" v-if="flags.levelSelectShow">
                <el-select v-model="searchForm.levelId">
                  <el-option v-for="option in searchForm.filterData.levelOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="注册日期：">
                <el-date-picker
                  v-model="searchForm.registrationTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :default-time="['00:00:00', '00:00:00']"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button class="wg-button" @click="handleSearch">搜索</el-button>
                <el-button class="wg-button blank" @click="handleReset">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <!-- filter -->
      <div class="wg-box">
        <div class="header">
          <div class="button-list">
            <el-button size="small" class="wg-button green" v-if="null === searchForm.auditFlag && !searchForm.disableFlag" icon="el-icon-plus" @click="handleAdd">新增</el-button>
            <el-button size="small" class="wg-button orange" v-if="null !== searchForm.auditFlag && !searchForm.auditFlag && !searchForm.disableFlag" icon="el-icon-check" @click="handleAudit">
              审核
            </el-button>
            <el-button size="small" class="wg-button red" v-if="!searchForm.disableFlag" icon="el-icon-video-pause" @click="handleDisableSelected">停用</el-button>
            <el-button size="small" class="wg-button green" v-if="searchForm.disableFlag" icon="el-icon-video-play" @click="handleEnableSelected">恢复</el-button>
            <el-button size="small" class="wg-button red" v-if="searchForm.disableFlag && flags.allowDeleteUser" icon="el-icon-delete" @click="handleDeleteSelected">删除</el-button>
          </div>
          <div class="wg-clear-fix"></div>
        </div>
        <div class="body">
          <div class="wg-table-box">
            <el-table :data="tableData" :max-height="600" row-key="id" border style="width: 100%" class="wg-table" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="50" fixed></el-table-column>
              <el-table-column label="序号" prop="index" width="100" fixed></el-table-column>
              <el-table-column label="真实姓名" prop="fullName" width="120" sortable :sort-method="(a, b) => sortColumn(a, b, 'fullName')" fixed></el-table-column>
              <el-table-column label="用户名称" prop="username" width="120" sortable :sort-method="(a, b) => sortColumn(a, b, 'username')"></el-table-column>
              <el-table-column label="省份" prop="provinceName" width="120" sortable :sort-method="(a, b) => sortColumn(a, b, 'provinceName')"></el-table-column>
              <el-table-column label="地市" prop="cityName" width="120" sortable :sort-method="(a, b) => sortColumn(a, b, 'cityName')"></el-table-column>
              <el-table-column label="区县" prop="districtName" width="120" sortable :sort-method="(a, b) => sortColumn(a, b, 'districtName')"></el-table-column>
              <el-table-column label="详细地址" prop="detailedAddress" width="120" sortable :sort-method="(a, b) => sortColumn(a, b, 'detailedAddress')"></el-table-column>
              <el-table-column label="所在单位" prop="organization" width="120" sortable :sort-method="(a, b) => sortColumn(a, b, 'organization')"></el-table-column>
              <el-table-column label="手机号" prop="phoneNumber" width="120" sortable :sort-method="(a, b) => sortColumn(a, b, 'phoneNumber')"></el-table-column>
              <el-table-column label="办公电话" prop="officePhone" width="120" sortable :sort-method="(a, b) => sortColumn(a, b, 'officePhone')"></el-table-column>
              <el-table-column label="电子邮箱" prop="email" width="200" sortable :sort-method="(a, b) => sortColumn(a, b, 'email')"></el-table-column>
              <el-table-column label="最后登录时间" prop="lastLoginTime" width="160" sortable :sort-method="(a, b) => sortColumn(a, b, 'lastLoginTime')"></el-table-column>
              <el-table-column label="注册时间" prop="registrationTime" width="160" sortable :sort-method="(a, b) => sortColumn(a, b, 'registrationTime')"></el-table-column>
              <el-table-column label="农户性质" prop="farmerNatureName" width="120" sortable :sort-method="(a, b) => sortColumn(a, b, 'farmerNatureName')"></el-table-column>
              <el-table-column label="文化程度" prop="educationalLevelName" width="120" sortable :sort-method="(a, b) => sortColumn(a, b, 'educationalLevelName')"></el-table-column>
              <el-table-column prop="cultivationLandArea" width="170" sortable>
                <template slot="header">
                  经营面积
                  <el-tooltip content="经营面积指农户从事农业生产总的经营面积，包括耕地、园地，主要反映农户生产规模大小。" placement="top">
                    <i class="el-icon-question" style="font-size: 18px; color:#E6A23C"></i>
                  </el-tooltip>
                  <br />（含园地，亩）
                </template>
              </el-table-column>
              <el-table-column label="示范户" prop="modelHouseholdFlag" class-name="cell-tag" width="120" sortable :sort-method="(a, b) => sortColumn(a, b, 'modelHouseholdName')">
                <template slot-scope="scope">
                  <div :style="null === scope.row.modelHouseholdFlag ? 'color:#333' : scope.row.modelHouseholdFlag ? 'color:#039F3A' : 'color:#FF8900'">
                    {{ scope.row.modelHouseholdName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="审核状态" prop="auditFlag" width="120" sortable v-if="null === searchForm.auditFlag">
                <template slot-scope="scope">
                  <div :style="scope.row.auditFlag ? 'color:#039F3A' : 'color:#FF8900'">
                    {{ scope.row.auditFlag ? '已审核' : '未审核' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="用户级别" align="center">
                <el-table-column label="国家级" prop="countryLevel" align="center" class-name="cell-tag"></el-table-column>
                <el-table-column label="省级" prop="provinceLevel" align="center" class-name="cell-tag"></el-table-column>
                <el-table-column label="地市级" prop="cityLevel" align="center" class-name="cell-tag"></el-table-column>
                <el-table-column label="区县级" prop="districtLevel" align="center" class-name="cell-tag"></el-table-column>
                <el-table-column label="农户" prop="farmerLevel" align="center" class-name="cell-tag"></el-table-column>
                <el-table-column label="专业" prop="professionalLevel" align="center" class-name="cell-tag"></el-table-column>
              </el-table-column>
              <el-table-column label="创建时间" prop="createdOn" width="160"></el-table-column>
              <el-table-column label="修改时间" prop="modifiedOn" width="160"></el-table-column>
              <el-table-column label="操作" fixed="right" width="80">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="wg-pagination-box">
            <el-pagination
              background
              layout="total, sizes, prev, pager, next"
              :total="recordTotal"
              :page-sizes="[30, 50, 100]"
              :page-size="pageSize"
              :current-page="pageIndex"
              @size-change="handleSizeChange"
              @current-change="handlePagingChange"
            ></el-pagination>
          </div>
        </div>
      </div>
      <!-- table data -->
    </div>
    <component ref="createDialog" v-bind:is="slots.createDialog" @close="handleCreateDialogClose" @create-success="handleCreateSuccess"></component>
    <component ref="modifyDialog" v-bind:is="slots.modifyDialog" @close="handleModifyDialogClose" @update-success="handleUpdateSuccess"></component>
  </div>
</template>

<script>
import RegionApi from '@/api/region-api'
import UserApi from '@/api/user-api'

import UserCreateDialog from './components/UserCreateDialog'
import UserModifyDialog from './components/UserModifyDialog'

export default {
  name: 'UserManage',
  components: {
    'user-create-dialog': UserCreateDialog,
    'user-modify-dialog': UserModifyDialog
  },
  data() {
    return {
      dataLoading: false,
      tabActivedName: 'all',
      noAuditeCount: 0,
      slots: {
        createDialog: null,
        modifyDialog: null
      },
      flags: {
        provinceSelectShow: false,
        citySelectShow: false,
        districtSelectShow: false,
        levelSelectShow: false,
        allowDeleteUser: false
      },
      searchForm: {
        filterData: {
          provinceList: [],
          cityList: [],
          districtList: [],
          provinceOptionList: [],
          cityOptionList: [],
          districtOptionList: [],
          levelOptionList: []
        },
        auditFlag: null,
        disableFlag: false,
        provinceId: null,
        cityId: null,
        districtId: null,
        keyword: null,
        levelId: null,
        registrationTime: []
      },
      tableData: [],
      recordTotal: 0,
      pageSize: 30,
      pageIndex: 1,
      selectedRows: [],
      editRow: null
    }
  },
  created() {
    // province city district level option list
    let userLevelId = sessionStorage.getItem('levelId')
    this.flags.allowDeleteUser = 'Country' === userLevelId || 'Province' === userLevelId

    this.searchForm.filterData.provinceOptionList.push({ text: '全部', value: null })
    this.searchForm.filterData.cityOptionList.push({ text: '全部', value: null })
    this.searchForm.filterData.districtOptionList.push({ text: '全部', value: null })
    this.searchForm.filterData.levelOptionList.push({ text: '全部', value: null })

    if ('Country' === userLevelId) {
      this.flags.provinceSelectShow = true
      this.flags.citySelectShow = true
      this.flags.districtSelectShow = true
      this.flags.levelSelectShow = true
      RegionApi.getProvinceList().then(res => {
        res.data.forEach(el => {
          this.searchForm.filterData.provinceOptionList.push({ text: el.name, value: el.id })
        })
      })
      if ('Admin' === sessionStorage.getItem('roleId')) {
        this.searchForm.filterData.levelOptionList.push({ text: '全国管理员', value: 'Country' })
      }
      this.searchForm.filterData.levelOptionList.push({ text: '省级', value: 'Province' })
      this.searchForm.filterData.levelOptionList.push({ text: '地市', value: 'City' })
      this.searchForm.filterData.levelOptionList.push({ text: '区县', value: 'District' })
      this.searchForm.filterData.levelOptionList.push({ text: '农户', value: 'Farmer' })
      this.searchForm.filterData.levelOptionList.push({ text: '专业', value: 'Professional' })
    }

    if ('Province' === userLevelId) {
      let provinceId = sessionStorage.getItem('provinceId')
      this.flags.citySelectShow = true
      this.flags.districtSelectShow = true
      this.flags.levelSelectShow = true
      this.searchForm.provinceId = provinceId
      RegionApi.getCityList(provinceId).then(res => {
        res.data.forEach(el => {
          this.searchForm.filterData.cityOptionList.push({ text: el.name, value: el.id })
        })
      })
      this.searchForm.filterData.levelOptionList.push({ text: '地市', value: 'City' })
      this.searchForm.filterData.levelOptionList.push({ text: '区县', value: 'District' })
      this.searchForm.filterData.levelOptionList.push({ text: '农户', value: 'Farmer' })
    }

    if ('City' === userLevelId) {
      let provinceId = sessionStorage.getItem('provinceId')
      let cityId = sessionStorage.getItem('cityId')
      this.flags.districtSelectShow = true
      this.flags.levelSelectShow = true
      this.searchForm.provinceId = provinceId
      this.searchForm.cityId = cityId
      RegionApi.getDistrictList(cityId).then(res => {
        res.data.forEach(el => {
          this.searchForm.filterData.districtOptionList.push({ text: el.name, value: el.id })
        })
      })
      this.searchForm.filterData.levelOptionList.push({ text: '区县', value: 'District' })
      this.searchForm.filterData.levelOptionList.push({ text: '农户', value: 'Farmer' })
    }
    if ('District' === userLevelId) {
      this.searchForm.provinceId = sessionStorage.getItem('provinceId')
      this.searchForm.cityId = sessionStorage.getItem('cityId')
      this.searchForm.districtId = sessionStorage.getItem('districtId')
    }

    this.bindTableData()
  },
  methods: {
    handleTabsClick(tab) {
      switch (tab.name) {
        case 'noAudit':
          this.searchForm.auditFlag = false
          this.searchForm.disableFlag = false
          break
        case 'audited':
          this.searchForm.auditFlag = true
          this.searchForm.disableFlag = false
          break
        case 'disabled':
          this.searchForm.auditFlag = null
          this.searchForm.disableFlag = true
          break
        default:
          this.searchForm.auditFlag = null
          this.searchForm.disableFlag = false
          break
      }
      this.resetFilters()
      this.bindTableData()
    },
    handleProvinceSelect(selectedValue) {
      this.searchForm.cityId = null
      this.searchForm.districtId = null
      this.searchForm.filterData.cityOptionList = []
      this.searchForm.filterData.cityOptionList.push({ text: '全部', value: null })
      this.searchForm.filterData.districtOptionList = []
      this.searchForm.filterData.districtOptionList.push({ text: '全部', value: null })
      if (null === selectedValue) return

      RegionApi.getCityList(selectedValue).then(res => {
        res.data.forEach(el => {
          this.searchForm.filterData.cityOptionList.push({ text: el.name, value: el.id })
        })
      })
    },
    handleCitySelect(selectedValue) {
      this.searchForm.districtId = null
      this.searchForm.filterData.districtOptionList = []
      this.searchForm.filterData.districtOptionList.push({ text: '全部', value: null })
      if (null === selectedValue) return

      RegionApi.getDistrictList(selectedValue).then(res => {
        res.data.forEach(el => {
          this.searchForm.filterData.districtOptionList.push({ text: el.name, value: el.id })
        })
      })
    },
    handleSearch() {
      this.selectedRows = []
      this.pageIndex = 1
      this.bindTableData()
    },
    handleReset() {
      this.pageIndex = 1
      this.resetFilters()
      this.bindTableData()
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows
    },
    handleSizeChange(pageSize) {
      this.pageIndex = 1
      this.pageSize = pageSize
      this.bindTableData()
    },
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
      this.bindTableData()
    },
    handleAdd() {
      this.slots.createDialog = 'user-create-dialog'
      this.$nextTick(() => {
        this.$refs.createDialog.open()
      })
    },
    handleEdit(row) {
      this.editRow = row
      this.slots.modifyDialog = 'user-modify-dialog'
      this.$nextTick(() => {
        this.$refs.modifyDialog.open(row.id, row.index)
      })
    },
    handleEnableSelected() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      this.$confirm('此操作恢复用户账号使用, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          for (let i = 0; i < this.selectedRows.length; i++) {
            await this.enable(this.selectedRows[i])
          }
          this.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消操作！', type: 'info' })
        })
    },
    handleDisableSelected() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      this.$confirm('此操作停用用户账号使用, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          for (let i = 0; i < this.selectedRows.length; i++) {
            await this.disable(this.selectedRows[i])
          }
          this.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消操作！', type: 'info' })
        })
    },
    handleDeleteSelected() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          for (let i = 0; i < this.selectedRows.length; i++) {
            await this.delete(this.selectedRows[i])
          }
          this.pageIndex = 1
          this.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    handleCreateDialogClose() {
      this.slots.createDialog = null
    },
    handleCreateSuccess() {
      this.bindTableData()
    },
    handleModifyDialogClose() {
      this.slots.modifyDialog = null
      this.editRow = null
    },
    handleUpdateSuccess() {
      let row = this.editRow
      this.editRow = null
      UserApi.getById(row.id)
        .then(res => {
          let model = res.data
          row.provinceId = model.provinceId
          row.provinceName = model.provinceName
          row.cityId = model.cityId
          row.cityName = model.cityName
          row.districtId = model.districtId
          row.districtName = model.districtName
          row.detailedAddress = model.detailedAddress
          row.organization = model.organization
          row.phoneNumber = model.phoneNumber
          row.officePhone = model.officePhone
          row.email = model.email
          row.lastLoginTime = model.lastLoginTime
          row.auditFlag = model.auditFlag
          row.registrationTime = model.registrationTime
          row.levelId = model.levelId
          row.levelName = model.levelName
          row.roleId = model.roleId
          row.roleName = model.roleName
          row.farmerNature = model.farmerNature
          row.farmerNatureName = model.farmerNatureName
          row.educationalLevel = model.educationalLevel
          row.educationalLevelName = model.educationalLevelName
          row.cultivationLandArea = model.cultivationLandArea
          row.modelHouseholdFlag = model.modelHouseholdFlag
          row.countryLevel = 'Country' === model.levelId ? '✓' : null
          row.provinceLevel = 'Province' === model.levelId ? '✓' : null
          row.cityLevel = 'City' === model.levelId ? '✓' : null
          row.districtLevel = 'District' === model.levelId ? '✓' : null
          row.farmerLevel = 'Farmer' === model.levelId ? '✓' : null
          row.farmerLevel = 'Professional' === model.levelId ? '✓' : null
          row.createdOn = model.createdOn
          row.modifiedOn = model.modifiedOn
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    handleAudit() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      this.$confirm('此操作将修改该数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.selectedRows.forEach(async el => {
            await this.audit(el)
          })
          this.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, type: 'info', message: '已取消操作！' })
        })
    },
    resetFilters() {
      let userLevelId = sessionStorage.getItem('levelId')
      this.searchForm.filterData.levelOptionList.push({ text: '全部', value: null })
      this.searchForm.filterData.levelOptionList = []
      if ('Country' === userLevelId) {
        this.searchForm.districtId = null
        this.searchForm.cityId = null
        this.searchForm.provinceId = null
        this.searchForm.filterData.districtOptionList = []
        this.searchForm.filterData.districtOptionList.push({ text: '全部', value: null })
        this.searchForm.filterData.cityOptionList = []
        this.searchForm.filterData.cityOptionList.push({ text: '全部', value: null })

        this.flags.levelSelectShow = true
        if ('Admin' === sessionStorage.getItem('roleId')) {
          this.searchForm.filterData.levelOptionList.push({ text: '全国', value: 'Country' })
        }
        this.searchForm.filterData.levelOptionList.push({ text: '省份', value: 'Province' })
        this.searchForm.filterData.levelOptionList.push({ text: '地市', value: 'City' })
        this.searchForm.filterData.levelOptionList.push({ text: '区县', value: 'District' })
        if (null === this.searchForm.auditFlag || this.searchForm.auditFlag) {
          this.searchForm.filterData.levelOptionList.push({ text: '农户', value: 'Farmer' })
        }
        this.searchForm.filterData.levelOptionList.push({ text: '专业', value: 'Professional' })
      }
      if ('Province' === userLevelId) {
        this.searchForm.districtId = null
        this.searchForm.cityId = null
        this.searchForm.filterData.districtOptionList = []
        this.searchForm.filterData.districtOptionList.push({ text: '全部', value: null })

        this.flags.levelSelectShow = true
        this.searchForm.filterData.levelOptionList.push({ text: '地市', value: 'City' })
        this.searchForm.filterData.levelOptionList.push({ text: '区县', value: 'District' })
        if (null === this.searchForm.auditFlag || this.searchForm.auditFlag) {
          this.searchForm.filterData.levelOptionList.push({ text: '农户', value: 'Farmer' })
        }
      }
      if ('City' === userLevelId) {
        this.searchForm.districtId = null
        this.flags.levelSelectShow = false
      }
      this.searchForm.keyword = null
      this.searchForm.levelId = null
      this.searchForm.registrationTime = []
    },
    sortColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1[fieldName], row2[fieldName])
    },
    bindTableData() {
      let regionId = null
      let keyword = this.searchForm.keyword
      let startDate = null
      let endDate = null
      let levelId = this.searchForm.levelId
      let auditFlag = this.searchForm.auditFlag
      let disableFlag = this.searchForm.disableFlag
      if (null !== this.searchForm.districtId) {
        regionId = this.searchForm.districtId
      } else if (null !== this.searchForm.cityId) {
        regionId = this.searchForm.cityId
      } else if (null !== this.searchForm.provinceId) {
        regionId = this.searchForm.provinceId
      }
      if (this.searchForm.registrationTime.length === 2) {
        startDate = this.searchForm.registrationTime[0] + ' 00:00:00'
        endDate = this.searchForm.registrationTime[1] + ' 00:00:00'
      }
      if (null !== this.searchForm.auditFlag && !this.searchForm.auditFlag) {
        UserApi.count(regionId, keyword, startDate, endDate, levelId, auditFlag, disableFlag).then(res => {
          this.noAuditeCount = res.data
        })
      } else {
        let userLevelId = sessionStorage.getItem('levelId')
        let tempRegionId = null
        if ('Province' === userLevelId) tempRegionId = sessionStorage.getItem('provinceId')
        else if ('City' === userLevelId) tempRegionId = sessionStorage.getItem('cityId')
        else if ('District' === userLevelId) tempRegionId = sessionStorage.getItem('districtId')
        UserApi.count(tempRegionId, null, null, null, null, false, false).then(res => {
          this.noAuditeCount = res.data
        })
      }

      this.dataLoading = true
      UserApi.pagingSearch(regionId, keyword, startDate, endDate, levelId, auditFlag, disableFlag, this.pageIndex, this.pageSize)
        .then(res => {
          this.tableData = []
          this.recordTotal = res.data.totalCount
          let index = 0
          res.data.userList.forEach(el => {
            this.tableData.push({
              index: ++index,
              id: el.id,
              username: el.username,
              fullName: el.fullName,
              provinceId: el.provinceId,
              provinceName: el.provinceName,
              cityId: el.cityId,
              cityName: el.cityName,
              districtId: el.districtId,
              districtName: el.districtName,
              detailedAddress: el.detailedAddress,
              organization: el.organization,
              phoneNumber: el.phoneNumber,
              officePhone: el.officePhone,
              email: el.email,
              lastLoginTime: el.lastLoginTime,
              auditFlag: el.auditFlag,
              registrationTime: el.registrationTime,
              levelId: el.levelId,
              levelName: el.levelName,
              roleId: el.roleId,
              roleName: el.roleName,
              farmerNature: el.farmerNature,
              farmerNatureName: el.farmerNatureName,
              educationalLevel: el.educationalLevel,
              educationalLevelName: el.educationalLevelName,
              cultivationLandArea: el.cultivationLandArea,
              modelHouseholdFlag: el.modelHouseholdFlag,
              modelHouseholdName: null === el.modelHouseholdFlag ? '--' : el.modelHouseholdFlag ? '是' : '否',
              countryLevel: 'Country' === el.levelId ? '✓' : null,
              provinceLevel: 'Province' === el.levelId ? '✓' : null,
              cityLevel: 'City' === el.levelId ? '✓' : null,
              districtLevel: 'District' === el.levelId ? '✓' : null,
              farmerLevel: 'Farmer' === el.levelId ? '✓' : null,
              professionalLevel: 'Professional' === el.levelId ? '✓' : null,
              createdOn: el.createdOn,
              modifiedOn: el.modifiedOn
            })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
        .finally(() => {
          this.dataLoading = false
        })
    },
    async delete(row) {
      await UserApi.deleteById(row.id)
        .then(() => {
          this.$message({ showClose: true, message: '记录【' + row.index + '】删除成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async audit(row) {
      await UserApi.audit(row.id)
        .then(() => {
          this.$message({ showClose: true, message: '记录【' + row.index + '】操作成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async enable(row) {
      await UserApi.enable(row.id)
        .then(() => {
          this.$message({ showClose: true, message: '记录【' + row.index + '】操作成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async disable(row) {
      await UserApi.disable(row.id)
        .then(() => {
          this.$message({ showClose: true, message: '记录【' + row.index + '】操作成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped></style>
>
