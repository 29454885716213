export default {
  isEmpty(val) {
    return null === val || '' === val
  },
  isNotEmpty(val) {
    return null !== val && '' !== val
  },
  equals(val1, val2) {
    if (null == val1 && '' == val1 && null == val2 && '' == val2) return true
    return val1 == val2
  }
}