<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/" class="bread_crumb">
        <el-breadcrumb-item :to="{ path: '/' }">农事管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/crop' }">肥料管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <div style="margin-top: 16px">
      <el-tabs v-model="activeName" class="wg-tabs" @tab-click="handleTabClick">
        <el-tab-pane name="chemicalFertilizerPane">
          <span slot="label"> 化肥管理</span>
          <chemical-fertilizer-manange-component ref="chemicalFertilizerManageComponent" v-if="isLoadChemicalFertilizerManageComponent"></chemical-fertilizer-manange-component>
        </el-tab-pane>
        <!-- chemical-fertilizer -->
        <el-tab-pane name="organicFertilizerPane">
          <span slot="label"> 有机肥管理</span>
          <organic-fertilizer-manage-component ref="organicFertilizerManageComponent" v-if="isLoadOrganicFertilizerManageComponent"></organic-fertilizer-manage-component>
        </el-tab-pane>
        <!-- organic-fertilizer -->
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ChemicalFertilizerManage from './components/ChemicalFertilizerManage'
import OrganicFertilizerManage from './components/OrganicFertilizerManage'

export default {
  name: 'FertilizerManage',
  data() {
    return {
      activeName: 'chemicalFertilizerPane',
      isLoadChemicalFertilizerManageComponent: true,
      isLoadOrganicFertilizerManageComponent: false
    }
  },
  components: {
    'chemical-fertilizer-manange-component': ChemicalFertilizerManage,
    'organic-fertilizer-manage-component': OrganicFertilizerManage
  },
  methods: {
    handleTabClick(tab) {
      this.isLoadChemicalFertilizerManageComponent = tab.name === 'chemicalFertilizerPane'
      this.isLoadOrganicFertilizerManageComponent = tab.name === 'organicFertilizerPane'
      this.$nextTick(() => {
        this.loadComponent(tab.name)
      })
    },
    loadComponent(name) {
      switch (name) {
        case 'chemicalFertilizerPane':
          this.$refs.chemicalFertilizerManageComponent.load()
          break
        case 'organicFertilizerPane':
          this.$refs.organicFertilizerManageComponent.load()
          break
        default:
          this.$refs.chemicalFertilizerManageComponent.load()
          break
      }
    }
  }
}
</script>

<style scoped>
</style>
