import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'

import ElementUI from 'element-ui'
import { UxGrid, UxTableColumn } from 'umy-ui'
import mainStore from './store/main-store'
import mainRouter from './router/main-router'

import 'regenerator-runtime'
import moment from 'moment'
import qs from 'qs'

import App from './App.vue'

import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/main.css'

import DatePicker from './components/date-picker/date-picker'
import '@/icons'
import dataV from '@jiaminghi/data-view'

Vue.component(DatePicker.name, DatePicker)
//import './mock'// mock 方式，正式发布时，注释掉该处即可

Vue.config.productionTip = false
Vue.prototype.$qs = qs
Vue.prototype.$moment = moment
Vue.prototype.$utils = {
  isEmpty(val) {
    return null === val || '' === val
  },
  isNotEmpty(val) {
    return null !== val && '' !== val
  },
  equals(val1, val2) {
    if (null == val1 && '' == val1 && null == val2 && '' == val2) return true
    return val1 == val2
  },
  sortColumn(val1, val2) {
    if (null === val1 && null === val2) return 0
    if (null === val1 && null !== val2) return -1
    if (null !== val1 && null === val2) return 1

    let res = 0
    const testType = char => {
      if (/^[\u4e00-\u9fa5]$/.test(char)) return ['zh', 300]
      if (/^[a-zA-Z]$/.test(char)) return ['en', 200]
      if (/^[0-9]$/.test(char)) return ['number', 100]
      return ['others', 999]
    }
    for (let i = 0; ; i++) {
      if (!val1[i] || !val2[i]) {
        res = val1.length - val2.length
        break
      }

      const char1 = val1[i]
      const char2 = val2[i]
      const char1Type = testType(char1)
      const char2Type = testType(char2)
      if (char1Type[0] === char2Type[0]) {
        if (char1 === char2) {
          continue
        } else {
          if (char1Type[0] === 'zh') {
            res = char1.localeCompare(char2, 'zh-Hans')
          } else if (char1Type[0] === 'en') {
            res = char1.charCodeAt(0) - char2.charCodeAt(0)
          } else {
            res = char1 - char2
          }
          break
        }
      } else {
        res = char1Type[1] - char2Type[1]
        break
      }
    }
    return res
  },
  //valueList要计算的数组
  //idx要计算数组中值的下表
  //precision百分比保留几位小数
  getPercentValue(valueList, idx, precision) {
    // 判断是否为空
    if (!valueList[idx]) {
      return 0
    }
    // 求和
    let sum = valueList.reduce(function (acc, val) {
      return acc + (isNaN(val) ? 0 : val)
    }, 0)
    if (sum === 0) {
      return 0
    }
    // 10的2次幂是100，用于计算精度。
    let digits = Math.pow(10, precision)
    // 扩大比例100，
    let votesPerQuota = valueList.map(function (val) {
      return ((isNaN(val) ? 0 : val) / sum) * digits * 100
    })
    // 总数，扩大比例意味的总数要扩大
    let targetSeats = digits * 100
    // 再向下取值，组成数组
    let seats = votesPerQuota.map(function (votes) {
      return Math.floor(votes)
    })
    // 再新计算合计，用于判断与总数量是否相同，相同则占比会100%
    let currentSum = seats.reduce(function (acc, val) {
      return acc + val
    }, 0)
    // 余数部分的数组：原先数组减去向下取值的数组，得到余数部分的数组
    let remainder = votesPerQuota.map(function (votes, idx) {
      return votes - seats[idx]
    })
    // 给最大最大的余额加1，凑个占比100%；
    while (currentSum < targetSeats) {
      //  找到下一个最大的余额，给其加1
      let max = Number.NEGATIVE_INFINITY
      let maxId = null
      for (let i = 0, len = remainder.length; i < len; ++i) {
        if (remainder[i] > max) {
          max = remainder[i]
          maxId = i
        }
      }
      // 对最大项余额加1
      ++seats[maxId]
      // 已经增加最大余数加1，则下次判断就可以不需要再判断这个余额数。
      remainder[maxId] = 0
      // 总的也要加1，为了判断是否总数是否相同，跳出循环。
      ++currentSum
    }
    // 这时候的seats就会总数占比会100%
    return seats[idx] / digits
  }
}
Vue.use(ElementUI)
Vue.use(UxGrid)
Vue.use(UxTableColumn)
Vue.use(dataV)
Vue.use(Vuex)
Vue.use(VueRouter)


new Vue({
  store: mainStore.create(),
  router: mainRouter.create(),
  beforeCreate() {
    let userLevelId = sessionStorage.getItem('levelId')
    let title = '农户施肥信息监测系统'
    switch (userLevelId) {
      case 'Country':
        title = '全国' + title
        break
      case 'Province':
        title = sessionStorage.getItem('provinceName') + title
        break
      case 'City':
        title = sessionStorage.getItem('cityName') + title
        break
      case 'District':
        title = sessionStorage.getItem('districtName') + title
        break
      default:
        title = '全国' + title
        break
    }

    document.title = title
  },
  render: h => h(App)
}).$mount('#app')
