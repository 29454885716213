<template>
  <div>
    <el-dialog :title="windowTitle" :visible.sync="windowVisible" width="440px" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
      <el-form ref="form" :rules="formRules" :model="form" label-position="left" label-width="100px">
        <el-form-item label="序号" prop="sortNumber">
          <el-input v-model="form.sortNumber"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="氮（%）" prop="nitrogen">
          <el-input v-model="form.nitrogen"></el-input>
        </el-form-item>
        <el-form-item label="磷（%）" prop="phosphorus">
          <el-input v-model="form.phosphorus"></el-input>
        </el-form-item>
        <el-form-item label="钾（%）" prop="potassium">
          <el-input v-model="form.potassium"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" class="wg-button blank" @click="windowVisible = false">取 消</el-button>
        <el-button size="small" class="wg-button" @click="handleSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ChemicalFertilizerApi from '@/api/chemical-fertilizer-api'

export default {
  name: 'ChemicalFertilizerCreate',
  data() {
    return {
      windowTitle: '新增化肥信息',
      windowVisible: false,
      form: {
        sortNumber: '',
        name: '',
        nitrogen: '',
        phosphorus: '',
        potassium: ''
      },
      formRules: {
        sortNumber: [{ required: true, trigger: 'blur', message: '请输入序号！' }],
        name: [{ required: true, trigger: 'blur', message: '请输入名称！' }],
        nitrogen: [{ required: true, trigger: 'blur', message: '请输入氮百分比！' }],
        phosphorus: [{ required: true, trigger: 'blur', message: '请输入磷百分比！' }],
        potassium: [{ required: true, trigger: 'blur', message: '请输入钾百分比！' }]
      }
    }
  },
  methods: {
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return false

        let createModel = {
          name: this.form.name,
          sortNumber: this.form.sortNumber,
          nitrogen: this.form.nitrogen,
          phosphorus: this.form.phosphorus,
          potassium: this.form.potassium
        }
        ChemicalFertilizerApi.create(createModel)
          .then(() => {
            this.$message({ type: 'success', message: '保存成功！' })
            this.windowVisible = false
          })
          .catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          })
      })
    },
    handleClose() {
      this.$emit('reload')
    },
    open() {
      this.windowVisible = true
    }
  }
}
</script>

<style scoped>
</style>
