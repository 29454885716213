<template>
  <div class="wg-box">
    <div class="header">
      <div class="multiple-fill-text">【{{ fullPlantCropModel.plantCrop.afterFertilizerFillInModeName }}】</div>
    </div>
    <div class="body">
      <div class="wg-table-box">
        <el-table ref="table" :data="tableData" class="wg-table normal" border :max-height="380" style="width: 100%">
          <el-table-column label="序号" prop="index" width="100" v-if="'MANY_TIMES' === fullPlantCropModel.plantCrop.afterFertilizerFillInMode" fixed></el-table-column>
          <el-table-column prop="fertilizationModeName" :label="'TOTAL' === fullPlantCropModel.plantCrop.afterFertilizerFillInMode ? '主要施肥方式' : '施肥方式'" width="120" fixed></el-table-column>
          <el-table-column prop="afterFertilizationDate" :label="'TOTAL' === fullPlantCropModel.plantCrop.afterFertilizerFillInMode ? '首次追肥日期' : '追肥日期'" width="150"></el-table-column>
          <el-table-column prop="afterFertilizationTimes" label="追肥次数" width="100"></el-table-column>
          <el-table-column prop="businessOrganicFertilizerDosage" label="商品有机肥用量" width="120"></el-table-column>
          <el-table-column prop="ureaDosage" label="尿素" width="100"></el-table-column>
          <el-table-column prop="ammoniumBicarbonateDosage" label="碳铵" width="100"></el-table-column>
          <el-table-column prop="diammoniumPhosphateDosage" label="磷酸二铵" width="100"></el-table-column>
          <el-table-column prop="potassiumChlorideDosage" label="氯化钾" width="100"></el-table-column>
          <el-table-column prop="potassiumSulphateDosage" label="硫酸钾" width="100"></el-table-column>
          <el-table-column label="复合肥（含配方肥）及养分%">
            <el-table-column prop="compoundFertilizerDosage" label="用量" width="100"></el-table-column>
            <el-table-column prop="compoundFertilizerN" label="N" width="100"></el-table-column>
            <el-table-column prop="compoundFertilizerP2O5" label="P2O5" width="100"></el-table-column>
            <el-table-column prop="compoundFertilizerK2O" label="K2O" width="100"></el-table-column>
          </el-table-column>
          <el-table-column label="水溶肥及养分%">
            <el-table-column prop="waterSolubleFertilizerDosage" label="用量" width="100"></el-table-column>
            <el-table-column prop="waterSolubleFertilizerN" label="N" width="100"></el-table-column>
            <el-table-column prop="waterSolubleFertilizerP2O5" label="P2O5" width="100"></el-table-column>
            <el-table-column prop="waterSolubleFertilizerK2O" label="K2O" width="100"></el-table-column>
          </el-table-column>
          <el-table-column label="其他肥料1">
            <el-table-column prop="otherFertilizer1Name" label="名称" width="120"></el-table-column>
            <el-table-column prop="otherFertilizer1Dosage" label="用量" width="120"></el-table-column>
          </el-table-column>
          <el-table-column label="其他肥料2">
            <el-table-column prop="otherFertilizer2Name" label="名称" width="120"></el-table-column>
            <el-table-column prop="otherFertilizer2Dosage" label="用量" width="120"></el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArchivedAfterFertilizer',
  props: {
    fullPlantCropModel: { type: Object, default: null }
  },
  data() {
    return {
      tableData: []
    }
  },
  created() {
    let index = 0
    let afterFertilizerModelList = this.fullPlantCropModel.afterFertilizerList
    this.tableData = []

    if ('MANY_TIMES' === this.fullPlantCropModel.afterFertilizerFillInMode) {
      afterFertilizerModelList.forEach(el => {
        if (null === el.id) return

        this.tableData.push({
          id: el.id,
          index: ++index,
          afterFertilizationTimes: el.afterFertilizationTimes,
          afterFertilizationDate: this.$utils.isEmpty(el.afterFertilizationDate) ? null : this.$moment(el.afterFertilizationDate).format('YYYY-MM-DD'),
          businessOrganicFertilizerDosage: el.businessOrganicFertilizerDosage,
          businessOrganicFertilizerPrice: el.businessOrganicFertilizerPrice,
          ureaDosage: el.ureaDosage,
          ammoniumBicarbonateDosage: el.ammoniumBicarbonateDosage,
          diammoniumPhosphateDosage: el.diammoniumPhosphateDosage,
          potassiumChlorideDosage: el.potassiumChlorideDosage,
          potassiumSulphateDosage: el.potassiumSulphateDosage,
          compoundFertilizerDosage: el.compoundFertilizerDosage,
          compoundFertilizerN: el.compoundFertilizerN,
          compoundFertilizerP2O5: el.compoundFertilizerP2O5,
          compoundFertilizerK2O: el.compoundFertilizerK2O,
          waterSolubleFertilizerDosage: el.waterSolubleFertilizerDosage,
          waterSolubleFertilizerN: el.waterSolubleFertilizerN,
          waterSolubleFertilizerP2O5: el.waterSolubleFertilizerP2O5,
          waterSolubleFertilizerK2O: el.waterSolubleFertilizerK2O,
          otherFertilizer1Id: el.otherFertilizer1Id,
          otherFertilizer1Name: el.otherFertilizer1Name,
          otherFertilizer1Dosage: el.otherFertilizer1Dosage,
          otherFertilizer2Id: el.otherFertilizer2Id,
          otherFertilizer2Name: el.otherFertilizer2Name,
          otherFertilizer2Dosage: el.otherFertilizer2Dosage,
          fertilizationModeName: el.fertilizationModeName
        })
      })
    }
    if ('TOTAL' === this.fullPlantCropModel.plantCrop.afterFertilizerFillInMode) {
      let afterFertilizerTotal = this.fullPlantCropModel.afterFertilizerTotal
      this.tableData.push({
        id: afterFertilizerTotal.id,
        index: null,
        afterFertilizationTimes: afterFertilizerTotal.afterFertilizationTimes,
        afterFertilizationDate: this.$utils.isEmpty(afterFertilizerTotal.afterFertilizationDate) ? null : this.$moment(afterFertilizerTotal.afterFertilizationDate).format('YYYY-MM-DD'),
        businessOrganicFertilizerDosage: afterFertilizerTotal.businessOrganicFertilizerDosage,
        businessOrganicFertilizerPrice: afterFertilizerTotal.businessOrganicFertilizerPrice,
        ureaDosage: afterFertilizerTotal.ureaDosage,
        ammoniumBicarbonateDosage: afterFertilizerTotal.ammoniumBicarbonateDosage,
        diammoniumPhosphateDosage: afterFertilizerTotal.diammoniumPhosphateDosage,
        potassiumChlorideDosage: afterFertilizerTotal.potassiumChlorideDosage,
        potassiumSulphateDosage: afterFertilizerTotal.potassiumSulphateDosage,
        compoundFertilizerDosage: afterFertilizerTotal.compoundFertilizerDosage,
        compoundFertilizerN: afterFertilizerTotal.compoundFertilizerN,
        compoundFertilizerP2O5: afterFertilizerTotal.compoundFertilizerP2O5,
        compoundFertilizerK2O: afterFertilizerTotal.compoundFertilizerK2O,
        waterSolubleFertilizerDosage: afterFertilizerTotal.waterSolubleFertilizerDosage,
        waterSolubleFertilizerN: afterFertilizerTotal.waterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: afterFertilizerTotal.waterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: afterFertilizerTotal.waterSolubleFertilizerK2O,
        otherFertilizer1Name: afterFertilizerTotal.otherFertilizer1Name,
        otherFertilizer1Dosage: afterFertilizerTotal.otherFertilizer1Dosage,
        otherFertilizer2Name: afterFertilizerTotal.otherFertilizer2Name,
        otherFertilizer2Dosage: afterFertilizerTotal.otherFertilizer2Dosage,
        fertilizationModeName: afterFertilizerTotal.fertilizationModeName
      })
    }

    afterFertilizerModelList.forEach(el => {
      if (null === el.id) return

      this.tableData.push({
        id: el.id,
        index: ++index,
        afterFertilizationTimes: el.afterFertilizationTimes,
        afterFertilizationDate: this.$utils.isEmpty(el.afterFertilizationDate) ? null : this.$moment(el.afterFertilizationDate).format('YYYY-MM-DD'),
        businessOrganicFertilizerDosage: el.businessOrganicFertilizerDosage,
        businessOrganicFertilizerPrice: el.businessOrganicFertilizerPrice,
        urea: el.ureaDosage,
        ammoniumBicarbonate: el.ammoniumBicarbonateDosage,
        diammoniumPhosphate: el.diammoniumPhosphateDosage,
        potassiumChloride: el.potassiumChlorideDosage,
        potassiumSulphate: el.potassiumSulphateDosage,
        compoundFertilizerDosage: el.compoundFertilizerDosage,
        compoundFertilizerN: el.compoundFertilizerN,
        compoundFertilizerP2O5: el.compoundFertilizerP2O5,
        compoundFertilizerK2O: el.compoundFertilizerK2O,
        waterSolubleFertilizerDosage: el.waterSolubleFertilizerDosage,
        waterSolubleFertilizerN: el.waterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: el.waterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: el.waterSolubleFertilizerK2O,
        otherFertilizer1Name: el.otherFertilizer1Name,
        otherFertilizer1Dosage: el.otherFertilizer1Dosage,
        otherFertilizer2Name: el.otherFertilizer2Name,
        otherFertilizer2Dosage: el.otherFertilizer2Dosage,
        fertilizationModeName: el.fertilizationModeName
      })
    })
  }
}
</script>

<style scoped>
.multiple-fill-text {
  margin: 0 0 0 10px;
  font-size: 14px;
  font-weight: bold;
}
</style>
