<template>
  <div class="wg-box">
    <div class="header" style="padding: 0 20px 0 20px">
      <div class="title" style="padding: 0 14px">调查作物一览表</div>
      <div class="button-list" style="float: right; padding: 8px 0 8px 0">
        <el-button size="small" class="wg-button blue" icon="el-icon-download" @click="handleExportData">导出</el-button>
      </div>
    </div>
    <div class="body" style="padding: 10px 20px 10px 20px">
      <div>
        <el-table :data="tableData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)" class="wg-table normal" v-loading="isLoading"
                  element-loading-text="拼命加载数据......"
                  element-loading-spinner="el-icon-loading">
          <el-table-column label="序号" type="index" width="100"></el-table-column>
          <el-table-column label="年份" prop="year" width="100"></el-table-column>
          <el-table-column label="作物">
            <template slot-scope="scope">
              <div style="white-space: normal; text-align: left">{{ scope.row.cropNames }}</div>
            </template>
          </el-table-column>
          <el-table-column :label="levelName">
            <template slot-scope="scope">
              <div style="white-space: normal; text-align: left">{{ scope.row.regionNames }}</div>
            </template>
          </el-table-column>
          <el-table-column label="总户数（户）" prop="farmerCount" width="150"></el-table-column>
          <el-table-column label="总面积（万亩）" prop="sowingArea" width="150"></el-table-column>
        </el-table>
      </div>
      <div class="wg-pagination-box">
        <el-pagination background layout="prev, pager, next" :total="recordTotal" :page-size="pageSize" :current-page="pageIndex" @current-change="handlePagingChange"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SurveyStatisticApi from '@/api/statistic/survey-statistic-api'
import FileApi from '@/api/file-api'

export default {
  name: 'SurveyCropSummary',
  props:["startYear", "endYear"],
  data() {
    return {
      isLoading: false,
      levelName: '省份',
      tableData: [],
      recordTotal: 0,
      pageSize: 10,
      pageIndex: 1
    }
  },
  created() {
    let userLevelId = sessionStorage.getItem('levelId')
    if ('Country' === userLevelId) this.levelName = '省份'
    if ('Province' === userLevelId) this.levelName = '地市'
    if ('City' === userLevelId) this.levelName = '区县'
    if ('District' === userLevelId) this.levelName = '区县'

    this.bindTableData()
  },
  methods: {
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
    },
    bindTableData() {
      // let currentDate = new Date()
      // this.params.year = currentDate.getFullYear()
      //let currentDate = 2022
      this.isLoading = true
      SurveyStatisticApi.getSurveyCropSummaryList(this.startYear, this.endYear)
        .then(res => {
          this.tableData = []
          this.recordTotal = res.data.length
          this.pageIndex = 1
          res.data.forEach(el => {
            this.tableData.push({
              year: el.year,
              cropNames: el.cropNames,
              regionNames: el.regionNames,
              farmerCount: el.farmerCount,
              sowingArea: null == el.sowingArea ? '' : el.sowingArea.toFixed(2)
            })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        }).finally(() => {
          this.isLoading = false
        })
    },
    handleExportData() {
      this.$confirm('准备导出数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {

          let exportId = null
          let exportCount = 0
          let finished = false

          while (!finished) {
            const loading = this.$loading({
              lock: true,
              text: 0 === exportCount ? '正在导出数据......' : '已导出' + exportCount + '条数据......',
              spinner: 'el-icon-loading'
            })

            await SurveyStatisticApi.exportSurveyCropSummaries(exportId, this.startYear, this.endYear)
              .then(res => {
                exportId = res.data.id
                exportCount = res.data.exportCount
                finished = res.data.finished
                loading.close()
              })
              .catch(err => {
                loading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          }

          const loading = this.$loading({
            lock: true,
            text: '等待下载数据文件......',
            spinner: 'el-icon-loading'
          })

          await FileApi.get('TEMP', exportId + '.xlsx')
            .then(res => {
              let byteBuffer = window.atob(res.data)
              let arrayBuffer = new ArrayBuffer(byteBuffer.length)
              let dataBuffer = new Uint8Array(arrayBuffer)
              for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

              let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(blob)
              link.setAttribute('download', '调查作物一览表.xlsx')
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(link.href)
              document.body.removeChild(link)
              loading.close()
            })
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })

          await FileApi.delete('TEMP', exportId + '.xlsx')
            .then(() => {})
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
        })
    },
  }
}
</script>

<style scoped>
.title {
  position: relative;
  margin: 12px 0;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.title:before {
  content: "";
  background-color: #039f3a;
  width: 6px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
}
</style>
