import httpRequest from '@/utils/http-request'

export default {
  getIntensity(params) {
    return httpRequest.post('/annual/intensity', params)
  },
  getProductivity(params) {
    return httpRequest.post('/annual/productivity', params)
  },
  getOrganicApplicationAmount(params) {
    return httpRequest.post('/annual/organic-application-amount', params)
  },
  getChemicalApplicationAmount(params) {
    return httpRequest.post('/annual/chemical-application-amount/all', params)
  },
  getPartialFactorProductivity(params) {
    return httpRequest.post('/annual/partial-factor-productivity', params)
  },
  getAreaAndCoverage(params) {
    return httpRequest.post('/annual/area-and-coverage', params)
  },
  exportExcel(params) {
    return httpRequest.post('/annual/export', params)
  }
}
