<template>
  <div class="wg-box">
    <div class="header uc-header">
      <div class="title">我的积分</div>
      <div class="wg-clear-fix"></div>
    </div>
    <div class="body">
      <div class="wg-table-box">
        <el-table :data="tableData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)" class="wg-table normal">
          <el-table-column prop="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="source" label="积分来源" sortable></el-table-column>
          <el-table-column prop="gainIntegrationTime" label="积分获得时间" sortable></el-table-column>
          <el-table-column prop="amount" label="获得积分">
            <template slot-scope="scope">
              <div style="color: #039f3a">{{ '+' + scope.row.amount }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="wg-pagination-box">
        <el-pagination background layout="prev, pager, next" :total="recordTotal" :page-size="pageSize" :current-page="pageIndex" @current-change="handlePagingChange"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IntegrationApi from '@/api/integration-api'

export default {
  name: 'OwnIntegration',
  data() {
    return {
      tableData: [],
      recordTotal: 0,
      pageSize: 10,
      pageIndex: 1
    }
  },
  created() {
    IntegrationApi.getOwnList().then(res => {
      let index = 0
      res.data.forEach(el => {
        let integration = {
          index: null,
          source: null,
          gainIntegrationTime: null,
          amount: null
        }
        integration.index = ++index
        if ('FILL_IN' === el.type) integration.source = '数据填报'
        if ('AUDIT' === el.type) integration.source = '数据提交准确奖励分'
        integration.gainIntegrationTime = el.gainIntegrationTime
        integration.amount = el.amount
        this.tableData.push(integration)
      })
    })
  },
  methods: {
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
    }
  }
}
</script>

<style scoped>
</style>