<template>
  <div>
    <el-dialog :title="windowTitle" :visible.sync="windowVisible" width="440px" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
      <el-form :model="form" :rules="formRules" ref="form" label-position="left" label-width="100px">
        <el-form-item label="序号" prop="sortNumber">
          <el-input v-model="form.sortNumber" type="number"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" class="wg-button blank" @click="windowVisible = false">取 消</el-button>
        <el-button size="small" class="wg-button" @click="handleSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CropCategoryApi from '@/api/crop/crop-category-api'

export default {
  name: 'CropCategoryCreate',
  data() {
    return {
      windowTitle: '新增作物分类',
      windowVisible: false,
      rootCategoryId: null,
      form: {
        sortNumber: null,
        name: ''
      },
      formRules: {
        sortNumber: [{ required: true, trigger: 'blur', message: '请填写序号！' }],
        name: [{ required: true, trigger: 'blur', message: '请填写名称！' }]
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('reload')
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return

        let createModel = {
          parentId: this.rootCategoryId,
          sortNumber: this.form.sortNumber,
          name: this.form.name
        }

        CropCategoryApi.create(createModel).then(() => {
          this.$message({ type: 'success', message: '保存成功！' })
          this.windowVisible = false
        }).catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
      })
    },
    open(rootCategoryId) {
      this.rootCategoryId = rootCategoryId
      this.windowVisible = true
    }
  }
}
</script>

<style scoped>

</style>