<template>
  <div>
    <el-dialog :title="windowTitle" :visible.sync="windowVisible" width="440px" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
      <el-form :model="form" :rules="formRules" ref="form" label-position="left" label-width="100px">
        <el-form-item label="序号" prop="sortNumber">
          <el-input v-model="form.sortNumber"></el-input>
        </el-form-item>
        <el-form-item label="作物" prop="cropName">
          <el-input v-model="form.cropName" disabled></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" class="wg-button blank" @click="windowVisible = false">取 消</el-button>
        <el-button size="small" class="wg-button" @click="handleSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CropGrowthPeriodApi from '@/api/crop-growth-period-api'
import CropApi from '@/api/crop-api'

export default {
  name: 'CropGrowthPeriodCreate',
  data() {
    return {
      windowTitle: '新增作物生育期',
      windowVisible: false,
      params: { cropId: null },
      form: {
        sortNumber: null,
        cropName: null,
        name: null
      },
      formRules: {
        sortNumber: [{ required: true, trigger: 'blur', message: '请输入序号！' }],
        name: [{ required: true, trigger: 'blur', message: '请输入名称！' }]
      }
    }
  },
  methods: {
    handleSave() {
      let createModel = {
        cropId: this.params.cropId,
        sortNumber: this.form.sortNumber,
        name: this.form.name
      }
      CropGrowthPeriodApi.create(createModel)
        .then(() => {
          this.$message({ type: 'success', message: '保存成功！' })
          this.windowVisible = false
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    handleClose() {
      this.$emit('reload')
    },
    async open(cropId) {
      this.windowVisible = true
      this.params.cropId = cropId

      await CropApi.getById(this.params.cropId)
        .then(res => {
          this.form.cropName = res.data.name
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped>
</style>
