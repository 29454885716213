import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/crops', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/crops/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/crops', updateModel)
  },
  getById(id) {
    return httpRequest.get('/crops/' + id)
  },
  getList(rootCategoryId, subCategoryId, keyword) {
    let config = {
      params: {
        rootCategoryId: rootCategoryId,
        subCategoryId: subCategoryId,
        keyword: keyword
      }
    }
    return httpRequest.get('/crops', config)
  },
  getStubList(rootCategoryId, subCategoryId) {
    let config = {
      params: {
        rootCategoryId: rootCategoryId,
        subCategoryId: subCategoryId
      }
    }
    return httpRequest.get('/crop-stubs', config)
  }
}
