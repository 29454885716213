<template>
  <div>
    <el-row>
      <el-col :span="24">
        <organic-fertilizer-chart :years="years" ref="organicFertilizerChart"></organic-fertilizer-chart>
      </el-col>
    </el-row>
    <el-row><el-col :span="24">&nbsp;</el-col></el-row>
    <el-row>
      <el-col :span="24">
        <chemical-fertilizer-chart :years="years" ref="chemicalFertilizerChart"></chemical-fertilizer-chart>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import OrganicFertilizerChart from '@/views/components/OrganicFertilizerChart.vue'
import ChemicalFertilizerChart from '@/views/components/ChemicalFertilizerChart.vue'

export default {
  name: 'FertilizerApplicationCharts',
  components: { ChemicalFertilizerChart, OrganicFertilizerChart },
  props:["years"],
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.el-card__header {
  padding: 0;
  border-bottom: 0px solid #EBEEF5;
  box-sizing: border-box;
}


.el-card {
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}

.el-card ::v-deep .el-card__header {
  padding: 2px 10px;
  height: 50px;
  border-bottom: none;
}

.el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover{
  box-shadow: none;
}
</style>
