import httpRequest from '@/utils/http-request'

export default {
  reject(id, reason) {
    let body = {
      id: id,
      reason: reason
    }
    return httpRequest.put('/plant-crop-reject', body)
  },
  re_audit(id) {
    let body = { id: id }
    return httpRequest.put('/plant-crop-re-audit', body)
  },
  getRejectReasonList(plantCropId) {
    let config = {
      params: {
        plantCropId: plantCropId
      }
    }
    return httpRequest.get('/reject-plant-crop-reasons', config)
  },
  getCount(year, regionId, keyword) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        keyword: keyword
      }
    }
    return httpRequest.get('/reject-plant-crop-count', config)
  },
  getPaging(year, regionId, keyword, readonly, pageSize, pageIndex) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        keyword: keyword,
        readonly: readonly,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }
    return httpRequest.get('/reject-plant-crop-paging', config)
  },
  exportTemplate(exportId, year, regionId, keyword) {
    let config = {
      params: {
        exportId: exportId,
        year: year,
        regionId: regionId,
        keyword: keyword
      }
    }
    return httpRequest.post('/reject-data-templates', null, config)
  },
  importData(year, fileName) {
    let config = {
      params: {
        year: year,
        fileName: fileName
      }
    }
    return httpRequest.post('/reject-data-import', null, config)
  },
  exportData(exportId, year, regionId, keyword) {
    let config = {
      params: {
        exportId: exportId,
        year: year,
        regionId: regionId,
        keyword: keyword
      }
    }
    return httpRequest.post('/reject-data-export', null, config)
  }
}
