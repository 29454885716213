<template>
  <div>
    <el-dialog :title="windowTitle" :visible.sync="windowVisible" width="800px" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form ref="form" :rules="formRules" :model="form" label-position="left" label-width="100px">
            <el-form-item label="序号" prop="sortNumber">
              <el-input v-model="form.sortNumber" type="number"> </el-input>
            </el-form-item>
            <el-form-item label="标识" prop="id">
              <el-input v-model="form.id" disabled></el-input>
            </el-form-item>
            <el-form-item label="名称" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="启用" prop="enableFlag">
              <el-select v-model="form.enableFlag">
                <el-option v-for="item in enableFlagOptions" :key="item.value" :value="item.value" :label="item.text"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="描述" prop="description">
              <el-input v-model="form.description" type="textarea" rows="2"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <div style="overflow: auto; height: 300px">
            <el-tree ref="permissionTree" :data="permissionGroup" :default-checked-keys="defaultCheckedPermissionKeys" show-checkbox node-key="id" default-expand-all> </el-tree>
          </div>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button size="small" class="wg-button blank" @click="windowVisible = false">取 消</el-button>
        <el-button size="small" class="wg-button" @click="handleSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import RoleApi from '@/api/role-api'
import PermissionApi from '@/api/permission-api'

export default {
  name: 'RoleModify',
  data() {
    return {
      windowTitle: '修改用户角色',
      windowVisible: false,
      params: { id: null },
      enableFlagOptions: [
        { value: true, text: '是' },
        { value: false, text: '否' }
      ],
      permissionGroup: [],
      defaultCheckedPermissionKeys: [],
      form: {
        sortNumber: null,
        id: null,
        name: null,
        description: null,
        enableFlag: null
      },
      formRules: {
        sortNumber: [{ required: true, trigger: 'blur' }],
        id: [{ required: true, trigger: 'blur', message: '请输入标识！' }],
        name: [{ required: true, trigger: 'blur' }]
      }
    }
  },
  methods: {
    handleSave() {
      let updateModel = {
        id: this.params.id,
        sortNumber: this.form.sortNumber,
        name: this.form.name,
        enableFlag: this.form.enableFlag,
        description: this.form.description,
        permissionIdList: []
      }

      this.$refs.permissionTree.getCheckedNodes().forEach(el => {
        if (!el.parent) {
          updateModel.permissionIdList.push(el.id)
        }
      })

      RoleApi.update(updateModel)
        .then(() => {
          this.$message({ showClose: true, message: '保存成功！', type: 'success' })
          this.windowVisible = false
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    handleClose() {
      this.$emit('reload')
    },
    async open(id) {
      this.params.id = id

      await PermissionApi.getGroupList()
        .then(res => {
          res.data.forEach(group => {
            // set permission group
            let permissionGroup = {
              id: group.name,
              label: group.name,
              disabled: true,
              parent: true,
              children: []
            }
            // set permission array
            group.permissionList.forEach(permission => {
              permissionGroup.children.push({
                id: permission.id,
                label: permission.name,
                parent: false
              })
            })
            this.permissionGroup.push(permissionGroup)
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })

      await PermissionApi.getGroupListByRoleId(this.params.id)
        .then(res => {
          res.data.forEach(group => {
            group.permissionList.forEach(permission => {
              this.defaultCheckedPermissionKeys.push(permission.id)
            })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })

      await RoleApi.getById(this.params.id)
        .then(res => {
          this.form.sortNumber = res.data.sortNumber
          this.form.id = res.data.id
          this.form.name = res.data.name
          this.form.enableFlag = res.data.enableFlag
          this.form.description = res.data.description
          this.windowVisible = true
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped>
</style>