import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/chemical-fertilizers', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/chemical-fertilizers/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/chemical-fertilizers', updateModel)
  },
  getById(id) {
    return httpRequest.get('/chemical-fertilizers/' + id)
  },
  getList(name, startDate, endDate) {
    let config = { params: { name: name, startDate: startDate, endDate: endDate } }
    return httpRequest.get('/chemical-fertilizers', config)
  },
  getStubList() {
    let config = { params: { fixFlag: false } }
    return httpRequest.get('/chemical-fertilizer-stubs', config)
  }
}
