import { render, staticRenderFns } from "./ChemicalFertilizerCreate.vue?vue&type=template&id=cff12264&scoped=true"
import script from "./ChemicalFertilizerCreate.vue?vue&type=script&lang=js"
export * from "./ChemicalFertilizerCreate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cff12264",
  null
  
)

export default component.exports