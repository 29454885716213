<template>
  <el-card>
    <div slot="header" class="chart-title"><span>化肥施用总量</span></div>
    <v-chart
      :option="options"
      :init-options="initOptions"
      ref="chart"
      :loading="barLoading"
      :loadingOptions="barLoadingOptions"
      :updateOptions="{notMerge: true}"
      autoresize
    />
  </el-card>
</template>
<script>

import { use } from 'echarts/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import VChart from 'vue-echarts'
import SurveyStatisticApi from '@/api/statistic/survey-statistic-api'

use([
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
]);

export default {
  name: 'ChemicalFertilizerChart',
  components: { VChart },
  props:["years"],
  watch:{
    years() {
      this.refresh()
    }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas"
      },
      chartData: null,
      options: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      chartType: "bar",
      seconds: -1,
      barLoading: false,
      barLoadingOptions: {
        text: "加载中…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0.4)"
      },
    }
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    getChartData() {
      this.options = {}
      this.barLoading = true
      SurveyStatisticApi.getSurveyChemicalFertilizerSummaries(this.years[0], this.years[1]).then(res => {
        this.chartData = res.data
        this.renderChart()

      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.barLoading = false
      })
    },
    renderChart() {
      if(this.chartData.length === 0) {
        this.options = {
          graphic: {
            type: 'text',     // 类型：文本
            left: 'center',
            top: 'middle',
            silent: true,     // 不响应事件
            style: {
              fill: '#9d9d9d',
              fontWeight: 'bold',
              text: '暂无数据',
              fontFamily: 'Microsoft YaHei',
              fontSize: '25px'
            }
          }
        }
        return
      }

      let dataset = this.chartData.map(f => {
        return {
          source: f.value.map(p => {
            return { name: p.name, value: p.value }
          })
        }
      })

      const colors = ['#597EF7', '#36CFC9', '#FFC53D']
      let legends = this.chartData.map(el => el.name)
      let xLabels = this.chartData[0].value.map(f => f.name)
      this.options = {
        grid: {
          bottom: 0,
          left: 10,
          right:10,
          containLabel: true,
        },
        textStyle: {
          fontSize: 14
        },
        color: colors,
        title: {
          text: '单位: 万吨',
          top: 20,
          left: 'left',
          textStyle: {
            fontSize: 14,
            color: '#000000',
            fontWeight: 500
          }
        },
        legend: {
          x: 'right',      //可设定图例在左、右、居中
          y: 'top',     //可设定图例在上、下、居中
          padding: [0, 60, 0, 0],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          icon: 'rect', // 修改形状
          itemWidth: 8,
          itemHeight: 8, // 修改icon图形大小
          left: 'right',
          data: legends
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            label: {
              backgroundColor: '#6a7985'
            }
          },
          textStyle: {
            align: 'left'
          },
          formatter(a) {
            let res = ''
            let sum = 0
            a.forEach((item, index) => {
              if (index === 0) {
                res += `${item.axisValue}<br/>`
              }
              sum += item.data.value
              res += `${item.marker} ${item.seriesName} <span style='font-weight:bold;float: right'>${item.data.value}</span><br/>`
            })
            res += `<br/> 总养分 <span style='font-weight:bold;float: right'>${sum.toFixed(4)}</span>`
            return res
          },
          extraCssText: 'width: 200px'
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            fontSize: 14
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed'
            }
          },
          data: xLabels
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        dataset: dataset,
        series: this.chartData.map((f, index) => {
          return {
            name: f.name,
            type: 'line',
            stack: '总养分',
            datasetIndex: index,
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            symbol: 'circle',
            symbolSize: [8, 8],
            areaStyle: {
              color: colors[index]
            },
            emphasis: {
              focus: 'series'
            },
            encode: { y: 'value' }
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.echarts {
  width: 30vw;
  min-width: 20vw;
  height: 27vh;
}
.chart-title {
  font-size: 14px;
}
</style>
