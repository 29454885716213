<template>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
    <div class="wg-box">
      <div class="body">
        <el-form ref="form" :model="form" :rules="form.rules" :show-message="true" label-width="150px">
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="用户名称：" prop="username">
                <el-input v-model="form.username" size="medium"></el-input>
              </el-form-item>
              <el-form-item label="用户密码：" prop="password">
                <el-input v-model="form.password" size="medium" show-password autocomplete="new-password"></el-input>
              </el-form-item>
              <el-form-item label="确认密码：" prop="confirmPassword">
                <el-input v-model="form.confirmPassword" size="medium" show-password></el-input>
              </el-form-item>
              <el-form-item label="真实姓名：" prop="fullName">
                <el-input v-model="form.fullName" size="medium"></el-input>
              </el-form-item>
              <el-form-item label="办公电话：" prop="officePhone">
                <el-input v-model="form.officePhone" size="medium" placeholder="区号-电话号码"></el-input>
              </el-form-item>
              <el-form-item label="手机号码：" prop="phoneNumber">
                <el-input v-model="form.phoneNumber" size="medium"></el-input>
              </el-form-item>
              <el-form-item label="电子邮箱：" prop="email">
                <el-input v-model="form.email" size="medium"></el-input>
              </el-form-item>
              <el-form-item label="所在单位：" prop="organization">
                <el-autocomplete class="inline-input" v-model="form.organization" size="medium" :fetch-suggestions="querySearch" clearable :readonly="flags.isProfessionalIndexDirectory"></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户级别：" prop="levelId">
                <el-select v-model="form.levelId" size="medium" :disabled="flags.disableLevelSelect" @change="handleLevelChange">
                  <el-option v-for="option in form.filterData.levelOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="用户类别：" prop="professionalCategory" v-if="flags.isProfessionalCategory">
                <el-select v-model="form.professionalCategory" value-key="id" size="medium" @change="handleProfessionalCategory">
                  <el-option v-for="option in form.filterData.professionalCategoryOptionList" :key="option.id" :label="option.name" :value="option"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属省份：" prop="provinceId">
                <el-select v-model="form.provinceId" size="medium" :disabled="flags.disableProvinceSelect" @change="handleProvinceChange">
                  <el-option v-for="option in form.filterData.provinceOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属地市：" prop="cityId">
                <el-select v-model="form.cityId" size="medium" :disabled="flags.disableCitySelect" @change="handleCityChange">
                  <el-option v-for="option in form.filterData.cityOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所属区县：" prop="districtId">
                <el-select v-model="form.districtId" size="medium" :disabled="flags.disableDistrictSelect">
                  <el-option v-for="option in form.filterData.districtOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="详细地址：" prop="detailedAddress">
                <el-input v-model="form.detailedAddress" size="medium"></el-input>
              </el-form-item>
              <el-form-item label="农户性质：" prop="farmerNature" v-if="flags.isFarmer">
                <el-select v-model="form.farmerNature">
                  <el-option v-for="option in form.filterData.farmerNatureOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="文化程度：" prop="educationalLevel" v-if="flags.isFarmer">
                <el-select v-model="form.educationalLevel">
                  <el-option v-for="option in form.filterData.educationalLevelOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="经营面积（亩）：" prop="cultivationLandArea" v-if="flags.isFarmer">
                <el-input v-model="form.cultivationLandArea"></el-input>
              </el-form-item>
              <el-form-item label="示范户：" prop="modelHouseholdFlag" v-if="flags.isFarmer">
                <el-select v-model="form.modelHouseholdFlag">
                  <el-option v-for="option in form.filterData.modelHouseholdFlagOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="wg-clear-fix"></div>
      </div>
      <div slot="footer" class="footer">
        <div class="button-list right">
          <el-button class="wg-button blank" @click="dialogVisible = false">取 消</el-button>
          <el-button class="wg-button" @click="handleSave">保 存</el-button>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import UserApi from '@/api/user-api'
import RegionApi from '@/api/region-api'
import ProfessionalCategoryApi from '@/api/professional-category-api'
import ProfessionalIndexDirectoryApi from '@/api/professional-index-directory-api'

export default {
  name: 'UserCreateDialog',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^[^\s]*$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('用户名存在空格！'))
        }
      } else {
        callback()
      }
    }

    const validatePassword = (rule, value, callback) => {
      if (this.$utils.isEmpty(value) && this.$utils.isEmpty(this.form.confirmPassword)) {
        callback()
      } else if (this.$utils.isEmpty(value) && this.$utils.isNotEmpty(this.form.confirmPassword)) {
        callback(new Error('请输入用户密码！'))
      } else {
        const reg = /^(?=.*\d)(?=.*[a-zA-Z]).{8,32}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('密码必须包含至少8位字母数字！'))
        }
      }
    }

    const validateConfirmPassword = (rule, value, callback) => {
      if (this.$utils.isEmpty(value) && this.$utils.isEmpty(this.form.password)) {
        callback()
      } else if (this.$utils.isEmpty(value) && this.$utils.isNotEmpty(this.form.password)) {
        callback(new Error('请输入确认密码！'))
      } else if (this.$utils.isNotEmpty(value) && this.$utils.isEmpty(this.form.password)) {
        callback()
      } else if (value !== this.form.password) {
        callback(new Error('输入密码不一致！'))
      } else {
        callback()
      }
    }

    const validateOfficePhone = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^(0\d{2,3})-?(\d{7,8})$/
        if (reg.test(value)) {
          callback()
        } else {
          callback('请输入正确电话号码！')
        }
      } else {
        callback()
      }
    }

    const validatePhoneNumber = (rule, value, callback) => {
      const reg = /^[1-9][0-9]{10}$/
      if (this.$utils.isNotEmpty(value) && !reg.test(value)) {
        callback(new Error('请输入正确手机号码！'))
      } else {
        callback()
      }
    }

    const validateEmail = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        if (reg.test(value)) {
          callback()
        } else {
          callback('请输入正确电子邮件！')
        }
      } else {
        callback()
      }
    }

    const validateNumber = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^([1-9]\d*(\.\d*)?)$|^0\.\d*[1-9]\d*$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入数字！'))
        }
      } else {
        callback()
      }
    }

    return {
      dialogTitle: '新增用户',
      dialogVisible: false,
      flags: {
        isFarmer: true,
        isProfessionalCategory: false,
        isProfessionalIndexDirectory: false,
        disableLevelSelect: false,
        disableProvinceSelect: false,
        disableCitySelect: false,
        disableDistrictSelect: false
      },
      form: {
        filterData: {
          organizationNameList: [],
          levelOptionList: [],
          professionalCategoryOptionList: [],
          provinceOptionList: [],
          cityOptionList: [],
          districtOptionList: [],
          farmerNatureOptionList: [],
          educationalLevelOptionList: [],
          auditFlagOptionList: [],
          modelHouseholdFlagOptionList: []
        },
        rules: {
          username: [
            { required: true, trigger: 'blur', message: '请输入用户名称！' },
            { trigger: 'blur', validator: validateUsername }
          ],
          password: [{ trigger: 'blur', validator: validatePassword }],
          confirmPassword: [{ trigger: 'blur', validator: validateConfirmPassword }],
          fullName: [{ required: true, trigger: 'blur', message: '请输入真实姓名！' }],
          officePhone: [{ trigger: 'blur', validator: validateOfficePhone }],
          phoneNumber: [
            { required: true, trigger: 'blur', message: '请输入手机号码！' },
            { trigger: 'blur', validator: validatePhoneNumber }
          ],
          email: [{ trigger: 'blur', validator: validateEmail }],
          organization: [{ required: false, trigger: 'blur', message: '请输入单位名称' }],
          levelId: [{ required: true, trigger: 'change', message: '请选择用户级别！' }],
          professionalCategory: [{ required: true, trigger: 'change', message: '请选择专业用户类别！' }],
          provinceId: [{ required: true, trigger: 'change', message: '请选择省份！' }],
          cityId: [{ required: true, trigger: 'change', message: '请选择省份！' }],
          districtId: [{ required: true, trigger: 'change', message: '请选择省份！' }],
          detailedAddress: [{ required: true, trigger: 'change', message: '请输入详细地址！' }],
          farmerNature: [{ required: true, trigger: 'change', message: '请选择农户性质！' }],
          educationalLevel: [{ required: true, trigger: 'change', message: '请选择文化程度！' }],
          cultivationLandArea: [
            { required: true, trigger: 'blur', message: '请输入经营面积！' },
            { trigger: 'blur', validator: validateNumber }
          ],
          modelHouseholdFlag: [{ required: true, trigger: 'change', message: '请选择示范户！' }]
        },
        username: null,
        password: null,
        confirmPassword: null,
        fullName: null,
        officePhone: null,
        phoneNumber: null,
        email: null,
        detailedAddress: null,
        organization: null,
        levelId: 'Farmer',
        professionalCategory: null,
        provinceId: null,
        cityId: null,
        districtId: null,
        farmerNature: null,
        educationalLevel: null,
        cultivationLandArea: null,
        modelHouseholdFlag: null
      }
    }
  },
  async created() {
    // professionalCategoryOptionList
    await ProfessionalCategoryApi.getList().then(res => {
      res.data.forEach(el => {
        this.form.filterData.professionalCategoryOptionList.push({
          id: el.id,
          name: el.name,
          indexDirectoryFlag: el.indexDirectoryFlag
        })
      })
    })
    // farmerNatureOptionList
    this.form.filterData.farmerNatureOptionList.push({ text: '请选择', value: null })
    this.form.filterData.farmerNatureOptionList.push({ text: '普通种植户', value: 'COMMON_PLANTER' })
    this.form.filterData.farmerNatureOptionList.push({ text: '种植大户', value: 'LARGE_PLANTER' })
    this.form.filterData.farmerNatureOptionList.push({ text: '家庭农场', value: 'FAMILY_FARM' })
    this.form.filterData.farmerNatureOptionList.push({ text: '专业合作社', value: 'SPECIALIZED_COOPERATIVE' })
    // educationalLevelOptionList
    this.form.filterData.educationalLevelOptionList.push({ text: '请选择', value: null })
    this.form.filterData.educationalLevelOptionList.push({ text: '小学', value: 'PRIMARY_SCHOOL' })
    this.form.filterData.educationalLevelOptionList.push({ text: '初中', value: 'JUNIOR_HIGH_SCHOOL' })
    this.form.filterData.educationalLevelOptionList.push({ text: '中专/中技', value: 'TECHNICAL_SECONDARY_SCHOOL' })
    this.form.filterData.educationalLevelOptionList.push({ text: '高中', value: 'SENIOR_HIGH_SCHOOL' })
    this.form.filterData.educationalLevelOptionList.push({ text: '大专', value: 'JUNIOR_COLLEGE' })
    this.form.filterData.educationalLevelOptionList.push({ text: '本科', value: 'UNDERGRADUATE' })
    this.form.filterData.educationalLevelOptionList.push({ text: '研究生', value: 'GRADUATE' })
    // modelHouseholdFlagOptionList
    this.form.filterData.modelHouseholdFlagOptionList.push({ text: '请选择', value: null })
    this.form.filterData.modelHouseholdFlagOptionList.push({ text: '是', value: true })
    this.form.filterData.modelHouseholdFlagOptionList.push({ text: '否', value: false })
    // init
    let userLevelId = sessionStorage.getItem('levelId')
    let roleId = sessionStorage.getItem('roleId')
    let provinceId = sessionStorage.getItem('provinceId')
    let cityId = sessionStorage.getItem('cityId')
    let districtId = sessionStorage.getItem('districtId')
    if ('Country' === userLevelId) {
      if ('Admin' === roleId) this.form.filterData.levelOptionList.push({ text: '国家', value: 'Country' })
      this.form.filterData.levelOptionList.push({ text: '省级', value: 'Province' })
      this.form.filterData.levelOptionList.push({ text: '地市', value: 'City' })
      this.form.filterData.levelOptionList.push({ text: '区县', value: 'District' })
      this.form.filterData.levelOptionList.push({ text: '农户', value: 'Farmer' })
      this.form.filterData.levelOptionList.push({ text: '专业', value: 'Professional' })
      this.bindProvinceSelectData()
    } else if ('Province' === userLevelId) {
      this.form.filterData.levelOptionList.push({ text: '地市', value: 'City' })
      this.form.filterData.levelOptionList.push({ text: '区县', value: 'District' })
      this.form.filterData.levelOptionList.push({ text: '农户', value: 'Farmer' })
      this.form.provinceId = provinceId
      this.bindProvinceSelectData()
      this.bindCitySelectData(provinceId)
      this.flags.disableProvinceSelect = true
    } else if ('City' === userLevelId) {
      this.form.filterData.levelOptionList.push({ text: '区县', value: 'District' })
      this.form.filterData.levelOptionList.push({ text: '农户', value: 'Farmer' })
      this.form.provinceId = provinceId
      this.form.cityId = cityId
      this.bindProvinceSelectData()
      this.bindCitySelectData(provinceId)
      this.bindDistrictSelectData(cityId)
      this.flags.disableProvinceSelect = true
      this.flags.disableCitySelect = true
    } else if ('District' === userLevelId) {
      this.form.filterData.levelOptionList.push({ text: '农户', value: 'Farmer' })
      this.form.levelId = 'Farmer'
      this.form.provinceId = provinceId
      this.form.cityId = cityId
      this.form.districtId = districtId
      this.flags.disableLevelSelect = true
      this.flags.disableProvinceSelect = true
      this.flags.disableCitySelect = true
      this.flags.disableDistrictSelect = true
      this.bindProvinceSelectData()
      this.bindCitySelectData(provinceId)
      this.bindDistrictSelectData(cityId)
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleLevelChange(selectedValue) {
      this.flags.isFarmer = 'Farmer' === selectedValue
      this.flags.isProfessionalCategory = 'Professional' === selectedValue
      this.flags.isProfessionalIndexDirectory = false
      this.form.filterData.organizationNameList = []

      if ('Country' === selectedValue) {
        this.form.rules.organization[0].required = false
        this.form.rules.provinceId[0].required = false
        this.form.rules.cityId[0].required = false
        this.form.rules.districtId[0].required = false
      } else if ('Professional' === selectedValue) {
        this.form.rules.organization[0].required = true
        this.form.rules.provinceId[0].required = false
        this.form.rules.cityId[0].required = false
        this.form.rules.districtId[0].required = false
      } else if ('Province' === selectedValue) {
        this.form.rules.organization[0].required = false
        this.form.rules.provinceId[0].required = true
        this.form.rules.cityId[0].required = false
        this.form.rules.districtId[0].required = false
      } else if ('City' === selectedValue) {
        this.form.rules.organization[0].required = false
        this.form.rules.provinceId[0].required = true
        this.form.rules.cityId[0].required = true
        this.form.rules.districtId[0].required = false
      } else if ('District' === selectedValue || 'Farmer' === selectedValue) {
        this.form.rules.organization[0].required = false
        this.form.rules.provinceId[0].required = true
        this.form.rules.cityId[0].required = true
        this.form.rules.districtId[0].required = true
      }

      this.form.organization = null
      this.form.professionalCategory = null

      this.form.farmerNature = null
      this.form.educationalLevel = null
      this.form.cultivationLandArea = null
      this.form.modelHouseholdFlag = null
    },
    handleProfessionalCategory(selectedValue) {
      this.form.organization = null
      this.form.filterData.organizationNameList = []
      this.flags.isProfessionalIndexDirectory = selectedValue.indexDirectoryFlag
      if (selectedValue.indexDirectoryFlag) {
        ProfessionalIndexDirectoryApi.getListByProfessionalCategoryId(selectedValue.id)
          .then(res => {
            res.data.forEach(el => {
              this.form.filterData.organizationNameList.push({ value: el.name })
            })
          })
          .catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          })
      }
    },
    handleProvinceChange(selectedValue) {
      this.form.districtId = null
      this.form.cityId = null
      this.bindDistrictSelectData(null)
      this.bindCitySelectData(selectedValue)
    },
    handleCityChange(selectedValue) {
      this.form.districtId = null
      this.bindDistrictSelectData(selectedValue)
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return false

        let createModel = {
          username: this.form.username,
          password: this.form.password,
          fullName: this.form.fullName,
          email: this.$utils.isEmpty(this.form.email) ? null : this.form.email,
          phoneNumber: this.form.phoneNumber,
          officePhone: this.$utils.isEmpty(this.form.officePhone) ? null : this.form.officePhone,
          detailedAddress: this.$utils.isEmpty(this.form.detailedAddress) ? null : this.form.detailedAddress,
          organization: this.$utils.isEmpty(this.form.organization) ? null : this.form.organization,
          levelId: this.form.levelId,
          professionalCategoryId: null === this.form.professionalCategory ? null : this.form.professionalCategory.id,
          regionId: null,
          farmerNature: this.form.farmerNature,
          educationalLevel: this.form.educationalLevel,
          cultivationLandArea: this.form.cultivationLandArea,
          modelHouseholdFlag: this.form.modelHouseholdFlag
        }
        if (this.$utils.isNotEmpty(this.form.districtId)) {
          createModel.regionId = this.form.districtId
        } else if (this.$utils.isNotEmpty(this.form.cityId)) {
          createModel.regionId = this.form.cityId
        } else if (this.$utils.isNotEmpty(this.form.provinceId)) {
          createModel.regionId = this.form.provinceId
        }

        UserApi.create(createModel)
          .then(() => {
            this.$message({ type: 'success', message: '保存成功！' })
            this.$emit('create-success')
            this.dialogVisible = false
          })
          .catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          })
      })
    },
    querySearch(queryString, cb) {
      if (null === this.form.professionalCategory) {
        return cb([])
      }

      if (null !== this.form.professionalCategory && this.form.professionalCategory.indexDirectoryFlag) {
        return cb(this.form.filterData.organizationNameList)
      }

      let restaurants = this.form.filterData.organizationNameList
      let results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        cb(results)
      }, 3000 * Math.random())
    },
    createStateFilter(queryString) {
      return state => {
        return state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    bindProvinceSelectData() {
      this.form.filterData.provinceOptionList = []
      this.form.filterData.provinceOptionList.push({ text: '请选择', value: null })
      RegionApi.getProvinceList().then(res => {
        res.data.forEach(el => {
          this.form.filterData.provinceOptionList.push({ text: el.name, value: el.id })
        })
      })
    },
    bindCitySelectData(provinceId) {
      this.form.filterData.cityOptionList = []
      this.form.filterData.cityOptionList.push({ text: '请选择', value: null })
      if (null === provinceId) return

      RegionApi.getCityList(provinceId)
        .then(res => {
          res.data.forEach(el => {
            this.form.filterData.cityOptionList.push({ text: el.name, value: el.id })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    bindDistrictSelectData(cityId) {
      this.form.filterData.districtOptionList = []
      this.form.filterData.districtOptionList.push({ text: '请选择', value: null })
      if (null === cityId) return

      RegionApi.getDistrictList(cityId)
        .then(res => {
          res.data.forEach(el => {
            this.form.filterData.districtOptionList.push({ text: el.name, value: el.id })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    open() {
      this.dialogVisible = true
    }
  }
}
</script>
