import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/questionnaires', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/questionnaires/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/questionnaires', updateModel)
  },
  submit(id) {
    return httpRequest.put('/questionnaire-submit', { id: id })
  },
  exitQuestionnaire(year) {
    let config = { params: { year: year } }
    return httpRequest.get('/questionnaire-exist', config)
  },
  getQuestionList() {
    return httpRequest.get('/questionnaire-questions')
  },
  getById(id) {
    return httpRequest.get('/questionnaires/' + id)
  },
  getList(year, regionId, keyword, submitFlag, assistFlag) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        keyword: keyword,
        submitFlag: submitFlag,
        assistFlag: assistFlag
      }
    }
    return httpRequest.get('/questionnaires', config)
  },
  getPaging(year, regionId, keyword, submitFlag, assistFlag, pageSize, pageIndex) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        keyword: keyword,
        submitFlag: submitFlag,
        assistFlag: assistFlag,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }
    return httpRequest.get('/questionnaire-paging', config)
  },
  importData(year, fileName) {
    let config = {
      params: {
        year: year,
        fileName: fileName
      }
    }
    return httpRequest.post('/questionnaire-import', null, config)
  },
  exportTemplate(exportId, year, regionId) {
    let config = {
      params: {
        exportId: exportId,
        year: year,
        regionId: regionId
      }
    }
    return httpRequest.post('/questionnaire-template-export', null, config)
  },
  exportData(exportId, year, regionId, submitFlag, assistFlag) {
    let config = {
      params: {
        exportId: exportId,
        year: year,
        regionId: regionId,
        submitFlag: submitFlag,
        assistFlag: assistFlag

      }
    }
    return httpRequest.get('/questionnaire-data-export', config)
  }
}
