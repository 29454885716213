<template>
  <div ref="rejectedPlantCropReadonlyDataPane" class="wg-box" style="margin-top: 0" v-loading="flags.dataLoading" element-loading-text="拼命加载数据......" element-loading-spinner="el-icon-loading">
    <div class="header">
      <div class="button-list">
        <el-button size="small" class="wg-button blue" icon="el-icon-full-screen" v-if="!isFullscreen" @click="handleFullscreen">全屏 </el-button>
        <el-button size="small" class="wg-button blue" icon="el-icon-full-screen" v-if="isFullscreen" @click="handleExtFullscreen">退出 </el-button>
      </div>
      <!-- button list -->
      <div class="wg-clear-fix"></div>
      <div class="filter-param-list">
        <el-row :gutter="10">
          <el-col :span="4">
            <el-input size="small" v-model="filterParams.keyword" @change="handleKeywordChange" :clearable="true" placeholder="农户姓名或电话">
              <template slot="prepend">关键字</template>
            </el-input>
          </el-col>
        </el-row>
      </div>
      <!-- filter param list -->
    </div>
    <div class="body">
      <div>
        <div class="wg-table-box">
          <el-table ref="table" :data="tableData" :max-height="tableHeight" row-key="plantCrop.id" class="wg-table normal" border>
            <el-table-column label="序号" type="index" width="100" sortable fixed></el-table-column>
            <el-table-column label="农户姓名" prop="farmer.fullName" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'fullName')" fixed></el-table-column>
            <el-table-column label="农户信息">
              <el-table-column label="省份" prop="farmer.provinceName" width="100" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'provinceName')"></el-table-column>
              <el-table-column label="地市" prop="farmer.cityName" width="100" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'cityName')"></el-table-column>
              <el-table-column label="区县" prop="farmer.districtName" width="100" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'districtName')"></el-table-column>
              <el-table-column label="详细地址" prop="farmer.detailedAddress" width="150" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'detailedAddress')"></el-table-column>
              <el-table-column label="电话" prop="farmer.phoneNumber" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'phoneNumber')"></el-table-column>
              <el-table-column label="农户性质" prop="farmer.farmerNatureName" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'farmerNatureName')"></el-table-column>
              <el-table-column label="文化程度" prop="farmer.educationalLevelName" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'educationalLevelName')"></el-table-column>
              <el-table-column prop="farmer.cultivationLandArea" width="170" sortable>
                <template slot="header">
                  经营面积
                  <el-tooltip content="经营面积指农户从事农业生产总的经营面积，包括耕地、园地，主要反映农户生产规模大小。" placement="top">
                    <i class="el-icon-question" style="font-size: 18px; color:#E6A23C"></i>
                  </el-tooltip>
                  <br />（含园地，亩）
                </template>
              </el-table-column>
              <el-table-column label="示范户" prop="farmer.modelHouseholdFlag" class-name="cell-tag" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'modelHouseholdName')">
                <template slot-scope="scope">
                  <div :style="null === scope.row.farmer.modelHouseholdFlag ? 'color:#333' : scope.row.farmer.modelHouseholdFlag ? 'color:#039F3A' : 'color:#FF8900'">
                    {{ scope.row.farmer.modelHouseholdName }}
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <!-- 农户信息 -->
            <el-table-column label="地块信息">
              <el-table-column label="地块名称" prop="monitoringPlot.name" width="120" sortable :sort-method="(a, b) => sortMonitoringPlotColumn(a, b, 'name')"></el-table-column>
              <el-table-column label="地块代码" prop="monitoringPlot.code" width="120" sortable :sort-method="(a, b) => sortMonitoringPlotColumn(a, b, 'code')"></el-table-column>
              <el-table-column prop="monitoringPlot.plotArea" width="120" sortable :sort-method="(a, b) => sortMonitoringPlotColumn(a, b, 'plotArea')">
                <template slot="header">地块面积<br />（亩）</template>
              </el-table-column>
            </el-table-column>
            <!-- 地块信息 -->
            <el-table-column label="种植信息">
              <el-table-column label="作物分类" width="120" prop="plantCrop.cropSubCategoryName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'cropSubCategoryName')"></el-table-column>
              <el-table-column label="作物名称" width="120" prop="plantCrop.cropName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'cropName')"></el-table-column>
              <el-table-column label="作物备注" width="120" prop="plantCrop.cropRemark" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'cropRemark')"></el-table-column>
              <el-table-column label="播种日期" width="150" prop="plantCrop.sowingDate" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'sowingDate')"></el-table-column>
              <el-table-column label="收获日期" width="150" prop="plantCrop.harvestTime" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'harvestTime')"></el-table-column>
              <el-table-column width="120" prop="plantCrop.sowingArea" sortable>
                <template slot="header">播种面积<br />（亩）</template>
              </el-table-column>
              <el-table-column label="地块肥力" width="120" prop="plantCrop.plotFertilityName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'plotFertilityName')"></el-table-column>
              <el-table-column width="120" prop="plantCrop.yield" sortable>
                <template slot="header">亩产量<br />（公斤/亩）</template>
              </el-table-column>
              <el-table-column label="产量水平" width="130" prop="plantCrop.yieldLevelName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'yieldLevelName')">
                <template slot="header">
                  产量水平
                  <el-tooltip content="产量水平指该作物产量相对当地水平如何。" placement="top">
                    <i class="el-icon-question" style="font-size: 18px; color:#E6A23C"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="plantCrop.facilityCultivationName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'facilityCultivationName')">
                <template slot="header">是否<br />设施栽培</template>
                <template slot-scope="scope">
                  <div :style="null === scope.row.plantCrop.facilityCultivationFlag ? 'color:#333' : scope.row.plantCrop.facilityCultivationFlag ? 'color:#039F3A' : 'color:#FF8900'">
                    {{ scope.row.plantCrop.facilityCultivationName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="plantCrop.happenDisasterName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'happenDisasterName')">
                <template slot="header">是否<br />种植季受灾</template>
                <template slot-scope="scope">
                  <div :style="null === scope.row.plantCrop.happenDisasterFlag ? 'color:#333' : scope.row.plantCrop.happenDisasterFlag ? 'color:#039F3A' : 'color:#FF8900'">
                    {{ scope.row.plantCrop.happenDisasterName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="前茬作物" width="120" prop="plantCrop.previousCropSubCategoryName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'previousCropSubCategoryName')"></el-table-column>
              <el-table-column width="120" prop="plantCrop.previousYield" sortable>
                <template slot="header">前茬作物<br />亩产量<br />（公斤/亩）</template>
                <template slot-scope="scope">
                  <div>{{ scope.row.plantCrop.previousYield }}</div>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="plantCrop.previousStrawReturnRate" sortable>
                <template slot="header">前茬秸秆<br />还田率<br />（%）</template>
                <template slot-scope="scope">
                  <div>{{ scope.row.plantCrop.previousStrawReturnRate }}</div>
                </template>
              </el-table-column>
            </el-table-column>
            <!-- 种植作物 -->
            <el-table-column label="底肥（公斤/亩）">
              <el-table-column width="120" prop="baseFertilizer.fertilizationModeName" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'fertilizationModeName')">
                <template slot="header">底肥<br />施肥方式</template>
              </el-table-column>
              <el-table-column label="施肥日期" prop="baseFertilizer.fertilizationDate" width="150" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'fertilizationDate')"></el-table-column>
              <el-table-column label="商用有机肥">
                <el-table-column label="用量" prop="baseFertilizer.businessOrganicFertilizerDosage" width="100" sortable></el-table-column>
                <el-table-column width="120" prop="baseFertilizer.businessOrganicFertilizerPrice" sortable>
                  <template slot="header">价格<br />（元/吨）</template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="农家肥1（干重）">
                <el-table-column label="名称" width="100" prop="baseFertilizer.organicFertilizer1Name" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'organicFertilizer1Name')"></el-table-column>
                <el-table-column label="用量" width="100" prop="baseFertilizer.organicFertilizer1Dosage" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="农家肥2（干重）">
                <el-table-column label="名称" width="100" prop="baseFertilizer.organicFertilizer2Name" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'organicFertilizer2Name')"></el-table-column>
                <el-table-column label="用量" width="100" prop="baseFertilizer.organicFertilizer2Dosage" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="尿素" width="100" prop="baseFertilizer.urea" sortable></el-table-column>
              <el-table-column label="碳铵" width="100" prop="baseFertilizer.ammoniumBicarbonate" sortable></el-table-column>
              <el-table-column label="磷酸二铵" width="100" prop="baseFertilizer.diammoniumPhosphate" sortable></el-table-column>
              <el-table-column label="氯化钾" width="100" prop="baseFertilizer.potassiumChloride" sortable></el-table-column>
              <el-table-column label="硫酸钾" width="100" prop="baseFertilizer.potassiumSulphate" sortable></el-table-column>
              <el-table-column label="复合肥（含配方肥）及养分%">
                <el-table-column label="用量" width="100" prop="baseFertilizer.compoundFertilizerDosage" sortable></el-table-column>
                <el-table-column label="N" width="100" prop="baseFertilizer.compoundFertilizerN" sortable></el-table-column>
                <el-table-column label="P2O5" width="100" prop="baseFertilizer.compoundFertilizerP2O5" sortable></el-table-column>
                <el-table-column label="K2O" width="100" prop="baseFertilizer.compoundFertilizerK2O" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="水溶肥及养分%">
                <el-table-column label="用量" width="100" prop="baseFertilizer.waterSolubleFertilizerDosage" sortable></el-table-column>
                <el-table-column label="N" width="100" prop="baseFertilizer.waterSolubleFertilizerN" sortable></el-table-column>
                <el-table-column label="P2O5" width="100" prop="baseFertilizer.waterSolubleFertilizerP2O5" sortable></el-table-column>
                <el-table-column label="K2O" width="100" prop="baseFertilizer.waterSolubleFertilizerK2O" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="其他肥料1">
                <el-table-column label="名称" width="100" prop="baseFertilizer.otherFertilizer1Name" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'otherFertilizer1Name')"></el-table-column>
                <el-table-column label="用量" width="100" prop="baseFertilizer.otherFertilizer1Dosage" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="其他肥料2">
                <el-table-column label="名称" width="100" prop="baseFertilizer.otherFertilizer2Name" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'otherFertilizer2Name')"></el-table-column>
                <el-table-column label="用量" width="100" prop="baseFertilizer.otherFertilizer2Dosage" sortable></el-table-column>
              </el-table-column>
            </el-table-column>
            <!-- 底肥 -->
            <el-table-column width="120" align="center" prop="plantCrop.afterFertilizerFillInModeName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'afterFertilizerFillInModeName')">
              <template slot="header">追肥是否<br />分次填报</template>
            </el-table-column>
            <!-- 追肥是否分次填报 -->
            <el-table-column label="追肥合计（公斤/亩）">
              <el-table-column label="主要施肥方式" width="150" prop="afterFertilizerTotal.fertilizationModeName" sortable :sort-method="(a, b) => sortAfterFertilizerTotalColumn(a, b, 'fertilizationModeName')"></el-table-column>
              <el-table-column label="追肥次数" width="150" prop="afterFertilizerTotal.afterFertilizationTimes" sortable></el-table-column>
              <el-table-column label="首次追肥日期" width="150" prop="afterFertilizerTotal.afterFertilizationDate" sortable :sort-method="(a, b) => sortAfterFertilizerTotalColumn(a, b, 'afterFertilizationDate')"></el-table-column>
              <el-table-column label="商品有机肥用量" width="150" prop="afterFertilizerTotal.businessOrganicFertilizerDosage" sortable></el-table-column>
              <el-table-column label="尿素" width="100" prop="afterFertilizerTotal.urea" sortable></el-table-column>
              <el-table-column label="碳铵" width="100" prop="afterFertilizerTotal.ammoniumBicarbonate" sortable></el-table-column>
              <el-table-column label="磷酸二铵" width="120" prop="afterFertilizerTotal.diammoniumPhosphate" sortable></el-table-column>
              <el-table-column label="氯化钾" width="100" prop="afterFertilizerTotal.potassiumChloride" sortable></el-table-column>
              <el-table-column label="硫酸钾" width="100" prop="afterFertilizerTotal.potassiumSulphate" sortable></el-table-column>
              <el-table-column label="复合肥（含配方肥）及养分%">
                <el-table-column label="用量" width="100" prop="afterFertilizerTotal.compoundFertilizerDosage" sortable></el-table-column>
                <el-table-column label="N" width="100" prop="afterFertilizerTotal.compoundFertilizerN" sortable></el-table-column>
                <el-table-column label="P2O5" width="100" prop="afterFertilizerTotal.compoundFertilizerP2O5" sortable></el-table-column>
                <el-table-column label="K2O" width="100" prop="afterFertilizerTotal.compoundFertilizerK2O" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="水溶肥及养分%">
                <el-table-column label="用量" width="100" prop="afterFertilizerTotal.waterSolubleFertilizerDosage" sortable></el-table-column>
                <el-table-column label="N" width="100" prop="afterFertilizerTotal.waterSolubleFertilizerN" sortable></el-table-column>
                <el-table-column label="P2O5" width="100" prop="afterFertilizerTotal.waterSolubleFertilizerP2O5" sortable></el-table-column>
                <el-table-column label="K2O" width="100" prop="afterFertilizerTotal.waterSolubleFertilizerK2O" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="其他肥料1">
                <el-table-column label="名称" width="120" prop="afterFertilizerTotal.otherFertilizer1Name" sortable :sort-method="(a, b) => sortAfterFertilizerTotalColumn(a, b, 'otherFertilizer1Name')"></el-table-column>
                <el-table-column label="用量" width="100" prop="afterFertilizerTotal.otherFertilizer1Dosage" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="其他肥料2">
                <el-table-column label="名称" width="120" prop="afterFertilizerTotal.otherFertilizer2Name" sortable :sort-method="(a, b) => sortAfterFertilizerTotalColumn(a, b, 'otherFertilizer2Name')"></el-table-column>
                <el-table-column label="用量" width="100" prop="afterFertilizerTotal.otherFertilizer2Dosage" sortable></el-table-column>
              </el-table-column>
            </el-table-column>
            <!-- 追肥合计 -->
            <el-table-column label="追肥分次">
              <el-table-column v-for="(number, index) in [1, 2, 3, 4, 5]" :label="'追肥' + number + '（公斤/亩）'" :key="index">
                <el-table-column label="施肥方式" width="120" :prop="'afterFertilizerList.' + index + '.fertilizationModeName'" sortable :sort-method="(a, b) => sortAfterFertilizerColumn(a, b, index, 'fertilizationModeName')"></el-table-column>
                <el-table-column label="追肥日期" width="150" :prop="'afterFertilizerList.' + index + '.afterFertilizationDate'" sortable :sort-method="(a, b) => sortAfterFertilizerColumn(a, b, index, 'afterFertilizationDate')"></el-table-column>
                <el-table-column label="商品有机肥用量" width="120" :prop="'afterFertilizerList.' + index + '.businessOrganicFertilizerDosage'" sortable></el-table-column>
                <el-table-column label="尿素" width="100" :prop="'afterFertilizerList.' + index + '.urea'" sortable></el-table-column>
                <el-table-column label="碳铵" width="100" :prop="'afterFertilizerList.' + index + '.ammoniumBicarbonate'" sortable></el-table-column>
                <el-table-column label="磷酸二铵" width="100" :prop="'afterFertilizerList.' + index + '.diammoniumPhosphate'" sortable></el-table-column>
                <el-table-column label="氯化钾" width="100" :prop="'afterFertilizerList.' + index + '.potassiumChloride'" sortable></el-table-column>
                <el-table-column label="硫酸钾" width="100" :prop="'afterFertilizerList.' + index + '.potassiumSulphate'" sortable></el-table-column>
                <el-table-column label="复合肥（含配方肥）及养分%">
                  <el-table-column label="用量" width="100" :prop="'afterFertilizerList.' + index + '.compoundFertilizerDosage'" sortable></el-table-column>
                  <el-table-column label="N" width="100" :prop="'afterFertilizerList.' + index + '.compoundFertilizerN'" sortable></el-table-column>
                  <el-table-column label="P2O5" width="100" :prop="'afterFertilizerList.' + index + '.compoundFertilizerP2O5'" sortable></el-table-column>
                  <el-table-column label="K2O" width="100" :prop="'afterFertilizerList.' + index + '.compoundFertilizerK2O'" sortable></el-table-column>
                </el-table-column>
                <el-table-column label="水溶肥及养分%">
                  <el-table-column label="用量" width="100" :prop="'afterFertilizerList.' + index + '.waterSolubleFertilizerDosage'" sortable></el-table-column>
                  <el-table-column label="N" width="100" :prop="'afterFertilizerList.' + index + '.waterSolubleFertilizerN'" sortable></el-table-column>
                  <el-table-column label="P2O5" width="100" :prop="'afterFertilizerList.' + index + '.waterSolubleFertilizerP2O5'" sortable></el-table-column>
                  <el-table-column label="K2O" width="100" :prop="'afterFertilizerList.' + index + '.waterSolubleFertilizerK2O'" sortable></el-table-column>
                </el-table-column>
                <el-table-column label="其他肥料1">
                  <el-table-column label="名称" width="120" :prop="'afterFertilizerList.' + index + '.otherFertilizer1Name'" sortable :sort-method="(a, b) => sortAfterFertilizerColumn(a, b, index, 'otherFertilizer1Name')"></el-table-column>
                  <el-table-column label="用量" width="100" :prop="'afterFertilizerList.' + index + '.otherFertilizer1Dosage'" sortable></el-table-column>
                </el-table-column>
                <el-table-column label="其他肥料2">
                  <el-table-column label="名称" width="120" :prop="'afterFertilizerList.' + index + '.otherFertilizer2Name'" sortable :sort-method="(a, b) => sortAfterFertilizerColumn(a, b, index, 'otherFertilizer2Name')"></el-table-column>
                  <el-table-column label="用量" width="100" :prop="'afterFertilizerList.' + index + '.otherFertilizer2Dosage'" sortable></el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <!-- 追肥 1 ~ 5 -->
            <el-table-column label="单位面积化肥养分投入量（公斤/亩）" v-if="!flags.enableEdit">
              <el-table-column label="氮（N）" width="100" prop="unitAreaChemicalFertilizerDosage.nitrogen" sortable>
                <template slot-scope="scope">
                  {{ null === scope.row.unitAreaChemicalFertilizerDosage.nitrogen ? null : scope.row.unitAreaChemicalFertilizerDosage.nitrogen.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="磷（P2O5）" width="130" prop="unitAreaChemicalFertilizerDosage.phosphorus" sortable>
                <template slot-scope="scope">
                  {{ null === scope.row.unitAreaChemicalFertilizerDosage.phosphorus ? null : scope.row.unitAreaChemicalFertilizerDosage.phosphorus.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="钾（K2O）" width="120" prop="unitAreaChemicalFertilizerDosage.potassium" sortable>
                <template slot-scope="scope">
                  {{ null === scope.row.unitAreaChemicalFertilizerDosage.potassium ? null : scope.row.unitAreaChemicalFertilizerDosage.potassium.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="总养分" width="120" prop="unitAreaChemicalFertilizerDosage.total" sortable>
                <template slot-scope="scope">
                  {{ null === scope.row.unitAreaChemicalFertilizerDosage.total ? null : scope.row.unitAreaChemicalFertilizerDosage.total.toFixed(2) }}
                </template>
              </el-table-column>
            </el-table-column>
            <!-- 单位面积化肥养分投入量 -->
            <el-table-column label="记录情况" width="200" v-if="!flags.enableEdit">
              <template slot-scope="scope">
                <el-popover placement="left-start" width="260" trigger="hover">
                  <ul class="table-column-record">
                    <li>创建时间：{{ scope.row.plantCrop.createdOn }}</li>
                    <li>修改时间：{{ scope.row.plantCrop.modifiedOn }}</li>
                    <li>提交时间：{{ scope.row.plantCrop.submittedOn }}</li>
                  </ul>
                  <i class="el-icon-view" slot="reference"></i>
                </el-popover>
                &nbsp;&nbsp;
                {{ scope.row.plantCrop.createdOn }}
              </template>
            </el-table-column>
            <!-- 记录情况 -->
            <el-table-column label="退回情况" v-if="!flags.enableEdit">
              <el-table-column label="情况" width="100">
                <template slot-scope="scope">
                  <el-popover placement="left-start" width="260" trigger="hover">
                    <ul class="table-column-record">
                      <li>国家级退回时间：{{ scope.row.plantCrop.countryRejectedOn }}</li>
                      <li>国家级退回次数：{{ scope.row.plantCrop.countryRejectTimes }}</li>
                      <li>省级退回时间：{{ scope.row.plantCrop.provinceRejectedOn }}</li>
                      <li>省级退回次数：{{ scope.row.plantCrop.provinceRejectTimes }}</li>
                      <li>地市退回时间：{{ scope.row.plantCrop.cityRejectedOn }}</li>
                      <li>地市退回次数：{{ scope.row.plantCrop.provinceRejectTimes }}</li>
                    </ul>
                    <i class="el-icon-view" slot="reference"></i>
                  </el-popover>
                  {{ scope.row.plantCrop.countryRejectFlag ? '国家' : '' }}
                  {{ scope.row.plantCrop.provinceRejectFlag ? ' >> 省级' : '' }}
                  {{ scope.row.plantCrop.cityRejectFlag ? ' >> 地市' : '' }}
                </template>
              </el-table-column>
              <el-table-column label="退回原因" fixed="right" width="80">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="handleRejectReason(scope.row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table-column>
            <!-- 退回情况 -->
          </el-table>
        </div>
      </div>
      <div class="wg-pagination-box">
        <el-pagination background layout="total, sizes, prev, pager, next" :total="recordTotal" :page-sizes="[30, 50, 100]" :page-size="pageSize" :current-page="pageIndex" @size-change="handleSizeChange" @current-change="handlePagingChange"></el-pagination>
      </div>
    </div>
    <rejected-plant-crop-reason-dialog ref="rejectedPlantCropReasonDialog" v-if="flags.loadRejectedPlantCropReasonDialog" @close="handleRejectedPlantCropReasonDialogDialogClose"></rejected-plant-crop-reason-dialog>
  </div>
</template>

<script>
import Screenfull from 'screenfull'

import RejectPlantCropApi from '@/api/reject-plant-crop-api'
import RejectedPlantCropReasonDialog from './RejectedPlantCropReasonDialog'

export default {
  name: 'RejectedPlantCropReadonlyData',
  components: {
    'rejected-plant-crop-reason-dialog': RejectedPlantCropReasonDialog
  },
  data() {
    return {
      tableHeight: 600,
      isFullscreen: false,
      params: {
        year: null,
        regionId: null
      },
      flags: {
        dataLoading: false,
        loadRejectedPlantCropReasonDialog: false
      },
      filterParams: {
        keyword: null
      },
      columnFilterData: {
        cropOptionList: []
      },
      tableData: [],
      recordTotal: 0,
      pageSize: 30,
      pageIndex: 1
    }
  },
  created() {},
  mounted() {
    let that = this
    Screenfull.on('change', function () {
      that.$nextTick(() => {
        that.isFullscreen = Screenfull.isFullscreen
        that.tableHeight = Screenfull.isFullscreen ? window.innerHeight - 160 : '600px'
      })
    })
  },
  destroy() {
    if (Screenfull.enabled) {
      Screenfull.off('change', this.change)
    }
  },
  methods: {
    handleRejectedPlantCropReasonDialogDialogClose() {
      this.flags.loadRejectedPlantCropReasonDialog = false
    },
    handleKeywordChange() {
      this.pageIndex = 1
      this.bindTableData()
    },
    handleFullscreen() {
      if (!Screenfull.isEnabled) {
        this.$message({
          message: '浏览器不支持',
          type: 'warning'
        })
        return false
      }

      Screenfull.request(this.$refs.rejectedPlantCropReadonlyDataPane)
    },
    handleExtFullscreen() {
      if (!Screenfull.isEnabled) {
        this.$message({
          message: '浏览器不支持',
          type: 'warning'
        })
        return false
      }

      Screenfull.exit()
    },
    handleRejectReason(row) {
      this.flags.loadRejectedPlantCropReasonDialog = true
      this.$nextTick(() => {
        this.$refs.rejectedPlantCropReasonDialog.open(row.plantCrop.id)
      })
    },
    handleSizeChange(pageSize) {
      this.pageIndex = 1
      this.pageSize = pageSize
      this.bindTableData()
    },
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
      this.bindTableData()
    },
    bindTableData() {
      this.flags.dataLoading = true
      RejectPlantCropApi.getPaging(this.params.year, this.params.regionId, this.filterParams.keyword, true, this.pageSize, this.pageIndex)
        .then(res => {
          let index = 0
          this.tableData = []
          this.recordTotal = res.data.totalCount
          this.exportPageCount = Math.ceil(res.data.totalCount / 5000)

          let cropArray = []
          this.columnFilterData.cropOptionList = []

          res.data.plantCropList.forEach(el => {
            let farmerModel = el.farmer
            let monitoringPlotModel = el.monitoringPlot
            let plantCropModel = el.plantCrop
            let baseFertilizerModel = el.baseFertilizer
            let afterFertilizerModelList = el.afterFertilizerList
            let unitAreaChemicalFertilizerDosageModel = el.unitAreaChemicalFertilizerDosage

            if (undefined === cropArray[plantCropModel.cropId]) cropArray[plantCropModel.cropId] = plantCropModel.cropName

            let row = {
              index: ++index,
              editFlag: false,
              farmer: this.mappingFarmer(undefined, farmerModel),
              monitoringPlot: this.mappingMonitoringPlot(undefined, monitoringPlotModel),
              plantCrop: this.mappingPlantCrop(undefined, plantCropModel),
              baseFertilizer: this.mappingBaseFertilizer(undefined, baseFertilizerModel),
              afterFertilizerTotal: this.mappingAfterFertilizerTotal(undefined),
              afterFertilizerList: [],
              unitAreaChemicalFertilizerDosage: {
                nitrogen: unitAreaChemicalFertilizerDosageModel.nitrogen,
                phosphorus: unitAreaChemicalFertilizerDosageModel.phosphorus,
                potassium: unitAreaChemicalFertilizerDosageModel.potassium,
                total: unitAreaChemicalFertilizerDosageModel.total
              }
            }
            let afterFertilizerCount = 0
            if ('TOTAL' === plantCropModel.afterFertilizerFillInMode) {
              if (1 === afterFertilizerModelList.length) {
                let afterFertilizerTotalModel = afterFertilizerModelList[0]
                this.mappingAfterFertilizerTotal(row.afterFertilizerTotal, afterFertilizerTotalModel)
              }
            } else if ('MANY_TIMES' === plantCropModel.afterFertilizerFillInMode) {
              afterFertilizerModelList.forEach(elAfterFertilizer => {
                row.afterFertilizerList.push(this.mappingAfterFertilizer(undefined, elAfterFertilizer))
                ++afterFertilizerCount
              })
            }
            for (; afterFertilizerCount < 5; ++afterFertilizerCount) {
              row.afterFertilizerList.push(this.mappingAfterFertilizer(undefined))
            }
            this.tableData.push(row)
          })

          for (let key in cropArray) {
            this.columnFilterData.cropOptionList.push({
              text: cropArray[key],
              value: key
            })
          }

          this.flags.dataLoading = false
        })
        .catch(err => {
          this.flags.dataLoading = false
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    sortFarmerColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.farmer[fieldName], row2.farmer[fieldName])
    },
    sortMonitoringPlotColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.monitoringPlot[fieldName], row2.monitoringPlot[fieldName])
    },
    sortPlantCropColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.plantCrop[fieldName], row2.plantCrop[fieldName])
    },
    sortBaseFertilizerColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.baseFertilizer[fieldName], row2.baseFertilizer[fieldName])
    },
    sortAfterFertilizerTotalColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.afterFertilizerTotal[fieldName], row2.afterFertilizerTotal[fieldName])
    },
    sortAfterFertilizerColumn(row1, row2, index, fieldName) {
      return this.$utils.sortColumn(row1.afterFertilizerList[index][fieldName], row2.afterFertilizerList[index][fieldName])
    },
    mappingFarmer(farmer, model) {
      if (undefined === farmer || null === farmer) {
        farmer = {
          id: null,
          provinceId: null,
          provinceName: null,
          cityId: null,
          cityName: null,
          districtId: null,
          districtName: null,
          detailedAddress: null,
          fullName: null,
          phoneNumber: null,
          farmerNature: null,
          farmerNatureName: null,
          educationalLevel: null,
          educationalLevelName: null,
          cultivationLandArea: null,
          modelHouseholdFlag: null,
          modelHouseholdName: null
        }
      }
      if (undefined !== model && null !== model) {
        farmer.id = model.id
        farmer.provinceId = model.provinceId
        farmer.provinceName = model.provinceName
        farmer.cityId = model.cityId
        farmer.cityName = model.cityName
        farmer.districtId = model.districtId
        farmer.districtName = model.districtName
        farmer.detailedAddress = model.detailedAddress
        farmer.fullName = model.fullName
        farmer.phoneNumber = model.phoneNumber
        farmer.farmerNature = model.farmerNature
        farmer.farmerNatureName = model.farmerNatureName
        farmer.educationalLevel = model.educationalLevel
        farmer.educationalLevelName = model.educationalLevelName
        farmer.cultivationLandArea = model.cultivationLandArea
        farmer.modelHouseholdFlag = model.modelHouseholdFlag
        farmer.modelHouseholdName = null === model.modelHouseholdFlag ? '--' : model.modelHouseholdFlag ? '是' : '否'
      }
      return farmer
    },
    mappingMonitoringPlot(monitoringPlot, model) {
      if (undefined === monitoringPlot || null === monitoringPlot) {
        monitoringPlot = {
          id: null,
          name: null,
          code: null,
          plotArea: null
        }
      }
      if (undefined !== model && null !== model) {
        monitoringPlot.id = model.id
        monitoringPlot.name = model.name
        monitoringPlot.code = model.code
        monitoringPlot.plotArea = model.plotArea
      }
      return monitoringPlot
    },
    mappingPlantCrop(plantCrop, model) {
      if (undefined === plantCrop || null === plantCrop) {
        plantCrop = {
          id: null,
          cropSubCategoryId: null,
          cropSubCategoryName: null,
          cropId: null,
          cropName: null,
          cropRemark: null,
          sowingDate: null,
          harvestTime: null,
          sowingArea: null,
          plotFertility: null,
          plotFertilityName: null,
          yield: null,
          yieldLevel: null,
          yieldLevelName: null,
          facilityCultivationFlag: null,
          facilityCultivationName: null,
          happenDisasterFlag: null,
          happenDisasterName: null,
          previousCropSubCategoryId: null,
          previousCropSubCategoryName: null,
          previousYield: null,
          previousStrawReturnRate: null,
          submitFlag: null,
          submittedOn: null,
          afterFertilizerFillInMode: null,
          afterFertilizerFillInModeName: null,
          createdOn: null,
          createdByFullName: null,
          modifiedOn: null,
          modifiedByFullName: null,
          districtAuditFlag: false,
          districtAuditTime: null,
          cityAuditFlag: false,
          cityAuditTime: null,
          provinceAuditFlag: false,
          provinceAuditTime: null,
          cityRejectFlag: null,
          cityRejectTimes: null,
          cityRejectedOn: null,
          cityRejectedByFullName: null,
          provinceRejectFlag: null,
          provinceRejectTimes: null,
          provinceRejectedOn: null,
          provinceRejectedByFullName: null,
          countryRejectFlag: null,
          countryRejectTimes: null,
          countryRejectedOn: null,
          countryRejectedByFullName: null
        }
      }
      if (undefined !== model && null !== model) {
        plantCrop.id = model.id
        plantCrop.cropRootCategoryId = model.cropRootCategoryId
        plantCrop.cropRootCategoryName = model.cropRootCategoryName
        plantCrop.cropSubCategoryId = model.cropSubCategoryId
        plantCrop.cropSubCategoryName = model.cropSubCategoryName
        plantCrop.cropId = model.cropId
        plantCrop.cropName = model.cropName
        plantCrop.cropRemark = model.cropRemark
        plantCrop.sowingDate = this.$utils.isEmpty(model.sowingDate) ? null : this.$moment(model.sowingDate).format('YYYY-MM-DD')
        plantCrop.harvestTime = this.$utils.isEmpty(model.harvestTime) ? null : this.$moment(model.harvestTime).format('YYYY-MM-DD')
        plantCrop.sowingArea = model.sowingArea
        plantCrop.plotFertility = model.plotFertility
        plantCrop.plotFertilityName = model.plotFertilityName
        plantCrop.yield = model.yield
        plantCrop.yieldLevel = model.yieldLevel
        plantCrop.yieldLevelName = model.yieldLevelName
        plantCrop.facilityCultivationFlag = model.facilityCultivationFlag
        plantCrop.facilityCultivationName = null === model.facilityCultivationFlag ? '--' : model.facilityCultivationFlag ? '是' : '否'
        plantCrop.happenDisasterFlag = model.happenDisasterFlag
        plantCrop.happenDisasterName = null === model.happenDisasterFlag ? '--' : model.happenDisasterFlag ? '是' : '否'
        plantCrop.previousCropSubCategoryId = model.previousCropSubCategoryId
        plantCrop.previousCropSubCategoryName = model.previousCropSubCategoryName
        plantCrop.previousYield = model.previousYield
        plantCrop.previousStrawReturnRate = model.previousStrawReturnRate
        plantCrop.submitFlag = model.submitFlag
        plantCrop.submittedOn = model.submittedOn
        plantCrop.afterFertilizerFillInMode = model.afterFertilizerFillInMode
        switch (model.afterFertilizerFillInMode) {
          case 'NONE':
            plantCrop.afterFertilizerFillInModeName = '无追肥'
            break
          case 'MANY_TIMES':
            plantCrop.afterFertilizerFillInModeName = '分次填报'
            break
          case 'TOTAL':
            plantCrop.afterFertilizerFillInModeName = '合计填报'
            break
          default:
            plantCrop.afterFertilizerFillInModeName = '--'
            break
        }
        plantCrop.createdOn = model.createdOn
        plantCrop.createdByFullName = model.createdByFullName
        plantCrop.modifiedOn = model.modifiedOn
        plantCrop.modifiedByFullName = model.modifiedByFullName
        plantCrop.districtAuditFlag = model.districtAuditFlag
        plantCrop.districtAuditedOn = model.districtAuditedOn
        plantCrop.districtAuditedByFullName = model.districtAuditedByFullName
        plantCrop.cityAuditFlag = model.cityAuditFlag
        plantCrop.cityAuditedOn = model.cityAuditedOn
        plantCrop.cityAuditedByFullName = model.cityAuditedByFullName
        plantCrop.provinceAuditFlag = model.provinceAuditFlag
        plantCrop.provinceAuditedOn = model.provinceAuditedOn
        plantCrop.provinceAuditedByFullName = model.provinceAuditedByFullName
        plantCrop.cityRejectFlag = model.cityRejectFlag
        plantCrop.cityRejectTimes = model.cityRejectTimes
        plantCrop.cityRejectedOn = model.cityRejectedOn
        plantCrop.cityRejectedByFullName = model.cityRejectedByFullName
        plantCrop.provinceRejectFlag = model.provinceRejectFlag
        plantCrop.provinceRejectTimes = model.provinceRejectTimes
        plantCrop.provinceRejectedOn = model.provinceRejectedOn
        plantCrop.provinceRejectedByFullName = model.provinceRejectedByFullName
        plantCrop.countryRejectFlag = model.countryRejectFlag
        plantCrop.countryRejectTimes = model.countryRejectTimes
        plantCrop.countryRejectedOn = model.countryRejectedOn
        plantCrop.countryRejectedByFullName = model.countryRejectedByFullName
      }
      return plantCrop
    },
    mappingBaseFertilizer(baseFertilizer, model) {
      if (undefined === baseFertilizer || null === baseFertilizer) {
        baseFertilizer = {
          id: null,
          fertilizationDate: null,
          businessOrganicFertilizerDosage: null,
          businessOrganicFertilizerPrice: null,
          organicFertilizer1Id: null,
          organicFertilizer1Name: null,
          organicFertilizer1Dosage: null,
          organicFertilizer2Id: null,
          organicFertilizer2Name: null,
          organicFertilizer2Dosage: null,
          urea: null,
          ammoniumBicarbonate: null,
          diammoniumPhosphate: null,
          potassiumChloride: null,
          potassiumSulphate: null,
          compoundFertilizerDosage: null,
          compoundFertilizerN: null,
          compoundFertilizerP2O5: null,
          compoundFertilizerK2O: null,
          waterSolubleFertilizerDosage: null,
          waterSolubleFertilizerN: null,
          waterSolubleFertilizerP2O5: null,
          waterSolubleFertilizerK2O: null,
          otherFertilizer1Id: null,
          otherFertilizer1Name: null,
          otherFertilizer1Dosage: null,
          otherFertilizer2Id: null,
          otherFertilizer2Name: null,
          otherFertilizer2Dosage: null,
          fertilizationMode: null,
          fertilizationModeName: null
        }
      }
      if (undefined !== model && null !== model) {
        baseFertilizer.id = model.id
        baseFertilizer.fertilizationDate = this.$utils.isEmpty(model.fertilizationDate) ? null : this.$moment(model.fertilizationDate).format('YYYY-MM-DD')
        baseFertilizer.businessOrganicFertilizerDosage = model.businessOrganicFertilizerDosage
        baseFertilizer.businessOrganicFertilizerPrice = model.businessOrganicFertilizerPrice
        baseFertilizer.organicFertilizer1Id = model.organicFertilizer1Id
        baseFertilizer.organicFertilizer1Name = model.organicFertilizer1Name
        baseFertilizer.organicFertilizer1Dosage = model.organicFertilizer1Dosage
        baseFertilizer.organicFertilizer2Id = model.organicFertilizer2Id
        baseFertilizer.organicFertilizer2Name = model.organicFertilizer2Name
        baseFertilizer.organicFertilizer2Dosage = model.organicFertilizer2Dosage
        baseFertilizer.urea = model.urea
        baseFertilizer.ammoniumBicarbonate = model.ammoniumBicarbonate
        baseFertilizer.diammoniumPhosphate = model.diammoniumPhosphate
        baseFertilizer.potassiumChloride = model.potassiumChloride
        baseFertilizer.potassiumSulphate = model.potassiumSulphate
        baseFertilizer.compoundFertilizerDosage = model.compoundFertilizerDosage
        baseFertilizer.compoundFertilizerN = model.compoundFertilizerN
        baseFertilizer.compoundFertilizerP2O5 = model.compoundFertilizerP2O5
        baseFertilizer.compoundFertilizerK2O = model.compoundFertilizerK2O
        baseFertilizer.waterSolubleFertilizerDosage = model.waterSolubleFertilizerDosage
        baseFertilizer.waterSolubleFertilizerN = model.waterSolubleFertilizerN
        baseFertilizer.waterSolubleFertilizerP2O5 = model.waterSolubleFertilizerP2O5
        baseFertilizer.waterSolubleFertilizerK2O = model.waterSolubleFertilizerK2O
        baseFertilizer.otherFertilizer1Id = model.otherFertilizer1Id
        baseFertilizer.otherFertilizer1Name = model.otherFertilizer1Name
        baseFertilizer.otherFertilizer1Dosage = model.otherFertilizer1Dosage
        baseFertilizer.otherFertilizer2Id = model.otherFertilizer2Id
        baseFertilizer.otherFertilizer2Name = model.otherFertilizer2Name
        baseFertilizer.otherFertilizer2Dosage = model.otherFertilizer2Dosage
        baseFertilizer.fertilizationMode = model.fertilizationMode
        baseFertilizer.fertilizationModeName = model.fertilizationModeName
      }
      return baseFertilizer
    },
    mappingAfterFertilizerTotal(afterFertilizerTotal, model) {
      if (undefined === afterFertilizerTotal || null === afterFertilizerTotal) {
        afterFertilizerTotal = {
          id: null,
          plantCropId: null,
          afterFertilizationTimes: null,
          afterFertilizationDate: null,
          businessOrganicFertilizerDosage: null,
          urea: null,
          ammoniumBicarbonate: null,
          diammoniumPhosphate: null,
          potassiumChloride: null,
          potassiumSulphate: null,
          compoundFertilizerDosage: null,
          compoundFertilizerN: null,
          compoundFertilizerP2O5: null,
          compoundFertilizerK2O: null,
          waterSolubleFertilizerDosage: null,
          waterSolubleFertilizerN: null,
          waterSolubleFertilizerP2O5: null,
          waterSolubleFertilizerK2O: null,
          otherFertilizer1Id: null,
          otherFertilizer1Name: null,
          otherFertilizer1Dosage: null,
          otherFertilizer2Id: null,
          otherFertilizer2Name: null,
          otherFertilizer2Dosage: null,
          fertilizationMode: null,
          fertilizationModeName: null
        }
      }
      if (undefined !== model && null !== model) {
        afterFertilizerTotal.id = model.id
        afterFertilizerTotal.plantCropId = model.plantCropId
        afterFertilizerTotal.afterFertilizationTimes = model.afterFertilizationTimes
        afterFertilizerTotal.afterFertilizationDate = this.$utils.isEmpty(model.afterFertilizationDate) ? null : this.$moment(model.afterFertilizationDate).format('YYYY-MM-DD')
        afterFertilizerTotal.businessOrganicFertilizerDosage = model.businessOrganicFertilizerDosage
        afterFertilizerTotal.urea = model.urea
        afterFertilizerTotal.ammoniumBicarbonate = model.ammoniumBicarbonate
        afterFertilizerTotal.diammoniumPhosphate = model.diammoniumPhosphate
        afterFertilizerTotal.potassiumChloride = model.potassiumChloride
        afterFertilizerTotal.potassiumSulphate = model.potassiumSulphate
        afterFertilizerTotal.compoundFertilizerDosage = model.compoundFertilizerDosage
        afterFertilizerTotal.compoundFertilizerN = model.compoundFertilizerN
        afterFertilizerTotal.compoundFertilizerP2O5 = model.compoundFertilizerP2O5
        afterFertilizerTotal.compoundFertilizerK2O = model.compoundFertilizerK2O
        afterFertilizerTotal.waterSolubleFertilizerDosage = model.waterSolubleFertilizerDosage
        afterFertilizerTotal.waterSolubleFertilizerN = model.waterSolubleFertilizerN
        afterFertilizerTotal.waterSolubleFertilizerP2O5 = model.waterSolubleFertilizerP2O5
        afterFertilizerTotal.waterSolubleFertilizerK2O = model.waterSolubleFertilizerK2O
        afterFertilizerTotal.otherFertilizer1Id = model.otherFertilizer1Id
        afterFertilizerTotal.otherFertilizer1Name = model.otherFertilizer1Name
        afterFertilizerTotal.otherFertilizer1Dosage = model.otherFertilizer1Dosage
        afterFertilizerTotal.otherFertilizer2Id = model.otherFertilizer2Id
        afterFertilizerTotal.otherFertilizer2Name = model.otherFertilizer2Name
        afterFertilizerTotal.otherFertilizer2Dosage = model.otherFertilizer2Dosage
        afterFertilizerTotal.fertilizationMode = model.fertilizationMode
        afterFertilizerTotal.fertilizationModeName = model.fertilizationModeName
      }
      return afterFertilizerTotal
    },
    mappingAfterFertilizer(afterFertilizer, model) {
      if (undefined === afterFertilizer || null === afterFertilizer) {
        afterFertilizer = {
          id: null,
          afterFertilizationTimes: null,
          afterFertilizationDate: null,
          businessOrganicFertilizerDosage: null,
          urea: null,
          ammoniumBicarbonate: null,
          diammoniumPhosphate: null,
          potassiumChloride: null,
          potassiumSulphate: null,
          compoundFertilizerDosage: null,
          compoundFertilizerN: null,
          compoundFertilizerP2O5: null,
          compoundFertilizerK2O: null,
          waterSolubleFertilizerDosage: null,
          waterSolubleFertilizerN: null,
          waterSolubleFertilizerP2O5: null,
          waterSolubleFertilizerK2O: null,
          otherFertilizer1Id: null,
          otherFertilizer1Name: null,
          otherFertilizer1Dosage: null,
          otherFertilizer2Id: null,
          otherFertilizer2Name: null,
          otherFertilizer2Dosage: null,
          fertilizationMode: null,
          fertilizationModeName: null
        }
      }
      if (undefined !== model && null !== model) {
        afterFertilizer.id = model.id
        afterFertilizer.afterFertilizationTimes = model.afterFertilizationTimes
        afterFertilizer.afterFertilizationDate = this.$utils.isEmpty(model.afterFertilizationDate) ? null : this.$moment(model.afterFertilizationDate).format('YYYY-MM-DD')
        afterFertilizer.businessOrganicFertilizerDosage = model.businessOrganicFertilizerDosage
        afterFertilizer.urea = model.urea
        afterFertilizer.ammoniumBicarbonate = model.ammoniumBicarbonate
        afterFertilizer.diammoniumPhosphate = model.diammoniumPhosphate
        afterFertilizer.potassiumChloride = model.potassiumChloride
        afterFertilizer.potassiumSulphate = model.potassiumSulphate
        afterFertilizer.compoundFertilizerDosage = model.compoundFertilizerDosage
        afterFertilizer.compoundFertilizerN = model.compoundFertilizerN
        afterFertilizer.compoundFertilizerP2O5 = model.compoundFertilizerP2O5
        afterFertilizer.compoundFertilizerK2O = model.compoundFertilizerK2O
        afterFertilizer.waterSolubleFertilizerDosage = model.waterSolubleFertilizerDosage
        afterFertilizer.waterSolubleFertilizerN = model.waterSolubleFertilizerN
        afterFertilizer.waterSolubleFertilizerP2O5 = model.waterSolubleFertilizerP2O5
        afterFertilizer.waterSolubleFertilizerK2O = model.waterSolubleFertilizerK2O
        afterFertilizer.otherFertilizer1Id = model.otherFertilizer1Id
        afterFertilizer.otherFertilizer1Name = model.otherFertilizer1Name
        afterFertilizer.otherFertilizer1Dosage = model.otherFertilizer1Dosage
        afterFertilizer.otherFertilizer2Id = model.otherFertilizer2Id
        afterFertilizer.otherFertilizer2Name = model.otherFertilizer2Name
        afterFertilizer.otherFertilizer2Dosage = model.otherFertilizer2Dosage
        afterFertilizer.fertilizationMode = model.fertilizationMode
        afterFertilizer.fertilizationModeName = model.fertilizationModeName
      }
      return afterFertilizer
    },
    load(year, regionId) {
      this.params.year = year
      this.params.regionId = regionId
      this.bindTableData()
    }
  }
}
</script>

<style scoped></style>