<template>
  <div class="wg-box">
    <div class="body">
      <div class="wg-table-box">
        <el-table :data="tableData" class="wg-table normal" border>
          <el-table-column prop="nitrogen" label="氮（N）"></el-table-column>
          <el-table-column prop="phosphorus" label="磷（P2O5）"></el-table-column>
          <el-table-column prop="potassium" label="钾（K2O）"></el-table-column>
          <el-table-column prop="total" label="总养分"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnitAreaChemicalFertilizerDosage',
  props: {
    fullPlantCropModel: { type: Object, default: null }
  },
  data() {
    return {
      tableData: []
    }
  },
  created() {
    this.tableData.push({
      nitrogen: null,
      phosphorus: null,
      potassium: null,
      total: null
    })

    this.loadData()
  },
  methods: {
    loadData() {
      if (null !== this.fullPlantCropModel.unitAreaChemicalFertilizerDosage) {
        let unitAreaChemicalFertilizerModel = this.fullPlantCropModel.unitAreaChemicalFertilizerDosage
        let row = this.tableData[0]
        row.nitrogen = null === unitAreaChemicalFertilizerModel.nitrogen ? null : unitAreaChemicalFertilizerModel.nitrogen.toFixed(2)
        row.phosphorus = null === unitAreaChemicalFertilizerModel.phosphorus ? null : unitAreaChemicalFertilizerModel.phosphorus.toFixed(2)
        row.potassium = null === unitAreaChemicalFertilizerModel.potassium ? null : unitAreaChemicalFertilizerModel.potassium.toFixed(2)
        row.total = null === unitAreaChemicalFertilizerModel.total ? null : unitAreaChemicalFertilizerModel.total.toFixed(2)
      }
    }
  }
}
</script>
