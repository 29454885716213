import httpRequest from '../utils/http-request'

export default {
  update(updateModel) {
    return httpRequest.put('/full-plant-crops', updateModel)
  },
  getById(id) {
    return httpRequest.get('/full-plant-crops/' + id)
  },
  getOwnList(year, plotId) {
    let config = { params: { year: year, plotId: plotId } }
    return httpRequest.get('/own-full-plant-crops', config)
  },
  getUnitAreaChemicalFertilizerDosage(plantCropId) {
    let config = { params: { plantCropId: plantCropId } }
    return httpRequest.get('/unit-area-chemical-fertilizer-dosages', config)
  }
}
