<template>
  <div>
    <SoilFertility ref="soilfertility" :params="params"></SoilFertility>
    <div class="chart-divider"></div>
    <BaseFertilizationMethod ref="basefertilizationmethod" :params="params"></BaseFertilizationMethod>
    <div class="chart-divider"></div>
    <AfterFertilizationMethod ref="afterfertilizationmethod" :params="params"></AfterFertilizationMethod>
  </div>
</template>

<script>
import SoilFertility from '@/views/chart-analysis/components/planting/SoilFertility'
import BaseFertilizationMethod from '@/views/chart-analysis/components/planting/BaseFertilizationMethod'
import AfterFertilizationMethod from '@/views/chart-analysis/components/planting/AfterFertilizationMethod.vue'
export default {
  name: "ChartGroup-Planting",
  components: {
    SoilFertility,
    BaseFertilizationMethod,
    AfterFertilizationMethod
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  methods: {
    refreshCharts() {
      this.$nextTick(() => {
        this.$refs.soilfertility.refresh()
        this.$refs.basefertilizationmethod.refresh()
        this.$refs.afterfertilizationmethod.refresh()
      })
    }
  }
}
</script>

<style scoped>

.el-card {
  border: 1px solid #D6D6D6;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
  border-radius: 0px 0px 4px 4px;
}
.el-card ::v-deep .el-card__header {
  padding: 2px 10px;
  height: 50px;
}
.el-card ::v-deep .el-card__body {
  padding: 0px;
  background: #FFFFFF;
  border: 0.5px solid #D6D6D6;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
  border-radius: 0px 0px 4px 4px;
}
</style>
