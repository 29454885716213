<template>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" :lock-scroll="false" @close="handleClose">
    <div>
      <el-card class="box-card">
        <el-upload name="file" ref="fileUpload" :action="actionUrl" :data="uploadParams" :auto-upload="false" :before-upload="handleUploadBeforeUpload" :on-success="handleUploadSuccess">
          <el-button slot="trigger" size="small" type="primary">点击上传</el-button>
          <el-button size="small" style="margin-left: 10px;" class="wg-button" @click="handleStartImport">开始导入
          </el-button>
          <div slot="tip" class="el-upload__tip" style="margin-top: 10px">提示：上传文件格式限定（xlsx）</div>
        </el-upload>
      </el-card>
    </div>
    <div style="margin-top:10px">
      <el-card class="box-card" v-if="importState === 'ERROR'">
        <el-button size="small" class="wg-button" @click="handleDownloadErrorReport">下载错误报告</el-button>
        <div style="margin-top: 10px;" v-if="infoList.length > 0">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item :title="'第' + info.lineNumber + '行错误列表：'" :name="info.lineNumber" :key="info.lineNumber" v-for="info in infoList">
              <ul class="info-list">
                <li :key="index" v-for="(message, index) in info.messageList">{{ message }}</li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>

      </el-card>
      <el-card class="box-card" v-if="importState === 'SUCCESS'">
        <div style="margin-top: 10px;">
          <el-alert title="数据导入成功！" type="success" :closable="false" center show-icon></el-alert>
        </div>
        <div style="margin-top: 10px;" v-if="infoList.length > 0">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item :title="info.lineNumber === 0 ? '总体信息列表：' : '第' + info.lineNumber + '行信息列表：'" :name="info.lineNumber" :key="info.lineNumber" v-for="info in infoList">
              <ul class="info-list">
                <li :key="index" v-for="(message, index) in info.messageList">{{ message }}</li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-card>
    </div>
    <div slot="footer">
      <el-button size="small" class="wg-button blank" @click="dialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FileApi from '@/api/file-api'
import AssistFillInDataApi from '@/api/data-fill-in/assist/assist-fill-in-data-api'

export default {
  name: 'UploadAssistFillInDataFileDialog',
  props: [],
  data() {
    return {
      dialogTitle: '文件上传',
      dialogVisible: false,
      activeName: 0,
      actionUrl: null,
      year: null,
      uploadParams: { category: 'UPLOAD_TEMP' },
      importState: '',
      infoList: []
    }
  },
  created() {
    this.actionUrl = FileApi.getUploadURI()
  },
  methods: {
    handleClose() {
      this.$emit('close', this.importState)
    },
    handleStartImport() {
      this.importState = ''
      this.infoList = []
      this.$refs.fileUpload.submit()
    },
    handleUploadBeforeUpload(file) {
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel') {
        this.$message({ type: 'error', message: '上传文件格式错误！' })
        return false
      }
    },
    handleUploadSuccess(res) {
      let loading = this.$loading({
        lock: true,
        text: '等待导入结果......',
        spinner: 'el-icon-loading'
      })

      setTimeout(() => {
      }, 10000)
      AssistFillInDataApi.importData(this.year, res.savedFileName)
        .then(res => {
          this.importState = res.data.state
          if (res.data.state === 'ERROR') {
            this.infoList = res.data.importRowResultList
          }
          if (res.data.state == 'SUCCESS') {

            this.infoList = res.data.importRowResultList
          }
          loading.close()
        })
        .catch(err => {
          loading.close()
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        }).finally(() => {
        this.$refs.fileUpload.clearFiles()
      })
    },
    handleDownloadErrorReport() {
      this.$confirm('下载错误报告，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let textArray = []
        this.infoList.forEach(el => {
          textArray.push('第' + el.lineNumber + '行错误列表：\n')
          el.messageList.forEach(message => {
            textArray.push(message + '\n')
          })
          textArray.push('\n')
        })

        let blob = new Blob(textArray, { type: 'text/plain' })
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', '错误报告.txt')
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(link.href)
        document.body.removeChild(link)
      })
    },
    async open(year) {
      this.year = year
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped>
.info-list {
  margin: 0;
  padding: 0;
}

.info-list li {
  margin: 0;
  padding: 0;
}
</style>
