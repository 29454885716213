<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">数据填报</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/farmer-fill-in' }">农户填报</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <el-container>
      <el-aside class="wg-side" :width="isCollapse ? '24px' : '240px'">
        <div v-show="!isCollapse">
          <div class="side-fence">
            农户填报
            <i class="el-icon-d-arrow-left collapse-button" @click="handleToggleCollapse"></i>
          </div>
          <el-tree ref="farmerFillInTree" :data="treeData" :props="treeProps" node-key="id" class="wg-tree"
                   :default-expanded-keys="treeDefaultExpendedKey" :expand-on-click-node="false" :load="loadNode"
                   :indent="0" highlight-current lazy @node-click="handleNodeClick"></el-tree>
        </div>
        <div v-show="isCollapse" class="collapse-fence">
          展开菜单栏
          <div class="collapse-icon">
            <i class="el-icon-d-arrow-right" @click="handleToggleCollapse"></i>
          </div>
        </div>
      </el-aside>
      <el-main class="wg-main">
        <el-empty v-if="showDataEmpty" description="请选择地块！" :image-size="200"></el-empty>
        <div v-else>
          <farmer-fill-in-collapse :year="checkedItem.year" :monitoringPlotId="checkedItem.monitoringPlotId"
                                   v-if="flags.isLoadFarmerFillInCollapseComponent"></farmer-fill-in-collapse>
          <archived-fill-in-collapse :year="checkedItem.year" :monitoringPlotId="checkedItem.monitoringPlotId"
                                     v-if="flags.isLoadArchivedFillInCollapseComponent"></archived-fill-in-collapse>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import FillInCycleApi from '@/api/data-fill-in/cycle/fill-in-cycle-api'
import MonitoringPlotApi from '@/api/monitoring-plot-api'
import ArchivedMonitoringPlotApi from '@/api/data-fill-in/archive/archived-monitoring-plot-api'

import FarmerFillInCollapse from './components/FarmerFillInCollapse'
import ArchivedFillInCollapse from './components/archive/ArchivedFillInCollapse'

export default {
  name: 'FarmerFillIn',
  components: {
    'farmer-fill-in-collapse': FarmerFillInCollapse,
    'archived-fill-in-collapse': ArchivedFillInCollapse
  },
  data() {
    return {
      isCollapse: false,
      showDataEmpty: true,
      flags: {
        isLoadFarmerFillInCollapseComponent: false,
        isLoadArchivedFillInCollapseComponent: false
      },
      treeProps: {
        label: 'label',
        children: 'monitoringPlotList',
        isLeaf: 'leaf'
      },
      treeData: [],
      treeDefaultExpendedKey: [],
      checkedItem: {
        year: null,
        archiveFlag: null,
        monitoringPlotId: null
      }
    }
  },
  created() {
    FillInCycleApi.getLast().then(res => {
      this.checkedItem.year = res.data.year
      this.checkedItem.archiveFlag = res.data.archiveFlag
    })
  },
  methods: {
    handleToggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    handleNodeClick(nodeData) {
      this.checkedItem.year = nodeData.year
      this.checkedItem.archiveFlag = nodeData.archiveFlag
      this.checkedItem.monitoringPlotId = nodeData.monitoringPlotId

      if ('Year' === nodeData.type) {
        this.showDataEmpty = true
        this.flags.isLoadFarmerFillInCollapseComponent = false
      }

      if ('AddMonitoringPlot' === nodeData.type) {
        this.$router.push({path: '/plot'})
      }

      if ('MonitoringPlot' === nodeData.type) {
        this.showDataEmpty = false
        this.flags.isLoadFarmerFillInCollapseComponent = !this.checkedItem.archiveFlag
        this.flags.isLoadArchivedFillInCollapseComponent = this.checkedItem.archiveFlag
      }
    },
    async loadNode(node, resolve) {
      if (node.level === 0) {
        let rootArray = []
        let fillInCycleList = []

        await FillInCycleApi.getList().then(res => {
          fillInCycleList = res.data
        })

        fillInCycleList.forEach(el => {
          let tempNode = {
            id: el.year,
            type: 'Year',
            label: el.archiveFlag ? el.year + '年（已归档）' : el.year + '年',
            leaf: false,
            year: el.year,
            archiveFlag: el.archiveFlag,
            monitoringPlotId: null
          }

          rootArray.push(tempNode)
        })

        this.$nextTick(async () => {
          this.$refs.farmerFillInTree.setCurrentKey(rootArray[0].id)
          this.treeDefaultExpendedKey.push(rootArray[0].id)
        })

        return resolve(rootArray)
      }

      if ('Year' === node.data.type) {
        let monitoringPlotList = []
        if (node.data.archiveFlag) {
          await ArchivedMonitoringPlotApi.getOwnList(node.data.year).then(res => {
            res.data.forEach(el => {
              monitoringPlotList.push({
                type: 'MonitoringPlot',
                id: node.data.year + el.id,
                label: el.name,
                leaf: true,
                year: node.data.year,
                archiveFlag: node.data.archiveFlag,
                monitoringPlotId: el.id
              })
            })
          })
        } else {
          monitoringPlotList.push({
            id: node.data.year + '-none',
            type: 'AddMonitoringPlot',
            label: '新增地块',
            leaf: true,
            year: node.data.year,
            archiveFlag: node.data.archiveFlag,
            monitoringPlotId: null
          })

          await MonitoringPlotApi.getOwnList(false).then(res => {
            res.data.forEach(el => {
              monitoringPlotList.push({
                type: 'MonitoringPlot',
                id: node.data.year + el.id,
                label: el.name,
                leaf: true,
                year: node.data.year,
                archiveFlag: node.data.archiveFlag,
                monitoringPlotId: el.id
              })
            })
          })
        }

        return resolve(monitoringPlotList)
      }
    }
  }
}
</script>

<style scoped></style>
