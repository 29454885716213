import httpRequest from '@/utils/http-request'

export default {
  getFillInStatisticData(year, provinceId) {
    let config = {
      params: {
        year: year,
        provinceId: provinceId
      }
    }
    return httpRequest.get('/province-fill-in-statistic-data', config)
  },
  exportData(year, provinceId) {
    let config = {
      params: {
        year: year,
        provinceId: provinceId
      }
    }
    return httpRequest.post('/province-data-fill-in-statistic-export', null, config)
  }
}