<template>
  <div>
    <el-tabs v-model="activeName" class="wg-tabs" @tab-click="handleTabClick">
      <el-tab-pane name="unauditedPane">
        <span slot="label">待审核</span>
        <archived-unaudited-plant-crop-data ref="archivedUnauditedPlantCropData" v-if="flags.isLoadArchivedUnauditedPlantCropDataComponent"></archived-unaudited-plant-crop-data>
      </el-tab-pane>
      <el-tab-pane name="auditedPane">
        <span slot="label">已审核</span>
        <archived-audited-plant-crop-data ref="archivedAuditedPlantCropData" v-if="flags.isLoadArchivedAuditedPlantCropDataComponent"></archived-audited-plant-crop-data>
      </el-tab-pane>
      <el-tab-pane name="rejectedPane" v-if="flags.allowReject">
        <span slot="label">
          上级已退回
          <el-badge :value="rejectedCount"></el-badge>
        </span>
        <archived-up-level-rejected-plant-crop-data
          ref="archivedUpLevelRejectedPlantCropData"
          v-if="flags.isLoadArchivedUpLevelRejectedPlantCropDataComponent"
        ></archived-up-level-rejected-plant-crop-data>
      </el-tab-pane>
      <el-tab-pane name="statisticPane">
        <span slot="label">数据统计</span>
        <data-fill-in-statistic ref="dataFillInStatistic" v-if="flags.isLoadDataFillInStatisticComponent"></data-fill-in-statistic>
      </el-tab-pane>
      <el-tab-pane name="rejectedReadonlyPane">
        <span slot="label">数据退回</span>
        <archived-rejected-plant-crop-data ref="archivedRejectedPlantCropReadonlyData" v-if="flags.isLoadArchivedRejectedPlantCropDataComponent"></archived-rejected-plant-crop-data>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import RejectPlantCropApi from '@/api/reject-plant-crop-api'

import ArchivedUnauditedPlantCropData from './ArchivedUnauditedPlantCropData'
import ArchivedAuditedPlantCropData from './ArchivedAuditedPlantCropData'
import ArchivedUpLevelRejectedPlantCropData from './ArchivedUpLevelRejectedPlantCropData'
import DataFillInStatistic from '../DataFillInStatistic'
import ArchivedRejectedPlantCropData from './ArchivedRejectedPlantCropData'

export default {
  name: 'ArchivedDataAuditTab',
  components: {
    'archived-unaudited-plant-crop-data': ArchivedUnauditedPlantCropData,
    'archived-audited-plant-crop-data': ArchivedAuditedPlantCropData,
    'archived-up-level-rejected-plant-crop-data': ArchivedUpLevelRejectedPlantCropData,
    'data-fill-in-statistic': DataFillInStatistic,
    'archived-rejected-plant-crop-data': ArchivedRejectedPlantCropData
  },
  props: {
    year: Number,
    regionId: String
  },
  data() {
    return {
      flags: {
        allowReject: false,
        isLoadArchivedUnauditedPlantCropDataComponent: false,
        isLoadArchivedAuditedPlantCropDataComponent: false,
        isLoadArchivedUpLevelRejectedPlantCropDataComponent: false,
        isLoadDataFillInStatisticComponent: false,
        isLoadArchivedRejectedPlantCropDataComponent: false
      },
      rejectedCount: 0,
      activeName: 'unauditedPane'
    }
  },
  created() {
    let userLevelId = sessionStorage.getItem('levelId')
    this.flags.allowReject = userLevelId === 'Province' || userLevelId === 'City' || userLevelId === 'District'

    this.flags.isLoadArchivedUnauditedPlantCropDataComponent = true

    this.$nextTick(() => {
      this.loadComponent(this.activeName)
    })

    RejectPlantCropApi.getCount(this.year, this.regionId, null).then(res => {
      this.rejectedCount = res.data
    })
  },
  watch: {
    year() {
      this.loadComponent(this.activeName)
    },
    regionId() {
      this.loadComponent(this.activeName)
    }
  },
  methods: {
    handleTabClick(tab) {
      this.flags.isLoadArchivedUnauditedPlantCropDataComponent = tab.name === 'unauditedPane'
      this.flags.isLoadArchivedAuditedPlantCropDataComponent = tab.name === 'auditedPane'
      this.flags.isLoadArchivedUpLevelRejectedPlantCropDataComponent = tab.name === 'rejectedPane'
      this.flags.isLoadDataFillInStatisticComponent = tab.name === 'statisticPane'
      this.flags.isLoadArchivedRejectedPlantCropDataComponent = tab.name === 'rejectedReadonlyPane'

      this.$nextTick(() => {
        this.loadComponent(tab.name)
      })
    },
    loadComponent(name) {
      switch (name) {
        case 'unauditedPane':
          this.$refs.archivedUnauditedPlantCropData.load(this.year, this.regionId)
          break
        case 'auditedPane':
          this.$refs.archivedAuditedPlantCropData.load(this.year, this.regionId)
          break
        case 'rejectedPane':
          this.$refs.archivedUpLevelRejectedPlantCropData.load(this.year, this.regionId)
          break
        case 'statisticPane':
          this.$refs.dataFillInStatistic.load(this.year, this.regionId)
          break
        case 'rejectedReadonlyPane':
          this.$refs.archivedRejectedPlantCropReadonlyData.load(this.year, this.regionId)
          break
        default:
          this.$refs.archivedUnauditedPlantCropData.load(this.year, this.regionId)
          break
      }
    },
    reloadComponent() {
      this.loadComponent(this.activeName)
    },
    getRejectedCount(rejectedCount) {
      this.rejectedCount = rejectedCount
    }
  }
}
</script>

<style scoped></style>
