<template>
  <div>
    <monitoring-plot ref="monitoringPlot"></monitoring-plot>
    <el-button class="new-crop-button" @click="handleAddPlantCrop" icon="el-icon-circle-plus-outline">新增作物</el-button>
    <div style="margin-top: 20px" v-for="(item, index) in collapseData" :key="item.plantCrop.id" :name="index">
      <el-collapse class="wg-collapse" v-model="collapseActiveNames">
        <el-collapse-item :name="item.plantCrop.id">
          <template slot="title">
            <div class="header background-style1">
              <div class="title">{{ item.plantCrop.cropSubCategoryName }}总体情况</div>
              <div class="state" :style="item.plantCrop.submitFlag ? 'color:#039F3A' : null === item.plantCrop.modifiedOn ? 'color:#00a333' : 'color:#FF8900'">
                {{ item.plantCrop.submitFlag ? '已提交' : null === item.plantCrop.modifiedOn ? '已新增' : '已保存' }}
              </div>
            </div>
            <div class="wg-clear-fix"></div>
          </template>
          <plant-crop ref="plantCrop" :fullPlantCropModel="item" :cropRootCategoryArray="cropRootCategoryArray" :cropSubCategoryArray="cropSubCategoryArray" :cropArray="cropArray" @delete="handlePlantCropDelete"></plant-crop>
        </el-collapse-item>
      </el-collapse>
    </div>
    <crop-category-select-dialog ref="cropCategorySelectDialog" :cropRootCategoryArray="cropRootCategoryArray" :cropSubCategoryArray="cropSubCategoryArray" @confirm="handleCropCategorySelectConfirm"></crop-category-select-dialog>
  </div>
</template>

<script>
import CropCategoryApi from '@/api/crop/crop-category-api'
import CropApi from '@/api/crop/crop-api'
import FullPlantCropApi from '@/api/full-plant-crop-api'
import PlantCropApi from '@/api/plant-crop-api'

import MonitoringPlot from './MonitoringPlot'
import PlantCrop from './ProfessionalPlantCrop'
import CropCategorySelectDialog from './CropCategorySelectDialog.vue'

export default {
  name: 'ProfessionalFillInCollapse',
  props: {
    year: Number,
    monitoringPlotId: String
  },
  components: {
    'crop-category-select-dialog': CropCategorySelectDialog,
    'monitoring-plot': MonitoringPlot,
    'plant-crop': PlantCrop
  },
  data() {
    return {
      isCollapse: false,
      collapseActiveNames: [],
      collapseData: [],
      cropRootCategoryArray: [],
      cropSubCategoryArray: [],
      cropArray: []
    }
  },
  watch: {
    year() {
      this.bindCollapseData()
    },
    monitoringPlotId() {
      this.bindCollapseData()
    }
  },
  async created() {
    await CropCategoryApi.getRootStubList(null).then(res => {
      res.data.forEach(el => {
        this.cropRootCategoryArray.push({ id: el.id, name: el.name })
      })
    })

    await CropCategoryApi.getSubStubList(null).then(res => {
      res.data.forEach(el => {
        this.cropSubCategoryArray.push({ id: el.id, name: el.name, parentId: el.parentId })
      })
    })

    await CropApi.getStubList(null, null).then(res => {
      res.data.forEach(el => {
        this.cropArray.push({
          id: el.id,
          name: el.name,
          rootCategoryId: el.rootCategoryId,
          subCategoryId: el.subCategoryId
        })
      })
    })

    this.$nextTick(() => {
      this.$refs.monitoringPlot.load(this.monitoringPlotId)
    })
    this.bindCollapseData()
  },
  methods: {
    handleAddPlantCrop() {
      this.$refs.cropCategorySelectDialog.open()
    },
    handleCropCategorySelectConfirm(checkedList) {
      if (checkedList.length > 0) {
        checkedList.forEach(async el => {
          await this.createPlantCrop(el)
          this.bindCollapseData()
        })
      }
    },
    handlePlantCropDelete() {
      this.bindCollapseData()
    },
    bindCollapseData() {
      FullPlantCropApi.getOwnList(this.year, this.monitoringPlotId)
        .then(res => {
          this.collapseData = []
          res.data.forEach(el => {
            if (null === el.plantCrop.modifiedOn) this.collapseActiveNames.push(el.plantCrop.id)
            this.collapseData.push(el)
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async createPlantCrop(cropCategory) {
      let createModel = {
        year: this.year,
        monitoringPlotId: this.monitoringPlotId,
        cropRootCategoryId: cropCategory.rootCategoryId,
        cropRootCategoryName: cropCategory.rootCategoryName,
        cropSubCategoryId: cropCategory.subCategoryId,
        cropSubCategoryName: cropCategory.subCategoryName,
        cropId: null,
        cropName: null,
        cropRemark: null,
        sowingDate: null,
        harvestTime: null,
        sowingArea: null,
        plotFertility: null,
        yield: null,
        yieldLevel: null,
        facilityCultivationFlag: null,
        happenDisasterFlag: null,
        previousCropSubCategoryId: null,
        previousCropSubCategoryName: null,
        previousYield: null,
        previousStrawReturnRate: null,
        remark1: null,
        remark2: null,
        afterFertilizerFillInMode: null
      }

      await PlantCropApi.create(createModel)
        .then(res => {
          this.collapseActiveNames.push(res.data)
          this.$message({ showClose: true, message: cropCategory.subCategoryName + '新增成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped>
.new-crop-button {
  margin: 10px 0;
  padding: 6px 0;
  color: #ff9601;
  border: 1px dashed #ff9601;
  width: 100%;
}

.new-crop-button:hover,
.new-crop-button:active,
.new-crop-button:focus {
  color: #ff9601;
  background-color: #fff;
  border: 1px dashed #ff9601;
}
</style>
