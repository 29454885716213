import httpRequest from '@/utils/http-request'

export default {
  getSurveyCount(year) {
    let config = {
      params: {
        year: year,
      }
    }
    return httpRequest.get('/survey-count', config)
  },
  getSurveyRegionSummaries(year) {
    let config = {
      params: {
        year: year
      }
    }
    return httpRequest.get('/survey-region-summaries', config)
  },
  getSurveyOrganicFertilizerSummaries(startYear, endYear) {
    let config = {
      params: {
        startYear: startYear,
        endYear: endYear
      }
    }
    return httpRequest.get('/survey-organic-fertilizer-summaries', config)
  },
  getSurveyChemicalFertilizerSummaries(startYear, endYear) {
    let config = {
      params: {
        startYear: startYear,
        endYear: endYear
      }
    }
    return httpRequest.get('/survey-chemical-fertilizer-summaries', config)
  },
  getSurveyCropSummaryList(startYear, endYear) {
    let config = {
      params: {
        startYear: startYear,
        endYear: endYear
      }
    }
    return httpRequest.get('/survey-crop-summaries', config)
  },
  getUnitSowingAreaFertilizerDosageList(year) {
    let config = {
      params: {
        year: year
      }
    }
    return httpRequest.get('/unit-sowing-area-fertilizer-dosages', config)
  },
  exportSurveyCropSummaries(exportId, startYear, endYear) {
    let config = {
      params: {
        exportId: exportId,
        startYear: startYear,
        endYear: endYear
      }
    }
    return httpRequest.post('/survey-crop-summaries/export', null, config)
  },
  exportUnitSowingAreaFertilizerDosages(exportId, year) {
    let config = {
      params: {
        exportId: exportId,
        year: year
      }
    }
    return httpRequest.post('/unit-sowing-area-fertilizer-dosages/export', null, config)
  }
}
