import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/organic-fertilizers', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/organic-fertilizers/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/organic-fertilizers', updateModel)
  },
  getById(id) {
    return httpRequest.get('/organic-fertilizers/' + id)
  },
  getList(name, startDate, endDate) {
    let config = { params: { name: name, startDate: startDate, endDate: endDate } }
    return httpRequest.get('/organic-fertilizers', config)
  },
  getStubList() {
    return httpRequest.get('/organic-fertilizer-stubs')
  }
}
