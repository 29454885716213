<template>
  <div id="app">
    <el-container>
      <el-header style="padding: 0; height: auto">
        <div class="top">
          <label class="label-welcome">您好，欢迎进入{{ regionName }}农户施肥信息监测系统！</label>
          <div class="link-big-data" style="display:none" v-if="showBigScreenLink">点击进入&nbsp;<el-link :underline="false" href="/bigscreen1.html" rel="opener" target="_blank"> 大数据 </el-link></div>
        </div>
        <!-- top -->
        <div class="logo-weather">
          <el-image class="img-logo" :src="logoUrl" alt="LOGO"></el-image>
          <div class="system-name">
            <div class="cn" style="margin-top: 5px; font-size: 42px"><router-link to="/">{{ regionName }}农户施肥信息监测系统</router-link></div>
            <div class="en" style="display: none"><router-link to="/">National Farmers Fertilization Information Monitoring System</router-link></div>
          </div>
          <div class="plug-in-weather">
            <div id="he-plugin-standard"></div>
          </div>
        </div>
        <!-- logo and weather -->
        <div class="main-nav-box">
          <el-menu :default-active="activeNav" mode="horizontal" @select="handleNavSelect" class="main-nav" background-color="#00953a" text-color="#d8d8d8" active-text-color="white" router>
            <el-menu-item v-if="menuEnable.index" index="/">首页</el-menu-item>
            <!-- dashboard -->
            <el-submenu index="DataProvided" v-if="menuEnable.dataFill.farmerFillIn || menuEnable.dataFill.professionalFillIn || menuEnable.dataFill.assistFillIn || menuEnable.dataFill.surfaceSurvey || menuEnable.dataFill.questionnaire">
              <template slot="title">数据填报</template>
              <el-menu-item index="/farmer-fill-in" v-if="menuEnable.dataFill.farmerFillIn">农户填报</el-menu-item>
              <el-menu-item index="/professional-fill-in" v-if="menuEnable.dataFill.professionalFillIn">专业用户填报 </el-menu-item>
              <el-menu-item index="/assist-fill-in" v-if="menuEnable.dataFill.assistFillIn">辅助填报</el-menu-item>
              <el-menu-item index="/surface-survey" v-if="menuEnable.dataFill.surfaceSurvey">面上调查</el-menu-item>
              <el-menu-item index="/questionnaire" v-if="menuEnable.dataFill.questionnaire">施肥问卷</el-menu-item>
              <el-menu-item index="/assist-questionnaire" v-if="menuEnable.dataFill.questionnaireAssist">施肥问卷 </el-menu-item>
            </el-submenu>
            <el-menu-item index="/plot" v-if="menuEnable.abm.plot">地块管理</el-menu-item>
            <!-- data provided -->
            <el-menu-item index="/data-audit" v-if="menuEnable.dataAudit">数据审核</el-menu-item>
            <!-- data auditing -->
            <el-submenu index="ChartAnalysis" v-if="menuEnable.chartAnalysis.dataVisualization || menuEnable.chartAnalysis.annualAnalysis" style="display: none;">
              <template slot="title">图表分析</template>
              <el-menu-item index="/data-visualization" v-if="menuEnable.chartAnalysis.dataVisualization">数据可视化 </el-menu-item>
              <el-menu-item index="/annual-analysis" v-if="menuEnable.chartAnalysis.annualAnalysis">年度分析 </el-menu-item>
            </el-submenu>
            <!-- chart analysis -->
            <el-submenu index="AgricultureBusinessManage" v-if="menuEnable.abm.crop || menuEnable.abm.fertilizer || menuEnable.abm.soil">
              <template slot="title">农事管理</template>
              <el-menu-item index="/crop-tab" v-if="menuEnable.abm.crop">作物管理</el-menu-item>
              <el-menu-item index="/fertilizer" v-if="menuEnable.abm.fertilizer">肥料管理</el-menu-item>
              <el-menu-item index="/soil" v-if="menuEnable.abm.soil">土壤管理</el-menu-item>
            </el-submenu>
            <!-- agriculture business manage -->
            <el-submenu index="UserManage" v-show="menuEnable.userManage.user || menuEnable.userManage.farmerIntegration">
              <template slot="title">用户管理</template>
              <el-menu-item index="/user" v-show="menuEnable.userManage.user">用户管理</el-menu-item>
              <el-menu-item index="/farmer-integration" v-show="menuEnable.userManage.farmerIntegration">农户积分 </el-menu-item>
            </el-submenu>
            <!-- user manage -->
            <el-submenu index="SystemManage" v-if="menuEnable.systemManage.userLevel || menuEnable.systemManage.role">
              <template slot="title">系统管理</template>
              <el-menu-item index="/user-level" v-if="menuEnable.systemManage.userLevel">用户级别管理</el-menu-item>
              <el-menu-item index="/role" v-if="menuEnable.systemManage.role">用户角色管理</el-menu-item>
              <el-menu-item index="/professional-category" v-if="menuEnable.systemManage.professionalCategory"> 专业用户类别管理 </el-menu-item>
            </el-submenu>
            <!-- system manage -->
            <el-menu-item index="menuXX" v-if="menuEnable.emergencyInvestigation">应急调查</el-menu-item>
            <!-- emergency ventilation -->
            <el-submenu index="UCenter" class="ucenter" v-if="loginFlag">
              <template slot="title">{{ fullName }}</template>
              <el-menu-item index="/personal">个人中心</el-menu-item>
              <el-menu-item @click="logout()">退出登录</el-menu-item>
            </el-submenu>
            <el-menu-item index="/enter-login" class="enter-login" v-else>点击登录</el-menu-item>
            <!-- user center -->
          </el-menu>
        </div>
      </el-header>
      <!-- header -->
      <el-main style="padding: 10px 20px 20px 20px">
        <el-row>
          <el-col :span="24">
            <router-view></router-view>
          </el-col>
        </el-row>
      </el-main>
      <!-- main -->
      <el-footer></el-footer>
      <!-- footer -->
    </el-container>
    <el-backtop :bottom="100" :visibility-height="50"></el-backtop>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      logoUrl: require('./assets/images/logo.png'),
      activeNav: '/',
      fullName: '欢迎您！',
      loginFlag: false,
      regionName: '全国',
      showBigScreenLink: false,
      menuEnable: {
        index: true,
        dataFill: {
          farmerFillIn: false,
          professionalFillIn: false,
          assistFillIn: false,
          surfaceSurvey: false,
          questionnaire: false
        },
        dataAudit: false,
        chartAnalysis: {
          dataVisualization: false,
          annualAnalysis: false
        },
        abm: {
          crop: false,
          fertilizer: false,
          soil: false,
          plot: false
        },
        userManage: {
          user: false,
          farmerIntegration: false
        },
        systemManage: {
          userLevel: false,
          role: false
        },
        emergencyInvestigation: false
      }
    }
  },
  created() {
    if (null === sessionStorage.getItem('token')) {
      window.location = '/login.html'
      return
    }

    if (null != sessionStorage.getItem('fullName')) {
      this.loginFlag = true
      this.fullName = sessionStorage.getItem('fullName')
    }

    let permissionArray = []
    let permissionJson = JSON.parse(sessionStorage.getItem('permissionIdList'))
    permissionJson.forEach(el => {
      permissionArray[el] = el
    })

    let userLevelId = sessionStorage.getItem('levelId')
    this.menuEnable.index = 'Farmer' !== userLevelId
    this.menuEnable.dataFill.farmerFillIn = undefined != permissionArray['farmer:fill']
    this.menuEnable.dataFill.professionalFillIn = undefined != permissionArray['professional:fill']
    this.menuEnable.dataFill.assistFillIn = undefined != permissionArray['assist:fill']
    this.menuEnable.dataFill.surfaceSurvey = undefined != permissionArray['survey:fill']
    this.menuEnable.dataFill.questionnaire = undefined != permissionArray['farmer:fill'] && 'Farmer' === userLevelId
    this.menuEnable.dataFill.questionnaireAssist = undefined != permissionArray['assist:fill']
    this.menuEnable.dataAudit = undefined != permissionArray['data_auditing:manage'] || (undefined != permissionArray['data_auditing:view'] && 'Farmer' !== userLevelId)
    // this.menuEnable.chartAnalysis = undefined != permissionArray['chart_analysis:data_visualization'] || undefined != permissionArray['chart_analysis:annual_analysis']
    this.menuEnable.chartAnalysis.dataVisualization = undefined != permissionArray['chart_analysis:data_visualization']
    this.menuEnable.chartAnalysis.annualAnalysis = undefined != permissionArray['chart_analysis:annual_analysis']
    this.menuEnable.abm.crop = undefined != permissionArray['crop:manage']
    this.menuEnable.abm.fertilizer = undefined != permissionArray['fertilizer:manage']
    this.menuEnable.abm.soil = undefined != permissionArray['soil:manage']
    this.menuEnable.abm.plot = undefined != permissionArray['plot:manage']
    this.menuEnable.userManage.user = undefined != permissionArray['user:manage']
    this.menuEnable.userManage.farmerIntegration = undefined != permissionArray['farmer_integration:manage']
    this.menuEnable.systemManage.userLevel = undefined != permissionArray['user_level:manage']
    this.menuEnable.systemManage.role = undefined != permissionArray['role:manage']
    this.menuEnable.systemManage.professionalCategory = undefined != permissionArray['professional_category:manage']

    switch (userLevelId) {
      case 'Country':
        this.showBigScreenLink = true
        break
      case 'Professional':
        this.regionName = '全国'
        break
      case 'Province':
        this.regionName = sessionStorage.getItem('provinceName')
        break
      case 'City':
        this.regionName = sessionStorage.getItem('cityName')
        break
      case 'District':
      case 'Farmer':
        this.regionName = sessionStorage.getItem('districtName')
        break
      default:
        this.regionName = '全国'
        break
    }

    this.setActivedNav()
  },
  mounted() {
    window.WIDGET = {
      CONFIG: {
        layout: '1',
        width: '520',
        height: '130',
        background: '5',
        dataColor: '434343',
        language: 'zh',
        key: '09a575d4cee84ccda4513de304817e57'
      }
    }
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://widget.qweather.net/standard/static/js/he-standard-common.js?v=2.0'
    document.getElementsByTagName('head')[0].appendChild(script)
  },
  beforeUpdate() {
    this.setActivedNav()
  },
  watch: {
    $router() {
      this.setActivedNav()
    }
  },
  methods: {
    setActivedNav() {
      let path = this.$route.path
      if ('/enter-login' === path) {
        window.location.href = '/login.html'
        return
      }
      this.activeNav = this.$route.path
    },
    handleNavSelect(key, keyPath) {
      console.log(key)
      console.log(keyPath)
    },
    logout() {
      sessionStorage.clear()
      window.location = '/login.html'
    }
  }
}
</script>

<style>
#app {
  margin: 0;
  padding: 0;
  color: #333;
}

/* top */
.top {
  height: 40px;
  background-color: #f4f4f4;
}

.top > .label-welcome {
  display: block;
  float: left;
  margin-left: 50px;
  line-height: 40px;
  font-size: 14px;
  color: #757575;
}

.top .link-big-data {
  display: block;
  float: right;
  margin-right: 120px;
  line-height: 40px;
  font-size: 14px;
  color: #757575;
}

.top .link-big-data .el-link {
  vertical-align: initial;
  text-decoration: underline;
}

.top .link-big-data .el-link.el-link--default {
  color: #039f3a;
}

.top .link-big-data .el-link:hover.el-link--default {
  color: #67c23a;
}

/* logo-weather */
.logo-weather {
  height: 150px;
  background: #fff;
}

.logo-weather > .img-logo {
  float: left;
  margin: 36px 0 0 50px;
}

.logo-weather .system-name {
  display: block;
  float: left;
  margin: 38px 0 0 20px;
}

.logo-weather > .system-name > .cn {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 1px;
}
.logo-weather > .system-name > .cn > {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 1px;
}
.logo-weather > .system-name > .cn > .router-link-active {
  text-decoration: none;
  color: #606266;
}
.logo-weather > .system-name > .cn > a {
  text-decoration: none;
   color: #606266;
}
.logo-weather > .system-name > .cn > .el-link {
  color: #606266;
  margin-top: 5px;
  font-size: 42px
}

.logo-weather > .system-name > .en {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #a6a6a6;
}

.plug-in-weather {
  float: right;
  margin: 18px 20px 0 0;
}

.main-nav-box {
  /* padding-left: 140px; */
  padding-left: 50px;
  height: 60px;
  border-bottom: solid 1px #e6e6e6;
  background: #00953a;
}

.main-nav.el-menu > .el-menu-item {
  font-size: 16px;
}

.main-nav.el-menu > .el-submenu .el-submenu__title {
  font-size: 16px;
}

.main-nav.el-menu .el-submenu__title i {
  color: #fff;
}

.main-nav.el-menu .el-submenu__icon-arrow {
  font-size: 14px;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  font-size: 16px;
}

.el-menu--horizontal > .ucenter.el-submenu {
  float: right;
  margin-right: 20px;
}

.el-menu--horizontal > .enter-login.el-menu-item {
  float: right;
  margin-right: 20px;
}

.echarts {
  /*width: 42vw;*/
  height: 340px;
  min-width: 300px;
  min-height: 240px;
}
</style>
