<template>
  <div class="wg-box">
    <div class="header uc-header">
      <div class="title">个人资料</div>
      <div class="wg-clear-fix"></div>
    </div>
    <div class="body">
      <el-form ref="form" :model="form" :rules="form.rules" label-width="150px" :show-message="true" v-loading="loading" element-loading-text="等待个人资料修改......" element-loading-spinner="el-icon-loading">
        <el-row :gutter="30">
          <el-col :span="8">
            <el-form-item label="用户名称：" prop="username">
              <el-input v-model="form.username" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="真实姓名：" prop="fullName">
              <el-input v-model="form.fullName"></el-input>
            </el-form-item>
            <el-form-item label="办公电话：" prop="officePhone">
              <el-input v-model="form.officePhone" placeholder="区号-电话号码"></el-input>
            </el-form-item>
            <el-form-item label="手机号码：" prop="phoneNumber">
              <el-input v-model="form.phoneNumber" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱：" prop="email">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="所在单位：" prop="organization">
              <el-input v-model="form.organization" :disabled="flags.isProfessionalCategory"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户级别：" prop="levelName">
              <el-input v-model="form.levelName" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="用户类别：" prop="professionalCategory" v-if="flags.isProfessionalCategory">
              <el-input v-model="form.professionalCategoryName" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="所属省份：" prop="provinceId">
              <el-select v-model="form.provinceId" :disabled="flags.disableProvinceSelect" @change="handleProvinceChange">
                <el-option v-for="option in form.filterData.provinceOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属地市：" prop="cityId">
              <el-select v-model="form.cityId" :disabled="flags.disableCitySelect" @change="handleCityChange">
                <el-option v-for="option in form.filterData.cityOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属区县：" prop="districtId">
              <el-select v-model="form.districtId" :disabled="flags.disableDistrictSelect">
                <el-option v-for="option in form.filterData.districtOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="详细地址：" prop="detailedAddress">
              <el-input v-model="form.detailedAddress"></el-input>
            </el-form-item>
            <el-form-item label="农户性质：" prop="farmerNature" v-if="flags.isFarmer">
              <el-select v-model="form.farmerNature" :disabled="flags.disableFarmerNatureSelect">
                <el-option v-for="option in form.filterData.farmerNatureOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文化程度：" prop="educationalLevel" v-if="flags.isFarmer">
              <el-select v-model="form.educationalLevel">
                <el-option v-for="option in form.filterData.educationalLevelOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经营面积（亩）：" prop="cultivationLandArea" v-if="flags.isFarmer">
              <el-input v-model="form.cultivationLandArea"></el-input>
            </el-form-item>
            <el-form-item label="示范户：" prop="modelHouseholdFlag" v-if="flags.isFarmer">
              <el-select v-model="form.modelHouseholdFlag">
                <el-option v-for="option in form.filterData.modelHouseholdFlagOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <div style="text-align: center; padding: 20px">
              <el-button class="wg-button blank" @click="handleReset">重置</el-button>
              &nbsp;&nbsp;
              <el-button class="wg-button" @click="handleSave">修改</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import AccountApi from '@/api/account-api'
import RegionApi from '@/api/region-api'

export default {
  name: 'ChangePassword',
  data() {
    const validateOfficePhone = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^(0\d{2,3})-?(\d{7,8})$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确电话号码！'))
        }
      } else {
        callback()
      }
    }

    const validateEmail = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        if (reg.test(value)) {
          callback()
        } else {
          callback('请输入正确电子邮件！')
        }
      } else {
        callback()
      }
    }

    const validateNumber = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^([1-9]\d*(\.\d*)?)$|^0\.\d*[1-9]\d*$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入数字！'))
        }
      } else {
        callback()
      }
    }

    return {
      loading: false,
      flags: {
        isFarmer: false,
        isProfessionalCategory: false,
        disableProvinceSelect: false,
        disableCitySelect: false,
        disableDistrictSelect: false,
        disableFarmerNatureSelect: false
      },
      form: {
        filterData: {
          provinceOptionList: [],
          cityOptionList: [],
          districtOptionList: [],
          farmerNatureOptionList: [],
          educationalLevelOptionList: [],
          auditFlagOptionList: [],
          modelHouseholdFlagOptionList: []
        },
        rules: {
          username: [{ required: true, trigger: 'blur', message: '请输入用户名称！' }],
          fullName: [{ required: true, trigger: 'blur', message: '请输入真实姓名！' }],
          officePhone: [{ trigger: 'blur', validator: validateOfficePhone }],
          email: [{ trigger: 'blur', validator: validateEmail }],
          provinceId: [{ required: true, trigger: 'change', message: '请选择省份！' }],
          cityId: [{ required: true, trigger: 'change', message: '请选择省份！' }],
          districtId: [{ required: true, trigger: 'change', message: '请选择省份！' }],
          detailedAddress: [{ required: true, trigger: 'change', message: '请输入详细地址！' }],
          farmerNature: [{ required: true, trigger: 'change', message: '请选择农户性质！' }],
          educationalLevel: [{ required: true, trigger: 'change', message: '请选择文化程度！' }],
          cultivationLandArea: [
            { required: true, trigger: 'blur', message: '请输入经营面积！' },
            { trigger: 'blur', validator: validateNumber }
          ],
          modelHouseholdFlag: [{ required: true, trigger: 'change', message: '请选择示范户！' }]
        },
        username: null,
        fullName: null,
        officePhone: null,
        phoneNumber: null,
        email: null,
        detailedAddress: null,
        organization: null,
        levelName: null,
        professionalCategoryName: null,
        provinceId: null,
        cityId: null,
        districtId: null,
        farmerNature: null,
        educationalLevel: null,
        cultivationLandArea: null,
        modelHouseholdFlag: null
      }
    }
  },
  created() {
    // farmerNatureOptionList
    this.form.filterData.farmerNatureOptionList.push({ text: '请选择', value: null })
    this.form.filterData.farmerNatureOptionList.push({ text: '普通种植户', value: 'COMMON_PLANTER' })
    this.form.filterData.farmerNatureOptionList.push({ text: '种植大户', value: 'LARGE_PLANTER' })
    this.form.filterData.farmerNatureOptionList.push({ text: '家庭农场', value: 'FAMILY_FARM' })
    this.form.filterData.farmerNatureOptionList.push({ text: '专业合作社', value: 'SPECIALIZED_COOPERATIVE' })
    // educationalLevelOptionList
    this.form.filterData.educationalLevelOptionList.push({ text: '请选择', value: null })
    this.form.filterData.educationalLevelOptionList.push({ text: '小学', value: 'PRIMARY_SCHOOL' })
    this.form.filterData.educationalLevelOptionList.push({ text: '初中', value: 'JUNIOR_HIGH_SCHOOL' })
    this.form.filterData.educationalLevelOptionList.push({ text: '中专/中技', value: 'TECHNICAL_SECONDARY_SCHOOL' })
    this.form.filterData.educationalLevelOptionList.push({ text: '高中', value: 'SENIOR_HIGH_SCHOOL' })
    this.form.filterData.educationalLevelOptionList.push({ text: '大专', value: 'JUNIOR_COLLEGE' })
    this.form.filterData.educationalLevelOptionList.push({ text: '本科', value: 'UNDERGRADUATE' })
    this.form.filterData.educationalLevelOptionList.push({ text: '研究生', value: 'GRADUATE' })
    // modelHouseholdFlagOptionList
    this.form.filterData.modelHouseholdFlagOptionList.push({ text: '请选择', value: null })
    this.form.filterData.modelHouseholdFlagOptionList.push({ text: '是', value: true })
    this.form.filterData.modelHouseholdFlagOptionList.push({ text: '否', value: false })
    let userLevelId = sessionStorage.getItem('levelId')

    if ('Province' === userLevelId) {
      this.flags.disableProvinceSelect = true
    } else if ('City' === userLevelId) {
      this.flags.disableProvinceSelect = true
      this.flags.disableCitySelect = true
    } else if ('District' === userLevelId) {
      this.flags.disableProvinceSelect = true
      this.flags.disableCitySelect = true
      this.flags.disableDistrictSelect = true
    } else if ('Farmer' === userLevelId) {
      this.flags.disableProvinceSelect = true
      this.flags.disableCitySelect = true
      this.flags.disableDistrictSelect = true
      this.flags.disableFarmerNatureSelect = true
    } else if ('Professional' === userLevelId) {
      this.flags.isProfessionalCategory = true
    }

    this.bindFormData()
  },
  methods: {
    handleProvinceChange(selectedValue) {
      this.form.districtId = null
      this.form.cityId = null
      this.bindDistrictSelectData(null)
      this.bindCitySelectData(selectedValue)
    },
    handleCityChange(selectedValue) {
      this.form.districtId = null
      this.bindDistrictSelectData(selectedValue)
    },
    handleReset() {
      this.bindFormData()
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return false

        let updateModel = {
          fullName: this.form.fullName,
          email: this.$utils.isEmpty(this.form.email) ? null : this.form.email,
          officePhone: this.$utils.isEmpty(this.form.officePhone) ? null : this.form.officePhone,
          detailedAddress: this.$utils.isEmpty(this.form.detailedAddress) ? null : this.form.detailedAddress,
          organization: this.$utils.isEmpty(this.form.organization) ? null : this.form.organization,
          levelId: this.form.levelId,
          regionId: null,
          farmerNature: this.form.farmerNature,
          educationalLevel: this.form.educationalLevel,
          cultivationLandArea: this.form.cultivationLandArea,
          modelHouseholdFlag: this.form.modelHouseholdFlag
        }

        if (this.$utils.isNotEmpty(this.form.districtId)) {
          updateModel.regionId = this.form.districtId
        } else if (this.$utils.isNotEmpty(this.form.cityId)) {
          updateModel.regionId = this.form.cityId
        } else if (this.$utils.isNotEmpty(this.form.provinceId)) {
          updateModel.regionId = this.form.provinceId
        }

        AccountApi.updateOwnAccount(updateModel)
          .then(() => {
            this.$message({ showClose: true, message: '个人信息修改成功！', type: 'success' })
          })
          .catch(err => {
            this.$message({ type: 'error', message: '【' + err.data.code + '】' + err.data.message })
          })
      })
    },
    bindFormData() {
      AccountApi.getOwnAccount()
        .then(res => {
          let userModel = res.data
          this.form.username = userModel.username
          this.form.fullName = userModel.fullName
          this.form.officePhone = userModel.officePhone
          this.form.phoneNumber = userModel.phoneNumber
          this.form.email = userModel.email
          this.form.detailedAddress = userModel.detailedAddress
          this.form.organization = userModel.organization
          this.form.levelName = userModel.levelName
          this.form.professionalCategoryName = userModel.professionalCategoryName
          this.form.provinceId = userModel.provinceId
          this.form.cityId = userModel.cityId
          this.form.districtId = userModel.districtId
          this.form.farmerNature = userModel.farmerNature
          this.form.educationalLevel = userModel.educationalLevel
          this.form.cultivationLandArea = userModel.cultivationLandArea
          this.form.modelHouseholdFlag = userModel.modelHouseholdFlag

          this.flags.isFarmer = 'Farmer' === userModel.levelId

          this.bindProvinceSelectData()
          if (this.$utils.isNotEmpty(userModel.provinceId)) {
            this.bindCitySelectData(userModel.provinceId)
          }
          if (this.$utils.isNotEmpty(userModel.cityId)) {
            this.bindDistrictSelectData(userModel.cityId)
          }

          if ('Country' === userModel.levelId) {
            this.form.rules.provinceId[0].required = false
            this.form.rules.cityId[0].required = false
            this.form.rules.districtId[0].required = false
          } else if ('Province' === userModel.levelId) {
            this.form.rules.provinceId[0].required = true
            this.form.rules.cityId[0].required = false
            this.form.rules.districtId[0].required = false
          } else if ('City' === userModel.levelId) {
            this.form.rules.provinceId[0].required = true
            this.form.rules.cityId[0].required = true
            this.form.rules.districtId[0].required = false
          } else if ('District' === userModel.levelId || 'Farmer' === userModel.levelId) {
            this.form.rules.provinceId[0].required = true
            this.form.rules.cityId[0].required = true
            this.form.rules.districtId[0].required = true
            if ('Farmer' === userModel.levelId) this.flags.isFarmer = true
          }
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    bindProvinceSelectData() {
      this.form.filterData.provinceOptionList = []
      this.form.filterData.provinceOptionList.push({ text: '请选择', value: null })
      RegionApi.getProvinceList().then(res => {
        res.data.forEach(el => {
          this.form.filterData.provinceOptionList.push({ text: el.name, value: el.id })
        })
      })
    },
    bindCitySelectData(provinceId) {
      this.form.filterData.cityOptionList = []
      this.form.filterData.cityOptionList.push({ text: '请选择', value: null })
      if (null === provinceId) return

      RegionApi.getCityList(provinceId)
        .then(res => {
          res.data.forEach(el => {
            this.form.filterData.cityOptionList.push({ text: el.name, value: el.id })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    bindDistrictSelectData(cityId) {
      this.form.filterData.districtOptionList = []
      this.form.filterData.districtOptionList.push({ text: '请选择', value: null })
      if (null === cityId) return

      RegionApi.getDistrictList(cityId)
        .then(res => {
          res.data.forEach(el => {
            this.form.filterData.districtOptionList.push({ text: el.name, value: el.id })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped></style>
