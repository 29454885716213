<template>
  <div>
    <el-row :gutter='12'>
      <el-col :span="12">
        <FertilizerApplicationIntensity ref="fertilizerapplicationintensity" :params="params"></FertilizerApplicationIntensity>
      </el-col>
      <el-col :span="12">
        <PartialFactorProductivity ref="partialfactorproductivity" :params="params"></PartialFactorProductivity>
      </el-col>
    </el-row>
    <div class='chart-divider'></div>
    <el-row>
      <el-col :span="24">
        <CropYield ref="cropyield" :params="params"></CropYield>
      </el-col>
    </el-row>
    <div class='chart-divider'></div>
    <BaseFertilizationIntensity ref="basefertilizationintensity" :params="params"></BaseFertilizationIntensity>
    <div class='chart-divider'></div>
    <el-row>
      <el-col :span="24">
        <NutrientStructure ref="nutrientstructure" :params="params"></NutrientStructure>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import FertilizerApplicationIntensity from '@/views/chart-analysis/components/nutrient/FertilizerApplicationIntensity'
import PartialFactorProductivity from '@/views/chart-analysis/components/nutrient/PartialFactorProductivity'
import CropYield from '@/views/chart-analysis/components/nutrient/CropYield'
import BaseFertilizationIntensity from '@/views/chart-analysis/components/nutrient/BaseFertilizationIntensity.vue'
import NutrientStructure from '@/views/chart-analysis/components/nutrient/NutrientStructure'
export default {
  name: "ChartGroup-Nutrient",
  components: {
    NutrientStructure,
    BaseFertilizationIntensity,
    CropYield,
    PartialFactorProductivity,
    FertilizerApplicationIntensity
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    refreshCharts() {
      this.$nextTick(() => {
        this.$refs.fertilizerapplicationintensity.refresh()
        this.$refs.partialfactorproductivity.refresh()
        this.$refs.cropyield.refresh()
        this.$refs.basefertilizationintensity.refresh()
        this.$refs.nutrientstructure.refresh()
      })
    }
  }

}
</script>

<style scoped>


.el-card {
  border: 1px solid #D6D6D6;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 4px 4px;
}

.el-card ::v-deep .el-card__header {
  padding: 2px 10px;
  height: 50px;
}

.el-card ::v-deep .el-card__body {
  padding: 0px;
  background: #FFFFFF;
  border: 0.5px solid #D6D6D6;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 4px 4px;
}
</style>
