import httpRequest from '@/utils/http-request'

export default {
  getList(regionId, keyword, startDate, endDate) {
    let config = {
      params: {
        regionId: regionId,
        keyword: keyword,
        startDate: startDate,
        endDate: endDate
      }
    }
    return httpRequest.get('/farmers', config)
  },
  getLimitList(regionId, keyword, filterCriteria, sortCriteria, pageSize, pageIndex) {
    let config = {
      params: {
        regionId: regionId,
        keyword: keyword,
        filterCriteria: filterCriteria,
        sortCriteria: sortCriteria,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }

    return httpRequest.get('/farmers-limit', config)
  },
  getCount(regionId, keyword) {
    let config = {
      params: {
        regionId: regionId,
        keyword: keyword
      }
    }

    return httpRequest.get('/farmers-count', config)
  },
  getListOfNoFillQuestionnaire(year, regionId, keyword) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        keyword: keyword
      }
    }
    return httpRequest.get('/no-fill-questionnaire-farmers', config)
  },
  getFarmerDataCount() {
    return httpRequest.get('/farmer-data-count')
  },
  exportFarmerData(exportId, regionId, oldExportId) {
    let config = {
      params: {
        exportId: exportId,
        regionId: regionId,
        oldExportId
      }
    }
    return httpRequest.post('/farmer-data-export', null, config)
  }
}
