import httpRequest from '@/utils/http-request'

export default {
  login(loginModel) {
    return httpRequest.post('/login', loginModel)
  },
  phoneNumberLogin(phoneNumber, verifyCode) {
    let body = {
      phoneNumber: phoneNumber,
      verifyCode: verifyCode
    }
    return httpRequest.post('/phone-number-login', body)
  },
  register(registerModel) {
    return httpRequest.post('/registration', registerModel)
  },
  resetPassword(phoneNumber, password, verifyCode) {
    let body = {
      phoneNumber: phoneNumber,
      password: password,
      verifyCode: verifyCode
    }
    return httpRequest.post('/password-reset', body)
  },
  changePassword(oldPassword, newPassword) {
    let body = {
      oldPassword: oldPassword,
      newPassword: newPassword
    }
    return httpRequest.post('/password-change', body)
  },
  changePhoneNumber(phoneNumber, verifyCode) {
    let body = {
      phoneNumber: phoneNumber,
      verifyCode: verifyCode
    }
    return httpRequest.post('/phone-number-change', body)
  },
  validatePhoneNumber(phoneNumber, verifyCode) {
    let body = {
      phoneNumber: phoneNumber,
      verifyCode: verifyCode
    }
    return httpRequest.post('/phone-number-validating', body)
  },
  getOwnAccount() {
    return httpRequest.get('/own-account')
  },
  updateOwnAccount(updateModel) {
    return httpRequest.put('/own-account', updateModel)
  }
}
