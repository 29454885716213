<template>
  <div class="wg-box">
    <div class="header uc-header">
      <div class="title">修改密码</div>
      <div class="wg-clear-fix"></div>
    </div>
    <div class="body">
      <el-form ref="form" :model="form" :rules="formRules" label-width="120px" :show-message="true" v-loading="loading" element-loading-text="等待密码修改......" element-loading-spinner="el-icon-loading">
        <el-row>
          <el-col :span="8">
            <el-form-item label="旧密码：" prop="oldPassword">
              <el-input v-model="form.oldPassword" show-password></el-input>
            </el-form-item>
            <el-form-item label="新密码：" prop="newPassword">
              <el-input v-model="form.newPassword" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="confirmPassword">
              <el-input v-model="form.confirmPassword" show-password></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="wg-button" @click="handleSave">修改</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import AccountApi from '@/api/account-api'

export default {
  name: 'ChangePassword',
  data() {
    const validatePassword = (rule, value, callback) => {
      if (this.$utils.isEmpty(value)) {
        callback(new Error('请输入用户密码！'))
      } else {
        const reg = /^(?=.*\d)(?=.*[a-zA-Z]).{8,32}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('密码必须包含至少8位字母数字！'))
        }
      }
    }

    const validateConfirmPassword = (rule, value, callback) => {
      if (this.$utils.isEmpty(value)) {
        callback(new Error('请输入确认密码！'))
      } else if (value !== this.form.newPassword) {
        callback(new Error('输入密码不一致！'))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      formRules: {
        oldPassword: [{ required: true, trigger: 'blur', message: '请输入旧密码！' }],
        newPassword: [{ trigger: 'blur', validator: validatePassword }],
        confirmPassword: [{ trigger: 'blur', validator: validateConfirmPassword }]
      }
    }
  },
  methods: {
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return false

        this.loading = true
        let theVue = this
        AccountApi.changePassword(this.form.oldPassword, this.form.newPassword)
          .then(() => {
            this.$message({
              type: 'success',
              message: '密码修改成功！',
              duration: 1000,
              onClose: function () {
                sessionStorage.clear()
                window.location = '/login.html'
              }
            })
          })
          .catch(function (err) {
            theVue.$message({ type: 'error', message: '【' + err.data.code + '】' + err.data.message })
            theVue.loading = false
          })
      })
    }
  }
}
</script>

<style scoped>
</style>