import Tools from '@/utils/tools'
import Moment from 'moment'

const validateNumber = ({ cellValue, column }) => {
  const reg = /^([0-9]\d*(\.\d*)?)$|^0\.\d*[1-9]\d*$/
  if (Tools.isNotEmpty(cellValue) && !reg.test(cellValue)) {
    return new Error('【' + column.title + '】请输入数字！')
  }
}

export default {
  validateNumber: ({ cellValue, column }) => {
    const reg = /^([0-9]\d*(\.\d*)?)$|^0\.\d*[1-9]\d*$/
    if (Tools.isNotEmpty(cellValue) && !reg.test(cellValue)) {
      return new Error('【' + column.title + '】请输入数字！')
    }
  },
  validRules: {
    UA_FullName: [{ required: true, message: '【农户姓名】未填写！' }],
    UA_DetailedAddress: [{ required: true, message: '【详细地址】未填写！' }],
    UA_FarmerNature: [{ required: true, message: '【农户性质】未填写！' }],
    UA_EducationalLevel: [{ required: true, message: '【文化程度】未填写！' }],
    UA_CultivationLandArea: [{ required: true, message: '【经营面积】未填写！' }],
    UA_ModelHouseholdFlag: [{ required: true, message: '【示范户】未填写！' }],
    MP_Id: [{ required: true, message: '【地块名称】未填写！' }],
    PC_CropId: [{ required: true, message: '【作物名称】未填写！' }],
    PC_SowingDate: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(cellValue) && Tools.isNotEmpty(row.PC_HarvestTime)) {
            let sowingDateUnix = Moment(cellValue).unix()
            let harvestTimeUnix = Moment(row.PC_HarvestTime).unix()
            if (sowingDateUnix > harvestTimeUnix) {
              return new Error('【播种日期】不能大于【收获日期】！')
            }
          }
        }
      }
    ],
    PC_HarvestTime: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(cellValue) && Tools.isNotEmpty(row.PC_SowingDate)) {
            let harvestTimeUnix = Moment(cellValue).unix()
            let sowingDateUnix = Moment(row.PC_SowingDate).unix()
            if (sowingDateUnix > harvestTimeUnix) {
              return new Error('【播种日期】不能大于【收获日期】！')
            }
          }
        }
      }
    ],
    PC_SowingArea: [{ required: true, message: '【播种面积】未填写！' }, { validator: validateNumber }],
    PC_PlotFertility: [{ required: true, message: '【地块肥力】未填写！' }],
    PC_Yield: [{ required: true, message: '【亩产量】未填写！' }, { validator: validateNumber }],
    PC_FacilityCultivationFlag: [{ required: true, message: '【是否设施栽培】未填写！' }],
    PC_PreviousCropId: [{ required: true, message: '【前茬作物】未填写！' }],
    PC_PreviousYield: [
      {
        validator: ({ cellValue, row }) => {
          if ('NONE' !== row.PC_PreviousCropId && Tools.isEmpty(cellValue)) {
            return new Error('【前茬作物】未填写！')
          }
        }
      }
    ],
    PC_PreviousStrawReturnRate: [
      {
        validator: ({ cellValue, row }) => {
          if ('NONE' !== row.PC_PreviousCropId && Tools.isEmpty(cellValue)) {
            return new Error('【前茬作物亩产量】未填写！')
          }
        }
      }
    ],
    PC_AfterFertilizerFillInMode: [{ required: true, message: '【追肥是否分次填报】未填写！' }],

    BF_FertilizationMode: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(cellValue)) {
            if (
              Tools.isEmpty(row.BF_BusinessOrganicFertilizerDosage) &&
              Tools.isEmpty(row.BF_OrganicFertilizer1Id) &&
              Tools.isEmpty(row.BF_OrganicFertilizer2Id) &&
              Tools.isEmpty(row.BF_UreaDosage) &&
              Tools.isEmpty(row.BF_AmmoniumBicarbonateDosage) &&
              Tools.isEmpty(row.BF_DiammoniumPhosphateDosage) &&
              Tools.isEmpty(row.BF_PotassiumChlorideDosage) &&
              Tools.isEmpty(row.BF_PotassiumSulphateDosage) &&
              Tools.isEmpty(row.BF_CompoundFertilizerDosage) &&
              Tools.isEmpty(row.BF_WaterSolubleFertilizerDosage) &&
              Tools.isEmpty(row.BF_OtherFertilizer1Id) &&
              Tools.isEmpty(row.BF_OtherFertilizer2Id)
            ) {
              return new Error('请至少填写一种【底肥】信息！')
            }
          }
        }
      }
    ],
    BF_BusinessOrganicFertilizerPrice: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.BF_BusinessOrganicFertilizerDosage) && Tools.isEmpty(cellValue)) {
            return new Error('底肥【商用有机肥价格】未填写！')
          }
        }
      }
    ],
    BF_OrganicFertilizer1Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.BF_OrganicFertilizer1Id) && Tools.isEmpty(cellValue)) {
            return new Error('底肥【农家肥1（干重）用量】未填写！')
          }
        }
      }
    ],
    BF_OrganicFertilizer2Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.BF_OrganicFertilizer2Id) && Tools.isEmpty(cellValue)) {
            return new Error('底肥【农家肥2（干重）用量】未填写！')
          }
        }
      }
    ],
    BF_CompoundFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.BF_CompoundFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.BF_CompoundFertilizerP2O5) && Tools.isEmpty(row.BF_CompoundFertilizerK2O)) {
            return new Error('底肥【复合肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.BF_CompoundFertilizerP2O5) ? 0 : Number(row.BF_CompoundFertilizerP2O5)
            let K2O = Tools.isEmpty(row.BF_CompoundFertilizerK2O) ? 0 : Number(row.BF_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('底肥【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('底肥【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    BF_CompoundFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.BF_CompoundFertilizerDosage) && Tools.isEmpty(row.BF_CompoundFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.BF_CompoundFertilizerK2O)) {
            return new Error('底肥【复合肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.BF_CompoundFertilizerN) ? 0 : Number(row.BF_CompoundFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.BF_CompoundFertilizerK2O) ? 0 : Number(row.BF_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('底肥【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('底肥【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    BF_CompoundFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.BF_CompoundFertilizerDosage) && Tools.isEmpty(row.BF_CompoundFertilizerN) && Tools.isEmpty(row.BF_CompoundFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('底肥【复合肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.BF_CompoundFertilizerN) ? 0 : Number(row.BF_CompoundFertilizerN)
            let P2O5 = Tools.isEmpty(row.BF_CompoundFertilizerP2O5) ? 0 : Number(row.BF_CompoundFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('底肥【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('底肥【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    BF_WaterSolubleFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.BF_WaterSolubleFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.BF_WaterSolubleFertilizerP2O5) && Tools.isEmpty(row.BF_WaterSolubleFertilizerK2O)) {
            return new Error('底肥【水溶肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.BF_WaterSolubleFertilizerP2O5) ? 0 : Number(row.BF_WaterSolubleFertilizerP2O5)
            let K2O = Tools.isEmpty(row.BF_WaterSolubleFertilizerK2O) ? 0 : Number(row.BF_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('底肥【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('底肥【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    BF_WaterSolubleFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (
            Tools.isNotEmpty(row.BF_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.BF_WaterSolubleFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.BF_WaterSolubleFertilizerK2O)) {
            return new Error('底肥【水溶肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.BF_WaterSolubleFertilizerN) ? 0 : Number(row.BF_WaterSolubleFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.BF_WaterSolubleFertilizerK2O) ? 0 : Number(row.BF_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('底肥【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('底肥【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    BF_WaterSolubleFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.BF_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.BF_WaterSolubleFertilizerN) && Tools.isEmpty(row.BF_WaterSolubleFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('底肥【水溶肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.BF_WaterSolubleFertilizerN) ? 0 : Number(row.BF_WaterSolubleFertilizerN)
            let P2O5 = Tools.isEmpty(row.BF_WaterSolubleFertilizerP2O5) ? 0 : Number(row.BF_WaterSolubleFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('底肥【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('底肥【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    BF_OtherFertilizer1Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.BF_OtherFertilizer1Id) && Tools.isEmpty(cellValue)) {
            return new Error('底肥【其他肥料1用量】未填写！')
          }
        }
      }
    ],
    BF_OtherFertilizer2Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.BF_OtherFertilizer2Id) && Tools.isEmpty(cellValue)) {
            return new Error('底肥【其他肥料2用量】未填写！')
          }
        }
      }
    ],

    AFT_FertilizationMode: [
      {
        validator: ({ cellValue, row }) => {
          if ('TOTAL' === row.PC_AfterFertilizerFillInMode && Tools.isEmpty(cellValue)) {
            return new Error('追肥合计【主要施肥方式】未填写！')
          }

          if (
            Tools.isNotEmpty(cellValue) &&
            Tools.isEmpty(row.AFT_BusinessOrganicFertilizerDosage) &&
            Tools.isEmpty(row.AFT_UreaDosage) &&
            Tools.isEmpty(row.AFT_AmmoniumBicarbonateDosage) &&
            Tools.isEmpty(row.AFT_DiammoniumPhosphateDosage) &&
            Tools.isEmpty(row.AFT_PotassiumChlorideDosage) &&
            Tools.isEmpty(row.AFT_PotassiumSulphateDosage) &&
            Tools.isEmpty(row.AFT_CompoundFertilizerDosage) &&
            Tools.isEmpty(row.AFT_WaterSolubleFertilizerDosage) &&
            Tools.isEmpty(row.AFT_OtherFertilizer1Id) &&
            Tools.isEmpty(row.AFT_OtherFertilizer2Id)
          ) {
            return new Error('请至少填写一种【追肥合计】信息！')
          }
        }
      }
    ],
    AFT_FertilizationTimes: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AFT_FertilizationMode) && Tools.isEmpty(cellValue)) {
            return new Error('追肥合计【追肥次数】未填写！')
          }
        }
      }
    ],
    AFT_CompoundFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AFT_CompoundFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AFT_CompoundFertilizerP2O5) && Tools.isEmpty(row.AFT_CompoundFertilizerK2O)) {
            return new Error('追肥合计【复合肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.AFT_CompoundFertilizerP2O5) ? 0 : Number(row.AFT_CompoundFertilizerP2O5)
            let K2O = Tools.isEmpty(row.AFT_CompoundFertilizerK2O) ? 0 : Number(row.AFT_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥合计【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥合计【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AFT_CompoundFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AFT_CompoundFertilizerDosage) && Tools.isEmpty(row.AFT_CompoundFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AFT_CompoundFertilizerK2O)) {
            return new Error('追肥合计【复合肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AFT_CompoundFertilizerN) ? 0 : Number(row.AFT_CompoundFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.AFT_CompoundFertilizerK2O) ? 0 : Number(row.AFT_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥合计【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥合计【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AFT_CompoundFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AFT_CompoundFertilizerDosage) && Tools.isEmpty(row.AFT_CompoundFertilizerN) && Tools.isEmpty(row.AFT_CompoundFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('追肥合计【复合肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AFT_CompoundFertilizerN) ? 0 : Number(row.AFT_CompoundFertilizerN)
            let P2O5 = Tools.isEmpty(row.AFT_CompoundFertilizerP2O5) ? 0 : Number(row.AFT_CompoundFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥合计【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥合计【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AFT_WaterSolubleFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AFT_WaterSolubleFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AFT_WaterSolubleFertilizerP2O5) && Tools.isEmpty(row.AFT_WaterSolubleFertilizerK2O)) {
            return new Error('追肥合计【水溶肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.AFT_WaterSolubleFertilizerP2O5) ? 0 : Number(row.AFT_WaterSolubleFertilizerP2O5)
            let K2O = Tools.isEmpty(row.AFT_WaterSolubleFertilizerK2O) ? 0 : Number(row.AFT_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥合计【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥合计【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AFT_WaterSolubleFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AFT_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.AFT_WaterSolubleFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AFT_WaterSolubleFertilizerK2O)) {
            return new Error('追肥合计【水溶肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AFT_WaterSolubleFertilizerN) ? 0 : Number(row.AFT_WaterSolubleFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.AFT_WaterSolubleFertilizerK2O) ? 0 : Number(row.AFT_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥合计【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥合计【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AFT_WaterSolubleFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AFT_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.AFT_WaterSolubleFertilizerN) && Tools.isEmpty(row.AFT_WaterSolubleFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('追肥合计【水溶肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AFT_WaterSolubleFertilizerN) ? 0 : Number(row.AFT_WaterSolubleFertilizerN)
            let P2O5 = Tools.isEmpty(row.AFT_WaterSolubleFertilizerP2O5) ? 0 : Number(row.AFT_WaterSolubleFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥合计【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥合计【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AFT_OtherFertilizer1Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AFT_OtherFertilizer1Id) && Tools.isEmpty(cellValue)) {
            return new Error('追肥合计【其他肥料1用量】未填写！')
          }
        }
      }
    ],
    AFT_OtherFertilizer2Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AFT_OtherFertilizer2Id) && Tools.isEmpty(cellValue)) {
            return new Error('追肥合计【其他肥料2用量】未填写！')
          }
        }
      }
    ],

    AF1_FertilizationMode: [
      {
        validator: ({ cellValue, row }) => {
          if ('MANY_TIMES' === row.PC_AfterFertilizerFillInMode && Tools.isEmpty(cellValue)) {
            return new Error('追肥1【主要施肥方式】未填写！')
          }

          if (
            Tools.isNotEmpty(cellValue) &&
            Tools.isEmpty(row.AF1_BusinessOrganicFertilizerDosage) &&
            Tools.isEmpty(row.AF1_UreaDosage) &&
            Tools.isEmpty(row.AF1_AmmoniumBicarbonateDosage) &&
            Tools.isEmpty(row.AF1_DiammoniumPhosphateDosage) &&
            Tools.isEmpty(row.AF1_PotassiumChlorideDosage) &&
            Tools.isEmpty(row.AF1_PotassiumSulphateDosage) &&
            Tools.isEmpty(row.AF1_CompoundFertilizerDosage) &&
            Tools.isEmpty(row.AF1_WaterSolubleFertilizerDosage) &&
            Tools.isEmpty(row.AF1_OtherFertilizer1Id) &&
            Tools.isEmpty(row.AF1_OtherFertilizer2Id)
          ) {
            return new Error('请至少填写一种【追肥1】信息！')
          }
        }
      }
    ],
    AF1_CompoundFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF1_CompoundFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF1_CompoundFertilizerP2O5) && Tools.isEmpty(row.AF1_CompoundFertilizerK2O)) {
            return new Error('追肥1【复合肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.AF1_CompoundFertilizerP2O5) ? 0 : Number(row.AF1_CompoundFertilizerP2O5)
            let K2O = Tools.isEmpty(row.AF1_CompoundFertilizerK2O) ? 0 : Number(row.AF1_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥1【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥1【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF1_CompoundFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF1_CompoundFertilizerDosage) && Tools.isEmpty(row.AF1_CompoundFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF1_CompoundFertilizerK2O)) {
            return new Error('追肥1【复合肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF1_CompoundFertilizerN) ? 0 : Number(row.AF1_CompoundFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.AF1_CompoundFertilizerK2O) ? 0 : Number(row.AF1_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥1【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥1【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF1_CompoundFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF1_CompoundFertilizerDosage) && Tools.isEmpty(row.AF1_CompoundFertilizerN) && Tools.isEmpty(row.AF1_CompoundFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('追肥1【复合肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF1_CompoundFertilizerN) ? 0 : Number(row.AF1_CompoundFertilizerN)
            let P2O5 = Tools.isEmpty(row.AF1_CompoundFertilizerP2O5) ? 0 : Number(row.AF1_CompoundFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥1【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥1【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF1_WaterSolubleFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF1_WaterSolubleFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF1_WaterSolubleFertilizerP2O5) && Tools.isEmpty(row.AF1_WaterSolubleFertilizerK2O)) {
            return new Error('追肥1【水溶肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.AF1_WaterSolubleFertilizerP2O5) ? 0 : Number(row.AF1_WaterSolubleFertilizerP2O5)
            let K2O = Tools.isEmpty(row.AF1_WaterSolubleFertilizerK2O) ? 0 : Number(row.AF1_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥1【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥1【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF1_WaterSolubleFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF1_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.AF1_WaterSolubleFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF1_WaterSolubleFertilizerK2O)) {
            return new Error('追肥1【水溶肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF1_WaterSolubleFertilizerN) ? 0 : Number(row.AF1_WaterSolubleFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.AF1_WaterSolubleFertilizerK2O) ? 0 : Number(row.AF1_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥1【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥1【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF1_WaterSolubleFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF1_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.AF1_WaterSolubleFertilizerN) && Tools.isEmpty(row.AF1_WaterSolubleFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('追肥1【水溶肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF1_WaterSolubleFertilizerN) ? 0 : Number(row.AF1_WaterSolubleFertilizerN)
            let P2O5 = Tools.isEmpty(row.AF1_WaterSolubleFertilizerP2O5) ? 0 : Number(row.AF1_WaterSolubleFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥1【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥1【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF1_OtherFertilizer1Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF1_OtherFertilizer1Id) && Tools.isEmpty(cellValue)) {
            return new Error('追肥1【其他肥料1用量】未填写！')
          }
        }
      }
    ],
    AF1_OtherFertilizer2Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF1_OtherFertilizer2Id) && Tools.isEmpty(cellValue)) {
            return new Error('追肥1【其他肥料2用量】未填写！')
          }
        }
      }
    ],

    AF2_FertilizationMode: [
      {
        validator: ({ cellValue, row }) => {
          if (
            Tools.isNotEmpty(cellValue) &&
            Tools.isEmpty(row.AF2_BusinessOrganicFertilizerDosage) &&
            Tools.isEmpty(row.AF2_UreaDosage) &&
            Tools.isEmpty(row.AF2_AmmoniumBicarbonateDosage) &&
            Tools.isEmpty(row.AF2_DiammoniumPhosphateDosage) &&
            Tools.isEmpty(row.AF2_PotassiumChlorideDosage) &&
            Tools.isEmpty(row.AF2_PotassiumSulphateDosage) &&
            Tools.isEmpty(row.AF2_CompoundFertilizerDosage) &&
            Tools.isEmpty(row.AF2_WaterSolubleFertilizerDosage) &&
            Tools.isEmpty(row.AF2_OtherFertilizer1Id) &&
            Tools.isEmpty(row.AF2_OtherFertilizer2Id)
          ) {
            return new Error('请至少填写一种【追肥2】信息！')
          }
        }
      }
    ],
    AF2_CompoundFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF2_CompoundFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF2_CompoundFertilizerP2O5) && Tools.isEmpty(row.AF2_CompoundFertilizerK2O)) {
            return new Error('追肥2【复合肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.AF2_CompoundFertilizerP2O5) ? 0 : Number(row.AF2_CompoundFertilizerP2O5)
            let K2O = Tools.isEmpty(row.AF2_CompoundFertilizerK2O) ? 0 : Number(row.AF2_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥2【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥2【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF2_CompoundFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF2_CompoundFertilizerDosage) && Tools.isEmpty(row.AF2_CompoundFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF2_CompoundFertilizerK2O)) {
            return new Error('追肥2【复合肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF2_CompoundFertilizerN) ? 0 : Number(row.AF2_CompoundFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.AF2_CompoundFertilizerK2O) ? 0 : Number(row.AF2_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥2【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥2【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF2_CompoundFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF2_CompoundFertilizerDosage) && Tools.isEmpty(row.AF2_CompoundFertilizerN) && Tools.isEmpty(row.AF2_CompoundFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('追肥2【复合肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF2_CompoundFertilizerN) ? 0 : Number(row.AF2_CompoundFertilizerN)
            let P2O5 = Tools.isEmpty(row.AF2_CompoundFertilizerP2O5) ? 0 : Number(row.AF2_CompoundFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥2【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥1【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF2_WaterSolubleFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF2_WaterSolubleFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF2_WaterSolubleFertilizerP2O5) && Tools.isEmpty(row.AF2_WaterSolubleFertilizerK2O)) {
            return new Error('追肥2【水溶肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.AF2_WaterSolubleFertilizerP2O5) ? 0 : Number(row.AF2_WaterSolubleFertilizerP2O5)
            let K2O = Tools.isEmpty(row.AF2_WaterSolubleFertilizerK2O) ? 0 : Number(row.AF2_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥2【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥2【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF2_WaterSolubleFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF2_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.AF2_WaterSolubleFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF2_WaterSolubleFertilizerK2O)) {
            return new Error('追肥2【水溶肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF2_WaterSolubleFertilizerN) ? 0 : Number(row.AF2_WaterSolubleFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.AF2_WaterSolubleFertilizerK2O) ? 0 : Number(row.AF2_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥2【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥2【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF2_WaterSolubleFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF2_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.AF2_WaterSolubleFertilizerN) && Tools.isEmpty(row.AF2_WaterSolubleFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('追肥2【水溶肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF2_WaterSolubleFertilizerN) ? 0 : Number(row.AF2_WaterSolubleFertilizerN)
            let P2O5 = Tools.isEmpty(row.AF2_WaterSolubleFertilizerP2O5) ? 0 : Number(row.AF2_WaterSolubleFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥2【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥2【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF2_OtherFertilizer1Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF2_OtherFertilizer1Id) && Tools.isEmpty(cellValue)) {
            return new Error('追肥2【其他肥料1用量】未填写！')
          }
        }
      }
    ],
    AF2_OtherFertilizer2Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF2_OtherFertilizer2Id) && Tools.isEmpty(cellValue)) {
            return new Error('追肥2【其他肥料2用量】未填写！')
          }
        }
      }
    ],

    AF3_FertilizationMode: [
      {
        validator: ({ cellValue, row }) => {
          if (
            Tools.isNotEmpty(cellValue) &&
            Tools.isEmpty(row.AF3_BusinessOrganicFertilizerDosage) &&
            Tools.isEmpty(row.AF3_UreaDosage) &&
            Tools.isEmpty(row.AF3_AmmoniumBicarbonateDosage) &&
            Tools.isEmpty(row.AF3_DiammoniumPhosphateDosage) &&
            Tools.isEmpty(row.AF3_PotassiumChlorideDosage) &&
            Tools.isEmpty(row.AF3_PotassiumSulphateDosage) &&
            Tools.isEmpty(row.AF3_CompoundFertilizerDosage) &&
            Tools.isEmpty(row.AF3_WaterSolubleFertilizerDosage) &&
            Tools.isEmpty(row.AF3_OtherFertilizer1Id) &&
            Tools.isEmpty(row.AF3_OtherFertilizer2Id)
          ) {
            return new Error('请至少填写一种【追肥3】信息！')
          }
        }
      }
    ],
    AF3_CompoundFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF3_CompoundFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF3_CompoundFertilizerP2O5) && Tools.isEmpty(row.AF3_CompoundFertilizerK2O)) {
            return new Error('追肥3【复合肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.AF3_CompoundFertilizerP2O5) ? 0 : Number(row.AF3_CompoundFertilizerP2O5)
            let K2O = Tools.isEmpty(row.AF3_CompoundFertilizerK2O) ? 0 : Number(row.AF3_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥3【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥3【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF3_CompoundFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF3_CompoundFertilizerDosage) && Tools.isEmpty(row.AF3_CompoundFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF3_CompoundFertilizerK2O)) {
            return new Error('追肥3【复合肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF3_CompoundFertilizerN) ? 0 : Number(row.AF3_CompoundFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.AF3_CompoundFertilizerK2O) ? 0 : Number(row.AF3_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥3【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥3【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF3_CompoundFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF3_CompoundFertilizerDosage) && Tools.isEmpty(row.AF3_CompoundFertilizerN) && Tools.isEmpty(row.AF3_CompoundFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('追肥3【复合肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF3_CompoundFertilizerN) ? 0 : Number(row.AF3_CompoundFertilizerN)
            let P2O5 = Tools.isEmpty(row.AF3_CompoundFertilizerP2O5) ? 0 : Number(row.AF3_CompoundFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥3【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥3【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF3_WaterSolubleFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF3_WaterSolubleFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF3_WaterSolubleFertilizerP2O5) && Tools.isEmpty(row.AF3_WaterSolubleFertilizerK2O)) {
            return new Error('追肥3【水溶肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.AF3_WaterSolubleFertilizerP2O5) ? 0 : Number(row.AF3_WaterSolubleFertilizerP2O5)
            let K2O = Tools.isEmpty(row.AF3_WaterSolubleFertilizerK2O) ? 0 : Number(row.AF3_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥3【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥3【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF3_WaterSolubleFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF3_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.AF3_WaterSolubleFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF3_WaterSolubleFertilizerK2O)) {
            return new Error('追肥3【水溶肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF3_WaterSolubleFertilizerN) ? 0 : Number(row.AF3_WaterSolubleFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.AF3_WaterSolubleFertilizerK2O) ? 0 : Number(row.AF3_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥3【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥3【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF3_WaterSolubleFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF3_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.AF3_WaterSolubleFertilizerN) && Tools.isEmpty(row.AF3_WaterSolubleFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('追肥3【水溶肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF3_WaterSolubleFertilizerN) ? 0 : Number(row.AF3_WaterSolubleFertilizerN)
            let P2O5 = Tools.isEmpty(row.AF3_WaterSolubleFertilizerP2O5) ? 0 : Number(row.AF3_WaterSolubleFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥3【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥3【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF3_OtherFertilizer1Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF3_OtherFertilizer1Id) && Tools.isEmpty(cellValue)) {
            return new Error('追肥3【其他肥料1用量】未填写！')
          }
        }
      }
    ],
    AF3_OtherFertilizer2Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF3_OtherFertilizer2Id) && Tools.isEmpty(cellValue)) {
            return new Error('追肥3【其他肥料2用量】未填写！')
          }
        }
      }
    ],

    AF4_FertilizationMode: [
      {
        validator: ({ cellValue, row }) => {
          if (
            Tools.isNotEmpty(cellValue) &&
            Tools.isEmpty(row.AF4_BusinessOrganicFertilizerDosage) &&
            Tools.isEmpty(row.AF4_UreaDosage) &&
            Tools.isEmpty(row.AF4_AmmoniumBicarbonateDosage) &&
            Tools.isEmpty(row.AF4_DiammoniumPhosphateDosage) &&
            Tools.isEmpty(row.AF4_PotassiumChlorideDosage) &&
            Tools.isEmpty(row.AF4_PotassiumSulphateDosage) &&
            Tools.isEmpty(row.AF4_CompoundFertilizerDosage) &&
            Tools.isEmpty(row.AF4_WaterSolubleFertilizerDosage) &&
            Tools.isEmpty(row.AF4_OtherFertilizer1Id) &&
            Tools.isEmpty(row.AF4_OtherFertilizer2Id)
          ) {
            return new Error('请至少填写一种【追肥4】信息！')
          }
        }
      }
    ],
    AF4_CompoundFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF4_CompoundFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF4_CompoundFertilizerP2O5) && Tools.isEmpty(row.AF4_CompoundFertilizerK2O)) {
            return new Error('追肥4【复合肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.AF4_CompoundFertilizerP2O5) ? 0 : Number(row.AF4_CompoundFertilizerP2O5)
            let K2O = Tools.isEmpty(row.AF4_CompoundFertilizerK2O) ? 0 : Number(row.AF4_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥4【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥4【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF4_CompoundFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF4_CompoundFertilizerDosage) && Tools.isEmpty(row.AF4_CompoundFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF4_CompoundFertilizerK2O)) {
            return new Error('追肥4【复合肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF4_CompoundFertilizerN) ? 0 : Number(row.AF4_CompoundFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.AF4_CompoundFertilizerK2O) ? 0 : Number(row.AF4_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥4【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥4【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF4_CompoundFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF4_CompoundFertilizerDosage) && Tools.isEmpty(row.AF4_CompoundFertilizerN) && Tools.isEmpty(row.AF4_CompoundFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('追肥4【复合肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF4_CompoundFertilizerN) ? 0 : Number(row.AF4_CompoundFertilizerN)
            let P2O5 = Tools.isEmpty(row.AF4_CompoundFertilizerP2O5) ? 0 : Number(row.AF4_CompoundFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥4【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥4【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF4_WaterSolubleFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF4_WaterSolubleFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF4_WaterSolubleFertilizerP2O5) && Tools.isEmpty(row.AF4_WaterSolubleFertilizerK2O)) {
            return new Error('追肥4【水溶肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.AF4_WaterSolubleFertilizerP2O5) ? 0 : Number(row.AF4_WaterSolubleFertilizerP2O5)
            let K2O = Tools.isEmpty(row.AF4_WaterSolubleFertilizerK2O) ? 0 : Number(row.AF4_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥4【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥4【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF4_WaterSolubleFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF4_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.AF4_WaterSolubleFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF4_WaterSolubleFertilizerK2O)) {
            return new Error('追肥4【水溶肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF4_WaterSolubleFertilizerN) ? 0 : Number(row.AF4_WaterSolubleFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.AF4_WaterSolubleFertilizerK2O) ? 0 : Number(row.AF4_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥4【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥4【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF4_WaterSolubleFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF4_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.AF4_WaterSolubleFertilizerN) && Tools.isEmpty(row.AF4_WaterSolubleFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('追肥4【水溶肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF4_WaterSolubleFertilizerN) ? 0 : Number(row.AF4_WaterSolubleFertilizerN)
            let P2O5 = Tools.isEmpty(row.AF4_WaterSolubleFertilizerP2O5) ? 0 : Number(row.AF4_WaterSolubleFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥4【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥4【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF4_OtherFertilizer1Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF4_OtherFertilizer1Id) && Tools.isEmpty(cellValue)) {
            return new Error('追肥4【其他肥料1用量】未填写！')
          }
        }
      }
    ],
    AF4_OtherFertilizer2Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF4_OtherFertilizer2Id) && Tools.isEmpty(cellValue)) {
            return new Error('追肥4【其他肥料2用量】未填写！')
          }
        }
      }
    ],

    AF5_FertilizationMode: [
      {
        validator: ({ cellValue, row }) => {
          if (
            Tools.isNotEmpty(cellValue) &&
            Tools.isEmpty(row.AF5_BusinessOrganicFertilizerDosage) &&
            Tools.isEmpty(row.AF5_UreaDosage) &&
            Tools.isEmpty(row.AF5_AmmoniumBicarbonateDosage) &&
            Tools.isEmpty(row.AF5_DiammoniumPhosphateDosage) &&
            Tools.isEmpty(row.AF5_PotassiumChlorideDosage) &&
            Tools.isEmpty(row.AF5_PotassiumSulphateDosage) &&
            Tools.isEmpty(row.AF5_CompoundFertilizerDosage) &&
            Tools.isEmpty(row.AF5_WaterSolubleFertilizerDosage) &&
            Tools.isEmpty(row.AF5_OtherFertilizer1Id) &&
            Tools.isEmpty(row.AF5_OtherFertilizer2Id)
          ) {
            return new Error('请至少填写一种【追肥5】信息！')
          }
        }
      }
    ],
    AF5_CompoundFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF5_CompoundFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF5_CompoundFertilizerP2O5) && Tools.isEmpty(row.AF5_CompoundFertilizerK2O)) {
            return new Error('追肥5【复合肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.AF5_CompoundFertilizerP2O5) ? 0 : Number(row.AF5_CompoundFertilizerP2O5)
            let K2O = Tools.isEmpty(row.AF5_CompoundFertilizerK2O) ? 0 : Number(row.AF5_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥5【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥5【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF5_CompoundFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF5_CompoundFertilizerDosage) && Tools.isEmpty(row.AF5_CompoundFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF5_CompoundFertilizerK2O)) {
            return new Error('追肥5【复合肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF5_CompoundFertilizerN) ? 0 : Number(row.AF5_CompoundFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.AF5_CompoundFertilizerK2O) ? 0 : Number(row.AF5_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥5【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥5【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF5_CompoundFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF5_CompoundFertilizerDosage) && Tools.isEmpty(row.AF5_CompoundFertilizerN) && Tools.isEmpty(row.AF5_CompoundFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('追肥5【复合肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF5_CompoundFertilizerN) ? 0 : Number(row.AF5_CompoundFertilizerN)
            let P2O5 = Tools.isEmpty(row.AF5_CompoundFertilizerP2O5) ? 0 : Number(row.AF5_CompoundFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥5【复合肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥5【复合肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF5_WaterSolubleFertilizerN: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF5_WaterSolubleFertilizerDosage) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF5_WaterSolubleFertilizerP2O5) && Tools.isEmpty(row.AF5_WaterSolubleFertilizerK2O)) {
            return new Error('追肥5【水溶肥N】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Number(cellValue)
            let P2O5 = Tools.isEmpty(row.AF5_WaterSolubleFertilizerP2O5) ? 0 : Number(row.AF5_WaterSolubleFertilizerP2O5)
            let K2O = Tools.isEmpty(row.AF5_WaterSolubleFertilizerK2O) ? 0 : Number(row.AF5_WaterSolubleFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥5【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥5【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF5_WaterSolubleFertilizerP2O5: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF5_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.AF5_WaterSolubleFertilizerN) && Tools.isEmpty(cellValue) && Tools.isEmpty(row.AF5_WaterSolubleFertilizerK2O)) {
            return new Error('追肥5【水溶肥P2O5】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF5_WaterSolubleFertilizerN) ? 0 : Number(row.AF5_WaterSolubleFertilizerN)
            let P2O5 = Number(cellValue)
            let K2O = Tools.isEmpty(row.AF5_CompoundFertilizerK2O) ? 0 : Number(row.AF5_CompoundFertilizerK2O)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥5【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥5【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF5_WaterSolubleFertilizerK2O: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF5_WaterSolubleFertilizerDosage) && Tools.isEmpty(row.AF5_WaterSolubleFertilizerN) && Tools.isEmpty(row.AF5_WaterSolubleFertilizerP2O5) && Tools.isEmpty(cellValue)) {
            return new Error('追肥5【水溶肥K2O】未填写！')
          }

          if (Tools.isNotEmpty(cellValue)) {
            let N = Tools.isEmpty(row.AF5_WaterSolubleFertilizerN) ? 0 : Number(row.AF5_WaterSolubleFertilizerN)
            let P2O5 = Tools.isEmpty(row.AF5_WaterSolubleFertilizerP2O5) ? 0 : Number(row.AF5_WaterSolubleFertilizerP2O5)
            let K2O = Number(cellValue)
            let total = N + P2O5 + K2O
            if (0 === total) {
              return new Error('追肥5【水溶肥 N + P2O5 + K2O】错误值，不能等于0！')
            } else if (total > 100) {
              return new Error('追肥5【水溶肥 N + P2O5 + K2O】错误值，不能大于100！')
            }
          }
        }
      }
    ],
    AF5_OtherFertilizer1Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF5_OtherFertilizer1Id) && Tools.isEmpty(cellValue)) {
            return new Error('追肥5【其他肥料1用量】未填写！')
          }
        }
      }
    ],
    AF5_OtherFertilizer2Dosage: [
      {
        validator: ({ cellValue, row }) => {
          if (Tools.isNotEmpty(row.AF5_OtherFertilizer2Id) && Tools.isEmpty(cellValue)) {
            return new Error('追肥5【其他肥料2用量】未填写！')
          }
        }
      }
    ]
  }
}