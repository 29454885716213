import httpRequest from '@/utils/http-request'

export default {
  getPaging(year, regionId, assistFlag, archiveFilter, keyword, sortCondition, pageSize, pageIndex) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        assistFlag: assistFlag,
        archiveFilter: archiveFilter,
        keyword: keyword,
        sortCondition: sortCondition,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }
    return httpRequest.get('/archived-farmer-plant-crop-paging', config)
  },
  exportData(exportId, year, regionId, assistFlag, archiveFilter, keyword, sortCondition) {
    let config = {
      params: {
        exportId: exportId,
        year: year,
        regionId: regionId,
        assistFlag: assistFlag,
        archiveFilter: archiveFilter,
        keyword: keyword,
        sortCondition: sortCondition
      }
    }
    return httpRequest.post('/archived-farmer-data-export', null, config)
  }
}
