import httpRequest from '@/utils/http-request'

export default {
  getFillInStatisticData(year) {
    let config = {
      params: {
        year: year
      }
    }
    return httpRequest.get('/country-fill-in-statistic-data', config)
  },
  exportData(year) {
    let config = {
      params: {
        year: year
      }
    }
    return httpRequest.post('/country-fill-in-statistic-data-export', null, config)
  }
}