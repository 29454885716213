<template>
  <div>
    <div class="wg-box">
      <div class="body">
        <div class="wg-table-box">
          <el-table :data="tableData" class="wg-table normal" :max-height="180" border style="width: 100%">
            <el-table-column label="序号" type="index" width="100" fixed></el-table-column>
            <el-table-column label="作物分类" prop="cropSubCategoryName" width="120" fixed></el-table-column>
            <el-table-column label="作物名称" prop="cropName" width="120" fixed></el-table-column>
            <el-table-column label="作物备注" prop="cropRemark" width="120"></el-table-column>
            <el-table-column label="播种日期" prop="sowingDate" width="150"></el-table-column>
            <el-table-column label="收获日期" prop="harvestTime" width="150"></el-table-column>
            <el-table-column prop="sowingArea" width="120">
              <template slot="header">播种面积<br />（亩）</template>
            </el-table-column>
            <el-table-column label="地块肥力" prop="plotFertilityName" width="120"></el-table-column>
            <el-table-column prop="yield" width="120">
              <template slot="header">亩产量<br />（公斤/亩）</template>
            </el-table-column>
            <el-table-column prop="yieldLevelName" width="120">
              <template slot="header">
                产量水平
                <el-tooltip content="产量水平指该作物产量相对当地水平如何。" placement="top">
                  <i class="el-icon-question" style="font-size: 18px; color:#E6A23C"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="facilityCultivationFlag" width="120">
              <template slot="header">是否<br />设施栽培</template>
              <template slot-scope="scope">
                <div :style="scope.row.facilityCultivationFlag ? 'color:#039F3A' : 'color:#FF8900'">
                  {{ scope.row.facilityCultivationFlag ? '是' : '否' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="happenDisasterFlag" width="120">
              <template slot="header">是否<br />种植季受灾</template>
              <template slot-scope="scope">
                <div :style="null === scope.row.happenDisasterFlag ? 'color:#333' : scope.row.happenDisasterFlag ? 'color:#039F3A' : 'color:#FF8900'">
                  {{ null === scope.row.happenDisasterFlag ? '--' : scope.row.happenDisasterFlag ? '是' : '否' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="previousCropSubCategoryName" label="前茬作物" width="120"></el-table-column>
            <el-table-column width="120">
              <template slot="header">前茬作物亩产量<br />（公斤/亩）</template>
            </el-table-column>
            <el-table-column width="120">
              <template slot="header">前茬秸秆还田率<br />（%）</template>
            </el-table-column>
            <el-table-column label="是否提交" prop="submitFlag">
              <template slot-scope="scope">
                <div :style="scope.row.submitFlag ? 'color:#039F3A' : 'color:#FF8900'">
                  {{ scope.row.submitFlag ? '是' : '否' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="submitTime" label="提交时间" width="140"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-collapse class="wg-collapse" v-model="collapseActiveNames">
      <el-collapse-item name="baseFertilizer">
        <template slot="title">
          <div class="header background-style1">
            <div class="title">底肥信息 <span>（单位：公斤/亩）</span></div>
          </div>
          <div class="wg-clear-fix"></div>
        </template>
        <base-fertilizer ref="baseFertilizerComponent" :fullPlantCropModel="fullPlantCropModel"></base-fertilizer>
      </el-collapse-item>
      <!-- 底肥信息 -->
      <el-collapse-item name="afterFertilizer">
        <template slot="title">
          <div class="header background-style1">
            <div class="title">追肥信息 <span>（单位：公斤/亩）</span></div>
          </div>
          <div class="wg-clear-fix"></div>
        </template>
        <after-fertilizer ref="afterFertilizerComponent" :fullPlantCropModel="fullPlantCropModel"></after-fertilizer>
      </el-collapse-item>
      <!-- 追肥信息 -->
      <el-collapse-item name="unitAreaChemicalFertilizerDosage">
        <template slot="title">
          <div class="header background-style1">
            <div class="title">单位面积化肥养分投入量<span>（单位：公斤/亩）</span></div>
          </div>
        </template>
        <unit-area-chemical-fertilizer-dosage ref="untAreaChemicalFertilizerDosageComponent" :fullPlantCropModel="fullPlantCropModel"></unit-area-chemical-fertilizer-dosage>
      </el-collapse-item>
      <!-- 单位面积 -->
      <el-collapse-item name="surveyRelatedPicture" v-if="showSurveyRelatedPicture">
        <template slot="title">
          <div class="header background-style1">
            <div class="title">施肥调查相关图片</div>
          </div>
        </template>
        <survey-related-picture :fullPlantCropModel="fullPlantCropModel"></survey-related-picture>
      </el-collapse-item>
      <!-- 施肥调查相关图片上传 -->
    </el-collapse>
  </div>
</template>

<script>
import ArchivedBaseFertilizer from './ArchivedBaseFertilizer'
import ArchivedAfterFertilizer from './ArchivedAfterFertilizer'
import ArchivedUnitAreaChemicalFertilizerDosage from './ArchivedUnitAreaChemicalFertilizerDosage'
import ArchivedSurveyRelatedPicture from './ArchivedSurveyRelatedPicture'

export default {
  name: 'ArchivedFarmerPlantCrop',
  components: {
    'base-fertilizer': ArchivedBaseFertilizer,
    'after-fertilizer': ArchivedAfterFertilizer,
    'unit-area-chemical-fertilizer-dosage': ArchivedUnitAreaChemicalFertilizerDosage,
    'survey-related-picture': ArchivedSurveyRelatedPicture
  },
  props: {
    fullPlantCropModel: Object
  },
  data() {
    return {
      showSurveyRelatedPicture: false,
      collapseActiveNames: ['baseFertilizer', 'afterFertilizer', 'unitAreaChemicalFertilizerDosage', 'surveyRelatedPicture'],
      tableData: []
    }
  },
  created() {
    this.showSurveyRelatedPicture = 'Farmer' === sessionStorage.getItem('levelId')

    let plantCropModel = this.fullPlantCropModel.plantCrop
    this.tableData.push({
      id: plantCropModel.id,
      year: this.fullPlantCropModel.year,
      monitoringPlotId: plantCropModel.monitoringPlotId,
      cropRootCategoryId: plantCropModel.cropRootCategoryId,
      cropRootCategoryName: plantCropModel.cropRootCategoryName,
      cropSubCategoryId: plantCropModel.cropSubCategoryId,
      cropSubCategoryName: plantCropModel.cropSubCategoryName,
      cropId: plantCropModel.cropId,
      cropName: plantCropModel.cropName,
      sowingDate: null === plantCropModel.sowingDate ? null : this.$moment(plantCropModel.sowingDate).format('YYYY-MM-DD'),
      harvestTime: null === plantCropModel.harvestTime ? null : this.$moment(plantCropModel.harvestTime).format('YYYY-MM-DD'),
      sowingArea: plantCropModel.sowingArea,
      plotFertility: plantCropModel.plotFertility,
      plotFertilityName: plantCropModel.plotFertilityName,
      yield: null === plantCropModel.yield ? null : plantCropModel.yield.toFixed(2),
      yieldLevel: plantCropModel.yieldLevel,
      yieldLevelName: plantCropModel.yieldLevelName,
      facilityCultivationFlag: plantCropModel.facilityCultivationFlag,
      happenDisasterFlag: plantCropModel.happenDisasterFlag,
      previousCropSubCategoryId: plantCropModel.previousCropSubCategoryId,
      previousCropSubCategoryName: plantCropModel.previousCropSubCategoryName,
      previousYield: plantCropModel.previousYield,
      previousStrawReturnRate: plantCropModel.previousStrawReturnRate,
      submitFlag: plantCropModel.submitFlag,
      submitTime: plantCropModel.submitTime,
      remark1: plantCropModel.remark1,
      remark2: plantCropModel.remark2
    })
  }
}
</script>

<style scoped></style>
