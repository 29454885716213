<template>
  <el-tree ref="regionTree" :props="regionTreeProps" node-key="id" class="wg-tree" :expand-on-click-node="false"
           :default-expanded-keys="defaultExpandedKeys"
           :indent="0" :load="loadNode" highlight-current lazy @node-click="handleNodeClick"></el-tree>
</template>

<script>
import FillInCycleApi from '@/api/data-fill-in/cycle/fill-in-cycle-api'
import RegionApi from '@/api/region-api'

export default {
  name: 'RegionTree',
  data() {
    return {
      regionTreeProps: {
        label: 'label',
        children: 'regionList',
        isLeaf: 'leaf'
      },
      defaultExpandedKeys: []
    }
  },
  methods: {
    handleNodeClick(node) {
      let checkedItem = {
        year: node.year,
        regionId: node.regionId,
        archiveFlag: node.archiveFlag
      }
      this.$emit('node-click', checkedItem)
    },
    async loadNode(node, resolve) {
      let userLevelId = sessionStorage.getItem('levelId')
      // node is root
      if (node.level === 0) {
        let rootArray = []
        let fillInCycleList = []

        await FillInCycleApi.getList().then(res => {
          fillInCycleList = res.data
        })

        fillInCycleList.forEach(el => {
          if (this.defaultExpandedKeys.length === 0 && userLevelId !== 'District')
            this.defaultExpandedKeys.push(el.year)

          let tempNode = {
            id: el.year,
            type: userLevelId,
            label: null,
            leaf: 'District' === userLevelId,
            year: el.year,
            archiveFlag: el.archiveFlag,
            regionId: null
          }

          tempNode.label = el.archiveFlag ? el.year + '年（已归档）' : el.year + '年'

          switch (userLevelId) {
            case 'Province':
              tempNode.regionId = sessionStorage.getItem('provinceId')
              break
            case 'City':
              tempNode.regionId = sessionStorage.getItem('cityId')
              break
            case 'District':
              tempNode.regionId = sessionStorage.getItem('districtId')
              break
          }

          rootArray.push(tempNode)
        })

        this.$nextTick(() => {
          this.$refs.regionTree.setCurrentKey(rootArray[0].id)
        })

        return resolve(rootArray)
      }

      // node is country
      if ('Country' === node.data.type) {
        let tempNodeList = []
        await RegionApi.getProvinceList().then(res => {
          res.data.forEach(el => {
            let tempNode = {
              id: el.year + el.id,
              type: 'Province',
              label: el.name,
              leaf: false,
              year: node.data.year,
              regionId: el.id,
              archiveFlag: node.data.archiveFlag
            }
            tempNodeList.push(tempNode)
          })
        })
        return resolve(tempNodeList)
      }

      // node is province
      if ('Province' === node.data.type) {
        let tempNodeList = []
        await RegionApi.getCityList(node.data.regionId).then(res => {
          res.data.forEach(el => {
            let tempNode = {
              id: el.year + el.id,
              type: 'City',
              label: el.name,
              leaf: false,
              year: node.data.year,
              regionId: el.id,
              archiveFlag: node.data.archiveFlag
            }
            tempNodeList.push(tempNode)
          })
        })
        return resolve(tempNodeList)
      }

      // node is city
      if ('City' === node.data.type) {
        let tempNodeList = []
        await RegionApi.getDistrictList(node.data.regionId).then(res => {
          res.data.forEach(el => {
            let tempNode = {
              id: el.year + el.id,
              type: 'District',
              label: el.name,
              leaf: true,
              year: node.data.year,
              regionId: el.id,
              archiveFlag: node.data.archiveFlag
            }
            tempNodeList.push(tempNode)
          })
        })
        return resolve(tempNodeList)
      }
    }
  }
}
</script>

<style scoped></style>