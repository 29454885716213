<template>
    <div>
      <div class="wg-breadcrumb">
        <el-breadcrumb separator="/" class="bread_crumb">
          <el-breadcrumb-item :to="{ path: '/' }">图表分析</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/annual-analysis' }">年度分析</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- breadcrumb -->
      <div class="wg-box">
          <el-tabs class="wg-tabs" v-model="tabActivedName">
            <el-tab-pane name="years" label="年际间情况" :lazy="true">
              <annual-year-search-form ref="yearSearchForm"></annual-year-search-form>
            </el-tab-pane>
            <el-tab-pane name="crops" label="各作物施肥情况" :lazy="true">
              <annual-crop-search-form ref="cropSearchForm"></annual-crop-search-form>
            </el-tab-pane>
            <el-tab-pane name="regions" label="各地区施肥情况" :lazy="true">
              <annual-region-search-form ref="regionSearchForm"></annual-region-search-form>
            </el-tab-pane>
          </el-tabs>
      </div>
    </div>
</template>

<script>
import AnnualYearSearchForm from '@/views/chart-analysis/components/annual/YearsSearchForm.vue'
import AnnualCropSearchForm from '@/views/chart-analysis/components/annual/CropsSearchForm.vue'
import AnnualRegionSearchForm from '@/views/chart-analysis/components/annual/RegionsSearchForm.vue'

export default {
  name: 'AnnualAnalysis',
  components: {
    AnnualRegionSearchForm,
    AnnualCropSearchForm,
    AnnualYearSearchForm
  },
  data() {
    return {
      tabActivedName: 'years',
      searchForm: {
        groupType: 'years'
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>
.el-card {
  border: 0;

}
.el-card ::v-deep .el-card__header {
  padding: 2px 10px;
  height: 50px;
}
.el-card ::v-deep .el-card__body {
  padding: 0px;
  background: #FFFFFF;
  border: 0;
}
</style>
