<template>
  <div class="wg-box" v-loading="dataLoading" element-loading-text="拼命加载数据......" element-loading-spinner="el-icon-loading">
    <div class="header">
      <div class="button-list">
        <el-button size="small" class="wg-button green" icon="el-icon-plus" v-if="!flags.archiveFlag" @click="handleAdd">新增</el-button>
        <el-button size="small" class="wg-button blue" icon="el-icon-edit" v-if="!flags.archiveFlag" @click="handleEdit">编辑</el-button>
        <el-button size="small" class="wg-button orange" icon="el-icon-check" v-if="!flags.archiveFlag && showSubmitBtn" @click="handleSubmit">提交</el-button>
        <el-button size="small" class="wg-button red" icon="el-icon-delete" v-if="!flags.archiveFlag" @click="handleDelete">删除</el-button>
        <el-button size="small" class="wg-button blue" icon="el-icon-download" @click="handleExportData">导出
        </el-button>
      </div>
      <div class="wg-clear-fix"></div>
    </div>
    <div class="body">
      <div style="padding: 10px 10px 0 10px" v-if="!flags.archiveFlag">
        <el-alert type="warning" show-icon :closable="false">
          <ul class="statistic-explain-list">
            <li>点击数据行并且点击编辑按钮可对该数据行进行编辑！</li>
            <li>双击任何数据行也可以对该行进行编辑！</li>
          </ul>
        </el-alert>
      </div>
      <div class="wg-table-box" style="padding-bottom: 10px">
        <el-table
          :data="tableData"
          class="wg-table normal"
          border
          :highlight-current-row="true"
          @row-click="handleRowClick"
          @row-dblclick="handleRowDoubleClick"
          @selection-change="handleSelectionChange"
          :max-height="350"
          style="width: 100%"
        >
          <el-table-column type="selection" width="40" fixed></el-table-column>
          <el-table-column type="index" label="序号" width="50" fixed></el-table-column>
          <el-table-column label="省份" prop="provinceName" fixed></el-table-column>
          <el-table-column label="地市" prop="cityName" fixed></el-table-column>
          <el-table-column label="区县" prop="districtName" fixed></el-table-column>
          <el-table-column label="代码" prop="regionId"></el-table-column>
          <el-table-column label="填报日期" prop="fillDate" width="120"></el-table-column>
          <el-table-column label="总体情况">
            <el-table-column prop="plantArea" width="120">
              <template slot="header">种植面积<br />（万亩）</template>
            </el-table-column>
            <el-table-column width="120" prop="totalYield">
              <template slot="header">总产量<br />（万吨）</template>
            </el-table-column>
            <el-table-column label="氮肥（万吨）">
              <el-table-column prop="nitrogenTotal" width="120">
                <template slot="header">总量<br />（N）
                </template>
              </el-table-column>
              <el-table-column prop="urea" width="120">
                <template slot="header">其中:<br />尿素</template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="磷肥（万吨）">
              <el-table-column prop="phosphorusTotal" width="120">
                <template slot="header">总量<br />（P2O5）</template>
              </el-table-column>
              <el-table-column prop="diammoniumPhosphate" width="120">
                <template slot="header">其中：<br />磷酸二铵</template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="钾肥（万吨）">
              <el-table-column prop="potassiumTotal" width="120">
                <template slot="header">总量<br />（K2O）</template>
              </el-table-column>
              <el-table-column prop="potassiumChloride" width="120">
                <template slot="header">其中:<br />氯化钾</template>
              </el-table-column>
              <el-table-column prop="potassiumSulphate" width="120">
                <template slot="header">其中:<br />硫酸钾</template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="复合肥料（万吨，实物)">
              <el-table-column label="低浓度" prop="compoundFertilizerLow" width="120"></el-table-column>
              <el-table-column label="中浓度" prop="compoundFertilizerMiddle" width="120"></el-table-column>
              <el-table-column label="高浓度" prop="compoundFertilizerHigh" width="120"></el-table-column>
            </el-table-column>
            <el-table-column label="有机肥料（万吨，实物）">
              <el-table-column label="总量" prop="organicFertilizerTotal" width="120"></el-table-column>
              <el-table-column prop="businessOrganicFertilizer" width="120">
                <template slot="header">商品<br />有机肥</template>
              </el-table-column>
              <el-table-column label="其他" prop="organicFertilizerOther" width="120"></el-table-column>
            </el-table-column>
          </el-table-column>
          <el-table-column v-for="(item, index) in [0, 1, 2, 3, 4]" :label="'主要作物' + Number(index + 1)" :key="index" align="center">
            <el-table-column label="作物分类" width="100" :prop="'stapleCropFertilizerDosageList.' + index + '.cropSubCategoryName'"></el-table-column>
            <el-table-column label="作物名称" width="100" :prop="'stapleCropFertilizerDosageList.' + index + '.cropName'"></el-table-column>
            <el-table-column width="120" :prop="'stapleCropFertilizerDosageList.' + index + '.plantArea'">
              <template slot="header">种植面积<br />（万亩）</template>
            </el-table-column>
            <el-table-column width="120" :prop="'stapleCropFertilizerDosageList.' + index + '.totalYield'">
              <template slot="header">总产量<br />（万吨）</template>
            </el-table-column>
            <el-table-column label="氮肥（万吨）">
              <el-table-column width="120" :prop="'stapleCropFertilizerDosageList.' + index + '.nitrogenTotal'">
                <template slot="header">总量<br />（N）</template>
              </el-table-column>
              <el-table-column width="120" :prop="'stapleCropFertilizerDosageList.' + index + '.urea'">
                <template slot="header">其中：<br />尿素</template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="磷肥（万吨）">
              <el-table-column width="120" :prop="'stapleCropFertilizerDosageList.' + index + '.phosphorusTotal'">
                <template slot="header">总量<br />（P2O5）</template>
              </el-table-column>
              <el-table-column width="120" :prop="'stapleCropFertilizerDosageList.' + index + '.diammoniumPhosphate'">
                <template slot="header">其中：<br />磷酸二铵</template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="钾肥（万吨）">
              <el-table-column width="120" :prop="'stapleCropFertilizerDosageList.' + index + '.potassiumTotal'">
                <template slot="header">总量<br />（K2O）</template>
              </el-table-column>
              <el-table-column width="120" :prop="'stapleCropFertilizerDosageList.' + index + '.potassiumChloride'">
                <template slot="header">其中：<br />氯化钾</template>
              </el-table-column>
              <el-table-column width="120" :prop="'stapleCropFertilizerDosageList.' + index + '.potassiumSulphate'">
                <template slot="header">其中：<br />硫酸钾</template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="复合肥料（万吨，实物)">
              <el-table-column label="低浓度" :prop="'stapleCropFertilizerDosageList.' + index + '.compoundFertilizerLow'" width="120"></el-table-column>
              <el-table-column label="中浓度" :prop="'stapleCropFertilizerDosageList.' + index + '.compoundFertilizerMiddle'" width="120"></el-table-column>
              <el-table-column label="高浓度" :prop="'stapleCropFertilizerDosageList.' + index + '.compoundFertilizerHigh'" width="120"></el-table-column>
            </el-table-column>
            <el-table-column label="有机肥料（万吨，实物）" :prop="'stapleCropFertilizerDosageList.' + index + '.organicFertilizerTotal'">
              <el-table-column label="总量" width="120"></el-table-column>
              <el-table-column width="120" :prop="'stapleCropFertilizerDosageList.' + index + '.businessOrganicFertilizer'">
                <template slot="header">商品<br />有机肥</template>
              </el-table-column>
              <el-table-column label="其他" width="120" :prop="'stapleCropFertilizerDosageList.' + index + '.organicFertilizerOther'"></el-table-column>
            </el-table-column>
          </el-table-column>
          <el-table-column label="提交" width="80" prop="submitFlag" fixed="right">
            <template slot-scope="scope">
              <div :style="null === scope.row.submitFlag ? 'color:#333' : scope.row.submitFlag ? 'color:#039F3A' : 'color:#FF8900'">{{ scope.row.submitFlag ? '是' : '否' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80" fixed="right" v-if="!flags.archiveFlag">
            <template slot-scope="scope">
              <el-button type="text" size="mini" v-if="!scope.row.submitFlag || flags.editFlag" @click="handleRowEdit(scope.row)">编辑</el-button>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="wg-pagination-box">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :total="recordTotal"
          :page-sizes="[30, 50, 100]"
          :page-size="pageSize"
          :current-page="pageIndex"
          @size-change="handleSizeChange"
          @current-change="handlePagingChange"
        ></el-pagination>
      </div>
    </div>
    <surface-survey-district-add
      ref="surfaceSurveyDistrictAddComponent"
      v-if="isLoadAddWindow"
      :provinceList="provinceList"
      :cityList="cityList"
      :districtList="districtList"
      :cropRootCategoryArray="cropRootCategoryArray"
      :cropSubCategoryArray="cropSubCategoryArray"
      :cropArray="cropArray"
      @close="handleCloseAddWindow"
    >
    </surface-survey-district-add>
    <surface-survey-district-modify
      ref="surfaceSurveyDistrictModifyComponent"
      v-if="isLoadModifyWindow"
      :provinceList="provinceList"
      :cityList="cityList"
      :districtList="districtList"
      :cropRootCategoryArray="cropRootCategoryArray"
      :cropSubCategoryArray="cropSubCategoryArray"
      :cropArray="cropArray"
      @close="handleCloseModifyWindow"
    >
    </surface-survey-district-modify>
  </div>
</template>

<script>
import FertilizerDosageApi from '@/api/survey/fertilizer-dosage-api'
import FileApi from '@/api/file-api'

import SurfaceSurveyAdd from './SurfaceSurveyAdd'
import SurfaceSurveyModify from './SurfaceSurveyModify'

export default {
  name: 'SurfaceSurveyManyDistrict',
  props: {
    provinceList: [],
    cityList: [],
    districtList: [],
    cropRootCategoryArray: [],
    cropSubCategoryArray: [],
    cropArray: []
  },
  components: {
    'surface-survey-district-add': SurfaceSurveyAdd,
    'surface-survey-district-modify': SurfaceSurveyModify
  },
  data() {
    return {
      dataLoading: false,
      isLoadAddWindow: true,
      isLoadModifyWindow: true,
      showSubmitBtn: false,
      flags: {
        archiveFlag: null,
        editFlag: false
      },
      params: {
        year: null,
        regionId: null
      },
      tableData: [],
      recordTotal: 0,
      pageSize: 30,
      pageIndex: 1,
      selectedRows: [],
      selectedRow: null
    }
  },
  created() {
    let userLevelId = sessionStorage.getItem('levelId')
    this.showSubmitBtn = 'District' === userLevelId
    this.flags.editFlag = 'City' === userLevelId || 'Province' === userLevelId || 'Country' === userLevelId
  },
  methods: {
    handleAdd() {
      this.$refs.surfaceSurveyDistrictAddComponent.open(this.params.year, 'District', this.params.regionId)
    },
    handleCloseAddWindow(args) {
      if (args.saved) {
        this.pageIndex = 1
        this.bindTableData()
      }

      this.isLoadAddWindow = false
      this.$nextTick(() => {
        this.isLoadAddWindow = true
      })
    },
    handleCloseModifyWindow(args) {
      if (args.saved) {
        this.bindTableData()
      }

      this.isLoadModifyWindow = false
      this.$nextTick(() => {
        this.isLoadModifyWindow = true
      })
    },
    handleSizeChange(pageSize) {
      this.pageIndex = 1
      this.pageSize = pageSize
      this.bindTableData()
    },
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
      this.bindTableData()
    },
    handleRowClick(row) {
      this.selectedRow = row
    },
    handleRowDoubleClick(row) {
      if ((this.flags.archiveFlag || row.submitFlag) && !this.flags.editFlag) {
        return
      }

      this.$refs.surfaceSurveyDistrictModifyComponent.open(row, 'District')
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows
    },
    handleExportData() {
      this.$confirm('导出区县面上调查数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let exportId = null
          let exportCount = 0
          let finished = false

          while (!finished) {
            const loading = this.$loading({
              lock: true,
              text: 0 == exportCount ? '正在导出数据......' : '已导出' + exportCount + '条数据......',
              spinner: 'el-icon-loading'
            })

            await FertilizerDosageApi.exportDistrictData(exportId, this.params.year, this.params.regionId)
              .then(res => {
                exportId = res.data.id
                exportCount = res.data.exportCount
                finished = res.data.finished
                loading.close()
              })
              .catch(err => {
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
              .finally(() => {
                loading.close()
              })
          }

          const loading = this.$loading({
            lock: true,
            text: '等待下载数据文件......',
            spinner: 'el-icon-loading'
          })

          await FileApi.get('TEMP', exportId + '.xlsx')
            .then(res => {
              let byteBuffer = window.atob(res.data)
              let arrayBuffer = new ArrayBuffer(byteBuffer.length)
              let dataBuffer = new Uint8Array(arrayBuffer)
              for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

              let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(blob)
              link.setAttribute('download', '区县面上调查数据.xlsx')
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(link.href)
              document.body.removeChild(link)
            })
            .catch(err => {
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })

          await FileApi.delete('TEMP', exportId + '.xlsx')
            .then(() => {
            })
            .catch(err => {
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
            .finally(() => {
              loading.close()
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
        })
    },
    handleDelete() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      let theObj = this
      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          for (let i = 0; i < theObj.selectedRows.length; i++) {
            await theObj.delete(theObj.selectedRows[i])
          }

          this.pageIndex = 1
          await theObj.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除', type: 'info' })
        })
    },
    handleEdit() {
      if (null === this.selectedRow) {
        this.$message({ showClose: true, message: '请点击数据行！', type: 'warning' })
        return
      }

      if ((this.flags.archiveFlag || this.selectedRow.submitFlag) && !this.flags.editFlag) {
        return
      }

      this.$refs.surfaceSurveyDistrictModifyComponent.open(this.selectedRow, 'District')
    },
    handleRowEdit(row) {
      this.$refs.surfaceSurveyDistrictModifyComponent.open(row, 'District')
    },
    handleSubmit() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      let theObj = this
      this.$confirm('此操作将提交数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          for (let i = 0; i < theObj.selectedRows.length; i++) {
            if (theObj.selectedRows[i].submitFlag) {
              await this.$message({
                showClose: true,
                message: '记录【' + theObj.selectedRows[i].fillDate + '】已提交！',
                type: 'success'
              })
            } else {
              await theObj.submit(theObj.selectedRows[i])
            }
          }

          await theObj.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消操作！', type: 'info' })
        })
    },
    load(year, regionId, archiveFlag) {
      this.params.year = year
      this.params.regionId = regionId
      this.flags.archiveFlag = archiveFlag
      this.bindTableData()
    },
    async bindTableData() {
      this.dataLoading = true
      this.selectedRow = null
      await FertilizerDosageApi.getDistrictPaging(this.params.year, this.params.regionId, this.pageSize, this.pageIndex)
        .then(res => {
          this.tableData = []
          this.recordTotal = res.data.totalCount

          res.data.fertilizerDosageList.forEach(el => {
            let row = {
              id: el.id,
              year: el.year,
              provinceId: el.provinceId,
              provinceName: el.provinceName,
              cityId: el.cityId,
              cityName: el.cityName,
              districtId: el.districtId,
              districtName: el.districtName,
              regionId: el.districtId,
              fillDate: this.$utils.isEmpty(el.fillDate) ? null : this.$moment(el.fillDate).format('YYYY-MM-DD'),
              plantArea: el.plantArea,
              totalYield: el.totalYield,
              nitrogenTotal: el.nitrogenTotal,
              urea: el.urea,
              phosphorusTotal: el.phosphorusTotal,
              diammoniumPhosphate: el.diammoniumPhosphate,
              potassiumTotal: el.potassiumTotal,
              potassiumChloride: el.potassiumChloride,
              potassiumSulphate: el.potassiumSulphate,
              compoundFertilizerLow: el.compoundFertilizerLow,
              compoundFertilizerMiddle: el.compoundFertilizerMiddle,
              compoundFertilizerHigh: el.compoundFertilizerHigh,
              organicFertilizerTotal: el.organicFertilizerTotal,
              businessOrganicFertilizer: el.businessOrganicFertilizer,
              organicFertilizerOther: el.organicFertilizerOther,
              createdOn: el.createdOn,
              createdByFullName: el.createdByFullName,
              modifiedOn: el.modifiedOn,
              modifiedByFullName: el.modifiedByFullName,
              submitFlag: el.submitFlag,
              stapleCropFertilizerDosageList: []
            }

            el.stapleCropFertilizerDosageList.forEach(mainCrop => {
              row.stapleCropFertilizerDosageList.push({
                id: mainCrop.id,
                cropRootCategoryId: mainCrop.cropRootCategoryId,
                cropRootCategoryName: mainCrop.cropRootCategoryName,
                cropSubCategoryId: mainCrop.cropSubCategoryId,
                cropSubCategoryName: mainCrop.cropSubCategoryName,
                cropId: mainCrop.cropId,
                cropName: mainCrop.cropName,
                plantArea: mainCrop.plantArea,
                totalYield: mainCrop.totalYield,
                nitrogenTotal: mainCrop.nitrogenTotal,
                urea: mainCrop.urea,
                phosphorusTotal: mainCrop.phosphorusTotal,
                diammoniumPhosphate: mainCrop.diammoniumPhosphate,
                potassiumTotal: mainCrop.potassiumTotal,
                potassiumChloride: mainCrop.potassiumChloride,
                potassiumSulphate: mainCrop.potassiumSulphate,
                compoundFertilizerLow: mainCrop.compoundFertilizerLow,
                compoundFertilizerMiddle: mainCrop.compoundFertilizerMiddle,
                compoundFertilizerHigh: mainCrop.compoundFertilizerHigh,
                organicFertilizerTotal: mainCrop.organicFertilizerTotal,
                businessOrganicFertilizer: mainCrop.businessOrganicFertilizer,
                organicFertilizerOther: mainCrop.organicFertilizerOther,
                removeFlag: false
              })
            })

            let mainCropCount = el.stapleCropFertilizerDosageList.length
            if (mainCropCount < 5) {
              for (; mainCropCount < 5; mainCropCount++) {
                row.stapleCropFertilizerDosageList.push({
                  id: null,
                  fertilizerDosageId: null,
                  cropRootCategoryId: null,
                  cropRootCategoryName: null,
                  cropSubCategoryId: null,
                  cropSubCategoryName: null,
                  cropId: null,
                  cropName: null,
                  plantArea: null,
                  totalYield: null,
                  nitrogenTotal: null,
                  urea: null,
                  phosphorusTotal: null,
                  diammoniumPhosphate: null,
                  potassiumTotal: null,
                  potassiumChloride: null,
                  potassiumSulphate: null,
                  compoundFertilizerLow: null,
                  compoundFertilizerMiddle: null,
                  compoundFertilizerHigh: null,
                  organicFertilizerTotal: null,
                  businessOrganicFertilizer: null,
                  organicFertilizerOther: null,
                  removeFlag: false
                })
              }
            }

            this.tableData.push(row)
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
        .finally(() => {
          this.dataLoading = false
        })
    },
    async delete(row) {
      await FertilizerDosageApi.deleteById(row.id)
        .then(() => {
          this.$message({ showClose: true, message: '记录【' + row.fillDate + '】删除成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async submit(row) {
      FertilizerDosageApi.submit(row.id)
        .then(() => {
          this.$message({ showClose: true, message: '记录【' + row.fillDate + '】提交成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>
