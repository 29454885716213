import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/assist-fill-in-data', createModel)
  },
  createMultiple(createModelList) {
    return httpRequest.post('/assist-fill-in-data/multiple', createModelList)
  },
  deleteById(id) {
    return httpRequest.delete('/assist-fill-in-data/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/assist-fill-in-data', updateModel)
  },
  count(year, regionId, submitFlag) {
    let body = {
      year: year,
      regionId: regionId,
      submitFlag: submitFlag
    }

    return httpRequest.post('/assist-fill-in-data/count', body)
  },
  search(year, regionId, submitFlag, pageSize, pageIndex) {
    let body = {
      year: year,
      regionId: regionId,
      submitFlag: submitFlag
    }

    let config = {
      params: {
        year: year,
        regionId: regionId,
        submitFlag: submitFlag,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }

    return httpRequest.post('/assist-fill-in-data/search', body, config)
  },
  submit(id) {
    return httpRequest.put('/assist-fill-in-data/submit', { id: id })
  },
  importData(year, fileName) {
    let body = {
      year: year,
      fileName: fileName
    }

    return httpRequest.post('/assist-fill-in-data/import', body)
  },
  exportData(year, regionId) {
    let config = {
      params: {
        year: year,
        regionId: regionId
      }
    }
    return httpRequest.post('/assist-fill-in-data/export', null, config)
  },
  exportTemplate(year, regionId) {
    let config = {
      params: {
        year: year,
        regionId: regionId
      }
    }
    return httpRequest.post('/assist-fill-in-data-template', null, config)
  }
}
