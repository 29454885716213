<template>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" :lock-scroll="false" @close="handleClose">
    <div class="block">
      <el-timeline>
        <el-timeline-item v-for="item in reasonList" v-bind:key="item.rejectedOn" :timestamp="item.rejectedOn" placement="top">
          <el-card>
            <h4>{{ item.rejectedByFullName + '（'+item.levelName + '用户）' }}</h4>
            <p>{{ item.reason }}</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
  </el-dialog>
</template>

<script>
import RejectPlantCropApi from '@/api/reject-plant-crop-api'

export default {
  name: 'RejectedPlantCropReasonDialog',
  data() {
    return {
      dialogTitle: '退回原因',
      dialogVisible: false,
      reasonList: []
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    async open(plantCropId) {
      await RejectPlantCropApi.getRejectReasonList(plantCropId).then(res => {
        res.data.forEach(el => {
          let model = {}
          switch (el.levelId) {
            case 'Country':
              model.levelName = '国家'
              break
            case 'Province':
              model.levelName = '省级'
              break
            case 'City':
              model.levelName = '地市'
              break
            case 'District':
              model.levelName = '区县'
              break
          }
          model.reason = el.reason
          model.rejectedOn = el.rejectedOn
          model.rejectedByFullName = el.rejectedByFullName
          this.reasonList.push(model)
        })
      })
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped></style>
