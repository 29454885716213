import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/regions', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/regions/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/regions', updateModel)
  },
  getById(id) {
    return httpRequest.get('/regions/' + id)
  },
  getList() {
    return httpRequest.get('/regions')
  },
  getProvinceList() {
    return httpRequest.get('/provinces')
  },
  getCityList(provinceId) {
    let config = { params: { provinceId: provinceId } }
    return httpRequest.get('/cities', config)
  },
  getDistrictList(cityId) {
    let config = { params: { cityId: cityId } }
    return httpRequest.get('districts', config)
  },
  exportDistrictData(exportId, regionId, oldExportId) {
    let config = {
      params: {
        exportId: exportId,
        regionId: regionId,
        oldExportId: oldExportId
      }
    }
    return httpRequest.post('/district-data-export', null, config)
  }
}
