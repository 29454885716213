<template>
  <div>
    <el-dialog :title="windowTitle" :visible.sync="windowVisible" width="440px" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
      <el-form ref="form" :rules="formRules" :model="form" label-position="left" label-width="100px">
        <el-form-item label="序号" prop="sortNumber">
          <el-input v-model="form.sortNumber" type="number"></el-input>
        </el-form-item>
        <el-form-item label="标识" prop="id">
          <el-input v-model="form.id"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="启用" prop="enableFlag">
          <el-select v-model="form.enableFlag">
            <el-option v-for="item in enableFlagOptions" :key="item.value" :value="item.value" :label="item.text"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input v-model="form.description" type="textarea" rows="2"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" class="wg-button blank" @click="windowVisible = false">取 消</el-button>
        <el-button size="small" class="wg-button" @click="handleSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UserLevelApi from '@/api/user-level-api'

export default {
  name: 'UserLevelCreate',
  data() {
    return {
      windowTitle: '新增用户级别',
      windowVisible: false,
      enableFlagOptions: [
        { value: true, text: '是' },
        { value: false, text: '否' }
      ],
      form: {
        sortNumber: null,
        id: null,
        name: null,
        description: null,
        enableFlag: true
      },
      formRules: {
        sortNumber: [{ required: true, trigger: 'blur', message: '请输入序号！' }],
        id: [{ required: true, trigger: 'blur', message: '请输入标识！' }],
        name: [{ required: true, trigger: 'blur', message: '请输入名称！' }]
      }
    }
  },
  methods: {
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return

        let createModel = {
          id: this.form.id,
          sortNumber: this.form.sortNumber,
          name: this.form.name,
          enableFlag: this.form.enableFlag,
          description: this.form.description
        }
        UserLevelApi.create(createModel)
          .then(() => {
            this.$message({ showClose: true, message: '保存成功！', type: 'success' })
            this.windowVisible = false
          })
          .catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          })
      })
    },
    handleClose() {
      this.$emit('reload')
    },
    open() {
      this.windowVisible = true
    }
  }
}
</script>

<style scoped>
</style>