<template>
  <div>
    <summary-header></summary-header>
    <el-card>
      <div class="map-title">
        <span class="title">{{regionName}}肥料信息监测点分布</span>
        <div style="float: right; padding: 3px 3px 10px 0">
          <date-picker
              type="yearrange"
              v-model="years"
              format="yyyy年"
              value-format="yyyy"
              range-separator="至"
              start-placeholder="开始年份"
              end-placeholder="结束年份"
              :picker-options="pickerOptions"
              @change="handleChange"
            ></date-picker>
        </div>
      </div>
      <div>
        <el-row :gutter="20">
          <el-col :span="16">
            <distribution-map :year="mapYear" ref="map"></distribution-map>
          </el-col>
          <el-col :span="8">
            <fertilizer-application-charts :years="years" ref="charts"></fertilizer-application-charts>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>
<script>
import DistributionMap from './DistributionMap.vue'
import SummaryHeader from './SummaryHeader.vue'
import FertilizerApplicationCharts from '@/views/components/FertilizerApplicationCharts.vue'
import FillInCycleApi from '@/api/data-fill-in/cycle/fill-in-cycle-api'

export default {
  name: 'Distribution',
  components: { FertilizerApplicationCharts, SummaryHeader, DistributionMap },
  data() {
    return {
      regionName: '全国',
      timeRange: '',
      pickerOptions: {
        selectableRange: this.yearRange,
        onPick: ({ maxDate, minDate }) => {
          this.timeRange = minDate
          if (maxDate) this.timeRange = ''
        },
        disabledDate: (time) => {
          return time.getFullYear() < Number(this.yearRange[0]) || time.getFullYear() > Number(this.yearRange[1])
        },
      },
      yearRange: [],
      years: [],
      mapYear: '',
    }
  },
  mounted() {
    this.bindYears()
  },
  methods: {
    bindYears() {
      let yearlist = []
      FillInCycleApi.getList().then(res => {
        res.data.map(v => {
          yearlist.push(v.year)
        })
        if(yearlist.length >= 2) {
          let arr = yearlist.sort((a, b) => a - b)
          this.yearRange = [arr[0].toString(), arr[yearlist.length-1].toString()]
        } else {
          this.yearRange = [yearlist[0].toString(), yearlist[0].toString()]
        }
        this.years = this.yearRange;
        this.mapYear = this.yearRange[1].toString()
      })
    },
    handleChange(val){
      this.years = val
      this.mapYear = this.yearRange[1].toString()
    }
  }
}
</script>
<style scoped>
.title {
  font-weight: 600;
}
.map-title {
  font-size: 20px;
}
.el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover{
  box-shadow: none;
}
.el-card {
  border: none;
}
.el-date-editor >>> .el-input__inner {
  padding: 0 15px;
}
.el-date-editor >>> .el-input__prefix{
  right: 5px;
  left: unset;
}
.el-date-editor >>> .el-input__suffix{
  right: 25px;
}
</style>
