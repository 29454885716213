<template>
  <el-card>
    <div slot="header" class="chart-title">
      <span>作物信息</span>
    </div>
    <div>
      <el-row :gutter="20" type="flex">
        <el-col :span="12" style="padding-left: 40px;">
          <v-chart
            :option='options'
            :init-options='initOptions'
            ref='chart'
            :loading='barLoading'
            :loadingOptions='barLoadingOptions'
            :updateOptions="{notMerge: true}"
            autoresize
          />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import VChart from 'vue-echarts'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'

import {
  use
} from 'echarts/core'

import {
  BarChart
} from 'echarts/charts'

import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components'
import DataVisualizationApi from '@/api/chart-analysis/data-visualization-api'

use([
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
])

export default {
  name: 'cropyield',
  components: {
    VChart
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      initOptions: {
        renderer: 'canvas'
      },
      options: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      seconds: -1,
      barLoading: false,
      barLoadingOptions: {
        text: '加载中…',
        color: '#4ea397',
        maskColor: 'rgba(255, 255, 255, 0.4)'
      }
    }
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    getChartData() {
      this.options = {}
      this.barLoading = true
      DataVisualizationApi.getCropYield(this.params).then(res => {
        if(res.data.length === 0) {
          this.options = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }
        const colors = ['#44A1FF', '#31BF5D', '#FFC44A']
        this.options = {
          textStyle: {
            fontSize: 14
          },
          grid: [
            { left: '5%', width: '20%', top: '35%' },
            { left: '35%', width: '20%', top: '35%' },
            { left: '65%', width: '20%', top: '35%' }
          ],
          series: [
            {
              name: res.data[0].name,
              type: 'gauge',
              startAngle: 90,
              endAngle: -270,
              radius: '50%',
              center: ['20%', '35%'],
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false,
                fontSize: 14,
              },
              axisLine: {
                show: false,
                lineStyle: {
                  width: 25
                }
              },
              anchor: {},
              pointer: {
                show: false
              },
              title: {
                show: false,
                fontSize: 16,
                fontWeight: 400,
                offsetCenter: [0, 0]
              },
              progress: {
                show: false,
                width: 25,
                itemStyle: {
                  color: colors[0]
                }
              },
              detail: {
                show: true,
                fontSize: 24,
                color: colors[0],
                formatter: function () { // 展示处理
                  return [res.data[0].name, res.data[0].value + ' 万亩'].join("\n\n");
                }
              },
              data: [res.data[0]]
            },
            {
              name: res.data[1].name,
              type: 'gauge',
              startAngle: 90,
              endAngle: -270,
              radius: '50%',
              center: ['45%', '35%'],
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false,
                fontSize: 14,
              },
              axisLine: {
                show: false,
                lineStyle: {
                  width: 25
                }
              },
              anchor: {},
              pointer: {
                show: false
              },
              title: {
                show: false,
                fontSize: 16,
                fontWeight: 400,
                offsetCenter: [0, 0]
              },
              progress: {
                show: false,
                width: 25,
                itemStyle: {
                  color: colors[1]
                }
              },
              detail: {
                show: true,
                fontSize: 24,
                color: colors[1],
                formatter: function () { // 展示处理
                  return [res.data[1].name, res.data[1].value + ' 万公斤'].join("\n\n");
                }
              },
              data: [res.data[1]]
            },
            {
              name: res.data[2].name,
              type: 'gauge',
              startAngle: 90,
              endAngle: -270,
              radius: '50%',
              center: ['70%', '35%'],
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false,
                fontSize: 14,
              },
              axisLine: {
                show: false,
                lineStyle: {
                  width: 25
                }
              },
              anchor: {},
              pointer: {
                show: false
              },
              title: {
                show: false,
                fontSize: 16,
                fontWeight: 400,
                offsetCenter: [0, 0]
              },
              progress: {
                show: false,
                width: 25,
                itemStyle: {
                  color: colors[2]
                }
              },
              detail: {
                show: true,
                fontSize: 24,
                color: colors[2],
                formatter: function () { // 展示处理
                  return [res.data[2].name, res.data[2].value + ' 公斤'].join("\n\n");
                }
              },
              data: [res.data[2]]
            }
          ]
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.barLoading = false
      })
    }
  }
}
</script>

<style scoped>
.echarts {
  width: 95vw;
  height: 160px;
  min-height: 120px;
}
.el-divider--horizontal {
  margin: 2px 0;
}

.el-divider--vertical {
  display: inline-block;
  weight: 1px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;
}

</style>
