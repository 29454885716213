import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/assist-fill-in-plant-crops', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/assist-fill-in-plant-crops/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/assist-fill-in-plant-crops', updateModel)
  },
  submit(id) {
    return httpRequest.put('/assist-fill-in-plant-crop-submit', { id: id })
  },
  getById(id) {
    return httpRequest.get('/assist-fill-in-plant-crops/' + id)
  },
  getList(year, regionId, submitFlag, startDate, endDate) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        submitFlag: submitFlag,
        startDate: startDate,
        endDate: endDate
      }
    }
    return httpRequest.get('/assist-fill-in-plant-crops', config)
  },
  getPaging(year, regionId, submitFlag, startDate, endDate, pageSize, pageIndex) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        submitFlag: submitFlag,
        startDate: startDate,
        endDate: endDate,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }
    return httpRequest.get('/assist-fill-in-plant-crop-paging', config)
  },
  importData(year, fileName) {
    let config = {
      params: {
        year: year,
        fileName: fileName
      }
    }
    return httpRequest.post('/assist-fill-in-data-import', null, config)
  },
  exportTemplate(exportId, year, regionId) {
    let config = {
      params: {
        exportId: exportId,
        year: year,
        regionId: regionId
      }
    }
    return httpRequest.post('/assist-fill-in-template-export', null, config)
  },
  exportData(exportId, year, regionId, submitFlag, startDate, endDate) {
    let config = {
      params: {
        exportId: exportId,
        year: year,
        regionId: regionId,
        submitFlag: submitFlag,
        startDate: startDate,
        endDate: endDate
      }
    }
    return httpRequest.post('/assist-fill-in-data-export', null, config)
  }
}
