<template>
  <div>
    <el-dialog :title="windowTitle" :visible.sync="windowVisible" width="440px" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
      <el-form ref="form" :rules="formRules" :model="form" label-position="left" label-width="100px">
        <el-form-item label="专业用户类别" prop="professionalCategoryName">
          <el-input v-model="form.professionalCategoryName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" class="wg-button blank" @click="windowVisible = false">取 消</el-button>
        <el-button size="small" class="wg-button" @click="handleSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ProfessionalIndexDirectoryApi from '@/api/professional-index-directory-api'
import ProfessionalCategoryApi from '@/api/professional-category-api'

export default {
  name: 'ProfessionalIndexDirectoryCreate',
  data() {
    return {
      windowTitle: '修改自定义名称',
      windowVisible: false,
      params: {
        id: null,
        professionalCategoryName: null,
        professionalCategoryId: null
      },
      form: {
        name: null
      },
      formRules: {
        name: [{ required: true, trigger: 'blur', message: '请输入名称！' }]
      }
    }
  },
  methods: {
    handleSave() {
      let updateModel = {
        id: this.params.id,
        name: this.form.name
      }

      ProfessionalIndexDirectoryApi.update(updateModel)
        .then(() => {
          this.$message({ showClose: true, message: '保存成功！', type: 'success' })
          this.windowVisible = false
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    handleClose() {
      this.$emit('reload')
    },
    async open(id) {
      this.params.id = id
      await ProfessionalIndexDirectoryApi.getById(id)
        .then(res => {
          this.params.professionalCategoryId = res.data.professionalCategoryId
          this.form.name = res.data.name

          ProfessionalCategoryApi.getById(this.params.professionalCategoryId)
            .then(res => {
              this.form.professionalCategoryName = res.data.name
              this.windowVisible = true
            })
            .catch(err => {
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped></style>
