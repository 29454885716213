<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="chart-title">
          <div>
            <span>单位面积作物产量</span>
            <el-button style="float: right; padding: 3px 30px 3px 0" type="text" @click="changeChartType">{{chartType === 'bar' ? '折线图' : '柱状图'}}</el-button>
          </div>
        </div>
        <div style="clear: both;">
          <el-divider></el-divider>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <v-chart
          :option="options"
          :init-options="initOptions"
          ref="intensityNumbers"
          :loading="barLoading"
          :loadingOptions="barLoadingOptions"
          :updateOptions="{notMerge: true}"
          autoresize
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import {CanvasRenderer, SVGRenderer} from 'echarts/renderers'

import {
  use
} from 'echarts/core'

import {
  BarChart
} from 'echarts/charts'

import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components'
import AnnualAnalysisApi from '@/api/chart-analysis/annual-analysis-api'

use([
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
])

export default {
  name: "ProductivityAnnual",
  components: {
    VChart
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas"
      },
      options: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      chartType: "bar",
      seconds: -1,
      barLoading: false,
      barLoadingOptions: {
        text: "加载中…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0.4)"
      },
    }
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    changeChartType() {
      this.chartType = this.chartType === "line" ? "bar" : "line"
      if (this.options && this.options.series) {
        this.options.series.forEach(p => p.type = this.chartType)
      }
    },
    getChartData() {
      this.options = {}
      this.barLoading = true
      let postfix = this.params.groupType === 'years' ? '年' : ''
      let prefix = this.params.groupType !== 'years' ? this.params.searchYear + '年  ' : ''
      AnnualAnalysisApi.getProductivity(this.params).then(res => {
        if(res.data.length === 0) {
          this.options = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }
        this.options = {
          textStyle: {
            fontSize: 14
          },
          color: ['#27BC55'],
          grid: {
            top: '12%',
            left: '3%',
            right: '4%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'line',
              label: {
                backgroundColor: '#6a7985'
              }
            },
            textStyle: {
              align: 'left'
            },
            formatter(a) {
              let res = '';
              a.forEach((item, index) => {
                if (index === 0) {
                  res += prefix + `${item.axisValue}<br/>`
                }
                res += `${item.marker} <span style='font-weight:bold;float: right'>${item.value}</span><br/>`
              })
              return res
            },
            extraCssText: 'width: 200px'
          },
          xAxis: {
            type: 'category',
            axisLabel: {
              fontSize: 14,
              interval: 0
            },
            data: res.data.map(f => f.name + postfix)
          },
          yAxis: {
            type: 'value',
            name: '(单位:公斤/亩)',
            axisLabel: {
              fontSize: 14
            },
          },
          dataZoom: [
            {
              type: 'inside',
              filterMode: 'empty',
              zoomOnMouseWheel: false,
              // start:20,
              // end:60,
            },
            {
              type: 'slider',
              height: '24px',
              bottom: '30px',
              left: 'center',
              brushSelect: false,
              borderColor: 'transparent',
              backgroundColor: 'rgba(0,0,0,0.04)',
              fillerColor: 'rgba(0,0,0,0.06)',
              moveOnMouseWheel: false,
              zoomOnMouseWheel: false,
            }
          ],
          series: [{
            type: this.chartType,
            barWidth: 25,
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            symbol: 'circle',
            symbolSize: [8, 8],
            areaStyle: {
              color: '#27BC55'
            },
            emphasis: {
              focus: 'series'
            },
            encode: { y: 'value' },
            data: res.data.map(item => item.value),
          }]
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.barLoading = false
      })
    }
  }
}
</script>

<style scoped>
.echarts {
  width: 98vw;
}

.el-divider--horizontal {
  margin: 2px 0;
}

.el-divider--vertical {
  display: inline-block;
  weight: 1px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;
}
</style>
