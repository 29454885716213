<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/professional-category-manage' }">专业用户类别管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <div class="wg-box">
      <div class="header">
        <div class="button-list">
          <el-button size="small" class="wg-button green" icon="el-icon-plus" @click="handleAdd">新增</el-button>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <div class="body">
        <div class="wg-table-box">
          <el-table :data="tableData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)" @selection-change="handleSelectionChange" class="wg-table normal">
            <el-table-column prop="sortNumber" label="序号" width="80" sortable></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="defaultFlag" label="系统默认" width="120">
              <template slot-scope="scope">
                <span :style="scope.row.defaultFlag ? 'color:#039F3A' : 'color:#FF8900'">{{ scope.row.defaultFlag ? '是' : '否' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="自定义名录" width="120">
              <template slot-scope="scope">
                <span :style="scope.row.indexDirectoryFlag ? 'color:#039F3A' : 'color:#FF8900'">{{ scope.row.indexDirectoryFlag ? '开启' : '关闭' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createdOn" label="创建时间" width="180" sortable></el-table-column>
            <el-table-column prop="createdByFullName" label="创建人" width="120" sortable></el-table-column>
            <el-table-column prop="modifiedOn" label="修改时间" width="180" sortable></el-table-column>
            <el-table-column prop="modifiedByFullName" label="修改人" width="120" sortable></el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleIndexDirectoryManage(scope.row)">管理名录</el-button>
                <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="wg-pagination-box">
          <el-pagination background layout="prev, pager, next" :total="recordTotal" :page-size="pageSize" :current-page="pageIndex" @current-change="handlePagingChange"></el-pagination>
        </div>
      </div>
    </div>
    <!-- table data -->
    <create-window ref="createWindow" v-if="isLoadCreateWindow" @reload="handleReloadCreateWindow"></create-window>
    <modify-window ref="modifyWindow" v-if="isLoadModifyWindow" @reload="handleReloadModifyWindow"></modify-window>
  </div>
</template>

<script>
import ProfessionalCategoryApi from '@/api/professional-category-api'

import ProfessionalCategoryCreate from './ProfessionalCategoryCreate'
import ProfessionalCategoryModify from './ProfessionalCategoryModify'

export default {
  name: 'ProfessionalCategoryManage',
  components: {
    'create-window': ProfessionalCategoryCreate,
    'modify-window': ProfessionalCategoryModify
  },
  data() {
    return {
      isLoadCreateWindow: true,
      isLoadModifyWindow: true,
      tableData: [],
      recordTotal: 0,
      pageSize: 10,
      pageIndex: 1,
      selectedRows: []
    }
  },
  created() {
    this.bindTableData()
  },
  methods: {
    handleReloadCreateWindow() {
      this.isLoadCreateWindow = false
      this.$nextTick(() => {
        this.isLoadCreateWindow = true
      })
      this.bindTableData()
    },
    handleReloadModifyWindow() {
      this.isLoadModifyWindow = false
      this.$nextTick(() => {
        this.isLoadModifyWindow = true
      })
      this.bindTableData()
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows
    },
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
    },
    handleAdd() {
      this.$refs.createWindow.open()
    },
    handleIndexDirectoryManage(row) {
      if (!row.indexDirectoryFlag) {
        this.$message({ showClose: true, message: '自定义目录未开启！', type: 'warning' })
        return
      }

      this.$router.push({ path: '/professional-index-directory', query: { professionalCategoryId: row.id } })
    },
    handleEdit(row) {
      this.$refs.modifyWindow.open(row.id)
    },
    handleDelete(row) {
      if (row.defaultFlag) {
        this.$message({ showClose: true, message: '系统默认无法删除！', type: 'warning' })
        return
      }

      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          ProfessionalCategoryApi.deleteById(row.id)
            .then(() => {
              this.$message({ type: 'success', message: '删除成功！' })
              this.bindTableData()
            })
            .catch(err => {
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    bindTableData() {
      ProfessionalCategoryApi.getList()
        .then(res => {
          this.tableData = []
          this.recordTotal = res.data.length
          this.pageIndex = 1
          res.data.forEach(el => {
            this.tableData.push({
              id: el.id,
              sortNumber: el.sortNumber,
              name: el.name,
              defaultFlag: el.defaultFlag,
              indexDirectoryFlag: el.indexDirectoryFlag,
              createdOn: el.createdOn,
              createdByFullName: el.createdByFullName,
              modifiedOn: el.modifiedOn,
              modifiedByFullName: el.modifiedByFullName
            })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped></style>
