<template>
  <div>
    <el-tabs v-model="activeName" class="wg-tabs" @tab-click="handleTabClick">
      <el-tab-pane name="unsubmittedPane">
        <span slot="label">未提交</span>
        <unsubmitted-plant-crop-data ref="unsubmittedPlantCropData" :cropRootCategoryArray="cropRootCategoryArray" :cropSubCategoryArray="cropSubCategoryArray" :cropArray="cropArray" v-if="flags.isLoadUnsubmittedPlantCropDataComponent"></unsubmitted-plant-crop-data>
      </el-tab-pane>
      <!-- unsubmitted -->
      <el-tab-pane name="unsubmittedPane1" v-if="false">
        <span slot="label">未提交1</span>
        <unsubmitted-assist-fill-in-data ref="unsubmittedAssistFillInData" v-if="flags.isLoadUnsubmittedAssistFillInDataComponent"></unsubmitted-assist-fill-in-data>
        <assist-fill-in-data-add-row ref="assistFillInDataAddRow" v-if="flags.isLoadAssistFillInDataAddRowComponent"></assist-fill-in-data-add-row>
      </el-tab-pane>
      <!-- unsubmitted1 -->
      <el-tab-pane name="submittiedPane">
        <span slot="label">已提交</span>
        <submitted-plant-crop-data ref="submittedPlantCropData" v-if="flags.isLoadSubmittedPlantCropDataComponent"></submitted-plant-crop-data>
      </el-tab-pane>
      <!-- submitted -->
    </el-tabs>
  </div>
</template>

<script>
import CropCategoryApi from '@/api/crop/crop-category-api'
import CropApi from '@/api/crop/crop-api'

import UnsubmittedPlantCropData from './UnsubmittedPlantCropData'
import UnsubmittedAssistFillInData from './UnsubmittedAssistFillInData'
import AssistFillInDataAddRow from './AssistFillInDataAddRow'
import SubmittedPlantCropData from './SubmittedPlantCropData'

export default {
  name: 'UnarchivedAssistFillInTab',
  components: {
    'unsubmitted-plant-crop-data': UnsubmittedPlantCropData,
    'unsubmitted-assist-fill-in-data': UnsubmittedAssistFillInData,
    'assist-fill-in-data-add-row': AssistFillInDataAddRow,
    'submitted-plant-crop-data': SubmittedPlantCropData
  },
  props: {
    year: Number,
    regionId: String
  },
  data() {
    return {
      isCollapse: false,
      flags: {
        isLoadUnsubmittedPlantCropDataComponent: false,
        isLoadAssistFillInDataAddRowComponent: false,
        isLoadUnsubmittedAssistFillInDataComponent: false,
        isLoadSubmittedPlantCropDataComponent: false
      },
      activeName: 'unsubmittedPane',
      cropRootCategoryArray: [],
      cropSubCategoryArray: [],
      cropArray: []
    }
  },
  async created() {
    await CropCategoryApi.getRootStubList().then(res => {
      res.data.forEach(el => {
        this.cropRootCategoryArray.push({ id: el.id, name: el.name })
      })
    })

    await CropCategoryApi.getSubStubList(null).then(res => {
      res.data.forEach(el => {
        this.cropSubCategoryArray.push({ id: el.id, name: el.name, parentId: el.parentId })
      })
    })

    await CropApi.getStubList(null, null).then(res => {
      res.data.forEach(el => {
        this.cropArray.push({
          id: el.id,
          name: el.name,
          rootCategoryId: el.rootCategoryId,
          subCategoryId: el.subCategoryId
        })
      })
    })

    this.flags.isLoadUnsubmittedPlantCropDataComponent = true

    this.$nextTick(() => {
      this.loadComponent(this.activeName)
    })
  },
  watch: {
    year() {
      this.loadComponent(this.activeName)
    },
    regionId() {
      this.loadComponent(this.activeName)
    }
  },
  methods: {
    handleTabClick(tab) {
      this.flags.isLoadUnsubmittedPlantCropDataComponent = tab.name === 'unsubmittedPane'
      this.flags.isLoadUnsubmittedAssistFillInDataComponent = tab.name === 'unsubmittedPane1'
      this.flags.isLoadSubmittedPlantCropDataComponent = tab.name === 'submittiedPane'

      this.$nextTick(() => {
        this.loadComponent(tab.name)
      })
    },
    loadComponent(name) {
      switch (name) {
        case 'unsubmittedPane':
          this.$refs.unsubmittedPlantCropData.load(this.year, this.regionId)
          break
        case 'unsubmittedPane1':
          this.$refs.unsubmittedAssistFillInData.load(this.year, this.regionId)
          break
        case 'submittiedPane':
          this.$refs.submittedPlantCropData.load(this.year, this.regionId)
          break
        default:
          this.$refs.unsubmittedPlantCropData.load(this.year, this.regionId)
          break
      }
    },
    reloadComponent() {
      this.loadComponent(this.activeName)
    }
  }
}
</script>

<style scoped></style>
