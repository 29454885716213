<template>
  <div ref="unsubmittedAssistFillInDataPane" v-loading="dataLoading" element-loading-text="正在加载数据......" element-loading-spinner="el-icon-loading">
    <div class="wg-box">
      <div class="header">
        <div class="button-list">
          <el-button size="medium" class="wg-button green" icon="el-icon-user" @click="handleAddUser">新增用户</el-button>
          <el-button size="medium" class="wg-button green" icon="el-icon-plus" @click="handleAddRows">开始填报</el-button>
          <el-button size="small" class="wg-button orange" icon="el-icon-upload" @click="handleSaveRows" v-show="false">保存</el-button>
          <el-button size="medium" class="wg-button red" icon="el-icon-delete" @click="handleDeleteRows">删除</el-button>
          <el-button size="medium" class="wg-button orange" icon="el-icon-finished" @click="handleSubmitRows">提交</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-refresh" @click="handleRefresh">刷新</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-upload2" @click="handleImportData">导入</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-download" @click="handleExportData">导出</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-tickets" @click="handleTemplateDownload">模板下载</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-full-screen" v-if="!isFullscreen" @click="handleFullscreen">全屏</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-full-screen" v-if="isFullscreen" @click="handleExtFullscreen">退出</el-button>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <div class="body">
        <div class="wg-table-box">
          <ux-grid ref="fillDataTable" rowId="PC_Id" height="600" keep-source :edit-config="tableEditConfig" :validConfig="{autoPos: true}" :rules="validRules" @edit-actived="handleTableEditActived">
            <ux-table-column type="checkbox" width="50" fixed="left"></ux-table-column>
            <ux-table-column type="index" width="50" :align="tableColumnAlign" fixed="left"></ux-table-column>
            <ux-table-column title="用户名称" width="120" fixed="left" :align="tableColumnAlign" field="UA_Username"></ux-table-column>
            <ux-table-column title="农户姓名" width="120" fixed="left" :align="tableColumnAlign" field="UA_FullName" edit-render>
              <template v-slot:edit="scope">
                <el-input v-model="scope.row.UA_FullName" :size="editComponentSize"></el-input>
              </template>
            </ux-table-column>
            <ux-table-column title="农户信息" :align="tableColumnAlign" :size="editComponentSize">
              <ux-table-column title="省份" width="120" :align="tableColumnAlign" field="UA_ProvinceName"></ux-table-column>
              <ux-table-column title="地市" width="120" :align="tableColumnAlign" field="UA_CityName"></ux-table-column>
              <ux-table-column title="区县" width="120" :align="tableColumnAlign" field="UA_DistrictName"></ux-table-column>
              <ux-table-column title="详细地址" width="200" :align="tableColumnAlign" field="UA_DetailedAddress" edit-render>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.UA_DetailedAddress" :size="editComponentSize"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="电话" width="120" :align="tableColumnAlign" field="UA_PhoneNumber"></ux-table-column>
              <ux-table-column title="农户性质" width="120" :align="tableColumnAlign" field="UA_FarmerNature" edit-render>
                <template v-slot:default="{row}">
                  <span v-if="row.UA_FarmerNature === 'COMMON_PLANTER'" class="elx-cell--label">普通种植户</span>
                  <span v-if="row.UA_FarmerNature === 'LARGE_PLANTER'" class="elx-cell--label">种植大户</span>
                  <span v-if="row.UA_FarmerNature === 'FAMILY_FARM'" class="elx-cell--label">家庭农场</span>
                  <span v-if="row.UA_FarmerNature === 'SPECIALIZED_COOPERATIVE'" class="elx-cell--label">专业合作社</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.UA_FarmerNature" :size="editComponentSize">
                    <el-option v-for="item in farmerNatureOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="文化程度" width="120" :align="tableColumnAlign" field="UA_EducationalLevel" edit-render>
                <template v-slot:default="{row}">
                  <span v-if="row.UA_EducationalLevel === 'PRIMARY_SCHOOL'" class="elx-cell--label">小学</span>
                  <span v-if="row.UA_EducationalLevel === 'JUNIOR_HIGH_SCHOOL'" class="elx-cell--label">初中</span>
                  <span v-if="row.UA_EducationalLevel === 'TECHNICAL_SECONDARY_SCHOOL'" class="elx-cell--label">中专/中技</span>
                  <span v-if="row.UA_EducationalLevel === 'SENIOR_HIGH_SCHOOL'" class="elx-cell--label">高中</span>
                  <span v-if="row.UA_EducationalLevel === 'JUNIOR_COLLEGE'" class="elx-cell--label">大专</span>
                  <span v-if="row.UA_EducationalLevel === 'UNDERGRADUATE'" class="elx-cell--label">本科</span>
                  <span v-if="row.UA_EducationalLevel === 'GRADUATE'" class="elx-cell--label">研究生</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.UA_EducationalLevel" :size="editComponentSize">
                    <el-option v-for="item in educationalLevelOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="经营面积（含园地，亩）" width="150" :align="tableColumnAlign" field="UA_CultivationLandArea" edit-render>
                <template v-slot:header>
                  经营面积<br />（含园地，亩）
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.UA_CultivationLandArea" :size="editComponentSize"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="示范户" width="120" :align="tableColumnAlign" field="UA_ModelHouseholdFlag" edit-render>
                <template v-slot:default="{row}">
                  <span v-if="row.UA_ModelHouseholdFlag" class="elx-cell--label">是</span>
                  <span v-else class="elx-cell--label">否</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.UA_ModelHouseholdFlag" :size="editComponentSize">
                    <el-option v-for="item in yesAndNoOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
            </ux-table-column>
            <!-- 农户信息 -->
            <ux-table-column title="地块信息" width="120" :align="tableColumnAlign">
              <ux-table-column title="地块名称" width="120" :align="tableColumnAlign" field="MP_Id" edit-render>
                <template v-slot:default="{row}">
                  <span class="elx-cell--label">{{ row.MP_Name }}</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.MP_Id" :size="editComponentSize" :disabled="scope.row.filterData.plotOptionList.length === 0" @change="handleMPPlotChange($event, scope)">
                    <el-option v-for="item in scope.row.filterData.plotOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="地块代码" width="120" :align="tableColumnAlign" field="MP_Code"></ux-table-column>
              <ux-table-column title="地块面积（亩）" width="120" :align="tableColumnAlign" field="MP_Area">
                <template v-slot:header>地块面积<br />（亩）</template>
              </ux-table-column>
            </ux-table-column>
            <!-- 地块信息 -->
            <ux-table-column title="种植信息" :align="tableColumnAlign">
              <ux-table-column title="作物名称" width="120" :align="tableColumnAlign" field="PC_CropId" edit-render>
                <template v-slot:default="{row}">
                  <span class="elx-cell--label">{{ row.PC_CropName }}</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.PC_CropId" :size="editComponentSize" @change="handlePCCropChange($event, scope)">
                    <el-option-group v-for="group in cropOptionGroupList" :key="group.label" :label="group.label">
                      <el-option v-for="option in group.optionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-option-group>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="作物类型" width="120" :align="tableColumnAlign" field="PC_CropType" edit-render>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.PC_CropType" :size="editComponentSize"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="播种日期" width="160" :align="tableColumnAlign" field="PC_SowingDate" edit-render>
                <template v-slot:edit="scope">
                  <el-date-picker v-model="scope.row.PC_SowingDate" type="date" value-format="yyyy-MM-dd" :size="editComponentSize" placeholder="选择日期" style="width: 140px"></el-date-picker>
                </template>
              </ux-table-column>
              <ux-table-column title="收获日期" width="160" :align="tableColumnAlign" field="PC_HarvestTime" edit-render>
                <template v-slot:edit="scope">
                  <el-date-picker v-model="scope.row.PC_HarvestTime" type="date" value-format="yyyy-MM-dd" :size="editComponentSize" placeholder="选择日期" style="width: 140px"></el-date-picker>
                </template>
              </ux-table-column>
              <ux-table-column title="播种面积（亩）" width="120" :align="tableColumnAlign" field="PC_SowingArea" edit-render>
                <template v-slot:header>
                  播种面积<br />（亩）
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.PC_SowingArea" :size="editComponentSize"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="地块肥力" width="120" :align="tableColumnAlign" field="PC_PlotFertility" edit-render>
                <template v-slot:default="{row}">
                  <span v-if="row.PC_PlotFertility === 'HIGH'" class="elx-cell--label">高</span>
                  <span v-if="row.PC_PlotFertility === 'MIDDLE'" class="elx-cell--label">中</span>
                  <span v-if="row.PC_PlotFertility === 'LOW'" class="elx-cell--label">低</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.PC_PlotFertility" :size="editComponentSize" @change="$refs.fillDataTable.updateStatus(scope)">
                    <el-option v-for="item in plotFertilityOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="亩产量（公斤/亩）" width="120" :align="tableColumnAlign" field="PC_Yield" edit-render>
                <template v-slot:header>
                  亩产量<br />（公斤/亩）
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.PC_Yield" :size="editComponentSize"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="是否设施栽培" width="120" :align="tableColumnAlign" field="PC_FacilityCultivationFlag" edit-render>
                <template v-slot:header>
                  是否<br />设施栽培
                </template>
                <template v-slot:default="{row}">
                  <span v-if="row.PC_FacilityCultivationFlag" class="elx-cell--label">是</span>
                  <span v-else class="elx-cell--label">否</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.PC_FacilityCultivationFlag" :size="editComponentSize" clearable @change="handleFacilityCultivationFlag(scope)">
                    <el-option v-for="item in yesAndNoOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="是否种植季受灾" width="120" :align="tableColumnAlign" field="PC_HappenDisasterFlag" edit-render>
                <template v-slot:header>
                  是否<br />种植季受灾
                </template>
                <template v-slot:default="{row}">
                  <span v-if="row.PC_HappenDisasterFlag" class="elx-cell--label">是</span>
                  <span v-else class="elx-cell--label">否</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.PC_HappenDisasterFlag" :size="editComponentSize" clearable @clear="handlePCHappenDisasterFlagClear(scope)">
                    <el-option v-for="item in yesAndNoOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="前茬作物" width="120" :align="tableColumnAlign" field="PC_PreviousCropId" edit-render>
                <template v-slot:default="{row}">
                  <span class="elx-cell--label">
                    {{ row.PC_PreviousCropName }}
                  </span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.PC_PreviousCropId" :size="editComponentSize" @change="handlePCPreviousCropChange($event, scope)">
                    <el-option-group v-for="group in previousCropOptionGroupList" :key="group.label" :label="group.label">
                      <el-option v-for="option in group.optionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-option-group>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="前茬作物亩产量（公斤/亩）" width="120" :align="tableColumnAlign" field="PC_PreviousYield" edit-render>
                <template v-slot:header>
                  前茬作物<br />亩产量<br />（公斤/亩）
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.PC_PreviousYield" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.PC_PreviousCropId) || scope.row.PC_PreviousCropId === 'NONE'"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="前茬秸秆还田率（%）" width="120" :align="tableColumnAlign" field="PC_PreviousStrawReturnRate" edit-render>
                <template v-slot:header>
                  前茬秸秆<br />还田率<br />（%）
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.PC_PreviousStrawReturnRate" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.PC_PreviousCropId) || scope.row.PC_PreviousCropId === 'NONE'"></el-input>
                </template>
              </ux-table-column>
            </ux-table-column>
            <!-- 种植信息 -->
            <ux-table-column title="底肥（公斤/亩）" :align="tableColumnAlign">
              <ux-table-column title="底肥施肥方式" width="120" :align="tableColumnAlign" field="BF_FertilizationMode" edit-render>
                <template v-slot:header>
                  底肥<br />施肥方式
                </template>
                <template v-slot:default="{row}">
                  <span v-if="row.BF_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                  <span v-if="row.BF_FertilizationMode === 'MODE2'" class="elx-cell--label">机械深施</span>
                  <span v-if="row.BF_FertilizationMode === 'MODE3'" class="elx-cell--label">种肥同播</span>
                  <span v-if="row.BF_FertilizationMode === 'MODE4'" class="elx-cell--label">条施</span>
                  <span v-if="row.BF_FertilizationMode === 'MODE5'" class="elx-cell--label">穴施</span>
                  <span v-if="row.BF_FertilizationMode === 'MODE7'" class="elx-cell--label">无人机施肥</span>
                  <span v-if="row.BF_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.BF_FertilizationMode" :size="editComponentSize" clearable @clear="handleBFFertilizationModeClear(scope)">
                    <el-option v-for="item in baseFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="施肥日期" width="150" :align="tableColumnAlign" field="BF_FertilizationDate" edit-render>
                <template v-slot:edit="scope">
                  <el-date-picker v-model="scope.row.BF_FertilizationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)"></el-date-picker>
                </template>
              </ux-table-column>
              <ux-table-column title="商用有机肥" :align="tableColumnAlign">
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_BusinessOrganicFertilizerDosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" @change="handleBFFieldChange($event, scope, 'BusinessOrganicFertilizerDosage')"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column width="120" :align="tableColumnAlign" field="BF_BusinessOrganicFertilizerPrice" edit-render>
                  <template v-slot:header>
                    价格<br />（元/吨）
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_BusinessOrganicFertilizerPrice" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_BusinessOrganicFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="农家肥1（干重）" :align="tableColumnAlign">
                <ux-table-column title="名称" width="120" :align="tableColumnAlign" field="BF_OrganicFertilizer1Id" edit-render>
                  <template v-slot:default="{row}">
                    <span class="elx-cell--label">
                      {{ row.BF_OrganicFertilizer1Name }}
                    </span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.BF_OrganicFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" clearable @change="handleBFFieldChange($event, scope, 'OrganicFertilizer1')">
                      <el-option v-for="option in organicFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_OrganicFertilizer1Dosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_OrganicFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_OrganicFertilizer1Id)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="农家肥2（干重）" :align="tableColumnAlign">
                <ux-table-column title="名称" width="120" :align="tableColumnAlign" field="BF_OrganicFertilizer2Id" edit-render>
                  <template v-slot:default="{row}">
                    <span class="elx-cell--label">
                      {{ row.BF_OrganicFertilizer2Name }}
                    </span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.BF_OrganicFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" clearable @change="handleBFFieldChange($event, scope, 'OrganicFertilizer2')">
                      <el-option v-for="option in organicFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_OrganicFertilizer2Dosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_OrganicFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_OrganicFertilizer2Id)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="尿素用量" width="100" :align="tableColumnAlign" field="BF_UreaDosage" edit-render>
                <template v-slot:header>
                  尿素<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.BF_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="碳铵用量" width="100" :align="tableColumnAlign" field="BF_AmmoniumBicarbonateDosage" edit-render>
                <template v-slot:header>
                  碳铵<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.BF_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="磷酸二铵用量" width="100" :align="tableColumnAlign" field="BF_DiammoniumPhosphateDosage" edit-render>
                <template v-slot:header>
                  磷酸二铵<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.BF_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="氯化钾用量" width="100" :align="tableColumnAlign" field="BF_PotassiumChlorideDosage" edit-render>
                <template v-slot:header>
                  氯化钾<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.BF_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="硫酸钾用量" width="100" :align="tableColumnAlign" field="BF_PotassiumSulphateDosage" edit-render>
                <template v-slot:header>
                  硫酸钾<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.BF_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_CompoundFertilizerDosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_CompoundFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" clearable @change="handleBFFieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="BF_CompoundFertilizerN" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_CompoundFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="BF_CompoundFertilizerP2O5" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_CompoundFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="BF_CompoundFertilizerK2O" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_CompoundFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_WaterSolubleFertilizerDosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" clearable @change="handleBFFieldChange($event, scope, 'WaterSolubleFertilizerDosage')"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="BF_WaterSolubleFertilizerN" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_WaterSolubleFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="BF_WaterSolubleFertilizerP2O5" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_WaterSolubleFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="BF_WaterSolubleFertilizerK2O" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_WaterSolubleFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                <ux-table-column title="名称" width="120" :align="tableColumnAlign" field="BF_OtherFertilizer1Id" edit-render>
                  <template v-slot:default="{row}">
                    <span class="elx-cell--label">
                      {{ row.BF_OtherFertilizer1Name }}
                    </span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.BF_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" clearable @change="handleBFFieldChange($event, scope, 'OtherFertilizer1')">
                      <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_OtherFertilizer1Dosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_OtherFertilizer1Id)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                <ux-table-column title="名称" width="120" :align="tableColumnAlign" field="BF_OtherFertilizer2Id" edit-render>
                  <template v-slot:default="{row}">
                    <span class="elx-cell--label">
                      {{ row.BF_OtherFertilizer2Name }}
                    </span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.BF_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" clearable @change="handleBFFieldChange($event, scope, 'OtherFertilizer2')">
                      <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_OtherFertilizer2Dosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_OtherFertilizer2Id)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
            </ux-table-column>
            <!-- 底肥 -->
            <ux-table-column title="追肥是否分次填报" width="120" :align="tableColumnAlign" field="PC_AfterFertilizerFillInMode" edit-render>
              <template v-slot:header>
                追肥<br />是否分次填报
              </template>
              <template v-slot:default="{row}">
                <span v-if="row.PC_AfterFertilizerFillInMode === 'NONE'" class="elx-cell--label">无追肥</span>
                <span v-if="row.PC_AfterFertilizerFillInMode === 'MANY_TIMES'" class="elx-cell--label">分次填报</span>
                <span v-if="row.PC_AfterFertilizerFillInMode === 'TOTAL'" class="elx-cell--label">合计填报</span>
              </template>
              <template v-slot:edit="scope">
                <el-select v-model="scope.row.PC_AfterFertilizerFillInMode" :size="editComponentSize" @change="handlePCAfterFertilizerFillInModeChange($event, scope)">
                  <el-option v-for="item in afterFertilizerFillInModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </template>
            </ux-table-column>
            <!-- 追肥是否分次填报 -->
            <ux-table-column title="追肥合计（公斤/亩）" :align="tableColumnAlign">
              <ux-table-column title="主要施肥方式" width="120" :align="tableColumnAlign" field="AFT_FertilizationMode" edit-render>
                <template v-slot:header>
                  主要<br />施肥方式
                </template>
                <template v-slot:default="{row}">
                  <span v-if="row.AFT_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE2'" class="elx-cell--label">冲施</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE3'" class="elx-cell--label">条施</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE4'" class="elx-cell--label">穴施</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE5'" class="elx-cell--label">水肥一体化</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE7'" class="elx-cell--label">机械深施</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE8'" class="elx-cell--label">无人机施肥</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.AFT_FertilizationMode" :size="editComponentSize" :disabled="'TOTAL' !== scope.row.PC_AfterFertilizerFillInMode" clearable @clear="handleAFTFertilizationModeClear(scope)">
                    <el-option v-for="item in afterFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="追肥次数" width="100" :align="tableColumnAlign" field="AFT_FertilizationTimes" edit-render>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_FertilizationTimes" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="首次施肥日期" width="150" :align="tableColumnAlign" field="AFT_FertilizationDate" edit-render>
                <template v-slot:edit="scope">
                  <el-date-picker v-model="scope.row.AFT_FertilizationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-date-picker>
                </template>
              </ux-table-column>
              <ux-table-column title="商用有机肥用量" width="100" :align="tableColumnAlign" field="AFT_BusinessOrganicFertilizerDosage" edit-render>
                <template v-slot:header>
                  商用<br />有机肥用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="尿素用量" width="100" :align="tableColumnAlign" field="AFT_UreaDosage" edit-render>
                <template v-slot:header>
                  尿素<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="碳铵用量" width="100" :align="tableColumnAlign" field="AFT_AmmoniumBicarbonateDosage" edit-render>
                <template v-slot:header>
                  碳铵<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="磷酸二铵用量" width="100" :align="tableColumnAlign" field="AFT_DiammoniumPhosphateDosage" edit-render>
                <template v-slot:header>
                  磷酸二铵<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="氯化钾用量" width="100" :align="tableColumnAlign" field="AFT_PotassiumChlorideDosage" edit-render>
                <template v-slot:header>氯化钾<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="硫酸钾用量" width="100" :align="tableColumnAlign" field="AFT_PotassiumSulphateDosage" edit-render>
                <template v-slot:header>
                  硫酸钾<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AFT_CompoundFertilizerDosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_CompoundFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)" clearable @clear="handleAFTFieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AFT_CompoundFertilizerN" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_CompoundFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AFT_CompoundFertilizerP2O5" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_CompoundFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AFT_CompoundFertilizerK2O" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_CompoundFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AFT_WaterSolubleFertilizerDosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)" clearable @clear="handleAFTFieldChange($event, scope, 'WaterSolubleFertilizerDosage')"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AFT_WaterSolubleFertilizerN" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_WaterSolubleFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AFT_WaterSolubleFertilizerP2O5" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_WaterSolubleFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AFT_WaterSolubleFertilizerK2O" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_WaterSolubleFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AFT_OtherFertilizer1Id" edit-render>
                  <template v-slot:default="{row}">
                    <span class="elx-cell--label">
                      {{ row.AFT_OtherFertilizer1Name }}
                    </span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AFT_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)" clearable @clear="handleAFTFieldChange($event, scope, 'OtherFertilizer1')">
                      <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AFT_OtherFertilizer1Dosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_OtherFertilizer1Id)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AFT_OtherFertilizer2Id" edit-render>
                  <template v-slot:default="{row}">
                    <span class="elx-cell--label">
                      {{ row.AFT_OtherFertilizer2Name }}
                    </span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AFT_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)" clearable @clear="handleAFTFieldChange($event, scope, 'OtherFertilizer2')">
                      <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AFT_OtherFertilizer2Dosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_OtherFertilizer2Id)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
            </ux-table-column>
            <!-- 追肥合计 -->
            <ux-table-column title="分次追肥 1 ~ 5" :align="tableColumnAlign">
              <ux-table-column title="追肥1（公斤/亩）" :align="tableColumnAlign">
                <ux-table-column title="主要施肥方式" width="120" :align="tableColumnAlign" field="AF1_FertilizationMode" edit-render>
                  <template v-slot:header>
                    主要<br />施肥方式
                  </template>
                  <template v-slot:default="{row}">
                    <span v-if="row.AF1_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE2'" class="elx-cell--label">冲施</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE3'" class="elx-cell--label">条施</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE4'" class="elx-cell--label">穴施</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE5'" class="elx-cell--label">水肥一体化</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE7'" class="elx-cell--label">机械深施</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE8'" class="elx-cell--label">无人机施肥</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AF1_FertilizationMode" :size="editComponentSize" :disabled="'MANY_TIMES' !== scope.row.PC_AfterFertilizerFillInMode" clearable @clear="handleAF1FertilizationModeClear(scope)">
                      <el-option v-for="item in afterFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="首次施肥日期" width="150" :align="tableColumnAlign" field="AF1_FertilizationDate" edit-render>
                  <template v-slot:edit="scope">
                    <el-date-picker v-model="scope.row.AF1_FertilizationDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-date-picker>
                  </template>
                </ux-table-column>
                <ux-table-column title="商用有机肥用量" width="100" :align="tableColumnAlign" field="AF1_BusinessOrganicFertilizerDosage" edit-render>
                  <template v-slot:header>
                    商用<br />有机肥用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF1_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="尿素用量" width="100" :align="tableColumnAlign" field="AF1_UreaDosage" edit-render>
                  <template v-slot:header>
                    尿素<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF1_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="碳铵用量" width="100" :align="tableColumnAlign" field="AF1_AmmoniumBicarbonateDosage" edit-render>
                  <template v-slot:header>
                    碳铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF1_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷酸二铵用量" width="100" :align="tableColumnAlign" field="AF1_DiammoniumPhosphateDosage" edit-render>
                  <template v-slot:header>
                    磷酸二铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF1_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氯化钾用量" width="100" :align="tableColumnAlign" field="AF1_PotassiumChlorideDosage" edit-render>
                  <template v-slot:header>
                    氯化钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF1_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="硫酸钾用量" width="100" :align="tableColumnAlign" field="AF1_PotassiumSulphateDosage" edit-render>
                  <template v-slot:header>
                    硫酸钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF1_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF1_CompoundFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_CompoundFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)" clearable @change="handleAF1FieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF1_CompoundFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF1_CompoundFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF1_CompoundFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF1_WaterSolubleFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)" clearable @change="handleAF1FieldChange($event, scope, 'WaterSolubleFertilizerDosage')"
                      ></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF1_WaterSolubleFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF1_WaterSolubleFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF1_WaterSolubleFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF1_OtherFertilizer1Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF1_OtherFertilizer1Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF1_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)" clearable @change="handleAF1FieldChange($event, scope, 'OtherFertilizer1')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF1_OtherFertilizer1Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_OtherFertilizer1Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF1_OtherFertilizer2Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF1_OtherFertilizer2Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF1_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)" clearable @change="handleAF1FieldChange($event, scope, 'OtherFertilizer2')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF1_OtherFertilizer2Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_OtherFertilizer2Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
              </ux-table-column>
              <!-- 追肥 1 -->
              <ux-table-column title="追肥2（公斤/亩）" :align="tableColumnAlign">
                <ux-table-column title="主要施肥方式" width="120" :align="tableColumnAlign" field="AF2_FertilizationMode" edit-render>
                  <template v-slot:header>
                    主要<br />施肥方式
                  </template>
                  <template v-slot:default="{row}">
                    <span v-if="row.AF2_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE2'" class="elx-cell--label">冲施</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE3'" class="elx-cell--label">条施</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE4'" class="elx-cell--label">穴施</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE5'" class="elx-cell--label">水肥一体化</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE7'" class="elx-cell--label">机械深施</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE8'" class="elx-cell--label">无人机施肥</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AF2_FertilizationMode" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)" clearable @clear="handleAF2FertilizationModeClear(scope)">
                      <el-option v-for="item in afterFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="首次施肥日期" width="150" :align="tableColumnAlign" field="AF2_FertilizationDate" edit-render>
                  <template v-slot:edit="scope">
                    <el-date-picker v-model="scope.row.AF2_FertilizationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-date-picker>
                  </template>
                </ux-table-column>
                <ux-table-column title="商用有机肥用量" width="100" :align="tableColumnAlign" field="AF2_BusinessOrganicFertilizerDosage" edit-render>
                  <template v-slot:header>
                    商用<br />有机肥用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF2_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="尿素用量" width="100" :align="tableColumnAlign" field="AF2_UreaDosage" edit-render>
                  <template v-slot:header>
                    尿素<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF2_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="碳铵用量" width="100" :align="tableColumnAlign" field="AF2_AmmoniumBicarbonateDosage" edit-render>
                  <template v-slot:header>
                    碳铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF2_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷酸二铵用量" width="100" :align="tableColumnAlign" field="AF2_DiammoniumPhosphateDosage" edit-render>
                  <template v-slot:header>
                    磷酸二铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF2_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氯化钾用量" width="100" :align="tableColumnAlign" field="AF2_PotassiumChlorideDosage" edit-render>
                  <template v-slot:header>
                    氯化钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF2_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="硫酸钾用量" width="100" :align="tableColumnAlign" field="AF2_PotassiumSulphateDosage" edit-render>
                  <template v-slot:header>
                    硫酸钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF2_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF2_CompoundFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_CompoundFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)" clearable @change="handleAF2FieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF2_CompoundFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF2_CompoundFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF2_CompoundFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF2_WaterSolubleFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)" clearable @change="handleAF2FieldChange($event, scope, 'WaterSolubleFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF2_WaterSolubleFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF2_WaterSolubleFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF2_WaterSolubleFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF2_OtherFertilizer1Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF2_OtherFertilizer1Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF2_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)" clearable @change="handleAF2FieldChange($event, scope, 'OtherFertilizer1')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF2_OtherFertilizer1Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_OtherFertilizer1Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF2_OtherFertilizer2Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF2_OtherFertilizer2Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF2_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)" clearable @change="handleAF2FieldChange($event, scope, 'OtherFertilizer2')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF2_OtherFertilizer2Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_OtherFertilizer2Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
              </ux-table-column>
              <!-- 追肥 2 -->
              <ux-table-column title="追肥3（公斤/亩）" :align="tableColumnAlign">
                <ux-table-column title="主要施肥方式" width="120" :align="tableColumnAlign" field="AF3_FertilizationMode" edit-render>
                  <template v-slot:header>
                    主要<br />施肥方式
                  </template>
                  <template v-slot:default="{row}">
                    <span v-if="row.AF3_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE2'" class="elx-cell--label">冲施</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE3'" class="elx-cell--label">条施</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE4'" class="elx-cell--label">穴施</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE5'" class="elx-cell--label">水肥一体化</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE7'" class="elx-cell--label">机械深施</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE8'" class="elx-cell--label">无人机施肥</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AF3_FertilizationMode" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)" clearable @clear="handleAF3FertilizationModeClear(scope)">
                      <el-option v-for="item in afterFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="首次施肥日期" width="150" :align="tableColumnAlign" field="AF3_FertilizationDate" edit-render>
                  <template v-slot:edit="scope">
                    <el-date-picker v-model="scope.row.AF3_FertilizationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-date-picker>
                  </template>
                </ux-table-column>
                <ux-table-column title="商用有机肥用量" width="100" :align="tableColumnAlign" field="AF3_BusinessOrganicFertilizerDosage" edit-render>
                  <template v-slot:header>
                    商用<br />有机肥用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF3_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="尿素用量" width="100" :align="tableColumnAlign" field="AF3_UreaDosage" edit-render>
                  <template v-slot:header>
                    尿素<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF3_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="碳铵用量" width="100" :align="tableColumnAlign" field="AF3_AmmoniumBicarbonateDosage" edit-render>
                  <template v-slot:header>
                    碳铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF3_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷酸二铵用量" width="100" :align="tableColumnAlign" field="AF3_DiammoniumPhosphateDosage" edit-render>
                  <template v-slot:header>
                    磷酸二铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF3_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氯化钾用量" width="100" :align="tableColumnAlign" field="AF3_PotassiumChlorideDosage" edit-render>
                  <template v-slot:header>
                    氯化钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF3_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="硫酸钾用量" width="100" :align="tableColumnAlign" field="AF3_PotassiumSulphateDosage" edit-render>
                  <template v-slot:header>
                    硫酸钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF3_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF3_CompoundFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_CompoundFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)" clearable @change="handleAF3FieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF3_CompoundFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF3_CompoundFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF3_CompoundFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF3_WaterSolubleFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)" clearable @change="handleAF3FieldChange($event, scope, 'WaterSolubleFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF3_WaterSolubleFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF3_WaterSolubleFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF3_WaterSolubleFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF3_OtherFertilizer1Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF3_OtherFertilizer1Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF3_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)" clearable @change="handleAF3FieldChange($event, scope, 'OtherFertilizer1')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF3_OtherFertilizer1Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_OtherFertilizer1Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF3_OtherFertilizer2Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF3_OtherFertilizer2Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF3_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)" clearable @change="handleAF3FieldChange($event, scope, 'OtherFertilizer2')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF3_OtherFertilizer2Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="'MANY_TIMES' !== scope.row.PC_AfterFertilizerFillInMode && helper.isEmpty(scope.row.AF3_OtherFertilizer2Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
              </ux-table-column>
              <!-- 追肥 3 -->
              <ux-table-column title="追肥4（公斤/亩）" :align="tableColumnAlign">
                <ux-table-column title="主要施肥方式" width="120" :align="tableColumnAlign" field="AF4_FertilizationMode" edit-render>
                  <template v-slot:header>
                    主要<br />施肥方式
                  </template>
                  <template v-slot:default="{row}">
                    <span v-if="row.AF4_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE2'" class="elx-cell--label">冲施</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE3'" class="elx-cell--label">条施</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE4'" class="elx-cell--label">穴施</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE5'" class="elx-cell--label">水肥一体化</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE7'" class="elx-cell--label">机械深施</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE8'" class="elx-cell--label">无人机施肥</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AF4_FertilizationMode" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)" clearable @clear="handleAF4FertilizationModeClear(scope)">
                      <el-option v-for="item in afterFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="首次施肥日期" width="150" :align="tableColumnAlign" field="AF4_FertilizationDate" edit-render>
                  <template v-slot:edit="scope">
                    <el-date-picker v-model="scope.row.AF4_FertilizationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-date-picker>
                  </template>
                </ux-table-column>
                <ux-table-column title="商用有机肥用量" width="100" :align="tableColumnAlign" field="AF4_BusinessOrganicFertilizerDosage" edit-render>
                  <template v-slot:header>
                    商用<br />有机肥用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF4_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="尿素用量" width="100" :align="tableColumnAlign" field="AF4_UreaDosage" edit-render>
                  <template v-slot:header>
                    尿素<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF4_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="碳铵用量" width="100" :align="tableColumnAlign" field="AF4_AmmoniumBicarbonateDosage" edit-render>
                  <template v-slot:header>
                    碳铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF4_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷酸二铵用量" width="100" :align="tableColumnAlign" field="AF4_DiammoniumPhosphateDosage" edit-render>
                  <template v-slot:header>
                    磷酸二铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF4_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氯化钾用量" width="100" :align="tableColumnAlign" field="AF4_PotassiumChlorideDosage" edit-render>
                  <template v-slot:header>
                    氯化钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF4_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="硫酸钾用量" width="100" :align="tableColumnAlign" field="AF4_PotassiumSulphateDosage" edit-render>
                  <template v-slot:header>
                    硫酸钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF4_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF4_CompoundFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_CompoundFertilizerDosage" :size="editComponentSize" :disabled="'MANY_TIMES' !== scope.row.PC_AfterFertilizerFillInMode && helper.isEmpty(scope.row.AF4_FertilizationMode)" clearable @change="handleAF4FieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF4_CompoundFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF4_CompoundFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF4_CompoundFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF4_WaterSolubleFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)" clearable @change="handleAF4FieldChange($event, scope, 'WaterSolubleFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF4_WaterSolubleFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF4_WaterSolubleFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF4_WaterSolubleFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF4_OtherFertilizer1Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF4_OtherFertilizer1Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF4_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)" clearable @change="handleAF4FieldChange($event, scope, 'OtherFertilizer1')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF4_OtherFertilizer1Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_OtherFertilizer1Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF4_OtherFertilizer2Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF4_OtherFertilizer2Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF4_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)" clearable @change="handleAF4FieldChange($event, scope, 'OtherFertilizer2')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF4_OtherFertilizer2Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_OtherFertilizer2Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
              </ux-table-column>
              <!-- 追肥 4 -->
              <ux-table-column title="追肥5（公斤/亩）" :align="tableColumnAlign">
                <ux-table-column title="主要施肥方式" width="120" :align="tableColumnAlign" field="AF5_FertilizationMode" edit-render>
                  <template v-slot:header>
                    主要<br />施肥方式
                  </template>
                  <template v-slot:default="{row}">
                    <span v-if="row.AF5_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE2'" class="elx-cell--label">冲施</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE3'" class="elx-cell--label">条施</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE4'" class="elx-cell--label">穴施</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE5'" class="elx-cell--label">水肥一体化</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE7'" class="elx-cell--label">机械深施</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE8'" class="elx-cell--label">无人机施肥</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AF5_FertilizationMode" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)" clearable @clear="handleAF5FertilizationModeClear(scope)">
                      <el-option v-for="item in afterFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="首次施肥日期" width="150" :align="tableColumnAlign" field="AF5_FertilizationDate" edit-render>
                  <template v-slot:edit="scope">
                    <el-date-picker v-model="scope.row.AF5_FertilizationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-date-picker>
                  </template>
                </ux-table-column>
                <ux-table-column width="100" :align="tableColumnAlign" field="AF5_BusinessOrganicFertilizerDosage" edit-render>
                  <template v-slot:header>
                    商用<br />有机肥用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF5_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column width="100" :align="tableColumnAlign" field="AF5_UreaDosage" edit-render>
                  <template v-slot:header>
                    尿素<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF5_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column width="100" :align="tableColumnAlign" field="AF5_AmmoniumBicarbonateDosage" edit-render>
                  <template v-slot:header>
                    碳铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF5_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column width="100" :align="tableColumnAlign" field="AF5_DiammoniumPhosphateDosage" edit-render>
                  <template v-slot:header>
                    磷酸二铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF5_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column width="100" :align="tableColumnAlign" field="AF5_PotassiumChlorideDosage" edit-render>
                  <template v-slot:header>
                    氯化钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF5_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column width="100" :align="tableColumnAlign" field="AF5_PotassiumSulphateDosage" edit-render>
                  <template v-slot:header>
                    硫酸钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF5_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF5_CompoundFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_CompoundFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)" clearable @change="handleAF5FieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF5_CompoundFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF5_CompoundFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF5_CompoundFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF5_WaterSolubleFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)" clearable @change="handleAF5FieldChange($event, scope, 'WaterSolubleFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF5_WaterSolubleFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF5_WaterSolubleFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF5_WaterSolubleFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF5_OtherFertilizer1Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF5_OtherFertilizer1Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF5_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)" clearable @change="handleAF5FieldChange($event, scope, 'OtherFertilizer1')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF5_OtherFertilizer1Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_OtherFertilizer1Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF5_OtherFertilizer2Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF5_OtherFertilizer2Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF5_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)" clearable @change="handleAF5FieldChange($event, scope, 'OtherFertilizer2')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF5_OtherFertilizer2Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_OtherFertilizer2Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
              </ux-table-column>
              <!-- 追肥 5 -->
            </ux-table-column>
            <!-- 追肥 1 ~ 5 -->
            <ux-table-column title="操作" width="100" :align="tableColumnAlign" fixed="right">
              <template v-slot="{row}">
                <template>
                  <el-button size="small" @click="handleSaveRow(row)">保存</el-button>
                </template>
              </template>
            </ux-table-column>
          </ux-grid>
        </div>
        <div class="wg-pagination-box">
          <el-pagination background layout="total, sizes, prev, pager, next" :total="tablePaging.recordTotal" :page-sizes="[100, 500, 1000]" :page-size="tablePaging.pageSize" :current-page="tablePaging.pageIndex" @size-change="handlePagingSizeChange" @current-change="handlePagingCurrentChange"></el-pagination>
        </div>
      </div>
    </div>

    <farmer-select-dialog ref="farmerSelectDialog" v-if="flags.loadFarmerSelectDialog" @close="handleFarmerSelectDialogClose"></farmer-select-dialog>
    <upload-assist-fill-in-data-file-dialog ref="uploadAssistFillInDataFileDialog" v-if="flags.loadUploadAssistFillInDataFileDialog" @close="handleUploadAssistFillInDataFileDialog"></upload-assist-fill-in-data-file-dialog>
  </div>
</template>

<script>
import AssistFillInDataApi from '@/api/data-fill-in/assist/assist-fill-in-data-api'
import MonitoringPlotApi from '@/api/monitoring-plot-api'
import CropApi from '@/api/crop-api'
import OrganicFertilizerApi from '@/api/organic-fertilizer-api'
import ChemicalFertilizerApi from '@/api/chemical-fertilizer-api'

import AssistFillInDataConfig from './AssistFillInDataConfig'

import FarmerSelectDialog from '@/components/FarmerSelectDialog'
import UploadAssistFillInDataFileDialog from './UploadAssistFillInDataFileDialog.vue'
import Screenfull from 'screenfull'
import FileApi from '@/api/file-api'

export default {
  name: 'UnsubmittedAssistFillInData',
  components: {
    'farmer-select-dialog': FarmerSelectDialog,
    'upload-assist-fill-in-data-file-dialog': UploadAssistFillInDataFileDialog
  },
  data() {
    return {
      year: null,
      regionId: null,
      dataLoading: false,
      isFullscreen: false,
      flags: {
        loadFarmerSelectDialog: false,
        loadUploadAssistFillInDataFileDialog: false,
        enableAdd: false,
        enableEdit: false
      },
      tableEditConfig: { trigger: 'click', mode: 'cell', showIcon: true },
      tableColumnAlign: 'center',
      tablePaging: {
        recordTotal: 0,
        pageSize: 100,
        pageIndex: 1
      },
      helper: {
        isEmpty: this.$utils.isEmpty,
        isNotEmpty: this.$utils.isNotEmpty
      },
      validRules: AssistFillInDataConfig.validRules,
      farmerNatureOptionList: [],
      educationalLevelOptionList: [],
      editComponentSize: 'medium',
      cropOptionGroupList: [],
      previousCropOptionGroupList: [],
      organicFertilizerOptionList: [],
      chemicalFertilizerOptionList: [],
      plotFertilityOptionList: [],
      yesAndNoOptionList: [],
      afterFertilizerFillInModeOptionList: [],
      baseFertilizationModeOptionList: [],
      afterFertilizationModeOptionList: []
    }
  },
  created() {
    this.farmerNatureOptionList.push({ label: '普通种植户', value: 'COMMON_PLANTER' })
    this.farmerNatureOptionList.push({ label: '种植大户', value: 'LARGE_PLANTER' })
    this.farmerNatureOptionList.push({ label: '家庭农场', value: 'FAMILY_FARM' })
    this.farmerNatureOptionList.push({ label: '专业合作社', value: 'SPECIALIZED_COOPERATIVE' })

    this.educationalLevelOptionList.push({ label: '小学', value: 'PRIMARY_SCHOOL' })
    this.educationalLevelOptionList.push({ label: '初中', value: 'JUNIOR_HIGH_SCHOOL' })
    this.educationalLevelOptionList.push({ label: '中专/中技', value: 'TECHNICAL_SECONDARY_SCHOOL' })
    this.educationalLevelOptionList.push({ label: '高中', value: 'SENIOR_HIGH_SCHOOL' })
    this.educationalLevelOptionList.push({ label: '大专', value: 'JUNIOR_COLLEGE' })
    this.educationalLevelOptionList.push({ label: '本科', value: 'UNDERGRADUATE' })
    this.educationalLevelOptionList.push({ label: '研究生', value: 'GRADUATE' })

    CropApi.getStubList()
      .then(res => {
        let grainCottonOilSugarOptionGroup = { label: '粮棉油糖', optionList: [] }
        let fruitTreeTobaccoTeaOptionGroup = { label: '果树烟茶', optionList: [] }
        let vegetableFlowerOptionGroup = { label: '蔬菜花卉', optionList: [] }
        res.data.forEach(el => {
          switch (el.category) {
            case 'GRAIN_COTTON_OIL_SUGAR':
              grainCottonOilSugarOptionGroup.optionList.push({
                label: el.name,
                value: el.id
              })
              break
            case 'FRUIT_TREE_TOBACCO_TEA':
              fruitTreeTobaccoTeaOptionGroup.optionList.push({
                label: el.name,
                value: el.id
              })
              break
            case 'VEGETABLE_FLOWER':
              vegetableFlowerOptionGroup.optionList.push({
                label: el.name,
                value: el.id
              })
              break
          }
        })

        this.cropOptionGroupList.push(grainCottonOilSugarOptionGroup)
        this.cropOptionGroupList.push(fruitTreeTobaccoTeaOptionGroup)
        this.cropOptionGroupList.push(vegetableFlowerOptionGroup)

        let defaultOptionGroup = { label: '默认', optionList: [] }
        defaultOptionGroup.optionList.push({ label: '无', value: 'NONE' })

        this.previousCropOptionGroupList.push(defaultOptionGroup)
        this.previousCropOptionGroupList.push(grainCottonOilSugarOptionGroup)
        this.previousCropOptionGroupList.push(fruitTreeTobaccoTeaOptionGroup)
        this.previousCropOptionGroupList.push(vegetableFlowerOptionGroup)
      })
      .catch(err => {
        this.$message({
          showClose: true,
          message: '【' + err.data.code + '】' + err.data.message,
          type: 'error'
        })
      })

    OrganicFertilizerApi.getStubList()
      .then(res => {
        res.data.forEach(el => {
          this.organicFertilizerOptionList.push({ label: el.name, value: el.id })
        })
      })
      .catch(err => {
        this.$message({
          showClose: true,
          message: '【' + err.data.code + '】' + err.data.message,
          type: 'error'
        })
      })

    ChemicalFertilizerApi.getStubList()
      .then(res => {
        res.data.forEach(el => {
          this.chemicalFertilizerOptionList.push({ label: el.name, value: el.id })
        })
      })
      .catch(err => {
        this.$message({
          showClose: true,
          message: '【' + err.data.code + '】' + err.data.message,
          type: 'error'
        })
      })

    this.plotFertilityOptionList.push({ label: '高', value: 'HIGH' })
    this.plotFertilityOptionList.push({ label: '中', value: 'MIDDLE' })
    this.plotFertilityOptionList.push({ label: '低', value: 'LOW' })

    this.yesAndNoOptionList.push({ label: '是', value: true })
    this.yesAndNoOptionList.push({ label: '否', value: false })

    this.afterFertilizerFillInModeOptionList.push({ label: '分次填报', value: 'MANY_TIMES' })
    this.afterFertilizerFillInModeOptionList.push({ label: '合计填报', value: 'TOTAL' })
    this.afterFertilizerFillInModeOptionList.push({ label: '无追肥', value: 'NONE' })

    this.baseFertilizationModeOptionList.push({ label: '撒施', value: 'MODE1' })
    this.baseFertilizationModeOptionList.push({ label: '机械深施', value: 'MODE2' })
    this.baseFertilizationModeOptionList.push({ label: '种肥同播', value: 'MODE3' })
    this.baseFertilizationModeOptionList.push({ label: '条施', value: 'MODE4' })
    this.baseFertilizationModeOptionList.push({ label: '穴施', value: 'MODE5' })
    this.baseFertilizationModeOptionList.push({ label: '无人机施肥', value: 'MODE7' })
    this.baseFertilizationModeOptionList.push({ label: '其他', value: 'MODE6' })

    this.afterFertilizationModeOptionList.push({ label: '撒施', value: 'MODE1' })
    this.afterFertilizationModeOptionList.push({ label: '冲施', value: 'MODE2' })
    this.afterFertilizationModeOptionList.push({ label: '条施', value: 'MODE3' })
    this.afterFertilizationModeOptionList.push({ label: '穴施', value: 'MODE4' })
    this.afterFertilizationModeOptionList.push({ label: '水肥一体化', value: 'MODE5' })
    this.afterFertilizationModeOptionList.push({ label: '机械深施', value: 'MODE7' })
    this.afterFertilizationModeOptionList.push({ label: '无人机施肥', value: 'MODE8' })
    this.afterFertilizationModeOptionList.push({ label: '其他', value: 'MODE6' })
  },
  mounted() {
    let that = this
    Screenfull.on('change', function() {
      that.$nextTick(() => {
        that.isFullscreen = Screenfull.isFullscreen
        that.tableHeight = Screenfull.isFullscreen ? window.innerHeight - 120 : '600px'
      })
    })
  },
  destroy() {
    if (Screenfull.enabled) {
      Screenfull.off('change', this.change)
    }
  },
  methods: {
    handleFullscreen() {
      if (!Screenfull.isEnabled) {
        this.$message({ message: '浏览器不支持', type: 'warning' })
        return false
      }

      Screenfull.request(this.$refs.unsubmittedAssistFillInDataPane)
    },
    handleExtFullscreen() {
      if (!Screenfull.isEnabled) {
        this.$message({ message: '浏览器不支持', type: 'warning' })
        return false
      }

      Screenfull.exit()
    },
    handlePagingSizeChange(pageSize) {
      this.tablePaging.pageSize = pageSize
      this.tablePaging.pageIndex = 1
    },
    handlePagingCurrentChange(pageIndex) {
      this.tablePaging.pageIndex = pageIndex
    },
    handleTableEditActived({ row, column }) {
      if (column.property === 'MP_Id') {
        row.filterData.plotOptionList = []
        MonitoringPlotApi.getStubList(row.UA_Id, false)
          .then(res => {
            res.data.forEach(el => {
              row.filterData.plotOptionList.push({
                value: el.id,
                label: el.name,
                name: el.name,
                code: el.code,
                area: el.plotArea
              })
            })
          })
          .catch(err => {
            this.$message({
              showClose: true,
              message: '【' + err.data.code + '】' + err.data.message,
              type: 'error'
            })
          })
      }
    },
    handleMPPlotChange(value, scope) {
      let option = scope.row.filterData.plotOptionList.find(el => el.value === value)
      scope.row.MP_Name = option.name
      scope.row.MP_Code = option.code
      scope.row.MP_Area = option.area

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handlePCCropChange(value, scope) {
      for (let i = 0; i < this.cropOptionGroupList.length; i++) {
        let option = this.cropOptionGroupList[i].optionList.find(el => el.value === value)
        if (undefined !== option) {
          scope.row.PC_CropName = option.label
          break
        }
      }
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleFacilityCultivationFlag(scope) {
      scope.row.PC_FacilityCultivationFlag = null
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handlePCHappenDisasterFlagClear(scope) {
      scope.row.PC_HappenDisasterFlag = null
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handlePCPreviousCropChange(value, scope) {
      if (value === '/') {
        scope.row.PC_PreviousCropName = '无'
        scope.row.PC_PreviousYield = null
        scope.row.PC_PreviousStrawReturnRate = null
      } else {
        for (let i = 0; i < this.previousCropOptionGroupList.length; i++) {
          let option = this.previousCropOptionGroupList[i].optionList.find(el => el.value === value)
          if (undefined !== option) {
            scope.row.PC_PreviousCropName = option.label
            break
          }
        }
      }
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleBFFertilizationModeClear(scope) {
      scope.row.BF_FertilizationMode = null
      scope.row.BF_FertilizationDate = null
      scope.row.BF_BusinessOrganicFertilizerDosage = null
      scope.row.BF_BusinessOrganicFertilizerPrice = null
      scope.row.BF_OrganicFertilizer1Id = null
      scope.row.BF_OrganicFertilizer1Name = null
      scope.row.BF_OrganicFertilizer1Dosage = null
      scope.row.BF_OrganicFertilizer2Id = null
      scope.row.BF_OrganicFertilizer2Name = null
      scope.row.BF_OrganicFertilizer2Dosage = null
      scope.row.BF_UreaDosage = null
      scope.row.BF_AmmoniumBicarbonateDosage = null
      scope.row.BF_DiammoniumPhosphateDosage = null
      scope.row.BF_PotassiumChlorideDosage = null
      scope.row.BF_PotassiumSulphateDosage = null
      scope.row.BF_CompoundFertilizerDosage = null
      scope.row.BF_CompoundFertilizerN = null
      scope.row.BF_CompoundFertilizerP2O5 = null
      scope.row.BF_CompoundFertilizerK2O = null
      scope.row.BF_WaterSolubleFertilizerDosage = null
      scope.row.BF_WaterSolubleFertilizerN = null
      scope.row.BF_WaterSolubleFertilizerP2O5 = null
      scope.row.BF_WaterSolubleFertilizerK2O = null
      scope.row.BF_OtherFertilizer1Id = null
      scope.row.BF_OtherFertilizer1Name = null
      scope.row.BF_OtherFertilizer1Dosage = null
      scope.row.BF_OtherFertilizer2Id = null
      scope.row.BF_OtherFertilizer2Name = null
      scope.row.BF_OtherFertilizer2Dosage = null
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleBFFieldChange(value, scope, field) {
      if (field === 'BusinessOrganicFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_BusinessOrganicFertilizerDosage = null
          scope.row.BF_BusinessOrganicFertilizerPrice = null
        }
      }

      if (field === 'OrganicFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_OrganicFertilizer1Id = null
          scope.row.BF_OrganicFertilizer1Name = null
          scope.row.BF_OrganicFertilizer1Dosage = null
        } else {
          let option = this.organicFertilizerOptionList.find(el => el.value === value)
          scope.row.BF_OrganicFertilizer1Name = option.label
        }
      }

      if (field === 'OrganicFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_OrganicFertilizer2Id = null
          scope.row.BF_OrganicFertilizer2Name = null
          scope.row.BF_OrganicFertilizer2Dosage = null
        } else {
          let option = this.organicFertilizerOptionList.find(el => el.value === value)
          scope.row.BF_OrganicFertilizer2Name = option.label
        }
      }

      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_CompoundFertilizerN = null
          scope.row.BF_CompoundFertilizerP2O5 = null
          scope.row.BF_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_WaterSolubleFertilizerN = null
          scope.row.BF_WaterSolubleFertilizerP2O5 = null
          scope.row.BF_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_OtherFertilizer1Id = null
          scope.row.BF_OtherFertilizer1Name = null
          scope.row.BF_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.BF_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_OtherFertilizer2Id = null
          scope.row.BF_OtherFertilizer2Name = null
          scope.row.BF_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.BF_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handlePCAfterFertilizerFillInModeChange(value, scope) {
      if (value === 'NONE') {
        this.clearAFTAllFieldValue(scope.row)
        this.clearAF1AllFieldValue(scope.row)
        this.clearAF2AllFieldValue(scope.row)
        this.clearAF3AllFieldValue(scope.row)
        this.clearAF4AllFieldValue(scope.row)
        this.clearAF5AllFieldValue(scope.row)
      }
      if (value === 'MANY_TIMES') {
        this.clearAFTAllFieldValue(scope.row)
      }
      if (value === 'TOTAL') {
        this.clearAF1AllFieldValue(scope.row)
        this.clearAF2AllFieldValue(scope.row)
        this.clearAF3AllFieldValue(scope.row)
        this.clearAF4AllFieldValue(scope.row)
        this.clearAF5AllFieldValue(scope.row)
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAFTFertilizationModeClear(scope) {
      this.clearAFTAllFieldValue(scope.row)
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAFTFieldChange(value, scope, field) {
      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AFT_CompoundFertilizerN = null
          scope.row.AFT_CompoundFertilizerP2O5 = null
          scope.row.AFT_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AFT_WaterSolubleFertilizerN = null
          scope.row.AFT_WaterSolubleFertilizerP2O5 = null
          scope.row.AFT_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.AFT_OtherFertilizer1Id = null
          scope.row.AFT_OtherFertilizer1Name = null
          scope.row.AFT_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AFT_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.AFT_OtherFertilizer2Id = null
          scope.row.AFT_OtherFertilizer2Name = null
          scope.row.AFT_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AFT_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF1FertilizationModeClear(scope) {
      this.clearAF1AllFieldValue(scope.row)
      this.clearAF2AllFieldValue(scope.row)
      this.clearAF3AllFieldValue(scope.row)
      this.clearAF4AllFieldValue(scope.row)
      this.clearAF5AllFieldValue(scope.row)
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF1FieldChange(value, scope, field) {
      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF1_CompoundFertilizerN = null
          scope.row.AF1_CompoundFertilizerP2O5 = null
          scope.row.AF1_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF1_WaterSolubleFertilizerN = null
          scope.row.AF1_WaterSolubleFertilizerP2O5 = null
          scope.row.AF1_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF1_OtherFertilizer1Id = null
          scope.row.AF1_OtherFertilizer1Name = null
          scope.row.AF1_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF1_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF1_OtherFertilizer2Id = null
          scope.row.AF1_OtherFertilizer2Name = null
          scope.row.AF1_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF1_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF2FertilizationModeClear(scope) {
      this.clearAF2AllFieldValue(scope.row)
      this.clearAF3AllFieldValue(scope.row)
      this.clearAF4AllFieldValue(scope.row)
      this.clearAF5AllFieldValue(scope.row)
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF2FieldChange(value, scope, field) {
      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF2_CompoundFertilizerN = null
          scope.row.AF2_CompoundFertilizerP2O5 = null
          scope.row.AF2_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF2_WaterSolubleFertilizerN = null
          scope.row.AF2_WaterSolubleFertilizerP2O5 = null
          scope.row.AF2_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF2_OtherFertilizer1Id = null
          scope.row.AF2_OtherFertilizer1Name = null
          scope.row.AF2_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF2_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF2_OtherFertilizer2Id = null
          scope.row.AF2_OtherFertilizer2Name = null
          scope.row.AF2_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF2_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF3FertilizationModeClear(scope) {
      this.clearAF3AllFieldValue(scope.row)
      this.clearAF4AllFieldValue(scope.row)
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF3FieldChange(value, scope, field) {
      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF3_CompoundFertilizerN = null
          scope.row.AF3_CompoundFertilizerP2O5 = null
          scope.row.AF3_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF3_WaterSolubleFertilizerN = null
          scope.row.AF3_WaterSolubleFertilizerP2O5 = null
          scope.row.AF3_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF3_OtherFertilizer1Id = null
          scope.row.AF3_OtherFertilizer1Name = null
          scope.row.AF3_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF3_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF3_OtherFertilizer2Id = null
          scope.row.AF3_OtherFertilizer2Name = null
          scope.row.AF3_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF3_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF4FertilizationModeClear(scope) {
      this.clearAF4AllFieldValue(scope.row)
      this.clearAF5AllFieldValue(scope.row)
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF4FieldChange(value, scope, field) {
      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF4_CompoundFertilizerN = null
          scope.row.AF4_CompoundFertilizerP2O5 = null
          scope.row.AF4_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF4_WaterSolubleFertilizerN = null
          scope.row.AF4_WaterSolubleFertilizerP2O5 = null
          scope.row.AF4_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF4_OtherFertilizer1Id = null
          scope.row.AF4_OtherFertilizer1Name = null
          scope.row.AF4_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF4_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF4_OtherFertilizer2Id = null
          scope.row.AF4_OtherFertilizer2Name = null
          scope.row.AF4_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF4_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF5FertilizationModeClear(scope) {
      this.clearAF5AllFieldValue(scope.row)
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF5FieldChange(value, scope, field) {
      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF5_CompoundFertilizerN = null
          scope.row.AF5_CompoundFertilizerP2O5 = null
          scope.row.AF5_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF5_WaterSolubleFertilizerN = null
          scope.row.AF5_WaterSolubleFertilizerP2O5 = null
          scope.row.AF5_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF5_OtherFertilizer1Id = null
          scope.row.AF5_OtherFertilizer1Name = null
          scope.row.AF5_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF5_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF5_OtherFertilizer2Id = null
          scope.row.AF5_OtherFertilizer2Name = null
          scope.row.AF5_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF5_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAddUser() {
      this.$router.push({ path: '/user' })
    },
    handleAddRows() {
      this.flags.loadFarmerSelectDialog = true
      this.$nextTick(() => {
        this.$refs.farmerSelectDialog.open(this.regionId)
      })
    },
    handleSaveRow(row) {
      if (!this.$refs.fillDataTable.isUpdateByRow(row)) {
        this.$message({ showClose: true, message: '未修改任何数据！', type: 'warning' })
        return
      }

      this.$refs.fillDataTable.validate(row).then(data => {
        if (data) {
          let masStr = '<ul class="valid-error-list">'
          Object.values(data).forEach(errList => {
            errList.forEach(params => {
              let { rowIndex, rules } = params
              rules.forEach(rule => {
                masStr += `<li>第 ${rowIndex + 1} 行 ${rule.message}</li>`
              })
            })
          })
          masStr += '</ul>'
          this.$notify.warning({
            title: '填报数据验证失败！',
            dangerouslyUseHTMLString: true,
            message: masStr,
            duration: 5000
          })
        } else {

          let model = this.buildSaveModel(row)
          this.dataLoading = true
          AssistFillInDataApi.update(model).then(() => {
            this.$message({ type: 'success', message: '保存成功！' })
            this.$refs.fillDataTable.reloadRow(row, null, null)
          }).catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          }).finally(() => {
            this.dataLoading = false
          })
        }
      })
    },
    handleSaveRows() {
      this.$refs.fillDataTable.fullValidate().then(data => {
        if (data !== undefined) {
          let masStr = '<ul class="valid-error-list">'
          Object.values(data).forEach(errList => {
            errList.forEach(params => {
              let { rowIndex, rules } = params
              rules.forEach(rule => {
                masStr += `<li>第 ${rowIndex + 1} 行 ${rule.message}</li>`
              })
            })
          })
          masStr += '</ul>'
          this.$notify.warning({
            title: '填报数据验证失败！',
            dangerouslyUseHTMLString: true,
            message: masStr,
            duration: 5000
          })
          return
        }

        let records = this.$refs.fillDataTable.getUpdateRecords()
        if (records.length === 0) {
          this.$message({ showClose: true, message: '未修改任何数据！', type: 'warning' })
          return
        }

        if (records.length === 1) {
          let model = this.buildSaveModel(records[0])

          AssistFillInDataApi.update(model).then(res => {
            console.log(res.data)
          })
        } else {
          let modelList = []
          for (let i = 0; i < records.length; i++) {
            let model = this.buildSaveModel(records[i])
            modelList.push(model)
          }

          AssistFillInDataApi.updateMultiple(modelList).then(res => {
            console.log(res.data)
          })
        }
      })
    },
    async handleDeleteRows() {
      let checkedRecords = this.$refs.fillDataTable.getCheckboxRecords()
      let deletedCount = 0

      if (checkedRecords.length === 0) {
        this.$message({ type: 'warning', message: '未选择任何记录！' })
        return
      }

      this.dataLoading = true
      for (let i = 0; i < checkedRecords.length; i++) {
        await AssistFillInDataApi.deleteById(checkedRecords[i].PC_Id).then(() => {
          deletedCount++
        }).catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
      }
      this.dataLoading = false

      if (deletedCount === checkedRecords.length)
        this.$message({ type: 'success', message: '删除' + deletedCount + '记录成功！' })

      this.bindTableDataCount()
      this.bindTableData()
    },
    async handleSubmitRows() {
      let checkedRecords = this.$refs.fillDataTable.getCheckboxRecords()
      let submitCount = 0

      if (checkedRecords.length === 0) {
        this.$message({ type: 'warning', message: '未选择任何记录！' })
        return
      }

      this.dataLoading = true

      for (let i = 0; i < checkedRecords.length; i++) {
        await AssistFillInDataApi.submit(checkedRecords[i].PC_Id).then(() => {
          submitCount++
        }).catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
      }

      this.dataLoading = false

      if (submitCount === checkedRecords.length)
        this.$message({ type: 'success', message: '提交' + submitCount + '记录成功！' })

      this.bindTableDataCount()
      this.bindTableData()
    },
    handleRefresh() {
      this.bindTableData()
    },
    handleImportData() {
      this.flags.loadUploadAssistFillInDataFileDialog = true
      this.$nextTick(() => {
        this.$refs.uploadAssistFillInDataFileDialog.open(this.year)
      })
    },
    handleExportData() {
      this.$confirm('导出数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const exportLoading = this.$loading({
          lock: true,
          text: '正在导出数据......',
          spinner: 'el-icon-loading'
        })

        let exportFileName
        await AssistFillInDataApi.exportData(this.year, this.regionId).then(res => {
          exportFileName = res.data
        }).catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        }).finally(() => {
          exportLoading.close()
        })

        const downFileLoading = this.$loading({
          lock: true,
          text: '正在下载数据文件......',
          spinner: 'el-icon-loading'
        })

        await FileApi.get('TEMP', exportFileName).then(res => {
          let byteBuffer = window.atob(res.data)
          let arrayBuffer = new ArrayBuffer(byteBuffer.length)
          let dataBuffer = new Uint8Array(arrayBuffer)
          for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

          let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', '数据模板.xlsx')
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
          document.body.removeChild(link)
        }).catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        }).finally(() => {
          downFileLoading.close()
        })

        await FileApi.delete('TEMP', exportFileName)
          .then(() => {
          })
          .catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          })
      }).catch(() => {
        this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
      })
    },
    handleTemplateDownload() {
      this.$confirm('导出数据模板, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const exportLoading = this.$loading({
          lock: true,
          text: '正在导出数据......',
          spinner: 'el-icon-loading'
        })

        let exportFileName
        await AssistFillInDataApi.exportTemplate(this.year, this.regionId).then(res => {
          exportFileName = res.data
        }).catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        }).finally(() => {
          exportLoading.close()
        })

        const downFileLoading = this.$loading({
          lock: true,
          text: '正在下载数据文件......',
          spinner: 'el-icon-loading'
        })

        await FileApi.get('TEMP', exportFileName).then(res => {
          let byteBuffer = window.atob(res.data)
          let arrayBuffer = new ArrayBuffer(byteBuffer.length)
          let dataBuffer = new Uint8Array(arrayBuffer)
          for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

          let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', '数据模板.xlsx')
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
          document.body.removeChild(link)
        }).catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        }).finally(() => {
          downFileLoading.close()
        })

        await FileApi.delete('TEMP', exportFileName)
          .then(() => {
          })
          .catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          })
      }).catch(() => {
        this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
      })
    },
    handleFarmerSelectDialogClose(rows) {
      if (rows.length === 0)
        return

      this.$emit('hideComponent', rows)
    },
    handleUploadAssistFillInDataFileDialog(imported) {
      if (imported === 'SUCCESS') {
        this.bindTableDataCount()
        this.bindTableData()
      }

      this.flags.loadUploadAssistFillInDataFileDialog = false
    },
    clearAFTAllFieldValue(row) {
      row.AFT_FertilizationMode = null
      row.AFT_FertilizationTimes = null
      row.AFT_FertilizationDate = null
      row.AFT_BusinessOrganicFertilizerDosage = null
      row.AFT_UreaDosage = null
      row.AFT_AmmoniumBicarbonateDosage = null
      row.AFT_DiammoniumPhosphateDosage = null
      row.AFT_PotassiumChlorideDosage = null
      row.AFT_PotassiumSulphateDosage = null
      row.AFT_CompoundFertilizerDosage = null
      row.AFT_CompoundFertilizerN = null
      row.AFT_CompoundFertilizerP2O5 = null
      row.AFT_CompoundFertilizerK2O = null
      row.AFT_WaterSolubleFertilizerDosage = null
      row.AFT_WaterSolubleFertilizerN = null
      row.AFT_WaterSolubleFertilizerP2O5 = null
      row.AFT_WaterSolubleFertilizerK2O = null
      row.AFT_OtherFertilizer1Id = null
      row.AFT_OtherFertilizer1Name = null
      row.AFT_OtherFertilizer1Dosage = null
      row.AFT_OtherFertilizer2Id = null
      row.AFT_OtherFertilizer2Name = null
      row.AFT_OtherFertilizer2Dosage = null
    },
    clearAF1AllFieldValue(row) {
      row.AF1_FertilizationMode = null
      row.AF1_FertilizationDate = null
      row.AF1_BusinessOrganicFertilizerDosage = null
      row.AF1_UreaDosage = null
      row.AF1_AmmoniumBicarbonateDosage = null
      row.AF1_DiammoniumPhosphateDosage = null
      row.AF1_PotassiumChlorideDosage = null
      row.AF1_PotassiumSulphateDosage = null
      row.AF1_CompoundFertilizerDosage = null
      row.AF1_CompoundFertilizerN = null
      row.AF1_CompoundFertilizerP2O5 = null
      row.AF1_CompoundFertilizerK2O = null
      row.AF1_WaterSolubleFertilizerDosage = null
      row.AF1_WaterSolubleFertilizerN = null
      row.AF1_WaterSolubleFertilizerP2O5 = null
      row.AF1_WaterSolubleFertilizerK2O = null
      row.AF1_OtherFertilizer1Id = null
      row.AF1_OtherFertilizer1Name = null
      row.AF1_OtherFertilizer1Dosage = null
      row.AF1_OtherFertilizer2Id = null
      row.AF1_OtherFertilizer2Name = null
      row.AF1_OtherFertilizer2Dosage = null
    },
    clearAF2AllFieldValue(row) {
      row.AF2_FertilizationMode = null
      row.AF2_FertilizationDate = null
      row.AF2_BusinessOrganicFertilizerDosage = null
      row.AF2_UreaDosage = null
      row.AF2_AmmoniumBicarbonateDosage = null
      row.AF2_DiammoniumPhosphateDosage = null
      row.AF2_PotassiumChlorideDosage = null
      row.AF2_PotassiumSulphateDosage = null
      row.AF2_CompoundFertilizerDosage = null
      row.AF2_CompoundFertilizerN = null
      row.AF2_CompoundFertilizerP2O5 = null
      row.AF2_CompoundFertilizerK2O = null
      row.AF2_WaterSolubleFertilizerDosage = null
      row.AF2_WaterSolubleFertilizerN = null
      row.AF2_WaterSolubleFertilizerP2O5 = null
      row.AF2_WaterSolubleFertilizerK2O = null
      row.AF2_OtherFertilizer1Id = null
      row.AF2_OtherFertilizer1Name = null
      row.AF2_OtherFertilizer1Dosage = null
      row.AF2_OtherFertilizer2Id = null
      row.AF2_OtherFertilizer2Name = null
      row.AF2_OtherFertilizer2Dosage = null
    },
    clearAF3AllFieldValue(row) {
      row.AF3_FertilizationMode = null
      row.AF3_FertilizationDate = null
      row.AF3_BusinessOrganicFertilizerDosage = null
      row.AF3_UreaDosage = null
      row.AF3_AmmoniumBicarbonateDosage = null
      row.AF3_DiammoniumPhosphateDosage = null
      row.AF3_PotassiumChlorideDosage = null
      row.AF3_PotassiumSulphateDosage = null
      row.AF3_CompoundFertilizerDosage = null
      row.AF3_CompoundFertilizerN = null
      row.AF3_CompoundFertilizerP2O5 = null
      row.AF3_CompoundFertilizerK2O = null
      row.AF3_WaterSolubleFertilizerDosage = null
      row.AF3_WaterSolubleFertilizerN = null
      row.AF3_WaterSolubleFertilizerP2O5 = null
      row.AF3_WaterSolubleFertilizerK2O = null
      row.AF3_OtherFertilizer1Id = null
      row.AF3_OtherFertilizer1Name = null
      row.AF3_OtherFertilizer1Dosage = null
      row.AF3_OtherFertilizer2Id = null
      row.AF3_OtherFertilizer2Name = null
      row.AF3_OtherFertilizer2Dosage = null
    },
    clearAF4AllFieldValue(row) {
      row.AF4_FertilizationMode = null
      row.AF4_FertilizationDate = null
      row.AF4_BusinessOrganicFertilizerDosage = null
      row.AF4_UreaDosage = null
      row.AF4_AmmoniumBicarbonateDosage = null
      row.AF4_DiammoniumPhosphateDosage = null
      row.AF4_PotassiumChlorideDosage = null
      row.AF4_PotassiumSulphateDosage = null
      row.AF4_CompoundFertilizerDosage = null
      row.AF4_CompoundFertilizerN = null
      row.AF4_CompoundFertilizerP2O5 = null
      row.AF4_CompoundFertilizerK2O = null
      row.AF4_WaterSolubleFertilizerDosage = null
      row.AF4_WaterSolubleFertilizerN = null
      row.AF4_WaterSolubleFertilizerP2O5 = null
      row.AF4_WaterSolubleFertilizerK2O = null
      row.AF4_OtherFertilizer1Id = null
      row.AF4_OtherFertilizer1Name = null
      row.AF4_OtherFertilizer1Dosage = null
      row.AF4_OtherFertilizer2Id = null
      row.AF4_OtherFertilizer2Name = null
      row.AF4_OtherFertilizer2Dosage = null
    },
    clearAF5AllFieldValue(row) {
      row.AF5_FertilizationMode = null
      row.AF5_FertilizationDate = null
      row.AF5_BusinessOrganicFertilizerDosage = null
      row.AF5_UreaDosage = null
      row.AF5_AmmoniumBicarbonateDosage = null
      row.AF5_DiammoniumPhosphateDosage = null
      row.AF5_PotassiumChlorideDosage = null
      row.AF5_PotassiumSulphateDosage = null
      row.AF5_CompoundFertilizerDosage = null
      row.AF5_CompoundFertilizerN = null
      row.AF5_CompoundFertilizerP2O5 = null
      row.AF5_CompoundFertilizerK2O = null
      row.AF5_WaterSolubleFertilizerDosage = null
      row.AF5_WaterSolubleFertilizerN = null
      row.AF5_WaterSolubleFertilizerP2O5 = null
      row.AF5_WaterSolubleFertilizerK2O = null
      row.AF5_OtherFertilizer1Id = null
      row.AF5_OtherFertilizer1Name = null
      row.AF5_OtherFertilizer1Dosage = null
      row.AF5_OtherFertilizer2Id = null
      row.AF5_OtherFertilizer2Name = null
      row.AF5_OtherFertilizer2Dosage = null
    },
    load(year, regionId) {
      this.year = year
      this.regionId = regionId
      this.bindTableDataCount()
      this.bindTableData()
    },
    bindTableDataCount() {
      AssistFillInDataApi.count(this.year, this.regionId, false).then(res => {
        this.tablePaging.recordTotal = res.data
      })
    },
    bindTableData() {
      this.dataLoading = true
      setTimeout(async () => {
        const tableData = []
        await AssistFillInDataApi.search(this.year, this.regionId, false, this.tablePaging.pageSize, this.tablePaging.pageIndex).then(res => {
          res.data.forEach(el => {
            let row = {
              filterData: {
                plotOptionList: []
              },
              UA_Id: el.user.id,
              UA_Username: el.user.username,
              UA_FullName: el.user.fullName,
              UARegionId: el.user.regionId,
              UA_ProvinceName: el.user.provinceName,
              UA_CityName: el.user.cityName,
              UA_DistrictName: el.user.districtName,
              UA_DetailedAddress: el.user.detailedAddress,
              UA_PhoneNumber: el.user.phoneNumber,
              UA_FarmerNature: el.user.farmerNature,
              UA_EducationalLevel: el.user.educationalLevel,
              UA_CultivationLandArea: el.user.cultivationLandArea,
              UA_ModelHouseholdFlag: el.user.modelHouseholdFlag,
              MP_Id: el.monitoringPlot.id,
              MP_Name: el.monitoringPlot.name,
              MP_Code: el.monitoringPlot.code,
              MP_Area: el.monitoringPlot.area,
              PC_Id: el.plantCrop.id,
              PC_Year: el.plantCrop.year,
              PC_CropId: el.plantCrop.cropId,
              PC_CropName: el.plantCrop.cropName,
              PC_CropType: el.plantCrop.cropType,
              PC_SowingDate: this.helper.isEmpty(el.plantCrop.sowingDate) ? null : this.$moment(el.plantCrop.sowingDate).format('YYYY-MM-DD'),
              PC_HarvestTime: this.helper.isEmpty(el.plantCrop.harvestTime) ? null : this.$moment(el.plantCrop.harvestTime).format('YYYY-MM-DD'),
              PC_SowingArea: el.plantCrop.sowingArea,
              PC_PlotFertility: el.plantCrop.plotFertility,
              PC_Yield: el.plantCrop.yield,
              PC_FacilityCultivationFlag: el.plantCrop.facilityCultivationFlag,
              PC_HappenDisasterFlag: el.plantCrop.happenDisasterFlag,
              PC_PreviousCropId: null === el.plantCrop.previousCropId ? 'NONE' : el.plantCrop.previousCropId,
              PC_PreviousCropName: null === el.plantCrop.previousCropName ? '无' : el.plantCrop.previousCropName,
              PC_PreviousYield: el.plantCrop.previousYield,
              PC_PreviousStrawReturnRate: el.plantCrop.previousStrawReturnRate,
              PC_AfterFertilizerFillInMode: el.plantCrop.afterFertilizerFillInMode,
              PC_CreatedOn: el.plantCrop.createdOn,
              PC_ModifiedOn: el.plantCrop.modifiedOn,
              BF_FertilizationMode: el.baseFertilizer.fertilizationMode,
              BF_FertilizationDate: this.helper.isEmpty(el.baseFertilizer.fertilizationDate) ? null : this.$moment(el.baseFertilizer.fertilizationDate).format('YYYY-MM-DD'),
              BF_BusinessOrganicFertilizerDosage: el.baseFertilizer.businessOrganicFertilizerDosage,
              BF_BusinessOrganicFertilizerPrice: el.baseFertilizer.businessOrganicFertilizerPrice,
              BF_OrganicFertilizer1Id: el.baseFertilizer.organicFertilizer1Id,
              BF_OrganicFertilizer1Name: el.baseFertilizer.organicFertilizer1Name,
              BF_OrganicFertilizer1Dosage: el.baseFertilizer.organicFertilizer1Dosage,
              BF_OrganicFertilizer2Id: el.baseFertilizer.organicFertilizer2Id,
              BF_OrganicFertilizer2Name: el.baseFertilizer.organicFertilizer2Name,
              BF_OrganicFertilizer2Dosage: el.baseFertilizer.organicFertilizer2Dosage,
              BF_UreaDosage: el.baseFertilizer.ureaDosage,
              BF_AmmoniumBicarbonateDosage: el.baseFertilizer.ammoniumBicarbonateDosage,
              BF_DiammoniumPhosphateDosage: el.baseFertilizer.diammoniumPhosphateDosage,
              BF_PotassiumChlorideDosage: el.baseFertilizer.potassiumChlorideDosage,
              BF_PotassiumSulphateDosage: el.baseFertilizer.potassiumSulphateDosage,
              BF_CompoundFertilizerDosage: el.baseFertilizer.compoundFertilizerDosage,
              BF_CompoundFertilizerN: el.baseFertilizer.compoundFertilizerN,
              BF_CompoundFertilizerP2O5: el.baseFertilizer.compoundFertilizerP2O5,
              BF_CompoundFertilizerK2O: el.baseFertilizer.compoundFertilizerK2O,
              BF_WaterSolubleFertilizerDosage: el.baseFertilizer.waterSolubleFertilizerDosage,
              BF_WaterSolubleFertilizerN: el.baseFertilizer.waterSolubleFertilizerN,
              BF_WaterSolubleFertilizerP2O5: el.baseFertilizer.waterSolubleFertilizerP2O5,
              BF_WaterSolubleFertilizerK2O: el.baseFertilizer.waterSolubleFertilizerK2O,
              BF_OtherFertilizer1Id: el.baseFertilizer.otherFertilizer1Id,
              BF_OtherFertilizer1Name: el.baseFertilizer.otherFertilizer1Name,
              BF_OtherFertilizer1Dosage: el.baseFertilizer.otherFertilizer1Dosage,
              BF_OtherFertilizer2Id: el.baseFertilizer.otherFertilizer2Id,
              BF_OtherFertilizer2Name: el.baseFertilizer.otherFertilizer2Name,
              BF_OtherFertilizer2Dosage: el.baseFertilizer.otherFertilizer2Dosage,
              AFT_FertilizationMode: el.afterFertilizerTotal.fertilizationMode,
              AFT_FertilizationTimes: el.afterFertilizerTotal.fertilizationTimes,
              AFT_FertilizationDate: this.helper.isEmpty(el.afterFertilizerTotal.fertilizationDate) ? null : this.$moment(el.afterFertilizerTotal.fertilizationDate).format('YYYY-MM-DD'),
              AFT_BusinessOrganicFertilizerDosage: el.afterFertilizerTotal.businessOrganicFertilizerDosage,
              AFT_UreaDosage: el.afterFertilizerTotal.ureaDosage,
              AFT_AmmoniumBicarbonateDosage: el.afterFertilizerTotal.ammoniumBicarbonateDosage,
              AFT_DiammoniumPhosphateDosage: el.afterFertilizerTotal.diammoniumPhosphateDosage,
              AFT_PotassiumChlorideDosage: el.afterFertilizerTotal.potassiumChlorideDosage,
              AFT_PotassiumSulphateDosage: el.afterFertilizerTotal.potassiumSulphateDosage,
              AFT_CompoundFertilizerDosage: el.afterFertilizerTotal.compoundFertilizerDosage,
              AFT_CompoundFertilizerN: el.afterFertilizerTotal.compoundFertilizerN,
              AFT_CompoundFertilizerP2O5: el.afterFertilizerTotal.compoundFertilizerP2O5,
              AFT_CompoundFertilizerK2O: el.afterFertilizerTotal.compoundFertilizerK2O,
              AFT_WaterSolubleFertilizerDosage: el.afterFertilizerTotal.waterSolubleFertilizerDosage,
              AFT_WaterSolubleFertilizerN: el.afterFertilizerTotal.waterSolubleFertilizerN,
              AFT_WaterSolubleFertilizerP2O5: el.afterFertilizerTotal.waterSolubleFertilizerP2O5,
              AFT_WaterSolubleFertilizerK2O: el.afterFertilizerTotal.waterSolubleFertilizerK2O,
              AFT_OtherFertilizer1Id: el.afterFertilizerTotal.otherFertilizer1Id,
              AFT_OtherFertilizer1Name: el.afterFertilizerTotal.otherFertilizer1Name,
              AFT_OtherFertilizer1Dosage: el.afterFertilizerTotal.otherFertilizer1Dosage,
              AFT_OtherFertilizer2Id: el.afterFertilizerTotal.otherFertilizer2Id,
              AFT_OtherFertilizer2Name: el.afterFertilizerTotal.otherFertilizer2Name,
              AFT_OtherFertilizer2Dosage: el.afterFertilizerTotal.otherFertilizer2Dosage,
              AF1_FertilizationMode: el.afterFertilizer1.fertilizationMode,
              AF1_FertilizationDate: this.helper.isEmpty(el.afterFertilizer1.fertilizationDate) ? null : this.$moment(el.afterFertilizer1.fertilizationDate).format('YYYY-MM-DD'),
              AF1_BusinessOrganicFertilizerDosage: el.afterFertilizer1.businessOrganicFertilizerDosage,
              AF1_UreaDosage: el.afterFertilizer1.ureaDosage,
              AF1_AmmoniumBicarbonateDosage: el.afterFertilizer1.ammoniumBicarbonateDosage,
              AF1_DiammoniumPhosphateDosage: el.afterFertilizer1.diammoniumPhosphateDosage,
              AF1_PotassiumChlorideDosage: el.afterFertilizer1.potassiumChlorideDosage,
              AF1_PotassiumSulphateDosage: el.afterFertilizer1.potassiumSulphateDosage,
              AF1_CompoundFertilizerDosage: el.afterFertilizer1.compoundFertilizerDosage,
              AF1_CompoundFertilizerN: el.afterFertilizer1.compoundFertilizerN,
              AF1_CompoundFertilizerP2O5: el.afterFertilizer1.compoundFertilizerP2O5,
              AF1_CompoundFertilizerK2O: el.afterFertilizer1.compoundFertilizerK2O,
              AF1_WaterSolubleFertilizerDosage: el.afterFertilizer1.waterSolubleFertilizerDosage,
              AF1_WaterSolubleFertilizerN: el.afterFertilizer1.waterSolubleFertilizerN,
              AF1_WaterSolubleFertilizerP2O5: el.afterFertilizer1.waterSolubleFertilizerP2O5,
              AF1_WaterSolubleFertilizerK2O: el.afterFertilizer1.waterSolubleFertilizerK2O,
              AF1_OtherFertilizer1Id: el.afterFertilizer1.otherFertilizer1Id,
              AF1_OtherFertilizer1Name: el.afterFertilizer1.otherFertilizer1Name,
              AF1_OtherFertilizer1Dosage: el.afterFertilizer1.otherFertilizer1Dosage,
              AF1_OtherFertilizer2Id: el.afterFertilizer1.otherFertilizer2Id,
              AF1_OtherFertilizer2Name: el.afterFertilizer1.otherFertilizer2Name,
              AF1_OtherFertilizer2Dosage: el.afterFertilizer1.otherFertilizer2Dosage,
              AF2_FertilizationMode: el.afterFertilizer2.fertilizationMode,
              AF2_FertilizationDate: this.helper.isEmpty(el.afterFertilizer2.fertilizationDate) ? null : this.$moment(el.afterFertilizer2.fertilizationDate).format('YYYY-MM-DD'),
              AF2_BusinessOrganicFertilizerDosage: el.afterFertilizer2.businessOrganicFertilizerDosage,
              AF2_UreaDosage: el.afterFertilizer2.ureaDosage,
              AF2_AmmoniumBicarbonateDosage: el.afterFertilizer2.ammoniumBicarbonateDosage,
              AF2_DiammoniumPhosphateDosage: el.afterFertilizer2.diammoniumPhosphateDosage,
              AF2_PotassiumChlorideDosage: el.afterFertilizer2.potassiumChlorideDosage,
              AF2_PotassiumSulphateDosage: el.afterFertilizer2.potassiumSulphateDosage,
              AF2_CompoundFertilizerDosage: el.afterFertilizer2.compoundFertilizerDosage,
              AF2_CompoundFertilizerN: el.afterFertilizer2.compoundFertilizerN,
              AF2_CompoundFertilizerP2O5: el.afterFertilizer2.compoundFertilizerP2O5,
              AF2_CompoundFertilizerK2O: el.afterFertilizer2.compoundFertilizerK2O,
              AF2_WaterSolubleFertilizerDosage: el.afterFertilizer2.waterSolubleFertilizerDosage,
              AF2_WaterSolubleFertilizerN: el.afterFertilizer2.waterSolubleFertilizerN,
              AF2_WaterSolubleFertilizerP2O5: el.afterFertilizer2.waterSolubleFertilizerP2O5,
              AF2_WaterSolubleFertilizerK2O: el.afterFertilizer2.waterSolubleFertilizerK2O,
              AF2_OtherFertilizer1Id: el.afterFertilizer2.otherFertilizer1Id,
              AF2_OtherFertilizer1Name: el.afterFertilizer2.otherFertilizer1Name,
              AF2_OtherFertilizer1Dosage: el.afterFertilizer2.otherFertilizer1Dosage,
              AF2_OtherFertilizer2Id: el.afterFertilizer2.otherFertilizer2Id,
              AF2_OtherFertilizer2Name: el.afterFertilizer2.otherFertilizer2Name,
              AF2_OtherFertilizer2Dosage: el.afterFertilizer2.otherFertilizer2Dosage,
              AF3_FertilizationMode: el.afterFertilizer3.fertilizationMode,
              AF3_FertilizationDate: this.helper.isEmpty(el.afterFertilizer3.fertilizationDate) ? null : this.$moment(el.afterFertilizer3.fertilizationDate).format('YYYY-MM-DD'),
              AF3_BusinessOrganicFertilizerDosage: el.afterFertilizer3.businessOrganicFertilizerDosage,
              AF3_UreaDosage: el.afterFertilizer3.ureaDosage,
              AF3_AmmoniumBicarbonateDosage: el.afterFertilizer3.ammoniumBicarbonateDosage,
              AF3_DiammoniumPhosphateDosage: el.afterFertilizer3.diammoniumPhosphateDosage,
              AF3_PotassiumChlorideDosage: el.afterFertilizer3.potassiumChlorideDosage,
              AF3_PotassiumSulphateDosage: el.afterFertilizer3.potassiumSulphateDosage,
              AF3_CompoundFertilizerDosage: el.afterFertilizer3.compoundFertilizerDosage,
              AF3_CompoundFertilizerN: el.afterFertilizer3.compoundFertilizerN,
              AF3_CompoundFertilizerP2O5: el.afterFertilizer3.compoundFertilizerP2O5,
              AF3_CompoundFertilizerK2O: el.afterFertilizer3.compoundFertilizerK2O,
              AF3_WaterSolubleFertilizerDosage: el.afterFertilizer3.waterSolubleFertilizerDosage,
              AF3_WaterSolubleFertilizerN: el.afterFertilizer3.waterSolubleFertilizerN,
              AF3_WaterSolubleFertilizerP2O5: el.afterFertilizer3.waterSolubleFertilizerP2O5,
              AF3_WaterSolubleFertilizerK2O: el.afterFertilizer3.waterSolubleFertilizerK2O,
              AF3_OtherFertilizer1Id: el.afterFertilizer3.otherFertilizer1Id,
              AF3_OtherFertilizer1Name: el.afterFertilizer3.otherFertilizer1Name,
              AF3_OtherFertilizer1Dosage: el.afterFertilizer3.otherFertilizer1Dosage,
              AF3_OtherFertilizer2Id: el.afterFertilizer3.otherFertilizer2Id,
              AF3_OtherFertilizer2Name: el.afterFertilizer3.otherFertilizer2Name,
              AF3_OtherFertilizer2Dosage: el.afterFertilizer3.otherFertilizer2Dosage,
              AF4_FertilizationMode: el.afterFertilizer4.fertilizationMode,
              AF4_FertilizationDate: this.helper.isEmpty(el.afterFertilizer4.fertilizationDate) ? null : this.$moment(el.afterFertilizer4.fertilizationDate).format('YYYY-MM-DD'),
              AF4_BusinessOrganicFertilizerDosage: el.afterFertilizer4.businessOrganicFertilizerDosage,
              AF4_UreaDosage: el.afterFertilizer4.ureaDosage,
              AF4_AmmoniumBicarbonateDosage: el.afterFertilizer4.ammoniumBicarbonateDosage,
              AF4_DiammoniumPhosphateDosage: el.afterFertilizer4.diammoniumPhosphateDosage,
              AF4_PotassiumChlorideDosage: el.afterFertilizer4.potassiumChlorideDosage,
              AF4_PotassiumSulphateDosage: el.afterFertilizer4.potassiumSulphateDosage,
              AF4_CompoundFertilizerDosage: el.afterFertilizer4.compoundFertilizerDosage,
              AF4_CompoundFertilizerN: el.afterFertilizer4.compoundFertilizerN,
              AF4_CompoundFertilizerP2O5: el.afterFertilizer4.compoundFertilizerP2O5,
              AF4_CompoundFertilizerK2O: el.afterFertilizer4.compoundFertilizerK2O,
              AF4_WaterSolubleFertilizerDosage: el.afterFertilizer4.waterSolubleFertilizerDosage,
              AF4_WaterSolubleFertilizerN: el.afterFertilizer4.waterSolubleFertilizerN,
              AF4_WaterSolubleFertilizerP2O5: el.afterFertilizer4.waterSolubleFertilizerP2O5,
              AF4_WaterSolubleFertilizerK2O: el.afterFertilizer4.waterSolubleFertilizerK2O,
              AF4_OtherFertilizer1Id: el.afterFertilizer4.otherFertilizer1Id,
              AF4_OtherFertilizer1Name: el.afterFertilizer4.otherFertilizer1Name,
              AF4_OtherFertilizer1Dosage: el.afterFertilizer4.otherFertilizer1Dosage,
              AF4_OtherFertilizer2Id: el.afterFertilizer4.otherFertilizer2Id,
              AF4_OtherFertilizer2Name: el.afterFertilizer4.otherFertilizer2Name,
              AF4_OtherFertilizer2Dosage: el.afterFertilizer4.otherFertilizer2Dosage,
              AF5_FertilizationMode: el.afterFertilizer5.fertilizationMode,
              AF5_FertilizationDate: this.helper.isEmpty(el.afterFertilizer5.fertilizationDate) ? null : this.$moment(el.afterFertilizer5.fertilizationDate).format('YYYY-MM-DD'),
              AF5_BusinessOrganicFertilizerDosage: el.afterFertilizer5.businessOrganicFertilizerDosage,
              AF5_UreaDosage: el.afterFertilizer5.ureaDosage,
              AF5_AmmoniumBicarbonateDosage: el.afterFertilizer5.ammoniumBicarbonateDosage,
              AF5_DiammoniumPhosphateDosage: el.afterFertilizer5.diammoniumPhosphateDosage,
              AF5_PotassiumChlorideDosage: el.afterFertilizer5.potassiumChlorideDosage,
              AF5_PotassiumSulphateDosage: el.afterFertilizer5.potassiumSulphateDosage,
              AF5_CompoundFertilizerDosage: el.afterFertilizer5.compoundFertilizerDosage,
              AF5_CompoundFertilizerN: el.afterFertilizer5.compoundFertilizerN,
              AF5_CompoundFertilizerP2O5: el.afterFertilizer5.compoundFertilizerP2O5,
              AF5_CompoundFertilizerK2O: el.afterFertilizer5.compoundFertilizerK2O,
              AF5_WaterSolubleFertilizerDosage: el.afterFertilizer5.waterSolubleFertilizerDosage,
              AF5_WaterSolubleFertilizerN: el.afterFertilizer5.waterSolubleFertilizerN,
              AF5_WaterSolubleFertilizerP2O5: el.afterFertilizer5.waterSolubleFertilizerP2O5,
              AF5_WaterSolubleFertilizerK2O: el.afterFertilizer5.waterSolubleFertilizerK2O,
              AF5_OtherFertilizer1Id: el.afterFertilizer5.otherFertilizer1Id,
              AF5_OtherFertilizer1Name: el.afterFertilizer5.otherFertilizer1Name,
              AF5_OtherFertilizer1Dosage: el.afterFertilizer5.otherFertilizer1Dosage,
              AF5_OtherFertilizer2Id: el.afterFertilizer5.otherFertilizer2Id,
              AF5_OtherFertilizer2Name: el.afterFertilizer5.otherFertilizer2Name,
              AF5_OtherFertilizer2Dosage: el.afterFertilizer5.otherFertilizer2Dosage
            }
            tableData.push(row)
          })
        }).catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        }).finally(() => {
          this.dataLoading = false
        })

        this.$refs.fillDataTable.reloadData(tableData)
      })
    },
    buildSaveModel(record) {
      let model = {}
      model.user = {
        id: record.UA_Id,
        fullName: record.UA_FullName,
        detailedAddress: record.UA_DetailedAddress,
        phoneNumber: record.UA_PhoneNumber,
        farmerNature: record.UA_FarmerNature,
        educationalLevel: record.UA_EducationalLevel,
        cultivationLandArea: record.UA_CultivationLandArea,
        modelHouseholdFlag: record.UA_ModelHouseholdFlag
      }

      // set monitoring plot model
      model.monitoringPlot = {
        id: record.MP_Id,
        name: record.MP_Name,
        code: record.MP_Code,
        area: record.MP_Area
      }

      // set plant crop model
      model.plantCrop = {
        id: record.PC_Id,
        year: record.PC_Year,
        cropId: record.PC_CropId,
        cropType: record.PC_CropType,
        sowingDate: this.helper.isEmpty(record.PC_SowingDate) ? null : record.PC_SowingDate + ' 00:00:00',
        harvestTime: this.helper.isEmpty(record.PC_HarvestTime) ? null : record.PC_HarvestTime + ' 00:00:00',
        sowingArea: record.PC_SowingArea,
        plotFertility: record.PC_PlotFertility,
        yield: record.PC_Yield,
        facilityCultivationFlag: record.PC_FacilityCultivationFlag,
        happenDisasterFlag: record.PC_HappenDisasterFlag,
        previousCropId: 'NONE' === record.PC_PreviousCropId ? null : record.PC_PreviousCropId,
        previousYield: record.PC_PreviousYield,
        previousStrawReturnRate: record.PC_PreviousStrawReturnRate,
        remark1: null,
        remark2: null,
        afterFertilizerFillInMode: record.PC_AfterFertilizerFillInMode
      }

      // set base fertilizer model
      model.baseFertilizer = {
        fertilizationMode: record.BF_FertilizationMode,
        fertilizationDate: this.helper.isEmpty(record.BF_FertilizationDate) ? null : record.BF_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.BF_BusinessOrganicFertilizerDosage,
        businessOrganicFertilizerPrice: record.BF_BusinessOrganicFertilizerPrice,
        organicFertilizer1Id: record.BF_OrganicFertilizer1Id,
        organicFertilizer1Dosage: record.BF_OrganicFertilizer1Dosage,
        organicFertilizer2Id: record.BF_OrganicFertilizer2Id,
        organicFertilizer2Dosage: record.BF_OrganicFertilizer2Dosage,
        ureaDosage: record.BF_UreaDosage,
        ammoniumBicarbonateDosage: record.BF_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.BF_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.BF_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.BF_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.BF_CompoundFertilizerDosage,
        compoundFertilizerN: record.BF_CompoundFertilizerN,
        compoundFertilizerP2O5: record.BF_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.BF_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.BF_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.BF_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.BF_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.BF_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.BF_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.BF_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.BF_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.BF_OtherFertilizer2Dosage
      }

      // set after fertilizer total model
      model.afterFertilizerTotal = {
        fertilizationMode: record.AFT_FertilizationMode,
        fertilizationTimes: record.AFT_FertilizationTimes,
        fertilizationDate: this.helper.isEmpty(record.AFT_FertilizationDate) ? null : record.AFT_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.AFT_BusinessOrganicFertilizerDosage,
        ureaDosage: record.AFT_UreaDosage,
        ammoniumBicarbonateDosage: record.AFT_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.AFT_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.AFT_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.AFT_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.AFT_CompoundFertilizerDosage,
        compoundFertilizerN: record.AFT_CompoundFertilizerN,
        compoundFertilizerP2O5: record.AFT_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.AFT_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.AFT_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.AFT_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.AFT_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.AFT_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.AFT_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.AFT_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.AFT_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.AFT_OtherFertilizer2Dosage
      }

      // set after fertilizer 1 model
      model.afterFertilizer1 = {
        fertilizationMode: record.AF1_FertilizationMode,
        fertilizationDate: this.helper.isEmpty(record.AF1_FertilizationDate) ? null : record.AF1_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.AF1_BusinessOrganicFertilizerDosage,
        ureaDosage: record.AF1_UreaDosage,
        ammoniumBicarbonateDosage: record.AF1_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.AF1_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.AF1_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.AF1_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.AF1_CompoundFertilizerDosage,
        compoundFertilizerN: record.AF1_CompoundFertilizerN,
        compoundFertilizerP2O5: record.AF1_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.AF1_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.AF1_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.AF1_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.AF1_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.AF1_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.AF1_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.AF1_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.AF1_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.AF1_OtherFertilizer2Dosage
      }

      // set after fertilizer 2 model
      model.afterFertilizer2 = {
        fertilizationMode: record.AF2_FertilizationMode,
        fertilizationDate: this.helper.isEmpty(record.AF2_FertilizationDate) ? null : record.AF2_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.AF2_BusinessOrganicFertilizerDosage,
        ureaDosage: record.AF2_UreaDosage,
        ammoniumBicarbonateDosage: record.AF2_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.AF2_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.AF2_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.AF2_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.AF2_CompoundFertilizerDosage,
        compoundFertilizerN: record.AF2_CompoundFertilizerN,
        compoundFertilizerP2O5: record.AF2_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.AF2_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.AF2_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.AF2_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.AF2_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.AF2_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.AF2_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.AF2_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.AF2_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.AF2_OtherFertilizer2Dosage
      }

      // set after fertilizer 3 model
      model.afterFertilizer3 = {
        fertilizationMode: record.AF3_FertilizationMode,
        fertilizationDate: this.helper.isEmpty(record.AF3_FertilizationDate) ? null : record.AF3_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.AF3_BusinessOrganicFertilizerDosage,
        ureaDosage: record.AF3_UreaDosage,
        ammoniumBicarbonateDosage: record.AF3_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.AF3_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.AF3_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.AF3_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.AF3_CompoundFertilizerDosage,
        compoundFertilizerN: record.AF3_CompoundFertilizerN,
        compoundFertilizerP2O5: record.AF3_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.AF3_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.AF3_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.AF3_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.AF3_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.AF3_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.AF3_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.AF3_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.AF3_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.AF3_OtherFertilizer2Dosage
      }

      // set after fertilizer 4 model
      model.afterFertilizer4 = {
        fertilizationMode: record.AF4_FertilizationMode,
        fertilizationDate: this.helper.isEmpty(record.AF4_FertilizationDate) ? null : record.AF4_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.AF4_BusinessOrganicFertilizerDosage,
        ureaDosage: record.AF4_UreaDosage,
        ammoniumBicarbonateDosage: record.AF4_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.AF4_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.AF4_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.AF4_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.AF4_CompoundFertilizerDosage,
        compoundFertilizerN: record.AF4_CompoundFertilizerN,
        compoundFertilizerP2O5: record.AF4_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.AF4_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.AF4_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.AF4_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.AF4_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.AF4_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.AF4_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.AF4_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.AF4_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.AF4_OtherFertilizer2Dosage
      }

      // set after fertilizer 5 model
      model.afterFertilizer5 = {
        fertilizationMode: record.AF5_FertilizationMode,
        fertilizationDate: this.helper.isEmpty(record.AF5_FertilizationDate) ? null : record.AF5_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.AF5_BusinessOrganicFertilizerDosage,
        ureaDosage: record.AF5_UreaDosage,
        ammoniumBicarbonateDosage: record.AF5_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.AF5_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.AF5_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.AF5_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.AF5_CompoundFertilizerDosage,
        compoundFertilizerN: record.AF5_CompoundFertilizerN,
        compoundFertilizerP2O5: record.AF5_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.AF5_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.AF5_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.AF5_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.AF5_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.AF5_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.AF5_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.AF5_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.AF5_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.AF5_OtherFertilizer2Dosage
      }

      return model
    }
  }
}
</script>

<style>
.valid-error-list {
  margin: 0;
  padding: 0;
}

.valid-error-list li {
  line-height: 24px;
}
</style>
