<template>
  <div class="wg-box">
    <div class="header" v-if="!fullPlantCropModel.plantCrop.submitFlag">
      <div class="button-list">
        <el-button size="small" class="wg-button red" icon="el-icon-delete" @click="handleDelete">删除</el-button>
      </div>
      <div class="wg-clear-fix"></div>
    </div>
    <div class="body">
      <el-form ref="baseFertilizerTableForm" :model="tableForm">
        <div class="wg-table-box">
          <el-table :data="tableForm.tableData" class="wg-table normal" border :max-height="180" style="width: 100%">
            <el-table-column label="序号" type="index" width="100" fixed></el-table-column>
            <el-table-column prop="fertilizationModeName" label="施肥方式" width="120" fixed>
              <template slot-scope="scope">
                <div v-if="!fullPlantCropModel.plantCrop.submitFlag">
                  <el-form-item :prop="'tableData.' + scope.$index + '.fertilizationMode'" :rules="tableForm.formRules.fertilizationMode">
                    <el-select size="small" v-model="scope.row.fertilizationMode" placeholder="--" clearable @clear="handleRowValueClear('fertilizationMode', scope.row)" @change="handleRowValueChange($event, 'fertilizationMode', scope.row)">
                      <el-option v-for="option in tableForm.filterData.fertilizationModeOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div v-else>{{ scope.row.fertilizationModeName }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="fertilizationDate" label="施肥日期" width="150">
              <template slot-scope="scope">
                <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                  <el-form-item :prop="'tableData.' + scope.$index + '.fertilizationDate'" :rules="tableForm.formRules.fertilizationDate">
                    <el-date-picker size="small" v-model="scope.row.fertilizationDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
                  </el-form-item>
                </div>
                <div v-else>{{ scope.row.fertilizationDate }}</div>
              </template>
            </el-table-column>
            <el-table-column label="商用有机肥">
              <el-table-column prop="businessOrganicFertilizerDosage" label="用量" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.businessOrganicFertilizerDosage'" :rules="tableForm.formRules.businessOrganicFertilizerDosage">
                      <el-input size="small" v-model="scope.row.businessOrganicFertilizerDosage" @input="handleRowValueChange($event, 'businessOrganicFertilizerDosage', scope.row)"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.businessOrganicFertilizerDosage }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="businessOrganicFertilizerPrice" label="价格（元/吨）" width="120">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.businessOrganicFertilizerDosage && '' !== scope.row.businessOrganicFertilizerDosage">
                    <el-form-item :prop="'tableData.' + scope.$index + '.businessOrganicFertilizerPrice'" :rules="tableForm.formRules.businessOrganicFertilizerPrice">
                      <el-input size="small" v-model="scope.row.businessOrganicFertilizerPrice"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.businessOrganicFertilizerPrice }}</div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="农家肥1（干重）">
              <el-table-column prop="organicFertilizer1Name" label="名称" width="120">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.organicFertilizer1Id'" :rules="tableForm.formRules.organicFertilizer1Id">
                      <el-select size="small" v-model="scope.row.organicFertilizer1Id" clearable @clear="handleRowValueClear('organicFertilizer1Id', scope.row)" @change="handleRowValueChange($event, 'organicFertilizer1Id', scope.row)">
                        <el-option v-for="option in tableForm.filterData.organicFertilizerOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.organicFertilizer1Name }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="organicFertilizer1Dosage" label="用量" width="120">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.organicFertilizer1Id">
                    <el-form-item :prop="'tableData.' + scope.$index + '.organicFertilizer1Dosage'" :rules="tableForm.formRules.organicFertilizer1Dosage">
                      <el-input size="small" v-model="scope.row.organicFertilizer1Dosage"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.organicFertilizer1Dosage }}</div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="农家肥2（干重）">
              <el-table-column prop="organicFertilizer2Name" label="名称" width="120">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.organicFertilizer2Id'" :rules="tableForm.formRules.organicFertilizer2Id">
                      <el-select size="small" v-model="scope.row.organicFertilizer2Id" clearable @clear="handleRowValueClear('organicFertilizer2Id', scope.row)" @change="handleRowValueChange($event, 'organicFertilizer2Id', scope.row)">
                        <el-option v-for="option in tableForm.filterData.organicFertilizerOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.organicFertilizer2Name }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="organicFertilizer2Dosage" label="用量" width="120">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.organicFertilizer2Id">
                    <el-form-item :prop="'tableData.' + scope.$index + '.organicFertilizer2Dosage'" :rules="tableForm.formRules.organicFertilizer2Dosage">
                      <el-input size="small" v-model="scope.row.organicFertilizer2Dosage"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.organicFertilizer2Dosage }}</div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="urea" label="尿素" width="100">
              <template slot-scope="scope">
                <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                  <el-form-item :prop="'tableData.' + scope.$index + '.urea'" :rules="tableForm.formRules.urea">
                    <el-input size="small" v-model="scope.row.urea"></el-input>
                  </el-form-item>
                </div>
                <div v-else>{{ scope.row.urea }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="ammoniumBicarbonate" label="碳铵" width="100">
              <template slot-scope="scope">
                <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                  <el-form-item :prop="'tableData.' + scope.$index + '.ammoniumBicarbonate'" :rules="tableForm.formRules.ammoniumBicarbonate">
                    <el-input size="small" v-model="scope.row.ammoniumBicarbonate"></el-input>
                  </el-form-item>
                </div>
                <div v-else>{{ scope.row.ammoniumBicarbonate }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="diammoniumPhosphate" label="磷酸二铵" width="100">
              <template slot-scope="scope">
                <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                  <el-form-item :prop="'tableData.' + scope.$index + '.diammoniumPhosphate'" :rules="tableForm.formRules.diammoniumPhosphate">
                    <el-input size="small" v-model="scope.row.diammoniumPhosphate"></el-input>
                  </el-form-item>
                </div>
                <div v-else>{{ scope.row.diammoniumPhosphate }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="potassiumChloride" label="氯化钾" width="100">
              <template slot-scope="scope">
                <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                  <el-form-item :prop="'tableData.' + scope.$index + '.potassiumChloride'" :rules="tableForm.formRules.potassiumChloride">
                    <el-input size="small" v-model="scope.row.potassiumChloride"></el-input>
                  </el-form-item>
                </div>
                <div v-else>{{ scope.row.potassiumChloride }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="potassiumSulphate" label="硫酸钾" width="100">
              <template slot-scope="scope">
                <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                  <el-form-item :prop="'tableData.' + scope.$index + '.potassiumSulphate'" :rules="tableForm.formRules.potassiumSulphate">
                    <el-input size="small" v-model="scope.row.potassiumSulphate"></el-input>
                  </el-form-item>
                </div>
                <div v-else>{{ scope.row.potassiumSulphate }}</div>
              </template>
            </el-table-column>
            <el-table-column label="复合肥（含配方肥）及养分%">
              <el-table-column prop="compoundFertilizerDosage" label="用量" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.compoundFertilizerDosage'" :rules="tableForm.formRules.compoundFertilizerDosage">
                      <el-input size="small" v-model="scope.row.compoundFertilizerDosage" @input="handleRowValueChange($event, 'compoundFertilizerDosage', scope.row)"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.compoundFertilizerDosage }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="compoundFertilizerN" label="N" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.compoundFertilizerDosage && '' !== scope.row.compoundFertilizerDosage">
                    <el-form-item :prop="'tableData.' + scope.$index + '.compoundFertilizerN'" :rules="tableForm.formRules.compoundFertilizerN">
                      <el-input size="small" v-model="scope.row.compoundFertilizerN"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.compoundFertilizerN }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="compoundFertilizerP2O5" label="P2O5" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.compoundFertilizerDosage && '' !== scope.row.compoundFertilizerDosage">
                    <el-form-item :prop="'tableData.' + scope.$index + '.compoundFertilizerP2O5'" :rules="tableForm.formRules.compoundFertilizerP2O5">
                      <el-input size="small" v-model="scope.row.compoundFertilizerP2O5"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.compoundFertilizerP2O5 }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="compoundFertilizerK2O" label="K2O" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.compoundFertilizerDosage && '' !== scope.row.compoundFertilizerDosage">
                    <el-form-item :prop="'tableData.' + scope.$index + '.compoundFertilizerK2O'" :rules="tableForm.formRules.compoundFertilizerK2O">
                      <el-input size="small" v-model="scope.row.compoundFertilizerK2O"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.compoundFertilizerK2O }}</div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="水溶肥及养分%">
              <el-table-column prop="waterSolubleFertilizerDosage" label="用量" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.waterSolubleFertilizerDosage'" :rules="tableForm.formRules.waterSolubleFertilizerDosage">
                      <el-input size="small" v-model="scope.row.waterSolubleFertilizerDosage" @input="handleRowValueChange($event, 'waterSolubleFertilizerDosage', scope.row)"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.waterSolubleFertilizerDosage }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="waterSolubleFertilizerN" label="N" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.waterSolubleFertilizerDosage && '' !== scope.row.waterSolubleFertilizerDosage">
                    <el-form-item :prop="'tableData.' + scope.$index + '.waterSolubleFertilizerN'" :rules="tableForm.formRules.waterSolubleFertilizerN">
                      <el-input size="small" v-model="scope.row.waterSolubleFertilizerN"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.waterSolubleFertilizerN }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="waterSolubleFertilizerP2O5" label="P2O5" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.waterSolubleFertilizerDosage && '' !== scope.row.waterSolubleFertilizerDosage">
                    <el-form-item :prop="'tableData.' + scope.$index + '.waterSolubleFertilizerP2O5'" :rules="tableForm.formRules.waterSolubleFertilizerP2O5">
                      <el-input size="small" v-model="scope.row.waterSolubleFertilizerP2O5"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.waterSolubleFertilizerP2O5 }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="waterSolubleFertilizerK2O" label="K2O" width="100">
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.waterSolubleFertilizerDosage && '' !== scope.row.waterSolubleFertilizerDosage">
                    <el-form-item :prop="'tableData.' + scope.$index + '.waterSolubleFertilizerK2O'" :rules="tableForm.formRules.waterSolubleFertilizerK2O">
                      <el-input size="small" v-model="scope.row.waterSolubleFertilizerK2O"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.waterSolubleFertilizerK2O }}</div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="其他肥料1">
              <el-table-column prop="otherFertilizer1Name" width="120">
                <template slot="header">名称</template>
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.otherFertilizer1Id'" :rules="tableForm.formRules.otherFertilizer1Id">
                      <el-select size="small" v-model="scope.row.otherFertilizer1Id" clearable @clear="handleRowValueClear('otherFertilizer1Id', scope.row)" @change="handleRowValueChange($event, 'otherFertilizer1Id', scope.row)">
                        <el-option v-for="option in tableForm.filterData.otherFertilizerOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.otherFertilizer1Name }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="otherFertilizer1Dosage" width="120">
                <template slot="header">用量</template>
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.otherFertilizer1Id">
                    <el-form-item :prop="'tableData.' + scope.$index + '.otherFertilizer1Dosage'" :rules="tableForm.formRules.otherFertilizer1Dosage">
                      <el-input size="small" v-model="scope.row.otherFertilizer1Dosage"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.otherFertilizer1Dosage }}</div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="其他肥料2">
              <el-table-column prop="otherFertilizer2Name" width="120">
                <template slot="header">名称</template>
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.fertilizationMode">
                    <el-form-item :prop="'tableData.' + scope.$index + '.otherFertilizer2Id'" :rules="tableForm.formRules.otherFertilizer2Id">
                      <el-select size="small" v-model="scope.row.otherFertilizer2Id" clearable @clear="handleRowValueClear('otherFertilizer2Id', scope.row)" @change="handleRowValueChange($event, 'otherFertilizer2Id', scope.row)">
                        <el-option v-for="option in tableForm.filterData.otherFertilizerOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.otherFertilizer2Name }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="otherFertilizer2Dosage" width="120">
                <template slot="header">用量</template>
                <template slot-scope="scope">
                  <div v-if="!fullPlantCropModel.plantCrop.submitFlag && null !== scope.row.otherFertilizer2Id">
                    <el-form-item :prop="'tableData.' + scope.$index + '.otherFertilizer2Dosage'" :rules="tableForm.formRules.otherFertilizer2Dosage">
                      <el-input size="small" v-model="scope.row.otherFertilizer2Dosage"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.otherFertilizer2Dosage }}</div>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import ChemicalFertilizerApi from '@/api/chemical-fertilizer-api'
import OrganicFertilizerApi from '@/api/organic-fertilizer-api'
import BaseFertilizerApi from '@/api/base-fertilizer-api'

export default {
  name: 'BaseFertilizer',
  props: {
    fullPlantCropModel: { type: Object, default: null }
  },
  data() {
    const validateFertilizationMode = (rule, value, callback) => {
      console.log(rule)
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let fertilizationMode = this.tableForm.tableData[rowIndex].fertilizationMode
      if (null === fertilizationMode) {
        callback()
      } else {
        let row = this.tableForm.tableData[rowIndex]
        if (this.$utils.isEmpty(row.businessOrganicFertilizerDosage) && null === row.organicFertilizer1Id && null === row.organicFertilizer2Id && this.$utils.isEmpty(row.urea) && this.$utils.isEmpty(row.ammoniumBicarbonate) && this.$utils.isEmpty(row.diammoniumPhosphate) && this.$utils.isEmpty(row.potassiumChloride) && this.$utils.isEmpty(row.potassiumSulphate) && this.$utils.isEmpty(row.compoundFertilizerDosage) && this.$utils.isEmpty(row.waterSolubleFertilizerDosage) && null === row.otherFertilizer1Id && null === row.otherFertilizer2Id) {
          callback(new Error())
        } else {
          callback()
        }
      }
    }

    const validateNumber = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^([0-9]\d*(\.\d*)?)$|^0\.\d*[1-9]\d*$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error())
        }
      } else {
        callback()
      }
    }

    const validateCompoundFertilizerDosage = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let compoundFertilizerN = this.tableForm.tableData[rowIndex].compoundFertilizerN
      let compoundFertilizerP2O5 = this.tableForm.tableData[rowIndex].compoundFertilizerP2O5
      let compoundFertilizerK2O = this.tableForm.tableData[rowIndex].compoundFertilizerK2O
      if (this.$utils.isNotEmpty(compoundFertilizerN) && this.$utils.isNotEmpty(compoundFertilizerP2O5) && this.$utils.isNotEmpty(compoundFertilizerK2O)) {
        let total = Number(compoundFertilizerN) + Number(compoundFertilizerP2O5) + Number(compoundFertilizerK2O)
        if (0 === total) {
          callback(new Error())
        } else if (total > 100) {
          callback(new Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    const validateWaterSolubleFertilizerDosage = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let waterSolubleFertilizerN = this.tableForm.tableData[rowIndex].waterSolubleFertilizerN
      let waterSolubleFertilizerP2O5 = this.tableForm.tableData[rowIndex].waterSolubleFertilizerP2O5
      let waterSolubleFertilizerK2O = this.tableForm.tableData[rowIndex].waterSolubleFertilizerK2O
      if (this.$utils.isNotEmpty(waterSolubleFertilizerN) && this.$utils.isNotEmpty(waterSolubleFertilizerP2O5) && this.$utils.isNotEmpty(waterSolubleFertilizerK2O)) {
        let total = Number(waterSolubleFertilizerN) + Number(waterSolubleFertilizerP2O5) + Number(waterSolubleFertilizerK2O)
        if (0 === total) {
          callback(new Error())
        } else if (total > 100) {
          callback(new Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    return {
      tableForm: {
        tableData: [],
        filterData: {
          organicFertilizerOptionList: [],
          otherFertilizerOptionList: [],
          fertilizationModeOptionList: []
        },
        formRules: {
          fertilizationDate: [],
          businessOrganicFertilizerDosage: [
            { trigger: 'blur', validator: validateFertilizationMode },
            { trigger: 'blur', validator: validateNumber }
          ],
          businessOrganicFertilizerPrice: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber }
          ],
          organicFertilizer1Id: [{ trigger: 'change', validator: validateFertilizationMode }],
          organicFertilizer1Dosage: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber }
          ],
          organicFertilizer2Id: [{ trigger: 'change', validator: validateFertilizationMode }],
          organicFertilizer2Dosage: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber }
          ],
          urea: [
            { trigger: 'blur', validator: validateFertilizationMode },
            { trigger: 'blur', validator: validateNumber }
          ],
          ammoniumBicarbonate: [
            { trigger: 'blur', validator: validateFertilizationMode },
            { trigger: 'blur', validator: validateNumber }
          ],
          diammoniumPhosphate: [
            { trigger: 'blur', validator: validateFertilizationMode },
            { trigger: 'blur', validator: validateNumber }
          ],
          potassiumChloride: [
            { trigger: 'blur', validator: validateFertilizationMode },
            { trigger: 'blur', validator: validateNumber }
          ],
          potassiumSulphate: [
            { trigger: 'blur', validator: validateFertilizationMode },
            { trigger: 'blur', validator: validateNumber }
          ],
          compoundFertilizerDosage: [
            { trigger: 'blur', validator: validateFertilizationMode },
            { trigger: 'blur', validator: validateNumber }
          ],
          compoundFertilizerN: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber },
            { trigger: 'blur', validator: validateCompoundFertilizerDosage }
          ],
          compoundFertilizerP2O5: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber },
            { trigger: 'blur', validator: validateCompoundFertilizerDosage }
          ],
          compoundFertilizerK2O: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber },
            { trigger: 'blur', validator: validateCompoundFertilizerDosage }
          ],
          waterSolubleFertilizerDosage: [
            { trigger: 'blur', validator: validateFertilizationMode },
            { trigger: 'blur', validator: validateNumber }
          ],
          waterSolubleFertilizerN: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber },
            { trigger: 'blur', validator: validateWaterSolubleFertilizerDosage }
          ],
          waterSolubleFertilizerP2O5: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber },
            { trigger: 'blur', validator: validateWaterSolubleFertilizerDosage }
          ],
          waterSolubleFertilizerK2O: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber },
            { trigger: 'blur', validator: validateWaterSolubleFertilizerDosage }
          ],
          otherFertilizer1Id: [{ trigger: 'change', validator: validateFertilizationMode }],
          otherFertilizer1Dosage: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber }
          ],
          otherFertilizer2Id: [{ trigger: 'change', validator: validateFertilizationMode }],
          otherFertilizer2Dosage: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber }
          ],
          fertilizationMode: []
        }
      }
    }
  },
  created() {
    // organicFertilizerOptionList
    OrganicFertilizerApi.getStubList().then(res => {
      res.data.forEach(el => {
        this.tableForm.filterData.organicFertilizerOptionList.push({ text: el.name, value: el.id })
      })
    })
    // otherFertilizerOptionList
    ChemicalFertilizerApi.getStubList().then(res => {
      res.data.forEach(el => {
        this.tableForm.filterData.otherFertilizerOptionList.push({ text: el.name, value: el.id })
      })
    })
    // fertilizationModeOptionList
    this.tableForm.filterData.fertilizationModeOptionList.push({ text: '撒施', value: 'MODE1' })
    this.tableForm.filterData.fertilizationModeOptionList.push({ text: '机械深施', value: 'MODE2' })
    this.tableForm.filterData.fertilizationModeOptionList.push({ text: '种肥同播', value: 'MODE3' })
    this.tableForm.filterData.fertilizationModeOptionList.push({ text: '条施', value: 'MODE4' })
    this.tableForm.filterData.fertilizationModeOptionList.push({ text: '穴施', value: 'MODE5' })
    this.tableForm.filterData.fertilizationModeOptionList.push({ text: '无人机施肥', value: 'MODE7' })
    this.tableForm.filterData.fertilizationModeOptionList.push({ text: '其他', value: 'MODE6' })
    // init
    this.tableForm.tableData = []
    this.tableForm.tableData.push({
      id: null,
      fertilizationDate: null,
      businessOrganicFertilizerDosage: null,
      businessOrganicFertilizerPrice: null,
      organicFertilizer1Id: null,
      organicFertilizer1Name: null,
      organicFertilizer1Dosage: null,
      organicFertilizer2Id: null,
      organicFertilizer2Name: null,
      organicFertilizer2Dosage: null,
      urea: null,
      ammoniumBicarbonate: null,
      diammoniumPhosphate: null,
      potassiumChloride: null,
      potassiumSulphate: null,
      compoundFertilizerDosage: null,
      compoundFertilizerN: null,
      compoundFertilizerP2O5: null,
      compoundFertilizerK2O: null,
      waterSolubleFertilizerDosage: null,
      waterSolubleFertilizerN: null,
      waterSolubleFertilizerP2O5: null,
      waterSolubleFertilizerK2O: null,
      otherFertilizer1Id: null,
      otherFertilizer1Name: null,
      otherFertilizer1Dosage: null,
      otherFertilizer2Id: null,
      otherFertilizer2Name: null,
      otherFertilizer2Dosage: null,
      fertilizationMode: null,
      fertilizationModeName: null
    })
    this.loadData()
  },
  methods: {
    handleDelete() {
      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let row = this.tableForm.tableData[0]
          if (this.$utils.isNotEmpty(row.id)) {
            await BaseFertilizerApi.deleteById(row.id)
              .then(() => {
                this.$message({ showClose: true, message: '记录删除成功！', type: 'success' })
                this.$emit('delete-success')
              })
              .catch(() => {
                this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
              })
          }
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    handleRowValueClear(field, row) {
      if ('fertilizationMode' === field) {
        row.fertilizationMode = null
        row.fertilizationDate = null
        row.businessOrganicFertilizerDosage = null
        row.businessOrganicFertilizerPrice = null
        row.organicFertilizer1Id = null
        row.organicFertilizer1Dosage = null
        row.organicFertilizer2Id = null
        row.organicFertilizer2Dosage = null
        row.urea = null
        row.ammoniumBicarbonate = null
        row.diammoniumPhosphate = null
        row.potassiumChloride = null
        row.potassiumSulphate = null
        row.compoundFertilizerDosage = null
        row.compoundFertilizerN = null
        row.compoundFertilizerP2O5 = null
        row.compoundFertilizerK2O = null
        row.waterSolubleFertilizerDosage = null
        row.waterSolubleFertilizerN = null
        row.waterSolubleFertilizerP2O5 = null
        row.waterSolubleFertilizerK2O = null
        row.otherFertilizer1Id = null
        row.otherFertilizer1Dosage = null
        row.otherFertilizer2Id = null
        row.otherFertilizer2Dosage = null
      }
      if ('organicFertilizer1Id' === field) {
        row.organicFertilizer1Id = null
        row.organicFertilizer1Dosage = null
      }
      if ('organicFertilizer2Id' === field) {
        row.organicFertilizer2Id = null
        row.organicFertilizer2Dosage = null
      }
      if ('otherFertilizer1Id' === field) {
        row.otherFertilizer1Id = null
        row.otherFertilizer1Dosage = null
      }
      if ('otherFertilizer2Id' === field) {
        row.otherFertilizer2Id = null
        row.otherFertilizer2Dosage = null
      }
    },
    handleRowValueChange(value, field, row) {
      if ('businessOrganicFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.businessOrganicFertilizerDosage = null
        row.businessOrganicFertilizerPrice = null
      }
      if ('compoundFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.compoundFertilizerDosage = null
        row.compoundFertilizerN = null
        row.compoundFertilizerP2O5 = null
        row.compoundFertilizerK2O = null
      }
      if ('waterSolubleFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.waterSolubleFertilizerDosage = null
        row.waterSolubleFertilizerN = null
        row.waterSolubleFertilizerP2O5 = null
        row.waterSolubleFertilizerK2O = null
      }
    },
    loadData() {
      let row = this.tableForm.tableData[0]
      if (null !== this.fullPlantCropModel.baseFertilizer) {
        let baseFertilizerModel = this.fullPlantCropModel.baseFertilizer
        row.id = baseFertilizerModel.id
        row.fertilizationDate = this.$utils.isEmpty(baseFertilizerModel.fertilizationDate) ? null : this.$moment(baseFertilizerModel.fertilizationDate).format('YYYY-MM-DD')
        row.businessOrganicFertilizerDosage = baseFertilizerModel.businessOrganicFertilizerDosage
        row.businessOrganicFertilizerPrice = baseFertilizerModel.businessOrganicFertilizerPrice
        row.organicFertilizer1Id = baseFertilizerModel.organicFertilizer1Id
        row.organicFertilizer1Name = baseFertilizerModel.organicFertilizer1Name
        row.organicFertilizer1Dosage = baseFertilizerModel.organicFertilizer1Dosage
        row.organicFertilizer2Id = baseFertilizerModel.organicFertilizer2Id
        row.organicFertilizer2Name = baseFertilizerModel.organicFertilizer2Name
        row.organicFertilizer2Dosage = baseFertilizerModel.organicFertilizer2Dosage
        row.urea = baseFertilizerModel.urea
        row.ammoniumBicarbonate = baseFertilizerModel.ammoniumBicarbonate
        row.diammoniumPhosphate = baseFertilizerModel.diammoniumPhosphate
        row.potassiumChloride = baseFertilizerModel.potassiumChloride
        row.potassiumSulphate = baseFertilizerModel.potassiumSulphate
        row.compoundFertilizerDosage = baseFertilizerModel.compoundFertilizerDosage
        row.compoundFertilizerN = baseFertilizerModel.compoundFertilizerN
        row.compoundFertilizerP2O5 = baseFertilizerModel.compoundFertilizerP2O5
        row.compoundFertilizerK2O = baseFertilizerModel.compoundFertilizerK2O
        row.waterSolubleFertilizerDosage = baseFertilizerModel.waterSolubleFertilizerDosage
        row.waterSolubleFertilizerN = baseFertilizerModel.waterSolubleFertilizerN
        row.waterSolubleFertilizerP2O5 = baseFertilizerModel.waterSolubleFertilizerP2O5
        row.waterSolubleFertilizerK2O = baseFertilizerModel.waterSolubleFertilizerK2O
        row.otherFertilizer1Id = baseFertilizerModel.otherFertilizer1Id
        row.otherFertilizer1Name = baseFertilizerModel.otherFertilizer1Name
        row.otherFertilizer1Dosage = baseFertilizerModel.otherFertilizer1Dosage
        row.otherFertilizer2Id = baseFertilizerModel.otherFertilizer2Id
        row.otherFertilizer2Name = baseFertilizerModel.otherFertilizer2Name
        row.otherFertilizer2Dosage = baseFertilizerModel.otherFertilizer2Dosage
        row.fertilizationMode = baseFertilizerModel.fertilizationMode
        row.fertilizationModeName = baseFertilizerModel.fertilizationModeName
      } else {
        let properties = Object.getOwnPropertyNames(row)
        properties.forEach(key => {
          row[key] = null
        })
      }
    },
    validateForm() {
      let result = true
      this.$refs.baseFertilizerTableForm.validate(valid => {
        result = valid
      })

      return result
    },
    getBaseFertilizerModel() {
      let row = this.tableForm.tableData[0]
      if (null === row.fertilizationMode) {
        return null
      }

      let model = {
        id: row.id,
        plantCropId: this.fullPlantCropModel.plantCrop.id,
        fertilizationDate: null === row.fertilizationDate ? null : this.$moment(row.fertilizationDate).format('YYYY-MM-DD') + ' 00:00:00',
        businessOrganicFertilizerDosage: row.businessOrganicFertilizerDosage,
        businessOrganicFertilizerPrice: row.businessOrganicFertilizerPrice,
        organicFertilizer1Id: row.organicFertilizer1Id,
        organicFertilizer1Dosage: row.organicFertilizer1Dosage,
        organicFertilizer2Id: row.organicFertilizer2Id,
        organicFertilizer2Dosage: row.organicFertilizer2Dosage,
        urea: row.urea,
        ammoniumBicarbonate: row.ammoniumBicarbonate,
        diammoniumPhosphate: row.diammoniumPhosphate,
        potassiumChloride: row.potassiumChloride,
        potassiumSulphate: row.potassiumSulphate,
        compoundFertilizerDosage: row.compoundFertilizerDosage,
        compoundFertilizerN: row.compoundFertilizerN,
        compoundFertilizerP2O5: row.compoundFertilizerP2O5,
        compoundFertilizerK2O: row.compoundFertilizerK2O,
        waterSolubleFertilizerDosage: row.waterSolubleFertilizerDosage,
        waterSolubleFertilizerN: row.waterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: row.waterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: row.waterSolubleFertilizerK2O,
        otherFertilizer1Id: row.otherFertilizer1Id,
        otherFertilizer1Dosage: row.otherFertilizer1Dosage,
        otherFertilizer2Id: row.otherFertilizer2Id,
        otherFertilizer2Dosage: row.otherFertilizer2Dosage,
        fertilizationMode: row.fertilizationMode
      }

      return model
    }
  }
}
</script>
<style scoped></style>
