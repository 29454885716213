<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/" class="bread_crumb">
        <el-breadcrumb-item :to="{ path: '/' }">农事管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/soil' }">土壤管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <div class="wg-box">
      <div class="header">
        <div class="button-list">
          <el-button size="small" class="wg-button green" icon="el-icon-plus" @click="handleAdd">新增</el-button>
          <el-button size="small" class="wg-button red" icon="el-icon-delete" @click="handleDeleteSelected">删除</el-button>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <div class="body">
        <div class="wg-table-box">
          <el-table :data="tableData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)" @selection-change="handleSelectionChange" class="wg-table normal">
            <el-table-column type="selection" width="50"> </el-table-column>
            <el-table-column prop="sortNumber" label="序号" width="80" sortable></el-table-column>
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column prop="createdOn" label="创建时间" width="180" sortable></el-table-column>
            <el-table-column prop="modifiedOn" label="修改时间" width="180" sortable></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleEdit(scope.row.id)">编辑</el-button>
                <el-button type="text" size="small" @click="handleDelete(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="wg-pagination-box">
          <el-pagination background layout="prev, pager, next" :total="recordTotal" :page-size="pageSize" :current-page="pageIndex" @current-change="handlePagingChange"></el-pagination>
        </div>
      </div>
    </div>
    <!-- table data -->
    <create-window ref="createWindow" v-if="isLoadCreateWindow" @reload="handleReloadCreateWindow"></create-window>
    <modify-window ref="modifyWindow" v-if="isLoadModifyWindow" @reload="handleReloadModifyWindow"></modify-window>
  </div>
</template>

<script>
import SoilApi from '@/api/soil-api'

import SoilCreate from '@/views/soil/SoilCreate'
import SoilModify from '@/views/soil/SoilModify'

export default {
  name: 'SoilManage',
  data() {
    return {
      isLoadCreateWindow: true,
      isLoadModifyWindow: true,
      tableData: [],
      recordTotal: 0,
      pageSize: 10,
      pageIndex: 1,
      selectedRows: []
    }
  },
  components: {
    'create-window': SoilCreate,
    'modify-window': SoilModify
  },

  created() {
    this.bindTableData()
  },
  methods: {
    handleReloadCreateWindow() {
      this.isLoadCreateWindow = false
      this.$nextTick(() => {
        this.isLoadCreateWindow = true
      })
      this.bindTableData()
    },
    handleReloadModifyWindow() {
      this.isLoadModifyWindow = false
      this.$nextTick(() => {
        this.isLoadModifyWindow = true
      })
      this.bindTableData()
    },
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows
    },
    handleAdd() {
      this.$refs.createWindow.open()
    },
    handleDeleteSelected() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
        .then(() => {
          this.delete()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },

    handleEdit(id) {
      this.$refs.modifyWindow.open(id)
    },
    handleDelete(id) {
      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
        .then(() => {
          SoilApi.deleteById(id)
            .then(() => {
              this.$message({ type: 'success', message: '删除成功！' })
              this.bindTableData()
            })
            .catch(err => {
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    bindTableData() {
      SoilApi.getList().then(res => {
        this.tableData = []
        this.recordTotal = res.data.length
        this.pageIndex = 1
        res.data.forEach(el => {
          this.tableData.push({
            id: el.id,
            sortNumber: el.sortNumber,
            name: el.name,
            createdOn: el.createdOn,
            modifiedOn: el.modifiedOn
          })
        })
      })
    },
    async delete() {
      let theObj = this
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading' })
      for (let i = 0; i < this.selectedRows.length; i++) {
        await SoilApi.deleteById(this.selectedRows[i].id)
          .then(() => {
            this.$message({ showClose: true, message: theObj.selectedRows[i].name + '删除成功！', type: 'success' })
          })
          .catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          })
      }
      loading.close()
      this.bindTableData()
    }
  }
}
</script>

<style scoped>
</style>
