<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/" class="bread_crumb">
        <el-breadcrumb-item :to="{ path: '/' }">图表分析</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/data-visualization' }">数据可视化</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <div class="wg-box no-header">
      <div class="body">
        <el-form :inline="true" :model="searchForm" size="small" label-width="100px">
          <el-form-item label="选择省份：" v-if="flags.provinceSelectShow">
            <el-select v-model="filterData.provinceId" @change="handleProvinceSelect">
              <el-option v-for="option in filterData.provinceOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择地市：" v-if="flags.citySelectShow">
            <el-select v-model="filterData.cityId" @change="handleCitySelect">
              <el-option v-for="option in filterData.cityOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择区县：" v-if="flags.districtSelectShow">
            <el-select v-model="filterData.districtId" @change="handleDistrictSelect">
              <el-option v-for="option in filterData.districtOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择作物：">
            <el-select v-model="searchForm.cropName" @change="handleCropChange">
              <el-option v-for="option in filterData.cropOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择等级：" v-if="flags.ownerCategoryShow">
            <el-select v-model="searchForm.ownerCategoryName" @change="handleOwnerCategoryChange">
              <el-option v-for="option in filterData.ownerCategoryOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择年份：">
            <el-date-picker v-model="searchForm.searchYear" :clearable="false" type="year" format="yyyy年" value-format="yyyy" suffix-icon="el-icon-date" :picker-options="pickerOptions"></el-date-picker>
<!--            <el-select v-model="searchForm.searchYear">
              <el-option v-for="option in filterData.years" :key="option.value" :label="option.text" :value="option.value"></el-option>
            </el-select>-->
          </el-form-item>
          <el-form-item>
            <el-button class="wg-button" @click="handleSearch">查询</el-button>
            <el-button class="wg-button blank" @click="handleReset">重置</el-button>
            <el-button class="wg-button blank" @click="handleExport">导出</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- filter -->
    <div class="wg-box">
      <div class="body">
      <!-- collapse -->
      <el-collapse v-model="chartModuleActiveNames">
        <el-collapse-item name="farmerinfo">
          <template slot="title">
            农户信息
          </template>
          <ChartGroupFarmer ref="farmerGroup" :params="searchForm"></ChartGroupFarmer>
        </el-collapse-item>
        <div class="chart-divider"></div>
        <el-collapse-item name="plantinginfo">
          <template slot="title">
            种植信息
          </template>
          <ChartGroupPlanting ref="plantingGroup" :params="searchForm"></ChartGroupPlanting>
        </el-collapse-item>
        <div class="chart-divider"></div>
        <el-collapse-item name="ferilizationinfo">
          <template slot="title">
            施肥信息
          </template>
          <ChartGroupFertilize ref="fertilizeGroup" :params="searchForm"></ChartGroupFertilize>
        </el-collapse-item>
        <div class="chart-divider"></div>
        <el-collapse-item name="nutrientinfo">
          <template slot="title">
            养分投入
          </template>
          <ChartGroupNutrient ref="nutrientGroup" :params="searchForm"></ChartGroupNutrient>
        </el-collapse-item>
      </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import RegionApi from '@/api/region-api'
import CropApi from '@/api/crop-api'
import DataVisualizationApi from '@/api/chart-analysis/data-visualization-api'
import ChartGroupFarmer from '@/views/chart-analysis/components/farmer/ChartGroup'
import ChartGroupPlanting from '@/views/chart-analysis/components/planting/ChartGroup'
import ChartGroupFertilize from '@/views/chart-analysis/components/fertilize/ChartGroup'
import ChartGroupNutrient from '@/views/chart-analysis/components/nutrient/ChartGroup'
import FileApi from '@/api/file-api'
import FillInCycleApi from '@/api/data-fill-in/cycle/fill-in-cycle-api'

export default {
  name: 'DataVisualization',
  components: {
    ChartGroupNutrient,
    ChartGroupFertilize,
    ChartGroupFarmer,
    ChartGroupPlanting
  },
  data() {
    return {
      chartModuleActiveNames: ['farmerinfo'],
      flags: {
        provinceSelectShow: false,
        citySelectShow: false,
        districtSelectShow: false,
        ownerCategoryShow: false
      },
      pickerOptions: {
        disabledDate: (time) => {
          let years = this.filterData.years
          if(Array.isArray(years)) {
            return !years.includes(time.getFullYear())
          }
          return time.getFullYear() !== years[0]
        }
      },
      filterData: {
        provinceOptionList: [],
        cityOptionList: [],
        districtOptionList: [],
        cropOptionList: [],
        ownerCategoryOptionList: [],
        provinceId: null,
        cityId: null,
        districtId: null,
        years: [],
      },
      searchForm: {
        provinceName: null,
        cityName: null,
        districtName: null,
        cropName: null,
        ownerCategoryName: null,
        searchYear: '',
      }
    }
  },
  created() {
    this.bindYears()
    // province city district level option list
    let userLevelId = sessionStorage.getItem('levelId')
    this.filterData.cropOptionList.push({ text: '全部', value: null })

    if ('Country' === userLevelId) {
      this.flags.provinceSelectShow = true
      this.flags.citySelectShow = true
      this.flags.districtSelectShow = true
      // 只有国家级管理员才出现专业用户
      this.flags.ownerCategoryShow = true
      this.filterData.ownerCategoryOptionList.push({ text: '全部', value: null })
      this.filterData.ownerCategoryOptionList.push({ text: '农户', value: 'FARMER' })
      this.filterData.ownerCategoryOptionList.push({ text: '专业用户', value: 'PROFESSIONAL' })

      this.filterData.provinceOptionList.push({ text: '全部', value: null })
      this.filterData.cityOptionList.push({ text: '全部', value: null })
      this.filterData.districtOptionList.push({ text: '全部', value: null })
      RegionApi.getProvinceList().then(res => {
        res.data.forEach(el => {
          this.filterData.provinceOptionList.push({ text: el.name, value: el.id })
        })
      })
    }

    if ('Province' === userLevelId) {
      let provinceId = sessionStorage.getItem('provinceId')
      this.flags.citySelectShow = true
      this.flags.districtSelectShow = true
      this.flags.ownerCategoryShow = false
      this.filterData.provinceId = provinceId
      this.searchForm.provinceName = sessionStorage.getItem('provinceName')
      this.searchForm.ownerCategoryName = '农户'
      this.filterData.cityOptionList.push({ text: '全部', value: null })
      this.filterData.districtOptionList.push({ text: '全部', value: null })
      RegionApi.getCityList(provinceId).then(res => {
        res.data.forEach(el => {
          this.filterData.cityOptionList.push({ text: el.name, value: el.id })
        })
      })
    }

    if ('City' === userLevelId) {
      let provinceId = sessionStorage.getItem('provinceId')
      let cityId = sessionStorage.getItem('cityId')
      this.flags.districtSelectShow = true
      this.flags.ownerCategoryShow = false
      this.filterData.provinceId = provinceId
      this.searchForm.provinceName = sessionStorage.getItem('provinceName')
      this.filterData.cityId = cityId
      this.searchForm.cityName = sessionStorage.getItem('cityName')
      this.searchForm.ownerCategoryName = '农户'
      this.filterData.districtOptionList.push({ text: '全部', value: null })
      RegionApi.getDistrictList(cityId).then(res => {
        res.data.forEach(el => {
          this.filterData.districtOptionList.push({ text: el.name, value: el.id })
        })
      })
    }

    if ('District' === userLevelId) {
      this.flags.ownerCategoryShow = false
      this.filterData.provinceId = sessionStorage.getItem('provinceId')
      this.searchForm.provinceName = sessionStorage.getItem('provinceName')
      this.filterData.cityId = sessionStorage.getItem('cityId')
      this.searchForm.cityName = sessionStorage.getItem('cityName')
      this.filterData.districtId = sessionStorage.getItem('districtId')
      this.searchForm.districtName = sessionStorage.getItem('districtName')
      this.searchForm.ownerCategoryName = '农户'
    }

    CropApi.getStubList().then(res => {
      res.data.forEach(el => {
        this.filterData.cropOptionList.push({ text: el.name, value: el.id })
      })
    })
  },
  methods: {
    bindYears() {
      this.filterData.years = []
      FillInCycleApi.getList().then(res => {
        res.data.map(v => {
          this.filterData.years.push(v.year)
        })
        this.searchForm.searchYear = this.filterData.years[0].toString()
      })
    },
    handleSearch() {
      this.$nextTick(() => {
        if(this.searchForm.cropName === '全部') {
          this.searchForm.cropName = null
        }
        if(this.searchForm.ownerCategoryName === '全部') {
          this.searchForm.ownerCategoryName = null
        }
        this.$refs.farmerGroup.refreshCharts()
        this.$refs.plantingGroup.refreshCharts()
        this.$refs.fertilizeGroup.refreshCharts()
        this.$refs.nutrientGroup.refreshCharts()
      })
    },

    handleProvinceSelect(selectedValue) {
      this.filterData.cityId = null
      this.searchForm.cityName = null
      this.filterData.districtId = null
      this.searchForm.districtName = null
      this.filterData.cityOptionList = []
      this.filterData.cityOptionList.push({ text: '全部', value: null })
      this.filterData.districtOptionList = []
      this.filterData.districtOptionList.push({ text: '全部', value: null })
      if (null === selectedValue) {
        this.searchForm.provinceName = null
        return
      }
      this.searchForm.provinceName = this.filterData.provinceOptionList.find(p => p.value === selectedValue).text

      RegionApi.getCityList(selectedValue).then(res => {
        res.data.forEach(el => {
          this.filterData.cityOptionList.push({ text: el.name, value: el.id })
        })
      })
    },
    handleCitySelect(selectedValue) {
      this.filterData.districtId = null
      this.searchForm.districtName = null
      this.filterData.districtOptionList = []
      this.filterData.districtOptionList.push({ text: '全部', value: null })
      if (null === selectedValue) return
      this.searchForm.cityName = this.filterData.cityOptionList.find(p => p.value === selectedValue).text
      RegionApi.getDistrictList(selectedValue).then(res => {
        res.data.forEach(el => {
          this.filterData.districtOptionList.push({ text: el.name, value: el.id })
        })
      })
    },
    handleDistrictSelect(selectedValue) {
      this.filterData.districtId = selectedValue
      if (null === selectedValue) return
      this.searchForm.districtName = this.filterData.districtOptionList.find(p => p.value === selectedValue).text
    },
    handleCropChange(selectedValue) {
      if (null === selectedValue) return
      this.searchForm.cropName = this.filterData.cropOptionList.find(p => p.value === selectedValue).text
    },
    handleOwnerCategoryChange(selectedValue) {
      if (null === selectedValue) return
      this.searchForm.ownerCategoryName = this.filterData.ownerCategoryOptionList.find(p => p.value === selectedValue).text
    },
    handleReset() {
      this.resetFilters()
    },
    handleExport() {
      this.$confirm('导出数据, 是否继续？', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
        .then(async () => {
          let exportId = null

          const loading = this.$loading({
            lock: true,
            text: '正在导出数据......',
            spinner: 'el-icon-loading'
          })
          if(this.searchForm.cropName === '全部') {
            this.searchForm.cropName = null
          }
          if(this.searchForm.ownerCategoryName === '全部') {
            this.searchForm.ownerCategoryName = null
          }
          await DataVisualizationApi.exportExcel(this.searchForm)
            .then(res => {
              exportId = res.data.id
              loading.close()
            })
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })

          const loadingDownload = this.$loading({
            lock: true,
            text: '等待下载数据文件......',
            spinner: 'el-icon-loading'
          })

          await FileApi.get('TEMP', exportId + '.xlsx')
            .then(res => {
              let byteBuffer = window.atob(res.data)
              let arrayBuffer = new ArrayBuffer(byteBuffer.length)
              let dataBuffer = new Uint8Array(arrayBuffer)
              for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

              let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(blob)
              link.setAttribute('download', '数据可视化.xlsx')
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(link.href)
              document.body.removeChild(link)
              loadingDownload.close()
            })
            .catch(err => {
              loadingDownload.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })

          await FileApi.delete('TEMP', exportId + '.xlsx')
            .then(() => {})
            .catch(err => {
              loadingDownload.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
        })
    },
    resetFilters() {
      this.searchForm.provinceName = null
      this.searchForm.cityName = null
      this.searchForm.districtName = null
      this.searchForm.cropName = null
      this.searchForm.searchYear = this.filterData.years[0].toString()
    }
  }
}
</script>
<style>

.echarts {
  width: 45vw;
  min-width: 400px;
  height: 340px;
}
.chart-divider {
  clear: both;
  height: 20px;
  background: #f6f6f6;
}
.chart-title {
  position: relative;
  padding-left: 32px;
  margin: 12px 0;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.chart-title:before {
  content: "";
  background-color: #039f3a;
  width: 6px;
  height: 20px;
  position: absolute;
  left: 18px;
  top: 50%;
  margin-top: -10px;
}
</style>
<style scoped>

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-collapse {
  border-top: 0;
  border-bottom: 0;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
}

/deep/ .el-collapse-item__arrow {
  font-size: 16px;
  font-weight: bold;
}
/deep/ .el-collapse-item__header {
  font-size: 14px;
  font-weight: bold;
  height: 50px;
  padding-left: 20px;
  background-color: #D6D6D6;
  border: 0.5px solid #D6D6D6;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
  border-radius: 4px 4px 0px 0px;
}
/deep/ .el-collapse-item__header.is-active {
  font-size: 14px;
  font-weight: bold;
  height: 50px;
  padding-left: 20px;
  background-color: #D6D6D6;
  border: 0.5px solid #D6D6D6;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
  border-radius: 4px 4px 0px 0px;
}
/deep/ .el-collapse-item__content {
  padding-bottom: 0px;
}
/deep/ .el-collapse-item__wrap {
  border-bottom: 0px solid #d6d6d6;
}
/deep/ .el-collapse {
  border-top: 0px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}
.el-date-editor >>> .el-input__inner {
  padding: 0 15px;
}
.el-date-editor >>> .el-input__prefix{
  right: 5px;
  left: unset;
}
.el-date-editor >>> .el-input__suffix{
  right: 25px;
}
</style>
