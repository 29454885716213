import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/roles', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/roles/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/roles', updateModel)
  },
  getById(id) {
    return httpRequest.get('/roles/' + id)
  },
  getList(name) {
    let config = { params: { name: name } }
    return httpRequest.get('/roles', config)
  },
  getListByUserId(userId) {
    return httpRequest.get('/users/' + userId + '/roles')
  }
}
