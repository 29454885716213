import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/plant-crops', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/plant-crops/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/plant-crops', updateModel)
  },
  submit(id) {
    return httpRequest.put('/plant-crop-submit', { id: id })
  },
  getAccount() {
    return httpRequest.get('/own-account')
  },
  getById(id) {
    return httpRequest.get('/plant-crops/' + id)
  }
}
