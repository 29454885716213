<template>
  <div>
    <el-row>
      <el-col :span='24'>
        <div class="chart-title">
          <div>
            <span>化肥施用强度</span>
            <el-button style="float: right; padding: 3px 30px 3px 0" type="text" @click="changeChartType">{{chartType === 'bar' ? '折线图' : '柱状图'}}</el-button>
          </div>
        </div>
        <div style="clear: both;">
          <el-divider></el-divider>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span='24'>
        <v-chart
          :option='options'
          :init-options='initOptions'
          ref='intensityNumbers'
          :loading='barLoading'
          :loadingOptions='barLoadingOptions'
          :updateOptions="{notMerge: true}"
          autoresize
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VChart from 'vue-echarts'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'

import {
  use
} from 'echarts/core'

import {
  BarChart
} from 'echarts/charts'

import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components'
import AnnualAnalysisApi from '@/api/chart-analysis/annual-analysis-api'

use([
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
])

export default {
  name: 'IntensityAnnual',
  components: {
    VChart
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      initOptions: {
        renderer: 'canvas'
      },
      options: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      chartType: 'line',
      seconds: -1,
      barLoading: false,
      barLoadingOptions: {
        text: '加载中…',
        color: '#4ea397',
        maskColor: 'rgba(255, 255, 255, 0.4)'
      }
    }
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    changeChartType() {
      this.chartType = this.chartType === 'line' ? 'bar' : 'line'
      if (this.options && this.options.series) {
        this.options.series.forEach(p => p.type = this.chartType)
      }
    },
    getChartData() {
      this.options = {}
      this.barLoading = true
      let postfix = this.params.groupType === 'years' ? '年' : ''
      let prefix = this.params.groupType !== 'years' ? this.params.searchYear + '年  ' : ''

      AnnualAnalysisApi.getIntensity(this.params).then(res => {
        if(res.data.length === 0) {
          this.options = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }

        const colors = ['#5B8FF9', '#62D9AA', '#FBDB63']
        let legends = res.data.map(el => el.name)
        let xLabels = res.data[0].value.map(f => f.name + postfix)
        let dataset = res.data.map(f => {
          return {
            source: f.value.map(p => {
              return { name: p.name + postfix, value: p.value }
            })
          }
        })
        this.options = {
          textStyle: {
            fontSize: 14
          },
          color: colors,
          /*title: {
            text: '(单位:公斤/亩)',
            top: 20,
            left: 'left',
            textStyle: {
              fontSize: 14,
              color: '#000000',
              fontWeight: 500
            }
          },*/
          grid: {
            top: '12%',
            left: '3%',
            right: '4%',
            containLabel: true
          },
          legend: {
            x: 'right',      //可设定图例在左、右、居中
            y: 'top',     //可设定图例在上、下、居中
            padding: [14, 50, 0, 0],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
            icon: 'rect', // 修改形状
            itemWidth: 8,
            itemHeight: 8, // 修改icon图形大小
            data: legends
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'line',
              label: {
                backgroundColor: '#6a7985'
              }
            },
            textStyle: {
              align: 'left'
            },
            formatter(a) {
              let res = ''
              let sum = 0
              a.forEach((item, index) => {
                if (index === 0) {
                  res += prefix + `${item.axisValue}<br/>`
                }
                sum += item.data.value
                res += `${item.marker} ${item.seriesName} <span style='font-weight:bold;float: right'>${item.data.value}</span><br/>`
                if (index === a.length - 1) {
                  res += `<br/> 总养分 <span style='font-weight:bold;float: right'>${sum}</span>`
                }
              })
              return res
            },
            extraCssText: 'width: 200px'
          },
          xAxis: {
            type: 'category',
            axisLabel: {
              fontSize: 14
            },
            data: xLabels
          },
          yAxis: {
            type: 'value',
            name: '(单位:公斤/亩)',
            axisLabel: {
              fontSize: 14,
              interval: 0
            }
          },
          dataZoom: [
            {
              type: 'inside',
              filterMode: 'empty',
              zoomOnMouseWheel: false,
              // start:20,
              // end:60,
            },
            {
              type: 'slider',
              height: '24px',
              bottom: '30px',
              left: 'center',
              brushSelect: false,
              borderColor: 'transparent',
              backgroundColor: 'rgba(0,0,0,0.04)',
              fillerColor: 'rgba(0,0,0,0.06)',
              moveOnMouseWheel: false,
              zoomOnMouseWheel: false
            }
          ],
          dataset: dataset,
          series: res.data.map((f, index) => {
            return {
              name: f.name,
              type: this.chartType,
              stack: '总养分',
              datasetIndex: index,
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              symbol: 'circle',
              symbolSize: [8, 8],
              areaStyle: {
                color: colors[index]
              },
              emphasis: {
                focus: 'series'
              },
              encode: { y: 'value' }
            }
          })
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.barLoading = false
      })
    }
  }
}
</script>

<style scoped>
.echarts {
  width: 98vw;
}

.el-divider--horizontal {
  margin: 2px 0;
}

.el-divider--vertical {
  display: inline-block;
  weight: 1px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;
}
</style>
