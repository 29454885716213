<template>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" :lock-scroll="false" @close="handleClose">
    <div>
      <el-card class="box-card">
        <el-upload name="file" ref="fileUpload" :action="actionUrl" :data="uploadParams" :auto-upload="false" :before-upload="handleUploadBeforeUpload" :on-success="handleUploadSuccess">
          <el-button size="small" class="wg-button">点击上传</el-button>
          <div slot="tip" class="el-upload__tip" style="margin-top: 10px">提示：上传文件格式限定（xlsx）</div>
        </el-upload>
      </el-card>
      <div class="import-summary">
        <p style="color: #ff0000">点击“导入模板下载”，在模板里录入信息，点击“导入”按钮，实现数据批量导入；请仔细阅读导入模板中的提示！！！</p>
        <ul class="import-description">
          <li>1.1&nbsp;数据模板的“电话”列不可修改，如果表格行的”电话“列标记为绿色表示该行已经保存过。</li>
          <li>1.2&nbsp;导入后返回“数据导入结果”文件，打开文件查看导入的情况；</li>
          <li>1.3&nbsp;记录行的“电话”列标记为绿色表示该行已经导入成功；</li>
          <li style="color: #ff0000">1.4&nbsp;记录行的“电话”列标记为红色表示该行导入失败,该行的最后一列显示“导入失败原因”。</li>
        </ul>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" class="wg-button blank" @click="dialogVisible = false">取 消</el-button>
      <el-button size="small" class="wg-button" @click="handleOk">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FileApi from '@/api/file-api'

export default {
  name: 'UploadQuestionnaireImportFileDialog',
  props: [],
  data() {
    return {
      dialogTitle: '文件上传',
      dialogVisible: false,
      actionUrl: null,
      uploadParams: { category: 'UPLOAD_TEMP' }
    }
  },
  created() {
    this.actionUrl = FileApi.getUploadURI()
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleOk() {
      this.$refs.fileUpload.submit()
    },
    handleUploadBeforeUpload(file) {
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.$message({ type: 'error', message: '上传文件格式错误！' })
        return false
      }
    },
    handleUploadSuccess(res) {
      this.$emit('uploadSuccess', res.savedFileName)
      this.dialogVisible = false
    },
    async open() {
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped>
.import-summary {
  padding: 8px;
}
.import-summary > p {
  margin: 8px;
  line-height: 24px;
}

.import-description {
  margin: 0 0 0 10px;
  padding: 0;
  list-style-type: none;
}
.import-description li {
  line-height: 24px;
}
</style>
