import httpRequest from '@/utils/http-request'

export default {
  sendLoginVerifyCode(phoneNumber) {
    let body = { phoneNumber: phoneNumber }
    return httpRequest.post('/login-verify-code-send', body)
  },
  sendResetPasswordVerifyCode(phoneNumber) {
    let body = { phoneNumber: phoneNumber }
    return httpRequest.post('/reset-password-verify-code-send', body)
  },
  sendPhoneNumberVerifyCode(phoneNumber) {
    let body = { phoneNumber: phoneNumber }
    return httpRequest.post('/phone-number-verify-code-send', body)
  }
}
