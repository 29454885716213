import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/users', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/users/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/users', updateModel)
  },
  audit(id) {
    let paramModel = { id: id }
    return httpRequest.put('/user-audit', paramModel)
  },
  enable(id) {
    let paramModel = { id: id }
    return httpRequest.put('/user-enable', paramModel)
  },
  disable(id) {
    let paramModel = { id: id }
    return httpRequest.put('/user-disable', paramModel)
  },
  getById(id) {
    return httpRequest.get('/users/' + id)
  },
  count(regionId, keyword, startDate, endDate, levelId, auditFlag, disableFlag) {
    let body = {
      regionId: regionId,
      keyword: keyword,
      startDate: startDate,
      endDate: endDate,
      levelId: levelId,
      auditFlag: auditFlag,
      disableFlag
    }
    return httpRequest.post('/user-count', body)
  },
  search(regionId, keyword, startDate, endDate, levelId, auditFlag, disableFlag) {
    let body = {
      regionId: regionId,
      keyword: keyword,
      startDate: startDate,
      endDate: endDate,
      levelId: levelId,
      auditFlag: auditFlag,
      disableFlag
    }
    return httpRequest.post('/user-search', body)
  },
  pagingSearch(regionId, keyword, startDate, endDate, levelId, auditFlag, disableFlag, offset, limit) {
    let body = {
      regionId: regionId,
      keyword: keyword,
      startDate: startDate,
      endDate: endDate,
      levelId: levelId,
      auditFlag: auditFlag,
      disableFlag
    }
    let config = {
      params: {
        offset: offset,
        limit: limit
      }
    }
    return httpRequest.post('/user-paging-search', body, config)
  }
}
