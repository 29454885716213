<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">农事管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/CropManage' }">作物管理</el-breadcrumb-item>
        <el-breadcrumb-item>生育期</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <div class="wg-box">
      <div class="header">
        <div class="title">{{ crop.name }}</div>
        <div class="wg-clear-fix"></div>
        <div class="button-list">
          <el-button size="small" class="wg-button green" icon="el-icon-plus" @click="handleAdd">新增</el-button>
          <el-button size="small" class="wg-button red" icon="el-icon-delete" @click="handleDeleteSelected">删除</el-button>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <div class="body">
        <div class="wg-table-box">
          <el-table :data="tableData" @selection-change="handleSelectionChange" class="wg-table normal">
            <el-table-column type="selection" width="50"> </el-table-column>
            <el-table-column label="序号" width="80" prop="sortNumber"> </el-table-column>
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column prop="createdOn" label="创建时间" width="180" sortable></el-table-column>
            <el-table-column prop="modifiedOn" label="修改时间" width="180" sortable></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleEdit(scope.row.id)">编辑</el-button>
                <el-button type="text" size="small" @click="handleDelete(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="footer">
        <el-button size="small" class="wg-button blank" @click="handleGotoCrop">返回</el-button>
      </div>
    </div>
    <!-- table data -->
    <create-window ref="createWindow" v-if="isLoadCreateWindow" @reload="handleReloadCreateWindow"></create-window>
    <modify-window ref="modifyWindow" v-if="isLoadModifyWindow" @reload="handleReloadModifyWindow"></modify-window>
  </div>
</template>

<script>
import CropGrowthPeriodApi from '@/api/crop-growth-period-api'
import CropApi from '@/api/crop-api'

import CropGrowthPeriodCreate from './CropGrowthPeriodCreate.vue'
import CropGrowthPeriodModify from './CropGrowthPeriodModify.vue'

export default {
  name: 'CropGrowthPeriodManage',
  components: {
    'create-window': CropGrowthPeriodCreate,
    'modify-window': CropGrowthPeriodModify
  },
  data() {
    return {
      isLoadCreateWindow: true,
      isLoadModifyWindow: true,
      params: { cropId: null },
      crop: {
        name: null,
        category: null
      },
      tableData: [],
      selectedRows: []
    }
  },
  created() {
    this.params.cropId = this.$route.query.cropId
    CropApi.getById(this.params.cropId)
      .then(res => {
        this.crop.name = res.data.name
        this.crop.category = res.data.category
      })
      .catch(err => {
        this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
      })
    this.bindTableData()
  },
  methods: {
    handleReloadCreateWindow() {
      this.isLoadCreateWindow = false
      this.$nextTick(() => {
        this.isLoadCreateWindow = true
      })
      this.bindTableData()
    },
    handleReloadModifyWindow() {
      this.isLoadModifyWindow = false
      this.$nextTick(() => {
        this.isLoadModifyWindow = true
      })
      this.bindTableData()
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows
    },
    handleAdd() {
      this.$refs.createWindow.open(this.params.cropId)
    },
    handleDeleteSelected() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
        .then(() => {
          this.delete()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    handleEdit(id) {
      this.$refs.modifyWindow.open(id)
    },
    handleDelete(id) {
      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
        .then(() => {
          CropGrowthPeriodApi.deleteById(id)
            .then(() => {
              this.$message({ type: 'success', message: '删除成功！' })
              this.bindTableData()
            })
            .catch(err => {
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    handleGotoCrop() {
      this.$router.push({ path: '/crop', query: { category: this.crop.category } })
    },
    bindTableData() {
      CropGrowthPeriodApi.getListByCropId(this.params.cropId)
        .then(res => {
          this.tableData = []
          res.data.forEach(el => {
            this.tableData.push({
              id: el.id,
              sortNumber: el.sortNumber,
              name: el.name,
              createdOn: el.createdOn,
              modifiedOn: el.modifiedOn
            })
          })
        })
        .catch(err => {
          this.$err({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async delete() {
      let theObj = this
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading' })
      for (let i = 0; i < this.selectedRows.length; i++) {
        await CropGrowthPeriodApi.deleteById(this.selectedRows[i].id)
          .then(() => {
            this.$message({ showClose: true, message: theObj.selectedRows[i].name + '删除成功！', type: 'success' })
          })
          .catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          })
      }
      loading.close()
      this.bindTableData()
    }
  }
}
</script>

<style scoped>
</style>