<template>
  <el-card>
    <div slot="header" class="chart-title"><span>有机肥各类型施用量及户数</span></div>
    <div>
      <el-row :gutter="20" type="flex">
        <el-col :span="12" style="padding-left: 40px;">
          <v-chart
              :option="organicFertilizerDosagesOptions"
              :init-options="initOptions"
              ref="organicFertilizerDosages"
              :loading="dosagesLoading"
              :loadingOptions="barLoadingOptions"
              :updateOptions="{notMerge: true}"
              autoresize
          />
        </el-col>
        <el-divider direction="vertical"></el-divider>
        <el-col :span="12">
          <v-chart
              :option="organicFertilizerFarmersOptions"
              :init-options="initOptions"
              ref="organicFertilizerFarmers"
              :loading="farmersLoading"
              :loadingOptions="barLoadingOptions"
              :updateOptions="{notMerge: true}"
              autoresize
          />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import VChart from 'vue-echarts'
import {CanvasRenderer, SVGRenderer} from 'echarts/renderers'

import {
  use
} from 'echarts/core'

import {
  BarChart
} from 'echarts/charts'

import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components'
import DataVisualizationApi from '@/api/chart-analysis/data-visualization-api'

use([
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
])

export default {
  name: "OrganicFertilizer",
  components: {
    VChart
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas"
      },
      dosagesChartData: null,
      farmersChartData: null,
      organicFertilizerDosagesOptions: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      organicFertilizerFarmersOptions: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      seconds: -1,
      dosagesLoading: false,
      farmersLoading: false,
      barLoadingOptions: {
        text: "加载中…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0.4)"
      },
    };
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    getChartData() {
      this.organicFertilizerDosagesOptions = {}
      this.organicFertilizerFarmersOptions = {}
      this.dosagesLoading = true
      this.farmersLoading = true
      DataVisualizationApi.getOrganicFertilizerDosages(this.params).then(res => {
        this.dosagesChartData = res.data
        if(this.dosagesChartData.length === 0) {
          this.organicFertilizerDosagesOptions = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }
        this.renderOrganicFertilizerDosagesChart()
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.dosagesLoading = false
      })
      DataVisualizationApi.getOrganicFertilizerFarmers(this.params).then(res => {
        this.farmersChartData = res.data
        if(this.farmersChartData.length === 0) {
          this.organicFertilizerFarmersOptions = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }
        this.renderOrganicFertilizerFarmersChart()
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.farmersLoading = false
      })
    },
    renderOrganicFertilizerDosagesChart() {
      this.organicFertilizerDosagesOptions = {
        textStyle: {
          fontSize: 14
        },
        title: {
          text: '数量(公斤/亩)',
          top: 20,
          left: 'left',
          textStyle: {
            fontSize: 14,
            color: '#000000',
            fontWeight: 500
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLabel: {
            interval: 0, //强制显示X轴文本
            fontSize: 14
          },
          data: this.dosagesChartData.map(item => item.name)
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            data: this.dosagesChartData.map(item => item.value),
            type: 'bar',
            barWidth: '25%',
            label: {
              show: true,
              position: 'top',
              fontSize: 14
            },
            itemStyle: {
              color: '#27BC55',
              shadowColor: '#91cc75',
              borderType: 'dotted'
            }
          }
        ]
      }
    },
    renderOrganicFertilizerFarmersChart() {
      this.organicFertilizerFarmersOptions = {
        textStyle: {
          fontSize: 14
        },
        title: {
          text: '户数',
          top: 20,
          left: 'left',
          textStyle: {
            fontSize: 14,
            color: '#000000',
            fontWeight: 500
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed'
            }
          },
          axisLabel: {
            interval: 0, //强制显示X轴文本
            fontSize: 14
          },
          data: this.farmersChartData.map(item => item.name)
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            data: this.farmersChartData.map(item => item.value),
            type: 'bar',
            barWidth: '25%',
            label: {
              show: true,
              position: 'top',
              fontSize: 14
            },
            itemStyle: {
              color: '#FFC44A',
              shadowColor: '#f8dba3',
              borderType: 'dotted'
            }
          }
        ]

      }
    }
  }
}
</script>

<style scoped>

.el-divider--horizontal {
  margin: 2px 0;
}

.el-divider--vertical {
  display: inline-block;
  weight: 1px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;
}
</style>
