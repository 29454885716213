import VueRouter from 'vue-router'
import Main from '../views/Main'

import FarmerFillIn from '../views/fill-in/FarmerFillIn'
import ProfessionalFillIn from '../views/fill-in/ProfessionalFillIn'
import AssistFillIn from '../views/assist-fill-in/AssistFillIn'
import SurfaceSurvey from '../views/surface-survey/SurfaceSurvey'

import Questionnaire from '../views/questionnaire/Questionnaire'
import QuestionnaireAssist from '../views/questionnaire/QuestionnaireAssist'

import DataAudit from '../views/data-audit/DataAudit'

import CropTab from '../views/crop/CropTab'
import CropGrowthPeriodManage from '../views/crop-growth-period/CropGrowthPeriodManage'
import FertilizerManage from '../views/fertilizer/FertilizerManage'
import SoilManage from '../views/soil/SoilManage'
import PlotManage from '../views/plot/PlotManage'

import UserManage from '../views/user/UserManage'
import FarmerIntegration from '../views/farmer-integration/FarmerIntegration'

import UserLevelManage from '../views/user-level/UserLevelManage'
import RoleManage from '../views/role/RoleManage'
import ProfessionalCategoryManage from '../views/professional-category/ProfessionalCategoryManage'
import ProfessionalIndexDirectoryManage from '../views/professional-index-directory/ProfessionalIndexDirectoryManage'

import UCenter from '../views/ucenter/UCenter'
import Personal from '../views/ucenter/Personal'
import OwnIntegration from '../views/ucenter/OwnIntegration'
import ChangePassword from '../views/ucenter/ChangePassword'
import ChangePhoneNumber from '../views/ucenter/ChangePhoneNumber'
import ValidatePhoneNumber from '../views/ucenter/ValidatePhoneNumber'

import DataVisualization from '@/views/chart-analysis/DataVisualization'
import AnnualAnalysis from '@/views/chart-analysis/AnnualAnalysis'

export default {
  create() {
    const configRoutes = [
      { name: 'Main', path: '/', component: Main },
      { name: 'FarmerFillIn', path: '/farmer-fill-in', component: FarmerFillIn },
      { name: 'ProfessionalFillIn', path: '/professional-fill-in', component: ProfessionalFillIn },
      { name: 'AssistFillIn', path: '/assist-fill-in', component: AssistFillIn },
      { name: 'SurfaceSurvey', path: '/surface-survey', component: SurfaceSurvey },
      { name: 'Questionnaire', path: '/questionnaire', component: Questionnaire },
      { name: 'QuestionnaireAssist', path: '/assist-questionnaire', component: QuestionnaireAssist },
      { name: 'DataAuditManage', path: '/data-audit', component: DataAudit },
      { name: 'DataVisualization', path: '/data-visualization', component: DataVisualization },
      { name: 'AnnualAnalysis', path: '/annual-analysis', component: AnnualAnalysis },
      { name: 'CropTab', path: '/crop-tab', component: CropTab },
      { name: 'CropGrowthPeriodManage', path: '/crop/growth-period', component: CropGrowthPeriodManage },
      { name: 'SoilManage', path: '/soil', component: SoilManage },
      { name: 'PlotManage', path: '/plot', component: PlotManage },
      { name: 'FertilizerManage', path: '/fertilizer', component: FertilizerManage },
      { name: 'UserManage', path: '/user', component: UserManage },
      { name: 'FarmerIntegration', path: '/farmer-integration', component: FarmerIntegration },
      { name: 'UserLevelManage', path: '/user-level', component: UserLevelManage },
      { name: 'RoleManage', path: '/role', component: RoleManage },
      { name: 'ProfessionalCategoryManage', path: '/professional-category', component: ProfessionalCategoryManage },
      {
        name: 'ProfessionalIndexDirectoryManage',
        path: '/professional-index-directory',
        component: ProfessionalIndexDirectoryManage
      },
      {
        name: 'UCenter',
        path: '/ucenter',
        component: UCenter,
        children: [
          { name: 'Personal', path: '/personal', component: Personal },
          { name: 'OwnIntegration', path: '/own-integration', component: OwnIntegration },
          { name: 'ChangePassword', path: '/change-password', component: ChangePassword },
          { name: 'ChangePhoneNumber', path: '/change-phone-number', component: ChangePhoneNumber },
          { name: 'ValidatePhoneNumber', path: '/validate-phone-number', component: ValidatePhoneNumber }
        ]
      }
    ]

    const mainRouter = new VueRouter({
      routes: configRoutes,
      mode: 'history'
    })

    return mainRouter
  }
}
