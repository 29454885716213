<template>
  <div ref="archivedUnsubmittedPlantCropDataPane" style="margin-top: 0" v-loading="dataLoading" element-loading-text="拼命加载数据......" element-loading-spinner="el-icon-loading">
    <div class="wg-box">
      <div class="header">
        <div class="button-list">
          <el-button size="small" class="wg-button red" icon="el-icon-delete" @click="handleDeleteSelected">删除 </el-button>
          <el-button size="small" class="wg-button blue" icon="el-icon-download" @click="handleExportData">导出 </el-button>
          <el-button size="small" class="wg-button blue" icon="el-icon-full-screen" v-if="!isFullscreen" @click="handleFullscreen">全屏 </el-button>
          <el-button size="small" class="wg-button blue" icon="el-icon-full-screen" v-if="isFullscreen" @click="handleExtFullscreen">退出 </el-button>
        </div>
        <div class="wg-clear-fix"></div>
        <div class="filter-param-list">
          <el-row :gutter="10">
            <el-col :span="4">
              <el-input size="small" v-model="filterParams.keyword" @change="handleKeywordChange" :clearable="true" placeholder="农户姓名或电话">
                <template slot="prepend">关键字</template>
              </el-input>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="body">
        <div class="wg-table-box">
          <el-table ref="table" :data="tableData" :max-height="tableHeight" row-key="id" class="wg-table normal" @selection-change="handleSelectionChange" border>
            <el-table-column type="selection" width="50" fixed></el-table-column>
            <el-table-column label="序号" type="index" width="100" sortable fixed></el-table-column>
            <el-table-column label="用户名称" prop="farmer.username" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'username')" fixed></el-table-column>
            <el-table-column label="农户姓名" prop="farmer.fullName" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'fullName')" fixed></el-table-column>
            <el-table-column label="农户信息">
              <el-table-column label="省份" prop="farmer.provinceName" width="100" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'provinceName')"></el-table-column>
              <el-table-column label="地市" prop="farmer.cityName" width="100" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'cityName')"></el-table-column>
              <el-table-column label="区县" prop="farmer.districtName" width="100" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'districtName')"></el-table-column>
              <el-table-column label="详细地址" prop="farmer.detailedAddress" width="150" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'detailedAddress')"></el-table-column>
              <el-table-column label="电话" prop="farmer.phoneNumber" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'phoneNumber')"></el-table-column>
              <el-table-column label="农户性质" prop="farmer.farmerNatureName" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'farmerNatureName')"></el-table-column>
              <el-table-column label="文化程度" prop="farmer.educationalLevelName" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'educationalLevelName')"></el-table-column>
              <el-table-column prop="farmer.cultivationLandArea" width="170" sortable>
                <template slot="header">
                  经营面积
                  <el-tooltip content="经营面积指农户从事农业生产总的经营面积，包括耕地、园地，主要反映农户生产规模大小。" placement="top">
                    <i class="el-icon-question" style="font-size: 18px; color:#E6A23C"></i>
                  </el-tooltip>
                  <br />（含园地，亩）
                </template>
              </el-table-column>
              <el-table-column label="示范户" prop="farmer.modelHouseholdFlag" class-name="cell-tag" width="120" sortable>
                <template slot-scope="scope">
                  <div :style="null === scope.row.farmer.modelHouseholdFlag ? 'color:#333' : scope.row.farmer.modelHouseholdFlag ? 'color:#039F3A' : 'color:#FF8900'">
                    {{ null === scope.row.farmer.modelHouseholdFlag ? '--' : scope.row.farmer.modelHouseholdFlag ? '是' : '否' }}
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <!-- 农户信息 -->
            <el-table-column label="地块信息">
              <el-table-column label="地块名称" prop="monitoringPlot.name" width="120" sortable :sort-method="(a, b) => sortMonitoringPlotColumn(a, b, 'name')"></el-table-column>
              <el-table-column label="地块代码" prop="monitoringPlot.code" width="120" sortable :sort-method="(a, b) => sortMonitoringPlotColumn(a, b, 'code')"></el-table-column>
              <el-table-column label="地块面积" prop="monitoringPlot.plotArea" width="120" sortable>
                <template slot="header">地块面积<br />（亩）</template>
              </el-table-column>
            </el-table-column>
            <!-- 地块信息 -->
            <el-table-column label="种植信息">
              <el-table-column label="作物分类" width="120" prop="plantCrop.cropSubCategoryName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'cropSubCategoryName')"></el-table-column>
              <el-table-column label="作物名称" width="120" prop="plantCrop.cropName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'cropName')"></el-table-column>
              <el-table-column label="作物备注" width="120" prop="plantCrop.cropRemark" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'cropRemark')"></el-table-column>
              <el-table-column label="播种日期" width="150" prop="plantCrop.sowingDate" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'sowingDate')"></el-table-column>
              <el-table-column label="收获日期" width="150" prop="plantCrop.harvestTime" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'harvestTime')"></el-table-column>
              <el-table-column width="120" prop="plantCrop.sowingArea" sortable>
                <template slot="header">播种面积<br />（亩）</template>
                <template slot-scope="scope">
                  <div>{{ scope.row.plantCrop.sowingArea }}</div>
                </template>
              </el-table-column>
              <el-table-column label="地块肥力" width="120" prop="plantCrop.plotFertilityName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'plotFertilityName')"></el-table-column>
              <el-table-column width="120" prop="plantCrop.yield" sortable>
                <template slot="header">亩产量<br />（公斤/亩）</template>
                <template slot-scope="scope">
                  <div>{{ scope.row.plantCrop.yield }}</div>
                </template>
              </el-table-column>
              <el-table-column label="产量水平" width="130" prop="plantCrop.yieldLevelName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'yieldLevelName')">
                <template slot="header">
                  产量水平
                  <el-tooltip content="产量水平指该作物产量相对当地水平如何。" placement="top">
                    <i class="el-icon-question" style="font-size: 18px; color:#E6A23C"></i>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="plantCrop.facilityCultivationFlag" sortable>
                <template slot="header">是否<br />设施栽培</template>
                <template slot-scope="scope">
                  <div :style="null === scope.row.plantCrop.facilityCultivationFlag ? 'color:#333' : scope.row.plantCrop.facilityCultivationFlag ? 'color:#039F3A' : 'color:#FF8900'">
                    {{ null === scope.row.plantCrop.facilityCultivationFlag ? '--' : scope.row.plantCrop.facilityCultivationFlag ? '是' : '否' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="plantCrop.happenDisasterFlag" sortable>
                <template slot="header">是否<br />种植季受灾</template>
                <template slot-scope="scope">
                  <div :style="null === scope.row.plantCrop.happenDisasterFlag ? 'color:#333' : scope.row.plantCrop.happenDisasterFlag ? 'color:#039F3A' : 'color:#FF8900'">
                    {{ null === scope.row.plantCrop.happenDisasterFlag ? '--' : scope.row.plantCrop.happenDisasterFlag ? '是' : '否' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="前茬作物" width="120" prop="plantCrop.previousCropSubCategoryName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'previousCropSubCategoryName')">
                <template slot-scope="scope">
                  <div>{{ null === scope.row.plantCrop.previousCropSubCategoryName ? '无' : scope.row.plantCrop.previousCropSubCategoryName }}</div>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="plantCrop.previousYield" sortable>
                <template slot="header">前茬作物<br />亩产量<br />（公斤/亩）</template>
                <template slot-scope="scope">
                  <div>{{ scope.row.plantCrop.previousYield }}</div>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="plantCrop.previousStrawReturnRate" sortable>
                <template slot="header">前茬秸秆<br />还田率<br />（%）</template>
                <template slot-scope="scope">
                  <div>{{ scope.row.plantCrop.previousStrawReturnRate }}</div>
                </template>
              </el-table-column>
            </el-table-column>
            <!-- 种植作物 -->
            <el-table-column label="底肥（公斤/亩）">
              <el-table-column width="120" prop="baseFertilizer.fertilizationModeName" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'fertilizationModeName')">
                <template slot="header">底肥<br />施肥方式</template>
                <template slot-scope="scope">
                  <div>{{ scope.row.baseFertilizer.fertilizationModeName }}</div>
                </template>
              </el-table-column>
              <el-table-column label="施肥日期" prop="baseFertilizer.fertilizationDate" width="150" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'fertilizationDate')"></el-table-column>
              <el-table-column label="商用有机肥">
                <el-table-column label="用量" prop="baseFertilizer.businessOrganicFertilizerDosage" width="100" sortable></el-table-column>
                <el-table-column width="120" prop="baseFertilizer.businessOrganicFertilizerPrice" sortable>
                  <template slot="header">价格<br />（元/吨）</template>
                  <template slot-scope="scope">
                    <div>{{ scope.row.baseFertilizer.businessOrganicFertilizerPrice }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="农家肥1（干重）">
                <el-table-column label="名称" width="100" prop="baseFertilizer.organicFertilizer1Name" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'organicFertilizer1Name')"></el-table-column>
                <el-table-column label="用量" width="100" prop="baseFertilizer.organicFertilizer1Dosage" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="农家肥2（干重）">
                <el-table-column label="名称" width="100" prop="baseFertilizer.organicFertilizer2Name" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'organicFertilizer2Name')"></el-table-column>
                <el-table-column label="用量" width="100" prop="baseFertilizer.organicFertilizer2Dosage" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="尿素" width="100" prop="baseFertilizer.ureaDosage" sortable></el-table-column>
              <el-table-column label="碳铵" width="100" prop="baseFertilizer.ammoniumBicarbonateDosage" sortable></el-table-column>
              <el-table-column label="磷酸二铵" width="100" prop="baseFertilizer.diammoniumPhosphateDosage" sortable></el-table-column>
              <el-table-column label="氯化钾" width="100" prop="baseFertilizer.potassiumChlorideDosage" sortable></el-table-column>
              <el-table-column label="硫酸钾" width="100" prop="baseFertilizer.potassiumSulphateDosage" sortable></el-table-column>
              <el-table-column label="复合肥（含配方肥）及养分%">
                <el-table-column label="用量" width="100" prop="baseFertilizer.compoundFertilizerDosage" sortable></el-table-column>
                <el-table-column label="N" width="100" prop="baseFertilizer.compoundFertilizerN" sortable></el-table-column>
                <el-table-column label="P2O5" width="100" prop="baseFertilizer.compoundFertilizerP2O5" sortable></el-table-column>
                <el-table-column label="K2O" width="100" prop="baseFertilizer.compoundFertilizerK2O" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="水溶肥及养分%">
                <el-table-column label="用量" width="100" prop="baseFertilizer.waterSolubleFertilizerDosage" sortable></el-table-column>
                <el-table-column label="N" width="100" prop="baseFertilizer.waterSolubleFertilizerN" sortable></el-table-column>
                <el-table-column label="P2O5" width="100" prop="baseFertilizer.waterSolubleFertilizerP2O5" sortable></el-table-column>
                <el-table-column label="K2O" width="100" prop="baseFertilizer.waterSolubleFertilizerK2O" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="其他肥料1">
                <el-table-column label="名称" width="100" prop="baseFertilizer.otherFertilizer1Name" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'otherFertilizer1Name')"></el-table-column>
                <el-table-column label="用量" width="100" prop="baseFertilizer.otherFertilizer1Dosage" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="其他肥料2">
                <el-table-column label="名称" width="100" prop="baseFertilizer.otherFertilizer2Name" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'otherFertilizer2Name')"></el-table-column>
                <el-table-column label="用量" width="100" prop="baseFertilizer.otherFertilizer2Dosage" sortable></el-table-column>
              </el-table-column>
            </el-table-column>
            <!-- 底肥 -->
            <el-table-column width="120" align="center" prop="plantCrop.afterFertilizerFillInModeName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'afterFertilizerFillInModeName')">
              <template slot="header">追肥是否<br />分次填报</template>
              <template slot-scope="scope">
                <div>{{ scope.row.plantCrop.afterFertilizerFillInModeName }}</div>
              </template>
            </el-table-column>
            <!-- 追肥是否分次填报 -->
            <el-table-column label="追肥合计（公斤/亩）">
              <el-table-column label="主要施肥方式" width="150" prop="afterFertilizerTotal.fertilizationModeName" sortable :sort-method="(a, b) => sortAfterFertilizerTotalColumn(a, b, 'fertilizationModeName')"></el-table-column>
              <el-table-column label="追肥次数" width="150" prop="afterFertilizerTotal.afterFertilizationTimes" sortable></el-table-column>
              <el-table-column label="首次追肥日期" width="150" prop="afterFertilizerTotal.afterFertilizationDate" sortable :sort-method="(a, b) => sortAfterFertilizerTotalColumn(a, b, 'afterFertilizationDate')"></el-table-column>
              <el-table-column label="商品有机肥用量" width="150" prop="afterFertilizerTotal.businessOrganicFertilizerDosage" sortable></el-table-column>
              <el-table-column label="尿素" width="100" prop="afterFertilizerTotal.ureaDosage" sortable></el-table-column>
              <el-table-column label="碳铵" width="100" prop="afterFertilizerTotal.ammoniumBicarbonateDosage" sortable></el-table-column>
              <el-table-column label="磷酸二铵" width="120" prop="afterFertilizerTotal.diammoniumPhosphateDosage" sortable></el-table-column>
              <el-table-column label="氯化钾" width="100" prop="afterFertilizerTotal.potassiumChlorideDosage" sortable></el-table-column>
              <el-table-column label="硫酸钾" width="100" prop="afterFertilizerTotal.potassiumSulphateDosage" sortable></el-table-column>
              <el-table-column label="复合肥（含配方肥）及养分%">
                <el-table-column label="用量" width="100" prop="afterFertilizerTotal.compoundFertilizerDosage" sortable></el-table-column>
                <el-table-column label="N" width="100" prop="afterFertilizerTotal.compoundFertilizerN" sortable></el-table-column>
                <el-table-column label="P2O5" width="100" prop="afterFertilizerTotal.compoundFertilizerP2O5" sortable></el-table-column>
                <el-table-column label="K2O" width="100" prop="afterFertilizerTotal.compoundFertilizerK2O" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="水溶肥及养分%">
                <el-table-column label="用量" width="100" prop="afterFertilizerTotal.waterSolubleFertilizerDosage" sortable></el-table-column>
                <el-table-column label="N" width="100" prop="afterFertilizerTotal.waterSolubleFertilizerN" sortable></el-table-column>
                <el-table-column label="P2O5" width="100" prop="afterFertilizerTotal.waterSolubleFertilizerP2O5" sortable></el-table-column>
                <el-table-column label="K2O" width="100" prop="afterFertilizerTotal.waterSolubleFertilizerK2O" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="其他肥料1">
                <el-table-column label="名称" width="120" prop="afterFertilizerTotal.otherFertilizer1Name" sortable :sort-method="(a, b) => sortAfterFertilizerTotalColumn(a, b, 'otherFertilizer1Name')"></el-table-column>
                <el-table-column label="用量" width="100" prop="afterFertilizerTotal.otherFertilizer1Dosage" sortable></el-table-column>
              </el-table-column>
              <el-table-column label="其他肥料2">
                <el-table-column label="名称" width="120" prop="afterFertilizerTotal.otherFertilizer2Name" sortable :sort-method="(a, b) => sortAfterFertilizerTotalColumn(a, b, 'otherFertilizer2Name')"></el-table-column>
                <el-table-column label="用量" width="100" prop="afterFertilizerTotal.otherFertilizer2Dosage" sortable></el-table-column>
              </el-table-column>
            </el-table-column>
            <!-- 追肥合计 -->
            <el-table-column label="追肥分次">
              <el-table-column v-for="(number, index) in [1, 2, 3, 4, 5]" :label="'追肥' + number + '（公斤/亩）'" :key="index">
                <el-table-column label="施肥方式" width="120" :prop="'afterFertilizerList.' + index + '.fertilizationModeName'" sortable :sort-method="(a, b) => sortAfterFertilizerColumn(a, b, index, 'fertilizationModeName')">
                  <template slot-scope="scope">
                    <div>{{ scope.row.afterFertilizerList[index].fertilizationModeName }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="追肥日期" width="150" :prop="'afterFertilizerList.' + index + '.afterFertilizationDate'" sortable :sort-method="(a, b) => sortAfterFertilizerColumn(a, b, index, 'afterFertilizationDate')">
                  <template slot-scope="scope">
                    <div>{{ scope.row.afterFertilizerList[index].afterFertilizationDate }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="商品有机肥用量" width="120" :prop="'afterFertilizerList.' + index + '.businessOrganicFertilizerDosage'" sortable>
                  <template slot-scope="scope">
                    <div>{{ scope.row.afterFertilizerList[index].businessOrganicFertilizerDosage }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="尿素" width="100" :prop="'afterFertilizerList.' + index + '.ureaDosage'" sortable>
                  <template slot-scope="scope">
                    <div>{{ scope.row.afterFertilizerList[index].ureaDosage }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="碳铵" width="100" :prop="'afterFertilizerList.' + index + '.ammoniumBicarbonateDosage'" sortable>
                  <template slot-scope="scope">
                    <div>{{ scope.row.afterFertilizerList[index].ammoniumBicarbonateDosage }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="磷酸二铵" width="100" :prop="'afterFertilizerList.' + index + '.diammoniumPhosphateDosage'" sortable>
                  <template slot-scope="scope">
                    <div>{{ scope.row.afterFertilizerList[index].diammoniumPhosphateDosage }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="氯化钾" width="100" :prop="'afterFertilizerList.' + index + '.potassiumChlorideDosage'" sortable>
                  <template slot-scope="scope">
                    <div>{{ scope.row.afterFertilizerList[index].potassiumChlorideDosage }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="硫酸钾" width="100" :prop="'afterFertilizerList.' + index + '.potassiumSulphateDosage'" sortable>
                  <template slot-scope="scope">
                    <div>{{ scope.row.afterFertilizerList[index].potassiumSulphateDosage }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="复合肥（含配方肥）及养分%">
                  <el-table-column label="用量" width="100" :prop="'afterFertilizerList.' + index + '.compoundFertilizerDosage'" sortable>
                    <template slot-scope="scope">
                      <div>{{ scope.row.afterFertilizerList[index].compoundFertilizerDosage }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="N" width="100" :prop="'afterFertilizerList.' + index + '.compoundFertilizerN'" sortable>
                    <template slot-scope="scope">
                      <div>{{ scope.row.afterFertilizerList[index].compoundFertilizerN }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="P2O5" width="100" :prop="'afterFertilizerList.' + index + '.compoundFertilizerP2O5'" sortable>
                    <template slot-scope="scope">
                      <div>{{ scope.row.afterFertilizerList[index].compoundFertilizerP2O5 }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="K2O" width="100" :prop="'afterFertilizerList.' + index + '.compoundFertilizerK2O'" sortable>
                    <template slot-scope="scope">
                      <div>{{ scope.row.afterFertilizerList[index].compoundFertilizerK2O }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="水溶肥及养分%">
                  <el-table-column label="用量" width="100" :prop="'afterFertilizerList.' + index + '.waterSolubleFertilizerDosage'" sortable>
                    <template slot-scope="scope">
                      <div>{{ scope.row.afterFertilizerList[index].waterSolubleFertilizerDosage }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="N" width="100" :prop="'afterFertilizerList.' + index + '.waterSolubleFertilizerN'" sortable>
                    <template slot-scope="scope">
                      <div>{{ scope.row.afterFertilizerList[index].waterSolubleFertilizerN }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="P2O5" width="100" :prop="'afterFertilizerList.' + index + '.waterSolubleFertilizerP2O5'" sortable>
                    <template slot-scope="scope">
                      <div>{{ scope.row.afterFertilizerList[index].waterSolubleFertilizerP2O5 }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="K2O" width="100" :prop="'afterFertilizerList.' + index + '.waterSolubleFertilizerK2O'" sortable>
                    <template slot-scope="scope">
                      <div>{{ scope.row.afterFertilizerList[index].waterSolubleFertilizerK2O }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="其他肥料1">
                  <el-table-column label="名称" width="120" :prop="'afterFertilizerList.' + index + '.otherFertilizer1Name'" sortable :sort-method="(a, b) => sortAfterFertilizerColumn(a, b, index, 'otherFertilizer1Name')">
                    <template slot-scope="scope">
                      <div>{{ scope.row.afterFertilizerList[index].otherFertilizer1Name }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="用量" width="100" :prop="'afterFertilizerList.' + index + '.otherFertilizer1Dosage'" sortable>
                    <template slot-scope="scope">
                      <div>{{ scope.row.afterFertilizerList[index].otherFertilizer1Dosage }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="其他肥料2">
                  <el-table-column label="名称" width="120" :prop="'afterFertilizerList.' + index + '.otherFertilizer2Name'" sortable :sort-method="(a, b) => sortAfterFertilizerColumn(a, b, index, 'otherFertilizer2Name')">
                    <template slot-scope="scope">
                      <div>{{ scope.row.afterFertilizerList[index].otherFertilizer2Name }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="用量" width="100" :prop="'afterFertilizerList.' + index + '.otherFertilizer2Dosage'" sortable>
                    <template slot-scope="scope">
                      <div>{{ scope.row.afterFertilizerList[index].otherFertilizer2Dosage }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <!-- 追肥 1 ~ 5 -->
            <el-table-column label="记录情况" width="260">
              <template slot-scope="scope">
                <el-popover placement="left-start" width="260" trigger="hover">
                  <ul class="table-column-record">
                    <li>创建时间：{{ scope.row.plantCrop.createdOn }}</li>
                    <li>修改时间：{{ scope.row.plantCrop.modifiedOn }}</li>
                  </ul>
                  <i class="el-icon-view" slot="reference"></i>
                </el-popover>
                &nbsp;&nbsp;
                {{ scope.row.plantCrop.createdOn }}
              </template>
            </el-table-column>
            <!-- 记录情况 -->
          </el-table>
        </div>
        <div class="wg-pagination-box">
          <el-pagination background layout="total, sizes, prev, pager, next" :total="recordTotal" :page-sizes="[30, 50, 100]" :page-size="pageSize" :current-page="pageIndex" @size-change="handleSizeChange" @current-change="handlePagingChange"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArchivedFarmerPlantCropApi from '@/api/data-fill-in/archive/archived-farmer-plant-crop-api'
import ArchivedPlantCropApi from '@/api/data-fill-in/archive/archived-plant-crop-api'
import FileApi from '@/api/file-api'

import Screenfull from 'screenfull'

export default {
  name: 'ArchivedUnsubmittedPlantCropData',
  data() {
    return {
      tableHeight: 600,
      isFullscreen: false,
      dataLoading: false,
      params: {
        year: null,
        regionId: null
      },
      filterParams: {
        keyword: null
      },
      columnFilterData: {
        cropOptionList: []
      },
      tableData: [],
      recordTotal: 0,
      pageSize: 30,
      pageIndex: 1,
      selectedRows: []
    }
  },
  mounted() {
    let that = this
    Screenfull.on('change', function () {
      that.$nextTick(() => {
        that.isFullscreen = Screenfull.isFullscreen
        that.tableHeight = Screenfull.isFullscreen ? window.innerHeight - 160 : '600px'
      })
    })
  },
  destroy() {
    if (Screenfull.enabled) {
      Screenfull.off('change', this.change)
    }
  },
  methods: {
    handleDeleteSelected() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      let theObj = this
      this.$confirm('此操作将删除该数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          for (let i = 0; i < theObj.selectedRows.length; i++) {
            await theObj.delete(theObj.selectedRows[i])
          }
          theObj.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    handleExportData() {
      this.$confirm('准备导出数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let exportId = null
          let exportCount = 0
          let finished = false

          while (!finished) {
            const loading = this.$loading({
              lock: true,
              text: 0 == exportCount ? '正在导出数据......' : '已导出' + exportCount + '条数据......',
              spinner: 'el-icon-loading'
            })

            await ArchivedFarmerPlantCropApi.exportData(exportId, this.params.year, this.params.regionId, true, 'UNSUBMITTED', this.filterParams.keyword)
              .then(res => {
                exportId = res.data.id
                exportCount = res.data.exportCount
                finished = res.data.finished
                loading.close()
              })
              .catch(err => {
                loading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          }

          const loading = this.$loading({
            lock: true,
            text: '等待下载数据文件......',
            spinner: 'el-icon-loading'
          })

          await FileApi.get('TEMP', exportId + '.xlsx')
            .then(res => {
              let byteBuffer = window.atob(res.data)
              let arrayBuffer = new ArrayBuffer(byteBuffer.length)
              let dataBuffer = new Uint8Array(arrayBuffer)
              for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

              let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(blob)
              link.setAttribute('download', '数据文件.xlsx')
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(link.href)
              document.body.removeChild(link)
              loading.close()
            })
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })

          await FileApi.delete('TEMP', exportId + '.xlsx')
            .then(() => {})
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
        })
    },
    handleKeywordChange() {
      this.pageIndex = 1
      this.bindTableData()
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows
    },
    handleFullscreen() {
      if (!Screenfull.isEnabled) {
        this.$message({
          message: '浏览器不支持',
          type: 'warning'
        })
        return false
      }

      Screenfull.request(this.$refs.archivedUnsubmittedPlantCropDataPane)
    },
    handleExtFullscreen() {
      if (!Screenfull.isEnabled) {
        this.$message({
          message: '浏览器不支持',
          type: 'warning'
        })
        return false
      }

      Screenfull.exit()
    },
    handleSizeChange(pageSize) {
      this.pageIndex = 1
      this.pageSize = pageSize
      this.bindTableData()
    },
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
      this.bindTableData()
    },
    async delete(row) {
      await ArchivedPlantCropApi.deleteById(row.plantCrop.id)
        .then(() => {
          this.$message({ showClose: true, message: '记录【' + row.index + '】删除成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    bindTableData() {
      this.dataLoading = true
      ArchivedFarmerPlantCropApi.getPaging(this.params.year, this.params.regionId, true, 'UNSUBMITTED', this.filterParams.keyword, null, this.pageSize, this.pageIndex)
        .then(res => {
          let index = 0
          this.tableData = []
          this.recordTotal = res.data.totalCount
          let cropArray = []
          this.columnFilterData.cropOptionList = []

          res.data.fullPlantCropList.forEach(el => {
            if (undefined === cropArray[el.plantCrop.cropName]) cropArray[el.plantCrop.cropName] = el.plantCrop.cropName

            let row = {
              index: ++index,
              farmer: this.mappingFarmer(el.user),
              monitoringPlot: this.mappingMonitoringPlot(el.monitoringPlot),
              plantCrop: this.mappingPlantCrop(el.plantCrop),
              baseFertilizer: this.mappingBaseFertilizer(el.baseFertilizer),
              afterFertilizerTotal: this.mappingAfterFertilizerTotal(el.afterFertilizerTotal),
              afterFertilizerList: []
            }
            el.afterFertilizerList.forEach(elAfterFertilizer => {
              row.afterFertilizerList.push(this.mappingAfterFertilizer(elAfterFertilizer))
            })
            this.tableData.push(row)
          })

          for (let key in cropArray) {
            this.columnFilterData.cropOptionList.push({ text: cropArray[key], value: key })
          }
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
        .finally(() => {
          this.dataLoading = false
        })
    },
    load(year, regionId) {
      this.params.year = year
      this.params.regionId = regionId
      this.bindTableData()
    },
    sortFarmerColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.farmer[fieldName], row2.farmer[fieldName])
    },
    sortMonitoringPlotColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.monitoringPlot[fieldName], row2.monitoringPlot[fieldName])
    },
    sortPlantCropColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.plantCrop[fieldName], row2.plantCrop[fieldName])
    },
    sortBaseFertilizerColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.baseFertilizer[fieldName], row2.baseFertilizer[fieldName])
    },
    sortAfterFertilizerTotalColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.afterFertilizerTotal[fieldName], row2.afterFertilizerTotal[fieldName])
    },
    sortAfterFertilizerColumn(row1, row2, index, fieldName) {
      return this.$utils.sortColumn(row1.afterFertilizerList[index][fieldName], row2.afterFertilizerList[index][fieldName])
    },
    mappingFarmer(model) {
      let farmer = {}
      farmer.username = model.username
      farmer.provinceName = model.provinceName
      farmer.cityName = model.cityName
      farmer.districtName = model.districtName
      farmer.detailedAddress = model.detailedAddress
      farmer.fullName = model.fullName
      farmer.phoneNumber = model.phoneNumber
      farmer.farmerNatureName = model.farmerNatureName
      farmer.educationalLevelName = model.educationalLevelName
      farmer.cultivationLandArea = model.cultivationLandArea
      farmer.modelHouseholdFlag = model.modelHouseholdFlag
      return farmer
    },
    mappingMonitoringPlot(model) {
      let monitoringPlot = {}
      monitoringPlot.name = model.name
      monitoringPlot.code = model.code
      monitoringPlot.plotArea = model.plotArea
      return monitoringPlot
    },
    mappingPlantCrop(model) {
      let plantCrop = {}
      plantCrop.id = model.id
      plantCrop.cropSubCategoryName = model.cropSubCategoryName
      plantCrop.cropName = model.cropName
      plantCrop.cropRemark = model.cropRemark
      plantCrop.sowingDate = this.$utils.isEmpty(model.sowingDate) ? null : this.$moment(model.sowingDate).format('YYYY-MM-DD')
      plantCrop.harvestTime = this.$utils.isEmpty(model.harvestTime) ? null : this.$moment(model.harvestTime).format('YYYY-MM-DD')
      plantCrop.sowingArea = model.sowingArea
      plantCrop.plotFertilityName = model.plotFertilityName
      plantCrop.yield = model.yield
      plantCrop.yieldLevelName = model.yieldLevelName
      plantCrop.facilityCultivationFlag = model.facilityCultivationFlag
      plantCrop.happenDisasterFlag = model.happenDisasterFlag
      plantCrop.previousCropSubCategoryName = this.$utils.isEmpty(model.previousCropSubCategoryName) ? '无' : model.previousCropSubCategoryName
      plantCrop.previousYield = model.previousYield
      plantCrop.previousStrawReturnRate = model.previousStrawReturnRate
      plantCrop.afterFertilizerFillInMode = model.afterFertilizerFillInMode
      plantCrop.afterFertilizerFillInModeName = model.afterFertilizerFillInModeName
      plantCrop.createdOn = model.createdOn
      plantCrop.createdByFullName = model.createdByFullName
      plantCrop.modifiedOn = model.modifiedOn
      plantCrop.modifiedByFullName = model.modifiedByFullName
      return plantCrop
    },
    mappingBaseFertilizer(model) {
      let baseFertilizer = {}
      baseFertilizer.fertilizationDate = this.$utils.isEmpty(model.fertilizationDate) ? null : this.$moment(model.fertilizationDate).format('YYYY-MM-DD')
      baseFertilizer.businessOrganicFertilizerDosage = model.businessOrganicFertilizerDosage
      baseFertilizer.businessOrganicFertilizerPrice = model.businessOrganicFertilizerPrice
      baseFertilizer.organicFertilizer1Name = model.organicFertilizer1Name
      baseFertilizer.organicFertilizer1Dosage = model.organicFertilizer1Dosage
      baseFertilizer.organicFertilizer2Name = model.organicFertilizer2Name
      baseFertilizer.organicFertilizer2Dosage = model.organicFertilizer2Dosage
      baseFertilizer.ureaDosage = model.ureaDosage
      baseFertilizer.ammoniumBicarbonateDosage = model.ammoniumBicarbonateDosage
      baseFertilizer.diammoniumPhosphateDosage = model.diammoniumPhosphateDosage
      baseFertilizer.potassiumChlorideDosage = model.potassiumChlorideDosage
      baseFertilizer.potassiumSulphateDosage = model.potassiumSulphateDosage
      baseFertilizer.compoundFertilizerDosage = model.compoundFertilizerDosage
      baseFertilizer.compoundFertilizerN = model.compoundFertilizerN
      baseFertilizer.compoundFertilizerP2O5 = model.compoundFertilizerP2O5
      baseFertilizer.compoundFertilizerK2O = model.compoundFertilizerK2O
      baseFertilizer.waterSolubleFertilizerDosage = model.waterSolubleFertilizerDosage
      baseFertilizer.waterSolubleFertilizerN = model.waterSolubleFertilizerN
      baseFertilizer.waterSolubleFertilizerP2O5 = model.waterSolubleFertilizerP2O5
      baseFertilizer.waterSolubleFertilizerK2O = model.waterSolubleFertilizerK2O
      baseFertilizer.otherFertilizer1Name = model.otherFertilizer1Name
      baseFertilizer.otherFertilizer1Dosage = model.otherFertilizer1Dosage
      baseFertilizer.otherFertilizer2Name = model.otherFertilizer2Name
      baseFertilizer.otherFertilizer2Dosage = model.otherFertilizer2Dosage
      baseFertilizer.fertilizationModeName = model.fertilizationModeName
      return baseFertilizer
    },
    mappingAfterFertilizerTotal(model) {
      let afterFertilizerTotal = {}
      afterFertilizerTotal.afterFertilizationTimes = model.afterFertilizationTimes
      afterFertilizerTotal.afterFertilizationDate = this.$utils.isEmpty(model.afterFertilizationDate) ? null : this.$moment(model.afterFertilizationDate).format('YYYY-MM-DD')
      afterFertilizerTotal.businessOrganicFertilizerDosage = model.businessOrganicFertilizerDosage
      afterFertilizerTotal.ureaDosage = model.ureaDosage
      afterFertilizerTotal.ammoniumBicarbonateDosage = model.ammoniumBicarbonateDosage
      afterFertilizerTotal.diammoniumPhosphateDosage = model.diammoniumPhosphateDosage
      afterFertilizerTotal.potassiumChlorideDosage = model.potassiumChlorideDosage
      afterFertilizerTotal.potassiumSulphateDosage = model.potassiumSulphateDosage
      afterFertilizerTotal.compoundFertilizerDosage = model.compoundFertilizerDosage
      afterFertilizerTotal.compoundFertilizerN = model.compoundFertilizerN
      afterFertilizerTotal.compoundFertilizerP2O5 = model.compoundFertilizerP2O5
      afterFertilizerTotal.compoundFertilizerK2O = model.compoundFertilizerK2O
      afterFertilizerTotal.waterSolubleFertilizerDosage = model.waterSolubleFertilizerDosage
      afterFertilizerTotal.waterSolubleFertilizerN = model.waterSolubleFertilizerN
      afterFertilizerTotal.waterSolubleFertilizerP2O5 = model.waterSolubleFertilizerP2O5
      afterFertilizerTotal.waterSolubleFertilizerK2O = model.waterSolubleFertilizerK2O
      afterFertilizerTotal.otherFertilizer1Name = model.otherFertilizer1Name
      afterFertilizerTotal.otherFertilizer1Dosage = model.otherFertilizer1Dosage
      afterFertilizerTotal.otherFertilizer2Name = model.otherFertilizer2Name
      afterFertilizerTotal.otherFertilizer2Dosage = model.otherFertilizer2Dosage
      afterFertilizerTotal.fertilizationModeName = model.fertilizationModeName
      return afterFertilizerTotal
    },
    mappingAfterFertilizer(model) {
      let afterFertilizer = {}
      afterFertilizer.afterFertilizationDate = this.$utils.isEmpty(model.afterFertilizationDate) ? null : this.$moment(model.afterFertilizationDate).format('YYYY-MM-DD')
      afterFertilizer.businessOrganicFertilizerDosage = model.businessOrganicFertilizerDosage
      afterFertilizer.ureaDosage = model.ureaDosage
      afterFertilizer.ammoniumBicarbonateDosage = model.ammoniumBicarbonateDosage
      afterFertilizer.diammoniumPhosphateDosage = model.diammoniumPhosphateDosage
      afterFertilizer.potassiumChlorideDosage = model.potassiumChlorideDosage
      afterFertilizer.potassiumSulphateDosage = model.potassiumSulphateDosage
      afterFertilizer.compoundFertilizerDosage = model.compoundFertilizerDosage
      afterFertilizer.compoundFertilizerN = model.compoundFertilizerN
      afterFertilizer.compoundFertilizerP2O5 = model.compoundFertilizerP2O5
      afterFertilizer.compoundFertilizerK2O = model.compoundFertilizerK2O
      afterFertilizer.waterSolubleFertilizerDosage = model.waterSolubleFertilizerDosage
      afterFertilizer.waterSolubleFertilizerN = model.waterSolubleFertilizerN
      afterFertilizer.waterSolubleFertilizerP2O5 = model.waterSolubleFertilizerP2O5
      afterFertilizer.waterSolubleFertilizerK2O = model.waterSolubleFertilizerK2O
      afterFertilizer.otherFertilizer1Name = model.otherFertilizer1Name
      afterFertilizer.otherFertilizer1Dosage = model.otherFertilizer1Dosage
      afterFertilizer.otherFertilizer2Name = model.otherFertilizer2Name
      afterFertilizer.otherFertilizer2Dosage = model.otherFertilizer2Dosage
      afterFertilizer.fertilizationModeName = model.fertilizationModeName
      return afterFertilizer
    }
  }
}
</script>

<style scoped></style>
