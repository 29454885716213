<template>
  <div>
    <div class="body" style="padding-top: 0; background-color: #ffffff;">
      <el-form :inline="true" :model="searchForm" size="small" label-width="100px">
        <el-form-item label="选择省份：" v-if="flags.provinceSelectShow">
          <el-select v-model="searchForm.provinceId" filterable @change="handleProvinceSelect">
            <el-option v-for="option in filterData.provinceOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择地市：" v-if="flags.citySelectShow">
          <el-select v-model="searchForm.cityId" filterable @change="handleCitySelect">
            <el-option v-for="option in filterData.cityOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择区县：" v-if="flags.districtSelectShow">
          <el-select v-model="searchForm.districtId" filterable @change="handleDistrictSelect">
            <el-option v-for="option in filterData.districtOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择作物：">
          <el-select name="cropIds" v-model="searchForm.cropIds" value-key="value" multiple collapse-tags>
            <el-option v-for="option in filterData.cropOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择等级：" v-if="flags.ownerCategoryShow">
          <el-select v-model="searchForm.ownerCategoryId" style="display: block;">
            <el-option v-for="option in filterData.ownerCategoryOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择年份：">
          <el-date-picker v-model="searchForm.searchYear" type="year" format="yyyy年" value-format="yyyy" suffix-icon="el-icon-date" :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="wg-button" @click="handleSearch">查询</el-button>
          <el-button class="wg-button blank" @click="handleReset">重置</el-button>
          <el-button class="wg-button blank" @click="handleExport">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="wg-box">
      <div class="body">
        <IntensityAnnual ref="intensityannual" :params="searchData"></IntensityAnnual>
        <div class='chart-divider'></div>
        <ProductivityAnnual ref="productivityannual" :params="searchData"></ProductivityAnnual>
        <div class='chart-divider'></div>
        <OrganicApplicationAmountAnnual ref="organicapplicationamountannual" :params="searchData"></OrganicApplicationAmountAnnual>
        <div class='chart-divider'></div>
        <ChemicalApplicationAmountAnnual ref="chemicalapplicationamountannual" :params="searchData"></ChemicalApplicationAmountAnnual>
        <div class='chart-divider'></div>
        <PartialFactorProductivityAnnual ref="partialfactorproductivityannual" :params="searchData"></PartialFactorProductivityAnnual>
        <div class='chart-divider'></div>
        <AreaAndCoverageAnnual ref="areaandcoverageannual" :params="searchData"></AreaAndCoverageAnnual>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment'
import FillInCycleApi from '@/api/data-fill-in/cycle/fill-in-cycle-api'
import RegionApi from '@/api/region-api'
import CropApi from '@/api/crop-api'
import PartialFactorProductivityAnnual from '@/views/chart-analysis/components/annual/PartialFactorProductivityAnnual.vue'
import IntensityAnnual from '@/views/chart-analysis/components/annual/IntensityAnnual.vue'
import AreaAndCoverageAnnual from '@/views/chart-analysis/components/annual/AreaAndCoverageAnnual.vue'
import OrganicApplicationAmountAnnual from '@/views/chart-analysis/components/annual/OrganicApplicationAmountAnnual.vue'
import ChemicalApplicationAmountAnnual from '@/views/chart-analysis/components/annual/ChemicalApplicationAmountAnnual.vue'
import ProductivityAnnual from '@/views/chart-analysis/components/annual/ProductivityAnnual.vue'
import AnnualAnalysisApi from '@/api/chart-analysis/annual-analysis-api'
import FileApi from '@/api/file-api'

export default {
  name: 'AnnualCropSearchForm',
  components: {
    ProductivityAnnual,
    ChemicalApplicationAmountAnnual,
    OrganicApplicationAmountAnnual,
    AreaAndCoverageAnnual,
    IntensityAnnual,
    PartialFactorProductivityAnnual
  },
  data() {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          let years = this.filterData.yearRange
          return time.getFullYear() < Number(years[0]) || time.getFullYear() > Number(years[1])
        },
      },
      flags: {
        provinceSelectShow: false,
        citySelectShow: false,
        districtSelectShow: false,
        cropSelectShow: true,
        yearRangeSelectShow: true,
        ownerCategoryShow: false
      },
      filterData: {
        provinceOptionList: [],
        cityOptionList: [],
        districtOptionList: [],
        cropOptionList: [],
        ownerCategoryOptionList: [],
        yearRange: [(moment().year() - 1).toString(), moment().year().toString()]
      },
      searchForm: {
        groupType: 'crops',
        provinceId: null,
        cityId: null,
        districtId: null,
        cropIds: [],
        ownerCategoryId: null,
        searchYear: moment().year().toString(),
      },
      searchData: {
        groupType: 'crops',
        provinceName: null,
        cityName: null,
        districtName: null,
        cropNames: [],
        ownerCategoryName: null,
        searchYear: moment().year().toString(),
      }
    }
  },
  created() {
    this.resetFilters()
  },
  methods: {
    bindYears() {
      let years = []
      FillInCycleApi.getList().then(res => {
        res.data.map(v => {
          years.push(v.year)
        })
        if(years.length >= 2) {
          let arr = years.sort((a, b) => a - b)
          this.filterData.yearRange = [arr[0].toString(), arr[years.length-1].toString()]
        } else {
          this.filterData.yearRange = [years[0].toString(), years[0].toString()]
        }
        this.searchForm.searchYear = this.filterData.yearRange[1].toString()
      })
    },
    initRegionDrops() {
      let userLevelId = sessionStorage.getItem('levelId')
      let provinceId = sessionStorage.getItem('provinceId')
      let provinceName = sessionStorage.getItem('provinceName')
      let cityId = sessionStorage.getItem('cityId')
      let cityName = sessionStorage.getItem('cityName')
      let districtId = sessionStorage.getItem('districtId')
      let districtName = sessionStorage.getItem('districtName')

      if ('Country' === userLevelId) {
        this.flags.provinceSelectShow = true
        this.flags.citySelectShow = true
        this.flags.districtSelectShow = true

        // 只有国家级管理员才出现专业用户
        this.flags.ownerCategoryShow = true
        this.filterData.ownerCategoryOptionList.push({ text: '全部', value: null })
        this.filterData.ownerCategoryOptionList.push({ text: '农户', value: 'FARMER' })
        this.filterData.ownerCategoryOptionList.push({ text: '专业用户', value: 'PROFESSIONAL' })

        this.filterData.provinceOptionList.push({ text: '全部', value: null })
        this.filterData.cityOptionList.push({ text: '全部', value: null })
        this.filterData.districtOptionList.push({ text: '全部', value: null })
        RegionApi.getProvinceList().then(res => {
          res.data.forEach(el => {
            this.filterData.provinceOptionList.push({ text: el.name, value: el.id })
          })
        })
      }

      if ('Province' === userLevelId) {
        this.flags.provinceSelectShow = false
        this.flags.citySelectShow = true
        this.flags.districtSelectShow = true

        // 只有国家级管理员才出现专业用户
        this.flags.ownerCategoryShow = false

        this.filterData.provinceOptionList.push({ text: provinceName, value: provinceId })
        this.filterData.cityOptionList.push({ text: '全部', value: null })
        this.filterData.districtOptionList.push({ text: '全部', value: null })

        RegionApi.getCityList(provinceId).then(res => {
          res.data.forEach(el => {
            this.filterData.cityOptionList.push({ text: el.name, value: el.id })
          })

          this.searchForm.cityName = '全部'
          this.searchForm.districtName = '全部'
        })
      }

      if ('City' === userLevelId) {
        this.flags.provinceSelectShow = false
        this.flags.citySelectShow = false
        this.flags.districtSelectShow = true

        // 只有国家级管理员才出现专业用户
        this.flags.ownerCategoryShow = false

        this.searchForm.provinceName = provinceName
        this.searchForm.cityName = cityName

        this.filterData.provinceOptionList.push({ text: provinceName, value: provinceId })
        this.filterData.cityOptionList.push({ text: cityName, value: cityId })

        this.filterData.districtOptionList.push({ text: '全部', value: null })
        RegionApi.getDistrictList(cityId).then(res => {
          res.data.forEach(el => {
            this.filterData.districtOptionList.push({ text: el.name, value: el.id })
          })

          this.searchForm.districtName = '全部'
        })
      }
      if ('District' === userLevelId) {
        this.flags.provinceSelectShow = false
        this.flags.citySelectShow = false
        this.flags.districtSelectShow = false

        // 只有国家级管理员才出现专业用户
        this.flags.ownerCategoryShow = false

        this.searchForm.provinceName = provinceName
        this.searchForm.cityName = cityName
        this.searchForm.districtName = districtName

        this.filterData.provinceOptionList.push({ text: provinceName, value: provinceId })
        this.filterData.cityOptionList.push({ text: cityName, value: cityId })
        this.filterData.districtOptionList.push({ text: districtName, value: districtId })
      }
    },
    initCropDrops() {
      this.filterData.cropOptionList = []
      CropApi.getStubList().then(res => {
        res.data.forEach(el => {
          this.filterData.cropOptionList.push({ text: el.name, value: el.id })
        })
      })
    },
    handleProvinceSelect(selectedValue) {

      this.searchForm.cityId = null
      this.searchForm.cityName = null
      this.searchForm.districtId = null
      this.searchForm.districtName = null
      this.filterData.cityOptionList = []
      this.filterData.cityOptionList.push({ text: '全部', value: null })
      this.filterData.districtOptionList = []
      this.filterData.districtOptionList.push({ text: '全部', value: null })

      this.searchForm.provinceId = selectedValue
      if (null === selectedValue) return
      RegionApi.getCityList(this.searchForm.provinceId).then(res => {
        res.data.forEach(el => {
          this.filterData.cityOptionList.push({ text: el.name, value: el.id })
        })
      })
    },
    handleCitySelect(selectedValue) {
      this.searchForm.districtId = null
      this.searchForm.districtName = null
      this.filterData.districtOptionList = []
      this.filterData.districtOptionList.push({ text: '全部', value: null })
      if (null === selectedValue) return

      this.searchForm.cityId = selectedValue
      RegionApi.getDistrictList(this.searchForm.cityId).then(res => {
        res.data.forEach(el => {
          this.filterData.districtOptionList.push({ text: el.name, value: el.id })
        })
      })
    },
    handleDistrictSelect(selectedValue) {
      if (null === selectedValue) return
      this.searchForm.districtId = selectedValue
    },
    initSearchData() {
      let userLevelId = sessionStorage.getItem('levelId')

      if ('Country' === userLevelId) {
        this.searchData.provinceName = this.filterData.provinceOptionList.find(p => p.value === this.searchForm.provinceId).text
        this.searchData.cityName = this.filterData.cityOptionList.find(p => p.value === this.searchForm.cityId).text
        this.searchData.districtName = this.filterData.districtOptionList.find(p => p.value === this.searchForm.districtId).text

        if(this.searchForm.ownerCategoryId === null) {
          this.searchData.ownerCategoryName = null
        }
      }
      if ('Province' === userLevelId) {
        this.searchData.provinceName = sessionStorage.getItem('provinceName')
        this.searchData.cityName = this.filterData.cityOptionList.find(p => p.value === this.searchForm.cityId).text
        this.searchData.districtName = this.filterData.districtOptionList.find(p => p.value === this.searchForm.districtId).text
        this.searchData.ownerCategoryName = '农户'
      }
      if ('City' === userLevelId) {
        this.searchData.provinceName = sessionStorage.getItem('provinceName')
        this.searchData.cityName = sessionStorage.getItem('cityName')
        this.searchData.districtName = this.filterData.districtOptionList.find(p => p.value === this.searchForm.districtId).text
        this.searchData.ownerCategoryName = '农户'
      }
      if ('District' === userLevelId) {
        this.searchData.provinceName = sessionStorage.getItem('provinceName')
        this.searchData.cityName = sessionStorage.getItem('cityName')
        this.searchData.districtName = sessionStorage.getItem('districtName')
        this.searchData.ownerCategoryName = '农户'
      }
      if(this.searchData.provinceName === '全部') {
        this.searchData.provinceName = null
      }
      if(this.searchData.cityName === '全部') {
        this.searchData.cityName = null
      }
      if(this.searchData.districtName === '全部') {
        this.searchData.districtName = null
      }
      if(this.searchForm.cropIds.length === 0) {
        this.searchData.cropNames = []
      } else {
        this.searchData.cropNames = []
        this.filterData.cropOptionList.filter(p => this.searchForm.cropIds.some(t => t === p.value)).forEach(p => this.searchData.cropNames.push(p.text))
      }
      this.searchData.searchYear = this.searchForm.searchYear
    },
    handleSearch() {
      this.initSearchData()
      this.$nextTick(() => {
        this.$refs.intensityannual.refresh()
        this.$refs.productivityannual.refresh()
        this.$refs.organicapplicationamountannual.refresh()
        this.$refs.chemicalapplicationamountannual.refresh()
        this.$refs.partialfactorproductivityannual.refresh()
        this.$refs.areaandcoverageannual.refresh()
      })
    },
    handleExport() {
      this.initSearchData()
      this.$confirm('导出数据, 是否继续？', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
        .then(async () => {
          let exportId = null

          const loading = this.$loading({
            lock: true,
            text: '正在导出数据......',
            spinner: 'el-icon-loading'
          })

          await AnnualAnalysisApi.exportExcel(this.searchData)
            .then(res => {
              exportId = res.data.id
              loading.close()
            })
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })


          const loadingDownload = this.$loading({
            lock: true,
            text: '等待下载数据文件......',
            spinner: 'el-icon-loading'
          })

          await FileApi.get('TEMP', exportId + '.xlsx')
            .then(res => {
              let byteBuffer = window.atob(res.data)
              let arrayBuffer = new ArrayBuffer(byteBuffer.length)
              let dataBuffer = new Uint8Array(arrayBuffer)
              for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

              let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(blob)
              link.setAttribute('download', '年度分析.xlsx')
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(link.href)
              document.body.removeChild(link)
              loadingDownload.close()
            })
            .catch(err => {
              loadingDownload.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })

          await FileApi.delete('TEMP', exportId + '.xlsx')
            .then(() => {})
            .catch(err => {
              loadingDownload.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
        })
    },
    handleReset() {
      this.resetFilters()
      this.handleSearch()
    },
    resetFilters() {
      this.filterData.provinceOptionList = []
      this.filterData.cityOptionList = []
      this.filterData.districtOptionList = []
      this.filterData.cropOptionList = []

      this.filterData.ownerCategoryOptionList = []

      this.searchForm.provinceId = null
      this.searchForm.cityId = null
      this.searchForm.districtId = null
      this.searchForm.cropIds = []
      this.searchForm.ownerCategoryId = null

      this.bindYears()

      this.initRegionDrops()

      this.initCropDrops()
    },
  }
}
</script>

<style scoped>
.el-form {
  padding-top: 18px;
}
.el-card {
  border: 0;

}
.el-card ::v-deep .el-card__header {
  padding: 2px 10px;
  height: 50px;
}
.el-card ::v-deep .el-card__body {
  padding: 0px;
  background: #FFFFFF;
  border: 0;
}
</style>
