<template>
  <div ref="unsubmittedPlantCropDataPane" v-loading="dataLoading" element-loading-text="拼命加载数据......" element-loading-spinner="el-icon-loading">
    <div class="wg-box">
      <div class="header">
        <div class="button-list">
          <el-button size="medium" class="wg-button green" icon="el-icon-user" v-if="!flags.enableAdd && !flags.enableEdit" @click="handleAddUser">新增用户</el-button>
          <el-button size="medium" class="wg-button green" icon="el-icon-plus" v-if="!flags.enableAdd && !flags.enableEdit" @click="handleAdd">开始填报</el-button>
          <el-button size="medium" class="wg-button orange" icon="el-icon-upload" v-if="flags.enableAdd && !flags.enableEdit" @click="handleSave">保存</el-button>
          <el-button size="medium" class="wg-button red" icon="el-icon-close" v-if="flags.enableAdd && !flags.enableEdit" @click="handleCancel">取消</el-button>
          <el-button size="medium" class="wg-button green" icon="el-icon-edit" v-if="!flags.enableAdd && !flags.enableEdit" @click="handleEdit">编辑</el-button>
          <el-button size="medium" class="wg-button orange" icon="el-icon-close" v-if="!flags.enableAdd && flags.enableEdit" @click="handleEditEnd">结束</el-button>
          <el-button size="medium" class="wg-button red" icon="el-icon-close" v-if="!flags.enableAdd && flags.enableEdit" @click="handleEditCancel">取消</el-button>
          <el-button size="medium" class="wg-button red" icon="el-icon-delete" v-if="!flags.enableAdd && !flags.enableEdit" @click="handleDeleteSelected">删除</el-button>
          <el-button size="medium" class="wg-button orange" icon="el-icon-finished" v-if="!flags.enableAdd && !flags.enableEdit" @click="handleSubmitSelected">提交</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-download" v-if="!flags.enableAdd && !flags.enableEdit" @click="handleExportData">导出</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-upload2" v-if="!flags.enableAdd && !flags.enableEdit" @click="handleImportData">导入</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-tickets" v-if="!flags.enableAdd && !flags.enableEdit" @click="handleTemplateDownload">导入模板下载</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-full-screen" v-if="!isFullscreen" @click="handleFullscreen">全屏</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-full-screen" v-if="isFullscreen" @click="handleExtFullscreen">退出</el-button>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <div class="body">
        <el-form ref="tableForm" :model="tableForm" :rules="tableForm.formRules" :show-message="false" style="padding-top: 0">
          <div class="wg-table-box">
            <el-table ref="table" :data="tableForm.tableData" :max-height="tableHeight" row-key="plantCrop.id" class="wg-table normal" border @row-click="handleRowClick" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="50" fixed></el-table-column>
              <el-table-column label="序号" prop="index" width="100" sortable fixed></el-table-column>
              <el-table-column label="农户姓名" prop="farmer.fullName" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'fullName')" fixed>
                <template v-slot:default="scope">
                  <div v-if="scope.row.editFlag">
                    <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.farmer.fullName'" :rules="tableForm.formRules.farmer.fullName">
                      <el-input size="mini" v-model="scope.row.farmer.fullName"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.farmer.fullName }}</div>
                </template>
              </el-table-column>
              <el-table-column label="农户信息">
                <el-table-column label="省份" prop="farmer.provinceName" width="100" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'provinceName')"></el-table-column>
                <el-table-column label="地市" prop="farmer.cityName" width="100" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'cityName')"></el-table-column>
                <el-table-column label="区县" prop="farmer.districtName" width="100" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'districtName')"></el-table-column>
                <el-table-column label="详细地址" prop="farmer.detailedAddress" width="150" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'detailedAddress')">
                  <template v-slot:default="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.farmer.fullName'" :rules="tableForm.formRules.farmer.detailedAddress">
                        <el-input size="mini" v-model="scope.row.farmer.detailedAddress"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.farmer.detailedAddress }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="电话" prop="farmer.phoneNumber" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'phoneNumber')"></el-table-column>
                <el-table-column label="农户性质" prop="farmer.farmerNatureName" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'farmerNatureName')">
                  <template v-slot:default="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.farmer.farmerNature'" :rules="tableForm.formRules.farmer.farmerNature">
                        <el-select size="mini" v-model="scope.row.farmer.farmerNature">
                          <el-option v-for="option in tableForm.filterData.farmerNatureOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.farmer.farmerNatureName }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="文化程度" prop="farmer.educationalLevelName" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'educationalLevelName')">
                  <template v-slot:default="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.farmer.farmerNature'" :rules="tableForm.formRules.farmer.educationalLevel">
                        <el-select size="mini" v-model="scope.row.farmer.educationalLevel">
                          <el-option v-for="option in tableForm.filterData.educationalLevelOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.farmer.educationalLevelName }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="farmer.cultivationLandArea" width="170" sortable>
                  <template v-slot:header>
                    经营面积
                    <el-tooltip content="经营面积指农户从事农业生产总的经营面积，包括耕地、园地，主要反映农户生产规模大小。" placement="top">
                      <i class="el-icon-question" style="font-size: 18px; color:#E6A23C"></i>
                    </el-tooltip>
                    <br />（含园地，亩）
                  </template>
                  <template v-slot:default="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.farmer.cultivationLandArea'" :rules="tableForm.formRules.farmer.cultivationLandArea">
                        <el-input size="mini" v-model="scope.row.farmer.cultivationLandArea"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.farmer.cultivationLandArea }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="示范户" prop="farmer.modelHouseholdFlag" class-name="cell-tag" width="120" sortable :sort-method="(a, b) => sortFarmerColumn(a, b, 'modelHouseholdName')">
                  <template v-slot:default="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.farmer.modelHouseholdFlag'" :rules="tableForm.formRules.farmer.modelHouseholdFlag">
                        <el-select size="mini" v-model="scope.row.farmer.modelHouseholdFlag">
                          <el-option v-for="option in tableForm.filterData.modelHouseholdFlagOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else :style="null === scope.row.farmer.modelHouseholdFlag ? 'color:#333' : scope.row.farmer.modelHouseholdFlag ? 'color:#039F3A' : 'color:#FF8900'">
                      {{ scope.row.farmer.modelHouseholdName }}
                    </div>
                  </template>
                </el-table-column>
              </el-table-column>
              <!-- 农户信息 -->
              <el-table-column label="地块信息">
                <el-table-column label="地块名称" width="120" prop="monitoringPlot.name" sortable :sort-method="(a, b) => sortMonitoringPlotColumn(a, b, 'name')">
                  <template v-slot:default="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.monitoringPlot.id'" :rules="tableForm.formRules.monitoringPlot.id">
                        <el-select size="mini" v-model="scope.row.monitoringPlot.id" @change="handleRowMonitoringPlotChange($event, scope.row)">
                          <el-option v-for="option in scope.row.filterData.monitoringPlotOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.monitoringPlot.name }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="地块代码" prop="monitoringPlot.code" width="120" sortable :sort-method="(a, b) => sortMonitoringPlotColumn(a, b, 'code')"></el-table-column>
                <el-table-column prop="monitoringPlot.plotArea" width="120" sortable :sort-method="(a, b) => sortMonitoringPlotColumn(a, b, 'plotArea')">
                  <template slot="header">地块面积<br />（亩）</template>
                </el-table-column>
              </el-table-column>
              <!-- 地块信息 -->
              <el-table-column label="种植信息">
                <el-table-column label="作物分类" width="120" prop="plantCrop.cropSubCategoryId" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'cropSubCategoryName')">
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.cropSubCategoryId'" :rules="tableForm.formRules.plantCrop.cropSubCategoryId">
                        <el-select size="mini" v-model="scope.row.plantCrop.cropSubCategoryId" @change="handleCropSubCategoryChange($event, scope.row)">
                          <el-option-group v-for="group in tableForm.filterData.cropSubCategoryOptionGroupList" :key="group.label" :label="group.label">
                            <el-option v-for="option in group.optionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                          </el-option-group>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.plantCrop.cropSubCategoryName }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="作物名称" width="120" prop="plantCrop.cropName">
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.cropName'" :rules="tableForm.formRules.plantCrop.cropName">
                        <el-autocomplete size="small" v-model="scope.row.plantCrop.cropName" :fetch-suggestions="(queryString, cb) => {queryCropName(queryString, cb, scope.row)}" @select="handleCropNameSelect($event, scope.row)" @input="handleCropNameInput($event, scope.row)">
                          <template slot-scope="{item }">
                            <div>{{ item.value }}</div>
                          </template>
                        </el-autocomplete>
                      </el-form-item>
                    </div>
                    <div v-else>
                      {{ scope.row.plantCrop.cropName }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="作物备注" width="120" prop="plantCrop.cropRemark">
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.cropRemark'" :rules="tableForm.formRules.plantCrop.cropRemark">
                        <el-input size="mini" v-model="scope.row.plantCrop.cropRemark"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.plantCrop.cropRemark }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="播种日期" width="150" prop="plantCrop.sowingDate" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'sowingDate')">
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.sowingDate'" :rules="tableForm.formRules.plantCrop.sowingDate">
                        <el-date-picker size="mini" v-model="scope.row.plantCrop.sowingDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 130px"></el-date-picker>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.plantCrop.sowingDate }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="收获日期" width="150" prop="plantCrop.harvestTime" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'harvestTime')">
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.harvestTime'" :rules="tableForm.formRules.plantCrop.harvestTime">
                        <el-date-picker size="mini" v-model="scope.row.plantCrop.harvestTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 130px"></el-date-picker>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.plantCrop.harvestTime }}</div>
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="plantCrop.sowingArea" sortable>
                  <template slot="header">
                    播种面积<br />（亩）
                  </template>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.sowingArea'" :rules="tableForm.formRules.plantCrop.sowingArea">
                        <el-input size="mini" v-model="scope.row.plantCrop.sowingArea"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.plantCrop.sowingArea }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="地块肥力" width="120" prop="plantCrop.plotFertilityName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'plotFertilityName')">
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.plotFertility'" :rules="tableForm.formRules.plantCrop.plotFertility">
                        <el-select size="mini" v-model="scope.row.plantCrop.plotFertility">
                          <el-option v-for="option in tableForm.filterData.plotFertilityOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.plantCrop.plotFertilityName }}</div>
                  </template>
                </el-table-column>
                <el-table-column width="130" prop="plantCrop.yield" sortable>
                  <template slot="header">
                    亩产量<br />（公斤/亩）
                  </template>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.yield'" :rules="tableForm.formRules.plantCrop.yield">
                        <el-input size="mini" v-model="scope.row.plantCrop.yield"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.plantCrop.yield }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="产量水平" width="130" prop="plantCrop.yieldLevelName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'yieldLevelName')">
                  <template slot="header">
                    产量水平
                    <el-tooltip content="产量水平指该作物产量相对当地水平如何。" placement="top">
                      <i class="el-icon-question" style="font-size: 18px; color:#E6A23C"></i>
                    </el-tooltip>
                  </template>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.yieldLevel'" :rules="tableForm.formRules.plantCrop.yieldLevel">
                        <el-select size="mini" v-model="scope.row.plantCrop.yieldLevel">
                          <el-option v-for="option in tableForm.filterData.yieldLevelOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.plantCrop.yieldLevelName }}</div>
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="plantCrop.facilityCultivationName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'facilityCultivationName')">
                  <template slot="header">
                    是否<br />设施栽培
                  </template>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.facilityCultivationFlag'" :rules="tableForm.formRules.plantCrop.facilityCultivationFlag">
                        <el-select size="mini" v-model="scope.row.plantCrop.facilityCultivationFlag">
                          <el-option v-for="option in tableForm.filterData.facilityCultivationFlagOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else :style="null === scope.row.plantCrop.facilityCultivationFlag ? 'color:#333' : scope.row.plantCrop.facilityCultivationFlag ? 'color:#039F3A' : 'color:#FF8900'">
                      {{ scope.row.plantCrop.facilityCultivationName }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="plantCrop.happenDisasterName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'happenDisasterName')">
                  <template slot="header">
                    是否<br />种植季受灾
                  </template>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.happenDisasterFlag'" :rules="tableForm.formRules.plantCrop.happenDisasterFlag">
                        <el-select size="mini" v-model="scope.row.plantCrop.happenDisasterFlag">
                          <el-option v-for="option in tableForm.filterData.happenDisasterFlagOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else
                         :style="null === scope.row.plantCrop.happenDisasterFlag ? 'color:#333' : scope.row.plantCrop.happenDisasterFlag ? 'color:#039F3A' : 'color:#FF8900'">
                      {{ scope.row.plantCrop.happenDisasterName }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="前茬作物" width="120" prop="plantCrop.previousCropSubCategoryName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'previousCropSubCategoryName')">
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.previousCropSubCategoryId'" :rules="tableForm.formRules.plantCrop.previousCropSubCategoryId">
                        <el-select size="mini" v-model="scope.row.plantCrop.previousCropSubCategoryId" @change="handlePreviousCropSubCategoryChange($event, scope.row)">
                          <el-option-group v-for="group in tableForm.filterData.previousCropSubCategoryOptionGroupList" :key="group.label" :label="group.label">
                            <el-option v-for="option in group.optionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                          </el-option-group>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.plantCrop.previousCropSubCategoryName }}</div>
                  </template>
                </el-table-column>
                <el-table-column width="130" prop="plantCrop.previousYield" sortable>
                  <template slot="header">
                    前茬作物<br />亩产量<br />（公斤/亩）
                  </template>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.plantCrop.previousCropSubCategoryId && 'NONE' !== scope.row.plantCrop.previousCropSubCategoryId">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.previousYield'" :rules="tableForm.formRules.plantCrop.previousYield">
                        <el-input size="mini" v-model="scope.row.plantCrop.previousYield"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.plantCrop.previousYield }}</div>
                  </template>
                </el-table-column>
                <el-table-column width="120" prop="plantCrop.previousStrawReturnRate" sortable>
                  <template slot="header">
                    前茬秸秆<br />还田率<br />（%）
                  </template>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.plantCrop.previousCropSubCategoryId && 'NONE' !== scope.row.plantCrop.previousCropSubCategoryId">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.previousStrawReturnRate'" :rules="tableForm.formRules.plantCrop.previousStrawReturnRate">
                        <el-input size="mini" v-model="scope.row.plantCrop.previousStrawReturnRate"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.plantCrop.previousStrawReturnRate }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
              <!-- 种植作物 -->
              <el-table-column label="底肥（公斤/亩）">
                <el-table-column width="120" prop="baseFertilizer.fertilizationModeName" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'fertilizationModeName')">
                  <template slot="header">底肥<br />施肥方式</template>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.fertilizationMode'" :rules="tableForm.formRules.baseFertilizer.fertilizationMode">
                        <el-select size="mini" v-model="scope.row.baseFertilizer.fertilizationMode" @change="handleRowBaseFertilizerValueChange($event, 'fertilizationMode', scope.row)">
                          <el-option v-for="option in tableForm.filterData.baseFertilizationModeOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.baseFertilizer.fertilizationModeName }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="施肥日期" prop="baseFertilizer.fertilizationDate" width="150" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'fertilizationDate')">
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.fertilizationDate'" :rules="tableForm.formRules.baseFertilizer.fertilizationDate">
                        <el-date-picker size="mini" v-model="scope.row.baseFertilizer.fertilizationDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 130px"></el-date-picker>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.baseFertilizer.fertilizationDate }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="商用有机肥">
                  <el-table-column label="用量" prop="baseFertilizer.businessOrganicFertilizerDosage" width="100" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.businessOrganicFertilizerDosage'" :rules="tableForm.formRules.baseFertilizer.businessOrganicFertilizerDosage">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.businessOrganicFertilizerDosage"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.businessOrganicFertilizerDosage }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column width="120" prop="baseFertilizer.businessOrganicFertilizerPrice" sortable>
                    <template slot="header">
                      价格<br />（元/吨）
                    </template>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.businessOrganicFertilizerDosage && '' !== scope.row.baseFertilizer.businessOrganicFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.businessOrganicFertilizerPrice'" :rules="tableForm.formRules.baseFertilizer.businessOrganicFertilizerPrice">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.businessOrganicFertilizerPrice"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.businessOrganicFertilizerPrice }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="农家肥1（干重）">
                  <el-table-column label="名称" width="100" prop="baseFertilizer.organicFertilizer1Name" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'organicFertilizer1Name')">
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.organicFertilizer1Id'" :rules="tableForm.formRules.baseFertilizer.organicFertilizer1Id">
                          <el-select size="mini" v-model="scope.row.baseFertilizer.organicFertilizer1Id">
                            <el-option v-for="option in tableForm.filterData.organicFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.organicFertilizer1Name }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="用量" width="100" prop="baseFertilizer.organicFertilizer1Dosage" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.organicFertilizer1Id">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.organicFertilizer1Dosage'" :rules="tableForm.formRules.baseFertilizer.organicFertilizer1Dosage">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.organicFertilizer1Dosage"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.organicFertilizer1Dosage }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="农家肥2（干重）">
                  <el-table-column label="名称" width="100" prop="baseFertilizer.organicFertilizer2Name" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'organicFertilizer2Name')">
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                        <el-form-item
                          :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.organicFertilizer2Id'"
                          :rules="tableForm.formRules.baseFertilizer.organicFertilizer2Id">
                          <el-select size="mini" v-model="scope.row.baseFertilizer.organicFertilizer2Id">
                            <el-option v-for="option in tableForm.filterData.organicFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.organicFertilizer2Name }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="用量" width="100" prop="baseFertilizer.organicFertilizer2Dosage" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.organicFertilizer2Id">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.organicFertilizer2Dosage'" :rules="tableForm.formRules.baseFertilizer.organicFertilizer2Dosage">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.organicFertilizer2Dosage"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.organicFertilizer2Dosage }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="尿素" width="100" prop="baseFertilizer.urea" sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.urea'" :rules="tableForm.formRules.baseFertilizer.urea">
                        <el-input size="mini" v-model="scope.row.baseFertilizer.urea"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.baseFertilizer.urea }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="碳铵" width="100" prop="baseFertilizer.ammoniumBicarbonate" sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.ammoniumBicarbonate'" :rules="tableForm.formRules.baseFertilizer.ammoniumBicarbonate">
                        <el-input size="mini" v-model="scope.row.baseFertilizer.ammoniumBicarbonate"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.baseFertilizer.ammoniumBicarbonate }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="磷酸二铵" width="100" prop="baseFertilizer.diammoniumPhosphate" sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.diammoniumPhosphate'" :rules="tableForm.formRules.baseFertilizer.diammoniumPhosphate">
                        <el-input size="mini" v-model="scope.row.baseFertilizer.diammoniumPhosphate"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.baseFertilizer.diammoniumPhosphate }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="氯化钾" width="100" prop="baseFertilizer.potassiumChloride" sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.potassiumChloride'" :rules="tableForm.formRules.baseFertilizer.potassiumChloride">
                        <el-input size="mini" v-model="scope.row.baseFertilizer.potassiumChloride"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.baseFertilizer.potassiumChloride }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="硫酸钾" width="100" prop="baseFertilizer.potassiumSulphate" sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.potassiumSulphate'" :rules="tableForm.formRules.baseFertilizer.potassiumSulphate">
                        <el-input size="mini" v-model="scope.row.baseFertilizer.potassiumSulphate"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.baseFertilizer.potassiumSulphate }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="复合肥（含配方肥）及养分%">
                  <el-table-column label="用量" width="100" prop="baseFertilizer.compoundFertilizerDosage" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.compoundFertilizerDosage'" :rules="tableForm.formRules.baseFertilizer.compoundFertilizerDosage">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.compoundFertilizerDosage" @input="handleRowBaseFertilizerValueChange($event, 'compoundFertilizerDosage', scope.row)"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.compoundFertilizerDosage }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="N" width="100" prop="baseFertilizer.compoundFertilizerN" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.compoundFertilizerDosage && '' !== scope.row.baseFertilizer.compoundFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.compoundFertilizerN'" :rules="tableForm.formRules.baseFertilizer.compoundFertilizerN">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.compoundFertilizerN"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.compoundFertilizerN }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="P2O5" width="100" prop="baseFertilizer.compoundFertilizerP2O5" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.compoundFertilizerDosage && '' !== scope.row.baseFertilizer.compoundFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.compoundFertilizerP2O5'" :rules="tableForm.formRules.baseFertilizer.compoundFertilizerP2O5">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.compoundFertilizerP2O5"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.compoundFertilizerP2O5 }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="K2O" width="100" prop="baseFertilizer.compoundFertilizerK2O" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.compoundFertilizerDosage && '' !== scope.row.baseFertilizer.compoundFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.compoundFertilizerK2O'" :rules="tableForm.formRules.baseFertilizer.compoundFertilizerK2O">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.compoundFertilizerK2O"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.compoundFertilizerK2O }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="水溶肥及养分%">
                  <el-table-column label="用量" width="100" prop="baseFertilizer.waterSolubleFertilizerDosage" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.waterSolubleFertilizerDosage'" :rules="tableForm.formRules.baseFertilizer.waterSolubleFertilizerDosage">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.waterSolubleFertilizerDosage" @input="handleRowBaseFertilizerValueChange($event, 'waterSolubleFertilizerDosage', scope.row)"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.waterSolubleFertilizerDosage }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="N" width="100" prop="baseFertilizer.waterSolubleFertilizerN" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.waterSolubleFertilizerDosage && '' !== scope.row.baseFertilizer.waterSolubleFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.waterSolubleFertilizerN'" :rules="tableForm.formRules.baseFertilizer.waterSolubleFertilizerN">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.waterSolubleFertilizerN"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.waterSolubleFertilizerN }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="P2O5" width="100" prop="baseFertilizer.waterSolubleFertilizerP2O5" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.waterSolubleFertilizerDosage && '' !== scope.row.baseFertilizer.waterSolubleFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.waterSolubleFertilizerP2O5'" :rules="tableForm.formRules.baseFertilizer.waterSolubleFertilizerP2O5">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.waterSolubleFertilizerP2O5"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.waterSolubleFertilizerP2O5 }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="K2O" width="100" prop="baseFertilizer.waterSolubleFertilizerK2O" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.waterSolubleFertilizerDosage && '' !== scope.row.baseFertilizer.waterSolubleFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.waterSolubleFertilizerK2O'" :rules="tableForm.formRules.baseFertilizer.waterSolubleFertilizerK2O">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.waterSolubleFertilizerK2O"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.waterSolubleFertilizerK2O }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="其他肥料1">
                  <el-table-column label="名称" width="100" prop="baseFertilizer.otherFertilizer1Name" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'otherFertilizer1Name')">
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.otherFertilizer1Id'" :rules="tableForm.formRules.baseFertilizer.otherFertilizer1Id">
                          <el-select size="mini" v-model="scope.row.baseFertilizer.otherFertilizer1Id" @change="handleRowBaseFertilizerValueChange($event, 'otherFertilizer1Id', scope.row)">
                            <el-option v-for="option in tableForm.filterData.chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.otherFertilizer1Name }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="用量" width="100" prop="baseFertilizer.otherFertilizer1Dosage" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.otherFertilizer1Id">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.otherFertilizer1Dosage'" :rules="tableForm.formRules.baseFertilizer.otherFertilizer1Dosage">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.otherFertilizer1Dosage"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.otherFertilizer1Dosage }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="其他肥料2">
                  <el-table-column label="名称" width="100" prop="baseFertilizer.otherFertilizer2Name" sortable :sort-method="(a, b) => sortBaseFertilizerColumn(a, b, 'otherFertilizer2Name')">
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.fertilizationMode">
                        <el-form-item
                          :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.otherFertilizer2Id'"
                          :rules="tableForm.formRules.baseFertilizer.otherFertilizer2Id">
                          <el-select size="mini" v-model="scope.row.baseFertilizer.otherFertilizer2Id" @change="handleRowBaseFertilizerValueChange($event, 'otherFertilizer2Id', scope.row)">
                            <el-option v-for="option in tableForm.filterData.chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.otherFertilizer2Name }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="用量" width="100" prop="baseFertilizer.otherFertilizer2Dosage" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.baseFertilizer.otherFertilizer2Id">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.baseFertilizer.otherFertilizer2Dosage'" :rules="tableForm.formRules.baseFertilizer.otherFertilizer2Dosage">
                          <el-input size="mini" v-model="scope.row.baseFertilizer.otherFertilizer2Dosage"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.baseFertilizer.otherFertilizer2Dosage }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
              <!-- 底肥 -->
              <el-table-column width="120" align="center" prop="plantCrop.afterFertilizerFillInModeName" sortable :sort-method="(a, b) => sortPlantCropColumn(a, b, 'afterFertilizerFillInModeName')">
                <template slot="header">
                  追肥是否<br />分次填报
                </template>
                <template slot-scope="scope">
                  <div v-if="scope.row.editFlag">
                    <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.plantCrop.afterFertilizerFillInMode'" :rules="tableForm.formRules.plantCrop.afterFertilizerFillInMode">
                      <el-select size="mini" v-model="scope.row.plantCrop.afterFertilizerFillInMode" @change="handleAfterFertilizerFillInModeChange($event, scope.row.index - 1)">
                        <el-option v-for="option in tableForm.filterData.afterFertilizerFillInModeOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-else>
                    {{ scope.row.plantCrop.afterFertilizerFillInModeName }}
                  </div>
                </template>
              </el-table-column>
              <!-- 追肥是否分次填报 -->
              <el-table-column label="追肥合计（公斤/亩）">
                <el-table-column label="主要施肥方式" width="150" prop="afterFertilizerTotal.fertilizationModeName" sortable :sort-method="(a, b) => sortAfterFertilizerTotalColumn(a, b, 'fertilizationModeName')">
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && 'TOTAL' === scope.row.plantCrop.afterFertilizerFillInMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.fertilizationMode'" :rules="tableForm.formRules.afterFertilizerTotal.fertilizationMode">
                        <el-select size="mini" v-model="scope.row.afterFertilizerTotal.fertilizationMode" @change="handleRowAfterFertilizerTotalValueChange($event, 'fertilizationMode', scope.row)">
                          <el-option v-for="option in tableForm.filterData.afterFertilizationModeOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.afterFertilizerTotal.fertilizationModeName }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="追肥次数" width="150" prop="afterFertilizerTotal.afterFertilizationTimes" sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.afterFertilizationTimes'" :rules="tableForm.formRules.afterFertilizerTotal.afterFertilizationTimes">
                        <el-input size="mini" v-model="scope.row.afterFertilizerTotal.afterFertilizationTimes"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.afterFertilizerTotal.afterFertilizationTimes }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="首次追肥日期" width="150" prop="afterFertilizerTotal.afterFertilizationDate" sortable :sort-method="(a, b) => sortAfterFertilizerTotalColumn(a, b, 'afterFertilizationDate')">
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.afterFertilizationDate'" :rules="tableForm.formRules.afterFertilizerTotal.afterFertilizationDate">
                        <el-date-picker size="mini" v-model="scope.row.afterFertilizerTotal.afterFertilizationDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 130px"></el-date-picker>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.afterFertilizerTotal.afterFertilizationDate }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="商品有机肥用量" width="150" prop="afterFertilizerTotal.businessOrganicFertilizerDosage" sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.businessOrganicFertilizerDosage'" :rules="tableForm.formRules.afterFertilizerTotal.businessOrganicFertilizerDosage">
                        <el-input size="mini" v-model="scope.row.afterFertilizerTotal.businessOrganicFertilizerDosage"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.afterFertilizerTotal.businessOrganicFertilizerDosage }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="尿素" width="100" prop="afterFertilizerTotal.urea" sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.urea'" :rules="tableForm.formRules.afterFertilizerTotal.urea">
                        <el-input size="mini" v-model="scope.row.afterFertilizerTotal.urea"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.afterFertilizerTotal.urea }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="碳铵" width="100" prop="afterFertilizerTotal.ammoniumBicarbonate" sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.ammoniumBicarbonate'" :rules="tableForm.formRules.afterFertilizerTotal.ammoniumBicarbonate">
                        <el-input size="mini" v-model="scope.row.afterFertilizerTotal.ammoniumBicarbonate"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.afterFertilizerTotal.ammoniumBicarbonate }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="磷酸二铵" width="120" prop="afterFertilizerTotal.diammoniumPhosphate" sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.diammoniumPhosphate'" :rules="tableForm.formRules.afterFertilizerTotal.diammoniumPhosphate">
                        <el-input size="mini" v-model="scope.row.afterFertilizerTotal.diammoniumPhosphate"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.afterFertilizerTotal.diammoniumPhosphate }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="氯化钾" width="100" prop="afterFertilizerTotal.potassiumChloride" sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.potassiumChloride'" :rules="tableForm.formRules.afterFertilizerTotal.potassiumChloride">
                        <el-input size="mini" v-model="scope.row.afterFertilizerTotal.potassiumChloride"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.afterFertilizerTotal.potassiumChloride }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="硫酸钾" width="100" prop="afterFertilizerTotal.potassiumSulphate" sortable>
                  <template slot-scope="scope">
                    <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.fertilizationMode">
                      <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.potassiumSulphate'" :rules="tableForm.formRules.afterFertilizerTotal.potassiumSulphate">
                        <el-input size="mini" v-model="scope.row.afterFertilizerTotal.potassiumSulphate"></el-input>
                      </el-form-item>
                    </div>
                    <div v-else>{{ scope.row.afterFertilizerTotal.potassiumSulphate }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="复合肥（含配方肥）及养分%">
                  <el-table-column label="用量" width="100" prop="afterFertilizerTotal.compoundFertilizerDosage" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.compoundFertilizerDosage'" :rules="tableForm.formRules.afterFertilizerTotal.compoundFertilizerDosage">
                          <el-input size="mini" v-model="scope.row.afterFertilizerTotal.compoundFertilizerDosage" @input="handleRowAfterFertilizerTotalValueChange($event, 'compoundFertilizerDosage', scope.row)"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerTotal.compoundFertilizerDosage }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="N" width="100" prop="afterFertilizerTotal.compoundFertilizerN" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.compoundFertilizerDosage && '' !== scope.row.afterFertilizerTotal.compoundFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.compoundFertilizerN'" :rules="tableForm.formRules.afterFertilizerTotal.compoundFertilizerN">
                          <el-input size="mini" v-model="scope.row.afterFertilizerTotal.compoundFertilizerN"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerTotal.compoundFertilizerN }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="P2O5" width="100" prop="afterFertilizerTotal.compoundFertilizerP2O5" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.compoundFertilizerDosage && '' !== scope.row.afterFertilizerTotal.compoundFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.compoundFertilizerP2O5'" :rules="tableForm.formRules.afterFertilizerTotal.compoundFertilizerP2O5">
                          <el-input size="mini" v-model="scope.row.afterFertilizerTotal.compoundFertilizerP2O5"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerTotal.compoundFertilizerP2O5 }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="K2O" width="100" prop="afterFertilizerTotal.compoundFertilizerK2O" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.compoundFertilizerDosage && '' !== scope.row.afterFertilizerTotal.compoundFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.compoundFertilizerK2O'" :rules="tableForm.formRules.afterFertilizerTotal.compoundFertilizerK2O">
                          <el-input size="mini" v-model="scope.row.afterFertilizerTotal.compoundFertilizerK2O"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerTotal.compoundFertilizerK2O }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="水溶肥及养分%">
                  <el-table-column label="用量" width="100" prop="afterFertilizerTotal.waterSolubleFertilizerDosage" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.waterSolubleFertilizerDosage'" :rules="tableForm.formRules.afterFertilizerTotal.waterSolubleFertilizerDosage">
                          <el-input size="mini" v-model="scope.row.afterFertilizerTotal.waterSolubleFertilizerDosage" @input="handleRowAfterFertilizerTotalValueChange($event, 'waterSolubleFertilizerDosage', scope.row)"
                          ></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerTotal.waterSolubleFertilizerDosage }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="N" width="100" prop="afterFertilizerTotal.waterSolubleFertilizerN" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.waterSolubleFertilizerDosage && '' !== scope.row.afterFertilizerTotal.waterSolubleFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.waterSolubleFertilizerN'" :rules="tableForm.formRules.afterFertilizerTotal.waterSolubleFertilizerN">
                          <el-input size="mini" v-model="scope.row.afterFertilizerTotal.waterSolubleFertilizerN"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerTotal.waterSolubleFertilizerN }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="P2O5" width="100" prop="afterFertilizerTotal.waterSolubleFertilizerP2O5" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.waterSolubleFertilizerDosage && '' !== scope.row.afterFertilizerTotal.waterSolubleFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.waterSolubleFertilizerP2O5'" :rules="tableForm.formRules.afterFertilizerTotal.waterSolubleFertilizerP2O5">
                          <el-input size="mini" v-model="scope.row.afterFertilizerTotal.waterSolubleFertilizerP2O5"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerTotal.waterSolubleFertilizerP2O5 }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="K2O" width="100" prop="afterFertilizerTotal.waterSolubleFertilizerK2O" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.waterSolubleFertilizerDosage && '' !== scope.row.afterFertilizerTotal.waterSolubleFertilizerDosage">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.waterSolubleFertilizerK2O'" :rules="tableForm.formRules.afterFertilizerTotal.waterSolubleFertilizerK2O">
                          <el-input size="mini" v-model="scope.row.afterFertilizerTotal.waterSolubleFertilizerK2O"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerTotal.waterSolubleFertilizerK2O }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="其他肥料1">
                  <el-table-column label="名称" width="120" prop="afterFertilizerTotal.otherFertilizer1Name" sortable :sort-method="(a, b) => sortAfterFertilizerTotalColumn(a, b, 'otherFertilizer1Name')">
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.otherFertilizer1Id'" :rules="tableForm.formRules.afterFertilizerTotal.otherFertilizer1Id">
                          <el-select size="mini" v-model="scope.row.afterFertilizerTotal.otherFertilizer1Id" @change="handleRowAfterFertilizerTotalValueChange($event, 'otherFertilizer1Id', scope.row)">
                            <el-option v-for="option in tableForm.filterData.chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerTotal.otherFertilizer1Name }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="用量" width="100" prop="afterFertilizerTotal.otherFertilizer1Dosage" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.otherFertilizer1Id">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.otherFertilizer1Dosage'" :rules="tableForm.formRules.afterFertilizerTotal.otherFertilizer1Dosage">
                          <el-input size="mini" v-model="scope.row.afterFertilizerTotal.otherFertilizer1Dosage"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerTotal.otherFertilizer1Dosage }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="其他肥料2">
                  <el-table-column label="名称" width="120" prop="afterFertilizerTotal.otherFertilizer2Name" sortable :sort-method="(a, b) => sortAfterFertilizerTotalColumn(a, b, 'otherFertilizer2Name')">
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.otherFertilizer2Id'" :rules="tableForm.formRules.afterFertilizerTotal.otherFertilizer2Id">
                          <el-select size="mini" v-model="scope.row.afterFertilizerTotal.otherFertilizer2Id" @change="handleRowAfterFertilizerTotalValueChange($event, 'otherFertilizer2Id', scope.row)">
                            <el-option v-for="option in tableForm.filterData.chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerTotal.otherFertilizer2Name }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="用量" width="100" prop="afterFertilizerTotal.otherFertilizer2Dosage" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerTotal.otherFertilizer2Id">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerTotal.otherFertilizer2Dosage'" :rules="tableForm.formRules.afterFertilizerTotal.otherFertilizer2Dosage">
                          <el-input size="mini" v-model="scope.row.afterFertilizerTotal.otherFertilizer2Dosage"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerTotal.otherFertilizer2Dosage }}</div>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
              <!-- 追肥合计 -->
              <el-table-column label="追肥分次">
                <el-table-column v-for="(number, index) in [1, 2, 3, 4, 5]" :label="'追肥' + number + '（公斤/亩）'" :key="index">
                  <el-table-column label="施肥方式" width="120" :prop="'afterFertilizerList.' + index + '.fertilizationModeName'" sortable :sort-method="(a, b) => sortAfterFertilizerColumn(a, b, index, 'fertilizationModeName')">
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && 'MANY_TIMES' === scope.row.plantCrop.afterFertilizerFillInMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.fertilizationMode'" :rules="tableForm.formRules.afterFertilizer.fertilizationMode">
                          <el-select size="mini" v-model="scope.row.afterFertilizerList[index].fertilizationMode" @change="handleRowAfterFertilizerValueChange($event, 'fertilizationMode', scope.row, index)">
                            <el-option v-for="option in tableForm.filterData.afterFertilizationModeOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerList[index].fertilizationModeName }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="追肥日期" width="150" :prop="'afterFertilizerList.' + index + '.afterFertilizationDate'" sortable :sort-method="(a, b) => sortAfterFertilizerColumn(a, b, index, 'afterFertilizationDate')">
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && scope.row.afterFertilizerList[index].fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.afterFertilizationDate'" :rules="tableForm.formRules.afterFertilizer.afterFertilizationDate">
                          <el-date-picker size="mini" v-model="scope.row.afterFertilizerList[index].afterFertilizationDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 130px"></el-date-picker>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerList[index].afterFertilizationDate }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="商品有机肥用量" width="120" :prop="'afterFertilizerList.' + index + '.businessOrganicFertilizerDosage'" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && scope.row.afterFertilizerList[index].fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.businessOrganicFertilizerDosage'" :rules="tableForm.formRules.afterFertilizer.businessOrganicFertilizerDosage">
                          <el-input size="mini" v-model="scope.row.afterFertilizerList[index].businessOrganicFertilizerDosage"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerList[index].businessOrganicFertilizerDosage }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="尿素" width="100" :prop="'afterFertilizerList.' + index + '.urea'" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && scope.row.afterFertilizerList[index].fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.urea'" :rules="tableForm.formRules.afterFertilizer.urea">
                          <el-input size="mini" v-model="scope.row.afterFertilizerList[index].urea"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerList[index].urea }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="碳铵" width="100" :prop="'afterFertilizerList.' + index + '.ammoniumBicarbonate'" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && scope.row.afterFertilizerList[index].fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.ammoniumBicarbonate'" :rules="tableForm.formRules.afterFertilizer.ammoniumBicarbonate">
                          <el-input size="mini" v-model="scope.row.afterFertilizerList[index].ammoniumBicarbonate"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerList[index].ammoniumBicarbonate }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="磷酸二铵" width="100" :prop="'afterFertilizerList.' + index + '.diammoniumPhosphate'" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && scope.row.afterFertilizerList[index].fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.diammoniumPhosphate'" :rules="tableForm.formRules.afterFertilizer.diammoniumPhosphate">
                          <el-input size="mini" v-model="scope.row.afterFertilizerList[index].diammoniumPhosphate"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerList[index].diammoniumPhosphate }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="氯化钾" width="100" :prop="'afterFertilizerList.' + index + '.potassiumChloride'" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && scope.row.afterFertilizerList[index].fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.potassiumChloride'" :rules="tableForm.formRules.afterFertilizer.potassiumChloride">
                          <el-input size="mini" v-model="scope.row.afterFertilizerList[index].potassiumChloride"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerList[index].potassiumChloride }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="硫酸钾" width="100" :prop="'afterFertilizerList.' + index + '.potassiumSulphate'" sortable>
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag && scope.row.afterFertilizerList[index].fertilizationMode">
                        <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.potassiumSulphate'" :rules="tableForm.formRules.afterFertilizer.potassiumSulphate">
                          <el-input size="mini" v-model="scope.row.afterFertilizerList[index].potassiumSulphate"></el-input>
                        </el-form-item>
                      </div>
                      <div v-else>{{ scope.row.afterFertilizerList[index].potassiumSulphate }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column label="复合肥（含配方肥）及养分%">
                    <el-table-column label="用量" width="100" :prop="'afterFertilizerList.' + index + '.compoundFertilizerDosage'" sortable>
                      <template slot-scope="scope">
                        <div v-if="scope.row.editFlag && scope.row.afterFertilizerList[index].fertilizationMode">
                          <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.compoundFertilizerDosage'" :rules="tableForm.formRules.afterFertilizer.compoundFertilizerDosage">
                            <el-input size="mini" v-model="scope.row.afterFertilizerList[index].compoundFertilizerDosage" @input="handleRowAfterFertilizerValueChange($event, 'compoundFertilizerDosage', scope.row, index)"></el-input>
                          </el-form-item>
                        </div>
                        <div v-else>{{ scope.row.afterFertilizerList[index].compoundFertilizerDosage }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="N" width="100" :prop="'afterFertilizerList.' + index + '.compoundFertilizerN'" sortable>
                      <template slot-scope="scope">
                        <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerList[index].compoundFertilizerDosage && '' !== scope.row.afterFertilizerList[index].compoundFertilizerDosage">
                          <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.compoundFertilizerN'" :rules="tableForm.formRules.afterFertilizer.compoundFertilizerN">
                            <el-input size="mini" v-model="scope.row.afterFertilizerList[index].compoundFertilizerN"></el-input>
                          </el-form-item>
                        </div>
                        <div v-else>{{ scope.row.afterFertilizerList[index].compoundFertilizerN }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="P2O5" width="100" :prop="'afterFertilizerList.' + index + '.compoundFertilizerP2O5'" sortable>
                      <template slot-scope="scope">
                        <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerList[index].compoundFertilizerDosage && '' !== scope.row.afterFertilizerList[index].compoundFertilizerDosage">
                          <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.compoundFertilizerP2O5'" :rules="tableForm.formRules.afterFertilizer.compoundFertilizerP2O5">
                            <el-input size="mini" v-model="scope.row.afterFertilizerList[index].compoundFertilizerP2O5"></el-input>
                          </el-form-item>
                        </div>
                        <div v-else>{{ scope.row.afterFertilizerList[index].compoundFertilizerP2O5 }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="K2O" width="100" :prop="'afterFertilizerList.' + index + '.compoundFertilizerK2O'" sortable>
                      <template slot-scope="scope">
                        <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerList[index].compoundFertilizerDosage && '' !== scope.row.afterFertilizerList[index].compoundFertilizerDosage">
                          <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.compoundFertilizerK2O'" :rules="tableForm.formRules.afterFertilizer.compoundFertilizerK2O">
                            <el-input size="mini" v-model="scope.row.afterFertilizerList[index].compoundFertilizerK2O"></el-input>
                          </el-form-item>
                        </div>
                        <div v-else>{{ scope.row.afterFertilizerList[index].compoundFertilizerK2O }}</div>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="水溶肥及养分%">
                    <el-table-column label="用量" width="100" :prop="'afterFertilizerList.' + index + '.waterSolubleFertilizerDosage'" sortable>
                      <template slot-scope="scope">
                        <div v-if="scope.row.editFlag && scope.row.afterFertilizerList[index].fertilizationMode">
                          <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.waterSolubleFertilizerDosage'" :rules="tableForm.formRules.afterFertilizer.waterSolubleFertilizerDosage">
                            <el-input size="mini" v-model="scope.row.afterFertilizerList[index].waterSolubleFertilizerDosage" @input="handleRowAfterFertilizerValueChange($event, 'waterSolubleFertilizerDosage', scope.row, index)"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div v-else>{{ scope.row.afterFertilizerList[index].waterSolubleFertilizerDosage }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="N" width="100" :prop="'afterFertilizerList.' + index + '.waterSolubleFertilizerN'" sortable>
                      <template slot-scope="scope">
                        <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerList[index].waterSolubleFertilizerDosage && '' !== scope.row.afterFertilizerList[index].waterSolubleFertilizerDosage">
                          <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.waterSolubleFertilizerN'" :rules="tableForm.formRules.afterFertilizer.waterSolubleFertilizerN">
                            <el-input size="mini" v-model="scope.row.afterFertilizerList[index].waterSolubleFertilizerN"></el-input>
                          </el-form-item>
                        </div>
                        <div v-else>{{ scope.row.afterFertilizerList[index].waterSolubleFertilizerN }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="P2O5" width="100" :prop="'afterFertilizerList.' + index + '.waterSolubleFertilizerP2O5'" sortable>
                      <template slot-scope="scope">
                        <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerList[index].waterSolubleFertilizerDosage && '' !== scope.row.afterFertilizerList[index].waterSolubleFertilizerDosage">
                          <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.waterSolubleFertilizerP2O5'" :rules="tableForm.formRules.afterFertilizer.waterSolubleFertilizerP2O5">
                            <el-input size="mini" v-model="scope.row.afterFertilizerList[index].waterSolubleFertilizerP2O5"></el-input>
                          </el-form-item>
                        </div>
                        <div v-else>{{ scope.row.afterFertilizerList[index].waterSolubleFertilizerP2O5 }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="K2O" width="100" :prop="'afterFertilizerList.' + index + '.waterSolubleFertilizerK2O'" sortable>
                      <template slot-scope="scope">
                        <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerList[index].waterSolubleFertilizerDosage && '' !== scope.row.afterFertilizerList[index].waterSolubleFertilizerDosage">
                          <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.waterSolubleFertilizerK2O'" :rules="tableForm.formRules.afterFertilizer.waterSolubleFertilizerK2O">
                            <el-input size="mini" v-model="scope.row.afterFertilizerList[index].waterSolubleFertilizerK2O"></el-input>
                          </el-form-item>
                        </div>
                        <div v-else>{{ scope.row.afterFertilizerList[index].waterSolubleFertilizerK2O }}</div>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="其他肥料1">
                    <el-table-column label="名称" width="120" :prop="'afterFertilizerList.' + index + '.otherFertilizer1Name'" sortable :sort-method="(a, b) => sortAfterFertilizerColumn(a, b, index, 'otherFertilizer1Name')">
                      <template slot-scope="scope">
                        <div v-if="scope.row.editFlag && scope.row.afterFertilizerList[index].fertilizationMode">
                          <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.otherFertilizer1Id'" :rules="tableForm.formRules.afterFertilizer.otherFertilizer1Id">
                            <el-select size="mini" v-model="scope.row.afterFertilizerList[index].otherFertilizer1Id" @change="handleRowAfterFertilizerValueChange($event, 'otherFertilizer1Id', scope.row, index)">
                              <el-option v-for="option in tableForm.filterData.chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                        <div v-else>{{ scope.row.afterFertilizerList[index].otherFertilizer1Name }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="用量" width="100" :prop="'afterFertilizerList.' + index + '.otherFertilizer1Dosage'" sortable>
                      <template slot-scope="scope">
                        <div v-if="scope.row.editFlag && null !== scope.row.afterFertilizerList[index].otherFertilizer1Id">
                          <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.otherFertilizer1Dosage'" :rules="tableForm.formRules.afterFertilizer.otherFertilizer1Dosage">
                            <el-input size="mini" v-model="scope.row.afterFertilizerList[index].otherFertilizer1Dosage"></el-input>
                          </el-form-item>
                        </div>
                        <div v-else>{{ scope.row.afterFertilizerList[index].otherFertilizer1Dosage }}</div>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="其他肥料2">
                    <el-table-column label="名称" width="120" :prop="'afterFertilizerList.' + index + '.otherFertilizer2Name'" sortable :sort-method="(a, b) => sortAfterFertilizerColumn(a, b, index, 'otherFertilizer2Name')">
                      <template slot-scope="scope">
                        <div v-if="scope.row.editFlag && scope.row.afterFertilizerList[index].fertilizationMode">
                          <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.otherFertilizer2Id'" :rules="tableForm.formRules.afterFertilizer.otherFertilizer2Id">
                            <el-select size="mini" v-model="scope.row.afterFertilizerList[index].otherFertilizer2Id" @change="handleRowAfterFertilizerValueChange($event, 'otherFertilizer2Id', scope.row, index)">
                              <el-option v-for="option in tableForm.filterData.chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                        <div v-else>{{ scope.row.afterFertilizerList[index].otherFertilizer2Name }}</div>
                      </template>
                    </el-table-column>
                    <el-table-column label="用量" width="100" :prop="'afterFertilizerList.' + index + '.otherFertilizer2Dosage'" sortable>
                      <template slot-scope="scope">
                        <div
                          v-if="scope.row.editFlag && null !== scope.row.afterFertilizerList[index].otherFertilizer2Id">
                          <el-form-item :prop="'tableData.' + (scope.row.index - 1) + '.afterFertilizerList.' + index + '.otherFertilizer2Dosage'" :rules="tableForm.formRules.afterFertilizer.otherFertilizer2Dosage">
                            <el-input size="mini" v-model="scope.row.afterFertilizerList[index].otherFertilizer2Dosage"></el-input>
                          </el-form-item>
                        </div>
                        <div v-else>{{ scope.row.afterFertilizerList[index].otherFertilizer2Dosage }}</div>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
              <!-- 追肥 1 ~ 5 -->
              <el-table-column label="记录情况" v-if="!flags.enableEdit" width="260">
                <template slot-scope="scope">
                  <el-popover placement="left-start" width="260" trigger="hover">
                    <ul class="table-column-record">
                      <li>创建时间：{{ scope.row.plantCrop.createdOn }}</li>
                      <li>修改时间：{{ scope.row.plantCrop.modifiedOn }}</li>
                    </ul>
                    <i class="el-icon-view" slot="reference"></i>
                  </el-popover>
                  {{ scope.row.plantCrop.createdOn }}
                </template>
              </el-table-column>
              <!-- 记录情况 -->
            </el-table>
          </div>
          <div class="wg-pagination-box">
            <el-pagination background layout="total, sizes, prev, pager, next" :total="tableForm.recordTotal" :page-sizes="[30, 50, 100]" :page-size="tableForm.pageSize" :current-page="tableForm.pageIndex" @size-change="handleSizeChange" @current-change="handlePagingChange"></el-pagination>
          </div>
        </el-form>
      </div>
    </div>

    <farmer-select-dialog ref="farmerSelectDialog" v-if="flags.loadFarmerSelectDialog" @close="handleFarmerSelectDialogClose"></farmer-select-dialog>
    <upload-plant-crop-import-file-dialog ref="uploadPlantCropImportFileDialog" v-if="flags.loadUploadPlantCropImportFileDialog" @close="handleUploadPlantCropImportFileDialogClose" @uploadSuccess="handleUploadPlantCropImportFileSuccess"></upload-plant-crop-import-file-dialog>
  </div>
</template>

<script>
import Screenfull from 'screenfull'

import FarmerApi from '@/api/farmer-api'
import RegionApi from '@/api/region-api'
import MonitoringPlotApi from '@/api/monitoring-plot-api'
import OrganicFertilizerApi from '@/api/organic-fertilizer-api'
import ChemicalFertilizerApi from '@/api/chemical-fertilizer-api'
import AssistFillInPlantCropApi from '@/api/assist-fill-in-plant-crop-api'

import FarmerSelectDialog from '@/components/FarmerSelectDialog'
import UploadPlantCropImportFileDialog from './UploadPlantCropImportFileDialog'
import FileApi from '@/api/file-api'

export default {
  name: 'UnsubmittedPlantCropData',
  components: {
    'farmer-select-dialog': FarmerSelectDialog,
    'upload-plant-crop-import-file-dialog': UploadPlantCropImportFileDialog
  },
  props: {
    cropRootCategoryArray: [],
    cropSubCategoryArray: [],
    cropArray: []
  },
  data() {
    // validate PlantCrop rule
    const validateSowingDate = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let harvestTime = this.tableForm.tableData[rowIndex].plantCrop.harvestTime
      if (null !== value && null !== harvestTime) {
        let sowingDateNum = this.$moment(value).unix()
        let harvestTimeNum = this.$moment(harvestTime).unix()
        if (sowingDateNum > harvestTimeNum) {
          callback(Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    const validateHarvestTime = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let sowingDate = this.tableForm.tableData[rowIndex].plantCrop.sowingDate
      if (null !== value && null !== sowingDate) {
        let harvestTimeNum = this.$moment(value).unix()
        let sowingDateNum = this.$moment(sowingDate).unix()
        if (sowingDateNum > harvestTimeNum) {
          callback(Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    // validate BaseFertilizer rule
    const validateBaseFertilizationMode = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let fertilizationMode = this.tableForm.tableData[rowIndex].baseFertilizer.fertilizationMode
      if (null === fertilizationMode) {
        callback()
      } else {
        let row = this.tableForm.tableData[rowIndex]
        if (
          this.$utils.isEmpty(row.baseFertilizer.businessOrganicFertilizerDosage)
          && null === row.baseFertilizer.organicFertilizer1Id
          && null === row.baseFertilizer.organicFertilizer2Id
          && this.$utils.isEmpty(row.baseFertilizer.urea)
          && this.$utils.isEmpty(row.baseFertilizer.ammoniumBicarbonate)
          && this.$utils.isEmpty(row.baseFertilizer.diammoniumPhosphate)
          && this.$utils.isEmpty(row.baseFertilizer.potassiumChloride)
          && this.$utils.isEmpty(row.baseFertilizer.potassiumSulphate)
          && this.$utils.isEmpty(row.baseFertilizer.compoundFertilizerDosage)
          && this.$utils.isEmpty(row.baseFertilizer.waterSolubleFertilizerDosage)
          && null === row.baseFertilizer.otherFertilizer1Id
          && null === row.baseFertilizer.otherFertilizer2Id
        ) {
          callback(new Error())
        } else {
          callback()
        }
      }
    }

    const validateBaseFertilizerCompoundFertilizerDosage = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let compoundFertilizerN = this.tableForm.tableData[rowIndex].baseFertilizer.compoundFertilizerN
      let compoundFertilizerP2O5 = this.tableForm.tableData[rowIndex].baseFertilizer.compoundFertilizerP2O5
      let compoundFertilizerK2O = this.tableForm.tableData[rowIndex].baseFertilizer.compoundFertilizerK2O
      if (this.$utils.isNotEmpty(compoundFertilizerN) && this.$utils.isNotEmpty(compoundFertilizerP2O5) && this.$utils.isNotEmpty(compoundFertilizerK2O)) {
        let total = Number(compoundFertilizerN) + Number(compoundFertilizerP2O5) + Number(compoundFertilizerK2O)
        if (0 === total) {
          callback(new Error())
        } else if (total > 100) {
          callback(new Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    const validateBaseFertilizerWaterSolubleFertilizerDosage = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let waterSolubleFertilizerN = this.tableForm.tableData[rowIndex].baseFertilizer.waterSolubleFertilizerN
      let waterSolubleFertilizerP2O5 = this.tableForm.tableData[rowIndex].baseFertilizer.waterSolubleFertilizerP2O5
      let waterSolubleFertilizerK2O = this.tableForm.tableData[rowIndex].baseFertilizer.waterSolubleFertilizerK2O
      if (this.$utils.isNotEmpty(waterSolubleFertilizerN) && this.$utils.isNotEmpty(waterSolubleFertilizerP2O5) && this.$utils.isNotEmpty(waterSolubleFertilizerK2O)) {
        let total = Number(waterSolubleFertilizerN) + Number(waterSolubleFertilizerP2O5) + Number(waterSolubleFertilizerK2O)
        if (0 === total) {
          callback(new Error())
        } else if (total > 100) {
          callback(new Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    // validate AfterFertilizerTotal rule
    const validateAfterTotalFertilizationMode = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let fertilizationMode = this.tableForm.tableData[rowIndex].afterFertilizerTotal.fertilizationMode
      if (null === fertilizationMode) {
        callback(new Error())
      } else {
        let row = this.tableForm.tableData[rowIndex].afterFertilizerTotal
        if (
          this.$utils.isEmpty(row.afterFertilizationTimes) &&
          this.$utils.isEmpty(row.businessOrganicFertilizerDosage) &&
          this.$utils.isEmpty(row.urea) &&
          this.$utils.isEmpty(row.ammoniumBicarbonate) &&
          this.$utils.isEmpty(row.diammoniumPhosphate) &&
          this.$utils.isEmpty(row.potassiumChloride) &&
          this.$utils.isEmpty(row.potassiumSulphate) &&
          this.$utils.isEmpty(row.compoundFertilizerDosage) &&
          this.$utils.isEmpty(row.waterSolubleFertilizerDosage) &&
          null === row.otherFertilizer1Id &&
          null === row.otherFertilizer2Id
        ) {
          callback(new Error())
        } else {
          callback()
        }
      }
    }

    const validateAfterTotalCompoundFertilizerDosage = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let compoundFertilizerN = this.tableForm.tableData[rowIndex].afterFertilizerTotal.compoundFertilizerN
      let compoundFertilizerP2O5 = this.tableForm.tableData[rowIndex].afterFertilizerTotal.compoundFertilizerP2O5
      let compoundFertilizerK2O = this.tableForm.tableData[rowIndex].afterFertilizerTotal.compoundFertilizerK2O
      if (this.$utils.isNotEmpty(compoundFertilizerN) && this.$utils.isNotEmpty(compoundFertilizerP2O5) && this.$utils.isNotEmpty(compoundFertilizerK2O)) {
        let total = Number(compoundFertilizerN) + Number(compoundFertilizerP2O5) + Number(compoundFertilizerK2O)
        if (0 === total) {
          callback(new Error())
        } else if (total > 100) {
          callback(new Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    const validateAfterTotalWaterSolubleFertilizerDosage = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let waterSolubleFertilizerN = this.tableForm.tableData[rowIndex].afterFertilizerTotal.waterSolubleFertilizerN
      let waterSolubleFertilizerP2O5 = this.tableForm.tableData[rowIndex].afterFertilizerTotal.waterSolubleFertilizerP2O5
      let waterSolubleFertilizerK2O = this.tableForm.tableData[rowIndex].afterFertilizerTotal.waterSolubleFertilizerK2O
      if (this.$utils.isNotEmpty(waterSolubleFertilizerN) && this.$utils.isNotEmpty(waterSolubleFertilizerP2O5) && this.$utils.isNotEmpty(waterSolubleFertilizerK2O)) {
        let total = Number(waterSolubleFertilizerN) + Number(waterSolubleFertilizerP2O5) + Number(waterSolubleFertilizerK2O)
        if (0 === total) {
          callback(new Error())
        } else if (total > 100) {
          callback(new Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    // validate AfterFertilizer
    const validateAfterFertilizationMode = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let afterFertilizerIndex = fieldSplitArray[3]
      let fertilizationMode = this.tableForm.tableData[rowIndex].afterFertilizerList[afterFertilizerIndex].fertilizationMode
      if (null === fertilizationMode) {
        callback()
      } else {
        let row = this.tableForm.tableData[rowIndex].afterFertilizerList[afterFertilizerIndex]
        if (
          this.$utils.isEmpty(row.businessOrganicFertilizerDosage) &&
          this.$utils.isEmpty(row.urea) &&
          this.$utils.isEmpty(row.ammoniumBicarbonate) &&
          this.$utils.isEmpty(row.diammoniumPhosphate) &&
          this.$utils.isEmpty(row.potassiumChloride) &&
          this.$utils.isEmpty(row.potassiumSulphate) &&
          this.$utils.isEmpty(row.compoundFertilizerDosage) &&
          this.$utils.isEmpty(row.waterSolubleFertilizerDosage) &&
          null === row.otherFertilizer1Id &&
          null === row.otherFertilizer2Id
        ) {
          callback(new Error())
        } else {
          callback()
        }
      }
    }

    const validateAfterCompoundFertilizerDosage = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let afterFertilizerIndex = fieldSplitArray[3]
      let compoundFertilizerN = this.tableForm.tableData[rowIndex].afterFertilizerList[afterFertilizerIndex].compoundFertilizerN
      let compoundFertilizerP2O5 = this.tableForm.tableData[rowIndex].afterFertilizerList[afterFertilizerIndex].compoundFertilizerP2O5
      let compoundFertilizerK2O = this.tableForm.tableData[rowIndex].afterFertilizerList[afterFertilizerIndex].compoundFertilizerK2O
      if (this.$utils.isNotEmpty(compoundFertilizerN) && this.$utils.isNotEmpty(compoundFertilizerP2O5) && this.$utils.isNotEmpty(compoundFertilizerK2O)) {
        let total = Number(compoundFertilizerN) + Number(compoundFertilizerP2O5) + Number(compoundFertilizerK2O)
        if (0 === total) {
          callback(new Error())
        } else if (total > 100) {
          callback(new Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    const validateAfterWaterSolubleFertilizerDosage = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let afterFertilizerIndex = fieldSplitArray[3]
      let waterSolubleFertilizerN = this.tableForm.tableData[rowIndex].afterFertilizerList[afterFertilizerIndex].waterSolubleFertilizerN
      let waterSolubleFertilizerP2O5 = this.tableForm.tableData[rowIndex].afterFertilizerList[afterFertilizerIndex].waterSolubleFertilizerP2O5
      let waterSolubleFertilizerK2O = this.tableForm.tableData[rowIndex].afterFertilizerList[afterFertilizerIndex].waterSolubleFertilizerK2O
      if (this.$utils.isNotEmpty(waterSolubleFertilizerN) && this.$utils.isNotEmpty(waterSolubleFertilizerP2O5) && this.$utils.isNotEmpty(waterSolubleFertilizerK2O)) {
        let total = Number(waterSolubleFertilizerN) + Number(waterSolubleFertilizerP2O5) + Number(waterSolubleFertilizerK2O)
        if (0 === total) {
          callback(new Error())
        } else if (total > 100) {
          callback(new Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    // validate common rule

    const validateNumber = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^([0-9]\d*(\.\d*)?)$|^0\.\d*[1-9]\d*$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入数字！'))
        }
      } else {
        callback()
      }
    }

    return {
      dataLoading: false,
      tableHeight: 600,
      isFullscreen: false,
      params: {
        year: null,
        regionId: null
      },
      flags: {
        loadFarmerSelectDialog: false,
        loadUploadPlantCropImportFileDialog: false,
        enableAdd: false,
        enableEdit: false
      },
      models: {
        assistFillPlantCropArray: []
      },
      tableForm: {
        filterData: {
          farmerNatureOptionList: [],
          educationalLevelOptionList: [],
          modelHouseholdFlagOptionList: [],
          monitoringPlotOptionList: [],
          cropSubCategoryOptionGroupList: [],
          previousCropSubCategoryOptionGroupList: [],
          plotFertilityOptionList: [],
          yieldLevelOptionList: [],
          facilityCultivationFlagOptionList: [],
          happenDisasterFlagOptionList: [],
          organicFertilizerOptionList: [],
          chemicalFertilizerOptionList: [],
          baseFertilizationModeOptionList: [],
          afterFertilizationModeOptionList: [],
          afterFertilizerFillInModeOptionList: []
        },
        formRules: {
          farmer: {
            fullName: [{ required: true, trigger: 'blur' }],
            farmerNature: [{ required: true, trigger: 'change' }],
            educationalLevel: [{ required: true, trigger: 'change' }],
            cultivationLandArea: [{ required: true, trigger: 'blur' }],
            modelHouseholdFlagOptionList: [{ required: true, trigger: 'change' }]
          },
          monitoringPlot: {
            id: [{ required: true, trigger: 'change' }]
          },
          plantCrop: {
            cropSubCategoryId: [{ required: true, trigger: 'change' }],
            cropName: [{ required: true, trigger: 'change' }],
            cropRemark: [{ required: false, trigger: 'blur' }],
            sowingDate: [{ trigger: 'blur', validator: validateSowingDate }],
            harvestTime: [{ trigger: 'blur', validator: validateHarvestTime }],
            sowingArea: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            plotFertility: [{ required: true, trigger: 'change' }],
            yield: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            yieldLevel: [{ required: true, trigger: 'change' }],
            facilityCultivationFlag: [{ required: true, trigger: 'change' }],
            happenDisasterFlag: [{ required: false, trigger: 'change' }],
            previousCropSubCategoryId: [{ required: true, trigger: 'blur' }],
            previousYield: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            previousStrawReturnRate: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            afterFertilizerFillInMode: [{ required: true, trigger: 'change' }]
          },
          baseFertilizer: {
            fertilizationDate: [],
            businessOrganicFertilizerDosage: [
              { trigger: 'blur', validator: validateBaseFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            businessOrganicFertilizerPrice: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            organicFertilizer1Id: [{ trigger: 'change', validator: validateBaseFertilizationMode }],
            organicFertilizer1Dosage: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            organicFertilizer2Id: [{ trigger: 'change', validator: validateBaseFertilizationMode }],
            organicFertilizer2Dosage: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            urea: [
              { trigger: 'blur', validator: validateBaseFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            ammoniumBicarbonate: [
              { trigger: 'blur', validator: validateBaseFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            diammoniumPhosphate: [
              { trigger: 'blur', validator: validateBaseFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            potassiumChloride: [
              { trigger: 'blur', validator: validateBaseFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            potassiumSulphate: [
              { trigger: 'blur', validator: validateBaseFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            compoundFertilizerDosage: [
              { trigger: 'blur', validator: validateBaseFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            compoundFertilizerN: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateBaseFertilizerCompoundFertilizerDosage }
            ],
            compoundFertilizerP2O5: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateBaseFertilizerCompoundFertilizerDosage }
            ],
            compoundFertilizerK2O: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateBaseFertilizerCompoundFertilizerDosage }
            ],
            waterSolubleFertilizerDosage: [
              { trigger: 'blur', validator: validateBaseFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            waterSolubleFertilizerN: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateBaseFertilizerWaterSolubleFertilizerDosage }
            ],
            waterSolubleFertilizerP2O5: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateBaseFertilizerWaterSolubleFertilizerDosage }
            ],
            waterSolubleFertilizerK2O: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateBaseFertilizerWaterSolubleFertilizerDosage }
            ],
            otherFertilizer1Id: [{ trigger: 'change', validator: validateBaseFertilizationMode }],
            otherFertilizer1Dosage: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            otherFertilizer2Id: [{ trigger: 'change', validator: validateBaseFertilizationMode }],
            otherFertilizer2Dosage: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            fertilizationMode: []
          },
          afterFertilizerTotal: {
            afterFertilizationTimes: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateAfterTotalFertilizationMode }
            ],
            afterFertilizationDate: [],
            businessOrganicFertilizerDosage: [
              { trigger: 'blur', validator: validateAfterTotalFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            urea: [
              { trigger: 'blur', validator: validateAfterTotalFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            ammoniumBicarbonate: [
              { trigger: 'blur', validator: validateAfterTotalFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            diammoniumPhosphate: [
              { trigger: 'blur', validator: validateAfterTotalFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            potassiumChloride: [
              { trigger: 'blur', validator: validateAfterTotalFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            potassiumSulphate: [
              { trigger: 'blur', validator: validateAfterTotalFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            compoundFertilizerDosage: [
              { trigger: 'blur', validator: validateAfterTotalFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            compoundFertilizerN: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateAfterTotalCompoundFertilizerDosage }
            ],
            compoundFertilizerP2O5: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateAfterTotalCompoundFertilizerDosage }
            ],
            compoundFertilizerK2O: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateAfterTotalCompoundFertilizerDosage }
            ],
            waterSolubleFertilizerDosage: [
              { trigger: 'blur', validator: validateAfterTotalFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            waterSolubleFertilizerN: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateAfterTotalWaterSolubleFertilizerDosage }
            ],
            waterSolubleFertilizerP2O5: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateAfterTotalWaterSolubleFertilizerDosage }
            ],
            waterSolubleFertilizerK2O: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateAfterTotalWaterSolubleFertilizerDosage }
            ],
            otherFertilizer1Id: [{ trigger: 'change', validator: validateAfterTotalFertilizationMode }],
            otherFertilizer1Dosage: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            otherFertilizer2Id: [{ trigger: 'change', validator: validateAfterTotalFertilizationMode }],
            otherFertilizer2Dosage: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            fertilizationMode: []
          },
          afterFertilizer: {
            afterFertilizationDate: [],
            businessOrganicFertilizerDosage: [
              { trigger: 'blur', validator: validateAfterFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            urea: [
              { trigger: 'blur', validator: validateAfterFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            ammoniumBicarbonate: [
              { trigger: 'blur', validator: validateAfterFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            diammoniumPhosphate: [
              { trigger: 'blur', validator: validateAfterFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            potassiumChloride: [
              { trigger: 'blur', validator: validateAfterFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            potassiumSulphate: [
              { trigger: 'blur', validator: validateAfterFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            compoundFertilizerDosage: [
              { trigger: 'blur', validator: validateAfterFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            compoundFertilizerN: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateAfterCompoundFertilizerDosage }
            ],
            compoundFertilizerP2O5: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateAfterCompoundFertilizerDosage }
            ],
            compoundFertilizerK2O: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateAfterCompoundFertilizerDosage }
            ],
            waterSolubleFertilizerDosage: [
              { trigger: 'blur', validator: validateAfterFertilizationMode },
              { trigger: 'blur', validator: validateNumber }
            ],
            waterSolubleFertilizerN: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateAfterWaterSolubleFertilizerDosage }
            ],
            waterSolubleFertilizerP2O5: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateAfterWaterSolubleFertilizerDosage }
            ],
            waterSolubleFertilizerK2O: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber },
              { trigger: 'blur', validator: validateAfterWaterSolubleFertilizerDosage }
            ],
            otherFertilizer1Id: [{ trigger: 'change', validator: validateAfterFertilizationMode }],
            otherFertilizer1Dosage: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            otherFertilizer2Id: [{ trigger: 'change', validator: validateAfterFertilizationMode }],
            otherFertilizer2Dosage: [
              { required: true, trigger: 'blur' },
              { trigger: 'blur', validator: validateNumber }
            ],
            fertilizationMode: []
          }
        },
        columnFilterData: {
          cropOptionList: []
        },
        tableData: [],
        recordTotal: 0,
        pageSize: 30,
        pageIndex: 1,
        selectedRows: [],
        clickedRow: null,
        exportPageCount: null
      }
    }
  },
  created() {
    this.tableForm.filterData.farmerNatureOptionList.push({ label: '请选择', value: null })
    this.tableForm.filterData.farmerNatureOptionList.push({ label: '普通种植户', value: 'COMMON_PLANTER' })
    this.tableForm.filterData.farmerNatureOptionList.push({ label: '种植大户', value: 'LARGE_PLANTER' })
    this.tableForm.filterData.farmerNatureOptionList.push({ label: '家庭农场', value: 'FAMILY_FARM' })
    this.tableForm.filterData.farmerNatureOptionList.push({ label: '专业合作社', value: 'SPECIALIZED_COOPERATIVE' })

    this.tableForm.filterData.educationalLevelOptionList.push({ label: '请选择', value: null })
    this.tableForm.filterData.educationalLevelOptionList.push({ label: '小学', value: 'PRIMARY_SCHOOL' })
    this.tableForm.filterData.educationalLevelOptionList.push({ label: '初中', value: 'JUNIOR_HIGH_SCHOOL' })
    this.tableForm.filterData.educationalLevelOptionList.push({ label: '中专/中技', value: 'TECHNICAL_SECONDARY_SCHOOL' })
    this.tableForm.filterData.educationalLevelOptionList.push({ label: '高中', value: 'SENIOR_HIGH_SCHOOL' })
    this.tableForm.filterData.educationalLevelOptionList.push({ label: '大专', value: 'JUNIOR_COLLEGE' })
    this.tableForm.filterData.educationalLevelOptionList.push({ label: '本科', value: 'UNDERGRADUATE' })
    this.tableForm.filterData.educationalLevelOptionList.push({ label: '研究生', value: 'GRADUATE' })

    this.tableForm.filterData.modelHouseholdFlagOptionList.push({ label: '请选择', value: null })
    this.tableForm.filterData.modelHouseholdFlagOptionList.push({ label: '是', value: true })
    this.tableForm.filterData.modelHouseholdFlagOptionList.push({ label: '否', value: false })

    // previousCropSubCategoryOptionGroupList
    this.tableForm.filterData.previousCropSubCategoryOptionGroupList.push({
      label: '默认',
      optionList: [{ label: '无', value: 'NONE' }]
    })

    this.cropRootCategoryArray.forEach(el => {
      let cropSubCategoryOptionGroup = { label: el.name, optionList: [] }

      let tempCropSubCategoryArray = this.cropSubCategoryArray.filter(el1 => {
        return el1.parentId === el.id
      })

      tempCropSubCategoryArray.forEach(el1 => {
        cropSubCategoryOptionGroup.optionList.push({ label: el1.name, value: el1.id })
      })
      this.tableForm.filterData.cropSubCategoryOptionGroupList.push(cropSubCategoryOptionGroup)
      this.tableForm.filterData.previousCropSubCategoryOptionGroupList.push(cropSubCategoryOptionGroup)
    })

    this.tableForm.filterData.plotFertilityOptionList.push({ label: '请选择', value: null })
    this.tableForm.filterData.plotFertilityOptionList.push({ label: '高', value: 'HIGH' })
    this.tableForm.filterData.plotFertilityOptionList.push({ label: '中', value: 'MIDDLE' })
    this.tableForm.filterData.plotFertilityOptionList.push({ label: '低', value: 'LOW' })

    this.tableForm.filterData.yieldLevelOptionList.push({ label: '请选择', value: null })
    this.tableForm.filterData.yieldLevelOptionList.push({ label: '高', value: 'HIGH' })
    this.tableForm.filterData.yieldLevelOptionList.push({ label: '中', value: 'MIDDLE' })
    this.tableForm.filterData.yieldLevelOptionList.push({ label: '低', value: 'LOW' })

    this.tableForm.filterData.facilityCultivationFlagOptionList.push({ label: '请选择', value: null })
    this.tableForm.filterData.facilityCultivationFlagOptionList.push({ label: '是', value: true })
    this.tableForm.filterData.facilityCultivationFlagOptionList.push({ label: '否', value: false })

    this.tableForm.filterData.happenDisasterFlagOptionList.push({ label: '请选择', value: null })
    this.tableForm.filterData.happenDisasterFlagOptionList.push({ label: '是', value: true })
    this.tableForm.filterData.happenDisasterFlagOptionList.push({ label: '否', value: false })

    this.tableForm.filterData.organicFertilizerOptionList.push({ label: '请选择', value: null })
    OrganicFertilizerApi.getStubList()
      .then(res => {
        res.data.forEach(el => {
          this.tableForm.filterData.organicFertilizerOptionList.push({ label: el.name, value: el.id })
        })
      })
      .catch(err => {
        this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
      })

    this.tableForm.filterData.chemicalFertilizerOptionList.push({ label: '请选择', value: null })
    ChemicalFertilizerApi.getStubList()
      .then(res => {
        res.data.forEach(el => {
          this.tableForm.filterData.chemicalFertilizerOptionList.push({ label: el.name, value: el.id })
        })
      })
      .catch(err => {
        this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
      })

    this.tableForm.filterData.baseFertilizationModeOptionList.push({ label: '请选择', value: null })
    this.tableForm.filterData.baseFertilizationModeOptionList.push({ label: '撒施', value: 'MODE1' })
    this.tableForm.filterData.baseFertilizationModeOptionList.push({ label: '机械深施', value: 'MODE2' })
    this.tableForm.filterData.baseFertilizationModeOptionList.push({ label: '种肥同播', value: 'MODE3' })
    this.tableForm.filterData.baseFertilizationModeOptionList.push({ label: '条施', value: 'MODE4' })
    this.tableForm.filterData.baseFertilizationModeOptionList.push({ label: '穴施', value: 'MODE5' })
    this.tableForm.filterData.baseFertilizationModeOptionList.push({ label: '无人机施肥', value: 'MODE7' })
    this.tableForm.filterData.baseFertilizationModeOptionList.push({ label: '其他', value: 'MODE6' })

    this.tableForm.filterData.afterFertilizationModeOptionList.push({ label: '请选择', value: null })
    this.tableForm.filterData.afterFertilizationModeOptionList.push({ label: '撒施', value: 'MODE1' })
    this.tableForm.filterData.afterFertilizationModeOptionList.push({ label: '冲施', value: 'MODE2' })
    this.tableForm.filterData.afterFertilizationModeOptionList.push({ label: '条施', value: 'MODE3' })
    this.tableForm.filterData.afterFertilizationModeOptionList.push({ label: '穴施', value: 'MODE4' })
    this.tableForm.filterData.afterFertilizationModeOptionList.push({ label: '水肥一体化', value: 'MODE5' })
    this.tableForm.filterData.afterFertilizationModeOptionList.push({ label: '机械深施', value: 'MODE7' })
    this.tableForm.filterData.afterFertilizationModeOptionList.push({ label: '无人机施肥', value: 'MODE8' })
    this.tableForm.filterData.afterFertilizationModeOptionList.push({ label: '其他', value: 'MODE6' })

    this.tableForm.filterData.afterFertilizerFillInModeOptionList.push({ label: '请选择', value: null })
    this.tableForm.filterData.afterFertilizerFillInModeOptionList.push({ label: '分次填报', value: 'MANY_TIMES' })
    this.tableForm.filterData.afterFertilizerFillInModeOptionList.push({ label: '合计填报', value: 'TOTAL' })
    this.tableForm.filterData.afterFertilizerFillInModeOptionList.push({ label: '无追肥', value: 'NONE' })
  },
  mounted() {
    let that = this
    Screenfull.on('change', function() {
      that.$nextTick(() => {
        that.isFullscreen = Screenfull.isFullscreen
        that.tableHeight = Screenfull.isFullscreen ? window.innerHeight - 120 : '600px'
      })
    })
  },
  destroy() {
    if (Screenfull.enabled) {
      Screenfull.off('change', this.change)
    }
  },
  methods: {
    handleFarmerSelectDialogClose(rows) {
      if (rows.length > 0) {
        this.flags.enableAdd = true
        // this.tableForm.newRowCount = rows.length
        // this.tableForm.recordTotal = this.tableForm.recordTotal + rows.length
        // this.tableForm.pageIndex = 1
        let index = 1

        this.tableForm.tableData = []
        this.tableForm.recordTotal = rows.length
        this.tableForm.pageIndex = 1

        rows.forEach(el => {
          let row = {
            editFlag: true,
            index: index++,
            filterData: {
              monitoringPlotOptionList: []
            },
            farmer: this.mappingFarmer(null, el),
            monitoringPlot: this.mappingMonitoringPlot(null, null),
            plantCrop: this.mappingPlantCrop(null, null),
            baseFertilizer: this.mappingBaseFertilizer(null, null),
            afterFertilizerTotal: this.mappingAfterFertilizerTotal(null, null),
            afterFertilizerList: []
          }

          for (let i = 0; i < 5; i++) {
            row.afterFertilizerList.push(this.mappingAfterFertilizer(null, null))
          }
          row.filterData.monitoringPlotOptionList.push({ label: '请选择', value: null })
          MonitoringPlotApi.getStubList(row.farmer.id, false)
            .then(res => {
              res.data.forEach(el => {
                row.filterData.monitoringPlotOptionList.push({
                  label: el.name,
                  value: el.id,
                  code: el.code,
                  plotArea: el.plotArea
                })
              })
            })
            .catch(err => {
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
          this.tableForm.tableData.push(row)
        })
      }
      this.flags.loadFarmerSelectDialog = false
    },
    handleUploadPlantCropImportFileDialogClose() {
      this.flags.loadUploadPlantCropImportFileDialog = false
    },
    handleUploadPlantCropImportFileSuccess(savedFileName) {
      this.importData(this.params.year, savedFileName)
    },
    handleAddUser() {
      this.$router.push({ path: '/user' })
    },
    handleAdd() {
      this.flags.loadFarmerSelectDialog = true
      this.$nextTick(() => {
        this.$refs.farmerSelectDialog.open(this.params.regionId)
      })
    },
    handleSave() {
      this.$refs.tableForm.validate(async valid => {
        if (!valid) {
          this.$message({ showClose: true, message: '保存验证失败！', type: 'warning' })
          return
        }
        for (let i = 0; i < this.tableForm.tableData.length; i++) {
          await this.create(this.tableForm.tableData[i])
        }

        this.flags.enableAdd = false
        this.bindTableData()
      })
    },
    handleCancel() {
      this.bindTableData()
      this.tableForm.pageIndex = 1
      this.flags.enableAdd = false
    },
    handleEdit() {
      this.flags.enableEdit = true

      const loading = this.$loading({
        lock: true,
        text: '准备编辑......',
        spinner: 'el-icon-loading'
      })
      setTimeout(() => {
        loading.close()
      }, 1 * 1000)
    },
    handleEditEnd() {
      if (null !== this.tableForm.clickedRow) {
        this.$refs.tableForm.validate(async valid => {
          if (!valid) {
            this.$message({ showClose: true, message: '保存验证失败！', type: 'warning' })
            return
          }

          let assistFillPlantCrop = this.models.assistFillPlantCropArray[this.tableForm.clickedRow.plantCrop.id]
          if (!this.compareRowData(this.tableForm.clickedRow, assistFillPlantCrop)) {
            await this.update(this.tableForm.clickedRow)
          }

          this.reloadRowData(this.tableForm.clickedRow)
          this.tableForm.clickedRow.editFlag = false
          this.tableForm.clickedRow = null
          this.flags.enableEdit = false

          const loading = this.$loading({
            lock: true,
            text: '结束编辑......',
            spinner: 'el-icon-loading'
          })

          setTimeout(() => {
            loading.close()
          }, 1 * 1000)
        })
      } else {
        this.flags.enableEdit = false
        const loading = this.$loading({
          lock: true,
          text: '结束编辑......',
          spinner: 'el-icon-loading'
        })

        setTimeout(() => {
          loading.close()
        }, 1 * 1000)
      }
    },
    handleEditCancel() {
      if (null !== this.tableForm.clickedRow) {
        let model = this.models.assistFillPlantCropArray[this.tableForm.clickedRow.plantCrop.id]
        if (this.compareRowData(this.tableForm.clickedRow, model)) {
          this.flags.enableEdit = false
          this.tableForm.clickedRow.editFlag = false
          this.tableForm.clickedRow = null
          return
        }

        let row = this.tableForm.clickedRow
        let farmerModel = model.farmer
        let monitoringPlotModel = model.monitoringPlot
        let plantCropModel = model.plantCrop
        let baseFertilizerModel = model.baseFertilizer
        let afterFertilizerModelArray = model.afterFertilizerArray
        let afterFertilizerTotalModel = model.afterFertilizerTotal

        row.editFlag = false
        // mapping farmer
        this.mappingFarmer(row.farmer, farmerModel)
        // mapping monitoringPlot
        this.mappingMonitoringPlot(row.monitoringPlot, monitoringPlotModel)
        // mapping plantCrop
        this.mappingPlantCrop(row.plantCrop, plantCropModel)
        // mapping baseFertilizerModel
        this.mappingBaseFertilizer(row.baseFertilizer, baseFertilizerModel)
        // mapping afterFertilizerTotal
        this.mappingAfterFertilizerTotal(row.afterFertilizerTotal, null)
        // mapping afterFertilizerModel
        row.afterFertilizerList = []
        let afterFertilizerCount = 0
        if ('TOTAL' === plantCropModel.afterFertilizerFillInMode) {
          this.mappingAfterFertilizerTotal(row.afterFertilizerTotal, afterFertilizerTotalModel)
        } else if ('MANY_TIMES' === plantCropModel.afterFertilizerFillInMode) {
          for (let key in afterFertilizerModelArray) {
            row.afterFertilizerList.push(this.mappingAfterFertilizer(undefined, afterFertilizerModelArray[key]))
            ++afterFertilizerCount
          }
        }
        for (; afterFertilizerCount < 5; ++afterFertilizerCount) {
          row.afterFertilizerList.push(this.mappingAfterFertilizer(undefined, null))
        }
      }

      this.flags.enableEdit = false
      this.tableForm.clickedRow = null

      const loading = this.$loading({
        lock: true,
        text: '取消编辑......',
        spinner: 'el-icon-loading'
      })
      setTimeout(() => {
        loading.close()
      }, 1 * 1000)
    },
    handleDeleteSelected() {
      if (0 === this.tableForm.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      let theObj = this
      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          for (let i = 0; i < theObj.tableForm.selectedRows.length; i++) {
            await theObj.delete(theObj.tableForm.selectedRows[i])
          }
          theObj.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    handleSubmitSelected() {
      if (0 === this.tableForm.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      let theObj = this
      this.$confirm('此操作将提交数据进行审核, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          for (let i = 0; i < theObj.tableForm.selectedRows.length; i++) {
            await theObj.submit(theObj.tableForm.selectedRows[i])
          }
          theObj.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消操作！', type: 'info' })
        })
    },
    handleTemplateDownload() {
      this.$confirm('导出数据模板, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let exportId = null
          let oldExportId = null
          let exportCount = 0
          let finished = false
          let sheetName = null

          // export fill data
          while (!finished) {
            const loading = this.$loading({
              lock: true,
              text: 0 == exportCount ? '正在导出数据......' : '已导出' + exportCount + '条' + sheetName + '数据......',
              spinner: 'el-icon-loading'
            })

            await AssistFillInPlantCropApi.exportTemplate(exportId, this.params.year, this.params.regionId)
              .then(res => {
                exportId = res.data.id
                exportCount = res.data.exportCount
                finished = res.data.finished
                sheetName = res.data.sheetName
                loading.close()
              })
              .catch(err => {
                loading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          }

          // export farmer
          oldExportId = exportId
          exportId = null
          exportCount = 0
          finished = false

          while (!finished) {
            const loading = this.$loading({
              lock: true,
              text: 0 == exportCount ? '正在导出数据......' : '已导出' + exportCount + '条' + sheetName + '数据......',
              spinner: 'el-icon-loading'
            })

            await FarmerApi.exportFarmerData(exportId, this.params.regionId, oldExportId)
              .then(res => {
                exportId = res.data.id
                exportCount = res.data.exportCount
                finished = res.data.finished
                sheetName = res.data.sheetName
                loading.close()
              })
              .catch(err => {
                loading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          }

          // export region
          oldExportId = exportId
          exportId = null
          exportCount = 0
          finished = false

          while (!finished) {
            const loading = this.$loading({
              lock: true,
              text: 0 == exportCount ? '正在导出数据......' : '已导出' + exportCount + '条' + sheetName + '数据......',
              spinner: 'el-icon-loading'
            })

            await RegionApi.exportDistrictData(exportId, this.params.regionId, oldExportId)
              .then(res => {
                exportId = res.data.id
                exportCount = res.data.exportCount
                finished = res.data.finished
                sheetName = res.data.sheetName
                loading.close()
              })
              .catch(err => {
                loading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          }

          const loading = this.$loading({
            lock: true,
            text: '等待下载数据文件......',
            spinner: 'el-icon-loading'
          })

          await FileApi.get('TEMP', exportId + '.xlsx')
            .then(res => {
              let byteBuffer = window.atob(res.data)
              let arrayBuffer = new ArrayBuffer(byteBuffer.length)
              let dataBuffer = new Uint8Array(arrayBuffer)
              for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

              let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(blob)
              link.setAttribute('download', '数据模板.xlsx')
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(link.href)
              document.body.removeChild(link)
              loading.close()
            })
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })

          await FileApi.delete('TEMP', exportId + '.xlsx')
            .then(() => {
            })
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
        })
    },
    handleImportData() {
      this.flags.loadUploadPlantCropImportFileDialog = true
      this.$nextTick(() => {
        this.$refs.uploadPlantCropImportFileDialog.open()
      })
    },
    handleExportData() {
      this.$confirm('导出未提交数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let exportId = null
          let exportCount = 0
          let finished = false

          while (!finished) {
            const loading = this.$loading({
              lock: true,
              text: 0 == exportCount ? '正在导出数据......' : '已导出' + exportCount + '条数据......',
              spinner: 'el-icon-loading'
            })

            await AssistFillInPlantCropApi.exportData(exportId, this.params.year, this.params.regionId, false, null, null)
              .then(res => {
                exportId = res.data.id
                exportCount = res.data.exportCount
                finished = res.data.finished
                loading.close()
              })
              .catch(err => {
                loading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          }

          const loading = this.$loading({
            lock: true,
            text: '等待下载数据文件......',
            spinner: 'el-icon-loading'
          })

          await FileApi.get('TEMP', exportId + '.xlsx')
            .then(res => {
              let byteBuffer = window.atob(res.data)
              let arrayBuffer = new ArrayBuffer(byteBuffer.length)
              let dataBuffer = new Uint8Array(arrayBuffer)
              for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

              let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(blob)
              link.setAttribute('download', '未提交数据.xlsx')
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(link.href)
              document.body.removeChild(link)
              loading.close()
            })
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })

          await FileApi.delete('TEMP', exportId + '.xlsx')
            .then(() => {
            })
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
        })
    },
    handleFullscreen() {
      if (!Screenfull.isEnabled) {
        this.$message({
          message: '浏览器不支持',
          type: 'warning'
        })
        return false
      }

      Screenfull.request(this.$refs.unsubmittedPlantCropDataPane)
    },
    handleExtFullscreen() {
      if (!Screenfull.isEnabled) {
        this.$message({
          message: '浏览器不支持',
          type: 'warning'
        })
        return false
      }

      Screenfull.exit()
    },
    handleSizeChange(pageSize) {
      this.tableForm.clickedRow = null
      this.tableForm.pageIndex = 1
      this.tableForm.pageSize = pageSize
      this.bindTableData()
    },
    handlePagingChange(pageIndex) {
      this.tableForm.clickedRow = null
      this.tableForm.pageIndex = pageIndex
      this.bindTableData()
    },
    handleCropSubCategoryChange(value, row) {
      row.plantCrop.cropId = null
      row.plantCrop.cropName = null

      let cropSubCategory = this.cropSubCategoryArray.find(m => m.id === value)
      if (null != cropSubCategory) {
        row.plantCrop.cropSubCategoryName = cropSubCategory.name

        let cropRootCategory = this.cropRootCategoryArray.find(m => m.id === cropSubCategory.parentId)
        if (null !== cropRootCategory) {
          row.plantCrop.cropRootCategoryId = cropRootCategory.id
          row.plantCrop.cropRootCategoryName = cropRootCategory.name
        }
      }
    },
    queryCropName(queryStr, cb, row) {
      let cropOptionList = []
      if (this.$utils.isNotEmpty(row.plantCrop.cropSubCategoryId)) {
        let tempCropArray = this.cropArray.filter(m => {
          return m.subCategoryId === row.plantCrop.cropSubCategoryId
        })

        tempCropArray.forEach(el => {
          cropOptionList.push({ value: el.name, id: el.id })
        })
      }
      cb(cropOptionList)
    },
    handleCropNameSelect(item, row) {
      row.plantCrop.cropId = item.id
      row.plantCrop.cropName = item.value
    },
    handleCropNameInput(value, row) {
      let crop = this.cropArray.find(m => m.name === value)
      if (undefined === crop)
        row.plantCrop.cropId = null
      else
        row.plantCrop.cropId = crop.id
    },
    handlePreviousCropSubCategoryChange(value, row) {
      if (value === null || value === 'NONE') {
        row.plantCrop.previousCropSubCategoryName = '无'
        row.plantCrop.previousYield = null
        row.plantCrop.previousStrawReturnRate = null
      } else {
        let cropSubCategory = this.cropSubCategoryArray.find(m => m.id === value)
        if (undefined !== cropSubCategory)
          row.plantCrop.previousCropSubCategoryName = cropSubCategory.name
      }
    },
    handleSelectionChange(rows) {
      this.tableForm.selectedRows = rows
    },
    handleRowMonitoringPlotChange(value, row) {
      let optionArray = row.filterData.monitoringPlotOptionList.filter(el => {
        return el.value === value
      })
      row.monitoringPlot.code = optionArray[0].code
      row.monitoringPlot.plotArea = optionArray[0].plotArea
    },
    handleRowClick(row) {
      if (!this.flags.enableEdit) return

      if (0 === row.filterData.monitoringPlotOptionList.length) {
        row.filterData.monitoringPlotOptionList.push({ label: '请选择', value: null })
        MonitoringPlotApi.getStubList(row.farmer.id, false)
          .then(res => {
            res.data.forEach(el => {
              row.filterData.monitoringPlotOptionList.push({
                label: el.regionId + el.name,
                value: el.id,
                code: el.code,
                plotArea: el.plotArea
              })
            })
          })
          .catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          })
      }

      if (row.plantCrop.submitFlag) {
        this.$message({ showClose: true, message: '数据已经提交，不可编辑！', type: 'warning' })
        return
      }

      if (null == this.tableForm.clickedRow) {
        row.editFlag = true
        this.tableForm.clickedRow = row
        return
      }

      if (row.plantCrop.id != this.tableForm.clickedRow.plantCrop.id) {
        // compare row value was changed
        this.$refs.tableForm.validate(async valid => {
          if (valid) {
            let assistFillPlantCrop = this.models.assistFillPlantCropArray[this.tableForm.clickedRow.plantCrop.id]
            if (!this.compareRowData(this.tableForm.clickedRow, assistFillPlantCrop)) {
              await this.update(this.tableForm.clickedRow)
              this.reloadRowData(this.tableForm.clickedRow)
            }
            this.tableForm.clickedRow.editFlag = false
            row.editFlag = true
            this.tableForm.clickedRow = row
          } else {
            this.$message({ showClose: true, message: '保存验证失败！', type: 'warning' })
            return
          }
        })
      }
    },
    handleAfterFertilizerFillInModeChange(selectedValue, rowIndex) {
      if ('TOTAL' === selectedValue) {
        for (let i = 0; i < 5; i++) {
          this.tableForm.tableData[rowIndex].afterFertilizerList[i] = this.mappingAfterFertilizer(undefined)
        }
      } else if ('MANY_TIMES' === selectedValue) {
        this.tableForm.tableData[rowIndex].afterFertilizerTotal = this.mappingAfterFertilizerTotal(undefined)
      } else {
        this.tableForm.tableData[rowIndex].afterFertilizerTotal = this.mappingAfterFertilizerTotal(undefined)
        for (let i = 0; i < 5; i++) {
          this.tableForm.tableData[rowIndex].afterFertilizerList[i] = this.mappingAfterFertilizer(undefined)
        }
      }
    },
    handleRowBaseFertilizerValueChange(value, field, row) {
      if ('fertilizationMode' === field && this.$utils.isEmpty(value)) {
        row.baseFertilizer.fertilizationModeName = null
        row.baseFertilizer.fertilizationDate = null
        row.baseFertilizer.businessOrganicFertilizerDosage = null
        row.baseFertilizer.businessOrganicFertilizerPrice = null
        row.baseFertilizer.organicFertilizer1Id = null
        row.baseFertilizer.organicFertilizer1Name = null
        row.baseFertilizer.organicFertilizer1Dosage = null
        row.baseFertilizer.organicFertilizer2Id = null
        row.baseFertilizer.organicFertilizer2Name = null
        row.baseFertilizer.organicFertilizer2Dosage = null
        row.baseFertilizer.urea = null
        row.baseFertilizer.ammoniumBicarbonate = null
        row.baseFertilizer.diammoniumPhosphate = null
        row.baseFertilizer.potassiumChloride = null
        row.baseFertilizer.potassiumSulphate = null
        row.baseFertilizer.compoundFertilizerDosage = null
        row.baseFertilizer.compoundFertilizerN = null
        row.baseFertilizer.compoundFertilizerP2O5 = null
        row.baseFertilizer.compoundFertilizerK2O = null
        row.baseFertilizer.waterSolubleFertilizerDosage = null
        row.baseFertilizer.waterSolubleFertilizerN = null
        row.baseFertilizer.waterSolubleFertilizerP2O5 = null
        row.baseFertilizer.waterSolubleFertilizerK2O = null
        row.baseFertilizer.otherFertilizer1Id = null
        row.baseFertilizer.otherFertilizer1Name = null
        row.baseFertilizer.otherFertilizer1Dosage = null
        row.baseFertilizer.otherFertilizer2Id = null
        row.baseFertilizer.otherFertilizer2Name = null
        row.baseFertilizer.otherFertilizer2Dosage = null
      }
      if ('businessOrganicFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.baseFertilizer.businessOrganicFertilizerPrice = null
      }
      if ('organicFertilizer1Id' === field && this.$utils.isEmpty(value)) {
        row.baseFertilizer.organicFertilizer1Dosage = null
      }
      if ('organicFertilizer2Id' === field && this.$utils.isEmpty(value)) {
        row.baseFertilizer.organicFertilizer2Dosage = null
      }
      if ('compoundFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.baseFertilizer.compoundFertilizerN = null
        row.baseFertilizer.compoundFertilizerP2O5 = null
        row.baseFertilizer.compoundFertilizerK2O = null
      }
      if ('waterSolubleFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.baseFertilizer.waterSolubleFertilizerN = null
        row.baseFertilizer.waterSolubleFertilizerP2O5 = null
        row.baseFertilizer.waterSolubleFertilizerK2O = null
      }
      if ('otherFertilizer1Id' === field && this.$utils.isEmpty(value)) {
        row.baseFertilizer.otherFertilizer1Dosage = null
      }
      if ('otherFertilizer2Id' === field && this.$utils.isEmpty(value)) {
        row.baseFertilizer.otherFertilizer2Dosage = null
      }
    },
    handleRowAfterFertilizerTotalValueChange(value, field, row) {
      if ('fertilizationMode' === field && this.$utils.isEmpty(value)) {
        row.afterFertilizerTotal.fertilizationModeName = null
        row.afterFertilizerTotal.afterFertilizationTimes = null
        row.afterFertilizerTotal.afterFertilizationDate = null
        row.afterFertilizerTotal.businessOrganicFertilizerDosage = null
        row.afterFertilizerTotal.urea = null
        row.afterFertilizerTotal.ammoniumBicarbonate = null
        row.afterFertilizerTotal.diammoniumPhosphate = null
        row.afterFertilizerTotal.potassiumChloride = null
        row.afterFertilizerTotal.potassiumSulphate = null
        row.afterFertilizerTotal.compoundFertilizerDosage = null
        row.afterFertilizerTotal.compoundFertilizerN = null
        row.afterFertilizerTotal.compoundFertilizerP2O5 = null
        row.afterFertilizerTotal.compoundFertilizerK2O = null
        row.afterFertilizerTotal.waterSolubleFertilizerDosage = null
        row.afterFertilizerTotal.waterSolubleFertilizerN = null
        row.afterFertilizerTotal.waterSolubleFertilizerP2O5 = null
        row.afterFertilizerTotal.waterSolubleFertilizerK2O = null
        row.afterFertilizerTotal.otherFertilizer1Id = null
        row.afterFertilizerTotal.otherFertilizer1Name = null
        row.afterFertilizerTotal.otherFertilizer1Dosage = null
        row.afterFertilizerTotal.otherFertilizer2Id = null
        row.afterFertilizerTotal.otherFertilizer2Name = null
        row.afterFertilizerTotal.otherFertilizer2Dosage = null
      }
      if ('businessOrganicFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.afterFertilizerTotal.businessOrganicFertilizerPrice = null
      }
      if ('compoundFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.afterFertilizerTotal.compoundFertilizerN = null
        row.afterFertilizerTotal.compoundFertilizerP2O5 = null
        row.afterFertilizerTotal.compoundFertilizerK2O = null
      }
      if ('waterSolubleFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.afterFertilizerTotal.waterSolubleFertilizerN = null
        row.afterFertilizerTotal.waterSolubleFertilizerP2O5 = null
        row.afterFertilizerTotal.waterSolubleFertilizerK2O = null
      }
      if ('otherFertilizer1Id' === field && this.$utils.isEmpty(value)) {
        row.afterFertilizerTotal.otherFertilizer1Dosage = null
      }
      if ('otherFertilizer2Id' === field && this.$utils.isEmpty(value)) {
        row.afterFertilizerTotal.otherFertilizer2Dosage = null
      }
    },
    handleRowAfterFertilizerValueChange(value, field, row, index) {
      if ('fertilizationMode' === field && this.$utils.isEmpty(value)) {
        row.afterFertilizerList[index].fertilizationModeName = null
        row.afterFertilizerList[index].afterFertilizationDate = null
        row.afterFertilizerList[index].businessOrganicFertilizerDosage = null
        row.afterFertilizerList[index].urea = null
        row.afterFertilizerList[index].ammoniumBicarbonate = null
        row.afterFertilizerList[index].diammoniumPhosphate = null
        row.afterFertilizerList[index].potassiumChloride = null
        row.afterFertilizerList[index].potassiumSulphate = null
        row.afterFertilizerList[index].compoundFertilizerDosage = null
        row.afterFertilizerList[index].compoundFertilizerN = null
        row.afterFertilizerList[index].compoundFertilizerP2O5 = null
        row.afterFertilizerList[index].compoundFertilizerK2O = null
        row.afterFertilizerList[index].waterSolubleFertilizerDosage = null
        row.afterFertilizerList[index].waterSolubleFertilizerN = null
        row.afterFertilizerList[index].waterSolubleFertilizerP2O5 = null
        row.afterFertilizerList[index].waterSolubleFertilizerK2O = null
        row.afterFertilizerList[index].otherFertilizer1Id = null
        row.afterFertilizerList[index].otherFertilizer1Name = null
        row.afterFertilizerList[index].otherFertilizer1Dosage = null
        row.afterFertilizerList[index].otherFertilizer2Id = null
        row.afterFertilizerList[index].otherFertilizer2Name = null
        row.afterFertilizerList[index].otherFertilizer2Dosage = null
      }
      if ('businessOrganicFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.afterFertilizerList[index].businessOrganicFertilizerPrice = null
      }
      if ('compoundFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.afterFertilizerList[index].compoundFertilizerN = null
        row.afterFertilizerList[index].compoundFertilizerP2O5 = null
        row.afterFertilizerList[index].compoundFertilizerK2O = null
      }
      if ('waterSolubleFertilizerDosage' === field && this.$utils.isEmpty(value)) {
        row.afterFertilizerList[index].waterSolubleFertilizerN = null
        row.afterFertilizerList[index].waterSolubleFertilizerP2O5 = null
        row.afterFertilizerList[index].waterSolubleFertilizerK2O = null
      }
      if ('otherFertilizer1Id' === field && this.$utils.isEmpty(value)) {
        row.afterFertilizerList[index].otherFertilizer1Dosage = null
      }
      if ('otherFertilizer2Id' === field && this.$utils.isEmpty(value)) {
        row.afterFertilizerList[index].otherFertilizer2Dosage = null
      }
    },
    bindTableData() {
      this.dataLoading = true
      AssistFillInPlantCropApi.getPaging(this.params.year, this.params.regionId, false, null, null, this.tableForm.pageSize, this.tableForm.pageIndex)
        .then(res => {
          this.models.assistFillPlantCropArray = []
          this.tableForm.tableData = []
          this.tableForm.recordTotal = res.data.totalCount
          this.exportPageCount = Math.ceil(res.data.totalCount / 5000)
          let cropArray = []
          this.tableForm.columnFilterData.cropOptionList = []
          let index = 0

          res.data.plantCropList.forEach(el => {
            let farmerModel = el.farmer
            let monitoringPlotModel = el.monitoringPlot
            let plantCropModel = el.plantCrop
            let baseFertilizerModel = el.baseFertilizer
            let afterFertilizerModelList = el.afterFertilizerList

            if (undefined === cropArray[plantCropModel.cropId]) cropArray[plantCropModel.cropId] = plantCropModel.cropName

            this.models.assistFillPlantCropArray[plantCropModel.id] = {
              farmer: farmerModel,
              monitoringPlot: monitoringPlotModel,
              plantCrop: plantCropModel,
              baseFertilizer: baseFertilizerModel,
              afterFertilizerTotal: {
                id: null,
                plantCropId: null,
                afterFertilizationTimes: null,
                afterFertilizationDate: null,
                businessOrganicFertilizerDosage: null,
                urea: null,
                ammoniumBicarbonate: null,
                diammoniumPhosphate: null,
                potassiumChloride: null,
                potassiumSulphate: null,
                compoundFertilizerDosage: null,
                compoundFertilizerN: null,
                compoundFertilizerP2O5: null,
                compoundFertilizerK2O: null,
                waterSolubleFertilizerDosage: null,
                waterSolubleFertilizerN: null,
                waterSolubleFertilizerP2O5: null,
                waterSolubleFertilizerK2O: null,
                otherFertilizer1Id: null,
                otherFertilizer1Name: null,
                otherFertilizer1Dosage: null,
                otherFertilizer2Id: null,
                otherFertilizer2Name: null,
                otherFertilizer2Dosage: null,
                fertilizationMode: null,
                fertilizationModeName: null,
                createdOn: null,
                modifiedOn: null
              },
              afterFertilizerArray: []
            }

            if ('TOTAL' === plantCropModel.afterFertilizerFillInMode && afterFertilizerModelList.length === 1) {
              this.models.assistFillPlantCropArray[plantCropModel.id].afterFertilizerTotal = afterFertilizerModelList[0]
            }
            if ('MANY_TIMES' === plantCropModel.afterFertilizerFillInMode) {
              afterFertilizerModelList.forEach(elAfterFertilizer => {
                this.models.assistFillPlantCropArray[plantCropModel.id].afterFertilizerArray[elAfterFertilizer.id] = elAfterFertilizer
              })
            }

            let row = {
              index: ++index,
              editFlag: false,
              filterData: {
                monitoringPlotOptionList: []
              },
              farmer: this.mappingFarmer(undefined, farmerModel),
              monitoringPlot: this.mappingMonitoringPlot(undefined, monitoringPlotModel),
              plantCrop: this.mappingPlantCrop(undefined, plantCropModel),
              baseFertilizer: this.mappingBaseFertilizer(undefined, baseFertilizerModel),
              afterFertilizerTotal: this.mappingAfterFertilizerTotal(undefined),
              afterFertilizerList: []
            }
            let afterFertilizerCount = 0
            if ('TOTAL' === plantCropModel.afterFertilizerFillInMode) {
              if (1 === afterFertilizerModelList.length) {
                let afterFertilizerTotalModel = afterFertilizerModelList[0]
                this.mappingAfterFertilizerTotal(row.afterFertilizerTotal, afterFertilizerTotalModel)
              }
            } else if ('MANY_TIMES' === plantCropModel.afterFertilizerFillInMode) {
              afterFertilizerModelList.forEach(elAfterFertilizer => {
                row.afterFertilizerList.push(this.mappingAfterFertilizer(undefined, elAfterFertilizer))
                ++afterFertilizerCount
              })
            }
            for (; afterFertilizerCount < 5; ++afterFertilizerCount) {
              row.afterFertilizerList.push(this.mappingAfterFertilizer(undefined))
            }
            this.tableForm.tableData.push(row)
          })

          for (let key in cropArray)
            this.tableForm.columnFilterData.cropOptionList.push({
              text: cropArray[key],
              value: key
            })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
        .finally(() => {
          this.dataLoading = false
        })
    },
    compareRowData(row, model) {
      // compare farmer
      if (!this.$utils.equals(row.farmer.fullName, model.farmer.fullName)) return false
      if (!this.$utils.equals(row.farmer.detailedAddress, model.farmer.detailedAddress)) return false
      if (!this.$utils.equals(row.farmer.farmerNature, model.farmer.farmerNature)) return false
      if (!this.$utils.equals(row.farmer.educationalLevel, model.farmer.educationalLevel)) return false
      if (!this.$utils.equals(row.farmer.cultivationLandArea, model.farmer.cultivationLandArea)) return false
      if (!this.$utils.equals(row.farmer.modelHouseholdFlag, model.farmer.modelHouseholdFlag)) return false
      // compare monitoringPlot
      if (!this.$utils.equals(row.monitoringPlot.id, model.monitoringPlot.id)) return false
      // compare plantCrop
      if (!this.$utils.equals(row.plantCrop.cropRootCategoryId, model.plantCrop.cropRootCategoryId)) return false
      if (!this.$utils.equals(row.plantCrop.cropRootCategoryName, model.plantCrop.cropRootCategoryName)) return false
      if (!this.$utils.equals(row.plantCrop.cropSubCategoryId, model.plantCrop.cropSubCategoryId)) return false
      if (!this.$utils.equals(row.plantCrop.cropSubCategoryName, model.plantCrop.cropSubCategoryName)) return false
      if (!this.$utils.equals(row.plantCrop.cropId, model.plantCrop.cropId)) return false
      if (!this.$utils.equals(row.plantCrop.cropName, model.plantCrop.cropName)) return false
      if (!this.$utils.equals(row.plantCrop.cropRemark, model.plantCrop.cropRemark)) return false
      if (!this.$utils.equals(this.$moment(row.plantCrop.sowingDate).format('YYYY-MM-DD'), this.$moment(model.plantCrop.sowingDate).format('YYYY-MM-DD'))) return false
      if (!this.$utils.equals(this.$moment(row.plantCrop.harvestTime).format('YYYY-MM-DD'), this.$moment(model.plantCrop.harvestTime).format('YYYY-MM-DD'))) return false
      if (!this.$utils.equals(row.plantCrop.sowingArea, model.plantCrop.sowingArea)) return false
      if (!this.$utils.equals(row.plantCrop.plotFertility, model.plantCrop.plotFertility)) return false
      if (!this.$utils.equals(row.plantCrop.yield, model.plantCrop.yield)) return false
      if (!this.$utils.equals(row.plantCrop.yieldLevel, model.plantCrop.yieldLevel)) return false
      if (!this.$utils.equals(row.plantCrop.facilityCultivationFlag, model.plantCrop.facilityCultivationFlag)) return false
      if (!this.$utils.equals(row.plantCrop.happenDisasterFlag, model.plantCrop.happenDisasterFlag)) return false
      let tempPreviousCropSubCategoryId = model.plantCrop.previousCropSubCategoryId ?? 'NONE'
      if (!this.$utils.equals(row.plantCrop.previousCropSubCategoryId, tempPreviousCropSubCategoryId)) return false
      if (!this.$utils.equals(row.plantCrop.previousCropSubCategoryName, model.plantCrop.previousCropSubCategoryName)) return false
      if (!this.$utils.equals(row.plantCrop.previousYield, model.plantCrop.previousYield)) return false
      if (!this.$utils.equals(row.plantCrop.previousStrawReturnRate, model.plantCrop.previousStrawReturnRate)) return false
      if (!this.$utils.equals(row.plantCrop.afterFertilizerFillInMode, model.plantCrop.afterFertilizerFillInMode)) return false

      // compare baseFertilizer
      if (this.$utils.isEmpty(row.baseFertilizer.id)) {
        if (this.$utils.isNotEmpty(row.baseFertilizer.fertilizationDate)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.businessOrganicFertilizerDosage)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.businessOrganicFertilizerPrice)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.organicFertilizer1Id)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.organicFertilizer1Dosage)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.organicFertilizer2Id)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.organicFertilizer2Dosage)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.urea)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.ammoniumBicarbonate)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.diammoniumPhosphate)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.potassiumChloride)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.potassiumSulphate)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.compoundFertilizerDosage)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.compoundFertilizerN)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.compoundFertilizerP2O5)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.compoundFertilizerK2O)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.waterSolubleFertilizerDosage)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.waterSolubleFertilizerN)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.waterSolubleFertilizerP2O5)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.waterSolubleFertilizerK2O)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.otherFertilizer1Id)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.otherFertilizer1Dosage)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.otherFertilizer2Id)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.otherFertilizer2Dosage)) return false
        if (this.$utils.isNotEmpty(row.baseFertilizer.fertilizationMode)) return false
      } else {
        if (!this.$utils.equals(this.$moment(row.baseFertilizer.fertilizationDate).format('YYYY-MM-DD'), this.$moment(model.baseFertilizer.fertilizationDate).format('YYYY-MM-DD'))) return false
        if (!this.$utils.equals(row.baseFertilizer.businessOrganicFertilizerDosage, model.baseFertilizer.businessOrganicFertilizerDosage)) return false
        if (!this.$utils.equals(row.baseFertilizer.businessOrganicFertilizerPrice, model.baseFertilizer.businessOrganicFertilizerPrice)) return false
        if (!this.$utils.equals(row.baseFertilizer.organicFertilizer1Id, model.baseFertilizer.organicFertilizer1Id)) return false
        if (!this.$utils.equals(row.baseFertilizer.organicFertilizer1Dosage, model.baseFertilizer.organicFertilizer1Dosage)) return false
        if (!this.$utils.equals(row.baseFertilizer.organicFertilizer2Id, model.baseFertilizer.organicFertilizer2Id)) return false
        if (!this.$utils.equals(row.baseFertilizer.organicFertilizer2Dosage, model.baseFertilizer.organicFertilizer2Dosage)) return false
        if (!this.$utils.equals(row.baseFertilizer.urea, model.baseFertilizer.urea)) return false
        if (!this.$utils.equals(row.baseFertilizer.ammoniumBicarbonate, model.baseFertilizer.ammoniumBicarbonate)) return false
        if (!this.$utils.equals(row.baseFertilizer.diammoniumPhosphate, model.baseFertilizer.diammoniumPhosphate)) return false
        if (!this.$utils.equals(row.baseFertilizer.potassiumChloride, model.baseFertilizer.potassiumChloride)) return false
        if (!this.$utils.equals(row.baseFertilizer.potassiumSulphate, model.baseFertilizer.potassiumSulphate)) return false
        if (!this.$utils.equals(row.baseFertilizer.compoundFertilizerDosage, model.baseFertilizer.compoundFertilizerDosage)) return false
        if (!this.$utils.equals(row.baseFertilizer.compoundFertilizerN, model.baseFertilizer.compoundFertilizerN)) return false
        if (!this.$utils.equals(row.baseFertilizer.compoundFertilizerP2O5, model.baseFertilizer.compoundFertilizerP2O5)) return false
        if (!this.$utils.equals(row.baseFertilizer.compoundFertilizerK2O, model.baseFertilizer.compoundFertilizerK2O)) return false
        if (!this.$utils.equals(row.baseFertilizer.waterSolubleFertilizerDosage, model.baseFertilizer.waterSolubleFertilizerDosage)) return false
        if (!this.$utils.equals(row.baseFertilizer.waterSolubleFertilizerN, model.baseFertilizer.waterSolubleFertilizerN)) return false
        if (!this.$utils.equals(row.baseFertilizer.waterSolubleFertilizerP2O5, model.baseFertilizer.waterSolubleFertilizerP2O5)) return false
        if (!this.$utils.equals(row.baseFertilizer.waterSolubleFertilizerK2O, model.baseFertilizer.waterSolubleFertilizerK2O)) return false
        if (!this.$utils.equals(row.baseFertilizer.otherFertilizer1Id, model.baseFertilizer.otherFertilizer1Id)) return false
        if (!this.$utils.equals(row.baseFertilizer.otherFertilizer1Dosage, model.baseFertilizer.otherFertilizer1Dosage)) return false
        if (!this.$utils.equals(row.baseFertilizer.otherFertilizer2Id, model.baseFertilizer.otherFertilizer2Id)) return false
        if (!this.$utils.equals(row.baseFertilizer.otherFertilizer2Dosage, model.baseFertilizer.otherFertilizer2Dosage)) return false
        if (!this.$utils.equals(row.baseFertilizer.fertilizationMode, model.baseFertilizer.fertilizationMode)) return false
      }
      // compare afterFertilizer total
      if ('TOTAL' === row.plantCrop.afterFertilizerFillInMode) {
        if (this.$utils.isEmpty(row.afterFertilizerTotal.id)) {
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.afterFertilizationTimes)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.afterFertilizationDate)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.businessOrganicFertilizerDosage)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.urea)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.ammoniumBicarbonate)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.diammoniumPhosphate)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.potassiumChloride)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.potassiumSulphate)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.compoundFertilizerDosage)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.compoundFertilizerN)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.compoundFertilizerP2O5)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.compoundFertilizerK2O)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.waterSolubleFertilizerDosage)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.waterSolubleFertilizerN)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.waterSolubleFertilizerP2O5)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.waterSolubleFertilizerK2O)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.otherFertilizer1Id)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.otherFertilizer1Dosage)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.otherFertilizer2Id)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.otherFertilizer2Dosage)) return false
          if (this.$utils.isNotEmpty(row.afterFertilizerTotal.fertilizationMode)) return false
        } else {
          if (!this.$utils.equals(row.afterFertilizerTotal.afterFertilizationTimes, model.afterFertilizerTotal.afterFertilizationTimes)) return false
          if (
            !this.$utils.equals(
              this.$moment(row.afterFertilizerTotal.afterFertilizationDate).format('YYYY-MM-DD'),
              this.$moment(model.afterFertilizerTotal.afterFertilizationDate).format('YYYY-MM-DD')
            )
          )
            return false
          if (!this.$utils.equals(row.afterFertilizerTotal.businessOrganicFertilizerDosage, model.afterFertilizerTotal.businessOrganicFertilizerDosage)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.urea, model.afterFertilizerTotal.urea)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.ammoniumBicarbonate, model.afterFertilizerTotal.ammoniumBicarbonate)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.diammoniumPhosphate, model.afterFertilizerTotal.diammoniumPhosphate)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.potassiumChloride, model.afterFertilizerTotal.potassiumChloride)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.potassiumSulphate, model.afterFertilizerTotal.potassiumSulphate)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.compoundFertilizerDosage, model.afterFertilizerTotal.compoundFertilizerDosage)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.compoundFertilizerN, model.afterFertilizerTotal.compoundFertilizerN)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.compoundFertilizerP2O5, model.afterFertilizerTotal.compoundFertilizerP2O5)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.compoundFertilizerK2O, model.afterFertilizerTotal.compoundFertilizerK2O)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.waterSolubleFertilizerDosage, model.afterFertilizerTotal.waterSolubleFertilizerDosage)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.waterSolubleFertilizerN, model.afterFertilizerTotal.waterSolubleFertilizerN)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.waterSolubleFertilizerP2O5, model.afterFertilizerTotal.waterSolubleFertilizerP2O5)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.waterSolubleFertilizerK2O, model.afterFertilizerTotal.waterSolubleFertilizerK2O)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.otherFertilizer1Id, model.afterFertilizerTotal.otherFertilizer1Id)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.otherFertilizer1Dosage, model.afterFertilizerTotal.otherFertilizer1Dosage)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.otherFertilizer2Id, model.afterFertilizerTotal.otherFertilizer2Id)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.otherFertilizer2Dosage, model.afterFertilizerTotal.otherFertilizer2Dosage)) return false
          if (!this.$utils.equals(row.afterFertilizerTotal.fertilizationMode, model.afterFertilizerTotal.fertilizationMode)) return false
        }
      } else if ('MANY_TIMES' === row.plantCrop.afterFertilizerFillInMode) {
        // compare afterFertilizer
        for (let i = 0; i < row.afterFertilizerList.length; i++) {
          if (this.$utils.isEmpty(row.afterFertilizerList[i].id)) {
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].afterFertilizationDate)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].businessOrganicFertilizerDosage)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].urea)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].ammoniumBicarbonate)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].diammoniumPhosphate)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].potassiumChloride)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].potassiumSulphate)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].compoundFertilizerDosage)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].compoundFertilizerN)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].compoundFertilizerP2O5)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].compoundFertilizerK2O)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].waterSolubleFertilizerDosage)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].waterSolubleFertilizerN)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].waterSolubleFertilizerP2O5)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].waterSolubleFertilizerK2O)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].otherFertilizer1Id)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].otherFertilizer1Dosage)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].otherFertilizer2Id)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].otherFertilizer2Dosage)) return false
            if (this.$utils.isNotEmpty(row.afterFertilizerList[i].fertilizationMode)) return false
          } else {
            let tempAfterFertilizer = model.afterFertilizerArray[row.afterFertilizerList[i].id]
            if (
              !this.$utils.equals(this.$moment(row.afterFertilizerList[i].afterFertilizationDate).format('YYYY-MM-DD'), this.$moment(tempAfterFertilizer.afterFertilizationDate).format('YYYY-MM-DD'))
            )
              return false
            if (!this.$utils.equals(row.afterFertilizerList[i].businessOrganicFertilizerDosage, tempAfterFertilizer.businessOrganicFertilizerDosage)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].urea, tempAfterFertilizer.urea)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].ammoniumBicarbonate, tempAfterFertilizer.ammoniumBicarbonate)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].diammoniumPhosphate, tempAfterFertilizer.diammoniumPhosphate)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].potassiumChloride, tempAfterFertilizer.potassiumChloride)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].potassiumSulphate, tempAfterFertilizer.potassiumSulphate)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].compoundFertilizerDosage, tempAfterFertilizer.compoundFertilizerDosage)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].compoundFertilizerN, tempAfterFertilizer.compoundFertilizerN)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].compoundFertilizerP2O5, tempAfterFertilizer.compoundFertilizerP2O5)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].compoundFertilizerK2O, tempAfterFertilizer.compoundFertilizerK2O)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].waterSolubleFertilizerDosage, tempAfterFertilizer.waterSolubleFertilizerDosage)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].waterSolubleFertilizerN, tempAfterFertilizer.waterSolubleFertilizerN)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].waterSolubleFertilizerP2O5, tempAfterFertilizer.waterSolubleFertilizerP2O5)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].waterSolubleFertilizerK2O, tempAfterFertilizer.waterSolubleFertilizerK2O)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].otherFertilizer1Id, tempAfterFertilizer.otherFertilizer1Id)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].otherFertilizer1Dosage, tempAfterFertilizer.otherFertilizer1Dosage)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].otherFertilizer2Id, tempAfterFertilizer.otherFertilizer2Id)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].otherFertilizer2Dosage, tempAfterFertilizer.otherFertilizer2Dosage)) return false
            if (!this.$utils.equals(row.afterFertilizerList[i].fertilizationMode, tempAfterFertilizer.fertilizationMode)) return false
          }
        }
      }
      return true
    },
    reloadRowData(row) {
      AssistFillInPlantCropApi.getById(row.plantCrop.id)
        .then(res => {
          let farmerModel = res.data.farmer
          let monitoringPlotModel = res.data.monitoringPlot
          let plantCropModel = res.data.plantCrop
          let baseFertilizerModel = res.data.baseFertilizer
          let afterFertilizerModelList = res.data.afterFertilizerList
          // update model
          this.models.assistFillPlantCropArray[plantCropModel.id].farmer = farmerModel
          this.models.assistFillPlantCropArray[plantCropModel.id].monitoringPlot = monitoringPlotModel
          this.models.assistFillPlantCropArray[plantCropModel.id].plantCrop = plantCropModel
          this.models.assistFillPlantCropArray[plantCropModel.id].baseFertilizer = baseFertilizerModel
          this.models.assistFillPlantCropArray[plantCropModel.id].afterFertilizerTotal = {
            id: null,
            plantCropId: null,
            afterFertilizationTimes: null,
            afterFertilizationDate: null,
            businessOrganicFertilizerDosage: null,
            urea: null,
            ammoniumBicarbonate: null,
            diammoniumPhosphate: null,
            potassiumChloride: null,
            potassiumSulphate: null,
            compoundFertilizerDosage: null,
            compoundFertilizerN: null,
            compoundFertilizerP2O5: null,
            compoundFertilizerK2O: null,
            waterSolubleFertilizerDosage: null,
            waterSolubleFertilizerN: null,
            waterSolubleFertilizerP2O5: null,
            waterSolubleFertilizerK2O: null,
            otherFertilizer1Id: null,
            otherFertilizer1Name: null,
            otherFertilizer1Dosage: null,
            otherFertilizer2Id: null,
            otherFertilizer2Name: null,
            otherFertilizer2Dosage: null,
            fertilizationMode: null,
            fertilizationModeName: null,
            createdOn: null,
            modifiedOn: null
          }
          this.models.assistFillPlantCropArray[plantCropModel.id].afterFertilizerArray = []
          if ('TOTAL' === plantCropModel.afterFertilizerFillInMode && afterFertilizerModelList.length === 1) {
            this.models.assistFillPlantCropArray[plantCropModel.id].afterFertilizerTotal = afterFertilizerModelList[0]
          } else if ('MANY_TIMES' === plantCropModel.afterFertilizerFillInMode) {
            afterFertilizerModelList.forEach(elAfterFertilizer => {
              this.models.assistFillPlantCropArray[plantCropModel.id].afterFertilizerArray[elAfterFertilizer.id] = elAfterFertilizer
            })
          }

          // update farmer
          row.farmer = this.mappingFarmer(undefined, farmerModel)
          // update monitoringPlot
          row.monitoringPlot = this.mappingMonitoringPlot(undefined, monitoringPlotModel)
          // update plantCrop
          row.plantCrop = this.mappingPlantCrop(undefined, plantCropModel)
          // udate baseFertilizer
          row.baseFertilizer = this.mappingBaseFertilizer(undefined, baseFertilizerModel)
          // update afterFertilizerTotal
          row.afterFertilizerTotal = this.mappingAfterFertilizerTotal(undefined)
          row.afterFertilizerList = []
          let afterFertilizerCount = 0
          if ('TOTAL' === plantCropModel.afterFertilizerFillInMode) {
            if (1 === afterFertilizerModelList.length) {
              let afterFertilizerTotalModel = afterFertilizerModelList[0]
              this.mappingAfterFertilizerTotal(row.afterFertilizerTotal, afterFertilizerTotalModel)
            }
          } else if ('MANY_TIMES' === plantCropModel.afterFertilizerFillInMode) {
            afterFertilizerModelList.forEach(elAfterFertilizer => {
              row.afterFertilizerList.push(this.mappingAfterFertilizer(undefined, elAfterFertilizer))
              ++afterFertilizerCount
            })
          }
          for (; afterFertilizerCount < 5; afterFertilizerCount++) {
            row.afterFertilizerList.push(this.mappingAfterFertilizer(undefined))
          }
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async create(row) {
      // set plantCropModel
      let monitoringPlot = row.monitoringPlot
      let plantCrop = row.plantCrop
      let baseFertilizer = row.baseFertilizer
      let afterFertilizerTotal = row.afterFertilizerTotal
      let afterFertilizerList = row.afterFertilizerList
      let plantCropModel = {
        year: this.params.year,
        monitoringPlotId: monitoringPlot.id,
        cropRootCategoryId: plantCrop.cropRootCategoryId,
        cropRootCategoryName: plantCrop.cropRootCategoryName,
        cropSubCategoryId: plantCrop.cropSubCategoryId,
        cropSubCategoryName: plantCrop.cropSubCategoryName,
        cropId: plantCrop.cropId,
        cropName: plantCrop.cropName,
        cropRemark: plantCrop.cropRemark,
        sowingDate: null === plantCrop.sowingDate ? null : plantCrop.sowingDate + ' 00:00:00',
        harvestTime: null === plantCrop.harvestTime ? null : plantCrop.harvestTime + ' 00:00:00',
        sowingArea: plantCrop.sowingArea,
        plotFertility: plantCrop.plotFertility,
        yield: plantCrop.yield,
        yieldLevel: plantCrop.yieldLevel,
        facilityCultivationFlag: plantCrop.facilityCultivationFlag,
        happenDisasterFlag: plantCrop.happenDisasterFlag,
        previousCropSubCategoryId: 'NONE' === plantCrop.previousCropSubCategoryId ? null : plantCrop.previousCropSubCategoryId,
        previousCropSubCategoryName: plantCrop.previousCropSubCategoryName,
        previousYield: plantCrop.previousYield,
        previousStrawReturnRate: plantCrop.previousStrawReturnRate,
        afterFertilizerFillInMode: row.plantCrop.afterFertilizerFillInMode
      }
      // set baseFertilizerModel
      let baseFertilizerModel = null
      if (baseFertilizer.fertilizationMode !== null) {
        baseFertilizerModel = {
          fertilizationDate: null === baseFertilizer.fertilizationDate ? null : baseFertilizer.fertilizationDate + ' 00:00:00',
          businessOrganicFertilizerDosage: baseFertilizer.businessOrganicFertilizerDosage,
          businessOrganicFertilizerPrice: baseFertilizer.businessOrganicFertilizerPrice,
          organicFertilizer1Id: baseFertilizer.organicFertilizer1Id,
          organicFertilizer1Dosage: baseFertilizer.organicFertilizer1Dosage,
          organicFertilizer2Id: baseFertilizer.organicFertilizer2Id,
          organicFertilizer2Dosage: baseFertilizer.organicFertilizer2Dosage,
          urea: baseFertilizer.urea,
          ammoniumBicarbonate: baseFertilizer.ammoniumBicarbonate,
          diammoniumPhosphate: baseFertilizer.diammoniumPhosphate,
          potassiumChloride: baseFertilizer.potassiumChloride,
          potassiumSulphate: baseFertilizer.potassiumSulphate,
          compoundFertilizerDosage: baseFertilizer.compoundFertilizerDosage,
          compoundFertilizerN: baseFertilizer.compoundFertilizerN,
          compoundFertilizerP2O5: baseFertilizer.compoundFertilizerP2O5,
          compoundFertilizerK2O: baseFertilizer.compoundFertilizerK2O,
          waterSolubleFertilizerDosage: baseFertilizer.waterSolubleFertilizerDosage,
          waterSolubleFertilizerN: baseFertilizer.waterSolubleFertilizerN,
          waterSolubleFertilizerP2O5: baseFertilizer.waterSolubleFertilizerP2O5,
          waterSolubleFertilizerK2O: baseFertilizer.waterSolubleFertilizerK2O,
          otherFertilizer1Id: baseFertilizer.otherFertilizer1Id,
          otherFertilizer1Dosage: baseFertilizer.otherFertilizer1Dosage,
          otherFertilizer2Id: baseFertilizer.otherFertilizer2Id,
          otherFertilizer2Dosage: baseFertilizer.otherFertilizer2Dosage,
          fertilizationMode: baseFertilizer.fertilizationMode
        }
      }

      let afterFertilizerModelList = []
      // set afterFertilizerTotalModel
      if (row.plantCrop.afterFertilizerFillInMode === 'TOTAL') {
        let afterFertilizerTotalModel = {
          afterFertilizationTimes: afterFertilizerTotal.afterFertilizationTimes,
          afterFertilizationDate: null === afterFertilizerTotal.afterFertilizationDate ? null : afterFertilizerTotal.afterFertilizationDate + ' 00:00:00',
          businessOrganicFertilizerDosage: afterFertilizerTotal.businessOrganicFertilizerDosage,
          urea: afterFertilizerTotal.urea,
          ammoniumBicarbonate: afterFertilizerTotal.ammoniumBicarbonate,
          diammoniumPhosphate: afterFertilizerTotal.diammoniumPhosphate,
          potassiumChloride: afterFertilizerTotal.potassiumChloride,
          potassiumSulphate: afterFertilizerTotal.potassiumSulphate,
          compoundFertilizerDosage: afterFertilizerTotal.compoundFertilizerDosage,
          compoundFertilizerN: afterFertilizerTotal.compoundFertilizerN,
          compoundFertilizerP2O5: afterFertilizerTotal.compoundFertilizerP2O5,
          compoundFertilizerK2O: afterFertilizerTotal.compoundFertilizerK2O,
          waterSolubleFertilizerDosage: afterFertilizerTotal.waterSolubleFertilizerDosage,
          waterSolubleFertilizerN: afterFertilizerTotal.waterSolubleFertilizerN,
          waterSolubleFertilizerP2O5: afterFertilizerTotal.waterSolubleFertilizerP2O5,
          waterSolubleFertilizerK2O: afterFertilizerTotal.waterSolubleFertilizerK2O,
          otherFertilizer1Id: afterFertilizerTotal.otherFertilizer1Id,
          otherFertilizer1Dosage: afterFertilizerTotal.otherFertilizer1Dosage,
          otherFertilizer2Id: afterFertilizerTotal.otherFertilizer2Id,
          otherFertilizer2Dosage: afterFertilizerTotal.otherFertilizer2Dosage,
          fertilizationMode: afterFertilizerTotal.fertilizationMode
        }
        afterFertilizerModelList.push(afterFertilizerTotalModel)
      }

      // set afterFertilizerModelList
      if (row.plantCrop.afterFertilizerFillInMode === 'MANY_TIMES') {
        afterFertilizerList.forEach(el => {
          if (null !== el.fertilizationMode) {
            let afterFertilizerModel = {
              afterFertilizationTimes: null,
              afterFertilizationDate: null === el.afterFertilizationDate ? null : el.afterFertilizationDate + ' 00:00:00',
              businessOrganicFertilizerDosage: el.businessOrganicFertilizerDosage,
              urea: el.urea,
              ammoniumBicarbonate: el.ammoniumBicarbonate,
              diammoniumPhosphate: el.diammoniumPhosphate,
              potassiumChloride: el.potassiumChloride,
              potassiumSulphate: el.potassiumSulphate,
              compoundFertilizerDosage: el.compoundFertilizerDosage,
              compoundFertilizerN: el.compoundFertilizerN,
              compoundFertilizerP2O5: el.compoundFertilizerP2O5,
              compoundFertilizerK2O: el.compoundFertilizerK2O,
              waterSolubleFertilizerDosage: el.waterSolubleFertilizerDosage,
              waterSolubleFertilizerN: el.waterSolubleFertilizerN,
              waterSolubleFertilizerP2O5: el.waterSolubleFertilizerP2O5,
              waterSolubleFertilizerK2O: el.waterSolubleFertilizerK2O,
              otherFertilizer1Id: el.otherFertilizer1Id,
              otherFertilizer1Dosage: el.otherFertilizer1Dosage,
              otherFertilizer2Id: el.otherFertilizer2Id,
              otherFertilizer2Dosage: el.otherFertilizer2Dosage,
              fertilizationMode: el.fertilizationMode
            }
            afterFertilizerModelList.push(afterFertilizerModel)
          }
        })
      }

      let createModel = {
        plantCrop: plantCropModel,
        baseFertilizer: baseFertilizerModel,
        afterFertilizerList: afterFertilizerModelList
      }

      await AssistFillInPlantCropApi.create(createModel)
        .then(() => {
          this.$message({ type: 'success', message: '记录【' + row.index + '】保存成功！' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async update(row) {
      // set plantCropModel
      let farmer = row.farmer
      let monitoringPlot = row.monitoringPlot
      let plantCrop = row.plantCrop
      let baseFertilizer = row.baseFertilizer
      let afterFertilizerTotal = row.afterFertilizerTotal
      let afterFertilizerList = row.afterFertilizerList

      let farmerModel = {
        id: farmer.id,
        fullName: farmer.fullName,
        detailedAddress: farmer.detailedAddress,
        farmerNature: farmer.farmerNature,
        educationalLevel: farmer.educationalLevel,
        cultivationLandArea: farmer.cultivationLandArea,
        modelHouseholdFlag: farmer.modelHouseholdFlag
      }
      let plantCropModel = {
        id: plantCrop.id,
        monitoringPlotId: monitoringPlot.id,
        cropRootCategoryId: plantCrop.cropRootCategoryId,
        cropRootCategoryName: plantCrop.cropRootCategoryName,
        cropSubCategoryId: plantCrop.cropSubCategoryId,
        cropSubCategoryName: plantCrop.cropSubCategoryName,
        cropId: plantCrop.cropId,
        cropName: plantCrop.cropName,
        cropRemark: plantCrop.cropRemark,
        sowingDate: null === plantCrop.sowingDate ? null : plantCrop.sowingDate + ' 00:00:00',
        harvestTime: null === plantCrop.harvestTime ? null : plantCrop.harvestTime + ' 00:00:00',
        sowingArea: plantCrop.sowingArea,
        plotFertility: plantCrop.plotFertility,
        yield: plantCrop.yield,
        yieldLevel: plantCrop.yieldLevel,
        facilityCultivationFlag: plantCrop.facilityCultivationFlag,
        happenDisasterFlag: plantCrop.happenDisasterFlag,
        previousCropSubCategoryId: 'NONE' === plantCrop.previousCropSubCategoryId ? null : plantCrop.previousCropSubCategoryId,
        previousCropSubCategoryName: plantCrop.previousCropSubCategoryName,
        previousYield: plantCrop.previousYield,
        previousStrawReturnRate: plantCrop.previousStrawReturnRate,
        afterFertilizerFillInMode: plantCrop.afterFertilizerFillInMode
      }
      // set baseFertilizerModel
      let baseFertilizerModel = null
      if (baseFertilizer.fertilizationMode !== null) {
        baseFertilizerModel = {
          id: baseFertilizer.id,
          plantCropId: plantCrop.id,
          fertilizationDate: null === baseFertilizer.fertilizationDate ? null : baseFertilizer.fertilizationDate + ' 00:00:00',
          businessOrganicFertilizerDosage: baseFertilizer.businessOrganicFertilizerDosage,
          businessOrganicFertilizerPrice: baseFertilizer.businessOrganicFertilizerPrice,
          organicFertilizer1Id: baseFertilizer.organicFertilizer1Id,
          organicFertilizer1Dosage: baseFertilizer.organicFertilizer1Dosage,
          organicFertilizer2Id: baseFertilizer.organicFertilizer2Id,
          organicFertilizer2Dosage: baseFertilizer.organicFertilizer2Dosage,
          urea: baseFertilizer.urea,
          ammoniumBicarbonate: baseFertilizer.ammoniumBicarbonate,
          diammoniumPhosphate: baseFertilizer.diammoniumPhosphate,
          potassiumChloride: baseFertilizer.potassiumChloride,
          potassiumSulphate: baseFertilizer.potassiumSulphate,
          compoundFertilizerDosage: baseFertilizer.compoundFertilizerDosage,
          compoundFertilizerN: baseFertilizer.compoundFertilizerN,
          compoundFertilizerP2O5: baseFertilizer.compoundFertilizerP2O5,
          compoundFertilizerK2O: baseFertilizer.compoundFertilizerK2O,
          waterSolubleFertilizerDosage: baseFertilizer.waterSolubleFertilizerDosage,
          waterSolubleFertilizerN: baseFertilizer.waterSolubleFertilizerN,
          waterSolubleFertilizerP2O5: baseFertilizer.waterSolubleFertilizerP2O5,
          waterSolubleFertilizerK2O: baseFertilizer.waterSolubleFertilizerK2O,
          otherFertilizer1Id: baseFertilizer.otherFertilizer1Id,
          otherFertilizer1Dosage: baseFertilizer.otherFertilizer1Dosage,
          otherFertilizer2Id: baseFertilizer.otherFertilizer2Id,
          otherFertilizer2Dosage: baseFertilizer.otherFertilizer2Dosage,
          fertilizationMode: baseFertilizer.fertilizationMode
        }
      }

      let afterFertilizerModelList = []
      // set afterFertilizerTotalModel
      if (plantCrop.afterFertilizerFillInMode === 'TOTAL') {
        let afterFertilizerTotalModel = {
          id: afterFertilizerTotal.id,
          plantCropId: plantCrop.id,
          afterFertilizationTimes: afterFertilizerTotal.afterFertilizationTimes,
          afterFertilizationDate: null === afterFertilizerTotal.afterFertilizationDate ? null : afterFertilizerTotal.afterFertilizationDate + ' 00:00:00',
          businessOrganicFertilizerDosage: afterFertilizerTotal.businessOrganicFertilizerDosage,
          urea: afterFertilizerTotal.urea,
          ammoniumBicarbonate: afterFertilizerTotal.ammoniumBicarbonate,
          diammoniumPhosphate: afterFertilizerTotal.diammoniumPhosphate,
          potassiumChloride: afterFertilizerTotal.potassiumChloride,
          potassiumSulphate: afterFertilizerTotal.potassiumSulphate,
          compoundFertilizerDosage: afterFertilizerTotal.compoundFertilizerDosage,
          compoundFertilizerN: afterFertilizerTotal.compoundFertilizerN,
          compoundFertilizerP2O5: afterFertilizerTotal.compoundFertilizerP2O5,
          compoundFertilizerK2O: afterFertilizerTotal.compoundFertilizerK2O,
          waterSolubleFertilizerDosage: afterFertilizerTotal.waterSolubleFertilizerDosage,
          waterSolubleFertilizerN: afterFertilizerTotal.waterSolubleFertilizerN,
          waterSolubleFertilizerP2O5: afterFertilizerTotal.waterSolubleFertilizerP2O5,
          waterSolubleFertilizerK2O: afterFertilizerTotal.waterSolubleFertilizerK2O,
          otherFertilizer1Id: afterFertilizerTotal.otherFertilizer1Id,
          otherFertilizer1Dosage: afterFertilizerTotal.otherFertilizer1Dosage,
          otherFertilizer2Id: afterFertilizerTotal.otherFertilizer2Id,
          otherFertilizer2Dosage: afterFertilizerTotal.otherFertilizer2Dosage,
          fertilizationMode: afterFertilizerTotal.fertilizationMode
        }
        afterFertilizerModelList.push(afterFertilizerTotalModel)
      }

      // set afterFertilizerModelList
      if (plantCrop.afterFertilizerFillInMode === 'MANY_TIMES') {
        afterFertilizerList.forEach(el => {
          if (null !== el.fertilizationMode) {
            let afterFertilizerModel = {
              id: el.id,
              plantCropId: plantCrop.id,
              afterFertilizationTimes: null,
              afterFertilizationDate: null === el.afterFertilizationDate ? null : el.afterFertilizationDate + ' 00:00:00',
              businessOrganicFertilizerDosage: el.businessOrganicFertilizerDosage,
              urea: el.urea,
              ammoniumBicarbonate: el.ammoniumBicarbonate,
              diammoniumPhosphate: el.diammoniumPhosphate,
              potassiumChloride: el.potassiumChloride,
              potassiumSulphate: el.potassiumSulphate,
              compoundFertilizerDosage: el.compoundFertilizerDosage,
              compoundFertilizerN: el.compoundFertilizerN,
              compoundFertilizerP2O5: el.compoundFertilizerP2O5,
              compoundFertilizerK2O: el.compoundFertilizerK2O,
              waterSolubleFertilizerDosage: el.waterSolubleFertilizerDosage,
              waterSolubleFertilizerN: el.waterSolubleFertilizerN,
              waterSolubleFertilizerP2O5: el.waterSolubleFertilizerP2O5,
              waterSolubleFertilizerK2O: el.waterSolubleFertilizerK2O,
              otherFertilizer1Id: el.otherFertilizer1Id,
              otherFertilizer1Dosage: el.otherFertilizer1Dosage,
              otherFertilizer2Id: el.otherFertilizer2Id,
              otherFertilizer2Dosage: el.otherFertilizer2Dosage,
              fertilizationMode: el.fertilizationMode
            }
            afterFertilizerModelList.push(afterFertilizerModel)
          }
        })
      }

      let updateModel = {
        farmer: farmerModel,
        plantCrop: plantCropModel,
        baseFertilizer: baseFertilizerModel,
        afterFertilizerList: afterFertilizerModelList
      }

      await AssistFillInPlantCropApi.update(updateModel)
        .then(() => {
          this.$message({ type: 'success', message: '记录【' + row.index + '】保存成功！' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async delete(row) {
      await AssistFillInPlantCropApi.deleteById(row.plantCrop.id)
        .then(() => {
          this.$message({ showClose: true, message: '记录【' + row.index + '】删除成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async submit(row) {
      await AssistFillInPlantCropApi.submit(row.plantCrop.id)
        .then(() => {
          this.$message({ showClose: true, message: '第' + row.index + '行提交成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({
            showClose: true,
            message: '第' + row.index + '行提交失败，【' + err.data.code + '】' + err.data.message,
            type: 'error'
          })
        })
    },
    importData(year, fileName) {
      let loading = this.$loading({
        lock: true,
        text: '等待返回导入结果......',
        spinner: 'el-icon-loading'
      })

      setTimeout(() => {
      }, 10000)
      AssistFillInPlantCropApi.importData(year, fileName)
        .then(res => {
          let byteBuffer = window.atob(res.data)
          let arrayBuffer = new ArrayBuffer(byteBuffer.length)
          let dataBuffer = new Uint8Array(arrayBuffer)
          for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

          let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', '数据导入结果.xlsx')
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
          document.body.removeChild(link)

          this.bindTableData()
          loading.close()
        })
        .catch(err => {
          loading.close()
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    load(year, regionId) {
      this.params.year = year
      this.params.regionId = regionId
      this.bindTableData()
    },
    sortFarmerColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.farmer[fieldName], row2.farmer[fieldName])
    },
    sortMonitoringPlotColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.monitoringPlot[fieldName], row2.monitoringPlot[fieldName])
    },
    sortPlantCropColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.plantCrop[fieldName], row2.plantCrop[fieldName])
    },
    sortBaseFertilizerColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.baseFertilizer[fieldName], row2.baseFertilizer[fieldName])
    },
    sortAfterFertilizerTotalColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1.afterFertilizerTotal[fieldName], row2.afterFertilizerTotal[fieldName])
    },
    sortAfterFertilizerColumn(row1, row2, index, fieldName) {
      return this.$utils.sortColumn(row1.afterFertilizerList[index][fieldName], row2.afterFertilizerList[index][fieldName])
    },
    mappingFarmer(farmer, model) {
      if (undefined === farmer || null === farmer) {
        farmer = {
          id: null,
          provinceId: null,
          provinceName: null,
          cityId: null,
          cityName: null,
          districtId: null,
          districtName: null,
          detailedAddress: null,
          fullName: null,
          phoneNumber: null,
          farmerNature: null,
          farmerNatureName: null,
          educationalLevel: null,
          educationalLevelName: null,
          cultivationLandArea: null,
          modelHouseholdFlag: null,
          modelHouseholdName: null
        }
      }
      if (undefined !== model && null !== model) {
        farmer.id = model.id
        farmer.provinceId = model.provinceId
        farmer.provinceName = model.provinceName
        farmer.cityId = model.cityId
        farmer.cityName = model.cityName
        farmer.districtId = model.districtId
        farmer.districtName = model.districtName
        farmer.detailedAddress = model.detailedAddress
        farmer.fullName = model.fullName
        farmer.phoneNumber = model.phoneNumber
        farmer.farmerNature = model.farmerNature
        farmer.farmerNatureName = model.farmerNatureName
        farmer.educationalLevel = model.educationalLevel
        farmer.educationalLevelName = model.educationalLevelName
        farmer.cultivationLandArea = model.cultivationLandArea
        farmer.modelHouseholdFlag = model.modelHouseholdFlag
        farmer.modelHouseholdName = null === model.modelHouseholdFlag ? '--' : model.modelHouseholdFlag ? '是' : '否'
      }
      return farmer
    },
    mappingMonitoringPlot(monitoringPlot, model) {
      if (undefined === monitoringPlot || null === monitoringPlot) {
        monitoringPlot = {
          id: null,
          name: null,
          code: null,
          plotArea: null
        }
      }
      if (undefined !== model && null !== model) {
        monitoringPlot.id = model.id
        monitoringPlot.name = model.name
        monitoringPlot.code = model.code
        monitoringPlot.plotArea = model.plotArea
      }
      return monitoringPlot
    },
    mappingPlantCrop(plantCrop, model) {
      if (undefined === plantCrop || null === plantCrop) {
        plantCrop = {
          id: null,
          cropRootCategoryId: null,
          cropRootCategoryName: null,
          cropSubCategoryId: null,
          cropSubCategoryName: null,
          cropId: null,
          cropName: null,
          cropRemark: null,
          sowingDate: null,
          harvestTime: null,
          sowingArea: null,
          plotFertility: null,
          plotFertilityName: null,
          yield: null,
          yieldLevel: null,
          yieldLevelName: null,
          facilityCultivationFlag: null,
          facilityCultivationName: null,
          happenDisasterFlag: null,
          happenDisasterName: null,
          previousCropSubCategoryId: null,
          previousCropSubCategoryName: null,
          previousYield: null,
          previousStrawReturnRate: null,
          afterFertilizerFillInMode: null,
          afterFertilizerFillInModeName: null,
          submitFlag: null,
          submittedOn: null,
          submittedByFullName: null,
          createdOn: null,
          createdByFullName: null,
          modifiedOn: null,
          modifiedByFullName: null
        }
      }
      if (undefined !== model && null !== model) {
        plantCrop.id = model.id
        plantCrop.cropRootCategoryId = model.cropRootCategoryId
        plantCrop.cropRootCategoryName = model.cropRootCategoryName
        plantCrop.cropSubCategoryId = model.cropSubCategoryId
        plantCrop.cropSubCategoryName = model.cropSubCategoryName
        plantCrop.cropId = model.cropId
        plantCrop.cropName = model.cropName
        plantCrop.cropRemark = model.cropRemark
        plantCrop.sowingDate = this.$utils.isEmpty(model.sowingDate) ? null : this.$moment(model.sowingDate).format('YYYY-MM-DD')
        plantCrop.harvestTime = this.$utils.isEmpty(model.harvestTime) ? null : this.$moment(model.harvestTime).format('YYYY-MM-DD')
        plantCrop.sowingArea = model.sowingArea
        plantCrop.plotFertility = model.plotFertility
        plantCrop.plotFertilityName = model.plotFertilityName
        plantCrop.yield = model.yield
        plantCrop.yieldLevel = model.yieldLevel
        plantCrop.yieldLevelName = model.yieldLevelName
        plantCrop.facilityCultivationFlag = model.facilityCultivationFlag
        plantCrop.facilityCultivationName = null === model.facilityCultivationFlag ? '--' : model.facilityCultivationFlag ? '是' : '否'
        plantCrop.happenDisasterFlag = model.happenDisasterFlag
        plantCrop.happenDisasterName = null === model.happenDisasterFlag ? '--' : model.happenDisasterFlag ? '是' : '否'
        plantCrop.previousCropSubCategoryId = model.previousCropSubCategoryId ?? 'NONE'
        plantCrop.previousCropSubCategoryName = model.previousCropSubCategoryName
        plantCrop.previousYield = model.previousYield
        plantCrop.previousStrawReturnRate = model.previousStrawReturnRate
        plantCrop.afterFertilizerFillInMode = model.afterFertilizerFillInMode
        switch (model.afterFertilizerFillInMode) {
          case 'NONE':
            plantCrop.afterFertilizerFillInModeName = '无追肥'
            break
          case 'MANY_TIMES':
            plantCrop.afterFertilizerFillInModeName = '分次填报'
            break
          case 'TOTAL':
            plantCrop.afterFertilizerFillInModeName = '合计填报'
            break
          default:
            plantCrop.afterFertilizerFillInModeName = '--'
            break
        }
        plantCrop.submitFlag = model.submitFlag
        plantCrop.submittedOn = model.submittedOn
        plantCrop.submittedByFullName = model.submittedByFullName
        plantCrop.createdOn = model.createdOn
        plantCrop.createdByFullName = model.createdByFullName
        plantCrop.modifiedOn = model.modifiedOn
        plantCrop.modifiedByFullName = model.modifiedByFullName
      }
      return plantCrop
    },
    mappingBaseFertilizer(baseFertilizer, model) {
      if (undefined === baseFertilizer || null === baseFertilizer) {
        baseFertilizer = {
          id: null,
          fertilizationDate: null,
          businessOrganicFertilizerDosage: null,
          businessOrganicFertilizerPrice: null,
          organicFertilizer1Id: null,
          organicFertilizer1Name: null,
          organicFertilizer1Dosage: null,
          organicFertilizer2Id: null,
          organicFertilizer2Name: null,
          organicFertilizer2Dosage: null,
          urea: null,
          ammoniumBicarbonate: null,
          diammoniumPhosphate: null,
          potassiumChloride: null,
          potassiumSulphate: null,
          compoundFertilizerDosage: null,
          compoundFertilizerN: null,
          compoundFertilizerP2O5: null,
          compoundFertilizerK2O: null,
          waterSolubleFertilizerDosage: null,
          waterSolubleFertilizerN: null,
          waterSolubleFertilizerP2O5: null,
          waterSolubleFertilizerK2O: null,
          otherFertilizer1Id: null,
          otherFertilizer1Name: null,
          otherFertilizer1Dosage: null,
          otherFertilizer2Id: null,
          otherFertilizer2Name: null,
          otherFertilizer2Dosage: null,
          fertilizationMode: null,
          fertilizationModeName: null
        }
      }
      if (undefined !== model && null !== model) {
        baseFertilizer.id = model.id
        baseFertilizer.fertilizationDate = this.$utils.isEmpty(model.fertilizationDate) ? null : this.$moment(model.fertilizationDate).format('YYYY-MM-DD')
        baseFertilizer.businessOrganicFertilizerDosage = model.businessOrganicFertilizerDosage
        baseFertilizer.businessOrganicFertilizerPrice = model.businessOrganicFertilizerPrice
        baseFertilizer.organicFertilizer1Id = model.organicFertilizer1Id
        baseFertilizer.organicFertilizer1Name = model.organicFertilizer1Name
        baseFertilizer.organicFertilizer1Dosage = model.organicFertilizer1Dosage
        baseFertilizer.organicFertilizer2Id = model.organicFertilizer2Id
        baseFertilizer.organicFertilizer2Name = model.organicFertilizer2Name
        baseFertilizer.organicFertilizer2Dosage = model.organicFertilizer2Dosage
        baseFertilizer.urea = model.urea
        baseFertilizer.ammoniumBicarbonate = model.ammoniumBicarbonate
        baseFertilizer.diammoniumPhosphate = model.diammoniumPhosphate
        baseFertilizer.potassiumChloride = model.potassiumChloride
        baseFertilizer.potassiumSulphate = model.potassiumSulphate
        baseFertilizer.compoundFertilizerDosage = model.compoundFertilizerDosage
        baseFertilizer.compoundFertilizerN = model.compoundFertilizerN
        baseFertilizer.compoundFertilizerP2O5 = model.compoundFertilizerP2O5
        baseFertilizer.compoundFertilizerK2O = model.compoundFertilizerK2O
        baseFertilizer.waterSolubleFertilizerDosage = model.waterSolubleFertilizerDosage
        baseFertilizer.waterSolubleFertilizerN = model.waterSolubleFertilizerN
        baseFertilizer.waterSolubleFertilizerP2O5 = model.waterSolubleFertilizerP2O5
        baseFertilizer.waterSolubleFertilizerK2O = model.waterSolubleFertilizerK2O
        baseFertilizer.otherFertilizer1Id = model.otherFertilizer1Id
        baseFertilizer.otherFertilizer1Name = model.otherFertilizer1Name
        baseFertilizer.otherFertilizer1Dosage = model.otherFertilizer1Dosage
        baseFertilizer.otherFertilizer2Id = model.otherFertilizer2Id
        baseFertilizer.otherFertilizer2Name = model.otherFertilizer2Name
        baseFertilizer.otherFertilizer2Dosage = model.otherFertilizer2Dosage
        baseFertilizer.fertilizationMode = model.fertilizationMode
        baseFertilizer.fertilizationModeName = model.fertilizationModeName
      }
      return baseFertilizer
    },
    mappingAfterFertilizerTotal(afterFertilizerTotal, model) {
      if (undefined === afterFertilizerTotal || null === afterFertilizerTotal) {
        afterFertilizerTotal = {
          id: null,
          plantCropId: null,
          afterFertilizationTimes: null,
          afterFertilizationDate: null,
          businessOrganicFertilizerDosage: null,
          urea: null,
          ammoniumBicarbonate: null,
          diammoniumPhosphate: null,
          potassiumChloride: null,
          potassiumSulphate: null,
          compoundFertilizerDosage: null,
          compoundFertilizerN: null,
          compoundFertilizerP2O5: null,
          compoundFertilizerK2O: null,
          waterSolubleFertilizerDosage: null,
          waterSolubleFertilizerN: null,
          waterSolubleFertilizerP2O5: null,
          waterSolubleFertilizerK2O: null,
          otherFertilizer1Id: null,
          otherFertilizer1Name: null,
          otherFertilizer1Dosage: null,
          otherFertilizer2Id: null,
          otherFertilizer2Name: null,
          otherFertilizer2Dosage: null,
          fertilizationMode: null,
          fertilizationModeName: null
        }
      }
      if (undefined !== model && null !== model) {
        afterFertilizerTotal.id = model.id
        afterFertilizerTotal.plantCropId = model.plantCropId
        afterFertilizerTotal.afterFertilizationTimes = model.afterFertilizationTimes
        afterFertilizerTotal.afterFertilizationDate = this.$utils.isEmpty(model.afterFertilizationDate) ? null : this.$moment(model.afterFertilizationDate).format('YYYY-MM-DD')
        afterFertilizerTotal.businessOrganicFertilizerDosage = model.businessOrganicFertilizerDosage
        afterFertilizerTotal.urea = model.urea
        afterFertilizerTotal.ammoniumBicarbonate = model.ammoniumBicarbonate
        afterFertilizerTotal.diammoniumPhosphate = model.diammoniumPhosphate
        afterFertilizerTotal.potassiumChloride = model.potassiumChloride
        afterFertilizerTotal.potassiumSulphate = model.potassiumSulphate
        afterFertilizerTotal.compoundFertilizerDosage = model.compoundFertilizerDosage
        afterFertilizerTotal.compoundFertilizerN = model.compoundFertilizerN
        afterFertilizerTotal.compoundFertilizerP2O5 = model.compoundFertilizerP2O5
        afterFertilizerTotal.compoundFertilizerK2O = model.compoundFertilizerK2O
        afterFertilizerTotal.waterSolubleFertilizerDosage = model.waterSolubleFertilizerDosage
        afterFertilizerTotal.waterSolubleFertilizerN = model.waterSolubleFertilizerN
        afterFertilizerTotal.waterSolubleFertilizerP2O5 = model.waterSolubleFertilizerP2O5
        afterFertilizerTotal.waterSolubleFertilizerK2O = model.waterSolubleFertilizerK2O
        afterFertilizerTotal.otherFertilizer1Id = model.otherFertilizer1Id
        afterFertilizerTotal.otherFertilizer1Name = model.otherFertilizer1Name
        afterFertilizerTotal.otherFertilizer1Dosage = model.otherFertilizer1Dosage
        afterFertilizerTotal.otherFertilizer2Id = model.otherFertilizer2Id
        afterFertilizerTotal.otherFertilizer2Name = model.otherFertilizer2Name
        afterFertilizerTotal.otherFertilizer2Dosage = model.otherFertilizer2Dosage
        afterFertilizerTotal.fertilizationMode = model.fertilizationMode
        afterFertilizerTotal.fertilizationModeName = model.fertilizationModeName
      }
      return afterFertilizerTotal
    },
    mappingAfterFertilizer(afterFertilizer, model) {
      if (undefined === afterFertilizer || null === afterFertilizer) {
        afterFertilizer = {
          id: null,
          afterFertilizationTimes: null,
          afterFertilizationDate: null,
          businessOrganicFertilizerDosage: null,
          urea: null,
          ammoniumBicarbonate: null,
          diammoniumPhosphate: null,
          potassiumChloride: null,
          potassiumSulphate: null,
          compoundFertilizerDosage: null,
          compoundFertilizerN: null,
          compoundFertilizerP2O5: null,
          compoundFertilizerK2O: null,
          waterSolubleFertilizerDosage: null,
          waterSolubleFertilizerN: null,
          waterSolubleFertilizerP2O5: null,
          waterSolubleFertilizerK2O: null,
          otherFertilizer1Id: null,
          otherFertilizer1Name: null,
          otherFertilizer1Dosage: null,
          otherFertilizer2Id: null,
          otherFertilizer2Name: null,
          otherFertilizer2Dosage: null,
          fertilizationMode: null,
          fertilizationModeName: null
        }
      }
      if (undefined !== model && null !== model) {
        afterFertilizer.id = model.id
        afterFertilizer.afterFertilizationTimes = null
        afterFertilizer.afterFertilizationDate = this.$utils.isEmpty(model.afterFertilizationDate) ? null : this.$moment(model.afterFertilizationDate).format('YYYY-MM-DD')
        afterFertilizer.businessOrganicFertilizerDosage = model.businessOrganicFertilizerDosage
        afterFertilizer.urea = model.urea
        afterFertilizer.ammoniumBicarbonate = model.ammoniumBicarbonate
        afterFertilizer.diammoniumPhosphate = model.diammoniumPhosphate
        afterFertilizer.potassiumChloride = model.potassiumChloride
        afterFertilizer.potassiumSulphate = model.potassiumSulphate
        afterFertilizer.compoundFertilizerDosage = model.compoundFertilizerDosage
        afterFertilizer.compoundFertilizerN = model.compoundFertilizerN
        afterFertilizer.compoundFertilizerP2O5 = model.compoundFertilizerP2O5
        afterFertilizer.compoundFertilizerK2O = model.compoundFertilizerK2O
        afterFertilizer.waterSolubleFertilizerDosage = model.waterSolubleFertilizerDosage
        afterFertilizer.waterSolubleFertilizerN = model.waterSolubleFertilizerN
        afterFertilizer.waterSolubleFertilizerP2O5 = model.waterSolubleFertilizerP2O5
        afterFertilizer.waterSolubleFertilizerK2O = model.waterSolubleFertilizerK2O
        afterFertilizer.otherFertilizer1Id = model.otherFertilizer1Id
        afterFertilizer.otherFertilizer1Name = model.otherFertilizer1Name
        afterFertilizer.otherFertilizer1Dosage = model.otherFertilizer1Dosage
        afterFertilizer.otherFertilizer2Id = model.otherFertilizer2Id
        afterFertilizer.otherFertilizer2Name = model.otherFertilizer2Name
        afterFertilizer.otherFertilizer2Dosage = model.otherFertilizer2Dosage
        afterFertilizer.fertilizationMode = model.fertilizationMode
        afterFertilizer.fertilizationModeName = model.fertilizationModeName
      }
      return afterFertilizer
    }
  }
}
</script>

<style scoped></style>
