<template>
  <div class="wg-box">
    <div class="body">
      <div class="wg-table-box">
        <el-table :data="tableData" class="wg-table normal" border :max-height="180" style="width: 100%">
          <el-table-column label="序号" type="index" width="100" fixed></el-table-column>
          <el-table-column prop="fertilizationModeName" label="施肥方式" width="120" fixed></el-table-column>
          <el-table-column prop="fertilizationDate" label="施肥日期" width="150"></el-table-column>
          <el-table-column label="商用有机肥">
            <el-table-column prop="businessOrganicFertilizerDosage" label="用量" width="100"></el-table-column>
            <el-table-column prop="businessOrganicFertilizerPrice" label="价格（元/吨）" width="120"></el-table-column>
          </el-table-column>
          <el-table-column label="农家肥1（干重）">
            <el-table-column prop="organicFertilizer1Name" label="名称" width="120"></el-table-column>
            <el-table-column prop="organicFertilizer1Dosage" label="用量" width="120"></el-table-column>
          </el-table-column>
          <el-table-column label="农家肥1（干重）">
            <el-table-column prop="organicFertilizer2Name" label="名称" width="120"></el-table-column>
            <el-table-column prop="organicFertilizer2Dosage" label="用量" width="120"></el-table-column>
          </el-table-column>
          <el-table-column prop="ureaDosage" label="尿素" width="100"></el-table-column>
          <el-table-column prop="ammoniumBicarbonateDosage" label="碳铵" width="100"></el-table-column>
          <el-table-column prop="diammoniumPhosphateDosage" label="磷酸二铵" width="100"></el-table-column>
          <el-table-column prop="potassiumChlorideDosage" label="氯化钾" width="100"></el-table-column>
          <el-table-column prop="potassiumSulphateDosage" label="硫酸钾" width="100"></el-table-column>
          <el-table-column label="复合肥（含配方肥）及养分%">
            <el-table-column prop="compoundFertilizerDosage" label="用量" width="100"></el-table-column>
            <el-table-column prop="compoundFertilizerN" label="N" width="100"></el-table-column>
            <el-table-column prop="compoundFertilizerP2O5" label="P2O5" width="100"></el-table-column>
            <el-table-column prop="compoundFertilizerK2O" label="K2O" width="100"></el-table-column>
          </el-table-column>
          <el-table-column label="水溶肥及养分%">
            <el-table-column prop="waterSolubleFertilizerDosage" label="用量" width="100"></el-table-column>
            <el-table-column prop="waterSolubleFertilizerN" label="N" width="100"></el-table-column>
            <el-table-column prop="waterSolubleFertilizerP2O5" label="P2O5" width="100"></el-table-column>
            <el-table-column prop="waterSolubleFertilizerK2O" label="K2O" width="100"></el-table-column>
          </el-table-column>
          <el-table-column label="其他肥料1">
            <el-table-column label="名称" prop="otherFertilizer1Name" width="120"></el-table-column>
            <el-table-column label="用量" prop="otherFertilizer1Dosage" width="120"></el-table-column>
          </el-table-column>
          <el-table-column label="其他肥料2">
            <el-table-column label="名称" prop="otherFertilizer2Name" width="120"></el-table-column>
            <el-table-column label="用量" prop="otherFertilizer2Dosage" width="120"></el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArchivedBaseFertilizer',
  props: {
    fullPlantCropModel: { type: Object, default: null }
  },
  data() {
    return {
      tableData: []
    }
  },
  created() {
    this.tableData.push({
      id: null,
      fertilizationDate: null,
      businessOrganicFertilizerDosage: null,
      businessOrganicFertilizerPrice: null,
      organicFertilizer1Id: null,
      organicFertilizer1Name: null,
      organicFertilizer1Dosage: null,
      organicFertilizer2Id: null,
      organicFertilizer2Name: null,
      organicFertilizer2Dosage: null,
      ureaDosage: null,
      ammoniumBicarbonateDosage: null,
      diammoniumPhosphateDosage: null,
      potassiumChlorideDosage: null,
      potassiumSulphateDosage: null,
      compoundFertilizerDosage: null,
      compoundFertilizerN: null,
      compoundFertilizerP2O5: null,
      compoundFertilizerK2O: null,
      waterSolubleFertilizerDosage: null,
      waterSolubleFertilizerN: null,
      waterSolubleFertilizerP2O5: null,
      waterSolubleFertilizerK2O: null,
      otherFertilizer1Id: null,
      otherFertilizer1Name: null,
      otherFertilizer1Dosage: null,
      otherFertilizer2Id: null,
      otherFertilizer2Name: null,
      otherFertilizer2Dosage: null,
      fertilizationMode: null,
      fertilizationModeName: null
    })

    let row = this.tableData[0]
    if (null !== this.fullPlantCropModel.baseFertilizer) {
      let baseFertilizerModel = this.fullPlantCropModel.baseFertilizer
      row.id = baseFertilizerModel.id
      row.fertilizationDate = this.$utils.isEmpty(baseFertilizerModel.fertilizationDate) ? null : this.$moment(baseFertilizerModel.fertilizationDate).format('YYYY-MM-DD')
      row.businessOrganicFertilizerDosage = baseFertilizerModel.businessOrganicFertilizerDosage
      row.businessOrganicFertilizerPrice = baseFertilizerModel.businessOrganicFertilizerPrice
      row.organicFertilizer1Id = baseFertilizerModel.organicFertilizer1Id
      row.organicFertilizer1Name = baseFertilizerModel.organicFertilizer1Name
      row.organicFertilizer1Dosage = baseFertilizerModel.organicFertilizer1Dosage
      row.organicFertilizer2Id = baseFertilizerModel.organicFertilizer2Id
      row.organicFertilizer2Name = baseFertilizerModel.organicFertilizer2Name
      row.organicFertilizer2Dosage = baseFertilizerModel.organicFertilizer2Dosage
      row.urea = baseFertilizerModel.urea
      row.ammoniumBicarbonate = baseFertilizerModel.ammoniumBicarbonate
      row.diammoniumPhosphate = baseFertilizerModel.diammoniumPhosphate
      row.potassiumChloride = baseFertilizerModel.potassiumChloride
      row.potassiumSulphate = baseFertilizerModel.potassiumSulphate
      row.compoundFertilizerDosage = baseFertilizerModel.compoundFertilizerDosage
      row.compoundFertilizerN = baseFertilizerModel.compoundFertilizerN
      row.compoundFertilizerP2O5 = baseFertilizerModel.compoundFertilizerP2O5
      row.compoundFertilizerK2O = baseFertilizerModel.compoundFertilizerK2O
      row.waterSolubleFertilizerDosage = baseFertilizerModel.waterSolubleFertilizerDosage
      row.waterSolubleFertilizerN = baseFertilizerModel.waterSolubleFertilizerN
      row.waterSolubleFertilizerP2O5 = baseFertilizerModel.waterSolubleFertilizerP2O5
      row.waterSolubleFertilizerK2O = baseFertilizerModel.waterSolubleFertilizerK2O
      row.otherFertilizer1Id = baseFertilizerModel.otherFertilizer1Id
      row.otherFertilizer1Name = baseFertilizerModel.otherFertilizer1Name
      row.otherFertilizer1Dosage = baseFertilizerModel.otherFertilizer1Dosage
      row.otherFertilizer2Id = baseFertilizerModel.otherFertilizer2Id
      row.otherFertilizer2Name = baseFertilizerModel.otherFertilizer2Name
      row.otherFertilizer2Dosage = baseFertilizerModel.otherFertilizer2Dosage
      row.fertilizationMode = baseFertilizerModel.fertilizationMode
      row.fertilizationModeName = baseFertilizerModel.fertilizationModeName
    }
  }
}
</script>

<style scoped></style>
