<template>
  <el-row :gutter="60" class="panel-group">
    <el-col :xs="8" :sm="8" :lg="8" class="card-panel-col">
      <div class="card-panel">
        <div class='card-panel-icon-wrapper icon-farmer'>
          <svg-icon icon-class="farmer" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            调查农户数量 <span class="card-panel-num-farmer">{{summary.farmerCount}}</span> 户
          </div>
        </div>
      </div>
    </el-col>
    <el-col :xs="8" :sm="8" :lg="8" class="card-panel-col">
      <div class="card-panel">
        <div class='card-panel-icon-wrapper icon-area'>
          <svg-icon icon-class="soil" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            调查总面积&nbsp;&nbsp; <span class="card-panel-num-area">{{summary.acreage}}</span> 万亩
          </div>
        </div>
      </div>
    </el-col>
    <el-col :xs="8" :sm="8" :lg="8" class="card-panel-col">
      <div class="card-panel">
        <div class='card-panel-icon-wrapper icon-crop'>
          <svg-icon icon-class="crop" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            调查作物数量 <span class="card-panel-num-crop">{{summary.cropCount}}</span> 个
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>

import SurveyStatisticApi from '@/api/statistic/survey-statistic-api'
import FillInCycleApi from '@/api/data-fill-in/cycle/fill-in-cycle-api'
export default {
  name: 'SummaryHeader',
  data() {
    return {
      years: [],
      summary: {
        cropCount: 0,
        farmerCount: 0,
        acreage: 0
      },
    }
  },
  created() {
    this.bindYears()
  },
  methods: {
    bindYears() {
      let yearlist = []
      FillInCycleApi.getList().then(res => {
        res.data.map(v => {
          yearlist.push(v.year)
        })
        if(yearlist.length >= 2) {
          let arr = yearlist.sort((a, b) => a - b)
          this.years = [arr[0].toString(), arr[yearlist.length-1].toString()]
        } else {
          this.years = [yearlist[0].toString(), yearlist[0].toString()]
        }
        let year = this.years[1]
        this.getSummaryData(year)
      })
    },
    getSummaryData(year) {
      SurveyStatisticApi.getSurveyCount(year).then(res => {
        this.summary.cropCount = res.data.cropCount
        this.summary.farmerCount = res.data.farmerCount
        this.summary.acreage = res.data.acreage
      })
    }
  }
}
</script>
<style scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 100px;
    cursor: pointer;
    color: #474747;
    font-size: 14px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-farmer {
        background: rgba(250, 196, 64, 0.5);
      }

      .icon-area {
        background: rgba(255, 124, 124, 0.5);
      }

      .icon-crop {
        background: rgba(0, 163, 51, 0.5);
      }

    }

    .icon-farmer {
      color: #fac45e;
      background: rgba(250, 196, 64, 0.1);
    }

    .icon-area {
      background: rgba(255, 124, 124, 0.1);
    }

    .icon-crop {
      color: #21a44a;
      background: rgba(0, 163, 51, 0.1);
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 20px 0 0 30px;
      padding: 8px;
      transition: all 0.38s ease-out;
      border-radius: 50%;
      background-size: 210% 210%;
      background-position: 100% 0;
    }

    .card-panel-icon {
      float: left;
      font-size: 28px;
    }

    .card-panel-description {
      font-weight: bold;
      margin: 30px 0 0 80px;

      .card-panel-text {
        line-height: 32px;
        color: #474747;
        font-size: 16px;
        margin-left: 45px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 30px;
        margin: 24px 12px 12px;
      }
      .card-panel-num-farmer {
        padding-top:10px;
        color: #fac45e;
        font-size: 30px;
      }

      .card-panel-num-area {
        color: #fda9a9;
        font-size: 30px;
      }

      .card-panel-num-crop {
        color: #21a44a;
        font-size: 30px;
      }
    }
  }
  .svg-icon {
    width: 1.5em;
    height: 1.5em;
  }
}
</style>
