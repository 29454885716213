<template>
  <el-card>
    <div slot="header" class="chart-title"><span>土壤肥力</span></div>
    <div>
      <el-row :gutter="20" type="flex">
        <el-col :span="12" style="padding-left: 40px;">
          <v-chart
              :option="fertilityNumbersOptions"
              :init-options="initOptions"
              ref="fertilityNumbers"
              class="echarts_especial"
              :loading="barLoading"
              :loadingOptions="barLoadingOptions"
              :updateOptions="{notMerge: true}"
              autoresize
          />
        </el-col>
        <el-divider direction="vertical"></el-divider>
        <el-col :span="12">
          <v-chart
              :option="fertilityProportionsOptions"
              :init-options="initOptions"
              ref="fertilityProportions"
              :loading="barLoading"
              :loadingOptions="barLoadingOptions"
              :updateOptions="{notMerge: true}"
              autoresize
          />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import VChart from 'vue-echarts'
import {CanvasRenderer, SVGRenderer} from 'echarts/renderers'

import {
  use
} from 'echarts/core'

import {
  BarChart,
  PieChart
} from 'echarts/charts'

import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components'
import DataVisualizationApi from '@/api/chart-analysis/data-visualization-api'

use([
  BarChart,
  PieChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
]);
export default {
  name: "SoilFertility",
  components: {
    VChart
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas"
      },
      chartData: null,
      fertilityNumbersOptions: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      fertilityProportionsOptions: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      seconds: -1,
      barLoading: false,
      barLoadingOptions: {
        text: "加载中…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0.4)"
      },
    };
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    getChartData() {
      this.fertilityNumbersOptions = {}
      this.fertilityProportionsOptions = {}
      this.barLoading = true
      DataVisualizationApi.getFertilityNumbers(this.params).then(res => {
        this.chartData = res.data
        if(this.chartData.length === 0) {
          this.fertilityNumbersOptions = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          this.fertilityProportionsOptions = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }
        this.renderFertilityNumbersChart()
        this.renderFertilityProportionsChart()
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.barLoading = false
      })
    },
    renderFertilityNumbersChart() {
      //柱条的颜色，每个柱条颜色不同
      const lineColor = ['#44A1FF', '#00B045', '#F4B202']
      let totalValue = this.chartData.reduce((vs, v) => vs + v.value, 0)
      let valueList = this.chartData.map((item) => {return item.value})
      let indicator = []
      this.chartData.map((item,idx) =>
        indicator.push({
          name: item.name,
          value: totalValue,
          realValue: item.value.toFixed(item.value < 1 ? 4 : 2),
          totalValue: totalValue,
          percent: totalValue == 0 ? 0 : this.$utils.getPercentValue(valueList, idx, 2)//parseFloat(item.value * 100 / totalValue).toFixed(2)
        }))

      this.fertilityNumbersOptions = {
        textStyle: { fontSize: 14 },
        title: {
          text: '面积(万亩)',
          top: 20,
          left: 'left',
          textStyle: {
            fontSize: 14,
            color: '#000000',
            fontWeight: 500
          }
        },
        grid: {
          x: '5%',// 左x 偏移量
          y: '20%', // 左y 偏移量
          x2: 200,// 右x 偏移量
          y2: 20,// 右y 偏移量
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          min: 0,
          max: totalValue,
          show: false,
        },
        yAxis: {
          type: 'category',
          inverse: true,//倒序
          axisTick: {
            show: false //不显示坐标轴刻度线
          },
          axisLabel: {
            fontSize: 14
          },
          axisLine: {
            show: false //不显示坐标轴线
          },
          splitLine: {
            show: false //不显示网格线
          },
          data: indicator.map(item => item.name)
        },
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: '35%',
            zlevel: 2, // 层级高的显示在上面
            data: indicator.map(function (item) {
              return {
                value: item.realValue,
                totalValue: totalValue,
                percent: item.percent
              };
            }),
            label: {
              show: true,
              position: 'inside',
              align: 'center',
              formatter: function (params) {
                return indicator.every(t => t.value === 0) ? '' : params.data.percent + '%'
              },
              fontSize: 14,
              offset: [50, 0],
            },
            itemStyle: {
              color: function(params) {
                return lineColor[params.dataIndex]
              },
              borderType: 'solid', //设置边框粗细、颜色与背景色相同，模拟出效果
              borderWidth: 4,
              borderColor: '#ECF2FE',
            },
          },
          {
            type: 'bar',
            silent: true,
            barWidth: '35%',
            barGap: '-100%',
            barCategoryGap: '-100%',
            showBackground: true,
            backgroundStyle: {
              color: '#ECF2FE',
            },
            itemStyle: {
              color: '#ECF2FE',
              borderType: 'solid', //设置边框粗细、颜色与背景色相同，模拟出效果
              borderWidth: 4,
              borderColor: '#ECF2FE',
            },
            data: indicator.map(function (item) {
              return {
                realValue: item.realValue,
                value: totalValue,
              };
            }),
            label: {
              show: true,
              position: 'right',
              distance: 10,
              formatter: function (params) {
                return params.data.realValue + '/' + totalValue.toFixed(totalValue < 1 ? 4 : 2) + "万亩";
              },
              fontSize: 14,
            },
            z: 4
          }
        ]
      }
    },
    renderFertilityProportionsChart() {
      const that = this
      const circleColors = ['#5B8FF9', '#5AD8A6', '#FBDB63']
      let totalValue = that.chartData.reduce((vs, v) => vs + v.value, 0)
      let valueList = that.chartData.map(function (item) {
        return item.value
      })
      let formatNumber = (num) => num.toString().replace(/(?=(\B)(\d{3})+$)/g, ',')
      this.fertilityProportionsOptions = {
        textStyle: {fontSize: 14},
        title: [
          {
            text: '占比(%)',
            top: 20,
            left: 'left',
            textStyle: {
              fontSize: 14,
              color: '#000000',
              fontWeight: 500
            }
          },
          {
            text: '土壤面积',
            subtext: `${formatNumber(totalValue.toFixed(2))}万亩`,
            textAlign: 'center', //图例文字居中显示
            textStyle: {
              fontSize: 18,
              color: '#000000',
              fontWeight: 400
            },
            subtextStyle: {
              fontSize: 20,
              color: '#000000',
              fontWeight: 600
            },
            x: '42%',   //距离左边的距离
            y: '50%'    //距离上边的距离
          }
        ],
        tooltip: {
          trigger: 'item',
          formatter: (params) => `${params.data.name}<br/><div style='text-align: left'>
${params.marker}<span style='color:${params.color}'>占比: ${params.percent} %</span><br/>
${params.marker}<span style='color:${params.color}'>面积: ${params.value.toFixed(params.value < 1 ? 4 : 2)} 万亩</span></div>`
        },
        legend: {
          itemWidth: 15, //图例的宽度
          itemHeight: 15, //图例的高度
          itemGap: 15, //图例各项间距
          orient: 'vertical',
          right: 'right',  //图例水平居右
          top: 'center',  //图例上下居中
          icon: 'circle',
          selectedMode: false, //取消图例上的点击事件
          padding: [0, 0, 0, 0],
          formatter: function(name) {
            if(totalValue === 0) {
              return `${name}：0%`
            }
            for (let i = 0; i < that.chartData.length; i++) {
              if (that.chartData[i].name === name) {
                let percent = that.$utils.getPercentValue(valueList, i, 2)
                if (isNaN(percent)) {
                  percent = 0
                }
                return `${name}：${percent}%`
              }
            }
          }
        },
        series: {
          type: 'pie',
          radius: ['40%', '65%'],
          center: ['42%', '58%'],
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 0,
            color: function(params) {
              return circleColors[params.dataIndex]
            }
          },
          label: {
            alignTo: 'labelLine',
            formatter: '{category|{b}}：{value|{d}%}\n{hr|}\n{a|}',
            padding: [0, -70],
            rich: {
              name: {
                lineHeight: 10,//设置最后一行空数据高度，为了能让延长线与hr线对接起来
                align: 'center'
              },
              hr: {//设置hr是为了让中间线能够自适应长度
                borderColor: 'inherit',//hr的颜色为auto时候会主动显示颜色的
                width: '110%',
                borderWidth: 0.5,
                height: 0.5
              },
              value: {//用百分比数据来调整下数字位置，显的好看些。如果不设置，formatter最后一行的空数据就不需要
                padding: [-4, 0]
              }
            }
          },
          labelLine: {
            length: 15,
            length2: 70,
            maxSurfaceAngle: 80
          },
          data: that.chartData
        }
      };

    }
  },

}

</script>

<style scoped>
.echarts_especial
{
  width: 40vw;
}
.el-divider--horizontal {
  margin: 2px 0;
}

.el-divider--vertical {
  display: inline-block;
  weight: 1px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;
}
</style>
