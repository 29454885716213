import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/base-fertilizers', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/base-fertilizers/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/base-fertilizers', updateModel)
  },
  getById(id) {
    return httpRequest.get('/base-fertilizers/' + id)
  }
}
