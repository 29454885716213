<template>
  <div>
    <div class="wg-box" v-loading="dataLoading" element-loading-text="拼命加载数据......" element-loading-spinner="el-icon-loading">
      <div class="header">
        <div class="button-list">
          <el-button size="mini" class="wg-button blue" icon="el-icon-share" style="display: none">导出</el-button>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <div class="body">
        <div class="wg-table-box" style="padding-bottom: 10px">
          <el-table :data="tableData" class="wg-table normal" style="100%">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column label="作物名称" prop="name" width="120"></el-table-column>
            <el-table-column prop="plantArea">
              <template slot="header">种植<br />面积<br />(万亩)</template>
            </el-table-column>
            <el-table-column prop="totalYield">
              <template slot="header">总产量<br />(万吨)</template>
            </el-table-column>
            <el-table-column label="氮肥(万吨)">
              <el-table-column prop="nitrogenTotal">
                <template slot="header">总量<br />(N)</template>
              </el-table-column>
              <el-table-column prop="urea">
                <template slot="header">其中：<br />尿素</template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="磷肥(万吨)">
              <el-table-column prop="phosphorusTotal">
                <template slot="header">总量<br />(P₂O₅)</template>
              </el-table-column>
              <el-table-column prop="diammoniumPhosphate">
                <template slot="header">其中：<br />磷酸二铵</template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="钾肥(万吨)">
              <el-table-column prop="potassiumTotal">
                <template slot="header">总量<br />(K₂O)</template>
              </el-table-column>
              <el-table-column prop="potassiumChloride">
                <template slot="header">其中：<br />氯化钾</template>
              </el-table-column>
              <el-table-column prop="potassiumSulphate">
                <template slot="header">其中：<br />硫酸钾</template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="复合肥料（万吨，实物）">
              <el-table-column label="低浓度" prop="compoundFertilizerLow" width="100"></el-table-column>
              <el-table-column label="中浓度" prop="compoundFertilizerMiddle" width="100"></el-table-column>
              <el-table-column label="高浓度" prop="compoundFertilizerHigh" width="100"></el-table-column>
            </el-table-column>
            <el-table-column label="有机肥料（万吨，实物）">
              <el-table-column label="总量" prop="organicFertilizerTotal"></el-table-column>
              <el-table-column prop="businessOrganicFertilizer">
                <template slot="header">商品<br />有机肥</template>
              </el-table-column>
              <el-table-column label="其他" prop="organicFertilizerOther"></el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FertilizerDosageApi from '@/api/survey/fertilizer-dosage-api'

export default {
  name: 'SurfaceSurveyCountryComponent',
  data() {
    return {
      params: {
        year: null,
        regionId: null
      },
      dataLoading: false,
      tableData: []
    }
  },
  methods: {
    bindTableData() {
      this.dataLoading = true
      FertilizerDosageApi.getAggregate(this.params.year, this.params.regionId)
        .then(res => {
          this.tableData = []
          let index = 0
          let overall = res.data.overallFertilizerDosage
          this.tableData.push({
            name: '总计',
            plantArea: null === overall.plantArea || undefined === overall.plantArea ? null : overall.plantArea.toFixed(4),
            totalYield: null === overall.totalYield || undefined === overall.totalYield ? null : overall.totalYield.toFixed(4),
            nitrogenTotal: null === overall.nitrogenTotal || undefined === overall.nitrogenTotal ? null : overall.nitrogenTotal.toFixed(4),
            urea: null === overall.urea || undefined === overall.urea ? null : overall.urea.toFixed(4),
            phosphorusTotal: null === overall.phosphorusTotal || undefined === overall.phosphorusTotal ? null : overall.phosphorusTotal.toFixed(4),
            diammoniumPhosphate: null === overall.diammoniumPhosphate || undefined === overall.diammoniumPhosphate ? null : overall.diammoniumPhosphate.toFixed(4),
            potassiumTotal: null === overall.potassiumTotal || undefined === overall.potassiumTotal ? null : overall.potassiumTotal.toFixed(4),
            potassiumChloride: null === overall.potassiumChloride || undefined === overall.potassiumChloride ? null : overall.potassiumChloride.toFixed(4),
            potassiumSulphate: null === overall.potassiumSulphate || undefined === overall.potassiumSulphate ? null : overall.potassiumSulphate.toFixed(4),
            compoundFertilizerLow: null === overall.compoundFertilizerLow || undefined === overall.compoundFertilizerLow ? null : overall.compoundFertilizerLow.toFixed(4),
            compoundFertilizerMiddle: null === overall.compoundFertilizerMiddle || undefined === overall.compoundFertilizerMiddle ? null : overall.compoundFertilizerMiddle.toFixed(4),
            compoundFertilizerHigh: null === overall.compoundFertilizerHigh || undefined === overall.compoundFertilizerHigh ? null : overall.compoundFertilizerHigh.toFixed(4),
            organicFertilizerTotal: null === overall.organicFertilizerTotal || undefined === overall.organicFertilizerTotal ? null : overall.organicFertilizerTotal.toFixed(4),
            businessOrganicFertilizer: null === overall.businessOrganicFertilizer || undefined === overall.businessOrganicFertilizer ? null : overall.businessOrganicFertilizer.toFixed(4),
            organicFertilizerOther: null === overall.organicFertilizerOther || undefined === overall.organicFertilizerOther ? null : overall.organicFertilizerOther.toFixed(4)
          })
          res.data.stapleCropFertilizerDosageList.forEach(el => {
            this.tableData.push({
              index: index++,
              name: el.cropName,
              plantArea: null === el.plantArea || undefined === el.plantArea ? null : el.plantArea.toFixed(4),
              totalYield: null === el.totalYield || undefined === el.totalYield ? null : el.totalYield.toFixed(4),
              nitrogenTotal: null === el.nitrogenTotal || undefined === el.nitrogenTotal ? null : el.nitrogenTotal.toFixed(4),
              urea: null === el.urea || undefined === el.urea ? null : el.urea.toFixed(4),
              phosphorusTotal: null === el.phosphorusTotal || undefined === el.phosphorusTotal ? null : el.phosphorusTotal.toFixed(4),
              diammoniumPhosphate: null === el.diammoniumPhosphate || undefined === el.diammoniumPhosphate ? null : el.diammoniumPhosphate.toFixed(4),
              potassiumTotal: null === el.potassiumTotal || undefined === el.potassiumTotal ? null : el.potassiumTotal.toFixed(4),
              potassiumChloride: null === el.potassiumChloride || undefined === el.potassiumChloride ? null : el.potassiumChloride.toFixed(4),
              potassiumSulphate: null === el.potassiumSulphate || undefined === el.potassiumSulphate ? null : el.potassiumSulphate.toFixed(4),
              compoundFertilizerLow: null === el.compoundFertilizerLow || undefined === el.compoundFertilizerLow ? null : el.compoundFertilizerLow.toFixed(4),
              compoundFertilizerMiddle: null === el.compoundFertilizerMiddle || undefined === el.compoundFertilizerMiddle ? null : el.compoundFertilizerMiddle.toFixed(4),
              compoundFertilizerHigh: null === el.compoundFertilizerHigh || undefined === el.compoundFertilizerHigh ? null : el.compoundFertilizerHigh.toFixed(4),
              organicFertilizerTotal: null === el.organicFertilizerTotal || undefined === el.organicFertilizerTotal ? null : el.organicFertilizerTotal.toFixed(4),
              businessOrganicFertilizer: null === el.businessOrganicFertilizer || undefined === el.businessOrganicFertilizer ? null : el.businessOrganicFertilizer.toFixed(4),
              organicFertilizerOther: null === el.organicFertilizerOther || undefined === el.organicFertilizerOther ? null : el.organicFertilizerOther.toFixed(4)
            })
          })
          let other = res.data.otherFertilizerDosage
          this.tableData.push({
            index: index,
            name: '其他',
            plantArea: null === other.plantArea || undefined === other.plantArea ? null : other.plantArea.toFixed(4),
            totalYield: null === other.totalYield || undefined === other.totalYield ? null : other.totalYield.toFixed(4),
            nitrogenTotal: null === other.nitrogenTotal || undefined === other.nitrogenTotal ? null : other.nitrogenTotal.toFixed(4),
            urea: null === other.urea || undefined === other.urea ? null : other.urea.toFixed(4),
            phosphorusTotal: null === other.phosphorusTotal || undefined === other.phosphorusTotal ? null : other.phosphorusTotal.toFixed(4),
            diammoniumPhosphate: null === other.diammoniumPhosphate || undefined === other.diammoniumPhosphate ? null : other.diammoniumPhosphate.toFixed(4),
            potassiumTotal: null === other.potassiumTotal || undefined === other.potassiumTotal ? null : other.potassiumTotal.toFixed(4),
            potassiumChloride: null === other.potassiumChloride || undefined === other.potassiumChloride ? null : other.potassiumChloride.toFixed(4),
            potassiumSulphate: null === other.potassiumSulphate || undefined === other.potassiumSulphate ? null : other.potassiumSulphate.toFixed(4),
            compoundFertilizerLow: null === other.compoundFertilizerLow || undefined === other.compoundFertilizerLow ? null : other.compoundFertilizerLow.toFixed(4),
            compoundFertilizerMiddle: null === other.compoundFertilizerMiddle || undefined === other.compoundFertilizerMiddle ? null : other.compoundFertilizerMiddle.toFixed(4),
            compoundFertilizerHigh: null === other.compoundFertilizerHigh || undefined === other.compoundFertilizerHigh ? null : other.compoundFertilizerHigh.toFixed(4),
            organicFertilizerTotal: null === other.organicFertilizerTotal || undefined === other.organicFertilizerTotal ? null : other.organicFertilizerTotal.toFixed(4),
            businessOrganicFertilizer: null === other.businessOrganicFertilizer || undefined === other.businessOrganicFertilizer ? null : other.businessOrganicFertilizer.toFixed(4),
            organicFertilizerOther: null === other.organicFertilizerOther || undefined === other.organicFertilizerOther ? null : other.organicFertilizerOther.toFixed(4)
          })
        })
        .catch(err => {
          console.log(err)
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
        .finally(() => {
          this.dataLoading = false
        })
    },
    load(year, regionId) {
      this.params.year = year
      this.params.regionId = regionId
      this.bindTableData()
    }
  }
}
</script>

<style scoped></style>
