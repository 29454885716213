<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/data-audit' }">数据审核</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <el-container>
      <el-aside class="wg-side" :width="isCollapse ? '24px' : '240px'">
        <div v-show="!isCollapse">
          <div class="side-fence">
            数据审核
            <i class="el-icon-d-arrow-left collapse-button" @click="handleToggleCollapse"></i>
          </div>
          <region-tree @node-click="handleRegionTreeNodeClick"></region-tree>
        </div>
        <div v-show="isCollapse" class="collapse-fence">
          展开菜单栏
          <div class="collapse-icon">
            <i class="el-icon-d-arrow-right" @click="handleToggleCollapse"></i>
          </div>
        </div>
      </el-aside>
      <el-main class="wg-main">
        <unarchived-data-audit-tab ref="unarchivedDataAuditTab" :year="params.year" :regionId="params.regionId" v-if="flags.isLoadUnarchivedDataAuditTabComponent"></unarchived-data-audit-tab>
        <archived-data-audit-tab ref="archivedDataAuditTab" :year="params.year" :regionId="params.regionId" v-if="flags.isLoadArchivedDataAuditTabComponent"></archived-data-audit-tab>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import FillInCycleApi from '@/api/data-fill-in/cycle/fill-in-cycle-api'

import RegionTree from './components/RegionTree'

import UnarchivedDataAuditTab from './components/UnarchivedDataAuditTab'
import ArchivedDataAuditTab from './components/archive/ArchivedDataAuditTab'

export default {
  name: 'DataAudit',
  components: {
    'region-tree': RegionTree,
    'unarchived-data-audit-tab': UnarchivedDataAuditTab,
    'archived-data-audit-tab': ArchivedDataAuditTab
  },
  data() {
    return {
      isCollapse: false,
      params: {
        year: null,
        regionId: null,
        archiveFlag: null
      },
      flags: {
        isLoadUnarchivedDataAuditTabComponent: false,
        isLoadArchivedDataAuditTabComponent: false
      }
    }
  },
  created() {
    FillInCycleApi.getLast().then(res => {
      let userLevelId = sessionStorage.getItem('levelId')
      switch (userLevelId) {
        case 'Province':
          this.params.regionId = sessionStorage.getItem('provinceId')
          break
        case 'City':
          this.params.regionId = sessionStorage.getItem('cityId')
          break
        case 'District':
          this.params.regionId = sessionStorage.getItem('districtId')
          break
      }
      this.params.year = res.data.year
      this.params.archiveFlag = res.data.archiveFlag

      this.flags.isLoadUnarchivedDataAuditTabComponent = !this.params.archiveFlag
      this.flags.isLoadArchivedDataAuditTabComponent = this.params.archiveFlag
    })
  },
  methods: {
    handleToggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    handleRegionTreeNodeClick(checkedItem) {
      if (checkedItem.year === this.params.year && checkedItem.regionId === this.params.regionId && checkedItem.archiveFlag === this.params.archiveFlag) {
        if (this.params.archiveFlag)
          this.$refs.archivedDataAuditTab.reloadComponent()
        else
          this.$refs.unarchivedDataAuditTab.reloadComponent()

        return
      }

      this.params.year = checkedItem.year
      this.params.regionId = checkedItem.regionId
      this.params.archiveFlag = checkedItem.archiveFlag

      if (this.params.archiveFlag) {
        if (this.flags.isLoadUnarchivedDataAuditTabComponent) {
          this.flags.isLoadUnarchivedDataAuditTabComponent = false
          this.flags.isLoadArchivedDataAuditTabComponent = true
        }
      } else {
        if (this.flags.isLoadArchivedDataAuditTabComponent) {
          this.flags.isLoadUnarchivedDataAuditTabComponent = true
          this.flags.isLoadArchivedDataAuditTabComponent = false
        }
      }
    }
  }
}
</script>

<style scoped></style>
