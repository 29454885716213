<template>
 <div>
   <v-chart
     :option='map'
     :init-options='initOptions'
     ref='map'
     :loading='loading'
     :loadingOptions='loadingOptions'
     autoresize
   />
 </div>
</template>
<script>

import VChart from 'vue-echarts'
import { registerMap, use } from 'echarts/core'
import { MapChart, ScatterChart } from 'echarts/charts'
import { GeoComponent, MarkPointComponent, TitleComponent, VisualMapComponent } from 'echarts/components'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import SurveyStatisticApi from '@/api/statistic/survey-statistic-api'

use([
  MapChart,
  ScatterChart,
  GeoComponent,
  VisualMapComponent,
  MarkPointComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
])

export default {
  name: 'DistributionMap',
  components: { VChart },
  props:["year"],
  data() {
    return {
      mapName: '',
      geoJson: null,
      mapData: null,
      layoutCenter: ["50%", "50%"],
      layoutSize: '100%',
      initOptions: {
        renderer: 'canvas'
      },
      chartData: null,
      map: null,
      seconds: -1,
      loading: false,
      loadingOptions: {
        text: '加载中…',
        color: '#4ea397',
        maskColor: 'rgba(255, 255, 255, 0.4)'
      }
    }
  },
  watch:{
    year() {
      this.refresh()
    }
  },
  methods: {
    /*handleGoeroam(e) {
      /!*this.$refs.map.$echarts.dispatchAction({
        type: 'geoRoam',
        x: e.offsetX,
        y: e.offsetY
      })*!/
      console.log(e)
    },*/
    ceilNumber(num, prec = 10, ceil = true) {
      const len = String(num).length
      if (len <= prec) { return num }

      const mult = Math.pow(10, prec)
      return ceil ?
        Math.ceil(num / mult) * mult :
        Math.floor(num / mult) * mult
    },
    refresh() {
      this.getChartData()
    },
    getChartData() {
      this.loading = true
      let dataFolder = '/data/map'

      let userLevelId = sessionStorage.getItem('levelId')
      let userProvinceId = sessionStorage.getItem('provinceId')
      let userCityId = sessionStorage.getItem('cityId')
      let userDistrictId = sessionStorage.getItem('districtId')
      // Professional和Farmer的用户可以是province，city，district三者中任意一个, 所以按所属区域来获取地图数据
      switch (userLevelId) {
        case 'Country':
          this.mapName = 'china'
          this.layoutSize = '120%'
          this.layoutCenter = ["50%", "70%"]
          break
        default:
          // 区县没有单独的地图数据，所以使用市（直辖市）地图数据
          if(userDistrictId && userDistrictId !== 'null') {
            // 直辖市辖区地图特殊处理，地图数据不在city中，直接在province中
            if(sessionStorage.getItem('cityName') === '市辖区') {
              this.mapName = sessionStorage.getItem('provinceId')
              dataFolder = `${dataFolder}/province`
            } else {
              this.mapName = sessionStorage.getItem('cityId')
              dataFolder = `${dataFolder}/city`
            }
          } else if(userCityId && userCityId !== 'null') {
            this.mapName = sessionStorage.getItem('cityId')
            dataFolder = `${dataFolder}/city`
          } else if(userProvinceId && userProvinceId !== 'null') {
            this.mapName = sessionStorage.getItem('provinceId')
            dataFolder = `${dataFolder}/province`
          }
          break
      }

      fetch(`${dataFolder}/${this.mapName}.json`).then((res) => {return res.json(); }).then(res => {
        this.geoJson = res
        // 区县用户只能查看自己所属的区县地图；市辖区用户只能查看自己所属区的地图
        if(userLevelId === 'District' || (userLevelId !== 'Country' && sessionStorage.getItem('cityName') === '市辖区')) {
          this.geoJson.features = res.features.filter(item => {
            if (item.properties.name === sessionStorage.getItem('districtName')) {
              return item
            }
          })
        }
        registerMap(this.mapName, this.geoJson)
        this.renderChart()
      })
    },
    renderChart() {
      var mapData = this.geoJson.features.map(function(item){
        return {
          code: sessionStorage.getItem('levelId') === 'Country' ? item.properties.adcode.toString() : item.properties.id.toString(),
          name: item.properties.name,
          value: 0,//item.properties.childrenNum,
          cp: item.properties.center,
        }
      });

      let convertData = function(data) {
        if(!data || data.length === 0) {
          return mapData
        }
        let res = []
        mapData.forEach((v) => {
          for (let i = 0; i < data.length; i++) {
            if (v.code === data[i].name) {
              res.push({
                name: v.name,
                value: data[i].value
              })
            }
          }
        })
        return res
      }
      let generatePieces = function (maxvalue, count) {
          const result = [];
          const step = Math.floor(maxvalue / (count - 1));
          for (let i = 0; i < count; i++) {
            if (i === count - 1) {
              result.push({ gte: Math.floor(i * step), label: '>' + Math.floor(i * step) });
            } else {
              result.push({ gte: Math.floor(i * step)+(i > 0 ? 1 : 0), lt: Math.floor((i + 1) * step), label: `${Math.floor(i * step)+(i > 0 ? 1 : 0)} - ${Math.floor((i + 1) * step)}` });
            }
          }
          return result;
      }
      SurveyStatisticApi.getSurveyRegionSummaries(this.year).then(res => {
        this.chartData = res.data

        let arr = []
        let maxValue = 100
        const splitNumber = 5
        if(res.data.length > 0) {
          arr = this.chartData.map(p => p.value)
          maxValue = this.ceilNumber(Math.max(...arr))
        }
        this.map = {
          grid: {
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          },
          tooltip: {
            trigger: 'item',
            formatter:function(params){
              return params.name === '' ? '' : `${params.name}\t\t<span style="color:red;">${~~params.value}</span>` //~~符号可以将NaN替换成0
            }
          },
          layoutCenter: this.layoutCenter,
          layoutSize: this.layoutSize,
          visualMap: {
            type: 'piecewise',
            left: 'right',
            min: 0,
            max: maxValue,
            splitNumber: splitNumber,
            seriesIndex: 0,
            itemWidth: 26,
            itemHeight: 16,
            itemGap: 0,
            itemSymbol: 'rect',
            orient: 'vertical',
            inverse: true,
            showLabel: true,
            text: ['高', '低'],
            realtime: false,
            calculable: true,
            inRange: {
              color: ['#E3FEED', '#B0FFCC', '#59EA9C', '#03A83B', '#027B2A']
            },
            pieces: generatePieces(maxValue, splitNumber),
            /*pieces: [
              {
                gte: 0,
                lt: 10,
                label: '0 - 10',
                color: '#E3FEED'
              }, {
                gte: 10,
                lte: 20,
                label: '10 - 20',
                color: '#B0FFCC'
              }, {
                gte: 30,
                lt: 40,
                label: '30 - 40',
                color: '#59EA9C'
              }, {
                gte: 40,
                lt: 50,
                label: '40 - 50',
                color: '#03A83B'
              }, {
                gte: 50,
                label: '>50',
                color: '#027B2A'
              }
            ],*/
            textStyle: {
              height: 20,
              fontSize: 12,
              fontWeight: 400,
              lineHeight: 30,
            }
          },
          series: [
            {
              name: '',
              type: 'map',
              map: this.mapName,
              //geoIndex: 0,
              //zoom: 1.1, //当前视角的缩放比例
              roam: true, //是否开启平游或缩放
              scaleLimit: { //滚轮缩放的极限控制
                min: 0.7,
                max: 1.2 //可以放大几倍
              },
              itemStyle: {
                areaColor: '#E3FEED',
                borderColor: 'rgba(0, 0, 0, 0.5)',
                borderWidth: 1,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                shadowBlur: 15,
                shadowOffsetX: 0,
                shadowOffsetY: 1,
              },
              label: {
                show: true,
                formatter:function(params){
                  return params.name === '' ? '' : `${params.name}\t\t{value|${~~params.value}}` //~~符号可以将NaN替换成0
                },
                backgroundColor: '#FFFFFF', //标签背景颜色
                borderColor: '#EAEAEA',  //外层边框颜色
                borderWidth: 1, //外层边框宽度
                lineHeight: 24,
                height: 24,
                padding: [2, 5, 2, 5],
                borderRadius: 2,
                rich: {
                  value: {
                    fontSize: 14,
                    color: '#FF0000'
                  },
                }
              },
              /*labelLayout: function(params) {
                return {
                  x: params.rect.x,
                  moveOverlap: 'shiftY'
                }
              },*/
              emphasis: {
                show: true,
                label: {
                  show: true,
                },
              },
              encode: {
                value: 2
              },
              z: 99,
              data: convertData(this.chartData),
            }
          ]
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.echarts {
  width: 100%;
  height: 750px;
}
</style>
