<template>
  <div class="wg-box">
    <div class="header uc-header">
      <div class="title">修改手机号</div>
      <div class="wg-clear-fix"></div>
    </div>
    <div class="body">
      <el-form ref="form" :model="form" :rules="formRules" label-width="120px" :show-message="true" v-loading="loading" element-loading-text="等待手机号修改......" element-loading-spinner="el-icon-loading">
        <el-row>
          <el-col :span="8">
            <el-form-item label="手机号码：" prop="phoneNumber">
              <el-input v-model="form.phoneNumber"></el-input>
            </el-form-item>
            <el-form-item label="验证码：" prop="verifyCode">
              <el-col :span="12">
                <el-input v-model="form.verifyCode"></el-input>
              </el-col>
              <el-col :span="10" :offset="2">
                <el-button class="verify-button" @click="handleSendVerifyCode" :disabled="flags.sendVerifyCodeDisableFlag">{{ sendVerifyButtonText }}</el-button>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-button class="wg-button" @click="handleSave">修改</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import AccountApi from '@/api/account-api'
import ShortMessageApi from '@/api/short-message-api'

export default {
  name: 'ChangePhoneNumber',
  data() {
    return {
      flags: {
        sendVerifyCodeDisableFlag: false
      },
      loading: false,
      sendVerifyButtonText: '发送验证码',
      form: {
        phoneNumber: '',
        verifyCode: ''
      },
      formRules: {
        phoneNumber: [{ required: true, trigger: 'blur', message: '请输入手机号码！' }],
        verifyCode: [{ required: true, trigger: 'blur', message: '请输入验证码！' }]
      }
    }
  },
  methods: {
    handleSendVerifyCode() {
      if (null === this.form.phoneNumber || '' === this.form.phoneNumber) {
        this.$message({ type: 'warning', message: '请填写手机号码！' })
        return
      }

      ShortMessageApi.sendPhoneNumberVerifyCode(this.form.phoneNumber)
        .then(res => {
          if (res.data.state === 'SUCCESS') {
            let waitTimes = 61
            this.flags.sendVerifyCodeDisableFlag = true
            this.sendVerifyButtonText = waitTimes + 's后重新获取'
            let timer = setInterval(() => {
              if (waitTimes > 1) {
                waitTimes--
                this.sendVerifyButtonText = waitTimes + 's后重新获取'
              } else {
                clearInterval(timer)
                this.sendVerifyButtonText = '获取验证码'
                this.flags.sendVerifyCodeDisableFlag = false
              }
            }, 1000)
          } else if (res.data.state === 'WARNING') {
            this.$message({ type: 'warning', message: res.data.message })
          } else if (res.data.state === 'ERROR') {
            this.$message({ type: 'error', message: res.data.message })
          } else {
            this.$message({ type: 'error', message: '系统发生异常！' })
          }
        })
        .catch(function (err) {
          this.$message({ type: 'error', message: '【' + err.data.code + '】' + err.data.message })
        })
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return false

        this.loading = true
        let theVue = this
        AccountApi.changePhoneNumber(this.form.phoneNumber, this.form.verifyCode)
          .then(() => {
            this.$message({
              type: 'success',
              message: '手机号修改成功！',
              duration: 1000,
              onClose: function () {
                theVue.$router.push({ path: '/own-integration' })
              }
            })
          })
          .catch(function (err) {
            theVue.$message({ type: 'error', message: '【' + err.data.code + '】' + err.data.message })
            theVue.loading = false
          })
      })
    }
  }
}
</script>

<style scoped>
</style>