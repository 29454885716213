<template>
  <div>
    <div class="wg-box">
      <div class="header uc-header">
        <div class="title">个人中心</div>
        <div class="wg-clear-fix"></div>
      </div>
      <div class="body uc-body">
        <el-row>
          <el-col :span="4" :offset="2">姓名：<span v-text="user.fullName"></span></el-col>
          <el-col :span="4">手机号码：<span v-text="user.phoneNumber"></span></el-col>
          <el-col :span="4">累计积分：<span style="color: #ff8900" v-text="null === user.integration ? '--' : user.integration"></span></el-col>
        </el-row>
      </div>
    </div>
    <el-row :gutter="20">
      <el-col :span="4">
        <div class="wg-box">
          <div class="header uc-side-header">
            <div class="title"><i class="el-icon-user" style="font-size: 20px"></i>&nbsp;个人中心</div>
          </div>
          <div class="body">
            <ul class="uc-nav">
              <li><router-link to="/personal" :class="'personal' === activedLinkName ? 'acitved' : ''" @click="() => (activedLinkName = 'personal')">个人资料</router-link></li>
              <li><router-link to="/own-integration" :class="'own-integration' === activedLinkName ? 'acitved' : ''" @click="() => (activedLinkName = 'own-integration')">我的积分</router-link></li>
              <li><router-link to="/change-password" :class="'change-password' === activedLinkName ? 'acitved' : ''" @click="() => (activedLinkName = 'change-password')">修改密码</router-link></li>
              <li><router-link to="/change-phone-number" :class="'change-phone-number' === activedLinkName ? 'acitved' : ''" @click="() => (activedLinkName = 'change-phone-number')">修改手机号</router-link></li>
            </ul>
          </div>
        </div>
      </el-col>
      <el-col :span="20">
        <router-view></router-view>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AccountApi from '@/api/account-api'

export default {
  name: 'UCenter',
  data() {
    return {
      activedLinkName: 'personal',
      user: {
        fullName: '--',
        phoneNumber: '--',
        integration: null,
        phoneNumberValidFlag: false
      }
    }
  },
  created() {
    AccountApi.getOwnAccount()
      .then(res => {
        this.user.fullName = res.data.fullName
        this.user.phoneNumber = res.data.phoneNumber
        this.user.integration = res.data.integration
        this.user.phoneNumberValidFlag = res.data.phoneNumberValidFlag
      })
      .catch(err => {
        this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
      })
  }
}
</script>

<style>
.wg-box > .header.uc-header {
  padding: 10px 0 20px 10px;
  border-bottom: 1px solid #e9e9e9;
}

.wg-box > .header.uc-side-header {
  padding: 10px 0 20px 0;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;
}

.wg-box > .header.uc-side-header > .title {
  float: none;
}

.wg-box > .body.uc-body {
  padding: 40px 0 40px 0;
  font-size: 14px;
}
</style>

<style scoped>
.uc-nav {
  margin: 0;
  padding: 20px 0 30px 0;
  list-style-type: none;
  font-size: 14px;
}

.uc-nav li {
  text-align: center;
  padding: 10px 0 10px 0;
}

.uc-nav li a {
  text-decoration: none;
  color: #333;
}

.uc-nav li a:hover {
  color: #039f3a;
}

.uc-nav li a.acitved {
  color: #039f3a;
}
</style>