import Vuex from 'vuex'

export default {
  create() {
    const mainStore = new Vuex.Store({
      modules: {},
      state: {},
      mutations: {},
      actions: {},
      getters: {}
    })

    return mainStore
  }
}
