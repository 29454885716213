<template>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
    <div class="wg-box">
      <div class="body">
        <el-alert title="提示：" type="warning" description="新增地块【所属用户】选择后不可修改！【管理人】是指管理该地块的人员并非地块拥有者！" :closable="false" show-icon></el-alert>
        <el-form ref="form" :model="form" :rules="form.rules" label-width="120px">
          <el-row :gutter="30">
            <el-col :span="12">
              <el-form-item label="所在省份" prop="provinceId">
                <el-select v-model="form.provinceId" style="width: 100%" v-bind:disabled="flags.provinceSelectDisable" @change="handleProvinceChange">
                  <el-option v-for="option in form.filterData.provinceOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所在地市" prop="cityId">
                <el-select v-model="form.cityId" style="width: 100%" v-bind:disabled="flags.citySelectDisable" @change="handleCityChange">
                  <el-option v-for="option in form.filterData.cityOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所在区县" prop="districtId">
                <el-select v-model="form.districtId" v-bind:disabled="flags.districtSelectDisable" style="width: 100%" @change="handleDistrictChange">
                  <el-option v-for="option in form.filterData.districtOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="详细位置" prop="detailedAddress">
                <el-input v-model="form.detailedAddress"></el-input>
              </el-form-item>
              <el-form-item label="所属用户" prop="farmerId" v-if="flags.farmerSelectShow">
                <el-select v-model="form.farmerId" style="width: 100%" @change="handleFarmerSelect">
                  <el-option v-for="option in form.filterData.farmerOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="地块名称" prop="name">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="经度（十进制）" prop="longitude">
                <el-input v-model="form.longitude" placeholder="格式：十进制"></el-input>
              </el-form-item>
              <el-form-item label="纬度（十进制）" prop="latitude">
                <el-input v-model="form.latitude" placeholder="格式：十进制"></el-input>
              </el-form-item>
              <el-form-item label="地块面积（亩）" prop="plotArea">
                <el-input v-model="form.plotArea"></el-input>
              </el-form-item>
              <el-form-item label="建立时间" prop="buildTime">
                <el-date-picker style="width: 100%" v-model="form.buildTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" :picker-options="pickerOptionsRule"></el-date-picker>
              </el-form-item>
              <el-form-item label="管理人" prop="managerFullName" :style="styles.other">
                <el-input v-model="form.managerFullName"></el-input>
              </el-form-item>
              <el-form-item label="手机号码" prop="managerPhoneNumber" :style="styles.other">
                <el-input v-model="form.managerPhoneNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="wg-clear-fix"></div>
      </div>
      <div slot="footer" class="footer">
        <div class="button-list right">
          <el-button class="wg-button blank" @click="dialogVisible = false">取 消</el-button>
          <el-button class="wg-button" @click="handleSave">保 存</el-button>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import RegionApi from '@/api/region-api'
import MonitoringPlotApi from '@/api/monitoring-plot-api'
import AccountApi from '@/api/account-api'
import FarmerApi from '@/api/farmer-api'

export default {
  name: 'PlotCreateDialog',
  data() {
    const validatePhoneNumber = (rule, value, callback) => {
      if (!this.$utils.isEmpty(value)) {
        const regOfPhoneNumber = /^[1-9][0-9]{10}$/
        const regOfTelNumber = /^(0\d{2,3})-?(\d{7,8})$/
        if (regOfPhoneNumber.test(value)) {
          callback()
        } else if (regOfTelNumber.test(value)) {
          callback()
        } else {
          callback('电话号码输入格式错误！')
        }
      } else {
        callback()
      }
    }

    const validateLongitude = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        // eslint-disable-next-line no-useless-escape
        const reg = /^[\-\+]?(0(\.\d{1,8})?|([1-9](\d)?)(\.\d{1,8})?|1[0-7]\d{1}(\.\d{1,8})?|180(([.][0]{1,8})?))$/
        if (reg.test(value)) {
          callback()
        } else {
          callback('经度格式输入错误！')
        }
      } else {
        callback()
      }
    }
    const validateLatitude = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        // eslint-disable-next-line no-useless-escape
        const reg = /^[\-\+]?((0|([1-8]\d?))(\.\d{1,10})?|90(\.0{1,10})?)$/
        if (reg.test(value)) {
          callback()
        } else {
          callback('经度格式输入错误！')
        }
      } else {
        callback()
      }
    }

    const validatePlantArea = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^([1-9]\d*(\.\d*)?)$|^0\.\d*[1-9]\d*$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入数字！'))
        }
      } else {
        callback()
      }
    }
    return {
      dialogTitle: '新增地块',
      dialogVisible: false,
      models: {
        farmerArray: []
      },
      flags: {
        provinceSelectDisable: false,
        citySelectDisable: false,
        districtSelectDisable: false,
        farmerSelectShow: false
      },
      styles: {
        other: ''
      },
      pickerOptionsRule: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      form: {
        filterData: {
          provinceOptionList: [],
          cityOptionList: [],
          districtOptionList: [],
          farmerOptionList: []
        },
        rules: {
          provinceId: [{ required: false, trigger: 'change', message: '请选择省份！' }],
          cityId: [{ required: false, trigger: 'change', message: '请选择省份！' }],
          districtId: [{ required: false, trigger: 'change', message: '请选择省份！' }],
          detailedAddress: [{ required: true, message: '请填写详细地址！', trigger: 'blur' }],
          farmerId: [{ required: true, message: '请选择农户账号！', trigger: 'change' }],
          managerFullName: [],
          managerPhoneNumber: [{ trigger: 'blur', validator: validatePhoneNumber }],
          name: [{ required: true, message: '请填写监测地块名称！', trigger: 'blur' }],
          longitude: [{ trigger: 'blur', validator: validateLongitude }],
          latitude: [{ trigger: 'blur', validator: validateLatitude }],
          plotArea: [
            { required: true, message: '请填写地块面积！', trigger: 'blur' },
            { trigger: 'blur', validator: validatePlantArea }
          ],
          buildTime: [{ required: true, message: '请选择建立时间！', trigger: 'blur' }]
        },
        provinceId: null,
        cityId: null,
        districtId: null,
        detailedAddress: null,
        farmerId: null,
        managerFullName: null,
        managerPhoneNumber: null,
        name: null,
        longitude: null,
        latitude: null,
        plotArea: null,
        buildTime: this.$moment().format('YYYY-MM-DD')
      }
    }
  },
  created() {
    let userLevelId = 'null' === sessionStorage.getItem('levelId') ? null : sessionStorage.getItem('levelId')
    let provinceId = 'null' === sessionStorage.getItem('provinceId') ? null : sessionStorage.getItem('provinceId')
    let cityId = 'null' === sessionStorage.getItem('cityId') ? null : sessionStorage.getItem('cityId')
    let districtId = 'null' === sessionStorage.getItem('districtId') ? null : sessionStorage.getItem('districtId')
    let regionId = null

    switch (userLevelId) {
      case 'Province':
        {
          regionId = provinceId
          this.form.provinceId = provinceId
          this.flags.provinceSelectDisable = true
          this.bindProvinceSelectData()
          this.bindCitySelectData(provinceId)
          this.bindDistrictSelectData(null)
        }
        break
      case 'City':
        {
          regionId = cityId
          this.form.provinceId = provinceId
          this.form.cityId = cityId
          this.flags.provinceSelectDisable = true
          this.flags.citySelectDisable = true
          this.bindCitySelectData(provinceId)
          this.bindDistrictSelectData(cityId)
        }
        break
      case 'District':
        {
          regionId = districtId
          this.form.provinceId = provinceId
          this.form.cityId = cityId
          this.form.districtId = districtId
          this.flags.provinceSelectDisable = true
          this.flags.citySelectDisable = true
          this.flags.districtSelectDisable = true
          this.bindProvinceSelectData()
          this.bindCitySelectData(provinceId)
          this.bindDistrictSelectData(cityId)
        }
        break
      case 'Farmer':
        {
          regionId = districtId
          this.form.provinceId = provinceId
          this.form.cityId = cityId
          this.form.districtId = districtId
          this.flags.provinceSelectDisable = true
          this.flags.citySelectDisable = true
          this.flags.districtSelectDisable = true
          this.bindProvinceSelectData()
          this.bindCitySelectData(provinceId)
          this.bindDistrictSelectData(cityId)
        }
        break
      case 'Professional':
        {
          regionId = districtId
          this.styles.other = 'display:none'
          this.form.rules.provinceId[0].required = true
          this.form.rules.cityId[0].required = true
          this.form.rules.districtId[0].required = true
          this.form.provinceId = provinceId
          this.form.cityId = cityId
          this.form.districtId = districtId
          this.bindProvinceSelectData()
          if (null !== provinceId) {
            this.bindCitySelectData(provinceId)
          }
          if (null !== cityId) {
            this.bindDistrictSelectData(cityId)
          }
        }
        break
      default:
        {
          this.bindProvinceSelectData()
          this.bindCitySelectData(null)
          this.bindDistrictSelectData(null)
        }
        break
    }

    if ('Farmer' === userLevelId || 'Professional' === userLevelId) {
      AccountApi.getOwnAccount().then(res => {
        this.form.farmerId = res.data.id
        this.form.managerFullName = res.data.fullName
        this.form.managerPhoneNumber = res.data.phoneNumber
        this.form.detailedAddress = res.data.detailedAddress
      })
    } else {
      this.flags.farmerSelectShow = true
      this.bindFarmerSelectData(regionId)
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleProvinceChange(selectedValue) {
      let userLevelId = sessionStorage.getItem('levelId')
      if ('Professional' !== userLevelId) {
        this.form.farmerId = null
        this.form.managerFullName = null
        this.form.managerPhoneNumber = null
      }

      this.form.districtId = null
      this.form.cityId = null
      this.bindDistrictSelectData(null)
      this.bindCitySelectData(selectedValue)
      this.bindFarmerSelectData(selectedValue)
    },
    handleCityChange(selectedValue) {
      let userLevelId = sessionStorage.getItem('levelId')
      if ('Professional' !== userLevelId) {
        this.form.farmerId = null
        this.form.managerFullName = null
        this.form.managerPhoneNumber = null
      }

      this.form.districtId = null
      this.bindDistrictSelectData(selectedValue)
      this.bindFarmerSelectData(selectedValue ?? this.form.provinceId)
    },
    handleDistrictChange(selectedValue) {
      let userLevelId = sessionStorage.getItem('levelId')
      if ('Professional' !== userLevelId) {
        this.form.farmerId = null
        this.form.managerFullName = null
        this.form.managerPhoneNumber = null
      }

      this.bindFarmerSelectData(selectedValue ?? this.form.cityId)
    },
    handleFarmerSelect(selectedValue) {
      if (null === selectedValue) {
        this.form.managerFullName = null
        this.form.managerPhoneNumber = null
        this.form.detailedAddress = null
        return
      }
      let farmerModel = this.models.farmerArray[selectedValue]
      this.form.managerFullName = farmerModel.fullName
      this.form.managerPhoneNumber = farmerModel.phoneNumber
      this.form.detailedAddress = farmerModel.detailedAddress
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return

        let createModel = {
          farmerId: this.form.farmerId,
          regionId: null,
          detailedAddress: this.form.detailedAddress,
          name: this.form.name,
          buildTime: this.form.buildTime + ' 00:00:00',
          longitude: this.$utils.isEmpty(this.form.longitude) ? null : this.form.longitude,
          latitude: this.$utils.isEmpty(this.form.latitude) ? null : this.form.latitude,
          plotArea: this.form.plotArea,
          managerFullName: this.form.managerFullName,
          managerPhoneNumber: this.form.managerPhoneNumber
        }

        let userLevelId = sessionStorage.getItem('levelId')
        if ('Professional' === userLevelId) {
          createModel.regionId = this.form.districtId
        }

        MonitoringPlotApi.create(createModel)
          .then(() => {
            this.$message({ type: 'success', message: '保存成功！' })
            this.$emit('create-success')
            this.dialogVisible = false
          })
          .catch(function (err) {
            console.log(err)
            this.$message({ type: 'error', message: '【' + err.data.code + '】' + err.data.message })
          })
      })
    },
    bindProvinceSelectData() {
      this.form.filterData.provinceOptionList = []
      this.form.filterData.provinceOptionList.push({ text: '请选择', value: null })
      RegionApi.getProvinceList().then(res => {
        res.data.forEach(el => {
          this.form.filterData.provinceOptionList.push({ text: el.name, value: el.id })
        })
      })
    },
    bindCitySelectData(provinceId) {
      this.form.filterData.cityOptionList = []
      this.form.filterData.cityOptionList.push({ text: '请选择', value: null })
      if (null === provinceId) return

      RegionApi.getCityList(provinceId)
        .then(res => {
          res.data.forEach(el => {
            this.form.filterData.cityOptionList.push({ text: el.name, value: el.id })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    bindDistrictSelectData(cityId) {
      this.form.filterData.districtOptionList = []
      this.form.filterData.districtOptionList.push({ text: '请选择', value: null })
      if (null === cityId) return

      RegionApi.getDistrictList(cityId)
        .then(res => {
          res.data.forEach(el => {
            this.form.filterData.districtOptionList.push({ text: el.name, value: el.id })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    bindFarmerSelectData(regionId) {
      this.form.filterData.farmerOptionList = []
      this.form.filterData.farmerOptionList.push({ text: '请选择', value: null })
      this.models.farmerArray = []
      FarmerApi.getList(regionId).then(res => {
        res.data.forEach(el => {
          this.models.farmerArray[el.id] = el
          this.form.filterData.farmerOptionList.push({ text: el.fullName + '（' + el.phoneNumber + '）', value: el.id })
        })
      })
    },
    open() {
      this.dialogVisible = true
    }
  }
}
</script>
