import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/survey-related-pictures', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/survey-related-pictures/' + id)
  },
  getList(plantCropId) {
    let config = { params: { plantCropId: plantCropId } }
    return httpRequest.get('/survey-related-pictures', config)
  }
}
