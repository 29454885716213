import httpRequest from '@/utils/http-request'

export default {
  getFarmerNatureNumbers(params) {
    return httpRequest.post('/dv/farmer/nature-numbers', params)
  },
  getFarmerNatureProportions(params) {
    return httpRequest.post('/dv/farmer/nature-proportions', params)
  },
  getFarmerDegreeNumbers(params) {
    return httpRequest.post('/dv/farmer/degree-numbers', params)
  },
  getFarmerDegreeProportions(params) {
    return httpRequest.post('/dv/farmer/degree-proportions', params)
  },
  getFertilityNumbers(params) {
    return httpRequest.post('/dv/planting/soil-fertility-numbers', params)
  },
  getFertilityProportions(params) {
    return httpRequest.post('/dv/planting/soil-fertility-proportions', params)
  },
  getBaseFertilizationModeNumbers(params) {
    return httpRequest.post('/dv/planting/base-fertilization-mode-numbers', params)
  },
  getBaseFertilizationModeProportions(params) {
    return httpRequest.post('/dv/planting/base-fertilization-mode-proportions', params)
  },
  getAfterFertilizationModeNumbers(params) {
    return httpRequest.post('/dv/planting/after-fertilization-mode-numbers', params)
  },
  getAfterFertilizationModeProportions(params) {
    return httpRequest.post('/dv/planting/after-fertilization-mode-proportions', params)
  },
  getOrganicFertilizerDosages(params) {
    return httpRequest.post('/dv/fertilization/organic-fertilizer-dosages/all', params)
  },
  getOrganicFertilizerFarmers(params) {
    return httpRequest.post('/dv/fertilization/organic-fertilizer-farmers/all', params)
  },
  getChemicalFertilizerDosages(params) {
    return httpRequest.post('/dv/fertilization/chemical-fertilizer-dosages/all', params)
  },
  getChemicalFertilizerFarmers(params) {
    return httpRequest.post('/dv/fertilization/chemical-fertilizer-farmers/all', params)
  },
  getVarietyOptions(params) {
    return httpRequest.post('/dv/fertilization/fertilizer-variety-proportions/all', params)
  },
  getAreaAndCoverageProportions(params) {
    return httpRequest.post('/dv/fertilization/area-and-coverage-proportions', params)
  },
  getFertilizerApplicationIntensity(params) {
    return httpRequest.post('/dv/nutrient/fertilizer-application-intensity', params)
  },
  getPartialFactorProductivity(params) {
    return httpRequest.post('/dv/nutrient/partial-factor-productivity', params)
  },
  getCropYield(params) {
    return httpRequest.post('/dv/nutrient/crop-yield', params)
  },
  getBaseFertilizerIntensityNumbers(params) {
    return httpRequest.post('/dv/nutrient/base-fertilizer-intensity-numbers', params)
  },
  getBaseFertilizerIntensityProportions(params) {
    return httpRequest.post('/dv/nutrient/base-fertilizer-intensity-proportions', params)
  },
  getNutrientStructure(params) {
    return httpRequest.post('/dv/nutrient/nutrient-structure-numbers', params)
  },

  exportExcel(params) {
    return httpRequest.post('/dv/export', params)
  }
}
