import httpRequest from '@/utils/http-request'

export default {
  getFillInStatisticData(year, districtId) {
    let config = {
      params: {
        year: year,
        districtId: districtId
      }
    }
    return httpRequest.get('/district-fill-in-statistic-data', config)
  },
  exportData(year, districtId) {
    let config = {
      params: {
        year: year,
        districtId: districtId
      }
    }
    return httpRequest.post('/district-fill-in-statistic-data-export', null, config)
  }
}