<template>
  <div>
    <el-dialog :title="windowTitle" :visible.sync="windowVisible" width="440px" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
      <el-form ref="form" :rules="formRules" :model="form" label-position="left" label-width="100px">
        <el-form-item label="序号" prop="sortNumber">
          <el-input v-model="form.sortNumber" type="number"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="自定义名录" prop="indexDirectoryFlag">
          <el-select v-model="form.indexDirectoryFlag">
            <el-option v-for="item in indexDirectoryFlagOptions" :key="item.value" :value="item.value" :label="item.text"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" class="wg-button blank" @click="windowVisible = false">取 消</el-button>
        <el-button size="small" class="wg-button" @click="handleSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ProfessionalCategoryApi from '@/api/professional-category-api'

export default {
  name: 'UserLevelModify',
  data() {
    return {
      windowTitle: '修改专业用户类别',
      windowVisible: false,
      params: { id: null },
      indexDirectoryFlagOptions: [
        { value: true, text: '开启' },
        { value: false, text: '关闭' }
      ],
      form: {
        sortNumber: null,
        name: null,
        indexDirectoryFlag: false
      },
      formRules: {
        sortNumber: [{ required: true, trigger: 'blur', message: '请输入序号！' }],
        name: [{ required: true, trigger: 'blur', message: '请输入名称！' }]
      }
    }
  },
  methods: {
    handleSave() {
      let updateModel = {
        id: this.params.id,
        sortNumber: this.form.sortNumber,
        name: this.form.name,
        indexDirectoryFlag: this.form.indexDirectoryFlag
      }

      ProfessionalCategoryApi.update(updateModel)
        .then(() => {
          this.$message({ showClose: true, message: '保存成功！', type: 'success' })
          this.windowVisible = false
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    handleClose() {
      this.$emit('reload')
    },
    async open(id) {
      this.params.id = id
      await ProfessionalCategoryApi.getById(id)
        .then(res => {
          this.form.sortNumber = res.data.sortNumber
          this.form.name = res.data.name
          this.form.indexDirectoryFlag = res.data.indexDirectoryFlag
          this.windowVisible = true
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped></style>