<template>
  <div>
    <el-dialog :title="windowTitle" :visible.sync="windowVisible" width="96%" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
      <el-form :model="form" :rules="formRules" ref="form" label-position="left" size="small">
        <div class="wg-form-box">
          <!-- 总统情况 -->
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="省份" prop="provinceId" label-width="140px" v-if="flags.enableProvinceFormItem">
                <el-select v-model="form.provinceId" clearable style="width: 100%" :disabled="flags.disableProvinceSelect" @change="handleSelectProvince">
                  <el-option v-for="item in formFilterData.provinceOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="地市" prop="cityId" label-width="140px" v-if="flags.enableCityFormItem">
                <el-select v-model="form.cityId" clearable style="width: 100%" :disabled="flags.disableCitySelect" @change="handleSelectCity">
                  <el-option v-for="item in formFilterData.cityOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="区县" prop="districtId" label-width="140px" v-if="flags.enableDistrictFormItem">
                <el-select v-model="form.districtId" clearable style="width: 100%" :disabled="flags.disableDistrictSelect">
                  <el-option v-for="item in formFilterData.districtOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="填报日期" prop="fillDate" label-width="140px">
                <el-date-picker v-model="form.fillDate" style="width: 100%" type="date" align="right" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
              </el-form-item>
              <el-form-item label="种植面积（万亩）" prop="plantArea" label-width="140px">
                <el-input v-model="form.plantArea"></el-input>
              </el-form-item>
              <el-form-item label="总产量（万吨）" prop="totalYield" label-width="140px">
                <el-input v-model="form.totalYield"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="氮肥总量（万吨）" prop="nitrogenTotal" label-width="220px">
                <el-input v-model="form.nitrogenTotal"></el-input>
              </el-form-item>
              <el-form-item label="氮肥其中：尿素（万吨）" prop="urea" label-width="220px">
                <el-input v-model="form.urea"></el-input>
              </el-form-item>
              <el-form-item label="磷肥总量（万吨）" prop="phosphorusTotal" label-width="220px">
                <el-input v-model="form.phosphorusTotal"></el-input>
              </el-form-item>
              <el-form-item label="磷肥其中：磷酸二铵（万吨）" prop="diammoniumPhosphate" label-width="220px">
                <el-input v-model="form.diammoniumPhosphate"></el-input>
              </el-form-item>
              <el-form-item label="钾肥总量（万吨）" prop="potassiumTotal" label-width="220px">
                <el-input v-model="form.potassiumTotal"></el-input>
              </el-form-item>
              <el-form-item label="钾肥其中：氯化钾（万吨）" prop="potassiumChloride" label-width="220px">
                <el-input v-model="form.potassiumChloride"></el-input>
              </el-form-item>
              <el-form-item label="钾肥其中：硫酸钾（万吨）" prop="potassiumSulphate" label-width="220px">
                <el-input v-model="form.potassiumSulphate"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="复合肥料：低浓度（万吨，实物)" prop="compoundFertilizerLow" label-width="250px">
                <el-input v-model="form.compoundFertilizerLow"></el-input>
              </el-form-item>
              <el-form-item label="复合肥料：中浓度（万吨，实物)" prop="compoundFertilizerMiddle" label-width="250px">
                <el-input v-model="form.compoundFertilizerMiddle"></el-input>
              </el-form-item>
              <el-form-item label="复合肥料：高浓度（万吨，实物)" prop="compoundFertilizerHigh" label-width="250px">
                <el-input v-model="form.compoundFertilizerHigh"></el-input>
              </el-form-item>
              <el-form-item label="有机肥料总量（万吨，实物）" prop="organicFertilizerTotal" label-width="250px">
                <el-input v-model="form.organicFertilizerTotal"></el-input>
              </el-form-item>
              <el-form-item label="有机肥料：商用有机肥（万吨，实物）" prop="businessOrganicFertilizer" label-width="250px">
                <el-input v-model="form.businessOrganicFertilizer"></el-input>
              </el-form-item>
              <el-form-item label="有机肥料：其他（万吨，实物）" prop="organicFertilizerOther" label-width="250px">
                <el-input v-model="form.organicFertilizerOther"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 主要作物 1- 5 -->
          <el-table :data="form.stapleCropFertilizerDosageList" class="wg-table normal" border :max-height="350" style="width: 100%">
            <el-table-column label="序号" type="index" width="100" fixed></el-table-column>
            <el-table-column label="作物分类" prop="cropSubCategoryId">
              <template slot-scope="scope">
                <div>
                  <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.cropSubCategoryId'" :rules="formRules.stapleCropFertilizerDosageList.cropSubCategoryId">
                    <el-select size="mini" v-model="scope.row.cropSubCategoryId" @change="handleCropSubCategoryChange($event, scope.row)">
                      <el-option-group v-for="group in formFilterData.cropSubCategoryOptionGroupList" :key="group.label" :label="group.label">
                        <el-option v-for="option in group.optionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="作物名称" width="150" prop="cropName">
              <template slot-scope="scope">
                <div>
                  <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.cropName'" :rules="formRules.stapleCropFertilizerDosageList.cropName">
                    <el-autocomplete size="small" v-model="scope.row.cropName" :fetch-suggestions="(queryString, cb) => {queryCropName(queryString, cb, scope.row)}" @select="handleCropNameSelect($event, scope.row)" @input="handleCropNameInput($event, scope.row)">
                      <template slot-scope="{item }">
                        <div>{{ item.value }}</div>
                      </template>
                    </el-autocomplete>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="120" prop="plantArea">
              <template slot="header">种植面积<br />（万亩）</template>
              <template slot-scope="scope">
                <div>
                  <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.plantArea'" :rules="formRules.stapleCropFertilizerDosageList.plantArea">
                    <el-input size="mini" v-model="scope.row.plantArea"></el-input>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="120" prop="totalYield">
              <template slot="header">总产量<br />（万吨）</template>
              <template slot-scope="scope">
                <div>
                  <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.totalYield'" :rules="formRules.stapleCropFertilizerDosageList.totalYield">
                    <el-input size="mini" v-model="scope.row.totalYield"></el-input>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="氮肥（万吨）">
              <el-table-column width="120" prop="nitrogenTotal">
                <template slot="header">总量<br />（N）</template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.nitrogenTotal'" :rules="formRules.stapleCropFertilizerDosageList.nitrogenTotal">
                      <el-input size="mini" v-model="scope.row.nitrogenTotal"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="urea">
                <template slot="header">其中：<br />尿素</template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.urea'" :rules="formRules.stapleCropFertilizerDosageList.urea">
                      <el-input size="mini" v-model="scope.row.urea"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="磷肥（万吨）">
              <el-table-column width="120" prop="phosphorusTotal">
                <template slot="header">总量<br />（P2O5）</template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.phosphorusTotal'" :rules="formRules.stapleCropFertilizerDosageList.phosphorusTotal">
                      <el-input size="mini" v-model="scope.row.phosphorusTotal"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="diammoniumPhosphate">
                <template slot="header">其中：<br />磷酸二铵</template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.diammoniumPhosphate'" :rules="formRules.stapleCropFertilizerDosageList.diammoniumPhosphate">
                      <el-input size="mini" v-model="scope.row.diammoniumPhosphate"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="钾肥（万吨）">
              <el-table-column width="120" prop="potassiumTotal">
                <template slot="header">总量<br />（K2O）</template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.potassiumTotal'" :rules="formRules.stapleCropFertilizerDosageList.potassiumTotal">
                      <el-input size="mini" v-model="scope.row.potassiumTotal"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="potassiumChloride">
                <template slot="header">其中：<br />氯化钾</template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.potassiumChloride'" :rules="formRules.stapleCropFertilizerDosageList.potassiumChloride">
                      <el-input size="mini" v-model="scope.row.potassiumChloride"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="potassiumSulphate">
                <template slot="header">其中：<br />硫酸钾</template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.potassiumSulphate'" :rules="formRules.stapleCropFertilizerDosageList.potassiumSulphate">
                      <el-input size="mini" v-model="scope.row.potassiumSulphate"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="复合肥料（万吨，实物)">
              <el-table-column label="低浓度" prop="compoundFertilizerLow" width="120">
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.compoundFertilizerLow'" :rules="formRules.stapleCropFertilizerDosageList.compoundFertilizerLow">
                      <el-input size="mini" v-model="scope.row.compoundFertilizerLow"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="中浓度" prop="compoundFertilizerMiddle" width="120">
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.compoundFertilizerMiddle'" :rules="formRules.stapleCropFertilizerDosageList.compoundFertilizerMiddle">
                      <el-input size="mini" v-model="scope.row.compoundFertilizerMiddle"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="高浓度" prop="compoundFertilizerHigh" width="120">
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.compoundFertilizerHigh'" :rules="formRules.stapleCropFertilizerDosageList.compoundFertilizerHigh">
                      <el-input size="mini" v-model="scope.row.compoundFertilizerHigh"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="有机肥料（万吨，实物）">
              <el-table-column label="总量" width="120" prop="organicFertilizerTotal">
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.organicFertilizerTotal'" :rules="formRules.stapleCropFertilizerDosageList.organicFertilizerTotal">
                      <el-input size="mini" v-model="scope.row.organicFertilizerTotal"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="120" prop="businessOrganicFertilizer">
                <template slot="header">商品<br />有机肥</template>
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.businessOrganicFertilizer'" :rules="formRules.stapleCropFertilizerDosageList.businessOrganicFertilizer">
                      <el-input size="mini" v-model="scope.row.businessOrganicFertilizer"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="其他" width="120" prop="organicFertilizerOther">
                <template slot-scope="scope">
                  <div>
                    <el-form-item :prop="'stapleCropFertilizerDosageList.' + scope.$index + '.organicFertilizerOther'">
                      <el-input size="mini" v-model="scope.row.organicFertilizerOther"></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="80">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="handleRemove(scope.row)">移除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <div slot="footer">
        <el-button size="small" class="wg-button blank" @click="windowVisible = false">取 消</el-button>
        <el-button size="small" class="wg-button" @click="handleSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FertilizerDosageApi from '@/api/survey/fertilizer-dosage-api'

export default {
  name: 'SurfaceSurveyAdd',
  props: {
    provinceList: [],
    cityList: [],
    districtList: [],
    cropRootCategoryArray: [],
    cropSubCategoryArray: [],
    cropArray: []
  },
  data() {
    let validateNumber = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^([0-9]\d*(\.\d*)?)$|^0\.\d*[1-9]\d*$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入数字！'))
        }
      } else {
        callback()
      }
    }

    const validateCropSubCategoryId = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let row = this.form.stapleCropFertilizerDosageList[rowIndex]
      if (this.$utils.isEmpty(row.cropSubCategoryId)
        && (
          this.$utils.isNotEmpty(row.cropName)
          || this.$utils.isNotEmpty(row.plantArea)
          || this.$utils.isNotEmpty(row.totalYield)
          || this.$utils.isNotEmpty(row.nitrogenTotal)
          || this.$utils.isNotEmpty(row.urea)
          || this.$utils.isNotEmpty(row.phosphorusTotal)
          || this.$utils.isNotEmpty(row.diammoniumPhosphate)
          || this.$utils.isNotEmpty(row.potassiumTotal)
          || this.$utils.isNotEmpty(row.potassiumChloride)
          || this.$utils.isNotEmpty(row.potassiumSulphate)
          || this.$utils.isNotEmpty(row.compoundFertilizerLow)
          || this.$utils.isNotEmpty(row.compoundFertilizerMiddle)
          || this.$utils.isNotEmpty(row.compoundFertilizerHigh)
          || this.$utils.isNotEmpty(row.organicFertilizerTotal)
          || this.$utils.isNotEmpty(row.businessOrganicFertilizer)
          || this.$utils.isNotEmpty(row.organicFertilizerOther)
        )
      ) {
        callback(new Error())
      } else {
        callback()
      }
    }

    const validateCropName = (rule, value, callback) => {
      let fieldSplitArray = rule.field.split('.')
      let rowIndex = fieldSplitArray[1]
      let row = this.form.stapleCropFertilizerDosageList[rowIndex]
      if (this.$utils.isEmpty(row.cropName)
        && (
          this.$utils.isNotEmpty(row.cropSubCategoryId)
          || this.$utils.isNotEmpty(row.plantArea)
          || this.$utils.isNotEmpty(row.totalYield)
          || this.$utils.isNotEmpty(row.nitrogenTotal)
          || this.$utils.isNotEmpty(row.urea)
          || this.$utils.isNotEmpty(row.phosphorusTotal)
          || this.$utils.isNotEmpty(row.diammoniumPhosphate)
          || this.$utils.isNotEmpty(row.potassiumTotal)
          || this.$utils.isNotEmpty(row.potassiumChloride)
          || this.$utils.isNotEmpty(row.potassiumSulphate)
          || this.$utils.isNotEmpty(row.compoundFertilizerLow)
          || this.$utils.isNotEmpty(row.compoundFertilizerMiddle)
          || this.$utils.isNotEmpty(row.compoundFertilizerHigh)
          || this.$utils.isNotEmpty(row.organicFertilizerTotal)
          || this.$utils.isNotEmpty(row.businessOrganicFertilizer)
          || this.$utils.isNotEmpty(row.organicFertilizerOther)
        )
      ) {
        callback(new Error())
      } else {
        callback()
      }
    }

    return {
      windowTitle: '新增记录',
      windowVisible: false,
      flags: {
        enableProvinceFormItem: false,
        enableCityFormItem: false,
        enableDistrictFormItem: false,
        disableProvinceSelect: false,
        disableCitySelect: false,
        disableDistrictSelect: false
      },
      formFilterData: {
        provinceOptionList: [],
        cityOptionList: [],
        districtOptionList: [],
        cropSubCategoryOptionGroupList: []
      },
      form: {
        id: null,
        year: null,
        provinceId: null,
        cityId: null,
        districtId: null,
        fillDate: null,
        plantArea: null,
        totalYield: null,
        nitrogenTotal: null,
        urea: null,
        phosphorusTotal: null,
        diammoniumPhosphate: null,
        potassiumTotal: null,
        potassiumChloride: null,
        potassiumSulphate: null,
        compoundFertilizerLow: null,
        compoundFertilizerMiddle: null,
        compoundFertilizerHigh: null,
        organicFertilizerTotal: null,
        businessOrganicFertilizer: null,
        organicFertilizerOther: null,
        stapleCropFertilizerDosageList: []
      },
      formRules: {
        provinceId: [{ required: true, trigger: 'change', message: '请选择省份！' }],
        cityId: [{ required: true, trigger: 'change', message: '请选择地市！' }],
        districtId: [{ required: true, trigger: 'change', message: '请选择区县！' }],
        fillDate: [{ required: true, trigger: 'change', message: '请选择填报日期！' }],
        plantArea: [{ trigger: 'blur', validator: validateNumber }],
        totalYield: [{ trigger: 'blur', validator: validateNumber }],
        nitrogenTotal: [{ trigger: 'blur', validator: validateNumber }],
        urea: [{ trigger: 'blur', validator: validateNumber }],
        phosphorusTotal: [{ trigger: 'blur', validator: validateNumber }],
        diammoniumPhosphate: [{ trigger: 'blur', validator: validateNumber }],
        potassiumTotal: [{ trigger: 'blur', validator: validateNumber }],
        potassiumChloride: [{ trigger: 'blur', validator: validateNumber }],
        potassiumSulphate: [{ trigger: 'blur', validator: validateNumber }],
        compoundFertilizerLow: [{ trigger: 'blur', validator: validateNumber }],
        compoundFertilizerMiddle: [{ trigger: 'blur', validator: validateNumber }],
        compoundFertilizerHigh: [{ trigger: 'blur', validator: validateNumber }],
        organicFertilizerTotal: [{ trigger: 'blur', validator: validateNumber }],
        businessOrganicFertilizer: [{ trigger: 'blur', validator: validateNumber }],
        organicFertilizerOther: [{ trigger: 'blur', validator: validateNumber }],
        stapleCropFertilizerDosageList: {
          cropSubCategoryId: [{ trigger: 'change', validator: validateCropSubCategoryId }],
          cropName: [{ trigger: 'change', validator: validateCropName }],
          plantArea: [{ trigger: 'blur', validator: validateNumber }],
          totalYield: [{ trigger: 'blur', validator: validateNumber }],
          nitrogenTotal: [{ trigger: 'blur', validator: validateNumber }],
          urea: [{ trigger: 'blur', validator: validateNumber }],
          phosphorusTotal: [{ trigger: 'blur', validator: validateNumber }],
          diammoniumPhosphate: [{ trigger: 'blur', validator: validateNumber }],
          potassiumTotal: [{ trigger: 'blur', validator: validateNumber }],
          potassiumChloride: [{ trigger: 'blur', validator: validateNumber }],
          potassiumSulphate: [{ trigger: 'blur', validator: validateNumber }],
          compoundFertilizerLow: [{ trigger: 'blur', validator: validateNumber }],
          compoundFertilizerMiddle: [{ trigger: 'blur', validator: validateNumber }],
          compoundFertilizerHigh: [{ trigger: 'blur', validator: validateNumber }],
          organicFertilizerTotal: [{ trigger: 'blur', validator: validateNumber }],
          businessOrganicFertilizer: [{ trigger: 'blur', validator: validateNumber }],
          organicFertilizerOther: [{ trigger: 'blur', validator: validateNumber }]
        }
      }
    }
  },
  created() {
    this.cropRootCategoryArray.forEach(el => {
      let cropSubCategoryOptionGroup = { label: el.name, optionList: [] }

      let tempCropSubCategoryArray = this.cropSubCategoryArray.filter(el1 => {
        return el1.parentId === el.id
      })

      tempCropSubCategoryArray.forEach(el1 => {
        cropSubCategoryOptionGroup.optionList.push({ label: el1.name, value: el1.id })
      })
      this.formFilterData.cropSubCategoryOptionGroupList.push(cropSubCategoryOptionGroup)
    })

    for (let i = 0; i < 5; i++) {
      this.form.stapleCropFertilizerDosageList.push({
        id: null,
        cropRootCategoryId: null,
        cropRootCategoryName: null,
        cropSubCategoryId: null,
        cropSubCategoryName: null,
        cropId: null,
        cropName: null,
        fertilizerDosageId: null,
        plantArea: null,
        totalYield: null,
        nitrogenTotal: null,
        urea: null,
        phosphorusTotal: null,
        diammoniumPhosphate: null,
        potassiumTotal: null,
        potassiumChloride: null,
        potassiumSulphate: null,
        compoundFertilizerLow: null,
        compoundFertilizerMiddle: null,
        compoundFertilizerHigh: null,
        organicFertilizerTotal: null,
        businessOrganicFertilizer: null,
        organicFertilizerOther: null
      })
    }
  },
  methods: {
    handleSelectProvince(val) {
      this.bindCitySelectData(val)
    },
    handleSelectCity(val) {
      this.bindDistrictSelectData(val)
    },
    handleCropSubCategoryChange(value, row) {
      row.cropId = null
      row.cropName = null

      let cropSubCategory = this.cropSubCategoryArray.find(m => m.id === value)
      if (null != cropSubCategory) {
        row.cropSubCategoryName = cropSubCategory.name

        let cropRootCategory = this.cropRootCategoryArray.find(m => m.id === cropSubCategory.parentId)
        if (null !== cropRootCategory) {
          row.cropRootCategoryId = cropRootCategory.id
          row.cropRootCategoryName = cropRootCategory.name
        }
      }
    },
    queryCropName(queryStr, cb, row) {
      let cropOptionList = []
      if (this.$utils.isNotEmpty(row.cropSubCategoryId)) {
        let tempCropArray = this.cropArray.filter(m => {
          return m.subCategoryId === row.cropSubCategoryId
        })

        tempCropArray.forEach(el => {
          cropOptionList.push({ value: el.name, id: el.id })
        })
      }
      cb(cropOptionList)
    },
    handleCropNameSelect(item, row) {
      row.cropId = item.id
      row.cropName = item.value
    },
    handleCropNameInput(value, row) {
      let crop = this.cropArray.find(m => m.name === value)
      if (undefined === crop)
        row.cropId = null
      else
        row.cropId = crop.id
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return

        console.log(this.form)

        let model = {
          id: this.form.id,
          year: this.form.year,
          regionId: null,
          fillDate: null === this.form.fillDate ? null : this.form.fillDate + ' 00:00:00',
          plantArea: this.form.plantArea,
          totalYield: this.form.totalYield,
          nitrogenTotal: this.form.nitrogenTotal,
          urea: this.form.urea,
          phosphorusTotal: this.form.phosphorusTotal,
          diammoniumPhosphate: this.form.diammoniumPhosphate,
          potassiumTotal: this.form.potassiumTotal,
          potassiumChloride: this.form.potassiumChloride,
          potassiumSulphate: this.form.potassiumSulphate,
          compoundFertilizerLow: this.form.compoundFertilizerLow,
          compoundFertilizerMiddle: this.form.compoundFertilizerMiddle,
          compoundFertilizerHigh: this.form.compoundFertilizerHigh,
          organicFertilizerTotal: this.form.organicFertilizerTotal,
          businessOrganicFertilizer: this.form.businessOrganicFertilizer,
          organicFertilizerOther: this.form.organicFertilizerOther,
          stapleCropFertilizerDosageEditList: []
        }

        if (this.flags.enableDistrictFormItem) {
          model.regionId = this.form.districtId
        } else if (this.flags.enableCityFormItem) {
          model.regionId = this.form.cityId
        } else if (this.flags.enableProvinceFormItem) {
          model.regionId = this.form.provinceId
        }

        this.form.stapleCropFertilizerDosageList.forEach(el => {
          model.stapleCropFertilizerDosageEditList.push({
            id: el.id,
            fertilizerDosageId: el.fertilizerDosageId,
            cropRootCategoryId: el.cropRootCategoryId,
            cropRootCategoryName: el.cropRootCategoryName,
            cropSubCategoryId: el.cropSubCategoryId,
            cropSubCategoryName: el.cropSubCategoryName,
            cropId: el.cropId,
            cropName: el.cropName,
            plantArea: el.plantArea,
            totalYield: el.totalYield,
            nitrogenTotal: el.nitrogenTotal,
            urea: el.urea,
            phosphorusTotal: el.phosphorusTotal,
            diammoniumPhosphate: el.diammoniumPhosphate,
            potassiumTotal: el.potassiumTotal,
            potassiumChloride: el.potassiumChloride,
            potassiumSulphate: el.potassiumSulphate,
            compoundFertilizerLow: el.compoundFertilizerLow,
            compoundFertilizerMiddle: el.compoundFertilizerMiddle,
            compoundFertilizerHigh: el.compoundFertilizerHigh,
            organicFertilizerTotal: el.organicFertilizerTotal,
            businessOrganicFertilizer: el.businessOrganicFertilizer,
            organicFertilizerOther: el.organicFertilizerOther,
            removeFlag: el.removeFlag
          })
        })
        console.log(model)

        FertilizerDosageApi.create(model)
          .then(() => {
            this.$emit('close', { saved: true })
            this.$message({ type: 'success', message: '保存成功！' })
            this.windowVisible = false
          })
          .catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          })
      })
    },
    handleClose() {
      this.$emit('close', { saved: false })
    },
    handleRemove(row) {
      row.cropRootCategoryId = null
      row.cropRootCategoryName = null
      row.cropSubCategoryId = null
      row.cropSubCategoryName = null
      row.cropId = null
      row.cropName = null
      row.plantArea = null
      row.totalYield = null
      row.nitrogenTotal = null
      row.urea = null
      row.phosphorusTotal = null
      row.diammoniumPhosphate = null
      row.potassiumTotal = null
      row.potassiumChloride = null
      row.potassiumSulphate = null
      row.compoundFertilizerLow = null
      row.compoundFertilizerMiddle = null
      row.compoundFertilizerHigh = null
      row.organicFertilizerTotal = null
      row.businessOrganicFertilizer = null
      row.organicFertilizerOther = null
    },
    async open(year, regionLevel, regionId) {
      this.form.year = year

      // country fill in
      if ('Province' === regionLevel) {
        this.flags.enableProvinceFormItem = true
        await this.bindProvinceSelectData()
        if (null !== regionId) {
          this.flags.disableProvinceSelect = true
          this.form.provinceId = regionId.substring(0, 2) + '0000'
        }

        this.windowVisible = true
      }

      // country or province fill in
      if ('City' === regionLevel) {
        this.flags.enableProvinceFormItem = true
        this.flags.enableCityFormItem = true
        await this.bindProvinceSelectData()
        if (null !== regionId) {
          const regProvinceId = /^[1-9][0-9]0000/
          const regCityId = /^[1-9][0-9]([0-9][1-9]|[1-9][0-9])00/
          const regDistrictId = /^[1-9][0-9]([0-9][1-9]|[1-9][0-9])([0-9][1-9]|[1-9][0-9])/

          if (regProvinceId.test(regionId)) {
            this.flags.disableProvinceSelect = true
            this.form.provinceId = regionId
            await this.bindCitySelectData(regionId)
          }

          if (regCityId.test(regionId) || regDistrictId.test(regionId)) {
            this.flags.disableProvinceSelect = true
            this.flags.disableCitySelect = true
            this.form.provinceId = regionId.substring(0, 2) + '0000'
            this.form.cityId = regionId.substring(0, 4) + '00'
            await this.bindCitySelectData(regionId.substring(0, 2) + '0000')
            await this.bindDistrictSelectData(regionId.substring(0, 4) + '00')
          }
        }
        this.windowVisible = true
      }

      // country or province or city fill in
      if ('District' === regionLevel) {
        this.flags.enableProvinceFormItem = true
        this.flags.enableCityFormItem = true
        this.flags.enableDistrictFormItem = true
        await this.bindProvinceSelectData()
        if (null !== regionId) {
          const regProvinceId = /^[1-9][0-9]0000/
          const regCityId = /^[1-9][0-9]([0-9][1-9]|[1-9][0-9])00/
          const regDistrictId = /^[1-9][0-9]([0-9][1-9]|[1-9][0-9])([0-9][1-9]|[1-9][0-9])/

          if (regProvinceId.test(regionId)) {
            this.flags.disableProvinceSelect = true
            this.form.provinceId = regionId
            await this.bindCitySelectData(regionId)
          }

          if (regCityId.test(regionId)) {
            this.flags.disableProvinceSelect = true
            this.flags.disableCitySelect = true
            this.form.provinceId = regionId.substring(0, 2) + '0000'
            this.form.cityId = regionId.substring(0, 4) + '00'
            await this.bindCitySelectData(regionId.substring(0, 2) + '0000')
            await this.bindDistrictSelectData(regionId.substring(0, 4) + '00')
          }

          if (regDistrictId.test(regionId)) {
            this.flags.disableProvinceSelect = true
            this.flags.disableCitySelect = true
            this.flags.disableDistrictSelect = true
            this.form.provinceId = regionId.substring(0, 2) + '0000'
            this.form.cityId = regionId.substring(0, 4) + '00'
            this.form.districtId = regionId
            await this.bindCitySelectData(regionId.substring(0, 2) + '0000')
            await this.bindDistrictSelectData(regionId.substring(0, 4) + '00')
          }
        }
        this.windowVisible = true
      }
    },
    async bindProvinceSelectData() {
      this.provinceList.forEach(el => {
        this.formFilterData.provinceOptionList.push({ label: el.name, value: el.id })
      })
    },
    async bindCitySelectData(provinceId) {
      if (!this.flags.enableCityFormItem) {
        return
      }

      this.formFilterData.cityOptionList = []
      this.cityList.forEach(el => {
        if (el.id.substring(0, 2) === provinceId.substring(0, 2)) {
          this.formFilterData.cityOptionList.push({ label: el.name, value: el.id })
        }
      })
    },
    async bindDistrictSelectData(cityId) {
      if (!this.flags.enableDistrictFormItem) {
        return
      }

      this.formFilterData.districtOptionList = []
      this.districtList.forEach(el => {
        if (el.id.substring(0, 4) === cityId.substring(0, 4)) {
          this.formFilterData.districtOptionList.push({ label: el.name, value: el.id })
        }
      })
    }
  }
}
</script>

<style scoped></style>
