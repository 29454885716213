import { render, staticRenderFns } from "./ArchivedPlantCrop.vue?vue&type=template&id=4362506b&scoped=true"
import script from "./ArchivedPlantCrop.vue?vue&type=script&lang=js"
export * from "./ArchivedPlantCrop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4362506b",
  null
  
)

export default component.exports