<template>
  <div>
    <monitoring-plot ref="monitoringPlot"></monitoring-plot>
    <div style="margin-top: 20px" v-for="(item, index) in collapseData" :key="item.plantCrop.id" :name="index">
      <el-collapse class="wg-collapse" v-model="collapseActiveNames">
        <el-collapse-item :name="item.plantCrop.id">
          <template slot="title">
            <div class="header background-style1">
              <div class="title">{{ item.plantCrop.cropName }}总体情况</div>
              <div class="state" style="color: #039f3a">已归档</div>
            </div>
            <div class="wg-clear-fix"></div>
          </template>
          <plant-crop ref="plantCrop" :fullPlantCropModel="item"></plant-crop>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import ArchivedPlantCropApi from '@/api/data-fill-in/archive/archived-plant-crop-api'

import MonitoringPlot from './ArchivedMonitoringPlot'
import PlantCrop from './ArchivedPlantCrop'

export default {
  name: 'ArchivedFillInCollapse',
  props: {
    year: Number,
    monitoringPlotId: String
  },
  components: {
    'monitoring-plot': MonitoringPlot,
    'plant-crop': PlantCrop
  },
  data() {
    return {
      isCollapse: false,
      collapseActiveNames: [],
      collapseData: []
    }
  },
  watch: {
    year() {
      this.bindCollapseData()
    },
    monitoringPlotId() {
      this.bindCollapseData()
    }
  },
  created() {
    this.$nextTick(() => {
      this.$refs.monitoringPlot.load(this.monitoringPlotId)
    })
    this.bindCollapseData()
  },
  methods: {
    bindCollapseData() {
      ArchivedPlantCropApi.getOwnList(this.year, this.monitoringPlotId)
        .then(res => {
          this.collapseData = []
          res.data.forEach(el => {
            this.collapseData.push(el)
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped></style>
