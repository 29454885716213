<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/" class="bread_crumb">
        <el-breadcrumb-item>数据填报</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/assist-fill-in' }">辅助填报</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <el-container>
      <el-aside class="wg-side" :width="isCollapse ? '24px' : '240px'">
        <div v-show="!isCollapse">
          <div class="side-fence">
            辅助填报
            <i class="el-icon-d-arrow-left collapse-button" @click="handleToggleCollapse"></i>
          </div>
          <region-tree @node-click="handleRegionTreeNodeClick"></region-tree>
        </div>
        <div v-show="isCollapse" class="collapse-fence">
          展开菜单栏
          <div class="collapse-icon">
            <i class="el-icon-d-arrow-right" @click="handleToggleCollapse"></i>
          </div>
        </div>
      </el-aside>
      <el-main class="wg-main">
        <unarchived-assist-fill-in-tab ref="unarchivedAssistFillInTab" :year="params.year" :regionId="params.regionId" v-if="flags.isLoadUnarchivedAssistFillInTabComponent"></unarchived-assist-fill-in-tab>
        <archived-assist-fill-in-tab ref="archivedAssistFillInTab" :year="params.year" :regionId="params.regionId" v-if="flags.isLoadArchivedAssistFillInTabComponent"></archived-assist-fill-in-tab>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import FillInCycleApi from '@/api/data-fill-in/cycle/fill-in-cycle-api'

import RegionTree from './components/RegionTree'

import UnarchivedAssistFillInTab from './components/UnarchivedAssistFillInTab'
import ArchivedAssistFillInTab from './components/archive/ArchivedAssistFillInTab'

export default {
  name: 'AssistFillIn',
  components: {
    'region-tree': RegionTree,
    'unarchived-assist-fill-in-tab': UnarchivedAssistFillInTab,
    'archived-assist-fill-in-tab': ArchivedAssistFillInTab
  },
  data() {
    return {
      isCollapse: false,
      params: {
        year: null,
        regionId: null,
        archiveFlag: null
      },
      flags: {
        isLoadUnarchivedAssistFillInTabComponent: false,
        isLoadArchivedAssistFillInTabComponent: false
      }
    }
  },
  created() {
    FillInCycleApi.getLast().then(res => {
      let userLevelId = sessionStorage.getItem('levelId')
      switch (userLevelId) {
        case 'Province':
          this.params.regionId = sessionStorage.getItem('provinceId')
          break
        case 'City':
          this.params.regionId = sessionStorage.getItem('cityId')
          break
        case 'District':
          this.params.regionId = sessionStorage.getItem('districtId')
          break
      }
      this.params.year = res.data.year
      this.params.archiveFlag = res.data.archiveFlag

      this.flags.isLoadUnarchivedAssistFillInTabComponent = !this.params.archiveFlag
      this.flags.isLoadArchivedAssistFillInTabComponent = this.params.archiveFlag
    })
  },
  methods: {
    handleToggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    handleRegionTreeNodeClick(checkedItem) {
      if (checkedItem.year === this.params.year && checkedItem.regionId === this.params.regionId && checkedItem.archiveFlag === this.params.archiveFlag) {
        if (this.params.archiveFlag)
          this.$refs.archivedAssistFillInTab.reloadComponent()
        else
          this.$refs.unarchivedAssistFillInTab.reloadComponent()

        return
      }

      this.params.year = checkedItem.year
      this.params.regionId = checkedItem.regionId
      this.params.archiveFlag = checkedItem.archiveFlag

      if (this.params.archiveFlag) {
        if (this.flags.isLoadUnarchivedAssistFillInTabComponent) {
          this.flags.isLoadUnarchivedAssistFillInTabComponent = false
          this.flags.isLoadArchivedAssistFillInTabComponent = true
        }
      } else {
        if (this.flags.isLoadArchivedAssistFillInTabComponent) {
          this.flags.isLoadUnarchivedAssistFillInTabComponent = true
          this.flags.isLoadArchivedAssistFillInTabComponent = false
        }
      }
    }
  }
}
</script>

<style scoped></style>
