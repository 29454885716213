<template>
  <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" :lock-scroll="false" @close="handleClose">
    <div>
      <el-card class="box-card">
        <el-upload name="file" ref="fileUpload" :action="actionUrl" :data="uploadParams" :auto-upload="false" :before-upload="handleUploadBeforeUpload" :on-success="handleUploadSuccess">
          <el-button size="small" class="wg-button">点击上传</el-button>
          <div slot="tip" class="el-upload__tip" style="margin-top: 10px">提示：上传文件格式限定（xlsx）</div>
        </el-upload>
      </el-card>
    </div>
    <div slot="footer">
      <el-button size="small" class="wg-button blank" @click="dialogVisible = false">取 消</el-button>
      <el-button size="small" class="wg-button" @click="handleOk">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FileApi from '@/api/file-api'

export default {
  name: 'UploadAuditPlantCropImportFileDialog',
  props: [],
  data() {
    return {
      dialogTitle: '文件上传',
      dialogVisible: false,
      actionUrl: null,
      uploadParams: { category: 'UPLOAD_TEMP' }
    }
  },
  created() {
    this.actionUrl = FileApi.getUploadURI()
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    handleOk() {
      this.$refs.fileUpload.submit()
    },
    handleUploadBeforeUpload(file) {
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.$message({ type: 'error', message: '上传文件格式错误！' })
        return false
      }
    },
    handleUploadSuccess(res) {
      this.$emit('uploadSuccess', res.savedFileName)
      this.dialogVisible = false
    },
    async open() {
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped>
</style>