import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/monitoring-plots', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/monitoring-plots/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/monitoring-plots', updateModel)
  },
  enable(id) {
    let paramModel = { id: id }
    return httpRequest.put('/monitoring-plot-enable', paramModel)
  },
  disable(id) {
    let paramModel = { id: id }
    return httpRequest.put('/monitoring-plot-disable', paramModel)
  },
  getById(id) {
    return httpRequest.get('/monitoring-plots/' + id)
  },
  getOwnList(disableFlag) {
    let config = {}
    if (undefined !== disableFlag && null !== disableFlag) {
      config.params = { disableFlag: disableFlag }
    }
    return httpRequest.get('/own-monitoring-plots', config)
  },
  search(searchParamModel) {
    return httpRequest.post('/monitoring-plot-search', searchParamModel)
  },
  searchPaging(searchParamModel, pageSize, pageIndex) {
    let config = {
      params: {
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }
    return httpRequest.post('/monitoring-plot-paging-search', searchParamModel, config)
  },
  getStubList(farmerId, disableFlag) {
    let config = { params: { farmerId: farmerId } }
    if (undefined !== disableFlag && null !== disableFlag) {
      config.params.disableFlag = disableFlag
    }
    return httpRequest.get('/monitoring-plot-stubs', config)
  }
}
