<template>
  <el-card>
    <div slot="header" class="chart-title">
      <span>肥料偏生产力</span>
      <el-button style="float: right; padding: 3px 30px 3px 0" type="text" @click="changeChartType">{{chartType == 'bar' ? '折线图' : '柱状图'}}</el-button>
    </div>
    <div>
      <el-row :gutter="20" type="flex">
        <el-col :span="12" style="padding-left: 40px;">
          <v-chart
              :option="options"
              :init-options="initOptions"
              ref="chart"
              :loading="barLoading"
              :loadingOptions="barLoadingOptions"
              :updateOptions="{notMerge: true}"
              autoresize
          />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import VChart from 'vue-echarts'
import {CanvasRenderer, SVGRenderer} from 'echarts/renderers'

import {
  use
} from 'echarts/core'

import {
  BarChart
} from 'echarts/charts'

import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components'
import DataVisualizationApi from '@/api/chart-analysis/data-visualization-api'

use([
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
]);

export default {
  name: "partial-factor-productivity",
  components: {
    VChart
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas"
      },
      options: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      chartType: "bar",
      seconds: -1,
      barLoading: false,
      barLoadingOptions: {
        text: "加载中…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0.4)"
      },
    }
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    changeChartType() {
      this.chartType = this.chartType === "bar" ? "line" : "bar"
      if (this.options && this.options.series) {
        this.options.series[0].type = this.chartType
      }
    },
    getChartData() {
      this.options = {}
      this.barLoading = true
      DataVisualizationApi.getPartialFactorProductivity(this.params).then(res => {
        if(res.data.length === 0) {
          this.options = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }
        this.options = {
          textStyle: {
            fontSize: 14
          },
          title: {
            text: '(单位:公斤/公斤)',
            top: 20,
            left: 'left',
            textStyle: {
              fontSize: 14,
              color: '#000000',
              fontWeight: 500
            }
          },
          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              fontSize: 14
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed'
              }
            },
            data: res.data.map(item => item.name)
          },
          yAxis: {
            type: 'value',
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            }
          },
          series: [
            {
              data: res.data.map(item => item.value),
              type: this.chartType,
              barWidth: '25%',
              showBackground: true,
              backgroundStyle: {
                color: '#ECF2FE',
              },
              label: {
                show: true,
                position: 'top',
              },
              itemStyle: {
                color: '#FFC44A'
              }
            }
          ]
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.barLoading = false
      })
    }
  }
}
</script>

<style scoped>

.el-divider--horizontal {
  margin: 2px 0;
}

.el-divider--vertical {
  display: inline-block;
  weight: 1px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;
}
</style>
