import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/fertilizer-dosages', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/fertilizer-dosages/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/fertilizer-dosages', updateModel)
  },
  submit(id) {
    return httpRequest.put('/fertilizer-dosage-submit', { id: id })
  },
  getAggregate(year, regionId) {
    let config = {
      params: {
        year: year,
        regionId: regionId
      }
    }
    return httpRequest.get('/fertilizer-dosage-aggregates', config)
  },
  getProvincePaging(year, regionId, pageSize, pageIndex) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }
    return httpRequest.get('/province-fertilizer-dosage-paging', config)
  },
  getCityPaging(year, regionId, pageSize, pageIndex) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }
    return httpRequest.get('/city-fertilizer-dosage-paging', config)
  },
  getDistrictPaging(year, regionId, pageSize, pageIndex) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }
    return httpRequest.get('/district-fertilizer-dosage-paging', config)
  },
  exportProvinceData(exportId, year, regionId) {
    let config = {
      params: {
        exportId: exportId,
        year: year,
        regionId: regionId
      }
    }
    return httpRequest.post('/survey-province-data-export', null, config)
  },
  exportCityData(exportId, year, regionId) {
    let config = {
      params: {
        exportId: exportId,
        year: year,
        regionId: regionId
      }
    }
    return httpRequest.post('/survey-city-data-export', null, config)
  },
  exportDistrictData(exportId, year, regionId) {
    let config = {
      params: {
        exportId: exportId,
        year: year,
        regionId: regionId
      }
    }
    return httpRequest.post('/survey-district-data-export', null, config)
  }
}
