<template>
  <el-card>
    <div slot='header' class='chart-title'><span>肥料施用面积及覆盖率</span></div>
    <div>
      <el-row :gutter="20" type="flex">
        <el-col :span="24" style="padding-left: 30px;">
          <v-chart
            :option='options'
            :init-options='initOptions'
            ref='options'
            autoresize
            :loading='barLoading'
            :loadingOptions='barLoadingOptions'
            :updateOptions="{notMerge: true}"
          />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import VChart from 'vue-echarts'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'

import {
  use
} from 'echarts/core'

import {
  BarChart,
  GaugeChart,
  PieChart
} from 'echarts/charts'

import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components'
import DataVisualizationApi from '@/api/chart-analysis/data-visualization-api'

use([
  BarChart,
  PieChart,
  GaugeChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
])

export default {
  name: 'AreaAndCoverage',
  components: {
    VChart
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      initOptions: {
        renderer: 'canvas'
      },
      chartData: null,
      options: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      seconds: -1,
      barLoading: false,
      barLoadingOptions: {
        text: '加载中…',
        color: '#4ea397',
        maskColor: 'rgba(255, 255, 255, 0.4)'
      }
    }
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    getChartData() {
      this.options = {}
      this.barLoading = true
      DataVisualizationApi.getAreaAndCoverageProportions(this.params).then(res => {
        this.chartData = res.data
        if(this.chartData.length === 0) {
          this.options = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }
        this.renderChart()
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.barLoading = false
      })
    },
    renderChart() {
      const colors = ['#27BC55', '#FBAA03', '#eeeeee']
      let formatNumber = (num) => isNaN(num) ? '0' : num.toFixed(2).toString()
      this.options = {
        grid: [{
          show: true,
          borderWidth: 0,
          backgroundColor: '#F9F9F9',
          width: '47%',
          left: '20',
          top: '20',
          bottom: '30'
        }, {
          show: true,
          borderWidth: 0,
          backgroundColor: '#F9F9F9',
          width: '47%',
          top: '20',
          right: '0',
          bottom: '30'
        }],
        title: [{
          text: '有机肥施用面积（按施用面积）',
          left: '6%',
          top: '10%',
          textStyle: {
            fontSize: 14,
            color: '#000000',
            fontWeight: 500
          }
        }, {
          text: `{a|${formatNumber(this.chartData[0].value[0].value)}} {b|万亩}`,
          left: '6%',
          top: '20%',
          textStyle: {
            rich: {
              a: {
                color: 'rgba(0,0,0,0.85)',
                fontSize: 24,
                fontWeight: 500
              },
              b: {
                color: 'rgba(0,0,0,0.45)',
                fontSize: 14,
                fontWeight: 400
              }
            }
          }
        }, {
          text: '总面积',
          subtext: `${formatNumber(this.chartData[0].value[1].value)}万亩`,
          textAlign: 'center', //图例文字居中显示
          textStyle: {
            fontSize: 18,
            color: '#000000',
            fontWeight: 400
          },
          subtextStyle: {
            fontSize: 20,
            color: '#000000',
            fontWeight: 600
          },
          x: '24%',   //距离左边的距离
          y: '48%'    //距离上边的距离

        }, {
          text: '有机肥覆盖率（按农户计算）',
          left: '55%',
          top: '10%',
          textStyle: {
            fontSize: 14,
            color: '#000000',
            fontWeight: 500
          }
        }, {
          text: `{a|${formatNumber(this.chartData[1].value[0].value * 100 /this.chartData[1].value[1].value)}} {b|%}`,
          left: '55%',
          top: '20%',
          textStyle: {
            rich: {
              a: {
                color: 'rgba(0,0,0,0.85)',
                fontSize: 24,
                fontWeight: 500
              },
              b: {
                color: 'rgba(0,0,0,0.45)',
                fontSize: 14,
                fontWeight: 400
              }
            }
          }
        }, {
          text: '总户数',
          subtext: `${this.chartData[1].value[1].value}户`,
          textAlign: 'center', //图例文字居中显示
          textStyle: {
            fontSize: 18,
            color: '#000000',
            fontWeight: 400
          },
          subtextStyle: {
            fontSize: 20,
            color: '#000000',
            fontWeight: 600
          },
          x: '74%',   //距离左边的距离
          y: '48%'    //距离上边的距离

        }],
        series: [
          {
            name: this.chartData[0].name,
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            radius: '50%',
            center: ['25%', '55%'],
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                width: 25
              }
            },
            anchor: {},
            pointer: {
              show: false
            },
            title: {
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0]
            },
            progress: {
              show: true,
              width: 25,
              itemStyle: {
                color: colors[0]
              }
            },
            detail: {
              show: false
            },
            data: [formatNumber(this.chartData[0].value[0].value * 100 /this.chartData[0].value[1].value)]
          },
          {
            name: this.chartData[1].name,
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            radius: '50%',
            center: ['75%', '55%'],
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisLine: {
              lineStyle: {
                width: 25
              }
            },
            anchor: {},
            pointer: {
              show: false
            },
            title: {
              fontSize: 16,
              fontWeight: 400,
              offsetCenter: [0, 0]
            },
            progress: {
              show: true,
              width: 25,
              itemStyle: {
                color: colors[1]
              }
            },
            detail: {
              show: false
            },
            data: [formatNumber(this.chartData[1].value[0].value * 100 /this.chartData[1].value[1].value)]
          }
        ]
      }
    }
  }
}
</script>


<style scoped>

.el-divider--horizontal {
    margin: 2px 0;
}

.el-divider--vertical {
    display: inline-block;
    weight: 1px;
    height: 300px;
    margin-top: 20px;
    margin-bottom: 0;
    position: relative;
}
</style>
