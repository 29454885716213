<template>
  <div class="wg-box">
    <div class="header" style="padding: 0 20px 0 20px">
      <div class="title" style="padding: 0 14px">单位播种面积施用量（公斤/亩）</div>
      <div class="button-list" style="float: right; padding: 8px 0 8px 0">
        <el-date-picker
          v-model="year"
          type="year"
          format="yyyy年"
          value-format="yyyy"
          suffix-icon="el-icon-date"
          size="small"
          :picker-options="pickerOptions"
          @change="bindTableData"
        >
        </el-date-picker>&nbsp;&nbsp;
        <el-button size="small" class="wg-button blue" icon="el-icon-download" @click="handleExportData">导出</el-button>
      </div>
    </div>
    <div class="body" style="padding: 10px 20px 10px 20px">
      <div>
        <el-table :data="tableData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)" class="wg-table normal" v-loading="isLoading"
                  element-loading-text="拼命加载数据......"
                  element-loading-spinner="el-icon-loading">
          <el-table-column label="序号" type="index" width="100"></el-table-column>
          <el-table-column label="区域" prop="regionName"></el-table-column>
          <el-table-column label="氮（N）（公斤/亩）" prop="nitrogenDosage"></el-table-column>
          <el-table-column label="磷（P2O5）（公斤/亩）" prop="phosphorusDosage"></el-table-column>
          <el-table-column label="钾（K2O）（公斤/亩）" prop="potassiumDosage"></el-table-column>
          <el-table-column label="总养分（公斤/亩）" prop="totalNutrient"></el-table-column>
          <el-table-column label="面积（万亩）" prop="sowingArea"></el-table-column>
          <el-table-column label="农户数（户）" prop="farmerCount"></el-table-column>
          <el-table-column label="作物数（个）" prop="cropCount"></el-table-column>
        </el-table>
      </div>
      <div class="wg-pagination-box">
        <el-pagination background layout="prev, pager, next" :total="recordTotal" :page-size="pageSize" :current-page="pageIndex" @current-change="handlePagingChange"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SurveyStatisticApi from '@/api/statistic/survey-statistic-api'
import FileApi from '@/api/file-api'
import FillInCycleApi from '@/api/data-fill-in/cycle/fill-in-cycle-api'

export default {
  name: 'UnitSowingAreaFertilizerDosage',
  data() {
    return {
      isLoading: false,
      year: '',
      years: [],
      pickerOptions: {
        disabledDate: (time) => {
          let years = this.years
          if(Array.isArray(years)) {
            return !years.includes(time.getFullYear())
          }
          return time.getFullYear() !== years[0]
        }
      },
      tableData: [],
      recordTotal: 0,
      pageSize: 10,
      pageIndex: 1
    }
  },
  created() {
    this.bindYears()
  },
  methods: {
    bindYears() {
      this.years = []
      FillInCycleApi.getList().then(res => {
        res.data.map(v => {
          this.years.push(v.year)
        })
        this.year = this.years[0].toString()
        this.bindTableData()
      })
    },
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
    },
    bindTableData() {
      this.isLoading = true
      SurveyStatisticApi.getUnitSowingAreaFertilizerDosageList(this.year)
        .then(res => {
          this.tableData = []
          this.recordTotal = res.data.length
          this.pageIndex = 1
          res.data.forEach(el => {
            this.tableData.push({
              regionName: el.regionName,
              nitrogenDosage: parseFloat(el.nitrogenDosage.toFixed(2)),
              phosphorusDosage: parseFloat(el.phosphorusDosage.toFixed(2)),
              potassiumDosage: parseFloat(el.potassiumDosage.toFixed(2)),
              totalNutrient: parseFloat(el.totalNutrient.toFixed(2)),
              sowingArea: parseFloat(el.sowingArea.toFixed(4)),
              farmerCount: el.farmerCount,
              cropCount: el.cropCount
            })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        }).finally(() => {
          this.isLoading = false
        })
    },
    handleExportData() {
      this.$confirm('准备导出数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let exportId = null
          let exportCount = 0
          let finished = false

          while (!finished) {
            const loading = this.$loading({
              lock: true,
              text: 0 === exportCount ? '正在导出数据......' : '已导出' + exportCount + '条数据......',
              spinner: 'el-icon-loading'
            })

            await SurveyStatisticApi.exportUnitSowingAreaFertilizerDosages(exportId, this.year)
              .then(res => {
                exportId = res.data.id
                exportCount = res.data.exportCount
                finished = res.data.finished
                loading.close()
              })
              .catch(err => {
                loading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          }

          const loading = this.$loading({
            lock: true,
            text: '等待下载数据文件......',
            spinner: 'el-icon-loading'
          })

          await FileApi.get('TEMP', exportId + '.xlsx')
            .then(res => {
              let byteBuffer = window.atob(res.data)
              let arrayBuffer = new ArrayBuffer(byteBuffer.length)
              let dataBuffer = new Uint8Array(arrayBuffer)
              for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

              let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(blob)
              link.setAttribute('download', `单位播种面积施用量-${this.year.toString()}.xlsx`)
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(link.href)
              document.body.removeChild(link)
              loading.close()
            })
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })

          await FileApi.delete('TEMP', exportId + '.xlsx')
            .then(() => {})
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
        })
    },
  }
}
</script>

<style scoped>

.title {
  position: relative;
  margin: 12px 0;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.title:before {
  content: "";
  background-color: #039f3a;
  width: 6px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -12px;
}

.el-date-editor >>> .el-input__inner {
  padding: 0 15px;
}
.el-date-editor >>> .el-input__prefix{
  right: 5px;
  left: unset;
}
.el-date-editor >>> .el-input__suffix{
  right: 25px;
}
</style>
