<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/" class="bread_crumb">
        <el-breadcrumb-item :to="{ path: '/' }">数据填报</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/questionnaire-admin' }">施肥问卷</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <el-container>
      <el-aside class="wg-side" :width="isCollapse ? '24px' : '240px'">
        <div v-show="!isCollapse">
          <div class="side-fence">
            施肥问卷
            <i class="el-icon-d-arrow-left collapse-button" @click="handleToggleCollapse"></i>
          </div>
          <region-tree @node-click="handleRegionTreeNodeClick"></region-tree>
        </div>
        <div v-show="isCollapse" class="collapse-fence">
          展开菜单栏
          <div class="collapse-icon">
            <i class="el-icon-d-arrow-right" @click="handleToggleCollapse"></i>
          </div>
        </div>
      </el-aside>
      <el-main class="wg-main" v-loading="dataLoading" element-loading-text="拼命加载数据......" element-loading-spinner="el-icon-loading">
        <div class="wg-box no-header" style="margin-top: 0px">
          <div class="body">
            <el-form :inline="true" :model="filterForm" size="small" label-width="100px">
              <el-form-item label="提交状态：" prop="submitFlag">
                <el-select v-model="filterForm.submitFlag" @change="handleSubmitFlagChange">
                  <el-option v-for="option in filterForm.filterData.submitFlagOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="填报方式：" prop="assistFlag">
                <el-select v-model="filterForm.assistFlag" @change="handleAssistFlagChange">
                  <el-option v-for="option in filterForm.filterData.assistFlagOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关键字：">
                <el-input v-model="filterForm.keyword" clearable size="small" @input="handleKeywordInput" style="width: 180px"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- filter -->
        <div class="wg-box">
          <div class="header">
            <div class="button-list">
              <el-button size="small" class="wg-button green" @click="handleAdd" v-if="!flags.enabledAddFlag && !flags.enableEditFlag && !checkedAuditFlag" icon="el-icon-plus"> 新增 </el-button>
              <el-button size="small" class="wg-button orange" @click="handleSave" v-if="flags.enabledAddFlag && !flags.enableEditFlag && !checkedAuditFlag" icon="el-icon-upload">保存 </el-button>
              <el-button size="small" class="wg-button red" @click="handleCancel" v-if="flags.enabledAddFlag && !flags.enableEditFlag && !checkedAuditFlag" icon="el-icon-close"> 取消 </el-button>
              <el-button size="small" class="wg-button green" @click="handleEdit" v-if="!flags.enabledAddFlag && !flags.enableEditFlag && !checkedAuditFlag" icon="el-icon-edit"> 编辑 </el-button>
              <el-button size="small" class="wg-button orange" @click="handleEditEnd" v-if="!flags.enabledAddFlag && flags.enableEditFlag && !checkedAuditFlag" icon="el-icon-close"> 结束 </el-button>
              <el-button size="small" class="wg-button red" @click="handleDeleteSelected" v-if="!flags.enabledAddFlag && !flags.enableEditFlag && !checkedAuditFlag" icon="el-icon-delete">删除 </el-button>
              <el-button size="small" class="wg-button blue" @click="handleExportData" v-if="!flags.enabledAddFlag && !flags.enableEditFlag" icon="el-icon-download">导出 </el-button>
              <el-button size="small" class="wg-button blue" @click="handleImportData" v-if="!flags.enabledAddFlag && !flags.enableEditFlag && !checkedAuditFlag" icon="el-icon-upload2">导入 </el-button>
              <el-button size="small" class="wg-button blue" @click="handleTemplateDownload" v-if="!flags.enabledAddFlag && !flags.enableEditFlag && !checkedAuditFlag" icon="el-icon-tickets">导入模板下载 </el-button>
            </div>
            <div class="wg-clear-fix"></div>
          </div>
          <div class="body">
            <div class="wg-table-box">
              <el-form ref="form" :model="form" style="padding-top: 0">
                <el-table ref="questionnaireTable" :data="form.tableData" :max-height="600" border style="width: 100%" class="wg-table questionnaire-table" @row-click="handleRowClick" @selection-change="handleSelectionChange">
                  <el-table-column type="selection" width="50" fixed></el-table-column>
                  <el-table-column label="序号" prop="index" width="80" sortable fixed></el-table-column>
                  <el-table-column label="省份" prop="provinceName" width="120"></el-table-column>
                  <el-table-column label="地市" prop="cityName" width="120"></el-table-column>
                  <el-table-column label="区县" prop="districtName" width="120"></el-table-column>
                  <el-table-column label="农户姓名" prop="fullName" width="120"></el-table-column>
                  <el-table-column label="电话" prop="phoneNumber" width="120"></el-table-column>
                  <el-table-column v-for="(question, index) in form.questionList" :label="question.name" :key="question.id" width="350px">
                    <template slot-scope="scope">
                      <div v-if="scope.row.editFlag">
                        <el-form-item :prop="'tableData.' + scope.$index + '.questionList.' + index + '.reply.' + scope.row.questionList[index].prop" :rules="scope.row.questionList[index].ruleType">
                          <el-radio-group v-model="scope.row.questionList[index].reply.radioed" v-if="scope.row.questionList[index].type === 'SINGLE_CHOICE'">
                            <el-radio v-for="answer in scope.row.questionList[index].answerList" :label="answer.id" :key="answer.id">{{ answer.name }} </el-radio>
                          </el-radio-group>
                          <!-- radio group -->
                          <el-checkbox-group v-model="scope.row.questionList[index].reply.checkedList" v-if="scope.row.questionList[index].type === 'MULTIPLE_CHOICE'">
                            <el-checkbox v-for="answer in scope.row.questionList[index].answerList" :label="answer.id" :key="answer.id" :ref="answer.id" @change="handleChkAnswerChange($event, answer.id, answer.extTextFlag)">
                              {{ answer.name }}&nbsp;
                              <el-input v-model="answer.extText" style="width: 150px" size="small" :disabled="answer.disabled" v-if="answer.extTextFlag"></el-input>
                            </el-checkbox>
                          </el-checkbox-group>
                          <!-- checkbox group -->
                          <el-input v-model="scope.row.questionList[index].reply.text" v-if="scope.row.questionList[index].type === 'TEXT'"></el-input>
                          <!-- text -->
                        </el-form-item>
                      </div>
                      <div v-else>
                        <div v-for="replyText in scope.row.questionList[index].replyTextList" :key="replyText">
                          {{ replyText }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form>
            </div>
            <div class="wg-pagination-box">
              <el-pagination background layout="total, sizes, prev, pager, next" :total="recordTotal" :page-sizes="[30, 50, 100]" :page-size="pageSize" :current-page="pageIndex" @size-change="handleSizeChange" @current-change="handlePagingChange"></el-pagination>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <farmer-select-dialog ref="farmerSelectDialog" v-if="flags.isLoadFarmerSelectDialog" @close="handleFarmerSelectDialogClose"></farmer-select-dialog>
    <upload-questionnaire-import-file-dialog ref="uploadQuestionnaireImportFileDialog" v-if="flags.loadUploadQuestionnaireImportFileDialog" @close="handleUploadQuestionnaireImportFileDialogClose" @uploadSuccess="handleUploadQuestionnaireImportFileSuccess"></upload-questionnaire-import-file-dialog>
  </div>
</template>

<script>
import QuestionnaireApi from '@/api/questionnaire-api'
import FillInCycleApi from '@/api/data-fill-in/cycle/fill-in-cycle-api'
import FileApi from '@/api/file-api'

import RegionTree from '@/components/RegionTree'
import NoFillQuestionnaireFarmerSelectDialog from './components/NoFillQuestionnaireFarmerSelectDialog'
import UploadQuestionnaireImportFileDialog from './components/UploadQuestionnaireImportFileDialog'

export default {
  name: 'QuestionnaireAssist',
  components: {
    'region-tree': RegionTree,
    'farmer-select-dialog': NoFillQuestionnaireFarmerSelectDialog,
    'upload-questionnaire-import-file-dialog': UploadQuestionnaireImportFileDialog
  },
  data() {
    return {
      isCollapse: false,
      dataLoading: false,
      flags: {
        isLoadFarmerSelectDialog: false,
        loadUploadQuestionnaireImportFileDialog: false,
        enabledAddFlag: false,
        enableEditFlag: false
      },
      models: {
        questionList: null
      },
      filterForm: {
        filterData: {
          submitFlagOptionList: [],
          assistFlagOptionList: []
        },
        submitFlag: null,
        assistFlag: null,
        keyword: null
      },
      form: {
        questionList: [],
        tableData: []
      },
      formRules: {
        radioed: [{ required: true, message: '请选择选项！', trigger: 'change' }],
        checkedList: [{ type: 'array', required: true, message: '请至少选择一个选项！', trigger: 'change' }],
        text: [{ required: true, message: '请输入内容！', trigger: 'blur' }]
      },
      recordTotal: 0,
      pageSize: 30,
      pageIndex: 1,
      checkedYear: null,
      checkedRegionId: null,
      checkedAuditFlag: null,

      newRowCount: 0,
      clickedRow: null,
      selectedRows: []
    }
  },
  async created() {
    this.filterForm.filterData.submitFlagOptionList.push({ label: '全部', value: null })
    this.filterForm.filterData.submitFlagOptionList.push({ label: '已提交', value: true })
    this.filterForm.filterData.submitFlagOptionList.push({ label: '未提交', value: false })

    this.filterForm.filterData.assistFlagOptionList.push({ label: '全部', value: null })
    this.filterForm.filterData.assistFlagOptionList.push({ label: '辅助填报', value: true })
    this.filterForm.filterData.assistFlagOptionList.push({ label: '农户填报', value: false })

    await FillInCycleApi.getLast().then(res => {
      let userLevelId = sessionStorage.getItem('levelId')
      switch (userLevelId) {
        case 'Province':
          this.checkedRegionId = sessionStorage.getItem('provinceId')
          break
        case 'City':
          this.checkedRegionId = sessionStorage.getItem('cityId')
          break
        case 'District':
          this.checkedRegionId = sessionStorage.getItem('districtId')
          break
      }
      this.checkedYear = res.data.year
      this.checkedAuditFlag = res.data.archiveFlag
    })

    await QuestionnaireApi.getQuestionList().then(res => {
      this.models.questionList = res.data
      res.data.forEach(el => {
        this.form.questionList.push({ id: el.id, name: el.name })
      })
    })

    this.bindTableData()
  },
  methods: {
    handleToggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    handleFarmerSelectDialogClose(rows) {
      if (rows.length > 0) {
        this.newRowCount = rows.length
        this.flags.enabledAddFlag = true
        let index = 0
        rows.forEach(el => {
          let rowData = {
            editFlag: true,
            index: --index,
            id: null,
            year: this.checkedYear,
            farmerId: el.id,
            provinceName: el.provinceName,
            cityName: el.cityName,
            districtName: el.districtName,
            fullName: el.fullName,
            phoneNumber: el.phoneNumber,
            questionList: []
          }
          this.form.tableData.unshift(rowData)

          this.models.questionList.forEach(elQuestion => {
            let question = {
              id: elQuestion.id,
              type: elQuestion.type,
              answerList: [],
              replyTextList: [],
              reply: {
                radioed: null,
                checkedList: [],
                text: null
              },
              ruleType: null
            }
            if (elQuestion.type === 'SINGLE_CHOICE') {
              question.ruleType = this.formRules.radioed
              question.prop = 'radioed'
            }
            if (elQuestion.type === 'MULTIPLE_CHOICE') {
              question.ruleType = this.formRules.checkedList
              question.prop = 'checkedList'
            }
            if (elQuestion.type === 'TEXT') {
              question.ruleType = this.formRules.text
              question.prop = 'text'
            }
            rowData.questionList.push(question)

            elQuestion.answerList.forEach(elAnswer => {
              let answer = {
                id: elAnswer.id,
                name: elAnswer.name,
                extTextFlag: elAnswer.extTextFlag,
                extText: elAnswer.extText,
                disabled: !elAnswer.reply
              }
              question.answerList.push(answer)
            })
          })
        })
      }
      this.flags.isLoadFarmerSelectDialog = false
    },
    handleUploadQuestionnaireImportFileDialogClose() {
      this.flags.loadUploadQuestionnaireImportFileDialog = false
    },
    handleUploadQuestionnaireImportFileSuccess(savedFileName) {
      this.importData(this.checkedYear, savedFileName)
    },
    handleRegionTreeNodeClick(checkedItem) {
      this.flags.enabledAddFlag = false
      this.flags.enableEditFlag = false
      this.checkedYear = checkedItem.year
      this.checkedRegionId = checkedItem.regionId
      this.checkedAuditFlag = checkedItem.archiveFlag
      this.bindTableData()
    },
    handleSubmitFlagChange() {
      this.bindTableData()
    },
    handleAssistFlagChange() {
      this.bindTableData()
    },
    handleKeywordInput() {
      this.bindTableData()
    },
    handleSizeChange(pageSize) {
      this.clickedRow = null
      this.pageIndex = 1
      this.pageSize = pageSize
      this.bindTableData()
    },
    handlePagingChange(pageIndex) {
      this.clickedRow = null
      this.pageIndex = pageIndex
      this.bindTableData()
    },
    handleRowClick(row) {
      if (!this.flags.enableEditFlag) return

      if (null === this.clickedRow) {
        row.editFlag = true
        this.clickedRow = row
      } else if (row.index != this.clickedRow.index) {
        this.$refs.form.validate(async valid => {
          if (!valid) return

          await this.update(this.clickedRow)

          this.clickedRow.editFlag = false
          row.editFlag = true
          this.clickedRow = row
        })
      }
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows
    },
    handleChkAnswerChange(checked, answerId, extTextFlag) {
      if (extTextFlag) {
        this.form.tableData.forEach(el => {
          el.questionList.forEach(elQuestion => {
            elQuestion.answerList.forEach(elAnswer => {
              if (elAnswer.id === answerId) {
                elAnswer.disabled = !checked
                if (checked) {
                  elAnswer.disabled = false
                } else {
                  elAnswer.disabled = true
                  elAnswer.extText = null
                }
              }
            })
          })
        })
      }
    },
    handleAdd() {
      this.flags.isLoadFarmerSelectDialog = true
      this.$nextTick(() => {
        this.$refs.farmerSelectDialog.open(this.checkedYear, this.checkedRegionId)
      })
    },
    handleSave() {
      this.$refs.form.validate(async valid => {
        if (!valid) return

        let rows = []
        for (let i = 0; i < this.newRowCount; i++) rows.push(this.form.tableData[i])
        this.create(rows)
        this.flags.enabledAddFlag = false
      })
    },
    handleCancel() {
      for (let i = 0; i < this.newRowCount; i++) {
        this.form.tableData.shift()
      }
      this.newRowCount = 0
      this.flags.enabledAddFlag = false
    },
    handleEdit() {
      this.flags.enableEditFlag = true
    },
    async handleEditEnd() {
      if (null != this.clickedRow) {
        this.$refs.form.validate(async valid => {
          if (!valid) return

          this.update(this.clickedRow)
          this.clickedRow.editFlag = false
          this.clickedRow = null
          this.flags.enableEditFlag = false
        })
      } else this.flags.enableEditFlag = false
    },
    handleDeleteSelected() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.delete()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    handleTemplateDownload() {
      this.$confirm('导出数据模板, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let exportId = null
          let exportCount = 0
          let finished = false

          while (!finished) {
            const loading = this.$loading({
              lock: true,
              text: 0 == exportCount ? '正在导出数据......' : '已导出' + exportCount + '条数据......',
              spinner: 'el-icon-loading'
            })

            await QuestionnaireApi.exportTemplate(exportId, this.checkedYear, this.checkedRegionId)
              .then(res => {
                exportId = res.data.id
                exportCount = res.data.exportCount
                finished = res.data.finished
                loading.close()
              })
              .catch(err => {
                loading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          }

          const loading = this.$loading({
            lock: true,
            text: '等待下载数据文件......',
            spinner: 'el-icon-loading'
          })

          await FileApi.get('TEMP', exportId + '.xlsx')
            .then(res => {
              let byteBuffer = window.atob(res.data)
              let arrayBuffer = new ArrayBuffer(byteBuffer.length)
              let dataBuffer = new Uint8Array(arrayBuffer)
              for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

              let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(blob)
              link.setAttribute('download', '数据模板.xlsx')
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(link.href)
              document.body.removeChild(link)
              loading.close()
            })
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })

          await FileApi.delete('TEMP', exportId + '.xlsx')
            .then(() => {})
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
        })
    },
    handleImportData() {
      this.flags.loadUploadQuestionnaireImportFileDialog = true
      this.$nextTick(() => {
        this.$refs.uploadQuestionnaireImportFileDialog.open()
      })
    },
    handleExportData() {
      this.$confirm('导出施肥问卷数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let exportId = null
          let exportCount = 0
          let finished = false

          while (!finished) {
            const loading = this.$loading({
              lock: true,
              text: 0 == exportCount ? '正在导出数据......' : '已导出' + exportCount + '条数据......',
              spinner: 'el-icon-loading'
            })

            await QuestionnaireApi.exportData(exportId, this.checkedYear, this.checkedRegionId, this.filterForm.submitFlag, this.filterForm.assistFlag)
              .then(res => {
                exportId = res.data.id
                exportCount = res.data.exportCount
                finished = res.data.finished
                loading.close()
              })
              .catch(err => {
                loading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          }

          const loading = this.$loading({
            lock: true,
            text: '等待下载数据文件......',
            spinner: 'el-icon-loading'
          })

          await FileApi.get('TEMP', exportId + '.xlsx')
            .then(res => {
              let byteBuffer = window.atob(res.data)
              let arrayBuffer = new ArrayBuffer(byteBuffer.length)
              let dataBuffer = new Uint8Array(arrayBuffer)
              for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

              let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = URL.createObjectURL(blob)
              link.setAttribute('download', '施肥问卷.xlsx')
              document.body.appendChild(link)
              link.click()
              URL.revokeObjectURL(link.href)
              document.body.removeChild(link)
              loading.close()
            })
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })

          await FileApi.delete('TEMP', exportId + '.xlsx')
            .then(() => {})
            .catch(err => {
              loading.close()
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
        })
    },
    async create(rows) {
      let createModelList = []
      rows.forEach(row => {
        let replyModelList = []
        row.questionList.forEach(elQuestion => {
          if (elQuestion.type === 'SINGLE_CHOICE') {
            let replyMode = {
              questionId: elQuestion.id,
              answerId: elQuestion.reply.radioed
            }
            replyModelList.push(replyMode)
          }
          if (elQuestion.type === 'MULTIPLE_CHOICE') {
            let tempAnswerArray = []
            elQuestion.answerList.forEach(elAnswer => {
              tempAnswerArray[elAnswer.id] = elAnswer
            })
            elQuestion.reply.checkedList.forEach(el => {
              let replyMode = {
                questionId: elQuestion.id,
                answerId: el,
                extText: null
              }
              let tempAnswer = tempAnswerArray[el]
              if (tempAnswer.extTextFlag) replyMode.extText = tempAnswer.extText
              replyModelList.push(replyMode)
            })
          }
          if (elQuestion.type === 'TEXT') {
            let replyMode = {
              questionId: elQuestion.id,
              answerId: elQuestion.answerList[0].id
            }
            replyModelList.push(replyMode)
          }
        })

        let createModel = {
          year: row.year,
          farmerId: row.farmerId,
          replyList: replyModelList
        }
        createModelList.push(createModel)
      })
      for (let i = 0; i < createModelList.length; i++) {
        await QuestionnaireApi.create(createModelList[i])
          .then(() => {
            this.$message({ type: 'success', message: '保存成功！' })
          })
          .catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          })
      }
      this.bindTableData()
    },
    async update(row) {
      let replyModelList = []
      row.questionList.forEach(elQuestion => {
        if (elQuestion.type === 'SINGLE_CHOICE') {
          let replyMode = {
            questionId: elQuestion.id,
            answerId: elQuestion.reply.radioed
          }
          replyModelList.push(replyMode)
        }
        if (elQuestion.type === 'MULTIPLE_CHOICE') {
          let tempAnswerArray = []
          elQuestion.answerList.forEach(elAnswer => {
            tempAnswerArray[elAnswer.id] = elAnswer
          })
          elQuestion.reply.checkedList.forEach(el => {
            let replyMode = {
              questionId: elQuestion.id,
              answerId: el,
              extText: null
            }
            let tempAnswer = tempAnswerArray[el]
            if (tempAnswer.extTextFlag) replyMode.extText = tempAnswer.extText
            replyModelList.push(replyMode)
          })
        }
        if (elQuestion.type === 'TEXT') {
          let replyMode = {
            questionId: elQuestion.id,
            answerId: elQuestion.answerList[0].id
          }
          replyModelList.push(replyMode)
        }
      })

      let updateModel = {
        id: row.id,
        year: row.year,
        farmerId: row.farmerId,
        replyList: replyModelList
      }
      await QuestionnaireApi.update(updateModel)
        .then(() => {
          this.$message({ type: 'success', message: '记录【' + row.index + '】保存成功！' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })

      QuestionnaireApi.getById(updateModel.id)
        .then(res => {
          let replyArray = []
          res.data.replyList.forEach(el => {
            replyArray[el.answerId] = el
          })
          row.questionList.forEach(elQuestion => {
            elQuestion.replyTextList = []
            elQuestion.reply.radioed = null
            elQuestion.reply.checkedList = []
            elQuestion.reply.text = null
            elQuestion.answerList.forEach(elAnswer => {
              elAnswer.extText = null
              elAnswer.disabled = true
              let reply = replyArray[elAnswer.id]
              if (undefined !== reply) {
                if (elQuestion.type === 'SINGLE_CHOICE') {
                  elQuestion.reply.radioed = reply.answerId
                  elAnswer.extText = reply.extText
                  elQuestion.replyTextList.push(elAnswer.name)
                } else if (elQuestion.type === 'MULTIPLE_CHOICE') {
                  elQuestion.reply.checkedList.push(reply.answerId)
                  let answerText = elAnswer.name
                  if (elAnswer.extTextFlag) {
                    elAnswer.disabled = false
                    elAnswer.extText = reply.extText
                    if (reply.extText !== '' && reply.extText !== null) answerText += '，' + reply.extText
                  }
                  elQuestion.replyTextList.push(answerText)
                } else if (elQuestion.type === 'TEXT') {
                  elAnswer.reply.text = reply.extText
                  elQuestion.replyTextList.push(reply.extText)
                }
              }
            })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async delete() {
      let theObj = this
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading' })
      for (let i = 0; i < this.selectedRows.length; i++) {
        await QuestionnaireApi.deleteById(this.selectedRows[i].id)
          .then(() => {
            this.$message({
              showClose: true,
              message: '记录【' + theObj.selectedRows[i].index + '】删除成功！',
              type: 'success'
            })
          })
          .catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          })
      }
      loading.close()
      this.bindTableData()
    },
    importData(year, fileName) {
      let loading = this.$loading({
        lock: true,
        text: '等待返回导入结果......',
        spinner: 'el-icon-loading'
      })

      setTimeout(() => {}, 10000)
      QuestionnaireApi.importData(year, fileName)
        .then(res => {
          let byteBuffer = window.atob(res.data)
          let arrayBuffer = new ArrayBuffer(byteBuffer.length)
          let dataBuffer = new Uint8Array(arrayBuffer)
          for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

          let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', '数据导入结果.xlsx')
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
          document.body.removeChild(link)

          this.bindTableData()
          loading.close()
        })
        .catch(err => {
          loading.close()
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    bindTableData() {
      let index = 0
      this.dataLoading = true
      QuestionnaireApi.getPaging(this.checkedYear, this.checkedRegionId, this.filterForm.keyword, this.filterForm.submitFlag, this.filterForm.assistFlag, this.pageSize, this.pageIndex)
        .then(res => {
          this.form.tableData = []
          this.recordTotal = res.data.totalCount

          res.data.questionnaireList.forEach(el => {
            let rowData = {
              editFlag: false,
              index: ++index,
              id: el.id,
              year: el.year,
              farmerId: el.farmerId,
              provinceName: el.provinceName,
              cityName: el.cityName,
              districtName: el.districtName,
              fullName: el.fullName,
              phoneNumber: el.phoneNumber,
              questionList: []
            }
            this.form.tableData.push(rowData)

            let replyArray = []
            el.replyList.forEach(el => {
              replyArray[el.answerId] = el
            })

            this.models.questionList.forEach(elQuestion => {
              let question = {
                id: elQuestion.id,
                type: elQuestion.type,
                answerList: [],
                replyTextList: [],
                reply: {
                  radioed: null,
                  checkedList: [],
                  text: null
                },
                ruleType: null
              }
              if (elQuestion.type === 'SINGLE_CHOICE') {
                question.ruleType = this.formRules.radioed
                question.prop = 'radioed'
              }
              if (elQuestion.type === 'MULTIPLE_CHOICE') {
                question.ruleType = this.formRules.checkedList
                question.prop = 'checkedList'
              }
              if (elQuestion.type === 'TEXT') {
                question.ruleType = this.formRules.text
                question.prop = 'text'
              }
              rowData.questionList.push(question)

              elQuestion.answerList.forEach(elAnswer => {
                let answer = {
                  id: elAnswer.id,
                  name: elAnswer.name,
                  extTextFlag: elAnswer.extTextFlag,
                  extText: elAnswer.extText,
                  disabled: !elAnswer.reply
                }
                let reply = replyArray[elAnswer.id]
                if (undefined !== reply) {
                  if (elQuestion.type === 'SINGLE_CHOICE') {
                    question.reply.radioed = reply.answerId
                    question.replyTextList.push(elAnswer.name)
                  } else if (elQuestion.type === 'MULTIPLE_CHOICE') {
                    question.reply.checkedList.push(reply.answerId)
                    let answerText = elAnswer.name
                    if (elAnswer.extTextFlag) {
                      answer.disabled = false
                      answer.extText = reply.extText
                      if (reply.extText !== '' && reply.extText !== null) answerText += '，' + reply.extText
                    }
                    question.replyTextList.push(answerText)
                  } else if (elQuestion.type === 'TEXT') {
                    answer.reply.text = reply.extText
                    question.replyTextList.push(reply.extText)
                  }
                }
                question.answerList.push(answer)
              })
            })
          })
        })
        .finally(() => {
          this.dataLoading = false
        })
    }
  }
}
</script>

<style scoped>
.questionnaire-table .el-checkbox {
  display: block;
}

.questionnaire-table .el-radio {
  display: block;
  line-height: 25px;
}
</style>
