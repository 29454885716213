<template>
  <div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="80%" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
      <div class="wg-box">
        <div class="header">
          <el-form :inline="true">
            <el-form-item label="关键字">
              <el-input v-model="filterForm.keyword" clearable size="small" style="width: 180px"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="wg-button" size="small" @click="handleSearch">搜索</el-button>
            </el-form-item>
            <el-form-item style="display: none">
              <el-button class="wg-button" size="small" @click="handleClearFilter">清除过滤器</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="body">
          <div class="wg-table-box">
            <el-table
              ref="table"
              :data="tableData"
              border
              class="wg-table normal"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              @sort-change="handleSortChange"
              @filter-change="handleFilterChange"
            >
              <el-table-column type="selection" width="50" fixed></el-table-column>
              <el-table-column label="序号" prop="index" width="80" sortable fixed></el-table-column>
              <el-table-column label="用户名称" prop="username"></el-table-column>
              <el-table-column label="省份" column-key="ProvinceName" prop="provinceName" width="100" sortable="custom"></el-table-column>
              <el-table-column label="地市" column-key="CityName" prop="cityName" width="100" sortable="custom"></el-table-column>
              <el-table-column label="区县" column-key="DistrictName" prop="districtName" width="100" sortable="custom"></el-table-column>
              <el-table-column label="详细地址" prop="detailedAddress"></el-table-column>
              <el-table-column label="农户姓名" prop="fullName"></el-table-column>
              <el-table-column label="电话" prop="phoneNumber" width="120"></el-table-column>
              <el-table-column label="农户性质" prop="farmerNatureName" width="120"></el-table-column>
              <el-table-column label="文化程度" prop="educationalLevelName" width="120"></el-table-column>
              <el-table-column label="经营面积(含园地，亩)" prop="cultivationLandArea"></el-table-column>
              <el-table-column label="示范户" prop="modelHouseholdFlag">
                <template slot-scope="scope">
                  <div :style="scope.row.modelHouseholdFlag ? 'color:#039F3A' : 'color:#FF8900'">{{ scope.row.modelHouseholdFlag ? '是' : '否' }}</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="wg-pagination-box">
            <el-pagination
              background
              layout="total, sizes, prev, pager, next"
              :total="recordTotal"
              :page-sizes="[30, 50, 100]"
              :page-size="pageSize"
              :current-page="pageIndex"
              @size-change="handleSizeChange"
              @current-change="handlePagingChange"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button size="small" class="wg-button blank" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" class="wg-button" @click="handleConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FarmerApi from '@/api/farmer-api'

export default {
  name: 'FarmerSelectDialog',
  data() {
    return {
      dialogTitle: '农户选择',
      dialogVisible: false,
      regionId: null,
      filterForm: {
        keyword: null,
        provinceId: null,
        cityId: null,
        districtId: null
      },
      tableData: [],
      sortCriteria: null,
      filterCriteria: null,
      recordTotal: 0,
      pageSize: 30,
      pageIndex: 1,
      selectedRows: []
    }
  },
  methods: {
    handleClose() {
      this.$emit('close', this.selectedRows)
    },
    handleSizeChange(pageSize) {
      this.pageIndex = 1
      this.pageSize = pageSize
      this.bindTableData()
    },
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
      this.bindTableData()
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows
    },
    handleSortChange({column, order}) {
      this.sortCriteria = null
      if (null != order) {
        if (order === 'ascending') {
          this.sortCriteria = column.columnKey + '-ASC'
        }
        if (order === 'descending') {
          this.sortCriteria = column.columnKey + '-DESC'
        }
      }
      this.bindTableData()
    },
    handleFilterChange(filters) {
      if (null !== this.filterCriteria) {
        let key = Object.keys(this.filterCriteria)[0]
        this.$refs.table.clearFilter(key)
      }

      this.filterCriteria = null

      let key = Object.keys(filters)[0]
      if (filters[key].length > 0) {
        this.filterCriteria = filters
      }

      this.bindTableData()
    },
    handleSearch() {
      this.pageIndex = 1
      this.sortCriteria = null
      this.$refs.table.clearSort()
      this.bindTableData()
    },
    handleClearFilter() {
      this.$refs.table.clearFilter()
    },
    handleConfirm() {
      this.dialogVisible = false
    },
    open(regionId) {
      this.regionId = regionId
      this.bindTableData()
    },
    bindTableData() {
      const loading = this.$loading({
        lock: true,
        text: '正在加载数据......',
        spinner: 'el-icon-loading'
      })

      FarmerApi.getCount(this.regionId, this.filterForm.keyword).then(res => {
        this.recordTotal = res.data
      })

      FarmerApi.getLimitList(this.regionId, this.filterForm.keyword, null, this.sortCriteria, this.pageSize, this.pageIndex)
        .then(res => {
          let index = (this.pageIndex - 1) * this.pageSize
          this.tableData = []
          res.data.forEach(el => {
            this.tableData.push({
              id: el.id,
              index: ++index,
              username: el.username,
              provinceId: el.provinceId,
              provinceName: el.provinceName,
              cityId: el.cityId,
              cityName: el.cityName,
              districtId: el.districtId,
              districtName: el.districtName,
              detailedAddress: el.detailedAddress,
              fullName: el.fullName,
              phoneNumber: el.phoneNumber,
              farmerNature: el.farmerNature,
              farmerNatureName: el.farmerNatureName,
              educationalLevel: el.educationalLevel,
              educationalLevelName: el.educationalLevelName,
              cultivationLandArea: el.cultivationLandArea,
              modelHouseholdFlag: el.modelHouseholdFlag
            })
          })

          this.dialogVisible = true
        })
        .catch(err => {
          this.$message({showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error'})
        })
        .finally(() => {
          loading.close()
        })
    }
  }
}
</script>

<style scoped></style>
