<template>
  <div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="800px" @close="handleClose">
      <div class="crop-filter">
        <el-row>
          <el-col :span="18">
            <div>
              已选择：<span class="crop-name" v-for="name in checkedCropNameArray" :key="name">{{ name }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div>
              <el-input placeholder="按下回车键搜索作物分类" v-model="keyword" @keypress.native.enter="handleSearch" size="mini" clearable></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-collapse v-model="cropSubCategoryGroupActiveArray" class="crop-select-collapse">
        <el-collapse-item :title="group.name" :name="group.name" :key="group.name" v-for="group in cropSubCategoryGroupArray">
          <el-checkbox-group v-model="group.checkedArray">
            <el-checkbox v-for="subCategory in group.subCategoryArray" :label="subCategory.id" :key="subCategory.id" @change="handleChange">
              {{ subCategory.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-collapse-item>
      </el-collapse>
      <div slot="footer">
        <el-button size="small" @click="handleClose"> 取 消</el-button>
        <el-button size="small" @click="handleConfirm" class="wg-button"> 确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  name: 'CropSelect',
  props: {
    cropRootCategoryArray: [],
    cropSubCategoryArray: []
  },
  data() {
    return {
      dialogTitle: '请勾选该地块您所要填报的作物分类(可多选)',
      dialogVisible: false,
      keyword: null,
      cropSubCategoryGroupActiveArray: [],
      cropSubCategoryGroupArray: [],
      checkedCropNameArray: []
    }
  },
  methods: {
    handleSearch() {
      this.bindData()
    },
    handleChange() {
      this.checkedCropNameArray = []
      this.cropSubCategoryGroupArray.forEach(el => {
        el.checkedArray.forEach(el1 => {
          let cropSubCategory = this.cropSubCategoryArray.find(m => m.id === el1)
          if (undefined !== cropSubCategory)
            this.checkedCropNameArray.push(cropSubCategory.name)
        })
      })
    },
    handleClose() {
      this.checkedCropNameArray = []
      this.cropSubCategoryGroupArray = []
      this.dialogVisible = false
    },
    handleConfirm() {
      let checkedCropSubCategoryArray = []
      this.cropSubCategoryGroupArray.forEach(el => {
        el.checkedArray.forEach(el1 => {
          let cropSubCategory = this.cropSubCategoryArray.find(m => m.id === el1)
          let cropSubCategoryName = ''
          if (undefined !== cropSubCategory)
            cropSubCategoryName = cropSubCategory.name

          checkedCropSubCategoryArray.push({
            rootCategoryId: el.id,
            rootCategoryName: el.name,
            subCategoryId: el1,
            subCategoryName: cropSubCategoryName
          })
        })
      })

      this.$emit('confirm', checkedCropSubCategoryArray)
      this.dialogVisible = false
    },
    open() {
      this.bindData()
      this.dialogVisible = true
    },
    bindData() {
      this.cropSubCategoryGroupArray = []
      this.cropRootCategoryArray.forEach(el => {
        let cropSubCategoryGroup = { id: el.id, name: el.name, subCategoryArray: [], checkedArray: [] }
        this.cropSubCategoryArray.forEach(el1 => {
          if (this.$utils.isNotEmpty(this.keyword) && el1.name.indexOf(this.keyword) === -1)
            return

          if (el1.parentId === el.id)
            cropSubCategoryGroup.subCategoryArray.push({ id: el1.id, name: el1.name })
        })
        this.cropSubCategoryGroupArray.push(cropSubCategoryGroup)
        this.cropSubCategoryGroupActiveArray.push(el.name)
      })
    }
  }
}
</script>

<style>
.crop-select-collapse.el-collapse {
  border-top: 0;
  border-bottom: 0;
}

.crop-select-collapse.el-collapse .el-collapse-item__header {
  font-size: 14px;
  border-bottom: 0;
}

.crop-select-collapse.el-collapse .el-collapse-item__wrap {
  border-bottom: 0;
}
</style>

<style scoped>
.crop-filter {
  padding: 10px 0;
  border-bottom: 1px solid #ebeef5;
}

.crop-filter .crop-name {
  padding: 0 10px;
  color: #00a333;
}
</style>