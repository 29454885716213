<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/" class="bread_crumb">
        <el-breadcrumb-item :to="{ path: '/' }">数据填报</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/questionnaire' }">施肥问卷</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <el-container>
      <el-aside class="wg-side" :width="isCollapse ? '24px' : '240px'">
        <div v-show="!isCollapse">
          <div class="side-fence">
            施肥问卷
            <i class="el-icon-d-arrow-left collapse-button" @click="handleToggleCollapse"></i>
          </div>
          <el-tree ref="yearTree" :props="yearTreeProps" node-key="id" class="wg-tree" :expand-on-click-node="false" :load="loadNode" highlight-current lazy @node-click="handleNodeClick"></el-tree>
        </div>
        <div v-show="isCollapse" class="collapse-fence">
          展开菜单栏
          <div class="collapse-icon">
            <i class="el-icon-d-arrow-right" @click="handleToggleCollapse"></i>
          </div>
        </div>
      </el-aside>
      <el-main class="wg-main">
        <div class="wg-box">
          <div class="header">
            <div class="title">施肥问卷</div>
            <div class="wg-clear-fix"></div>
          </div>
          <div class="body">
            <div style="padding: 20px; margin-bottom: 20px">
              <el-form ref="form" :model="form" label-position="top">
                <el-form-item v-for="question in form.questionList" :label="question.name" :key="question.id" :prop="question.prop" :rules="question.ruleType">
                  <div style="padding: 0 10px 0 10px">
                    <el-radio-group v-model="question.reply.radioed" v-if="question.type === 'SINGLE_CHOICE'" :disabled="form.submitFlag || checkedItem.archiveFlag">
                      <el-radio v-for="answer in question.answerList" :label="answer.id" :key="answer.id">{{ answer.name }} </el-radio>
                    </el-radio-group>
                    <!-- radio group -->
                    <el-checkbox-group v-model="question.reply.checkedList" v-if="question.type === 'MULTIPLE_CHOICE'" :disabled="form.submitFlag || checkedItem.archiveFlag">
                      <el-checkbox v-for="answer in question.answerList" :label="answer.id" :key="answer.id" :ref="answer.id" @change="handleChkAnswerChange($event, answer.id, answer.extTextFlag)">
                        {{ answer.name }}&nbsp;
                        <el-input v-model="answer.extText" style="width: 150px" size="small" :disabled="answer.disabled || form.submitFlag || checkedItem.archiveFlag" v-if="answer.extTextFlag"></el-input>
                      </el-checkbox>
                    </el-checkbox-group>
                    <!-- checkbox group -->
                    <el-input v-model="question.reply.text" v-if="question.type === 'TEXT'" :disabled="form.submitFlag || checkedItem.archiveFlag"></el-input>
                    <!-- text -->
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="footer" v-if="!this.form.submitFlag && !this.checkedItem.archiveFlag">
            <el-button class="wg-button orange" @click="handleSubmit" v-if="this.form.id !== '' && this.form.id !== null">提 交 </el-button>
            &nbsp;&nbsp;
            <el-button class="wg-button" @click="handleSave">保 存</el-button>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import QuestionnaireApi from '@/api/questionnaire-api'
import FillInCycleApi from '@/api/data-fill-in/cycle/fill-in-cycle-api'

export default {
  name: 'Questionnaire',
  data() {
    return {
      isCollapse: false,
      yearTreeProps: {
        label: 'label',
        isLeaf: 'leaf'
      },
      form: {
        id: null,
        farmerId: null,
        submitFlag: null,
        questionList: []
      },
      formRules: {
        radioed: [{ required: true, message: '请选择选项！', trigger: 'change' }],
        checkedList: [{ type: 'array', required: true, message: '请至少选择一个选项！', trigger: 'change' }],
        text: [{ required: true, message: '请输入内容！', trigger: 'blur' }]
      },
      checkedItem: {
        year: null,
        archiveFlag: null
      }
    }
  },
  async created() {
    await QuestionnaireApi.getQuestionList().then(res => {
      let index = 0
      res.data.forEach(el => {
        let question = {
          id: el.id,
          name: el.name,
          type: el.type,
          answerList: [],
          reply: {
            radioed: null,
            checkedList: [],
            text: null
          },
          ruleType: null
        }
        if (el.type === 'SINGLE_CHOICE') {
          question.ruleType = this.formRules.radioed
          question.prop = 'questionList.' + index + '.reply.radioed'
        }
        if (el.type === 'MULTIPLE_CHOICE') {
          question.ruleType = this.formRules.checkedList
          question.prop = 'questionList.' + index + '.reply.checkedList'
        }
        if (el.type === 'TEXT') {
          question.ruleType = this.formRules.text
          question.prop = 'questionList.' + index + '.reply.text'
        }
        this.form.questionList.push(question)
        ++index

        el.answerList.forEach(elAnswer => {
          let answer = {
            id: elAnswer.id,
            name: elAnswer.name,
            extTextFlag: elAnswer.extTextFlag,
            extText: null,
            disabled: true
          }
          question.answerList.push(answer)
        })
      })
    })

    await FillInCycleApi.getLast().then(res => {
      this.checkedItem.year = res.data.year
      this.checkedItem.archiveFlag = res.data.archiveFlag
    })

    await this.checkExist()
  },
  methods: {
    handleToggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    handleNodeClick(node) {
      this.checkedItem.year = node.year
      this.checkedItem.archiveFlag = node.archiveFlag
      this.checkExist()
    },
    async loadNode(node, resolve) {
      let userLevelId = sessionStorage.getItem('levelId')
      // node is root
      if (node.level === 0) {
        let rootArray = []
        let fillInCycleList = []

        await FillInCycleApi.getList().then(res => {
          fillInCycleList = res.data
        })

        fillInCycleList.forEach(el => {
          let tempNode = {
            id: el.year,
            type: userLevelId,
            label: null,
            leaf: true,
            year: el.year,
            archiveFlag: el.archiveFlag,
            prefix: '|'
          }

          tempNode.label = el.archiveFlag ? el.year + '年（已归档）' : el.year + '年'

          rootArray.push(tempNode)
        })

        this.$nextTick(() => {
          this.$refs.regionTree.setCurrentKey(rootArray[0].id)
        })

        return resolve(rootArray)
      }
    },
    handleSubmit() {
      this.$confirm('此操作将提交该数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.form.id === null || this.form.id === '') {
            this.$message({ showClose: true, message: '请先保存数据！', type: 'warning' })
            return
          }
          this.submit()
        })
        .catch(() => {
          this.$message({ showClose: true, type: 'info', message: '已取消操作！' })
        })
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return

        let replyModelList = []
        this.form.questionList.forEach(elQuestion => {
          if (elQuestion.type === 'SINGLE_CHOICE') {
            let replyMode = {
              questionId: elQuestion.id,
              answerId: elQuestion.reply.radioed
            }
            replyModelList.push(replyMode)
          }
          if (elQuestion.type === 'MULTIPLE_CHOICE') {
            let tempAnswerArray = []
            elQuestion.answerList.forEach(elAnswer => {
              tempAnswerArray[elAnswer.id] = elAnswer
            })
            elQuestion.reply.checkedList.forEach(el => {
              let replyMode = {
                questionId: elQuestion.id,
                answerId: el,
                extText: null
              }
              let tempAnswer = tempAnswerArray[el]
              if (tempAnswer.extTextFlag) replyMode.extText = tempAnswer.extText
              replyModelList.push(replyMode)
            })
          }
          if (elQuestion.type === 'TEXT') {
            let replyMode = {
              questionId: elQuestion.id,
              answerId: elQuestion.answerList[0].id
            }
            replyModelList.push(replyMode)
          }
        })

        if (null == this.form.id) {
          let createModel = {
            year: this.checkedItem.year,
            farmerId: this.form.farmerId,
            replyList: replyModelList
          }
          QuestionnaireApi.create(createModel)
            .then(res => {
              this.$message({ type: 'success', message: '保存成功！' })
              this.bindFormData(res.data)
            })
            .catch(err => {
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        } else {
          let updateModel = {
            id: this.form.id,
            year: this.checkedItem.year,
            farmerId: this.form.farmerId,
            replyList: replyModelList
          }
          QuestionnaireApi.update(updateModel)
            .then(() => {
              this.$message({ type: 'success', message: '保存成功！' })
              this.bindFormData(this.form.id)
            })
            .catch(err => {
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        }
      })
    },
    handleChkAnswerChange(checked, answerId, extTextFlag) {
      if (extTextFlag) {
        this.form.questionList.forEach(elQuestion => {
          elQuestion.answerList.forEach(elAnswer => {
            if (elAnswer.id === answerId) {
              elAnswer.disabled = !checked
              if (checked) {
                elAnswer.disabled = false
              } else {
                elAnswer.disabled = true
                elAnswer.extText = null
              }
            }
          })
        })
      }
    },
    checkExist() {
      QuestionnaireApi.exitQuestionnaire(this.checkedItem.year)
        .then(res => {
          let id = res.data
          this.bindFormData(id)
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async submit() {
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading' })
      await QuestionnaireApi.submit(this.form.id)
        .then(() => {
          this.$message({ showClose: true, message: '提交成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
        .finally(() => {
          loading.close()
        })

      await this.bindFormData(this.form.id)
    },
    async bindFormData(id) {
      if (null === id || '' === id) {
        this.form.id = null
        this.form.farmerId = null
        this.form.submitFlag = false
        this.form.questionList.forEach(elQuestion => {
          elQuestion.reply.radioed = null
          elQuestion.reply.checkedList = []
          elQuestion.reply.text = null
          elQuestion.answerList.forEach(elAnswer => {
            elAnswer.extText = null
            elAnswer.disabled = true
          })
        })
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
        return
      }

      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading' })
      await QuestionnaireApi.getById(id)
        .then(res => {
          this.form.id = res.data.id
          this.checkedItem.year = res.data.year
          this.form.farmerId = res.data.farmerId
          this.form.submitFlag = res.data.submitFlag
          let replyArray = []
          res.data.replyList.forEach(el => {
            replyArray[el.answerId] = el
          })
          this.form.questionList.forEach(elQuestion => {
            elQuestion.reply.radioed = null
            elQuestion.reply.checkedList = []
            elQuestion.reply.text = null
            elQuestion.answerList.forEach(elAnswer => {
              elAnswer.extText = null
              elAnswer.disabled = true
              let reply = replyArray[elAnswer.id]
              if (undefined !== reply) {
                if (elQuestion.type === 'SINGLE_CHOICE') {
                  elQuestion.reply.radioed = reply.answerId
                  elAnswer.extText = reply.extText
                } else if (elQuestion.type === 'MULTIPLE_CHOICE') {
                  elQuestion.reply.checkedList.push(reply.answerId)
                  if (elAnswer.extTextFlag) {
                    elAnswer.disabled = false
                    elAnswer.extText = reply.extText
                  }
                } else if (elQuestion.type === 'TEXT') {
                  elAnswer.reply.text = reply.extText
                }
              }
            })
          })
          loading.close()
          this.$nextTick(() => {
            this.$refs.form.clearValidate()
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped></style>
