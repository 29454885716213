<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/" class="bread_crumb">
        <el-breadcrumb-item :to="{ path: '/plot' }">地块管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <div v-loading="flags.dataLoading" element-loading-text="拼命加载数据......" element-loading-spinner="el-icon-loading">
      <div class="wg-box">
        <div class="header" style="padding-top: 0px">
          <el-tabs class="wg-tabs" v-model="tabActivedName" @tab-click="handleTabsClick">
            <el-tab-pane name="all" label="正常"></el-tab-pane>
            <el-tab-pane name="disabled" label="停用"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="body">
          <div style="max-width: 1280px">
            <el-form :inline="true" :model="searchForm" size="small" label-width="100px">
              <el-form-item label="选择省份：" v-if="flags.provinceSelectShow">
                <el-select v-model="searchForm.provinceId" @change="handleProvinceSelect">
                  <el-option v-for="option in searchForm.filterData.provinceOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择地市：" v-if="flags.citySelectShow">
                <el-select v-model="searchForm.cityId" @change="handleCitySelect" v-bind:disabled="flags.citySelectDisable">
                  <el-option v-for="option in searchForm.filterData.cityOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择区县：" v-if="flags.districtSelectShow">
                <el-select v-model="searchForm.districtId" v-bind:disabled="flags.districtSelectDisable">
                  <el-option v-for="option in searchForm.filterData.districtOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择级别：" v-if="flags.userLevelShow">
                <el-select v-model="searchForm.userLevelId">
                  <el-option v-for="option in searchForm.filterData.userLevelOptionList" :key="option.value" :label="option.text" :value="option.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="关键字：">
                <el-input v-model="searchForm.keyword"></el-input>
              </el-form-item>
              <el-form-item label="建站时间：">
                <el-date-picker v-model="searchForm.buildTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :default-time="['00:00:00', '00:00:00']"></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button class="wg-button" @click="handleSearch">搜索</el-button>
                <el-button class="wg-button blank" @click="handleReset">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <div class="wg-box">
        <div class="header">
          <div class="button-list">
            <el-button size="small" class="wg-button green" v-if="!searchForm.disableFlag" icon="el-icon-plus" @click="handleAdd">新增 </el-button>
            <el-button size="small" class="wg-button red" v-if="!searchForm.disableFlag" icon="el-icon-video-pause" @click="handleDisableSelected">停用 </el-button>
            <el-button size="small" class="wg-button green" v-if="searchForm.disableFlag" icon="el-icon-video-play" @click="handleEnableSelected">恢复 </el-button>
            <el-button size="small" class="wg-button red" v-if="!searchForm.disableFlag" icon="el-icon-delete" @click="handleDeleteSelected">删除 </el-button>
            <el-button size="small" class="wg-button red" v-if="searchForm.disableFlag" icon="el-icon-delete" @click="handleDeleteSelectedWithoutPlantCrop">删除 </el-button>
            <el-popover placement="bottom-start" width="450" trigger="hover" style="margin-left: 10px">
              <ul class="wg-popover-content red">
                <li>当年系统内某一地块如有任何施肥填报记录，则不可停用该地块；如确需停用，请先删除在该地块上填报过的所有施肥信息。</li>
                <li>当年系统内某一地块如没有填报过施肥信息，则可删除该地块。</li>
                <li>当年系统内某一地块填报过施肥信息且没有提交到上一级，如需删除，则将删除在该地块上填报过的所有施肥信息。</li>
                <li>当年系统内某一地块如有已提交的施肥填报记录，则不可删除该地块。</li>
              </ul>
              <el-button slot="reference" size="small" class="wg-button orange" icon="el-icon-question">操作提示 </el-button>
            </el-popover>
          </div>
          <div class="wg-clear-fix"></div>
        </div>
        <div class="body">
          <div class="wg-table-box">
            <el-table :data="tableData" :max-height="600" row-key="id" @selection-change="handleSelectionChange" class="wg-table normal" border>
              <el-table-column type="selection" width="50"></el-table-column>
              <el-table-column label="序号" prop="index" width="80" fixed></el-table-column>
              <el-table-column label="省份" prop="provinceName" width="120"></el-table-column>
              <el-table-column label="地市" prop="cityName" width="120"></el-table-column>
              <el-table-column label="区县" prop="districtName" width="120"></el-table-column>
              <el-table-column label="详细位置" prop="detailedAddress"></el-table-column>
              <el-table-column label="地块代码" prop="code" width="120"></el-table-column>
              <el-table-column label="地块名称" prop="name" width="120"></el-table-column>
              <el-table-column label="所属用户" prop="userFullName" width="120"></el-table-column>
              <el-table-column label="手机号码" prop="userPhoneNumber" width="120"></el-table-column>
              <el-table-column label="建立时间" prop="buildTime" width="150"></el-table-column>
              <el-table-column label="地块面积（亩）" prop="plotArea" width="120"></el-table-column>
              <el-table-column label="管理人" prop="managerFullName" width="120"></el-table-column>
              <el-table-column label="手机号码" prop="managerPhoneNumber" width="120"></el-table-column>
              <el-table-column v-if="searchForm.disableFlag" label="停用时间" prop="disabledOn" width="160"></el-table-column>
              <el-table-column v-if="searchForm.disableFlag" label="停用人" prop="disabledByFullName" width="100"></el-table-column>
              <el-table-column label="创建时间" prop="createdOn" width="160"></el-table-column>
              <el-table-column label="创建人" prop="createdByFullName" width="100"></el-table-column>
              <el-table-column label="修改时间" prop="modifiedOn" width="160"></el-table-column>
              <el-table-column label="修改人" prop="modifiedByFullName" width="100"></el-table-column>
              <el-table-column label="操作" fixed="right" width="80">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="wg-pagination-box">
            <el-pagination background layout="total, sizes, prev, pager, next" :total="recordTotal" :page-sizes="[30, 50, 100]" :page-size="pageSize" :current-page="pageIndex" @size-change="handleSizeChange" @current-change="handlePagingChange"></el-pagination>
          </div>
        </div>
      </div>
      <!-- table data -->
    </div>

    <component ref="createDialog" v-bind:is="slots.createDialog" @close="handleCreateDialogClose" @create-success="handleCreateSuccess()"></component>
    <component ref="modifyDialog" v-bind:is="slots.modifyDialog" @close="handleModifyDialogClose" @update-success="handleUpdateSuccess()"></component>
  </div>
</template>

<script>
import MonitoringPlotApi from '@/api/monitoring-plot-api'

import PlotCreateDialog from './PlotCreateDialog'
import PlotModifyDialog from './PlotModifyDialog'
import RegionApi from '@/api/region-api'

export default {
  name: 'PlotManage',
  components: {
    'plot-create-dialog': PlotCreateDialog,
    'plot-modify-dialog': PlotModifyDialog
  },
  data() {
    return {
      tabActivedName: 'all',
      slots: {
        createDialog: null,
        modifyDialog: null
      },
      flags: {
        dataLoading: false,
        provinceSelectShow: false,
        citySelectShow: false,
        districtSelectShow: false,
        userLevelShow: false,
        allowDeletePlot: false
      },
      searchForm: {
        filterData: {
          provinceOptionList: [],
          cityOptionList: [],
          districtOptionList: [],
          userLevelOptionList: []
        },
        disableFlag: false,
        provinceId: null,
        cityId: null,
        districtId: null,
        userLevelId: null,
        keyword: null,
        buildTime: []
      },
      tableData: [],
      recordTotal: 0,
      pageSize: 30,
      pageIndex: 1,
      selectedRows: [],
      editRow: null
    }
  },
  created() {
    let userLevelId = sessionStorage.getItem('levelId')
    this.flags.allowDeletePlot = 'Country' === userLevelId || 'Province' === userLevelId || 'Professional' == userLevelId
    this.flags.userLevelShow = 'Country' == userLevelId

    this.searchForm.filterData.provinceOptionList.push({ text: '全部', value: null })
    this.searchForm.filterData.cityOptionList.push({ text: '全部', value: null })
    this.searchForm.filterData.districtOptionList.push({ text: '全部', value: null })

    if ('Country' === userLevelId || 'Professional' === userLevelId) {
      this.flags.provinceSelectShow = true
      this.flags.citySelectShow = true
      this.flags.districtSelectShow = true
      RegionApi.getProvinceList().then(res => {
        res.data.forEach(el => {
          this.searchForm.filterData.provinceOptionList.push({ text: el.name, value: el.id })
        })
      })
    }

    if ('Province' === userLevelId) {
      let provinceId = sessionStorage.getItem('provinceId')
      this.flags.citySelectShow = true
      this.flags.districtSelectShow = true
      this.searchForm.provinceId = provinceId
      RegionApi.getCityList(provinceId).then(res => {
        res.data.forEach(el => {
          this.searchForm.filterData.cityOptionList.push({ text: el.name, value: el.id })
        })
      })
    }

    if ('City' === userLevelId) {
      let provinceId = sessionStorage.getItem('provinceId')
      let cityId = sessionStorage.getItem('cityId')
      this.flags.districtSelectShow = true
      this.searchForm.provinceId = provinceId
      this.searchForm.cityId = cityId
      RegionApi.getDistrictList(cityId).then(res => {
        res.data.forEach(el => {
          this.searchForm.filterData.districtOptionList.push({ text: el.name, value: el.id })
        })
      })
    }

    if ('District' === userLevelId || 'Farmer' === userLevelId) {
      this.searchForm.provinceId = sessionStorage.getItem('provinceId')
      this.searchForm.cityId = sessionStorage.getItem('cityId')
      this.searchForm.districtId = sessionStorage.getItem('districtId')
    }

    this.searchForm.filterData.userLevelOptionList.push({ text: '全部', value: null })
    this.searchForm.filterData.userLevelOptionList.push({ text: '农户', value: 'Farmer' })
    this.searchForm.filterData.userLevelOptionList.push({ text: '专业', value: 'Professional' })

    this.bindTableData()
  },
  methods: {
    handleTabsClick(tab) {
      switch (tab.name) {
        case 'disabled':
          this.pageIndex = 1
          this.searchForm.disableFlag = true
          break
        default:
          this.pageIndex = 1
          this.searchForm.disableFlag = false
          break
      }
      this.resetFilters()
      this.bindTableData()
    },
    handleProvinceSelect(selectedValue) {
      this.searchForm.districtId = null
      this.searchForm.cityId = null
      this.searchForm.filterData.districtOptionList = []
      this.searchForm.filterData.districtOptionList.push({ text: '全部', value: null })
      this.searchForm.filterData.cityOptionList = []
      this.searchForm.filterData.cityOptionList.push({ text: '全部', value: null })
      if (null == selectedValue) return

      RegionApi.getCityList(selectedValue)
        .then(res => {
          res.data.forEach(el => {
            this.searchForm.filterData.cityOptionList.push({ text: el.name, value: el.id })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    handleCitySelect(selectedValue) {
      this.searchForm.districtId = null
      this.searchForm.filterData.districtOptionList = []
      this.searchForm.filterData.districtOptionList.push({ text: '全部', value: null })
      if (null == selectedValue) return

      RegionApi.getDistrictList(selectedValue)
        .then(res => {
          res.data.forEach(el => {
            this.searchForm.filterData.districtOptionList.push({ text: el.name, value: el.id })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    handleSearch() {
      this.pageIndex = 1
      this.bindTableData()
    },
    handleReset() {
      this.pageIndex = 1
      this.resetFilters()
      this.bindTableData()
    },
    handleSizeChange(pageSize) {
      this.pageIndex = 1
      this.pageSize = pageSize
      this.bindTableData()
    },
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
      this.bindTableData()
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows
    },
    handleAdd() {
      this.slots.createDialog = 'plot-create-dialog'
      this.$nextTick(() => {
        this.$refs.createDialog.open()
      })
    },
    handleEdit(row) {
      this.editRow = row
      this.slots.modifyDialog = 'plot-modify-dialog'
      this.$nextTick(() => {
        this.$refs.modifyDialog.open(row.id, row.index)
      })
    },
    handleEnableSelected() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      this.$confirm('此操作恢复选中的地块, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          for (let i = 0; i < this.selectedRows.length; i++) {
            await this.enable(this.selectedRows[i])
          }
          this.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消操作！', type: 'info' })
        })
    },
    handleDisableSelected() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      this.$confirm('此操作停用选中的地块, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          for (let i = 0; i < this.selectedRows.length; i++) {
            await this.disable(this.selectedRows[i])
          }
          this.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消操作！', type: 'info' })
        })
    },
    handleDeleteSelected() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      this.$confirm('此操作删除选中的地块以及该地块上填报过的所有施肥信息, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          for (let i = 0; i < this.selectedRows.length; i++) {
            await this.delete(this.selectedRows[i])
          }
          this.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    handleDeleteSelectedWithoutPlantCrop() {
      if (0 === this.selectedRows.length) {
        this.$message({ showClose: true, message: '请选择记录！', type: 'warning' })
        return
      }

      this.$confirm('此操作删除选中的地块, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          for (let i = 0; i < this.selectedRows.length; i++) {
            await this.deleteWithoutPlantCrop(this.selectedRows[i])
          }
          this.bindTableData()
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    handleCreateDialogClose() {
      this.slots.createDialog = null
    },
    handleCreateSuccess() {
      this.bindTableData()
    },
    handleModifyDialogClose() {
      this.slots.modifyDialog = null
      this.editRow = null
    },
    handleUpdateSuccess() {
      let row = this.editRow
      this.editRow = null
      MonitoringPlotApi.getById(row.id)
        .then(res => {
          let model = res.data
          row.detailedAddress = model.detailedAddress
          row.name = model.name
          row.buildTime = this.$moment(model.buildTime).format('YYYY-MM-DD')
          row.longitude = model.longitude
          row.latitude = model.latitude
          row.plotArea = model.plotArea
          row.userFullName = model.farmerFullName
          row.userPhoneNumber = model.farmerPhoneNumber
          row.managerFullName = model.managerFullName
          row.managerPhoneNumber = model.managerPhoneNumber
          row.createdOn = model.createdOn
          row.modifiedOn = model.modifiedOn
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    resetFilters() {
      let userLevelId = sessionStorage.getItem('levelId')
      if ('Country' === userLevelId) {
        this.searchForm.districtId = null
        this.searchForm.cityId = null
        this.searchForm.provinceId = null
        this.searchForm.filterData.districtOptionList = []
        this.searchForm.filterData.districtOptionList.push({ text: '全部', value: null })
        this.searchForm.filterData.cityOptionList = []
        this.searchForm.filterData.cityOptionList.push({ text: '全部', value: null })
      }
      if ('Province' === userLevelId) {
        this.searchForm.districtId = null
        this.searchForm.cityId = null
        this.searchForm.filterData.districtOptionList = []
        this.searchForm.filterData.districtOptionList.push({ text: '全部', value: null })
      }
      if ('City' === userLevelId) {
        this.searchForm.districtId = null
      }
      this.searchForm.userLevelId = null
      this.searchForm.keyword = null
      this.searchForm.buildTime = []
    },
    bindTableData() {
      this.flags.dataLoading = true
      let searchParamModel = {}
      searchParamModel.disableFlag = this.searchForm.disableFlag
      if (null !== this.searchForm.districtId) searchParamModel.regionId = this.searchForm.districtId
      else if (null !== this.searchForm.cityId) searchParamModel.regionId = this.searchForm.cityId
      else if (null !== this.searchForm.provinceId) searchParamModel.regionId = this.searchForm.provinceId

      searchParamModel.userLevelId = this.searchForm.userLevelId
      searchParamModel.keyword = this.searchForm.keyword

      if (this.searchForm.buildTime.length === 2) {
        searchParamModel.startDate = this.searchForm.buildTime[0] + ' 00:00:00'
        searchParamModel.endDate = this.searchForm.buildTime[1] + ' 00:00:00'
      }

      MonitoringPlotApi.searchPaging(searchParamModel, this.pageSize, this.pageIndex)
        .then(res => {
          let index = 0
          this.tableData = []
          this.recordTotal = res.data.totalCount
          res.data.monitoringPlotList.forEach(el => {
            this.tableData.push({
              index: ++index,
              id: el.id,
              provinceName: el.provinceName,
              cityName: el.cityName,
              districtName: el.districtName,
              detailedAddress: el.detailedAddress,
              code: el.code,
              name: el.name,
              buildTime: this.$moment(el.buildTime).format('YYYY-MM-DD'),
              longitude: el.longitude,
              latitude: el.latitude,
              plotArea: el.plotArea,
              userFullName: el.farmerFullName,
              userPhoneNumber: el.farmerPhoneNumber,
              managerFullName: el.managerFullName,
              managerPhoneNumber: el.managerPhoneNumber,
              disabledOn: el.disabledOn,
              disabledByFullName: el.disabledByFullName,
              createdOn: el.createdOn,
              createdByFullName: el.createdByFullName,
              modifiedOn: el.modifiedOn,
              modifiedByFullName: el.modifiedByFullName
            })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
        .finally(() => {
          this.flags.dataLoading = false
        })
    },
    async delete(row) {
      await MonitoringPlotApi.deleteById(row.id)
        .then(() => {
          this.$message({ showClose: true, message: '地块【' + row.code + '】删除成功！', type: 'success' })
        })
        .catch(err => {
          if (err.data.code === 'MONITORING_PLOT_DELETING_FAILURE') {
            this.$message({
              showClose: true,
              message: '该地块【' + row.code + '】当年度已提交过施肥信息，当年度不可删除该地块！',
              type: 'error'
            })
          } else {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          }
        })
    },
    async deleteWithoutPlantCrop(row) {
      await MonitoringPlotApi.deleteById(row.id)
        .then(() => {
          this.$message({ showClose: true, message: '地块【' + row.code + '】删除成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({
            showClose: true,
            message: '【' + err.data.code + '】' + err.data.message + '请联系统管理员！谢谢！',
            type: 'error'
          })
        })
    },
    async enable(row) {
      await MonitoringPlotApi.enable(row.id)
        .then(() => {
          this.$message({ showClose: true, message: '地块【' + row.code + '】启用成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async disable(row) {
      await MonitoringPlotApi.disable(row.id)
        .then(() => {
          this.$message({ showClose: true, message: '地块【' + row.code + '】停用成功！', type: 'success' })
        })
        .catch(err => {
          if (err.data.code === 'MONITORING_PLOT_DISABLING_FAILURE') {
            this.$message({
              showClose: true,
              message: '该地块【' + row.code + '】当年度已填有施肥信息，如需停用，请先删除在该地块上当年已填报过的所有施肥记录！',
              type: 'error'
            })
          } else {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          }
        })
    }
  }
}
</script>

<style scoped></style>
