<template>
  <div ref="assistFillInDataAddPane" v-loading="dataLoading" element-loading-text="正在加载数据......" element-loading-spinner="el-icon-loading">
    <div class="wg-box">
      <div class="header">
        <div class="button-list">
          <el-button size="small" class="wg-button orange" icon="el-icon-upload" @click="handleSave">保存</el-button>
          <el-button size="medium" class="wg-button red" icon="el-icon-close" @click="handleCancel">取消</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-full-screen" v-if="!isFullscreen" @click="handleFullscreen">全屏</el-button>
          <el-button size="medium" class="wg-button blue" icon="el-icon-full-screen" v-if="isFullscreen" @click="handleExtFullscreen">退出</el-button>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <div class="body">
        <div class="wg-table-box">
          <ux-grid ref="fillDataTable" rowId="PC_Id" height="600" keep-source :edit-config="tableEditConfig" :validConfig="{autoPos: true}" :rules="validRules" @edit-actived="handleTableEditActived">
            <ux-table-column type="index" width="50" :align="tableColumnAlign" fixed="left"></ux-table-column>
            <ux-table-column title="用户名称" width="120" fixed="left" :align="tableColumnAlign" field="UA_Username"></ux-table-column>
            <ux-table-column title="农户姓名" width="120" fixed="left" :align="tableColumnAlign" field="UA_FullName"></ux-table-column>
            <ux-table-column title="农户信息" :align="tableColumnAlign" :size="editComponentSize">
              <ux-table-column title="省份" width="120" :align="tableColumnAlign" field="UA_ProvinceName"></ux-table-column>
              <ux-table-column title="地市" width="120" :align="tableColumnAlign" field="UA_CityName"></ux-table-column>
              <ux-table-column title="区县" width="120" :align="tableColumnAlign" field="UA_DistrictName"></ux-table-column>
              <ux-table-column title="详细地址" width="200" :align="tableColumnAlign" field="UA_DetailedAddress"></ux-table-column>
              <ux-table-column title="电话" width="120" :align="tableColumnAlign" field="UA_PhoneNumber"></ux-table-column>
              <ux-table-column title="农户性质" width="120" :align="tableColumnAlign" field="UA_FarmerNature">
                <template v-slot:default="{row}">
                  <span v-if="row.UA_FarmerNature === 'COMMON_PLANTER'" class="elx-cell--label">普通种植户</span>
                  <span v-if="row.UA_FarmerNature === 'LARGE_PLANTER'" class="elx-cell--label">种植大户</span>
                  <span v-if="row.UA_FarmerNature === 'FAMILY_FARM'" class="elx-cell--label">家庭农场</span>
                  <span v-if="row.UA_FarmerNature === 'SPECIALIZED_COOPERATIVE'" class="elx-cell--label">专业合作社</span>
                </template>
              </ux-table-column>
              <ux-table-column title="文化程度" width="120" :align="tableColumnAlign" field="UA_EducationalLevel">
                <template v-slot:default="{row}">
                  <span v-if="row.UA_EducationalLevel === 'PRIMARY_SCHOOL'" class="elx-cell--label">小学</span>
                  <span v-if="row.UA_EducationalLevel === 'JUNIOR_HIGH_SCHOOL'" class="elx-cell--label">初中</span>
                  <span v-if="row.UA_EducationalLevel === 'TECHNICAL_SECONDARY_SCHOOL'" class="elx-cell--label">中专/中技</span>
                  <span v-if="row.UA_EducationalLevel === 'SENIOR_HIGH_SCHOOL'" class="elx-cell--label">高中</span>
                  <span v-if="row.UA_EducationalLevel === 'JUNIOR_COLLEGE'" class="elx-cell--label">大专</span>
                  <span v-if="row.UA_EducationalLevel === 'UNDERGRADUATE'" class="elx-cell--label">本科</span>
                  <span v-if="row.UA_EducationalLevel === 'GRADUATE'" class="elx-cell--label">研究生</span>
                </template>
              </ux-table-column>
              <ux-table-column title="经营面积（含园地，亩）" width="150" :align="tableColumnAlign" field="UA_CultivationLandArea">
                <template v-slot:header>
                  经营面积<br />（含园地，亩）
                </template>
              </ux-table-column>
              <ux-table-column title="示范户" width="120" :align="tableColumnAlign" field="UA_ModelHouseholdFlag">
                <template v-slot:default="{row}">
                  <span v-if="row.UA_ModelHouseholdFlag" class="elx-cell--label">是</span>
                  <span v-else class="elx-cell--label">否</span>
                </template>
              </ux-table-column>
            </ux-table-column>
            <!-- 农户信息 -->
            <ux-table-column title="地块信息" width="120" :align="tableColumnAlign">
              <ux-table-column title="地块名称" width="120" :align="tableColumnAlign" field="MP_Id" edit-render>
                <template v-slot:default="{row}">
                  <span class="elx-cell--label">{{ row.MP_Name }}</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.MP_Id" :size="editComponentSize" :disabled="scope.row.filterData.plotOptionList.length === 0" @change="handleMPPlotChange($event, scope)">
                    <el-option v-for="item in scope.row.filterData.plotOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="地块代码" width="120" :align="tableColumnAlign" field="MP_Code"></ux-table-column>
              <ux-table-column title="地块面积（亩）" width="120" :align="tableColumnAlign" field="MP_Area">
                <template v-slot:header>地块面积<br />（亩）</template>
              </ux-table-column>
            </ux-table-column>
            <!-- 地块信息 -->
            <ux-table-column title="种植信息" :align="tableColumnAlign">
              <ux-table-column title="作物名称" width="120" :align="tableColumnAlign" field="PC_CropId" edit-render>
                <template v-slot:default="{row}">
                  <span class="elx-cell--label">{{ row.PC_CropName }}</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.PC_CropId" :size="editComponentSize" @change="handlePCCropChange($event, scope)">
                    <el-option-group v-for="group in cropOptionGroupList" :key="group.label" :label="group.label">
                      <el-option v-for="option in group.optionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-option-group>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="作物类型" width="120" :align="tableColumnAlign" field="PC_CropType" edit-render>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.PC_CropType" :size="editComponentSize"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="播种日期" width="160" :align="tableColumnAlign" field="PC_SowingDate" edit-render>
                <template v-slot:edit="scope">
                  <el-date-picker v-model="scope.row.PC_SowingDate" type="date" value-format="yyyy-MM-dd" :size="editComponentSize" placeholder="选择日期" style="width: 140px"></el-date-picker>
                </template>
              </ux-table-column>
              <ux-table-column title="收获日期" width="160" :align="tableColumnAlign" field="PC_HarvestTime" edit-render>
                <template v-slot:edit="scope">
                  <el-date-picker v-model="scope.row.PC_HarvestTime" type="date" value-format="yyyy-MM-dd" :size="editComponentSize" placeholder="选择日期" style="width: 140px"></el-date-picker>
                </template>
              </ux-table-column>
              <ux-table-column title="播种面积（亩）" width="120" :align="tableColumnAlign" field="PC_SowingArea" edit-render>
                <template v-slot:header>
                  播种面积<br />（亩）
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.PC_SowingArea" :size="editComponentSize"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="地块肥力" width="120" :align="tableColumnAlign" field="PC_PlotFertility" edit-render>
                <template v-slot:default="{row}">
                  <span v-if="row.PC_PlotFertility === 'HIGH'" class="elx-cell--label">高</span>
                  <span v-if="row.PC_PlotFertility === 'MIDDLE'" class="elx-cell--label">中</span>
                  <span v-if="row.PC_PlotFertility === 'LOW'" class="elx-cell--label">低</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.PC_PlotFertility" :size="editComponentSize" @change="$refs.fillDataTable.updateStatus(scope)">
                    <el-option v-for="item in plotFertilityOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="亩产量（公斤/亩）" width="120" :align="tableColumnAlign" field="PC_Yield" edit-render>
                <template v-slot:header>
                  亩产量<br />（公斤/亩）
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.PC_Yield" :size="editComponentSize"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="是否设施栽培" width="120" :align="tableColumnAlign" field="PC_FacilityCultivationFlag" edit-render>
                <template v-slot:header>
                  是否<br />设施栽培
                </template>
                <template v-slot:default="{row}">
                  <span v-if="row.PC_FacilityCultivationFlag === true" class="elx-cell--label">是</span>
                  <span v-else-if="row.PC_FacilityCultivationFlag === false" class="elx-cell--label">否</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.PC_FacilityCultivationFlag" :size="editComponentSize" clearable @clear="handlePCFacilityCultivationFlagClear(scope)">
                    <el-option v-for="item in yesAndNoOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="是否种植季受灾" width="120" :align="tableColumnAlign" field="PC_HappenDisasterFlag" edit-render>
                <template v-slot:header>
                  是否<br />种植季受灾
                </template>
                <template v-slot:default="{row}">
                  <span v-if="row.PC_HappenDisasterFlag === true" class="elx-cell--label">是</span>
                  <span v-else-if="row.PC_HappenDisasterFlag === false" class="elx-cell--label">否</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.PC_HappenDisasterFlag" :size="editComponentSize" clearable @clear="handlePCHappenDisasterFlagClear(scope)">
                    <el-option v-for="item in yesAndNoOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="前茬作物" width="120" :align="tableColumnAlign" field="PC_PreviousCropId" edit-render>
                <template v-slot:default="{row}">
                  <span class="elx-cell--label">
                    {{ row.PC_PreviousCropName }}
                  </span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.PC_PreviousCropId" :size="editComponentSize" @change="handlePCPreviousCropChange($event, scope)">
                    <el-option-group v-for="group in previousCropOptionGroupList" :key="group.label" :label="group.label">
                      <el-option v-for="option in group.optionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-option-group>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="前茬作物亩产量（公斤/亩）" width="120" :align="tableColumnAlign" field="PC_PreviousYield" edit-render>
                <template v-slot:header>
                  前茬作物<br />亩产量<br />（公斤/亩）
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.PC_PreviousYield" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.PC_PreviousCropId) || scope.row.PC_PreviousCropId === 'NONE'"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="前茬秸秆还田率（%）" width="120" :align="tableColumnAlign" field="PC_PreviousStrawReturnRate" edit-render>
                <template v-slot:header>
                  前茬秸秆<br />还田率<br />（%）
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.PC_PreviousStrawReturnRate" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.PC_PreviousCropId) || scope.row.PC_PreviousCropId === 'NONE'"></el-input>
                </template>
              </ux-table-column>
            </ux-table-column>
            <!-- 种植信息 -->
            <ux-table-column title="底肥（公斤/亩）" :align="tableColumnAlign">
              <ux-table-column title="底肥施肥方式" width="120" :align="tableColumnAlign" field="BF_FertilizationMode" edit-render>
                <template v-slot:header>
                  底肥<br />施肥方式
                </template>
                <template v-slot:default="{row}">
                  <span v-if="row.BF_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                  <span v-if="row.BF_FertilizationMode === 'MODE2'" class="elx-cell--label">机械深施</span>
                  <span v-if="row.BF_FertilizationMode === 'MODE3'" class="elx-cell--label">种肥同播</span>
                  <span v-if="row.BF_FertilizationMode === 'MODE4'" class="elx-cell--label">条施</span>
                  <span v-if="row.BF_FertilizationMode === 'MODE5'" class="elx-cell--label">穴施</span>
                  <span v-if="row.BF_FertilizationMode === 'MODE7'" class="elx-cell--label">无人机施肥</span>
                  <span v-if="row.BF_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.BF_FertilizationMode" :size="editComponentSize" clearable @clear="handleBFFertilizationModeClear(scope)">
                    <el-option v-for="item in baseFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="施肥日期" width="150" :align="tableColumnAlign" field="BF_FertilizationDate" edit-render>
                <template v-slot:edit="scope">
                  <el-date-picker v-model="scope.row.BF_FertilizationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)"></el-date-picker>
                </template>
              </ux-table-column>
              <ux-table-column title="商用有机肥" :align="tableColumnAlign">
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_BusinessOrganicFertilizerDosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" @change="handleBFFieldChange($event, scope, 'BusinessOrganicFertilizerDosage')"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column width="120" :align="tableColumnAlign" field="BF_BusinessOrganicFertilizerPrice" edit-render>
                  <template v-slot:header>
                    价格<br />（元/吨）
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_BusinessOrganicFertilizerPrice" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_BusinessOrganicFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="农家肥1（干重）" :align="tableColumnAlign">
                <ux-table-column title="名称" width="120" :align="tableColumnAlign" field="BF_OrganicFertilizer1Id" edit-render>
                  <template v-slot:default="{row}">
                    <span class="elx-cell--label">
                      {{ row.BF_OrganicFertilizer1Name }}
                    </span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.BF_OrganicFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" clearable @change="handleBFFieldChange($event, scope, 'OrganicFertilizer1')">
                      <el-option v-for="option in organicFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_OrganicFertilizer1Dosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_OrganicFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_OrganicFertilizer1Id)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="农家肥2（干重）" :align="tableColumnAlign">
                <ux-table-column title="名称" width="120" :align="tableColumnAlign" field="BF_OrganicFertilizer2Id" edit-render>
                  <template v-slot:default="{row}">
                    <span class="elx-cell--label">
                      {{ row.BF_OrganicFertilizer2Name }}
                    </span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.BF_OrganicFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" clearable @change="handleBFFieldChange($event, scope, 'OrganicFertilizer2')">
                      <el-option v-for="option in organicFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_OrganicFertilizer2Dosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_OrganicFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_OrganicFertilizer2Id)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="尿素用量" width="100" :align="tableColumnAlign" field="BF_UreaDosage" edit-render>
                <template v-slot:header>
                  尿素<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.BF_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="碳铵用量" width="100" :align="tableColumnAlign" field="BF_AmmoniumBicarbonateDosage" edit-render>
                <template v-slot:header>
                  碳铵<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.BF_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="磷酸二铵用量" width="100" :align="tableColumnAlign" field="BF_DiammoniumPhosphateDosage" edit-render>
                <template v-slot:header>
                  磷酸二铵<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.BF_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="氯化钾用量" width="100" :align="tableColumnAlign" field="BF_PotassiumChlorideDosage" edit-render>
                <template v-slot:header>
                  氯化钾<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.BF_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="硫酸钾用量" width="100" :align="tableColumnAlign" field="BF_PotassiumSulphateDosage" edit-render>
                <template v-slot:header>
                  硫酸钾<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.BF_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_CompoundFertilizerDosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_CompoundFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" clearable @change="handleBFFieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="BF_CompoundFertilizerN" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_CompoundFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="BF_CompoundFertilizerP2O5" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_CompoundFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="BF_CompoundFertilizerK2O" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_CompoundFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_WaterSolubleFertilizerDosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" clearable @change="handleBFFieldChange($event, scope, 'WaterSolubleFertilizerDosage')"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="BF_WaterSolubleFertilizerN" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_WaterSolubleFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="BF_WaterSolubleFertilizerP2O5" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_WaterSolubleFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="BF_WaterSolubleFertilizerK2O" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_WaterSolubleFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                <ux-table-column title="名称" width="120" :align="tableColumnAlign" field="BF_OtherFertilizer1Id" edit-render>
                  <template v-slot:default="{row}">
                    <span class="elx-cell--label">
                      {{ row.BF_OtherFertilizer1Name }}
                    </span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.BF_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" clearable @change="handleBFFieldChange($event, scope, 'OtherFertilizer1')">
                      <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_OtherFertilizer1Dosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_OtherFertilizer1Id)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                <ux-table-column title="名称" width="120" :align="tableColumnAlign" field="BF_OtherFertilizer2Id" edit-render>
                  <template v-slot:default="{row}">
                    <span class="elx-cell--label">
                      {{ row.BF_OtherFertilizer2Name }}
                    </span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.BF_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_FertilizationMode)" clearable @change="handleBFFieldChange($event, scope, 'OtherFertilizer2')">
                      <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="BF_OtherFertilizer2Dosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.BF_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.BF_OtherFertilizer2Id)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
            </ux-table-column>
            <!-- 底肥 -->
            <ux-table-column title="追肥是否分次填报" width="120" :align="tableColumnAlign" field="PC_AfterFertilizerFillInMode" edit-render>
              <template v-slot:header>
                追肥<br />是否分次填报
              </template>
              <template v-slot:default="{row}">
                <span v-if="row.PC_AfterFertilizerFillInMode === 'NONE'" class="elx-cell--label">无追肥</span>
                <span v-if="row.PC_AfterFertilizerFillInMode === 'MANY_TIMES'" class="elx-cell--label">分次填报</span>
                <span v-if="row.PC_AfterFertilizerFillInMode === 'TOTAL'" class="elx-cell--label">合计填报</span>
              </template>
              <template v-slot:edit="scope">
                <el-select v-model="scope.row.PC_AfterFertilizerFillInMode" :size="editComponentSize" @change="handlePCAfterFertilizerFillInModeChange($event, scope)">
                  <el-option v-for="item in afterFertilizerFillInModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </template>
            </ux-table-column>
            <!-- 追肥是否分次填报 -->
            <ux-table-column title="追肥合计（公斤/亩）" :align="tableColumnAlign">
              <ux-table-column title="主要施肥方式" width="120" :align="tableColumnAlign" field="AFT_FertilizationMode" edit-render>
                <template v-slot:header>
                  主要<br />施肥方式
                </template>
                <template v-slot:default="{row}">
                  <span v-if="row.AFT_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE2'" class="elx-cell--label">冲施</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE3'" class="elx-cell--label">条施</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE4'" class="elx-cell--label">穴施</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE5'" class="elx-cell--label">水肥一体化</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE7'" class="elx-cell--label">机械深施</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE8'" class="elx-cell--label">无人机施肥</span>
                  <span v-if="row.AFT_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                </template>
                <template v-slot:edit="scope">
                  <el-select v-model="scope.row.AFT_FertilizationMode" :size="editComponentSize" :disabled="'TOTAL' !== scope.row.PC_AfterFertilizerFillInMode" clearable @clear="handleAFTFertilizationModeClear(scope)">
                    <el-option v-for="item in afterFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </template>
              </ux-table-column>
              <ux-table-column title="追肥次数" width="100" :align="tableColumnAlign" field="AFT_FertilizationTimes" edit-render>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_FertilizationTimes" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="首次施肥日期" width="150" :align="tableColumnAlign" field="AFT_FertilizationDate" edit-render>
                <template v-slot:edit="scope">
                  <el-date-picker v-model="scope.row.AFT_FertilizationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-date-picker>
                </template>
              </ux-table-column>
              <ux-table-column title="商用有机肥用量" width="100" :align="tableColumnAlign" field="AFT_BusinessOrganicFertilizerDosage" edit-render>
                <template v-slot:header>
                  商用<br />有机肥用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="尿素用量" width="100" :align="tableColumnAlign" field="AFT_UreaDosage" edit-render>
                <template v-slot:header>
                  尿素<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="碳铵用量" width="100" :align="tableColumnAlign" field="AFT_AmmoniumBicarbonateDosage" edit-render>
                <template v-slot:header>
                  碳铵<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="磷酸二铵用量" width="100" :align="tableColumnAlign" field="AFT_DiammoniumPhosphateDosage" edit-render>
                <template v-slot:header>
                  磷酸二铵<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="氯化钾用量" width="100" :align="tableColumnAlign" field="AFT_PotassiumChlorideDosage" edit-render>
                <template v-slot:header>氯化钾<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="硫酸钾用量" width="100" :align="tableColumnAlign" field="AFT_PotassiumSulphateDosage" edit-render>
                <template v-slot:header>
                  硫酸钾<br />用量
                </template>
                <template v-slot:edit="scope">
                  <el-input v-model="scope.row.AFT_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)"></el-input>
                </template>
              </ux-table-column>
              <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AFT_CompoundFertilizerDosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_CompoundFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)" clearable @clear="handleAFTFieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AFT_CompoundFertilizerN" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_CompoundFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AFT_CompoundFertilizerP2O5" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_CompoundFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AFT_CompoundFertilizerK2O" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_CompoundFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AFT_WaterSolubleFertilizerDosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)" clearable @clear="handleAFTFieldChange($event, scope, 'WaterSolubleFertilizerDosage')"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AFT_WaterSolubleFertilizerN" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_WaterSolubleFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AFT_WaterSolubleFertilizerP2O5" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_WaterSolubleFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AFT_WaterSolubleFertilizerK2O" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_WaterSolubleFertilizerDosage)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AFT_OtherFertilizer1Id" edit-render>
                  <template v-slot:default="{row}">
                    <span class="elx-cell--label">
                      {{ row.AFT_OtherFertilizer1Name }}
                    </span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AFT_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)" clearable @clear="handleAFTFieldChange($event, scope, 'OtherFertilizer1')">
                      <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AFT_OtherFertilizer1Dosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_OtherFertilizer1Id)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
              <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AFT_OtherFertilizer2Id" edit-render>
                  <template v-slot:default="{row}">
                    <span class="elx-cell--label">
                      {{ row.AFT_OtherFertilizer2Name }}
                    </span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AFT_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_FertilizationMode)" clearable @clear="handleAFTFieldChange($event, scope, 'OtherFertilizer2')">
                      <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AFT_OtherFertilizer2Dosage" edit-render>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AFT_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AFT_OtherFertilizer2Id)"></el-input>
                  </template>
                </ux-table-column>
              </ux-table-column>
            </ux-table-column>
            <!-- 追肥合计 -->
            <ux-table-column title="分次追肥 1 ~ 5" :align="tableColumnAlign">
              <ux-table-column title="追肥1（公斤/亩）" :align="tableColumnAlign">
                <ux-table-column title="主要施肥方式" width="120" :align="tableColumnAlign" field="AF1_FertilizationMode" edit-render>
                  <template v-slot:header>
                    主要<br />施肥方式
                  </template>
                  <template v-slot:default="{row}">
                    <span v-if="row.AF1_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE2'" class="elx-cell--label">冲施</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE3'" class="elx-cell--label">条施</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE4'" class="elx-cell--label">穴施</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE5'" class="elx-cell--label">水肥一体化</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE7'" class="elx-cell--label">机械深施</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE8'" class="elx-cell--label">无人机施肥</span>
                    <span v-if="row.AF1_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AF1_FertilizationMode" :size="editComponentSize" :disabled="'MANY_TIMES' !== scope.row.PC_AfterFertilizerFillInMode" clearable @clear="handleAF1FertilizationModeClear(scope)">
                      <el-option v-for="item in afterFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="首次施肥日期" width="150" :align="tableColumnAlign" field="AF1_FertilizationDate" edit-render>
                  <template v-slot:edit="scope">
                    <el-date-picker v-model="scope.row.AF1_FertilizationDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-date-picker>
                  </template>
                </ux-table-column>
                <ux-table-column title="商用有机肥用量" width="100" :align="tableColumnAlign" field="AF1_BusinessOrganicFertilizerDosage" edit-render>
                  <template v-slot:header>
                    商用<br />有机肥用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF1_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="尿素用量" width="100" :align="tableColumnAlign" field="AF1_UreaDosage" edit-render>
                  <template v-slot:header>
                    尿素<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF1_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="碳铵用量" width="100" :align="tableColumnAlign" field="AF1_AmmoniumBicarbonateDosage" edit-render>
                  <template v-slot:header>
                    碳铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF1_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷酸二铵用量" width="100" :align="tableColumnAlign" field="AF1_DiammoniumPhosphateDosage" edit-render>
                  <template v-slot:header>
                    磷酸二铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF1_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氯化钾用量" width="100" :align="tableColumnAlign" field="AF1_PotassiumChlorideDosage" edit-render>
                  <template v-slot:header>
                    氯化钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF1_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="硫酸钾用量" width="100" :align="tableColumnAlign" field="AF1_PotassiumSulphateDosage" edit-render>
                  <template v-slot:header>
                    硫酸钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF1_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF1_CompoundFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_CompoundFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)" clearable @change="handleAF1FieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF1_CompoundFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF1_CompoundFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF1_CompoundFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF1_WaterSolubleFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)" clearable @change="handleAF1FieldChange($event, scope, 'WaterSolubleFertilizerDosage')"
                      ></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF1_WaterSolubleFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF1_WaterSolubleFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF1_WaterSolubleFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF1_OtherFertilizer1Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF1_OtherFertilizer1Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF1_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)" clearable @change="handleAF1FieldChange($event, scope, 'OtherFertilizer1')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF1_OtherFertilizer1Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_OtherFertilizer1Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF1_OtherFertilizer2Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF1_OtherFertilizer2Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF1_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)" clearable @change="handleAF1FieldChange($event, scope, 'OtherFertilizer2')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF1_OtherFertilizer2Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF1_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_OtherFertilizer2Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
              </ux-table-column>
              <!-- 追肥 1 -->
              <ux-table-column title="追肥2（公斤/亩）" :align="tableColumnAlign">
                <ux-table-column title="主要施肥方式" width="120" :align="tableColumnAlign" field="AF2_FertilizationMode" edit-render>
                  <template v-slot:header>
                    主要<br />施肥方式
                  </template>
                  <template v-slot:default="{row}">
                    <span v-if="row.AF2_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE2'" class="elx-cell--label">冲施</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE3'" class="elx-cell--label">条施</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE4'" class="elx-cell--label">穴施</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE5'" class="elx-cell--label">水肥一体化</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE7'" class="elx-cell--label">机械深施</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE8'" class="elx-cell--label">无人机施肥</span>
                    <span v-if="row.AF2_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AF2_FertilizationMode" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF1_FertilizationMode)" clearable @clear="handleAF2FertilizationModeClear(scope)">
                      <el-option v-for="item in afterFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="首次施肥日期" width="150" :align="tableColumnAlign" field="AF2_FertilizationDate" edit-render>
                  <template v-slot:edit="scope">
                    <el-date-picker v-model="scope.row.AF2_FertilizationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-date-picker>
                  </template>
                </ux-table-column>
                <ux-table-column title="商用有机肥用量" width="100" :align="tableColumnAlign" field="AF2_BusinessOrganicFertilizerDosage" edit-render>
                  <template v-slot:header>
                    商用<br />有机肥用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF2_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="尿素用量" width="100" :align="tableColumnAlign" field="AF2_UreaDosage" edit-render>
                  <template v-slot:header>
                    尿素<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF2_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="碳铵用量" width="100" :align="tableColumnAlign" field="AF2_AmmoniumBicarbonateDosage" edit-render>
                  <template v-slot:header>
                    碳铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF2_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷酸二铵用量" width="100" :align="tableColumnAlign" field="AF2_DiammoniumPhosphateDosage" edit-render>
                  <template v-slot:header>
                    磷酸二铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF2_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氯化钾用量" width="100" :align="tableColumnAlign" field="AF2_PotassiumChlorideDosage" edit-render>
                  <template v-slot:header>
                    氯化钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF2_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="硫酸钾用量" width="100" :align="tableColumnAlign" field="AF2_PotassiumSulphateDosage" edit-render>
                  <template v-slot:header>
                    硫酸钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF2_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF2_CompoundFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_CompoundFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)" clearable @change="handleAF2FieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF2_CompoundFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF2_CompoundFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF2_CompoundFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF2_WaterSolubleFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)" clearable @change="handleAF2FieldChange($event, scope, 'WaterSolubleFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF2_WaterSolubleFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF2_WaterSolubleFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF2_WaterSolubleFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF2_OtherFertilizer1Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF2_OtherFertilizer1Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF2_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)" clearable @change="handleAF2FieldChange($event, scope, 'OtherFertilizer1')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF2_OtherFertilizer1Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_OtherFertilizer1Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF2_OtherFertilizer2Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF2_OtherFertilizer2Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF2_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)" clearable @change="handleAF2FieldChange($event, scope, 'OtherFertilizer2')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF2_OtherFertilizer2Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF2_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_OtherFertilizer2Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
              </ux-table-column>
              <!-- 追肥 2 -->
              <ux-table-column title="追肥3（公斤/亩）" :align="tableColumnAlign">
                <ux-table-column title="主要施肥方式" width="120" :align="tableColumnAlign" field="AF3_FertilizationMode" edit-render>
                  <template v-slot:header>
                    主要<br />施肥方式
                  </template>
                  <template v-slot:default="{row}">
                    <span v-if="row.AF3_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE2'" class="elx-cell--label">冲施</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE3'" class="elx-cell--label">条施</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE4'" class="elx-cell--label">穴施</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE5'" class="elx-cell--label">水肥一体化</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE7'" class="elx-cell--label">机械深施</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE8'" class="elx-cell--label">无人机施肥</span>
                    <span v-if="row.AF3_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AF3_FertilizationMode" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF2_FertilizationMode)" clearable @clear="handleAF3FertilizationModeClear(scope)">
                      <el-option v-for="item in afterFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="首次施肥日期" width="150" :align="tableColumnAlign" field="AF3_FertilizationDate" edit-render>
                  <template v-slot:edit="scope">
                    <el-date-picker v-model="scope.row.AF3_FertilizationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-date-picker>
                  </template>
                </ux-table-column>
                <ux-table-column title="商用有机肥用量" width="100" :align="tableColumnAlign" field="AF3_BusinessOrganicFertilizerDosage" edit-render>
                  <template v-slot:header>
                    商用<br />有机肥用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF3_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="尿素用量" width="100" :align="tableColumnAlign" field="AF3_UreaDosage" edit-render>
                  <template v-slot:header>
                    尿素<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF3_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="碳铵用量" width="100" :align="tableColumnAlign" field="AF3_AmmoniumBicarbonateDosage" edit-render>
                  <template v-slot:header>
                    碳铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF3_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷酸二铵用量" width="100" :align="tableColumnAlign" field="AF3_DiammoniumPhosphateDosage" edit-render>
                  <template v-slot:header>
                    磷酸二铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF3_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氯化钾用量" width="100" :align="tableColumnAlign" field="AF3_PotassiumChlorideDosage" edit-render>
                  <template v-slot:header>
                    氯化钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF3_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="硫酸钾用量" width="100" :align="tableColumnAlign" field="AF3_PotassiumSulphateDosage" edit-render>
                  <template v-slot:header>
                    硫酸钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF3_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF3_CompoundFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_CompoundFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)" clearable @change="handleAF3FieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF3_CompoundFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF3_CompoundFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF3_CompoundFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF3_WaterSolubleFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)" clearable @change="handleAF3FieldChange($event, scope, 'WaterSolubleFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF3_WaterSolubleFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF3_WaterSolubleFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF3_WaterSolubleFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF3_OtherFertilizer1Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF3_OtherFertilizer1Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF3_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)" clearable @change="handleAF3FieldChange($event, scope, 'OtherFertilizer1')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF3_OtherFertilizer1Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_OtherFertilizer1Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF3_OtherFertilizer2Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF3_OtherFertilizer2Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF3_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)" clearable @change="handleAF3FieldChange($event, scope, 'OtherFertilizer2')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF3_OtherFertilizer2Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF3_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="'MANY_TIMES' !== scope.row.PC_AfterFertilizerFillInMode && helper.isEmpty(scope.row.AF3_OtherFertilizer2Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
              </ux-table-column>
              <!-- 追肥 3 -->
              <ux-table-column title="追肥4（公斤/亩）" :align="tableColumnAlign">
                <ux-table-column title="主要施肥方式" width="120" :align="tableColumnAlign" field="AF4_FertilizationMode" edit-render>
                  <template v-slot:header>
                    主要<br />施肥方式
                  </template>
                  <template v-slot:default="{row}">
                    <span v-if="row.AF4_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE2'" class="elx-cell--label">冲施</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE3'" class="elx-cell--label">条施</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE4'" class="elx-cell--label">穴施</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE5'" class="elx-cell--label">水肥一体化</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE7'" class="elx-cell--label">机械深施</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE8'" class="elx-cell--label">无人机施肥</span>
                    <span v-if="row.AF4_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AF4_FertilizationMode" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF3_FertilizationMode)" clearable @clear="handleAF4FertilizationModeClear(scope)">
                      <el-option v-for="item in afterFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="首次施肥日期" width="150" :align="tableColumnAlign" field="AF4_FertilizationDate" edit-render>
                  <template v-slot:edit="scope">
                    <el-date-picker v-model="scope.row.AF4_FertilizationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-date-picker>
                  </template>
                </ux-table-column>
                <ux-table-column title="商用有机肥用量" width="100" :align="tableColumnAlign" field="AF4_BusinessOrganicFertilizerDosage" edit-render>
                  <template v-slot:header>
                    商用<br />有机肥用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF4_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="尿素用量" width="100" :align="tableColumnAlign" field="AF4_UreaDosage" edit-render>
                  <template v-slot:header>
                    尿素<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF4_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="碳铵用量" width="100" :align="tableColumnAlign" field="AF4_AmmoniumBicarbonateDosage" edit-render>
                  <template v-slot:header>
                    碳铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF4_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="磷酸二铵用量" width="100" :align="tableColumnAlign" field="AF4_DiammoniumPhosphateDosage" edit-render>
                  <template v-slot:header>
                    磷酸二铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF4_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="氯化钾用量" width="100" :align="tableColumnAlign" field="AF4_PotassiumChlorideDosage" edit-render>
                  <template v-slot:header>
                    氯化钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF4_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="硫酸钾用量" width="100" :align="tableColumnAlign" field="AF4_PotassiumSulphateDosage" edit-render>
                  <template v-slot:header>
                    硫酸钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF4_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF4_CompoundFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_CompoundFertilizerDosage" :size="editComponentSize" :disabled="'MANY_TIMES' !== scope.row.PC_AfterFertilizerFillInMode && helper.isEmpty(scope.row.AF4_FertilizationMode)" clearable @change="handleAF4FieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF4_CompoundFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF4_CompoundFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF4_CompoundFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF4_WaterSolubleFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)" clearable @change="handleAF4FieldChange($event, scope, 'WaterSolubleFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF4_WaterSolubleFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF4_WaterSolubleFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF4_WaterSolubleFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF4_OtherFertilizer1Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF4_OtherFertilizer1Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF4_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)" clearable @change="handleAF4FieldChange($event, scope, 'OtherFertilizer1')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF4_OtherFertilizer1Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_OtherFertilizer1Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF4_OtherFertilizer2Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF4_OtherFertilizer2Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF4_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)" clearable @change="handleAF4FieldChange($event, scope, 'OtherFertilizer2')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF4_OtherFertilizer2Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF4_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_OtherFertilizer2Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
              </ux-table-column>
              <!-- 追肥 4 -->
              <ux-table-column title="追肥5（公斤/亩）" :align="tableColumnAlign">
                <ux-table-column title="主要施肥方式" width="120" :align="tableColumnAlign" field="AF5_FertilizationMode" edit-render>
                  <template v-slot:header>
                    主要<br />施肥方式
                  </template>
                  <template v-slot:default="{row}">
                    <span v-if="row.AF5_FertilizationMode === 'MODE1'" class="elx-cell--label">撒施</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE2'" class="elx-cell--label">冲施</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE3'" class="elx-cell--label">条施</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE4'" class="elx-cell--label">穴施</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE5'" class="elx-cell--label">水肥一体化</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE7'" class="elx-cell--label">机械深施</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE8'" class="elx-cell--label">无人机施肥</span>
                    <span v-if="row.AF5_FertilizationMode === 'MODE6'" class="elx-cell--label">其他</span>
                  </template>
                  <template v-slot:edit="scope">
                    <el-select v-model="scope.row.AF5_FertilizationMode" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF4_FertilizationMode)" clearable @clear="handleAF5FertilizationModeClear(scope)">
                      <el-option v-for="item in afterFertilizationModeOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </template>
                </ux-table-column>
                <ux-table-column title="首次施肥日期" width="150" :align="tableColumnAlign" field="AF5_FertilizationDate" edit-render>
                  <template v-slot:edit="scope">
                    <el-date-picker v-model="scope.row.AF5_FertilizationDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" style="width: 140px" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-date-picker>
                  </template>
                </ux-table-column>
                <ux-table-column width="100" :align="tableColumnAlign" field="AF5_BusinessOrganicFertilizerDosage" edit-render>
                  <template v-slot:header>
                    商用<br />有机肥用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF5_BusinessOrganicFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column width="100" :align="tableColumnAlign" field="AF5_UreaDosage" edit-render>
                  <template v-slot:header>
                    尿素<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF5_UreaDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column width="100" :align="tableColumnAlign" field="AF5_AmmoniumBicarbonateDosage" edit-render>
                  <template v-slot:header>
                    碳铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF5_AmmoniumBicarbonateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column width="100" :align="tableColumnAlign" field="AF5_DiammoniumPhosphateDosage" edit-render>
                  <template v-slot:header>
                    磷酸二铵<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF5_DiammoniumPhosphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column width="100" :align="tableColumnAlign" field="AF5_PotassiumChlorideDosage" edit-render>
                  <template v-slot:header>
                    氯化钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF5_PotassiumChlorideDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column width="100" :align="tableColumnAlign" field="AF5_PotassiumSulphateDosage" edit-render>
                  <template v-slot:header>
                    硫酸钾<br />用量
                  </template>
                  <template v-slot:edit="scope">
                    <el-input v-model="scope.row.AF5_PotassiumSulphateDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)"></el-input>
                  </template>
                </ux-table-column>
                <ux-table-column title="复合肥（含配方肥）及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF5_CompoundFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_CompoundFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)" clearable @change="handleAF5FieldChange($event, scope, 'CompoundFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF5_CompoundFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_CompoundFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF5_CompoundFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_CompoundFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF5_CompoundFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_CompoundFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_CompoundFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="水溶肥及养分%" :align="tableColumnAlign">
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF5_WaterSolubleFertilizerDosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_WaterSolubleFertilizerDosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)" clearable @change="handleAF5FieldChange($event, scope, 'WaterSolubleFertilizerDosage')"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="氮N" width="100" :align="tableColumnAlign" field="AF5_WaterSolubleFertilizerN" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_WaterSolubleFertilizerN" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="磷P2O5" width="100" :align="tableColumnAlign" field="AF5_WaterSolubleFertilizerP2O5" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_WaterSolubleFertilizerP2O5" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="钾K2O" width="100" :align="tableColumnAlign" field="AF5_WaterSolubleFertilizerK2O" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_WaterSolubleFertilizerK2O" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_WaterSolubleFertilizerDosage)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料1" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF5_OtherFertilizer1Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF5_OtherFertilizer1Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF5_OtherFertilizer1Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)" clearable @change="handleAF5FieldChange($event, scope, 'OtherFertilizer1')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF5_OtherFertilizer1Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_OtherFertilizer1Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_OtherFertilizer1Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
                <ux-table-column title="其他肥料2" :align="tableColumnAlign">
                  <ux-table-column title="名称" width="100" :align="tableColumnAlign" field="AF5_OtherFertilizer2Id" edit-render>
                    <template v-slot:default="{row}">
                      <span class="elx-cell--label">
                        {{ row.AF5_OtherFertilizer2Name }}
                      </span>
                    </template>
                    <template v-slot:edit="scope">
                      <el-select v-model="scope.row.AF5_OtherFertilizer2Id" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_FertilizationMode)" clearable @change="handleAF5FieldChange($event, scope, 'OtherFertilizer2')">
                        <el-option v-for="option in chemicalFertilizerOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </template>
                  </ux-table-column>
                  <ux-table-column title="用量" width="100" :align="tableColumnAlign" field="AF5_OtherFertilizer2Dosage" edit-render>
                    <template v-slot:edit="scope">
                      <el-input v-model="scope.row.AF5_OtherFertilizer2Dosage" :size="editComponentSize" :disabled="helper.isEmpty(scope.row.AF5_OtherFertilizer2Id)"></el-input>
                    </template>
                  </ux-table-column>
                </ux-table-column>
              </ux-table-column>
              <!-- 追肥 5 -->
            </ux-table-column>
            <!-- 追肥 1 ~ 5 -->
          </ux-grid>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssistFillInDataApi from '@/api/data-fill-in/assist/assist-fill-in-data-api'
import MonitoringPlotApi from '@/api/monitoring-plot-api'
import CropApi from '@/api/crop-api'
import OrganicFertilizerApi from '@/api/organic-fertilizer-api'
import ChemicalFertilizerApi from '@/api/chemical-fertilizer-api'

import AssistFillInDataConfig from './AssistFillInDataConfig'
import Screenfull from 'screenfull'

export default {
  name: 'AssistFillInDataAddRow',
  data() {
    return {
      year: null,
      regionId: null,
      dataLoading: false,
      isFullscreen: false,
      tableEditConfig: { trigger: 'click', mode: 'cell', showIcon: true },
      tableColumnAlign: 'center',
      helper: {
        isEmpty: this.$utils.isEmpty,
        isNotEmpty: this.$utils.isNotEmpty
      },
      validRules: AssistFillInDataConfig.validRules,
      editComponentSize: 'medium',
      cropOptionGroupList: [],
      previousCropOptionGroupList: [],
      organicFertilizerOptionList: [],
      chemicalFertilizerOptionList: [],
      plotFertilityOptionList: [],
      yesAndNoOptionList: [],
      afterFertilizerFillInModeOptionList: [],
      baseFertilizationModeOptionList: [],
      afterFertilizationModeOptionList: []
    }
  },
  created() {
    CropApi.getStubList()
      .then(res => {
        let grainCottonOilSugarOptionGroup = { label: '粮棉油糖', optionList: [] }
        let fruitTreeTobaccoTeaOptionGroup = { label: '果树烟茶', optionList: [] }
        let vegetableFlowerOptionGroup = { label: '蔬菜花卉', optionList: [] }
        res.data.forEach(el => {
          switch (el.category) {
            case 'GRAIN_COTTON_OIL_SUGAR':
              grainCottonOilSugarOptionGroup.optionList.push({
                label: el.name,
                value: el.id
              })
              break
            case 'FRUIT_TREE_TOBACCO_TEA':
              fruitTreeTobaccoTeaOptionGroup.optionList.push({
                label: el.name,
                value: el.id
              })
              break
            case 'VEGETABLE_FLOWER':
              vegetableFlowerOptionGroup.optionList.push({
                label: el.name,
                value: el.id
              })
              break
          }
        })

        this.cropOptionGroupList.push(grainCottonOilSugarOptionGroup)
        this.cropOptionGroupList.push(fruitTreeTobaccoTeaOptionGroup)
        this.cropOptionGroupList.push(vegetableFlowerOptionGroup)

        let defaultOptionGroup = { label: '默认', optionList: [] }
        defaultOptionGroup.optionList.push({ label: '无', value: 'NONE' })

        this.previousCropOptionGroupList.push(defaultOptionGroup)
        this.previousCropOptionGroupList.push(grainCottonOilSugarOptionGroup)
        this.previousCropOptionGroupList.push(fruitTreeTobaccoTeaOptionGroup)
        this.previousCropOptionGroupList.push(vegetableFlowerOptionGroup)
      })
      .catch(err => {
        this.$message({
          showClose: true,
          message: '【' + err.data.code + '】' + err.data.message,
          type: 'error'
        })
      })

    OrganicFertilizerApi.getStubList()
      .then(res => {
        res.data.forEach(el => {
          this.organicFertilizerOptionList.push({ label: el.name, value: el.id })
        })
      })
      .catch(err => {
        this.$message({
          showClose: true,
          message: '【' + err.data.code + '】' + err.data.message,
          type: 'error'
        })
      })

    ChemicalFertilizerApi.getStubList()
      .then(res => {
        res.data.forEach(el => {
          this.chemicalFertilizerOptionList.push({ label: el.name, value: el.id })
        })
      })
      .catch(err => {
        this.$message({
          showClose: true,
          message: '【' + err.data.code + '】' + err.data.message,
          type: 'error'
        })
      })

    this.plotFertilityOptionList.push({ label: '高', value: 'HIGH' })
    this.plotFertilityOptionList.push({ label: '中', value: 'MIDDLE' })
    this.plotFertilityOptionList.push({ label: '低', value: 'LOW' })

    this.yesAndNoOptionList.push({ label: '是', value: true })
    this.yesAndNoOptionList.push({ label: '否', value: false })

    this.afterFertilizerFillInModeOptionList.push({ label: '分次填报', value: 'MANY_TIMES' })
    this.afterFertilizerFillInModeOptionList.push({ label: '合计填报', value: 'TOTAL' })
    this.afterFertilizerFillInModeOptionList.push({ label: '无追肥', value: 'NONE' })

    this.baseFertilizationModeOptionList.push({ label: '撒施', value: 'MODE1' })
    this.baseFertilizationModeOptionList.push({ label: '机械深施', value: 'MODE2' })
    this.baseFertilizationModeOptionList.push({ label: '种肥同播', value: 'MODE3' })
    this.baseFertilizationModeOptionList.push({ label: '条施', value: 'MODE4' })
    this.baseFertilizationModeOptionList.push({ label: '穴施', value: 'MODE5' })
    this.baseFertilizationModeOptionList.push({ label: '无人机施肥', value: 'MODE7' })
    this.baseFertilizationModeOptionList.push({ label: '其他', value: 'MODE6' })

    this.afterFertilizationModeOptionList.push({ label: '撒施', value: 'MODE1' })
    this.afterFertilizationModeOptionList.push({ label: '冲施', value: 'MODE2' })
    this.afterFertilizationModeOptionList.push({ label: '条施', value: 'MODE3' })
    this.afterFertilizationModeOptionList.push({ label: '穴施', value: 'MODE4' })
    this.afterFertilizationModeOptionList.push({ label: '水肥一体化', value: 'MODE5' })
    this.afterFertilizationModeOptionList.push({ label: '机械深施', value: 'MODE7' })
    this.afterFertilizationModeOptionList.push({ label: '无人机施肥', value: 'MODE8' })
    this.afterFertilizationModeOptionList.push({ label: '其他', value: 'MODE6' })
  },
  mounted() {
    let that = this
    Screenfull.on('change', function() {
      that.$nextTick(() => {
        that.isFullscreen = Screenfull.isFullscreen
        that.tableHeight = Screenfull.isFullscreen ? window.innerHeight - 120 : '600px'
      })
    })
  },
  destroy() {
    if (Screenfull.enabled) {
      Screenfull.off('change', this.change)
    }
  },
  methods: {
    handleFullscreen() {
      if (!Screenfull.isEnabled) {
        this.$message({ message: '浏览器不支持', type: 'warning' })
        return false
      }

      Screenfull.request(this.$refs.assistFillInDataAddPane)
    },
    handleExtFullscreen() {
      if (!Screenfull.isEnabled) {
        this.$message({ message: '浏览器不支持', type: 'warning' })
        return false
      }

      Screenfull.exit()
    },
    handleTableEditActived({ row, column }) {
      if (column.property === 'MP_Id') {
        row.filterData.plotOptionList = []
        MonitoringPlotApi.getStubList(row.UA_Id, false)
          .then(res => {
            res.data.forEach(el => {
              row.filterData.plotOptionList.push({
                value: el.id,
                label: el.name,
                name: el.name,
                code: el.code,
                area: el.plotArea
              })
            })
          })
          .catch(err => {
            this.$message({
              showClose: true,
              message: '【' + err.data.code + '】' + err.data.message,
              type: 'error'
            })
          })
      }
    },
    handleSave() {
      this.$refs.fillDataTable.fullValidate().then(data => {
        if (data !== undefined) {
          let masStr = '<ul class="valid-error-list">'
          Object.values(data).forEach(errList => {
            errList.forEach(params => {
              let { rowIndex, rules } = params
              rules.forEach(rule => {
                masStr += `<li>第 ${rowIndex + 1} 行 ${rule.message}</li>`
              })
            })
          })
          masStr += '</ul>'
          this.$notify.warning({
            title: '填报数据验证失败！',
            dangerouslyUseHTMLString: true,
            message: masStr,
            duration: 5000
          })
          return
        }

        let records = this.$refs.fillDataTable.getUpdateRecords()
        if (records.length === 0) {
          this.$message({ showClose: true, message: '未修改任何数据！', type: 'warning' })
          return
        }

        this.dataLoading = true
        if (records.length === 1) {
          let model = this.buildSaveModel(records[0])
          AssistFillInDataApi.create(model).then(() => {
            this.$message({ type: 'success', message: '保存成功！' })
            this.$emit('hideComponent')
          }).catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          }).finally(() => {
            this.dataLoading = false
          })
        } else {
          let modelList = []
          for (let i = 0; i < records.length; i++) {
            let model = this.buildSaveModel(records[i])
            modelList.push(model)
          }

          AssistFillInDataApi.createMultiple(modelList).then(() => {
            this.$message({ type: 'success', message: '保存成功！' })
            this.$emit('hideComponent')
          }).catch(err => {
            this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
          }).finally(() => {
            this.dataLoading = false
          })
        }
      })
    },
    handleCancel() {
      this.$emit('hideComponent')
    },
    handleMPPlotChange(value, scope) {
      let option = scope.row.filterData.plotOptionList.find(el => el.value === value)
      scope.row.MP_Name = option.name
      scope.row.MP_Code = option.code
      scope.row.MP_Area = option.area

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handlePCCropChange(value, scope) {
      for (let i = 0; i < this.cropOptionGroupList.length; i++) {
        let option = this.cropOptionGroupList[i].optionList.find(el => el.value === value)
        if (undefined !== option) {
          scope.row.PC_CropName = option.label
          break
        }
      }
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handlePCFacilityCultivationFlagClear(scope) {
      scope.row.PC_FacilityCultivationFlag = null
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handlePCHappenDisasterFlagClear(scope) {
      scope.row.PC_HappenDisasterFlag = null
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handlePCPreviousCropChange(value, scope) {
      if (value === '/') {
        scope.row.PC_PreviousCropName = '无'
        scope.row.PC_PreviousYield = null
        scope.row.PC_PreviousStrawReturnRate = null
      } else {
        for (let i = 0; i < this.previousCropOptionGroupList.length; i++) {
          let option = this.previousCropOptionGroupList[i].optionList.find(el => el.value === value)
          if (undefined !== option) {
            scope.row.PC_PreviousCropName = option.label
            break
          }
        }
      }
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleBFFertilizationModeClear(scope) {
      scope.row.BF_FertilizationMode = null
      scope.row.BF_FertilizationDate = null
      scope.row.BF_BusinessOrganicFertilizerDosage = null
      scope.row.BF_BusinessOrganicFertilizerPrice = null
      scope.row.BF_OrganicFertilizer1Id = null
      scope.row.BF_OrganicFertilizer1Name = null
      scope.row.BF_OrganicFertilizer1Dosage = null
      scope.row.BF_OrganicFertilizer2Id = null
      scope.row.BF_OrganicFertilizer2Name = null
      scope.row.BF_OrganicFertilizer2Dosage = null
      scope.row.BF_UreaDosage = null
      scope.row.BF_AmmoniumBicarbonateDosage = null
      scope.row.BF_DiammoniumPhosphateDosage = null
      scope.row.BF_PotassiumChlorideDosage = null
      scope.row.BF_PotassiumSulphateDosage = null
      scope.row.BF_CompoundFertilizerDosage = null
      scope.row.BF_CompoundFertilizerN = null
      scope.row.BF_CompoundFertilizerP2O5 = null
      scope.row.BF_CompoundFertilizerK2O = null
      scope.row.BF_WaterSolubleFertilizerDosage = null
      scope.row.BF_WaterSolubleFertilizerN = null
      scope.row.BF_WaterSolubleFertilizerP2O5 = null
      scope.row.BF_WaterSolubleFertilizerK2O = null
      scope.row.BF_OtherFertilizer1Id = null
      scope.row.BF_OtherFertilizer1Name = null
      scope.row.BF_OtherFertilizer1Dosage = null
      scope.row.BF_OtherFertilizer2Id = null
      scope.row.BF_OtherFertilizer2Name = null
      scope.row.BF_OtherFertilizer2Dosage = null
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleBFFieldChange(value, scope, field) {
      if (field === 'BusinessOrganicFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_BusinessOrganicFertilizerDosage = null
          scope.row.BF_BusinessOrganicFertilizerPrice = null
        }
      }

      if (field === 'OrganicFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_OrganicFertilizer1Id = null
          scope.row.BF_OrganicFertilizer1Name = null
          scope.row.BF_OrganicFertilizer1Dosage = null
        } else {
          let option = this.organicFertilizerOptionList.find(el => el.value === value)
          scope.row.BF_OrganicFertilizer1Name = option.label
        }
      }

      if (field === 'OrganicFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_OrganicFertilizer2Id = null
          scope.row.BF_OrganicFertilizer2Name = null
          scope.row.BF_OrganicFertilizer2Dosage = null
        } else {
          let option = this.organicFertilizerOptionList.find(el => el.value === value)
          scope.row.BF_OrganicFertilizer2Name = option.label
        }
      }

      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_CompoundFertilizerN = null
          scope.row.BF_CompoundFertilizerP2O5 = null
          scope.row.BF_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_WaterSolubleFertilizerN = null
          scope.row.BF_WaterSolubleFertilizerP2O5 = null
          scope.row.BF_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_OtherFertilizer1Id = null
          scope.row.BF_OtherFertilizer1Name = null
          scope.row.BF_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.BF_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.BF_OtherFertilizer2Id = null
          scope.row.BF_OtherFertilizer2Name = null
          scope.row.BF_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.BF_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handlePCAfterFertilizerFillInModeChange(value, scope) {
      if (value === 'NONE') {
        this.clearAFTAllFieldValue(scope.row)
        this.clearAF1AllFieldValue(scope.row)
        this.clearAF2AllFieldValue(scope.row)
        this.clearAF3AllFieldValue(scope.row)
        this.clearAF4AllFieldValue(scope.row)
        this.clearAF5AllFieldValue(scope.row)
      }
      if (value === 'MANY_TIMES') {
        this.clearAFTAllFieldValue(scope.row)
      }
      if (value === 'TOTAL') {
        this.clearAF1AllFieldValue(scope.row)
        this.clearAF2AllFieldValue(scope.row)
        this.clearAF3AllFieldValue(scope.row)
        this.clearAF4AllFieldValue(scope.row)
        this.clearAF5AllFieldValue(scope.row)
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAFTFertilizationModeClear(scope) {
      this.clearAFTAllFieldValue(scope.row)
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAFTFieldChange(value, scope, field) {
      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AFT_CompoundFertilizerN = null
          scope.row.AFT_CompoundFertilizerP2O5 = null
          scope.row.AFT_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AFT_WaterSolubleFertilizerN = null
          scope.row.AFT_WaterSolubleFertilizerP2O5 = null
          scope.row.AFT_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.AFT_OtherFertilizer1Id = null
          scope.row.AFT_OtherFertilizer1Name = null
          scope.row.AFT_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AFT_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.AFT_OtherFertilizer2Id = null
          scope.row.AFT_OtherFertilizer2Name = null
          scope.row.AFT_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AFT_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF1FertilizationModeClear(scope) {
      this.clearAF1AllFieldValue(scope.row)
      this.clearAF2AllFieldValue(scope.row)
      this.clearAF3AllFieldValue(scope.row)
      this.clearAF4AllFieldValue(scope.row)
      this.clearAF5AllFieldValue(scope.row)
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF1FieldChange(value, scope, field) {
      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF1_CompoundFertilizerN = null
          scope.row.AF1_CompoundFertilizerP2O5 = null
          scope.row.AF1_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF1_WaterSolubleFertilizerN = null
          scope.row.AF1_WaterSolubleFertilizerP2O5 = null
          scope.row.AF1_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF1_OtherFertilizer1Id = null
          scope.row.AF1_OtherFertilizer1Name = null
          scope.row.AF1_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF1_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF1_OtherFertilizer2Id = null
          scope.row.AF1_OtherFertilizer2Name = null
          scope.row.AF1_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF1_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF2FertilizationModeClear(scope) {
      this.clearAF2AllFieldValue(scope.row)
      this.clearAF3AllFieldValue(scope.row)
      this.clearAF4AllFieldValue(scope.row)
      this.clearAF5AllFieldValue(scope.row)
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF2FieldChange(value, scope, field) {
      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF2_CompoundFertilizerN = null
          scope.row.AF2_CompoundFertilizerP2O5 = null
          scope.row.AF2_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF2_WaterSolubleFertilizerN = null
          scope.row.AF2_WaterSolubleFertilizerP2O5 = null
          scope.row.AF2_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF2_OtherFertilizer1Id = null
          scope.row.AF2_OtherFertilizer1Name = null
          scope.row.AF2_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF2_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF2_OtherFertilizer2Id = null
          scope.row.AF2_OtherFertilizer2Name = null
          scope.row.AF2_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF2_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF3FertilizationModeClear(scope) {
      this.clearAF3AllFieldValue(scope.row)
      this.clearAF4AllFieldValue(scope.row)
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF3FieldChange(value, scope, field) {
      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF3_CompoundFertilizerN = null
          scope.row.AF3_CompoundFertilizerP2O5 = null
          scope.row.AF3_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF3_WaterSolubleFertilizerN = null
          scope.row.AF3_WaterSolubleFertilizerP2O5 = null
          scope.row.AF3_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF3_OtherFertilizer1Id = null
          scope.row.AF3_OtherFertilizer1Name = null
          scope.row.AF3_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF3_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF3_OtherFertilizer2Id = null
          scope.row.AF3_OtherFertilizer2Name = null
          scope.row.AF3_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF3_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF4FertilizationModeClear(scope) {
      this.clearAF4AllFieldValue(scope.row)
      this.clearAF5AllFieldValue(scope.row)
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF4FieldChange(value, scope, field) {
      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF4_CompoundFertilizerN = null
          scope.row.AF4_CompoundFertilizerP2O5 = null
          scope.row.AF4_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF4_WaterSolubleFertilizerN = null
          scope.row.AF4_WaterSolubleFertilizerP2O5 = null
          scope.row.AF4_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF4_OtherFertilizer1Id = null
          scope.row.AF4_OtherFertilizer1Name = null
          scope.row.AF4_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF4_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF4_OtherFertilizer2Id = null
          scope.row.AF4_OtherFertilizer2Name = null
          scope.row.AF4_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF4_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF5FertilizationModeClear(scope) {
      this.clearAF5AllFieldValue(scope.row)
      this.$refs.fillDataTable.updateStatus(scope)
    },
    handleAF5FieldChange(value, scope, field) {
      if (field === 'CompoundFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF5_CompoundFertilizerN = null
          scope.row.AF5_CompoundFertilizerP2O5 = null
          scope.row.AF5_CompoundFertilizerK2O = null
        }
      }

      if (field === 'WaterSolubleFertilizerDosage') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF5_WaterSolubleFertilizerN = null
          scope.row.AF5_WaterSolubleFertilizerP2O5 = null
          scope.row.AF5_WaterSolubleFertilizerK2O = null
        }
      }

      if (field === 'OtherFertilizer1') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF5_OtherFertilizer1Id = null
          scope.row.AF5_OtherFertilizer1Name = null
          scope.row.AF5_OtherFertilizer1Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF5_OtherFertilizer1Name = option.label
        }
      }

      if (field === 'OtherFertilizer2') {
        if (this.helper.isEmpty(value)) {
          scope.row.AF5_OtherFertilizer2Id = null
          scope.row.AF5_OtherFertilizer2Name = null
          scope.row.AF5_OtherFertilizer2Dosage = null
        } else {
          let option = this.chemicalFertilizerOptionList.find(el => el.value === value)

          scope.row.AF5_OtherFertilizer2Name = option.label
        }
      }

      this.$refs.fillDataTable.updateStatus(scope)
    },
    clearAFTAllFieldValue(row) {
      row.AFT_FertilizationMode = null
      row.AFT_FertilizationTimes = null
      row.AFT_FertilizationDate = null
      row.AFT_BusinessOrganicFertilizerDosage = null
      row.AFT_UreaDosage = null
      row.AFT_AmmoniumBicarbonateDosage = null
      row.AFT_DiammoniumPhosphateDosage = null
      row.AFT_PotassiumChlorideDosage = null
      row.AFT_PotassiumSulphateDosage = null
      row.AFT_CompoundFertilizerDosage = null
      row.AFT_CompoundFertilizerN = null
      row.AFT_CompoundFertilizerP2O5 = null
      row.AFT_CompoundFertilizerK2O = null
      row.AFT_WaterSolubleFertilizerDosage = null
      row.AFT_WaterSolubleFertilizerN = null
      row.AFT_WaterSolubleFertilizerP2O5 = null
      row.AFT_WaterSolubleFertilizerK2O = null
      row.AFT_OtherFertilizer1Id = null
      row.AFT_OtherFertilizer1Name = null
      row.AFT_OtherFertilizer1Dosage = null
      row.AFT_OtherFertilizer2Id = null
      row.AFT_OtherFertilizer2Name = null
      row.AFT_OtherFertilizer2Dosage = null
    },
    clearAF1AllFieldValue(row) {
      row.AF1_FertilizationMode = null
      row.AF1_FertilizationDate = null
      row.AF1_BusinessOrganicFertilizerDosage = null
      row.AF1_UreaDosage = null
      row.AF1_AmmoniumBicarbonateDosage = null
      row.AF1_DiammoniumPhosphateDosage = null
      row.AF1_PotassiumChlorideDosage = null
      row.AF1_PotassiumSulphateDosage = null
      row.AF1_CompoundFertilizerDosage = null
      row.AF1_CompoundFertilizerN = null
      row.AF1_CompoundFertilizerP2O5 = null
      row.AF1_CompoundFertilizerK2O = null
      row.AF1_WaterSolubleFertilizerDosage = null
      row.AF1_WaterSolubleFertilizerN = null
      row.AF1_WaterSolubleFertilizerP2O5 = null
      row.AF1_WaterSolubleFertilizerK2O = null
      row.AF1_OtherFertilizer1Id = null
      row.AF1_OtherFertilizer1Name = null
      row.AF1_OtherFertilizer1Dosage = null
      row.AF1_OtherFertilizer2Id = null
      row.AF1_OtherFertilizer2Name = null
      row.AF1_OtherFertilizer2Dosage = null
    },
    clearAF2AllFieldValue(row) {
      row.AF2_FertilizationMode = null
      row.AF2_FertilizationDate = null
      row.AF2_BusinessOrganicFertilizerDosage = null
      row.AF2_UreaDosage = null
      row.AF2_AmmoniumBicarbonateDosage = null
      row.AF2_DiammoniumPhosphateDosage = null
      row.AF2_PotassiumChlorideDosage = null
      row.AF2_PotassiumSulphateDosage = null
      row.AF2_CompoundFertilizerDosage = null
      row.AF2_CompoundFertilizerN = null
      row.AF2_CompoundFertilizerP2O5 = null
      row.AF2_CompoundFertilizerK2O = null
      row.AF2_WaterSolubleFertilizerDosage = null
      row.AF2_WaterSolubleFertilizerN = null
      row.AF2_WaterSolubleFertilizerP2O5 = null
      row.AF2_WaterSolubleFertilizerK2O = null
      row.AF2_OtherFertilizer1Id = null
      row.AF2_OtherFertilizer1Name = null
      row.AF2_OtherFertilizer1Dosage = null
      row.AF2_OtherFertilizer2Id = null
      row.AF2_OtherFertilizer2Name = null
      row.AF2_OtherFertilizer2Dosage = null
    },
    clearAF3AllFieldValue(row) {
      row.AF3_FertilizationMode = null
      row.AF3_FertilizationDate = null
      row.AF3_BusinessOrganicFertilizerDosage = null
      row.AF3_UreaDosage = null
      row.AF3_AmmoniumBicarbonateDosage = null
      row.AF3_DiammoniumPhosphateDosage = null
      row.AF3_PotassiumChlorideDosage = null
      row.AF3_PotassiumSulphateDosage = null
      row.AF3_CompoundFertilizerDosage = null
      row.AF3_CompoundFertilizerN = null
      row.AF3_CompoundFertilizerP2O5 = null
      row.AF3_CompoundFertilizerK2O = null
      row.AF3_WaterSolubleFertilizerDosage = null
      row.AF3_WaterSolubleFertilizerN = null
      row.AF3_WaterSolubleFertilizerP2O5 = null
      row.AF3_WaterSolubleFertilizerK2O = null
      row.AF3_OtherFertilizer1Id = null
      row.AF3_OtherFertilizer1Name = null
      row.AF3_OtherFertilizer1Dosage = null
      row.AF3_OtherFertilizer2Id = null
      row.AF3_OtherFertilizer2Name = null
      row.AF3_OtherFertilizer2Dosage = null
    },
    clearAF4AllFieldValue(row) {
      row.AF4_FertilizationMode = null
      row.AF4_FertilizationDate = null
      row.AF4_BusinessOrganicFertilizerDosage = null
      row.AF4_UreaDosage = null
      row.AF4_AmmoniumBicarbonateDosage = null
      row.AF4_DiammoniumPhosphateDosage = null
      row.AF4_PotassiumChlorideDosage = null
      row.AF4_PotassiumSulphateDosage = null
      row.AF4_CompoundFertilizerDosage = null
      row.AF4_CompoundFertilizerN = null
      row.AF4_CompoundFertilizerP2O5 = null
      row.AF4_CompoundFertilizerK2O = null
      row.AF4_WaterSolubleFertilizerDosage = null
      row.AF4_WaterSolubleFertilizerN = null
      row.AF4_WaterSolubleFertilizerP2O5 = null
      row.AF4_WaterSolubleFertilizerK2O = null
      row.AF4_OtherFertilizer1Id = null
      row.AF4_OtherFertilizer1Name = null
      row.AF4_OtherFertilizer1Dosage = null
      row.AF4_OtherFertilizer2Id = null
      row.AF4_OtherFertilizer2Name = null
      row.AF4_OtherFertilizer2Dosage = null
    },
    clearAF5AllFieldValue(row) {
      row.AF5_FertilizationMode = null
      row.AF5_FertilizationDate = null
      row.AF5_BusinessOrganicFertilizerDosage = null
      row.AF5_UreaDosage = null
      row.AF5_AmmoniumBicarbonateDosage = null
      row.AF5_DiammoniumPhosphateDosage = null
      row.AF5_PotassiumChlorideDosage = null
      row.AF5_PotassiumSulphateDosage = null
      row.AF5_CompoundFertilizerDosage = null
      row.AF5_CompoundFertilizerN = null
      row.AF5_CompoundFertilizerP2O5 = null
      row.AF5_CompoundFertilizerK2O = null
      row.AF5_WaterSolubleFertilizerDosage = null
      row.AF5_WaterSolubleFertilizerN = null
      row.AF5_WaterSolubleFertilizerP2O5 = null
      row.AF5_WaterSolubleFertilizerK2O = null
      row.AF5_OtherFertilizer1Id = null
      row.AF5_OtherFertilizer1Name = null
      row.AF5_OtherFertilizer1Dosage = null
      row.AF5_OtherFertilizer2Id = null
      row.AF5_OtherFertilizer2Name = null
      row.AF5_OtherFertilizer2Dosage = null
    },
    load(year, regionId, farmerList) {
      this.year = year
      this.regionId = regionId

      const tableData = []
      farmerList.forEach(el => {
        let row = {
          filterData: {
            plotOptionList: []
          },
          UA_Id: el.id,
          UA_Username: el.username,
          UA_FullName: el.fullName,
          UARegionId: el.regionId,
          UA_ProvinceName: el.provinceName,
          UA_CityName: el.cityName,
          UA_DistrictName: el.districtName,
          UA_DetailedAddress: el.detailedAddress,
          UA_PhoneNumber: el.phoneNumber,
          UA_FarmerNature: el.farmerNature,
          UA_EducationalLevel: el.educationalLevel,
          UA_CultivationLandArea: el.cultivationLandArea,
          UA_ModelHouseholdFlag: el.modelHouseholdFlag,
          MP_Id: null,
          MP_Name: null,
          MP_Code: null,
          MP_Area: null,
          PC_Id: null,
          PC_Year: this.year,
          PC_CropId: null,
          PC_CropName: null,
          PC_CropType: null,
          PC_SowingDate: null,
          PC_HarvestTime: null,
          PC_SowingArea: null,
          PC_PlotFertility: null,
          PC_Yield: null,
          PC_FacilityCultivationFlag: null,
          PC_HappenDisasterFlag: null,
          PC_PreviousCropId: null,
          PC_PreviousCropName: null,
          PC_PreviousYield: null,
          PC_PreviousStrawReturnRate: null,
          PC_AfterFertilizerFillInMode: null,
          PC_CreatedOn: null,
          PC_ModifiedOn: null,
          BF_FertilizationMode: null,
          BF_FertilizationDate: null,
          BF_BusinessOrganicFertilizerDosage: null,
          BF_BusinessOrganicFertilizerPrice: null,
          BF_OrganicFertilizer1Id: null,
          BF_OrganicFertilizer1Name: null,
          BF_OrganicFertilizer1Dosage: null,
          BF_OrganicFertilizer2Id: null,
          BF_OrganicFertilizer2Name: null,
          BF_OrganicFertilizer2Dosage: null,
          BF_UreaDosage: null,
          BF_AmmoniumBicarbonateDosage: null,
          BF_DiammoniumPhosphateDosage: null,
          BF_PotassiumChlorideDosage: null,
          BF_PotassiumSulphateDosage: null,
          BF_CompoundFertilizerDosage: null,
          BF_CompoundFertilizerN: null,
          BF_CompoundFertilizerP2O5: null,
          BF_CompoundFertilizerK2O: null,
          BF_WaterSolubleFertilizerDosage: null,
          BF_WaterSolubleFertilizerN: null,
          BF_WaterSolubleFertilizerP2O5: null,
          BF_WaterSolubleFertilizerK2O: null,
          BF_OtherFertilizer1Id: null,
          BF_OtherFertilizer1Name: null,
          BF_OtherFertilizer1Dosage: null,
          BF_OtherFertilizer2Id: null,
          BF_OtherFertilizer2Name: null,
          BF_OtherFertilizer2Dosage: null,
          AFT_FertilizationMode: null,
          AFT_FertilizationTimes: null,
          AFT_FertilizationDate: null,
          AFT_BusinessOrganicFertilizerDosage: null,
          AFT_UreaDosage: null,
          AFT_AmmoniumBicarbonateDosage: null,
          AFT_DiammoniumPhosphateDosage: null,
          AFT_PotassiumChlorideDosage: null,
          AFT_PotassiumSulphateDosage: null,
          AFT_CompoundFertilizerDosage: null,
          AFT_CompoundFertilizerN: null,
          AFT_CompoundFertilizerP2O5: null,
          AFT_CompoundFertilizerK2O: null,
          AFT_WaterSolubleFertilizerDosage: null,
          AFT_WaterSolubleFertilizerN: null,
          AFT_WaterSolubleFertilizerP2O5: null,
          AFT_WaterSolubleFertilizerK2O: null,
          AFT_OtherFertilizer1Id: null,
          AFT_OtherFertilizer1Name: null,
          AFT_OtherFertilizer1Dosage: null,
          AFT_OtherFertilizer2Id: null,
          AFT_OtherFertilizer2Name: null,
          AFT_OtherFertilizer2Dosage: null,
          AF1_FertilizationMode: null,
          AF1_FertilizationDate: null,
          AF1_BusinessOrganicFertilizerDosage: null,
          AF1_UreaDosage: null,
          AF1_AmmoniumBicarbonateDosage: null,
          AF1_DiammoniumPhosphateDosage: null,
          AF1_PotassiumChlorideDosage: null,
          AF1_PotassiumSulphateDosage: null,
          AF1_CompoundFertilizerDosage: null,
          AF1_CompoundFertilizerN: null,
          AF1_CompoundFertilizerP2O5: null,
          AF1_CompoundFertilizerK2O: null,
          AF1_WaterSolubleFertilizerDosage: null,
          AF1_WaterSolubleFertilizerN: null,
          AF1_WaterSolubleFertilizerP2O5: null,
          AF1_WaterSolubleFertilizerK2O: null,
          AF1_OtherFertilizer1Id: null,
          AF1_OtherFertilizer1Name: null,
          AF1_OtherFertilizer1Dosage: null,
          AF1_OtherFertilizer2Id: null,
          AF1_OtherFertilizer2Name: null,
          AF1_OtherFertilizer2Dosage: null,
          AF2_FertilizationMode: null,
          AF2_FertilizationDate: null,
          AF2_BusinessOrganicFertilizerDosage: null,
          AF2_UreaDosage: null,
          AF2_AmmoniumBicarbonateDosage: null,
          AF2_DiammoniumPhosphateDosage: null,
          AF2_PotassiumChlorideDosage: null,
          AF2_PotassiumSulphateDosage: null,
          AF2_CompoundFertilizerDosage: null,
          AF2_CompoundFertilizerN: null,
          AF2_CompoundFertilizerP2O5: null,
          AF2_CompoundFertilizerK2O: null,
          AF2_WaterSolubleFertilizerDosage: null,
          AF2_WaterSolubleFertilizerN: null,
          AF2_WaterSolubleFertilizerP2O5: null,
          AF2_WaterSolubleFertilizerK2O: null,
          AF2_OtherFertilizer1Id: null,
          AF2_OtherFertilizer1Name: null,
          AF2_OtherFertilizer1Dosage: null,
          AF2_OtherFertilizer2Id: null,
          AF2_OtherFertilizer2Name: null,
          AF2_OtherFertilizer2Dosage: null,
          AF3_FertilizationMode: null,
          AF3_FertilizationDate: null,
          AF3_BusinessOrganicFertilizerDosage: null,
          AF3_UreaDosage: null,
          AF3_AmmoniumBicarbonateDosage: null,
          AF3_DiammoniumPhosphateDosage: null,
          AF3_PotassiumChlorideDosage: null,
          AF3_PotassiumSulphateDosage: null,
          AF3_CompoundFertilizerDosage: null,
          AF3_CompoundFertilizerN: null,
          AF3_CompoundFertilizerP2O5: null,
          AF3_CompoundFertilizerK2O: null,
          AF3_WaterSolubleFertilizerDosage: null,
          AF3_WaterSolubleFertilizerN: null,
          AF3_WaterSolubleFertilizerP2O5: null,
          AF3_WaterSolubleFertilizerK2O: null,
          AF3_OtherFertilizer1Id: null,
          AF3_OtherFertilizer1Name: null,
          AF3_OtherFertilizer1Dosage: null,
          AF3_OtherFertilizer2Id: null,
          AF3_OtherFertilizer2Name: null,
          AF3_OtherFertilizer2Dosage: null,
          AF4_FertilizationMode: null,
          AF4_FertilizationDate: null,
          AF4_BusinessOrganicFertilizerDosage: null,
          AF4_UreaDosage: null,
          AF4_AmmoniumBicarbonateDosage: null,
          AF4_DiammoniumPhosphateDosage: null,
          AF4_PotassiumChlorideDosage: null,
          AF4_PotassiumSulphateDosage: null,
          AF4_CompoundFertilizerDosage: null,
          AF4_CompoundFertilizerN: null,
          AF4_CompoundFertilizerP2O5: null,
          AF4_CompoundFertilizerK2O: null,
          AF4_WaterSolubleFertilizerDosage: null,
          AF4_WaterSolubleFertilizerN: null,
          AF4_WaterSolubleFertilizerP2O5: null,
          AF4_WaterSolubleFertilizerK2O: null,
          AF4_OtherFertilizer1Id: null,
          AF4_OtherFertilizer1Name: null,
          AF4_OtherFertilizer1Dosage: null,
          AF4_OtherFertilizer2Id: null,
          AF4_OtherFertilizer2Name: null,
          AF4_OtherFertilizer2Dosage: null,
          AF5_FertilizationMode: null,
          AF5_FertilizationDate: null,
          AF5_BusinessOrganicFertilizerDosage: null,
          AF5_UreaDosage: null,
          AF5_AmmoniumBicarbonateDosage: null,
          AF5_DiammoniumPhosphateDosage: null,
          AF5_PotassiumChlorideDosage: null,
          AF5_PotassiumSulphateDosage: null,
          AF5_CompoundFertilizerDosage: null,
          AF5_CompoundFertilizerN: null,
          AF5_CompoundFertilizerP2O5: null,
          AF5_CompoundFertilizerK2O: null,
          AF5_WaterSolubleFertilizerDosage: null,
          AF5_WaterSolubleFertilizerN: null,
          AF5_WaterSolubleFertilizerP2O5: null,
          AF5_WaterSolubleFertilizerK2O: null,
          AF5_OtherFertilizer1Id: null,
          AF5_OtherFertilizer1Name: null,
          AF5_OtherFertilizer1Dosage: null,
          AF5_OtherFertilizer2Id: null,
          AF5_OtherFertilizer2Name: null,
          AF5_OtherFertilizer2Dosage: null
        }
        tableData.push(row)
      })

      this.$refs.fillDataTable.reloadData(tableData)
    },
    buildSaveModel(record) {
      let model = {}
      model.user = {
        id: record.UA_Id,
        fullName: record.UA_FullName,
        detailedAddress: record.UA_DetailedAddress,
        phoneNumber: record.UA_PhoneNumber,
        farmerNature: record.UA_FarmerNature,
        educationalLevel: record.UA_EducationalLevel,
        cultivationLandArea: record.UA_CultivationLandArea,
        modelHouseholdFlag: record.UA_ModelHouseholdFlag
      }

      // set monitoring plot model
      model.monitoringPlot = {
        id: record.MP_Id,
        name: record.MP_Name,
        plotArea: record.MP_Area
      }

      // set plant crop model
      model.plantCrop = {
        id: record.PC_Id,
        year: record.PC_Year,
        cropId: record.PC_CropId,
        cropType: record.PC_CropType,
        sowingDate: this.helper.isEmpty(record.PC_SowingDate) ? null : record.PC_SowingDate + ' 00:00:00',
        harvestTime: this.helper.isEmpty(record.PC_HarvestTime) ? null : record.PC_HarvestTime + " 00:00:00",
        sowingArea: record.PC_SowingArea,
        plotFertility: record.PC_PlotFertility,
        yield: record.PC_Yield,
        facilityCultivationFlag: record.PC_FacilityCultivationFlag,
        happenDisasterFlag: record.PC_HappenDisasterFlag,
        previousCropId: 'NONE' === record.PC_PreviousCropId ? null : record.PC_PreviousCropId,
        previousYield: record.PC_PreviousYield,
        previousStrawReturnRate: record.PC_PreviousStrawReturnRate,
        remark1: null,
        remark2: null,
        afterFertilizerFillInMode: record.PC_AfterFertilizerFillInMode
      }

      // set base fertilizer model
      model.baseFertilizer = {
        fertilizationMode: record.BF_FertilizationMode,
        fertilizationDate: this.helper.isEmpty(record.BF_FertilizationDate) ? null : record.BF_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.BF_BusinessOrganicFertilizerDosage,
        businessOrganicFertilizerPrice: record.BF_BusinessOrganicFertilizerPrice,
        organicFertilizer1Id: record.BF_OrganicFertilizer1Id,
        organicFertilizer1Dosage: record.BF_OrganicFertilizer1Dosage,
        organicFertilizer2Id: record.BF_OrganicFertilizer2Id,
        organicFertilizer2Dosage: record.BF_OrganicFertilizer2Dosage,
        ureaDosage: record.BF_UreaDosage,
        ammoniumBicarbonateDosage: record.BF_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.BF_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.BF_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.BF_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.BF_CompoundFertilizerDosage,
        compoundFertilizerN: record.BF_CompoundFertilizerN,
        compoundFertilizerP2O5: record.BF_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.BF_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.BF_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.BF_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.BF_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.BF_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.BF_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.BF_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.BF_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.BF_OtherFertilizer2Dosage
      }

      // set after fertilizer total model
      model.afterFertilizerTotal = {
        fertilizationMode: record.AFT_FertilizationMode,
        fertilizationTimes: record.AFT_FertilizationTimes,
        fertilizationDate: this.helper.isEmpty(record.AFT_FertilizationDate) ? null : record.AFT_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.AFT_BusinessOrganicFertilizerDosage,
        ureaDosage: record.AFT_UreaDosage,
        ammoniumBicarbonateDosage: record.AFT_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.AFT_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.AFT_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.AFT_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.AFT_CompoundFertilizerDosage,
        compoundFertilizerN: record.AFT_CompoundFertilizerN,
        compoundFertilizerP2O5: record.AFT_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.AFT_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.AFT_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.AFT_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.AFT_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.AFT_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.AFT_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.AFT_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.AFT_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.AFT_OtherFertilizer2Dosage
      }

      // set after fertilizer 1 model
      model.afterFertilizer1 = {
        fertilizationMode: record.AF1_FertilizationMode,
        fertilizationDate: this.helper.isEmpty(record.AF1_FertilizationDate) ? null : record.AF1_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.AF1_BusinessOrganicFertilizerDosage,
        ureaDosage: record.AF1_UreaDosage,
        ammoniumBicarbonateDosage: record.AF1_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.AF1_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.AF1_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.AF1_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.AF1_CompoundFertilizerDosage,
        compoundFertilizerN: record.AF1_CompoundFertilizerN,
        compoundFertilizerP2O5: record.AF1_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.AF1_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.AF1_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.AF1_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.AF1_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.AF1_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.AF1_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.AF1_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.AF1_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.AF1_OtherFertilizer2Dosage
      }

      // set after fertilizer 2 model
      model.afterFertilizer2 = {
        fertilizationMode: record.AF2_FertilizationMode,
        fertilizationDate: this.helper.isEmpty(record.AF2_FertilizationDate) ? null : record.AF2_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.AF2_BusinessOrganicFertilizerDosage,
        ureaDosage: record.AF2_UreaDosage,
        ammoniumBicarbonateDosage: record.AF2_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.AF2_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.AF2_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.AF2_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.AF2_CompoundFertilizerDosage,
        compoundFertilizerN: record.AF2_CompoundFertilizerN,
        compoundFertilizerP2O5: record.AF2_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.AF2_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.AF2_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.AF2_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.AF2_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.AF2_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.AF2_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.AF2_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.AF2_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.AF2_OtherFertilizer2Dosage
      }

      // set after fertilizer 3 model
      model.afterFertilizer3 = {
        fertilizationMode: record.AF3_FertilizationMode,
        fertilizationDate: this.helper.isEmpty(record.AF3_FertilizationDate) ? null : record.AF3_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.AF3_BusinessOrganicFertilizerDosage,
        ureaDosage: record.AF3_UreaDosage,
        ammoniumBicarbonateDosage: record.AF3_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.AF3_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.AF3_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.AF3_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.AF3_CompoundFertilizerDosage,
        compoundFertilizerN: record.AF3_CompoundFertilizerN,
        compoundFertilizerP2O5: record.AF3_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.AF3_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.AF3_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.AF3_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.AF3_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.AF3_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.AF3_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.AF3_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.AF3_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.AF3_OtherFertilizer2Dosage
      }

      // set after fertilizer 4 model
      model.afterFertilizer4 = {
        fertilizationMode: record.AF4_FertilizationMode,
        fertilizationDate: this.helper.isEmpty(record.AF4_FertilizationDate) ? null : record.AF4_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.AF4_BusinessOrganicFertilizerDosage,
        ureaDosage: record.AF4_UreaDosage,
        ammoniumBicarbonateDosage: record.AF4_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.AF4_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.AF4_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.AF4_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.AF4_CompoundFertilizerDosage,
        compoundFertilizerN: record.AF4_CompoundFertilizerN,
        compoundFertilizerP2O5: record.AF4_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.AF4_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.AF4_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.AF4_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.AF4_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.AF4_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.AF4_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.AF4_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.AF4_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.AF4_OtherFertilizer2Dosage
      }

      // set after fertilizer 5 model
      model.afterFertilizer5 = {
        fertilizationMode: record.AF5_FertilizationMode,
        fertilizationDate: this.helper.isEmpty(record.AF5_FertilizationDate) ? null : record.AF5_FertilizationDate + ' 00:00:00',
        businessOrganicFertilizerDosage: record.AF5_BusinessOrganicFertilizerDosage,
        ureaDosage: record.AF5_UreaDosage,
        ammoniumBicarbonateDosage: record.AF5_AmmoniumBicarbonateDosage,
        diammoniumPhosphateDosage: record.AF5_DiammoniumPhosphateDosage,
        potassiumChlorideDosage: record.AF5_PotassiumChlorideDosage,
        potassiumSulphateDosage: record.AF5_PotassiumSulphateDosage,
        compoundFertilizerDosage: record.AF5_CompoundFertilizerDosage,
        compoundFertilizerN: record.AF5_CompoundFertilizerN,
        compoundFertilizerP2O5: record.AF5_CompoundFertilizerP2O5,
        compoundFertilizerK2O: record.AF5_CompoundFertilizerK2O,
        waterSolubleFertilizerDosage: record.AF5_WaterSolubleFertilizerDosage,
        waterSolubleFertilizerN: record.AF5_WaterSolubleFertilizerN,
        waterSolubleFertilizerP2O5: record.AF5_WaterSolubleFertilizerP2O5,
        waterSolubleFertilizerK2O: record.AF5_WaterSolubleFertilizerK2O,
        otherFertilizer1Id: record.AF5_OtherFertilizer1Id,
        otherFertilizer1Dosage: record.AF5_OtherFertilizer1Dosage,
        otherFertilizer2Id: record.AF5_OtherFertilizer2Id,
        otherFertilizer2Dosage: record.AF5_OtherFertilizer2Dosage
      }

      return model
    }
  }
}
</script>

<style scoped>

</style>