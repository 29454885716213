import { render, staticRenderFns } from "./RegionTree.vue?vue&type=template&id=52ee8069&scoped=true"
import script from "./RegionTree.vue?vue&type=script&lang=js"
export * from "./RegionTree.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ee8069",
  null
  
)

export default component.exports