import httpRequest from '@/utils/http-request'

export default {
  getOwnList(year) {
    let config = {
      params: {
        year: year
      }
    }
    return httpRequest.get('/archived-own-monitoring-plots', config)
  },
  getById(id) {
    return httpRequest.get('/archived-own-monitoring-plots/' + id)
  }
}
