<template>
  <div>
    <div class="wg-box">
      <div class="header" v-if="!fullPlantCropModel.plantCrop.submitFlag">
        <div class="button-list">
          <el-button size="small" class="wg-button green" icon="el-icon-edit" @click="handleSave">保存</el-button>
          <el-button size="small" class="wg-button green" icon="el-icon-refresh-left" @click="handleReset">刷新
          </el-button>
          <el-button size="small" class="wg-button red" icon="el-icon-delete" @click="handleDelete">删除</el-button>
          <el-button size="small" class="wg-button orange" icon="el-icon-finished" @click="handleSubmit">提交
          </el-button>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <div class="body">
        <el-form ref="tableForm" :model="tableForm" :show-message="false">
          <div class="wg-table-box">
            <el-table :data="tableForm.tableData" class="wg-table normal" :max-height="180" border style="width: 100%">
              <el-table-column label="序号" type="index" width="100" fixed></el-table-column>
              <el-table-column label="作物分类" prop="cropSubCategoryName" width="120" fixed></el-table-column>
              <el-table-column label="作物名称" prop="cropName" width="120" fixed>
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag">
                    <el-form-item :prop="'tableData.' + scope.$index + '.cropName'" :rules="tableForm.formRules.cropName">
                      <el-autocomplete size="small" v-model="scope.row.cropName" :fetch-suggestions="queryCropName" @select="handleCropNameSelect($event, scope.row)" @input="handleCropNameInput($event, scope.row)">
                        <template slot-scope="{item }">
                          <div>{{ item.value }}</div>
                        </template>
                      </el-autocomplete>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.cropRemark }}</div>
                </template>
              </el-table-column>
              <el-table-column label="作物备注" prop="cropRemark" width="120">
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag">
                    <el-form-item :prop="'tableData.' + scope.$index + '.cropRemark'" :rules="tableForm.formRules.cropRemark">
                      <el-input size="small" v-model="scope.row.cropRemark"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.cropRemark }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="sowingDate" label="播种日期" width="150">
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag">
                    <el-form-item :prop="'tableData.' + scope.$index + '.sowingDate'" :rules="tableForm.formRules.sowingDate">
                      <el-date-picker size="small" v-model="scope.row.sowingDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 130px"></el-date-picker>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.sowingDate }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="harvestTime" label="收获日期" width="150">
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag">
                    <el-form-item :prop="'tableData.' + scope.$index + '.harvestTime'" :rules="tableForm.formRules.harvestTime">
                      <el-date-picker size="small" v-model="scope.row.harvestTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" style="width: 130px"></el-date-picker>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.harvestTime }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="sowingArea" width="120">
                <template slot="header">播种面积<br />（亩）</template>
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag">
                    <el-form-item :prop="'tableData.' + scope.$index + '.sowingArea'" :rules="tableForm.formRules.sowingArea">
                      <el-input size="small" v-model="scope.row.sowingArea"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.sowingArea }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="plotFertilityName" label="地块肥力" width="120">
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag">
                    <el-form-item :prop="'tableData.' + scope.$index + '.plotFertility'" :rules="tableForm.formRules.plotFertility">
                      <el-select size="small" v-model="scope.row.plotFertility">
                        <el-option v-for="option in tableForm.filterData.plotFertilityOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.plotFertilityName }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="yield" width="120">
                <template slot="header">亩产量<br />（公斤/亩）</template>
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag">
                    <el-form-item :prop="'tableData.' + scope.$index + '.yield'" :rules="tableForm.formRules.yield">
                      <el-input size="small" v-model="scope.row.yield"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.yield }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="yieldLevelName" label="产量水平" width="120">
                <template slot="header">
                  产量水平
                  <el-tooltip content="产量水平指该作物产量相对当地水平如何。" placement="top">
                    <i class="el-icon-question" style="font-size: 18px; color:#E6A23C"></i>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag">
                    <el-form-item :prop="'tableData.' + scope.$index + '.yieldLevel'" :rules="tableForm.formRules.yieldLevel">
                      <el-select size="small" v-model="scope.row.yieldLevel" clearable>
                        <el-option v-for="option in tableForm.filterData.yieldLevelOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.yieldLevelName }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="facilityCultivationFlag" width="120">
                <template slot="header">是否<br />设施栽培</template>
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag">
                    <el-form-item :prop="'tableData.' + scope.$index + '.facilityCultivationFlag'" :rules="tableForm.formRules.facilityCultivationFlag">
                      <el-select size="small" v-model="scope.row.facilityCultivationFlag">
                        <el-option v-for="option in tableForm.filterData.facilityCultivationFlagOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div :style="scope.row.facilityCultivationFlag ? 'color:#039F3A' : 'color:#FF8900'" v-else>
                    {{ scope.row.facilityCultivationFlag ? '是' : '否' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="happenDisasterFlag" width="120">
                <template slot="header">是否<br />种植季受灾</template>
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag">
                    <el-form-item :prop="'tableData.' + scope.$index + '.happenDisasterFlag'" :rules="tableForm.formRules.happenDisasterFlag">
                      <el-select size="small" v-model="scope.row.happenDisasterFlag">
                        <el-option v-for="option in tableForm.filterData.happenDisasterFlagOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div :style="null === scope.row.happenDisasterFlag ? 'color:#333' : scope.row.happenDisasterFlag ? 'color:#039F3A' : 'color:#FF8900'" v-else>
                    {{ null === scope.row.happenDisasterFlag ? '--' : scope.row.happenDisasterFlag ? '是' : '否' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="previousCropSubCategoryName" label="前茬作物" width="120">
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag">
                    <el-form-item :prop="'tableData.' + scope.$index + '.previousCropSubCategoryId'" :rules="tableForm.formRules.previousCropSubCategoryId">
                      <el-select size="small" v-model="scope.row.previousCropSubCategoryId" @change="handlePreviousCropSubCategoryChange($event, scope.row)">
                        <el-option-group v-for="group in tableForm.filterData.previousCropSubCategoryOptionGroupList" :key="group.label" :label="group.label">
                          <el-option v-for="option in group.optionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                        </el-option-group>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div v-else>
                    {{ null === scope.row.previousCropSubCategoryName ? '无' : scope.row.previousCropSubCategoryName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="120">
                <template slot="header">
                  前茬作物亩产量
                  <br />
                  （公斤/亩）
                </template>
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag && null !== scope.row.previousCropSubCategoryId && 'NONE' !== scope.row.previousCropSubCategoryId">
                    <el-form-item :prop="'tableData.' + scope.$index + '.previousYield'" :rules="tableForm.formRules.previousYield">
                      <el-input size="small" v-model="scope.row.previousYield"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.previousYield }}</div>
                </template>
              </el-table-column>
              <el-table-column width="120">
                <template slot="header">
                  前茬秸秆还田率
                  <br />
                  （%）
                </template>
                <template slot-scope="scope">
                  <div v-if="!scope.row.submitFlag && null !== scope.row.previousCropSubCategoryId && 'NONE' !== scope.row.previousCropSubCategoryId">
                    <el-form-item :prop="'tableData.' + scope.$index + '.previousStrawReturnRate'" :rules="tableForm.formRules.previousStrawReturnRate">
                      <el-input size="small" v-model="scope.row.previousStrawReturnRate"></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>{{ scope.row.previousStrawReturnRate }}</div>
                </template>
              </el-table-column>
              <el-table-column label="是否提交" prop="submitFlag">
                <template slot-scope="scope">
                  <div :style="scope.row.submitFlag ? 'color:#039F3A' : 'color:#FF8900'">
                    {{ scope.row.submitFlag ? '是' : '否' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="submitTime" label="提交时间" width="140"></el-table-column>
            </el-table>
          </div>
        </el-form>
      </div>
    </div>
    <el-collapse class="wg-collapse" v-model="collapseActiveNames">
      <el-collapse-item name="baseFertilizer">
        <template slot="title">
          <div class="header background-style1">
            <div class="title">
              底肥信息
              <span>（单位：公斤/亩）</span>
            </div>
          </div>
          <div class="wg-clear-fix"></div>
        </template>
        <base-fertilizer ref="baseFertilizerComponent" :fullPlantCropModel="fullPlantCropModel" @delete-success="handleFertilizerUpdate"></base-fertilizer>
      </el-collapse-item>
      <!-- 底肥信息 -->
      <el-collapse-item name="afterFertilizer">
        <template slot="title">
          <div class="header background-style1">
            <div class="title">
              追肥信息
              <span>（单位：公斤/亩）</span>
            </div>
          </div>
          <div class="wg-clear-fix"></div>
        </template>
        <after-fertilizer ref="afterFertilizerComponent" :fullPlantCropModel="fullPlantCropModel" @delete-success="handleFertilizerUpdate"></after-fertilizer>
      </el-collapse-item>
      <!-- 追肥信息 -->
      <el-collapse-item name="unitAreaChemicalFertilizerDosage">
        <template slot="title">
          <div class="header background-style1">
            <div class="title">
              单位面积化肥养分投入量
              <span>（单位：公斤/亩）</span>
            </div>
          </div>
          <div class="wg-clear-fix"></div>
        </template>
        <unit-area-chemical-fertilizer-dosage ref="untAreaChemicalFertilizerDosageComponent" :fullPlantCropModel="fullPlantCropModel"></unit-area-chemical-fertilizer-dosage>
      </el-collapse-item>
      <!-- 单位面积 -->
    </el-collapse>
    <el-dialog title="提示" :visible.sync="tipDialogVisible" width="30%" @close="handleCertain">
      <div>
        <img src="@/assets/images/icons/ok.png" alt="" class="ok-icon" />
      </div>
      <div class="get-point-text-style">提交成功，获得{{ integration.getIntegration }}积分</div>
      <div class="total-point-text-style">累计{{ integration.integration }}积分</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="wg-button" size="small" @click="handleCertain()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import PlantCropApi from '@/api/plant-crop-api'
import FullPlantCropApi from '@/api/full-plant-crop-api'

import BaseFertilizer from './BaseFertilizer'
import AfterFertilizer from './AfterFertilizer'
import UnitAreaChemicalFertilizerDosage from './UnitAreaChemicalFertilizerDosage'

export default {
  name: 'ProfessionalPlantCrop',
  components: {
    'base-fertilizer': BaseFertilizer,
    'after-fertilizer': AfterFertilizer,
    'unit-area-chemical-fertilizer-dosage': UnitAreaChemicalFertilizerDosage
  },
  props: {
    fullPlantCropModel: Object,
    cropRootCategoryArray: [],
    cropSubCategoryArray: [],
    cropArray: []
  },
  data() {
    const validateSowingDate = (rule, value, callback) => {
      let harvestTime = this.tableForm.tableData[0].harvestTime
      if (null !== value && null !== harvestTime) {
        let sowingDateNum = this.$moment(value).unix()
        let harvestTimeNum = this.$moment(harvestTime).unix()
        if (sowingDateNum > harvestTimeNum) {
          callback(Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateHarvestTime = (rule, value, callback) => {
      let sowingDate = this.tableForm.tableData[0].sowingDate
      if (null !== value && null !== sowingDate) {
        let harvestTimeNum = this.$moment(value).unix()
        let sowingDateNum = this.$moment(sowingDate).unix()
        if (sowingDateNum > harvestTimeNum) {
          callback(Error())
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const validateNumber = (rule, value, callback) => {
      if (this.$utils.isNotEmpty(value)) {
        const reg = /^([0-9]\d*(\.\d*)?)$|^0\.\d*[1-9]\d*$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入数字！'))
        }
      } else {
        callback()
      }
    }
    return {
      collapseActiveNames: ['baseFertilizer', 'afterFertilizer', 'unitAreaChemicalFertilizerDosage'],
      tableForm: {
        filterData: {
          previousCropSubCategoryOptionGroupList: [],
          plotFertilityOptionList: [],
          yieldLevelOptionList: [],
          facilityCultivationFlagOptionList: [],
          happenDisasterFlagOptionList: []
        },
        formRules: {
          cropName: [{ required: true, trigger: 'change' }],
          cropRemark: [{ required: false, trigger: 'blur' }],
          sowingDate: [{ trigger: 'blur', validator: validateSowingDate }],
          harvestTime: [{ trigger: 'blur', validator: validateHarvestTime }],
          sowingArea: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber }
          ],
          plotFertility: [{ required: true, trigger: 'change' }],
          yield: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber }
          ],
          yieldLevel: [{ required: true, trigger: 'change' }],
          facilityCultivationFlag: [{ required: true, trigger: 'change' }],
          happenDisasterFlag: [{ required: false, trigger: 'change' }],
          previousCropSubCategoryId: [{ required: true, trigger: 'change' }],
          previousYield: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber }
          ],
          previousStrawReturnRate: [
            { required: true, trigger: 'blur' },
            { trigger: 'blur', validator: validateNumber }
          ]
        },
        tableData: []
      },
      savedFlag: false,
      tipDialogVisible: false,
      integration: {
        getIntegration: 100,
        integration: null
      }
    }
  },
  created() {
    // previousCropSubCategoryOptionGroupList
    this.tableForm.filterData.previousCropSubCategoryOptionGroupList.push({
      label: '默认',
      optionList: [{ label: '无', value: 'NONE' }]
    })
    this.cropRootCategoryArray.forEach(el => {
      let cropCategoryGroup = { label: el.name, optionList: [] }

      let tempCropSubCategoryArray = this.cropSubCategoryArray.filter(el1 => {
        return el1.parentId === el.id
      })

      tempCropSubCategoryArray.forEach(el1 => {
        cropCategoryGroup.optionList.push({ label: el1.name, value: el1.id })
      })

      this.tableForm.filterData.previousCropSubCategoryOptionGroupList.push(cropCategoryGroup)
    })
    // plotFertilityOptionList
    this.tableForm.filterData.plotFertilityOptionList.push({ label: '高', value: 'HIGH' })
    this.tableForm.filterData.plotFertilityOptionList.push({ label: '中', value: 'MIDDLE' })
    this.tableForm.filterData.plotFertilityOptionList.push({ label: '低', value: 'LOW' })
    // yieldLevelOptionList
    this.tableForm.filterData.yieldLevelOptionList.push({ label: '高', value: 'HIGH' })
    this.tableForm.filterData.yieldLevelOptionList.push({ label: '中', value: 'MIDDLE' })
    this.tableForm.filterData.yieldLevelOptionList.push({ label: '低', value: 'LOW' })
    // facilityCultivationFlagOptionList
    this.tableForm.filterData.facilityCultivationFlagOptionList.push({ label: '是', value: true })
    this.tableForm.filterData.facilityCultivationFlagOptionList.push({ label: '否', value: false })
    // happenDisasterFlagOptionList
    this.tableForm.filterData.happenDisasterFlagOptionList.push({ label: '是', value: true })
    this.tableForm.filterData.happenDisasterFlagOptionList.push({ label: '否', value: false })
    // init
    let plantCropModel = this.fullPlantCropModel.plantCrop
    this.tableForm.tableData.push({
      id: plantCropModel.id,
      year: plantCropModel.year,
      monitoringPlotId: plantCropModel.monitoringPlotId,
      cropRootCategoryId: plantCropModel.cropRootCategoryId,
      cropRootCategoryName: plantCropModel.cropRootCategoryName,
      cropSubCategoryId: plantCropModel.cropSubCategoryId,
      cropSubCategoryName: plantCropModel.cropSubCategoryName,
      cropId: plantCropModel.cropId,
      cropName: plantCropModel.cropName,
      cropRemark: plantCropModel.cropRemark,
      sowingDate: null === plantCropModel.sowingDate ? null : this.$moment(plantCropModel.sowingDate).format('YYYY-MM-DD'),
      harvestTime: null === plantCropModel.harvestTime ? null : this.$moment(plantCropModel.harvestTime).format('YYYY-MM-DD'),
      sowingArea: plantCropModel.sowingArea,
      plotFertility: plantCropModel.plotFertility,
      plotFertilityName: plantCropModel.plotFertilityName,
      yield: null === plantCropModel.yield ? null : plantCropModel.yield.toFixed(2),
      yieldLevel: plantCropModel.yieldLevel,
      yieldLevelName: plantCropModel.yieldLevelName,
      facilityCultivationFlag: plantCropModel.facilityCultivationFlag,
      happenDisasterFlag: plantCropModel.happenDisasterFlag,
      previousCropSubCategoryId: null === plantCropModel.previousCropSubCategoryId ? (null === plantCropModel.modifiedOn ? null : 'NONE') : plantCropModel.previousCropSubCategoryId,
      previousCropSubCategoryName: plantCropModel.previousCropSubCategoryName,
      previousYield: plantCropModel.previousYield,
      previousStrawReturnRate: plantCropModel.previousStrawReturnRate,
      submitFlag: plantCropModel.submitFlag,
      submitTime: plantCropModel.submitTime,
      remark1: plantCropModel.remark1,
      remark2: plantCropModel.remark2
    })

    this.savedFlag = null !== plantCropModel.modifiedOn
  },
  methods: {
    queryCropName(queryStr, cb) {
      let cropOptionList = []
      let tempCropArray = this.cropArray.filter(m => {
        return m.subCategoryId === this.fullPlantCropModel.plantCrop.cropSubCategoryId
      })

      tempCropArray.forEach(el => {
        cropOptionList.push({ value: el.name })
      })

      cb(cropOptionList)
    },
    handleCropNameSelect(item, row) {
      row.cropId = item.id
      row.cropName = item.value
    },
    handleCropNameInput(value, row) {
      let crop = this.cropArray.find(m => m.name === value)
      if (undefined === crop)
        row.cropId = null
      else
        row.cropId = crop.id
    },
    handlePreviousCropSubCategoryChange(value, row) {
      if (value === null || 'NONE' === value) {
        row.previousCropSubCategoryName = '无'
        row.previousYield = null
        row.previousStrawReturnRate = null
      } else {
        let cropSubCategory = this.cropSubCategoryArray.find(m => m.id === value)
        if (null !== cropSubCategory)
          row.previousCropSubCategoryName = cropSubCategory.name
      }
    },
    async handleSave() {
      let plantCropFormValidateResult = false
      await this.$refs.tableForm.validate(valid => {
        plantCropFormValidateResult = valid
        return valid
      })

      let baseFertilizerFormValidateResult = await this.$refs.baseFertilizerComponent.validateForm()
      let afterFertilizerFormValidateResult = await this.$refs.afterFertilizerComponent.validateForm()

      if (plantCropFormValidateResult && baseFertilizerFormValidateResult && afterFertilizerFormValidateResult) {
        await this.update(this.tableForm.tableData[0])
      } else {
        this.$message({ showClose: true, message: '保存验证失败！', type: 'warning' })
        setTimeout(() => {
          let isError = document.getElementsByClassName('is-error')
          isError[0].querySelector('input').focus()
        }, 100)
      }
    },
    handleReset() {
      this.reloadRowData(this.tableForm.tableData[0])
    },
    handleDelete() {
      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          PlantCropApi.deleteById(this.fullPlantCropModel.plantCrop.id).then(() => {
            this.$message({ showClose: true, message: '记录删除成功！', type: 'success' })
            this.$emit('delete')
          })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    handleSubmit() {
      if (!this.savedFlag) {
        this.$message({ showClose: true, message: '请先保存数据然后再提交！', type: 'warning' })
        return
      }

      this.$confirm('此操作将提交数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await PlantCropApi.submit(this.fullPlantCropModel.plantCrop.id)
            .then(res => {
              if ('SUCCESS' === res.data.state) {
                this.$message({ showClose: true, message: res.data.message, type: 'success' })
                this.fullPlantCropModel.plantCrop.submitFlag = true
                this.reloadRowData(this.tableForm.tableData[0])
              } else if ('WARNING' === res.data.state) {
                this.$message({ showClose: true, message: res.data.message, type: 'warning' })
              } else {
                this.$message({ showClose: true, message: '数据提报异常！', type: 'error' })
              }
            })
            .catch(err => {
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })

          await PlantCropApi.getAccount()
            .then(res => {
              this.integration.integration = res.data.integration
            })
            .catch(err => {
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消操作！', type: 'info' })
        })
    },
    handleFertilizerUpdate() {
      this.reloadRowData(this.tableForm.tableData[0])
    },
    handleCertain() {
      this.tipDialogVisible = false
    },
    async update(row) {
      let baseFertilizerModel = this.$refs.baseFertilizerComponent.getBaseFertilizerModel()
      let afterFertilizerModels = this.$refs.afterFertilizerComponent.getAfterFertilizerModels()

      if (null === this.fullPlantCropModel.plantCrop.afterFertilizerFillInMode) {
        this.$message({ showClose: true, message: '追肥信息【是否分次填报】未选择！', type: 'warning' })
        return
      }

      if ('NONE' !== this.fullPlantCropModel.plantCrop.afterFertilizerFillInMode && 0 === afterFertilizerModels.length) {
        this.$message({ showClose: true, message: '追肥信息没有填报数据！', type: 'warning' })
        return
      }

      let updateModel = {
        plantCrop: null,
        baseFertilizer: null,
        afterFertilizerList: []
      }

      updateModel.plantCrop = {
        id: row.id,
        year: row.year,
        monitoringPlotId: row.monitoringPlotId,
        cropRootCategoryId: row.cropRootCategoryId,
        cropRootCategoryName: row.cropRootCategoryName,
        cropSubCategoryId: row.cropSubCategoryId,
        cropSubCategoryName: row.cropSubCategoryName,
        cropId: row.cropId,
        cropName: row.cropName,
        cropRemark: this.$utils.isEmpty(row.cropRemark) ? null : row.cropRemark,
        sowingDate: null === row.sowingDate ? null : row.sowingDate + ' 00:00:00',
        harvestTime: null === row.harvestTime ? null : row.harvestTime + ' 00:00:00',
        sowingArea: this.$utils.isEmpty(row.sowingArea) ? null : row.sowingArea,
        plotFertility: row.plotFertility,
        yield: this.$utils.isEmpty(row.yield) ? null : row.yield,
        yieldLevel: row.yieldLevel,
        facilityCultivationFlag: row.facilityCultivationFlag,
        happenDisasterFlag: row.happenDisasterFlag,
        previousCropSubCategoryId: 'NONE' === row.previousCropSubCategoryId ? null : row.previousCropSubCategoryId,
        previousCropSubCategoryName: this.$utils.isEmpty(row.previousCropSubCategoryName) ? '无' : row.previousCropSubCategoryName,
        previousYield: row.previousYield,
        previousStrawReturnRate: row.previousStrawReturnRate,
        remark1: this.$utils.isEmpty(row.remark1) ? null : row.remark1,
        remark2: this.$utils.isEmpty(row.remark2) ? null : row.remark2,
        afterFertilizerFillInMode: this.fullPlantCropModel.plantCrop.afterFertilizerFillInMode
      }

      if (null !== baseFertilizerModel) {
        updateModel.baseFertilizer = baseFertilizerModel
      }

      if (afterFertilizerModels.length > 0) {
        updateModel.afterFertilizerList = afterFertilizerModels
      }

      await FullPlantCropApi.update(updateModel)
        .then(() => {
          this.$message({ showClose: true, message: '保存成功！', type: 'success' })
          this.reloadRowData(this.tableForm.tableData[0])
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    async reloadRowData(row) {
      await FullPlantCropApi.getById(row.id).then(res => {
        let plantCropModel = res.data.plantCrop
        let baseFertilizerModel = res.data.baseFertilizer
        let afterFertilizerModelList = res.data.afterFertilizerList
        let unitAreaChemicalFertilizerDosageModel = res.data.unitAreaChemicalFertilizerDosage

        this.fullPlantCropModel.plantCrop = plantCropModel
        this.fullPlantCropModel.baseFertilizer = baseFertilizerModel
        this.fullPlantCropModel.afterFertilizerList = afterFertilizerModelList
        this.fullPlantCropModel.unitAreaChemicalFertilizerDosage = unitAreaChemicalFertilizerDosageModel

        row.id = plantCropModel.id
        row.cropRootCategoryId = plantCropModel.cropRootCategoryId
        row.cropRootCategoryName = plantCropModel.cropRootCategoryName
        row.cropSubCategoryId = plantCropModel.cropSubCategoryId
        row.cropSubCategoryName = plantCropModel.cropSubCategoryName
        row.cropId = plantCropModel.cropId
        row.cropName = plantCropModel.cropName
        row.cropRemark = plantCropModel.cropRemark
        row.sowingDate = this.$utils.isEmpty(plantCropModel.sowingDate) ? null : this.$moment(plantCropModel.sowingDate).format('YYYY-MM-DD')
        row.harvestTime = this.$utils.isEmpty(plantCropModel.harvestTime) ? null : this.$moment(plantCropModel.harvestTime).format('YYYY-MM-DD')
        row.sowingArea = plantCropModel.sowingArea
        row.plotFertility = plantCropModel.plotFertility
        row.plotFertilityName = plantCropModel.plotFertilityName
        row.yield = plantCropModel.yield
        row.yieldLevel = plantCropModel.yieldLevel
        row.yieldLevelName = plantCropModel.yieldLevelName
        row.facilityCultivationFlag = plantCropModel.facilityCultivationFlag
        row.happenDisasterFlag = plantCropModel.happenDisasterFlag
        row.previousCropSubCategoryId = null === plantCropModel.previousCropSubCategoryId ? (null === plantCropModel.modifiedOn ? null : 'NONE') : plantCropModel.previousCropSubCategoryId
        row.previousCropSubCategoryName = plantCropModel.previousCropSubCategoryName
        row.previousYield = plantCropModel.previousYield
        row.previousStrawReturnRate = plantCropModel.previousStrawReturnRate
        row.submitFlag = plantCropModel.submitFlag
        row.submitTime = plantCropModel.submitTime
        row.remark1 = plantCropModel.remark1
        row.remark2 = plantCropModel.remark2

        this.savedFlag = null !== plantCropModel.modifiedOn

        this.$refs.baseFertilizerComponent.loadData()
        this.$refs.afterFertilizerComponent.loadData()
        this.$refs.untAreaChemicalFertilizerDosageComponent.loadData()
      })
    }
  }
}
</script>
<style scoped></style>
