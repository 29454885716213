<template>
  <div>
    <OrganicFertilizer ref="organicfertilizer" :params="params"></OrganicFertilizer>
    <div class='chart-divider'></div>
    <ChemicalFertilizer ref="chemicalfertilizer" :params="params"></ChemicalFertilizer>
    <div class='chart-divider'></div>
      <el-row :gutter='12'>
        <el-col :span='12'>
          <FertilizerVariety ref="fertilizervariety" :params="params"></FertilizerVariety>
        </el-col>
        <el-col :span='12'>
          <AreaAndCoverage ref="areaandcoverage" :params="params"></AreaAndCoverage>
        </el-col>
      </el-row>

  </div>
</template>

<script>
import OrganicFertilizer from '@/views/chart-analysis/components/fertilize/OrganicFertilizer'
import ChemicalFertilizer from '@/views/chart-analysis/components/fertilize/ChemicalFertilizer'
import FertilizerVariety from '@/views/chart-analysis/components/fertilize/FertilizerVariety'
import AreaAndCoverage from '@/views/chart-analysis/components/fertilize/AreaAndCoverage'

export default {
  name: 'ChartGroup-Fertilize',
  components: {
    AreaAndCoverage,
    FertilizerVariety,
    ChemicalFertilizer,
    OrganicFertilizer
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  methods: {
    refreshCharts() {
      this.$nextTick(() => {
        this.$refs.organicfertilizer.refresh()
        this.$refs.chemicalfertilizer.refresh()
        this.$refs.fertilizervariety.refresh()
        this.$refs.areaandcoverage.refresh()
      })
    }
  }
}
</script>

<style scoped>

.el-card {
  border: 1px solid #D6D6D6;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 4px 4px;
}

.el-card ::v-deep .el-card__header {
  padding: 2px 10px;
  height: 50px;
}

.el-card ::v-deep .el-card__body {
  padding: 0px;
  background: #FFFFFF;
  border: 0.5px solid #D6D6D6;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 4px 4px;
}
</style>
