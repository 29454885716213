import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/soils', createModel)
  },
  update(updateModel) {
    return httpRequest.put('/soils', updateModel)
  },
  deleteById(id) {
    return httpRequest.delete('/soils/' + id)
  },
  getById(id) {
    return httpRequest.get('/soils/' + id)
  },
  getList() {
    return httpRequest.get('/soils')
  },
  getStubList() {
    return httpRequest.get('/soil-stubs')
  }
}
