<template>
  <div>
    <el-dialog :title="windowTitle" :visible.sync="windowVisible" width="440px" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
      <el-form :model="form" :rules="formRules" ref="form" label-position="left" label-width="100px">
        <el-form-item label="作物分类" prop="subCategoryId">
          <el-select v-model="form.subCategoryId" style="width: 100%">
            <el-option v-for="option in subCategoryOptionList" :key="option.value" :label="option.label" :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="序号" prop="sortNumber">
          <el-input v-model="form.sortNumber" type="number"></el-input>
        </el-form-item>
        <el-form-item label="作物名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" class="wg-button blank" @click="windowVisible = false">取 消</el-button>
        <el-button size="small" class="wg-button" @click="handleSave">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CropApi from '@/api/crop/crop-api'
import CropCategoryApi from '@/api/crop/crop-category-api'

export default {
  name: 'CropCreate',
  data() {
    return {
      windowTitle: '新增作物',
      windowVisible: false,
      subCategoryOptionList: [],
      rootCategoryId: '',
      form: {
        subCategoryId: '',
        sortNumber: null,
        name: ''
      },
      formRules: {
        subCategoryId: [{ required: true, trigger: 'change', message: '请选择作物分类！' }],
        sortNumber: [{ required: true, trigger: 'blur', message: '请填写序号！' }],
        name: [{ required: true, trigger: 'blur', message: '请填写名称！' }]
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('reload')
    },
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return

        let createModel = {
          rootCategoryId: this.rootCategoryId,
          subCategoryId: this.form.subCategoryId,
          sortNumber: this.form.sortNumber,
          name: this.form.name,
          englishName: null
        }

        CropApi.create(createModel).then(() => {
          this.$message({ type: 'success', message: '保存成功！' })
          this.windowVisible = false
        }).catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
      })
    },
    open(rootCategoryId) {
      this.rootCategoryId = rootCategoryId
      CropCategoryApi.getSubStubList(this.rootCategoryId).then(res => {
        res.data.forEach(el => {
          this.subCategoryOptionList.push({ value: el.id, label: el.name })
        })
      }).catch(err => {
        this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
      })
      this.windowVisible = true
    }
  }
}
</script>

<style scoped>

</style>