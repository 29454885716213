<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>数据填报</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path: '/surface-survey'}">面上调查</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <el-container>
      <el-aside class="wg-side" :width="isCollapse ? '24px' : '240px'">
        <div v-show="!isCollapse">
          <div class="side-fence">
            面上调查
            <i class="el-icon-d-arrow-left collapse-button" @click="handleToggleCollapse"></i>
          </div>
          <region-tree @node-click="handleRegionTreeNodeClick"></region-tree>
        </div>
        <div v-show="isCollapse" class="collapse-fence">
          展开菜单栏
          <div class="collapse-icon">
            <i class="el-icon-d-arrow-right" @click="handleToggleCollapse"></i>
          </div>
        </div>
      </el-aside>
      <el-main class="wg-main">
        <el-collapse v-model="activeNames" class="wg-collapse">
          <el-collapse-item name="Country" v-if="flags.enableCountryCollapse">
            <template slot="title">
              <div class="header background-style1">
                <div class="title">国家数据汇总</div>
              </div>
              <div class="wg-clear-fix"></div>
            </template>
            <surface-survey-country ref="countryComponent"></surface-survey-country>
          </el-collapse-item>
          <el-collapse-item title="省级数据填报" name="Province" v-if="flags.enableProvinceCollapse">
            <template slot="title">
              <div class="header background-style1">
                <div class="title">省级数据填报</div>
              </div>
              <div class="wg-clear-fix"></div>
            </template>
            <surface-survey-province
              ref="provinceComponent"
              v-if="flags.isLoadProvinceComponent"
              :provinceList="provinceList"
              :cropRootCategoryArray="cropRootCategoryArray"
              :cropSubCategoryArray="cropSubCategoryArray"
              :cropArray="cropArray">
            </surface-survey-province>
          </el-collapse-item>
          <el-collapse-item title="地市数据填报" name="City" v-if="flags.enableCityCollapse">
            <template slot="title">
              <div class="header background-style1">
                <div class="title">地市数据填报</div>
              </div>
              <div class="wg-clear-fix"></div>
            </template>
            <surface-survey-city
              ref="cityComponent"
              v-if="flags.isLoadCityComponent"
              :provinceList="provinceList"
              :cityList="cityList"
              :cropRootCategoryArray="cropRootCategoryArray"
              :cropSubCategoryArray="cropSubCategoryArray"
              :cropArray="cropArray">
            </surface-survey-city>
          </el-collapse-item>
          <el-collapse-item title="区县数据填报" name="District" v-if="flags.enableDistrictCollapse">
            <template slot="title">
              <div class="header background-style1">
                <div class="title">区县数据填报</div>
              </div>
              <div class="wg-clear-fix"></div>
            </template>
            <surface-survey-district
              ref="districtComponent"
              v-if="flags.isLoadDistrictComponent"
              :provinceList="provinceList"
              :cityList="cityList"
              :districtList="districtList"
              :cropRootCategoryArray="cropRootCategoryArray"
              :cropSubCategoryArray="cropSubCategoryArray"
              :cropArray="cropArray"
            >
            </surface-survey-district>
          </el-collapse-item>
        </el-collapse>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import RegionApi from '@/api/region-api'
import CropCategoryApi from '@/api/crop/crop-category-api'
import CropApi from '@/api/crop/crop-api'
import FillInCycleApi from '@/api/data-fill-in/cycle/fill-in-cycle-api'

import RegionTree from '@/components/RegionTree'

import SurfaceSurveyCountry from './components/SurfaceSurveyCountry'
import SurfaceSurveyProvince from './components/SurfaceSurveyProvince'
import SurfaceSurveyCity from './components/SurfaceSurveyCity'
import SurfaceSurveyDistrict from './components/SurfaceSurveyDistrict'

export default {
  name: 'SurfaceSurvey',
  components: {
    'region-tree': RegionTree,
    'surface-survey-country': SurfaceSurveyCountry,
    'surface-survey-province': SurfaceSurveyProvince,
    'surface-survey-city': SurfaceSurveyCity,
    'surface-survey-district': SurfaceSurveyDistrict
  },
  data() {
    return {
      flags: {
        enableCountryCollapse: false,
        enableProvinceCollapse: false,
        enableCityCollapse: false,
        enableDistrictCollapse: false,
        isLoadProvinceComponent: false,
        isLoadCityComponent: false,
        isLoadDistrictComponent: false
      },
      isCollapse: false,
      params: {
        year: null,
        regionId: null,
        archiveFlag: null
      },
      activeNames: [],
      provinceList: [],
      cityList: [],
      districtList: [],
      cropRootCategoryArray: [],
      cropSubCategoryArray: [],
      cropArray: []
    }
  },
  async created() {
    await RegionApi.getProvinceList().then(res => {
      res.data.forEach(el => {
        this.provinceList.push({id: el.id, name: el.name})
      })
    })

    await RegionApi.getCityList(null).then(res => {
      res.data.forEach(el => {
        this.cityList.push({id: el.id, name: el.name})
      })
    })

    await RegionApi.getDistrictList(null).then(res => {
      res.data.forEach(el => {
        this.districtList.push({id: el.id, name: el.name})
      })
    })

    await CropCategoryApi.getRootStubList().then(res => {
      res.data.forEach(el => {
        this.cropRootCategoryArray.push({ id: el.id, name: el.name })
      })
    })

    await CropCategoryApi.getSubStubList(null).then(res => {
      res.data.forEach(el => {
        this.cropSubCategoryArray.push({ id: el.id, name: el.name, parentId: el.parentId })
      })
    })

    await CropApi.getStubList(null, null).then(res => {
      res.data.forEach(el => {
        this.cropArray.push({
          id: el.id,
          name: el.name,
          rootCategoryId: el.rootCategoryId,
          subCategoryId: el.subCategoryId
        })
      })
    })

    await FillInCycleApi.getLast().then(res => {
      this.params.year = res.data.year
      this.params.archiveFlag = res.data.archiveFlag

      let userLevelId = sessionStorage.getItem('levelId')
      if ('Country' === userLevelId) {
        this.activeNames.push('Country')
        this.activeNames.push('Province')
        this.flags.enableCountryCollapse = true
        this.flags.enableProvinceCollapse = true
        this.flags.enableCityCollapse = true
        this.flags.enableDistrictCollapse = true
        this.flags.isLoadProvinceComponent = true
        this.flags.isLoadCityComponent = true
        this.flags.isLoadDistrictComponent = true
        this.$nextTick(() => {
          this.$refs.countryComponent.load(this.params.year, this.params.regionId)
          this.$refs.provinceComponent.load(this.params.year, this.params.regionId, this.params.archiveFlag)
          this.$refs.cityComponent.load(this.params.year, this.params.regionId, this.params.archiveFlag)
          this.$refs.districtComponent.load(this.params.year, this.params.regionId, this.params.archiveFlag)
        })
      } else if ('Province' === userLevelId) {
        this.params.regionId = sessionStorage.getItem('provinceId')
        this.activeNames.push('Province')
        this.flags.enableProvinceCollapse = true
        this.flags.enableCityCollapse = true
        this.flags.enableDistrictCollapse = true
        this.flags.isLoadProvinceComponent = true
        this.flags.isLoadCityComponent = true
        this.flags.isLoadDistrictComponent = true
        this.$nextTick(() => {
          this.$refs.provinceComponent.load(this.params.year, this.params.regionId, this.params.archiveFlag)
          this.$refs.cityComponent.load(this.params.year, this.params.regionId, this.params.archiveFlag)
          this.$refs.districtComponent.load(this.params.year, this.params.regionId, this.params.archiveFlag)
        })
      } else if ('City' === userLevelId) {
        this.params.regionId = sessionStorage.getItem('cityId')
        this.activeNames.push('City')
        this.flags.enableCityCollapse = true
        this.flags.enableDistrictCollapse = true
        this.flags.isLoadCityComponent = true
        this.flags.isLoadDistrictComponent = true
        this.$nextTick(() => {
          this.$refs.cityComponent.load(this.params.year, this.params.regionId, this.params.archiveFlag)
          this.$refs.districtComponent.load(this.params.year, this.params.regionId, this.params.archiveFlag)
        })
      } else if ('District' === userLevelId) {
        this.params.regionId = sessionStorage.getItem('districtId')
        this.activeNames.push('District')
        this.flags.enableDistrictCollapse = true
        this.flags.isLoadDistrictComponent = true
        this.$nextTick(() => {
          this.$refs.districtComponent.load(this.params.year, this.params.regionId, this.params.archiveFlag)
        })
      } else {
        this.$router.push({path: '/'})
      }
    })
  },
  methods: {
    handleToggleCollapse() {
      this.isCollapse = !this.isCollapse
    },
    handleRegionTreeNodeClick(checkedItem) {
      this.params.year = checkedItem.year
      this.params.regionId = checkedItem.regionId
      this.params.archiveFlag = checkedItem.archiveFlag

      if (this.flags.enableCountryCollapse) {
        this.$refs.countryComponent.load(this.params.year, this.params.regionId)
      }

      if (this.flags.enableProvinceCollapse) {
        this.$refs.provinceComponent.load(this.params.year, this.params.regionId, this.params.archiveFlag)
      }

      if (this.flags.enableCityCollapse) {
        this.$refs.cityComponent.load(this.params.year, this.params.regionId, this.params.archiveFlag)
      }

      if (this.flags.enableDistrictCollapse) {
        this.$refs.districtComponent.load(this.params.year, this.params.regionId, this.params.archiveFlag)
      }
    }
  }
}
</script>

<style scoped></style>