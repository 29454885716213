<template>
  <el-card>
    <div slot="header" class="chart-title">
      <span>养分结构</span>
    </div>
    <div>
      <el-row :gutter="20" type="flex">
        <el-col :span="12" style="padding-left: 40px;">
          <v-chart
            :option="options"
            :init-options="initOptions"
            ref="chart"
            :loading='barLoading'
            :loadingOptions='barLoadingOptions'
            :updateOptions="{notMerge: true}"
            autoresize
          />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import VChart from 'vue-echarts'
import {CanvasRenderer, SVGRenderer} from 'echarts/renderers'

import {
  use
} from 'echarts/core'

import {
  BarChart,
  GaugeChart
} from 'echarts/charts'

import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components'
import DataVisualizationApi from '@/api/chart-analysis/data-visualization-api'

use([
  BarChart,
  GaugeChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
])

export default {
  name: "NutrientStructure",
  components: {
    VChart
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas"
      },
      options: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      seconds: -1,
      barLoading: false,
      barLoadingOptions: {
        text: "加载中…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0.4)"
      },
    };
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    getChartData() {
      this.options = {}
      this.barLoading = true
      DataVisualizationApi.getNutrientStructure(this.params).then(res => {
        if(res.data.length === 0) {
          this.options = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }
        const colors = ['#44A1FF', '#31BF5D', '#FFC44A']
        this.options = {
          textStyle: {
            fontSize: 24
          },
          graphic: [
            {
              type: 'group',
              bounding: 'raw',
              left: '45%',
              bottom: 50,
              z: 100,
              children: [
                {
                  type: 'rect',
                  left: 'center',
                  top: 'center',
                  z: 100,
                  shape: {
                    width: 1000,
                    height: 100,
                    r: 4
                  },
                  style: {
                    fill: '#EFEFEF'
                  }
                },
                {
                  type: 'text',
                  left: 'center',
                  top: 'center',
                  z: 100,
                  style: {
                    fill: '#383838',
                    text: res.data.map(p => p.name).join(' : ') + ' = ' + res.data.map(p => res.data[0].value === 0 ? 0 : Number(p.value/res.data[0].value).toFixed(1)).join(" : "),
                    font: 'bold 24px',
                    // 垂直居中
                    textVerticalAlign: "middle",
                    // 水平居中
                    textAlign: "center",
                  }
                }
              ]
            },
            {
              type: 'text',     // 类型：文本
                left: 'center',
                top: 'middle',
                silent: true,     // 不响应事件
                invisible: res.data.length > 0,   // 有数据就隐藏
                style: {
                fill: '#9d9d9d',
                  fontWeight: 'bold',
                  text: '暂无数据',
                  fontFamily: 'Microsoft YaHei',
                  fontSize: '25px'
              }
            }
          ],
          series: [
            {
              name: res.data[0].name,
              type: 'gauge',
              startAngle: 90,
              endAngle: -270,
              radius: '50%',
              center: ['20%', '35%'],
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false,
                fontSize: 14,
              },
              axisLine: {
                show: false,
                lineStyle: {
                  width: 25
                }
              },
              anchor: {},
              pointer: {
                show: false
              },
              title: {
                show: false,
                fontSize: 16,
                fontWeight: 400,
                offsetCenter: [0, 0]
              },
              progress: {
                show: false,
                width: 25,
                itemStyle: {
                  color: colors[0]
                }
              },
              detail: {
                show: true,
                fontSize: 24,
                color: colors[0],
                formatter: function (value) { // 展示处理
                  return [res.data[0].name, res.data[0].value === 0 ? 0 : Number(value/res.data[0].value).toFixed(1)].join("\n\n");
                }
              },
              data: [res.data[0]]
            },
            {
              name: res.data[1].name,
              type: 'gauge',
              startAngle: 90,
              endAngle: -270,
              radius: '50%',
              center: ['45%', '35%'],
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false,
                fontSize: 14,
              },
              axisLine: {
                show: false,
                lineStyle: {
                  width: 25
                }
              },
              anchor: {},
              pointer: {
                show: false
              },
              title: {
                show: false,
                fontSize: 16,
                fontWeight: 400,
                offsetCenter: [0, 0]
              },
              progress: {
                show: false,
                width: 25,
                itemStyle: {
                  color: colors[1]
                }
              },
              detail: {
                show: true,
                fontSize: 24,
                color: colors[1],
                formatter: function (value) { // 展示处理
                  return [res.data[1].name, res.data[0].value === 0 ? 0 : Number(value/res.data[0].value).toFixed(1)].join("\n\n");
                }
              },
              data: [res.data[1]]
            },
            {
              name: res.data[2].name,
              type: 'gauge',
              startAngle: 90,
              endAngle: -270,
              radius: '50%',
              center: ['70%', '35%'],
              splitLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                show: false,
                fontSize: 14,
              },
              axisLine: {
                show: false,
                lineStyle: {
                  width: 25
                }
              },
              anchor: {},
              pointer: {
                show: false
              },
              title: {
                show: false,
                fontSize: 16,
                fontWeight: 400,
                offsetCenter: [0, 0]
              },
              progress: {
                show: false,
                width: 25,
                itemStyle: {
                  color: colors[2]
                }
              },
              detail: {
                show: true,
                fontSize: 24,
                color: colors[2],
                formatter: function (value) { // 展示处理
                  return [res.data[2].name, res.data[0].value === 0 ? 0 : Number(value/res.data[0].value).toFixed(1)].join("\n\n");
                }
              },
              data: [res.data[2]]
            }
          ]
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.barLoading = false
      })
    }
  }
}
</script>

<style scoped>

.echarts {
  width: 95vw;
  height: 260px;
}
.title {
  position: relative;
  padding-left: 13px;
  margin: 12px 0;
  text-align: left;
}

.title:before {
  content: "";
  background-color: #039f3a;
  width: 6px;
  height: 18px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
}

.el-divider--horizontal {
  margin: 2px 0;
}

.el-divider--vertical {
  display: inline-block;
  weight: 1px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;
}
</style>
