import httpRequest from '@/utils/http-request'

export default {
  getList() {
    return httpRequest.get('/fill-in-cycles')
  },
  getLast() {
    return httpRequest.get('/last-fill-in-cycle')
  }
}
