import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/professional-index-directories', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/professional-index-directories/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/professional-index-directories', updateModel)
  },
  getById(id) {
    return httpRequest.get('/professional-index-directories/' + id)
  },
  getListByProfessionalCategoryId(professionalCategoryId) {
    let config = { params: { professionalCategoryId: professionalCategoryId } }
    return httpRequest.get('/professional-index-directories', config)
  }
}
