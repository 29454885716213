<template>
  <el-card>
    <div slot="header" class="chart-title">
      <span>基肥和追肥中施肥强度</span>
    </div>
    <div>
      <el-row :gutter="20" type="flex">
        <el-col :span="12" style="padding-left: 40px;">
          <v-chart
            :option="intensityNumbersOptions"
            :init-options="initOptions"
            ref="intensityNumbers"
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            :updateOptions="{notMerge: true}"
            autoresize
          />
        </el-col>
        <el-divider direction="vertical"></el-divider>
        <el-col :span="12">
          <v-chart
            :option="intensityProportionsOptions"
            :init-options="initOptions"
            ref="intensityProportions"
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            :updateOptions="{notMerge: true}"
            autoresize
          />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import VChart from 'vue-echarts'
import {CanvasRenderer, SVGRenderer} from 'echarts/renderers'

import {
  use
} from 'echarts/core'

import {
  BarChart
} from 'echarts/charts'

import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components'
import DataVisualizationApi from '@/api/chart-analysis/data-visualization-api'

use([
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
]);
export default {
  name: 'BaseFertilizationIntensity',
  components: {
    VChart
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas"
      },
      chartData: null,
      percentageChartData: null,
      intensityNumbersOptions: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      intensityProportionsOptions: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      seconds: -1,
      barLoading: false,
      barLoadingOptions: {
        text: "加载中…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0.4)"
      },
    };
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    getChartData() {
      this.intensityNumbersOptions = {}
      this.intensityProportionsOptions = {}
      this.barLoading = true
      DataVisualizationApi.getBaseFertilizerIntensityNumbers(this.params).then(res => {
        this.chartData = res.data
        if(this.chartData.length === 0) {
          this.intensityNumbersOptions = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }
        this.renderIntensityNumbersOptionsChart()
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.barLoading = false
      })
      DataVisualizationApi.getBaseFertilizerIntensityProportions(this.params).then(res => {
        this.percentageChartData = res.data
        if(this.percentageChartData.length === 0) {
          this.intensityProportionsOptions = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }
        this.renderIntensityProportionsOptionsChart()
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.barLoading = false
      })
    },
    renderIntensityNumbersOptionsChart() {
      const barColors = ['#27BC55', '#FFC554']
      let indicator = []
      let legends = this.chartData[0].value.map(el => el.name)
      legends.unshift('')
      indicator.push(legends)

      for (let j = 0; j < this.chartData.length; j++) {
        let itemName = this.chartData[j].name
        let itemData = this.chartData[j].value
        let itemValues = itemData.map(p => p.value)
        itemValues.unshift(itemName)
        indicator.push(itemValues)
      }

      this.intensityNumbersOptions = {
        textStyle: {
          fontSize: 14
        },
        grid:{
          containLabel: true
        },
        title: {
          text: '养分施用量(公斤/亩)',
          top: 20,
          left: 'left',
          textStyle: {
            fontSize: 14,
            color: '#000000',
            fontWeight: 500
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          textStyle: {
            align: 'left'
          }
        },
        legend: {
          x: 'right',      //可设定图例在左、右、居中
          y: 'top',     //可设定图例在上、下、居中
          padding: [20, 40, 0, 0],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          icon: 'rect', // 修改形状
          itemWidth: 8,
          itemHeight: 8, // 修改icon图形大小
          data: legends
        },
        dataset: {
          source: indicator
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            fontSize: 14
          }
        },
        yAxis: {
          axisLabel: {
            fontSize: 14
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: '25%',
            showBackground: true,
            backgroundStyle: {
              color: '#F7F6F6'
            },
            label: {
              show: true,
              position: 'top',
              fontSize: 14
            },
            itemStyle: {
              color: barColors[0]
            }
          },
          {
            type: 'bar',
            barWidth: '25%',
            showBackground: true,
            backgroundStyle: {
              color: '#F7F6F6'
            },
            label: {
              show: true,
              position: 'top',
              fontSize: 14
            },
            itemStyle: {
              color: barColors[1]
            }
          }
        ]
      }
    },
    renderIntensityProportionsOptionsChart() {
      let that = this
      let legends = this.percentageChartData[0].value.map(el => el.name)
      that.intensityProportionsOptions = {
        textStyle: {
          fontSize: 14
        },
        grid:{
          containLabel: true
        },
        color: ['#27BC55', '#FFC554'],
        title: [
          {
            text: '基追肥比例(%)',
            top: 20,
            left: 'left',
            textStyle: {
              fontSize: 14,
              color: '#000000',
              fontWeight: 500
            }
          },
          {
            subtext: that.percentageChartData[0].name,
            textAlign: 'center', //图例文字居中显示
            verticalAlign: 'middle',
            subtextStyle: {
              fontSize: 16,
              color: '#000000',
              fontWeight: 400
            },
            x: '18%',   //距离左边的距离
            y: '45%'    //距离上边的距离
          },{
            subtext: that.percentageChartData[1].name,
            textAlign: 'center', //图例文字居中显示
            verticalAlign: 'middle',
            subtextStyle: {
              fontSize: 16,
              color: '#000000',
              fontWeight: 400
            },
            x: '51%',   //距离左边的距离
            y: '45%'    //距离上边的距离
          },{
            subtext: that.percentageChartData[2].name,
            textAlign: 'center', //图例文字居中显示
            verticalAlign: 'middle',
            subtextStyle: {
              fontSize: 16,
              color: '#000000',
              fontWeight: 400
            },
            x: '85%',   //距离左边的距离
            y: '45%'    //距离上边的距离
          }],
        legend: {
          x: 'right',      //可设定图例在左、右、居中
          y: 'top',     //可设定图例在上、下、居中
          padding: [20, 40, 0, 0],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
          icon: 'rect', // 修改形状
          itemWidth: 8,
          itemHeight: 8, // 修改icon图形大小
          data: legends
        },
        series: [
          {
            name: that.percentageChartData[0].name,
            type: 'pie',
            radius: ['45%', '65%'],
            center: ['55%', '50%'],
            silent: true,
            avoidLabelOverlap: false,
            selectedOffset: 0, //选中块的偏移量
            label: {
              show: true,
              formatter: function(param) {
                return param.value + '%'
              },
              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 15,
            },
            labelLine: {
              show: false,
              smooth: true,
              length: 2,
              length2: 2,
              showAbove: true
            },
            data: that.percentageChartData[0].value.every(p => p.value === 0) ? [{name: '', value: 0, itemStyle:{color: '#ccc'}}] : that.percentageChartData[0].value,
            left: 0,
            right: '66.6667%',
            top: 0,
            bottom: 0
          }, {
            name: that.percentageChartData[1].name,
            type: 'pie',
            radius: ['45%', '65%'],
            center: ['55%', '50%'],
            silent: true,
            avoidLabelOverlap: false,
            selectedOffset: 0, //选中块的偏移量
            label: {
              show: true,
              formatter: function(param) {
                return param.value + '%'
              },
              minMargin: 5,
              edgeDistance: 5,
              lineHeight: 5,
            },
            labelLine: {
              show: false,
              smooth: true,
              length: 2,
              length2: 2,
              showAbove: true
            },
            data: that.percentageChartData[1].value.every(p => p.value === 0) ? [{name: '', value: 0, itemStyle:{color: '#ccc'}}] : that.percentageChartData[1].value,
            left: '33.3333%',
            right: '33.3333%',
            top: 0,
            bottom: 0
          },
          {
            name: that.percentageChartData[2].name,
            type: 'pie',
            radius: ['45%', '65%'],
            center: ['55%', '50%'],
            silent: true,
            avoidLabelOverlap: false,
            selectedOffset: 0, //选中块的偏移量
            label: {
              show: true,
              formatter: function(param) {
                  return param.value + '%'
              },
              minMargin: 5,
              edgeDistance: 5,
              lineHeight: 5,
            },
            labelLine: {
              show: false,
              smooth: true,
              length: 2,
              length2: 2,
              showAbove: true
            },
            data: that.percentageChartData[2].value.every(p => p.value === 0) ? [{name: '', value: 0, itemStyle:{color: '#ccc'}}] : that.percentageChartData[2].value,
            left: '66.6667%',
            right: 0,
            top: 0,
            bottom: 0
          }
        ]
      }
    }
  }
}
</script>


<style scoped>

.title {
  position: relative;
  padding-left: 13px;
  margin: 12px 0;
  text-align: left;
}

.title:before {
  content: "";
  background-color: #039f3a;
  width: 6px;
  height: 18px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
}

.el-divider--horizontal {
  margin: 2px 0;
}

.el-divider--vertical {
  display: inline-block;
  weight: 1px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;
  align-items: center;
}
</style>
