import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/user-levels', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/user-levels/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/user-levels', updateModel)
  },
  getById(id) {
    return httpRequest.get('/user-levels/' + id)
  },
  getList(name) {
    let config = { params: {} }

    if (null != name && '' != name && undefined != name) config.params.name = name

    return httpRequest.get('/user-levels', config)
  },
  getStubList() {
    return httpRequest.get('/user-level-stubs')
  }
}
