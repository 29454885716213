import { render, staticRenderFns } from "./ProfessionalCategoryManage.vue?vue&type=template&id=3014cba3&scoped=true"
import script from "./ProfessionalCategoryManage.vue?vue&type=script&lang=js"
export * from "./ProfessionalCategoryManage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3014cba3",
  null
  
)

export default component.exports