<template>
  <el-card>
    <div slot="header" class="chart-title"><span>有机肥施用总量</span></div>
    <v-chart
      :option="options"
      :init-options="initOptions"
      ref="chart"
      :loading="barLoading"
      :loadingOptions="barLoadingOptions"
      :updateOptions="{notMerge: true}"
      autoresize
    />
  </el-card>
</template>
<script>
import { use } from 'echarts/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, LegendComponent, TitleComponent, TooltipComponent } from 'echarts/components'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'
import VChart from 'vue-echarts'
import SurveyStatisticApi from '@/api/statistic/survey-statistic-api'

use([
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
]);

export default {
  name: 'OrganicFertilizerChart',
  components: {
    VChart
  },
  props:["years"],
  watch:{
    years() {
      this.refresh()
    }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas"
      },
      chartData: null,
      options: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      chartType: "bar",
      seconds: -1,
      barLoading: false,
      barLoadingOptions: {
        text: "加载中…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0.4)"
      },
    }
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    getChartData() {
      this.options = {}
      this.barLoading = true
      SurveyStatisticApi.getSurveyOrganicFertilizerSummaries(this.years[0], this.years[1]).then(res => {
        this.chartData = res.data
        this.renderChart()

      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.barLoading = false
      })
    },
    renderChart() {
      if(this.chartData.length === 0) {
        this.options = {
          graphic: {
            type: 'text',     // 类型：文本
            left: 'center',
            top: 'middle',
            silent: true,     // 不响应事件
            style: {
              fill: '#9d9d9d',
              fontWeight: 'bold',
              text: '暂无数据',
              fontFamily: 'Microsoft YaHei',
              fontSize: '25px'
            }
          }
        }
        return
      }
      this.options = {
        grid: {
          bottom: 0,
          left: 10,
          right:10,
          containLabel: true,
        },
        textStyle: {
          fontSize: 14
        },
        title: {
          text: '单位: 万吨',
          top: 20,
          left: 'left',
          textStyle: {
            fontSize: 14,
            color: '#000000',
            fontWeight: 500
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            fontSize: 14
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed'
            }
          },
          data: this.chartData.map(item => item.name)
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            data: this.chartData,
            type: this.chartType,
            barGap: 0.2,
            barWidth: '20%',
            showBackground: true,
            backgroundStyle: {
              color: '#ECF2FE',
            },
            label: {
              show: true,
              position: 'top',
            },
            itemStyle: {
              color: '#00A333'
            }
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.echarts {
  width: 30vw;
  min-width: 20vw;
  height: 27vh;
}

.chart-title {
  font-size: 14px;
}
</style>
