import httpRequest from '@/utils/http-request'

export default {
  getFillInStatisticData(year, cityId) {
    let config = {
      params: {
        year: year,
        cityId: cityId
      }
    }
    return httpRequest.get('/city-fill-in-statistic-data', config)
  },
  exportData(year, cityId) {
    let config = {
      params: {
        year: year,
        cityId: cityId
      }
    }
    return httpRequest.post('/city-fill-in-statistic-data-export', null, config)
  }
}