<template>
  <div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :lock-scroll="false" :close-on-click-modal="false" @close="handleClose">
      <div class="wg-box">
        <div class="header">
          <el-form :inline="true">
            <el-form-item label="关键字">
              <el-input v-model="filterForm.keyword" @input="handleKeywordInput" clearable size="small" style="width: 180px"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="body">
          <div class="wg-table-box">
            <el-table ref="table" :data="tableData" border class="wg-table" height="500" style="width: 100%" @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="50"> </el-table-column>
              <el-table-column label="序号" prop="index" width="100" sortable></el-table-column>
              <el-table-column label="省份" prop="provinceName" sortable :sort-method="(a, b) => sortColumn(a, b, 'provinceName')"></el-table-column>
              <el-table-column label="地市" prop="cityName" sortable :sort-method="(a, b) => sortColumn(a, b, 'cityName')"></el-table-column>
              <el-table-column label="区县" prop="districtName" sortable :sort-method="(a, b) => sortColumn(a, b, 'districtName')"></el-table-column>
              <el-table-column label="农户姓名" prop="fullName" sortable :sort-method="(a, b) => sortColumn(a, b, 'fullName')"></el-table-column>
              <el-table-column label="电话" prop="phoneNumber" sortable></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button size="small" class="wg-button blank" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" class="wg-button" @click="handleConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FarmerApi from '@/api/farmer-api'

export default {
  name: 'NoFillQuestionnaireFarmerSelectDialog',
  data() {
    return {
      dialogTitle: '未填报问卷的农户',
      dialogVisible: false,
      tableData: [],
      selectedRows: [],
      filterForm: {
        year: null,
        regionId: null,
        keyword: null
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('close', this.selectedRows)
    },
    handleKeywordInput() {
      this.bindTableData()
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows
    },
    handleConfirm() {
      this.dialogVisible = false
    },
    sortColumn(row1, row2, fieldName) {
      return this.$utils.sortColumn(row1[fieldName], row2[fieldName])
    },
    open(year, regionId) {
      this.filterForm.year = year
      this.filterForm.regionId = regionId
      this.dialogVisible = true
      this.bindTableData()
    },
    bindTableData() {
      FarmerApi.getListOfNoFillQuestionnaire(this.filterForm.year, this.filterForm.regionId, this.filterForm.keyword)
        .then(res => {
          let index = 0
          this.tableData = []
          res.data.forEach(el => {
            this.tableData.push({
              index: ++index,
              id: el.id,
              provinceId: el.provinceId,
              provinceName: el.provinceName,
              cityId: el.cityId,
              cityName: el.cityName,
              districtId: el.districtId,
              districtName: el.districtName,
              fullName: el.fullName + '（' + el.username + '）',
              phoneNumber: el.phoneNumber
            })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>


<style scoped>
</style>