import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/crop/categories', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/crop/categories/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/crop/categories', updateModel)
  },
  getById(id) {
    return httpRequest.get('/crop/categories/' + id)
  },
  getRootList() {
    return httpRequest.get('/crop/root-categories')
  },
  getSubList(parentId) {
    let config = {
      params: {
        parentId: parentId
      }
    }
    return httpRequest.get('/crop/sub-categories', config)
  },
  getRootStubList() {
    return httpRequest.get('/crop/root-category-stubs')
  },
  getSubStubList(parentId) {
    let config = {
      params: {
        parentId: parentId
      }
    }
    return httpRequest.get('/crop/sub-category-stubs', config)
  }
}