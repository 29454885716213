import httpRequest from '@/utils/http-request'

export default {
  deleteById(id) {
    return httpRequest.delete('/audit-plant-crops/' + id)
  },
  update(updateModel) {
    return httpRequest.put('/audit-plant-crops', updateModel)
  },
  audit(id) {
    let body = { id: id }
    return httpRequest.put('/plant-crop-audit', body)
  },
  getById(id) {
    return httpRequest.get('/audit-plant-crops/' + id)
  },
  getList(year, regionId, auditFlag) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        auditFlag: auditFlag,
        auditFilter: null
      }
    }
    return httpRequest.get('/audit-plant-crops', config)
  },
  getPaging(year, regionId, auditFlag, auditFilter, keyword, pageSize, pageIndex) {
    let config = {
      params: {
        year: year,
        regionId: regionId,
        auditFlag: auditFlag,
        auditFilter: auditFilter,
        keyword: keyword,
        pageSize: pageSize,
        pageIndex: pageIndex
      }
    }
    return httpRequest.get('/audit-plant-crop-paging', config)
  },
  exportTemplate(exportId, year, regionId, auditFlag, auditFilter, keyword) {
    let config = {
      params: {
        exportId: exportId,
        year: year,
        regionId: regionId,
        auditFlag: auditFlag,
        auditFilter: auditFilter,
        keyword: keyword
      }
    }
    return httpRequest.post('/audit-data-templates', null, config)
  },
  importData(year, fileName) {
    let config = {
      params: {
        year: year,
        fileName: fileName
      }
    }
    return httpRequest.post('/audit-data-import', null, config)
  },
  exportData(exportId, year, regionId, auditFlag, auditFilter, keyword) {
    let config = {
      params: {
        exportId: exportId,
        year: year,
        regionId: regionId,
        auditFlag: auditFlag,
        auditFilter: auditFilter,
        keyword: keyword
      }
    }
    return httpRequest.post('/audit-data-export', null, config)
  }
}
