<template>
  <div>
    <el-tabs v-model="activeName" class="wg-tabs" @tab-click="handleTabClick">
      <el-tab-pane name="unsubmittiedPane">
        <span slot="label">未提交</span>
        <archvied-unsubmitted-plant-crop-data ref="archviedUnsubmittedPlantCropData" v-if="flags.isLoadArchivedUnsubmittedPlantCropDataComponent"></archvied-unsubmitted-plant-crop-data>
      </el-tab-pane>
      <!-- unsubmitted -->
      <el-tab-pane name="submittiedPane">
        <span slot="label">已提交</span>
        <archvied-submitted-plant-crop-data ref="archviedSubmittedPlantCropData" v-if="flags.isLoadArchivedSubmittedPlantCropDataComponent"></archvied-submitted-plant-crop-data>
      </el-tab-pane>
      <!-- submitted -->
    </el-tabs>
  </div>
</template>

<script>
import ArchivedUnsubmittedPlantCropData from './ArchivedUnsubmittedPlantCropData'
import ArchivedSubmittedPlantCropData from './ArchivedSubmittedPlantCropData'

export default {
  name: 'ArchivedAssistFillInTab',
  components: {
    'archvied-unsubmitted-plant-crop-data': ArchivedUnsubmittedPlantCropData,
    'archvied-submitted-plant-crop-data': ArchivedSubmittedPlantCropData
  },
  props: {
    year: Number,
    regionId: String
  },
  data() {
    return {
      isCollapse: false,
      flags: {
        isLoadArchivedUnsubmittedPlantCropDataComponent: false,
        isLoadArchivedSubmittedPlantCropDataComponent: false
      },
      activeName: 'unsubmittiedPane'
    }
  },
  created() {
    this.flags.isLoadArchivedUnsubmittedPlantCropDataComponent = true

    this.$nextTick(() => {
      this.loadComponent(this.activeName)
    })
  },
  watch: {
    year() {
      this.loadComponent(this.activeName)
    },
    regionId() {
      this.loadComponent(this.activeName)
    }
  },
  methods: {
    handleTabClick(tab) {
      this.flags.isLoadArchivedUnsubmittedPlantCropDataComponent = tab.name === 'unsubmittiedPane'
      this.flags.isLoadArchivedSubmittedPlantCropDataComponent = tab.name === 'submittiedPane'

      this.$nextTick(() => {
        this.loadComponent(tab.name)
      })
    },
    loadComponent(name) {
      switch (name) {
        case 'unsubmittiedPane':
          this.$refs.archviedUnsubmittedPlantCropData.load(this.year, this.regionId)
          break
        case 'submittiedPane':
          this.$refs.archviedSubmittedPlantCropData.load(this.year, this.regionId)
          break
        default:
          this.$refs.archviedUnsubmittedPlantCropData.load(this.year, this.regionId)
          break
      }
    },
    reloadComponent() {
      this.loadComponent(this.activeName)
    }
  }
}
</script>

<style scoped></style>
