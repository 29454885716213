import { render, staticRenderFns } from "./SoilManage.vue?vue&type=template&id=453be0ed&scoped=true"
import script from "./SoilManage.vue?vue&type=script&lang=js"
export * from "./SoilManage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "453be0ed",
  null
  
)

export default component.exports