<template>
  <div>
    <div class="wg-breadcrumb">
      <el-breadcrumb separator="/" class="bread_crumb">
        <el-breadcrumb-item :to="{ path: '/' }">农事管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/crop-tab' }">作物管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- breadcrumb -->
    <div class="wg-box">
      <div class="header">
        <el-tabs v-model="rootCategoryId" class="wg-tabs" @tab-click="handleTabClick">
          <el-tab-pane v-for="item in rootCategoryArray" :key="item.id" :label="item.name" :name="item.id" />
        </el-tabs>
      </div>
    </div>
    <div class="body">
      <el-row :gutter="10">
        <el-col :span="12">
          <crop-category-manage :rootCategoryId="rootCategoryId"></crop-category-manage>
        </el-col>
        <el-col :span="12">
          <crop-manage :rootCategoryId="rootCategoryId"></crop-manage>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import CropCategoryApi from '@/api/crop/crop-category-api'

import CropCategoryManageComponent from './components/CropCategoryManage'
import CropManageComponent from './components/CropManage'

export default {
  name: 'CropTab',
  components: {
    'crop-category-manage': CropCategoryManageComponent,
    'crop-manage': CropManageComponent
  },
  data() {
    return {
      rootCategoryArray: [],
      rootCategoryId: ''
    }
  },
  created() {
    this.bindRootCategoryTab()
  },
  methods: {
    handleTabClick() {
    },
    bindRootCategoryTab() {
      CropCategoryApi.getRootList().then(res => {
        res.data.forEach(el => {
          this.rootCategoryArray.push({ id: el.id, name: el.name })
        })
        this.rootCategoryId = res.data[0].id
      })
    }
  }
}
</script>

<style scoped>

</style>