<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="chart-title"><span>有机肥施用面积及覆盖率</span></div>
        <el-divider></el-divider>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <v-chart
          :option="options"
          :init-options="initOptions"
          ref="intensityNumbers"
          :loading="loading"
          :loadingOptions="loadingOptions"
          :update-options="{notMerge:true}"
          autoresize
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VChart from "vue-echarts";
import {CanvasRenderer, SVGRenderer} from "echarts/renderers";

import {
  use
} from "echarts/core";

import {
  BarChart
} from "echarts/charts";

import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent
} from "echarts/components";
import AnnualAnalysisApi from '@/api/chart-analysis/annual-analysis-api'

use([
  BarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
]);

export default {
  name: "AreaAndCoverageAnnual",
  components: {
    VChart
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas"
      },
      options: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      seconds: -1,
      loading: false,
      loadingOptions: {
        text: "加载中…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0.4)"
      },
    };
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    getChartData() {
      this.loading = true
      this.options = {}
      let postfix = this.params.groupType === 'years' ? '年' : ''
      let prefix = this.params.groupType !== 'years' ? this.params.searchYear + '年  ' : ''
      AnnualAnalysisApi.getAreaAndCoverage(this.params).then(res => {
        if(res.data.length === 0) {
          this.options = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }
        this.options = {
          textStyle: {
            fontSize: 14
          },
          color: ['#27BC55', '#FFC85D'],
          grid: {
            top: '12%',
            left: '3%',
            right: '4%',
            containLabel: true
          },
          legend: {
            x: 'right',      //可设定图例在左、右、居中
            y: 'top',     //可设定图例在上、下、居中
            padding: [14, 160, 0, 0],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
            //icon: 'rect', // 修改形状
            itemWidth: 8,
            itemHeight: 8, // 修改icon图形大小
            textStyle: {
              fontSize: 14
            },
            data: ['施用面积', '覆盖率']
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'line',
              label: {
                backgroundColor: '#6a7985'
              }
            },
            textStyle:{
              align:'left'
            },
            formatter(a) {
              let res = ''
              a.forEach((item, index) => {
                let unit = item.seriesName === '覆盖率' ? '%' : '万亩'
                if (index === 0) {
                  res += prefix + `${item.axisValue}<br/>`
                }
                res += `${item.marker} ${item.seriesName}<span>&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-weight:bold;float: right">${item.data} ${unit}</span><br/>`
              })
              return res
            },
            extraCssText: 'width: 200px'
          },
          xAxis: {
            type: 'category',
            axisLabel: {
              fontSize: 14,
              interval: 0
            },
            data: res.data[0].value.map(f => f.name + postfix)
          },
          yAxis: [{
            type: 'value',
            name: '施用面积(单位：万亩)',
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              fontSize: 14
            }
          },{
            type: 'value',
            name: '覆盖率(单位：%)'
          }],
          dataZoom: [
            {
              type: 'inside',
              filterMode: 'empty',
              zoomOnMouseWheel: false,
              // start:20,
              // end:60,
            },
            {
              type: 'slider',
              height: '24px',
              bottom: '30px',
              left:'center',
              brushSelect: false,
              borderColor: 'transparent',
              backgroundColor:'rgba(0,0,0,0.04)',
              fillerColor: 'rgba(0,0,0,0.06)',
              moveOnMouseWheel: false,
              zoomOnMouseWheel: false,
            }
          ],
          series: [
            {
              name: res.data[0].name,
              type: 'bar',
              barWidth: 25,
              smooth: true,
              lineStyle: {
                width: 0
              },
              showSymbol: false,
              symbol: 'circle',
              symbolSize: [8, 8],
              tooltip: {
                valueFormatter: function (value) {
                  return value + '万亩';
                }
              },
              emphasis: {
                focus: 'series'
              },
              data: res.data[0].value.map(item => item.value),
            },{
              name: res.data[1].name,
              type: 'line',
              yAxisIndex: 1,
              tooltip: {
                valueFormatter: function (value) {
                  return value + '%'
                }
              },
              showSymbol: false,
              symbolSize: [8, 8],
              emphasis: {
                focus: 'series'
              },
              encode: {y: 'value'},
              data: res.data[1].value.map(item => item.value)
            }
          ]
        };
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.echarts {
  width: 98vw;
}

.el-divider--horizontal {
  margin: 2px 0;
}

.el-divider--vertical {
  display: inline-block;
  weight: 1px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;
}
</style>
