import httpRequest from '@/utils/http-request'

export default {
  getUploadURI() {
    return 'api/files'
  },
  delete(category, fileName) {
    let config = {
      params: {
        category: category,
        fileName: fileName
      }
    }
    return httpRequest.delete('/files', config)
  },
  get(category, fileName) {
    let config = {
      params: {
        category: category,
        fileName: fileName
      }
    }
    return httpRequest.get('/files', config)
  }
}
