<template>
  <div class="wg-box" style="margin-top: 0" v-loading="flags.dataLoading" element-loading-text="拼命加载数据......" element-loading-spinner="el-icon-loading">
    <div class="header">
      <div class="button-list">
        <el-button size="small" class="wg-button blue" icon="el-icon-download" @click="handleExportData">导出 </el-button>
      </div>
      <div class="wg-clear-fix"></div>
    </div>
    <div class="body">
      <div style="padding: 10px">
        <el-alert type="warning" show-icon :closable="false">
          <ul class="statistic-explain-list">
            <li>【未提交农户】（注册了账号，但没有提交过任何一条施肥数据）</li>
            <li>【已提交农户】（注册了账号，并提交过施肥数据）</li>
            <li>【待审核农户】（农户的所有填报记录均没有被审核）</li>
            <li>【已审核农户】（农户有数据被审核通过）</li>
          </ul>
        </el-alert>
      </div>
      <div class="wg-table-box" style="padding-bottom: 20px">
        <el-table ref="table" :data="tableData" :max-height="600" class="wg-table normal" border>
          <el-table-column label="行政编号" prop="regionId" width="100" fixed></el-table-column>
          <el-table-column label="区域名称" prop="regionName" fixed></el-table-column>
          <el-table-column label="填报数据统计（条）">
            <el-table-column label="辅助数据填报">
              <el-table-column label="已保存未提交" width="120" prop="savedOfAssistCount"></el-table-column>
              <el-table-column label="已提交" prop="submittedOfAssistCount"></el-table-column>
              <el-table-column label="合计" prop="assistFillInTotalCount"></el-table-column>
            </el-table-column>
            <el-table-column prop="submittedOfFarmerCount">
              <template slot="header">农户填报<br />已提交</template>
            </el-table-column>
            <el-table-column prop="submittedTotalCount">
              <template slot="header">已提交数据<br />合计</template>
            </el-table-column>
            <el-table-column label="数据审核">
              <el-table-column label="待审核" prop="unauditedCount"></el-table-column>
              <el-table-column label="已审核" prop="auditedCount"></el-table-column>
              <el-table-column label="退回" prop="rejectedCount"></el-table-column>
            </el-table-column>
          </el-table-column>
          <el-table-column label="农户数统计（户）">
            <el-table-column label="未提交" prop="unsubmittedFarmerCount"></el-table-column>
            <el-table-column label="已提交" prop="submittedFarmerCount"></el-table-column>
            <el-table-column label="待审核" prop="unauditedFarmerCount"></el-table-column>
            <el-table-column label="已审核" prop="auditedFarmerCount"></el-table-column>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFillInDataStatisticApi from '@/api/statistic/country-fill-in-data-statistic-api'
import ProvinceFillInDataStatisticApi from '@/api/statistic/province-fill-in-data-statistic-api'
import CityFillInDataStatisticApi from '@/api/statistic/city-fill-in-data-statistic-api'
import DistrictFillInDataStatisticApi from '@/api/statistic/district-fill-in-data-statistic-api'
import FileApi from '@/api/file-api'

export default {
  name: 'DataFillInStatistic',
  data() {
    return {
      params: {
        year: null,
        regionId: null
      },
      flags: {
        dataLoading: false
      },
      tableData: []
    }
  },
  methods: {
    load(year, regionId) {
      this.params.year = year
      this.params.regionId = regionId
      this.tableData = []

      let provincePattern = /^[1-9][0-9]0000/
      let cityPattern = /^[1-9][0-9]([0-9][1-9]|[1-9][0-9])00/
      let districtPattern = /^[1-9][0-9]([0-9][1-9]|[1-9][0-9])([0-9][1-9]|[1-9][0-9])/
      if (null === this.params.regionId) {
        this.bindAllProvinceTableStatisticData()
      } else {
        if (provincePattern.test(this.params.regionId)) {
          this.bindAllCityTableStatisticData()
        }
        if (cityPattern.test(this.params.regionId)) {
          this.bindAllDistrictTableStatisticData()
        }
        if (districtPattern.test(this.params.regionId)) {
          this.bindDistrictTableStatisticData()
        }
      }
    },
    handleExportData() {
      this.$confirm('导出统计数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const exportLoading = this.$loading({
            lock: true,
            text: '正在导出数据......',
            spinner: 'el-icon-loading'
          })

          let exportId = null
          let userLevelId = sessionStorage.getItem('levelId')
          if ('Country' === userLevelId) {
            await CountryFillInDataStatisticApi.exportData(this.params.year)
              .then(res => {
                exportId = res.data
                exportLoading.close()
              })
              .catch(err => {
                exportLoading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          } else if ('Province' === userLevelId) {
            let provinceId = sessionStorage.getItem('provinceId')
            await ProvinceFillInDataStatisticApi.exportData(this.params.year, provinceId)
              .then(res => {
                exportId = res.data
                exportLoading.close()
              })
              .catch(err => {
                exportLoading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          } else if ('City' === userLevelId) {
            let cityId = sessionStorage.getItem('cityId')
            await CityFillInDataStatisticApi.exportData(this.params.year, cityId)
              .then(res => {
                exportId = res.data
                exportLoading.close()
              })
              .catch(err => {
                exportLoading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          } else if ('District' === userLevelId) {
            let districtId = sessionStorage.getItem('districtId')
            await DistrictFillInDataStatisticApi.exportData(this.params.year, districtId)
              .then(res => {
                exportId = res.data
                exportLoading.close()
              })
              .catch(err => {
                exportLoading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          } else {
            exportLoading.close()
            this.$message({ showClose: true, message: '用户无法识别！', type: 'error' })
          }

          if (null !== exportId) {
            const downLoading = this.$loading({
              lock: true,
              text: '等待下载数据文件......',
              spinner: 'el-icon-loading'
            })

            await FileApi.get('TEMP', exportId + '.xlsx')
              .then(res => {
                let byteBuffer = window.atob(res.data)
                let arrayBuffer = new ArrayBuffer(byteBuffer.length)
                let dataBuffer = new Uint8Array(arrayBuffer)
                for (let i = 0; i < byteBuffer.length; i++) dataBuffer[i] = byteBuffer.charCodeAt(i)

                let blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                link.setAttribute('download', '统计数据.xlsx')
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href)
                document.body.removeChild(link)
                downLoading.close()
              })
              .catch(err => {
                downLoading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })

            await FileApi.delete('TEMP', exportId + '.xlsx')
              .then(() => {})
              .catch(err => {
                downLoading.close()
                this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
              })
          }
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消导出！', type: 'info' })
        })
    },
    async bindAllProvinceTableStatisticData() {
      this.flags.dataLoading = true
      CountryFillInDataStatisticApi.getFillInStatisticData(this.params.year)
        .then(res => {
          let statisticCountList = res.data
          statisticCountList.forEach(el => {
            this.tableData.push({
              regionId: el.regionId,
              regionName: el.regionName,
              savedOfAssistCount: el.savedOfAssistCount,
              submittedOfAssistCount: el.submittedOfAssistCount,
              assistFillInTotalCount: el.assistFillInTotalCount,
              submittedOfFarmerCount: el.submittedOfFarmerCount,
              submittedTotalCount: el.submittedTotalCount,
              unauditedCount: el.unauditedCount,
              auditedCount: el.auditedCount,
              rejectedCount: el.rejectedCount,
              unsubmittedFarmerCount: el.unsubmittedFarmerCount,
              submittedFarmerCount: el.submittedFarmerCount,
              unauditedFarmerCount: el.unauditedFarmerCount,
              auditedFarmerCount: el.auditedFarmerCount
            })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
        .finally(() => {
          this.flags.dataLoading = false
        })
    },
    async bindAllCityTableStatisticData() {
      this.flags.dataLoading = true
      ProvinceFillInDataStatisticApi.getFillInStatisticData(this.params.year, this.params.regionId)
        .then(res => {
          let statisticCountList = res.data
          statisticCountList.forEach(el => {
            this.tableData.push({
              regionId: el.regionId,
              regionName: el.regionName,
              savedOfAssistCount: el.savedOfAssistCount,
              submittedOfAssistCount: el.submittedOfAssistCount,
              assistFillInTotalCount: el.assistFillInTotalCount,
              submittedOfFarmerCount: el.submittedOfFarmerCount,
              submittedTotalCount: el.submittedTotalCount,
              unauditedCount: el.unauditedCount,
              auditedCount: el.auditedCount,
              rejectedCount: el.rejectedCount,
              unsubmittedFarmerCount: el.unsubmittedFarmerCount,
              submittedFarmerCount: el.submittedFarmerCount,
              unauditedFarmerCount: el.unauditedFarmerCount,
              auditedFarmerCount: el.auditedFarmerCount
            })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
        .finally(() => {
          this.flags.dataLoading = false
        })
    },
    async bindAllDistrictTableStatisticData() {
      this.flags.dataLoading = true
      CityFillInDataStatisticApi.getFillInStatisticData(this.params.year, this.params.regionId)
        .then(res => {
          let statisticCountList = res.data
          statisticCountList.forEach(el => {
            this.tableData.push({
              regionId: el.regionId,
              regionName: el.regionName,
              savedOfAssistCount: el.savedOfAssistCount,
              submittedOfAssistCount: el.submittedOfAssistCount,
              assistFillInTotalCount: el.assistFillInTotalCount,
              submittedOfFarmerCount: el.submittedOfFarmerCount,
              submittedTotalCount: el.submittedTotalCount,
              unauditedCount: el.unauditedCount,
              auditedCount: el.auditedCount,
              rejectedCount: el.rejectedCount,
              unsubmittedFarmerCount: el.unsubmittedFarmerCount,
              submittedFarmerCount: el.submittedFarmerCount,
              unauditedFarmerCount: el.unauditedFarmerCount,
              auditedFarmerCount: el.auditedFarmerCount
            })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
        .finally(() => {
          this.flags.dataLoading = false
        })
    },
    async bindDistrictTableStatisticData() {
      this.flags.dataLoading = true
      DistrictFillInDataStatisticApi.getFillInStatisticData(this.params.year, this.params.regionId)
        .then(res => {
          let statisticCountList = res.data
          statisticCountList.forEach(el => {
            this.tableData.push({
              regionId: el.regionId,
              regionName: el.regionName,
              savedOfAssistCount: el.savedOfAssistCount,
              submittedOfAssistCount: el.submittedOfAssistCount,
              assistFillInTotalCount: el.assistFillInTotalCount,
              submittedOfFarmerCount: el.submittedOfFarmerCount,
              submittedTotalCount: el.submittedTotalCount,
              unauditedCount: el.unauditedCount,
              auditedCount: el.auditedCount,
              rejectedCount: el.rejectedCount,
              unsubmittedFarmerCount: el.unsubmittedFarmerCount,
              submittedFarmerCount: el.submittedFarmerCount,
              unauditedFarmerCount: el.unauditedFarmerCount,
              auditedFarmerCount: el.auditedFarmerCount
            })
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
        .finally(() => {
          this.flags.dataLoading = false
        })
    }
  }
}
</script>

<style scoped>
.statistic-explain-list {
  list-style-type: none;
  padding: 0;
}

.statistic-explain-list li {
  font-size: 14px;
  line-height: 20px;
}
</style>
