<template>
  <el-collapse v-model="activeNames" class="wg-collapse">
    <el-collapse-item name="monitoringPlot">
      <template slot="title">
        <div class="header background-style1">
          <div class="title">监测点基本信息</div>
        </div>
        <div class="wg-clear-fix"></div>
      </template>
      <div class="wg-box">
        <div class="body">
          <div class="wg-table-box">
            <el-table ref="table" :data="tableData" :max-height="180" class="wg-table normal" border style="width: 100%">
              <el-table-column label="农户姓名" prop="farmerFullName" fixed></el-table-column>
              <el-table-column label="省份" prop="provinceName"></el-table-column>
              <el-table-column label="地市" prop="cityName"></el-table-column>
              <el-table-column label="区县" prop="districtName"></el-table-column>
              <el-table-column label="详细地址" prop="detailedAddress"></el-table-column>
              <el-table-column label="编码" prop="code"></el-table-column>
              <el-table-column label="地块名称" prop="name"></el-table-column>
              <el-table-column label="建立时间" prop="buildTime" width="120"></el-table-column>
              <el-table-column label="经度" prop="longitude"></el-table-column>
              <el-table-column label="纬度" prop="latitude"></el-table-column>
              <el-table-column label="地块面积（亩）" prop="plotArea"></el-table-column>
              <el-table-column label="电话号码" prop="phoneNumber"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
import ArchivedMonitoringPlotApi from '@/api/data-fill-in/archive/archived-monitoring-plot-api'

export default {
  name: 'ArchivedMonitoringPlot',
  data() {
    return {
      activeNames: ['monitoringPlot'],
      tableData: []
    }
  },
  methods: {
    load(id) {
      if (null == id) return

      ArchivedMonitoringPlotApi.getById(id)
        .then(res => {
          this.tableData = []
          let model = res.data
          this.tableData.push({
            id: model.id,
            provinceName: model.provinceName,
            cityName: model.cityName,
            districtName: model.districtName,
            detailedAddress: model.detailedAddress,
            code: model.code,
            name: model.name,
            buildTime: this.$moment(model.buildTime).format('YYYY-MM-DD'),
            longitude: model.longitude,
            latitude: model.latitude,
            plotArea: model.plotArea,
            farmerFullName: model.managerFullName,
            phoneNumber: model.managerPhoneNumber
          })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped></style>
