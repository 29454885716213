import { render, staticRenderFns } from "./ArchivedUnsubmittedPlantCropData.vue?vue&type=template&id=fdbfba22&scoped=true"
import script from "./ArchivedUnsubmittedPlantCropData.vue?vue&type=script&lang=js"
export * from "./ArchivedUnsubmittedPlantCropData.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdbfba22",
  null
  
)

export default component.exports