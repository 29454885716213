import axios from 'axios'

const instance = axios.create({
  baseURL: '/api',
  timeout: 10 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json;charset=utf-8'
  }
})

instance.interceptors.request.use(
  config => {
    if (undefined != sessionStorage.getItem('token')) config.headers['token'] = sessionStorage.getItem('token')

    return config
  },
  error => {
    return Promise.reject(error.response)
  }
)

instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.log(error)
    if (401 === error.response.status) {
      window.location = '/login.html?code=' + error.response.data.code + '&message=' + error.response.data.message
    }
    if (403 === error.response.status) {
      window.location = '/?code=' + error.response.data.code + '&message=' + error.response.data.message
    }
    return Promise.reject(error.response)
  }
)

export default instance
