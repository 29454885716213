<template>
  <div class="wg-box">
    <div class="body">
      <div class="survey-related-picture-box">
        <div class="title">纸质调查表格</div>
        <div class="picture-list-box">
          <div class="picture" v-for="item in paperQuestionnaire.imageList" :key="item.id">
            <el-image style="width: 120px; height: 120px" :src="item.url" :preview-src-list="item.srcList" :fit="item.fit"></el-image>
          </div>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <!-- PAPER_QUESTIONNAIRE -->
      <div class="survey-related-picture-box">
        <div class="title">肥料包装袋</div>
        <div class="picture-list-box">
          <div class="picture" v-for="item in fertilizerBag.imageList" :key="item.id">
            <el-image style="width: 120px; height: 120px" :src="item.url" :preview-src-list="item.srcList" :fit="item.fit"></el-image>
          </div>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <!-- FERTILIZER_BAG -->
      <div class="survey-related-picture-box">
        <div class="title">作物长势</div>
        <div class="picture-list-box">
          <div class="picture" v-for="item in cropGrowthTrend.imageList" :key="item.id">
            <el-image style="width: 120px; height: 120px" :src="item.url" :preview-src-list="item.srcList" :fit="item.fit"></el-image>
          </div>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <!-- CROP_GROWTH_TREND -->
    </div>
  </div>
</template>

<script>
import SurveyRelatedPictureApi from '@/api/survey-related-picture-api'

export default {
  props: {
    fullPlantCropModel: Object
  },
  data() {
    return {
      paperQuestionnaire: {
        imageList: []
      },
      fertilizerBag: {
        imageList: []
      },
      cropGrowthTrend: {
        imageList: []
      }
    }
  },
  created() {
    SurveyRelatedPictureApi.getList(this.fullPlantCropModel.plantCrop.id).then(res => {
      res.data.forEach(el => {
        if ('PAPER_QUESTIONNAIRE' === el.surveyCategory) {
          this.paperQuestionnaire.imageList.push({
            id: el.id,
            fit: 'cover',
            url: '/upload/pictures/survey-related/' + el.savedFileName,
            srcList: ['/upload/pictures/survey-related/' + el.savedFileName]
          })
        }
        if ('FERTILIZER_BAG' === el.surveyCategory) {
          this.fertilizerBag.imageList.push({
            id: el.id,
            fit: 'cover',
            url: '/upload/pictures/survey-related/' + el.savedFileName,
            srcList: ['/upload/pictures/survey-related/' + el.savedFileName]
          })
        }
        if ('CROP_GROWTH_TREND' === el.surveyCategory) {
          this.cropGrowthTrend.imageList.push({
            id: el.id,
            fit: 'cover',
            url: '/upload/pictures/survey-related/' + el.savedFileName,
            srcList: ['/upload/pictures/survey-related/' + el.savedFileName]
          })
        }
      })
    })
  }
}
</script>

<style>
.survey-related-picture-box {
  padding: 0 0 10px 10px;
}

.survey-related-picture-box .title {
  font-size: 14px;
  width: 100px;
}

.survey-related-picture-box .picture-list-box {
  padding: 10px 0 0 0;
}

.survey-related-picture-box .picture {
  float: left;
  margin-right: 20px;
}
</style>
