<template>
  <div class="wg-box">
    <div class="body">
      <div class="upload-box">
        <div class="title">纸质调查表格</div>
        <div class="upload-image">
          <el-upload name="file" :action="actionUrl" :data="uploadParams" :disabled="fullPlantCropModel.plantCrop.submitFlag" :file-list="paperQuestionnaire.imageList" list-type="picture-card" :on-success="handlePaperQuestionnaireSuccess" :on-error="handleError" :on-preview="handlePreview" :before-remove="handleBeforeRemove" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <!-- PAPER_QUESTIONNAIRE -->
      <div class="upload-box">
        <div class="title">肥料包装袋</div>
        <div class="upload-image">
          <el-upload name="file" :action="actionUrl" :data="uploadParams" :disabled="fullPlantCropModel.plantCrop.submitFlag" :file-list="fertilizerBag.imageList" list-type="picture-card" :on-success="handleFertilizerBagSuccess" :on-error="handleError" :on-preview="handlePreview" :before-remove="handleBeforeRemove" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <!-- FERTILIZER_BAG -->
      <div class="upload-box">
        <div class="title">作物长势</div>
        <div class="upload-image">
          <el-upload name="file" :action="actionUrl" :data="uploadParams" :disabled="fullPlantCropModel.plantCrop.submitFlag" :file-list="cropGrowthTrend.imageList" list-type="picture-card" :on-success="handleCropGrowthTrendSuccess" :on-error="handleError" :on-preview="handlePreview" :before-remove="handleBeforeRemove" :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="wg-clear-fix"></div>
      </div>
      <div class="wg-clear-fix"></div>
      <!-- CROP_GROWTH_TREND -->
    </div>
    <el-dialog :visible.sync="picturePreviewDialog.visible">
      <img width="100%" :src="picturePreviewDialog.imageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import FileApi from '@/api/file-api'
import SurveyRelatedPictureApi from '@/api/survey-related-picture-api'

export default {
  props: {
    fullPlantCropModel: Object
  },
  data() {
    return {
      actionUrl: null,
      uploadParams: { category: 'SURVEY_RELATED' },
      paperQuestionnaire: {
        imageList: []
      },
      fertilizerBag: {
        imageList: []
      },
      cropGrowthTrend: {
        imageList: []
      },
      picturePreviewDialog: {
        imageUrl: '',
        visible: false
      }
    }
  },
  created() {
    this.actionUrl = FileApi.getUploadURI()
    this.fullPlantCropModel.surveyRelatedPictureList.forEach(el => {
      if ('PAPER_QUESTIONNAIRE' === el.surveyCategory) {
        this.paperQuestionnaire.imageList.push({
          name: el.originalFileName,
          url: '/upload/pictures/survey-related/' + el.savedFileName,
          id: el.id
        })
      }
      if ('FERTILIZER_BAG' === el.surveyCategory) {
        this.fertilizerBag.imageList.push({
          name: el.originalFileName,
          url: '/upload/pictures/survey-related/' + el.savedFileName,
          id: el.id
        })
      }
      if ('CROP_GROWTH_TREND' === el.surveyCategory) {
        this.cropGrowthTrend.imageList.push({
          name: el.originalFileName,
          url: '/upload/pictures/survey-related/' + el.savedFileName,
          id: el.id
        })
      }
    })
  },
  methods: {
    handlePaperQuestionnaireSuccess(res, file) {
      let createModel = {
        plantCropId: this.fullPlantCropModel.plantCrop.id,
        surveyCategory: 'PAPER_QUESTIONNAIRE',
        originalFileName: res.originalFileName,
        savedFileName: res.savedFileName
      }
      SurveyRelatedPictureApi.create(createModel)
        .then(res => {
          file.id = res.data
          this.$message({ showClose: true, message: '上传成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    handleFertilizerBagSuccess(res, file) {
      let createModel = {
        plantCropId: this.fullPlantCropModel.plantCrop.id,
        surveyCategory: 'FERTILIZER_BAG',
        originalFileName: res.originalFileName,
        savedFileName: res.savedFileName
      }
      SurveyRelatedPictureApi.create(createModel)
        .then(res => {
          file.id = res.data
          this.$message({ showClose: true, message: '上传成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    handleCropGrowthTrendSuccess(res, file) {
      let createModel = {
        plantCropId: this.fullPlantCropModel.plantCrop.id,
        surveyCategory: 'CROP_GROWTH_TREND',
        originalFileName: res.originalFileName,
        savedFileName: res.savedFileName
      }
      SurveyRelatedPictureApi.create(createModel)
        .then(res => {
          file.id = res.data
          this.$message({ showClose: true, message: '上传成功！', type: 'success' })
        })
        .catch(err => {
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    },
    handleError() {
      this.$message({ showClose: true, message: '操作失败！', type: 'error' })
      this.reloadData()
    },
    handlePreview(file) {
      this.picturePreviewDialog.imageUrl = file.url
      this.picturePreviewDialog.visible = true
    },
    handleBeforeRemove() {
      return confirm('此操作将永久删除该数据, 是否继续？')
    },
    handleRemove(file) {
      SurveyRelatedPictureApi.deleteById(file.id)
        .then(() => {
          this.$message({ type: 'success', message: '删除成功！' })
        })
        .catch(err => {
          console.log(err)
          this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
        })
    }
  }
}
</script>

<style scoped>
.upload-box {
  padding: 0 0 10px 10px;
}
.upload-box .title {
  font-size: 14px;
  float: left;
  width: 100px;
}
.upload-box .upload-image {
  float: left;
}
</style>