<template>
  <div>
    <Nature ref="nature" :params="params"></Nature>
    <div class="chart-divider"></div>
    <Degree ref="degree" :params="params"></Degree>
  </div>
</template>

<script>
import Nature from '@/views/chart-analysis/components/farmer/Nature'
import Degree from '@/views/chart-analysis/components/farmer/Degree'
export default {
  name: 'ChartGroup-Farmer',
  components: { Nature, Degree },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    refreshCharts() {
      this.$nextTick(() => {
        this.$refs.nature.refresh()
        this.$refs.degree.refresh()
      })
    }
  }
}
</script>

<style scoped>

.el-card {
  border: 1px solid #D6D6D6;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
  border-radius: 0px 0px 4px 4px;
}
.el-card ::v-deep .el-card__header {
  padding: 2px 10px;
  height: 50px;
}
.el-card ::v-deep .el-card__body {
  padding: 0px;
  background: #FFFFFF;
  border: 0.5px solid #D6D6D6;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.04);
  border-radius: 0px 0px 4px 4px;
}
</style>
