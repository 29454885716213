<template>
  <div>
    <el-tabs v-model="activeName" class="wg-tabs" @tab-click="handleTabClick">
      <el-tab-pane name="unauditedPane">
        <span slot="label">待审核</span>
        <unaudited-plant-crop-data ref="unauditPlantCropData" :cropRootCategoryArray="cropRootCategoryArray" :cropSubCategoryArray="cropSubCategoryArray" :cropArray="cropArray" v-if="flags.isLoadUnauditedPlantCropDataComponent"></unaudited-plant-crop-data>
      </el-tab-pane>
      <el-tab-pane name="auditedPane">
        <span slot="label">已审核</span>
        <audited-plant-crop-data ref="auditedPlantCropData" v-if="flags.isLoadAuditedPlantCropDataComponent"></audited-plant-crop-data>
      </el-tab-pane>
      <el-tab-pane name="rejectedPane" v-if="flags.allowReject">
        <span slot="label">
          上级已退回
          <el-badge :value="rejectedCount"></el-badge>
        </span>
        <rejected-plant-crop-data ref="rejectedPlantCropData" :cropRootCategoryArray="cropRootCategoryArray" :cropSubCategoryArray="cropSubCategoryArray" :cropArray="cropArray" @getRejectedCount="getRejectedCount" v-if="flags.isLoadRejectedPlantCropDataComponent"></rejected-plant-crop-data>
      </el-tab-pane>
      <el-tab-pane name="statisticPane">
        <span slot="label">数据统计</span>
        <data-fill-in-statistic ref="dataFillInStatistic" v-if="flags.isLoadDataFillInStatisticComponent"></data-fill-in-statistic>
      </el-tab-pane>
      <el-tab-pane name="rejectedReadonlyPane">
        <span slot="label">数据退回</span>
        <rejected-plant-crop-readonly-data ref="rejectedPlantCropReadonlyData" v-if="flags.isLoadRejectedPlantCropReadonlyDataComponent"></rejected-plant-crop-readonly-data>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CropCategoryApi from '@/api/crop/crop-category-api'
import CropApi from '@/api/crop/crop-api'
import RejectPlantCropApi from '@/api/reject-plant-crop-api'

import UnauditedPlantCropData from './UnauditedPlantCropData'
import AuditedPlantCropData from './AuditedPlantCropData'
import RejectedPlantCropReadonlyData from './RejectedPlantCropReadonlyData'
import DataFillInStatistic from './DataFillInStatistic'
import RejectedPlantCropData from './RejectedPlantCropData'

export default {
  name: 'UnarchivedDataAuditTab',
  components: {
    'unaudited-plant-crop-data': UnauditedPlantCropData,
    'audited-plant-crop-data': AuditedPlantCropData,
    'rejected-plant-crop-data': RejectedPlantCropData,
    'data-fill-in-statistic': DataFillInStatistic,
    'rejected-plant-crop-readonly-data': RejectedPlantCropReadonlyData
  },
  props: {
    year: Number,
    regionId: String
  },
  data() {
    return {
      flags: {
        allowReject: false,
        isLoadUnauditedPlantCropDataComponent: false,
        isLoadAuditedPlantCropDataComponent: false,
        isLoadRejectedPlantCropDataComponent: false,
        isLoadDataFillInStatisticComponent: false,
        isLoadRejectedPlantCropReadonlyDataComponent: false
      },
      rejectedCount: 0,
      activeName: 'unauditedPane',
      cropRootCategoryArray: [],
      cropSubCategoryArray: [],
      cropArray: []
    }
  },
  async created() {
    await RejectPlantCropApi.getCount(this.year, this.regionId, null).then(res => {
      this.rejectedCount = res.data
    })

    await CropCategoryApi.getRootStubList().then(res => {
      res.data.forEach(el => {
        this.cropRootCategoryArray.push({ id: el.id, name: el.name })
      })
    })

    await CropCategoryApi.getSubStubList(null).then(res => {
      res.data.forEach(el => {
        this.cropSubCategoryArray.push({ id: el.id, name: el.name, parentId: el.parentId })
      })
    })

    await CropApi.getStubList(null, null).then(res => {
      res.data.forEach(el => {
        this.cropArray.push({
          id: el.id,
          name: el.name,
          rootCategoryId: el.rootCategoryId,
          subCategoryId: el.subCategoryId
        })
      })
    })

    let userLevelId = sessionStorage.getItem('levelId')
    this.flags.allowReject = userLevelId === 'Province' || userLevelId === 'City' || userLevelId === 'District'

    this.flags.isLoadUnauditedPlantCropDataComponent = true

    this.$nextTick(() => {
      this.loadComponent(this.activeName)
    })
  },
  watch: {
    year() {
      this.loadComponent(this.activeName)
    },
    regionId() {
      this.loadComponent(this.activeName)
    }
  },
  methods: {
    handleTabClick(tab) {
      this.flags.isLoadUnauditedPlantCropDataComponent = tab.name === 'unauditedPane'
      this.flags.isLoadAuditedPlantCropDataComponent = tab.name === 'auditedPane'
      this.flags.isLoadRejectedPlantCropDataComponent = tab.name === 'rejectedPane'
      this.flags.isLoadDataFillInStatisticComponent = tab.name === 'statisticPane'
      this.flags.isLoadRejectedPlantCropReadonlyDataComponent = tab.name === 'rejectedReadonlyPane'

      this.$nextTick(() => {
        this.loadComponent(tab.name)
      })
    },
    loadComponent(name) {
      switch (name) {
        case 'unauditedPane':
          this.$refs.unauditPlantCropData.load(this.year, this.regionId)
          break
        case 'auditedPane':
          this.$refs.auditedPlantCropData.load(this.year, this.regionId)
          break
        case 'rejectedPane':
          this.$refs.rejectedPlantCropData.load(this.year, this.regionId)
          break
        case 'statisticPane':
          this.$refs.dataFillInStatistic.load(this.year, this.regionId)
          break
        case 'rejectedReadonlyPane':
          this.$refs.rejectedPlantCropReadonlyData.load(this.year, this.regionId)
          break
        default:
          this.$refs.unauditPlantCropData.load(this.year, this.regionId)
          break
      }
    },
    reloadComponent() {
      this.loadComponent(this.activeName)
    },
    getRejectedCount(rejectedCount) {
      this.rejectedCount = rejectedCount
    }
  }
}
</script>

<style scoped></style>
