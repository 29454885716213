<template>
  <el-card>
    <div slot="header" class="chart-title"><span>农户性质数量及占比情况</span></div>
    <div>
      <el-row :gutter="20" type="flex">
        <el-col :span="12" style="padding-left: 40px;">
          <v-chart
            :option="farmerNumbersOptions"
            :init-options="initOptions"
            ref="farmerNumbers"
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            :updateOptions="{notMerge: true}"
            autoresize
          />
        </el-col>
        <el-divider direction="vertical"></el-divider>
        <el-col :span="12">
          <v-chart
            :option="farmerProportionsOptions"
            :init-options="initOptions"
            ref="farmerProportions"
            :loading="barLoading"
            :loadingOptions="barLoadingOptions"
            :updateOptions="{notMerge: true}"
            autoresize
          />
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import VChart from 'vue-echarts'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'

import {
  use
} from 'echarts/core'

import {
  BarChart,
  PieChart
} from 'echarts/charts'

import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent
} from 'echarts/components'
import DataVisualizationApi from '@/api/chart-analysis/data-visualization-api'

use([
  BarChart,
  PieChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  CanvasRenderer,
  SVGRenderer
])

export default {
  name: "Nature",
  components: {
    VChart
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      initOptions: {
        renderer: "canvas",
        notMerge: true
      },
      chartData: null,
      farmerNumbersOptions: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      farmerProportionsOptions: {
        graphic: {
          type: 'text',     // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true,     // 不响应事件
          style: {
            fill: '#9d9d9d',
            fontWeight: 'bold',
            text: '暂无数据',
            fontFamily: 'Microsoft YaHei',
            fontSize: '25px'
          }
        }
      },
      seconds: -1,
      barLoading: false,
      barLoadingOptions: {
        text: "加载中…",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0.4)"
      },
    }
  },
  methods: {
    refresh() {
      this.getChartData()
    },
    getChartData() {
      this.farmerNumbersOptions = {}
      this.farmerProportionsOptions = {}
      this.barLoading = true
      DataVisualizationApi.getFarmerNatureNumbers(this.params).then(res => {
        this.chartData = res.data
        if(this.chartData.length === 0) {
          this.farmerNumbersOptions = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }

          this.farmerProportionsOptions = {
            graphic: {
              type: 'text',     // 类型：文本
              left: 'center',
              top: 'middle',
              silent: true,     // 不响应事件
              style: {
                fill: '#9d9d9d',
                fontWeight: 'bold',
                text: '暂无数据',
                fontFamily: 'Microsoft YaHei',
                fontSize: '25px'
              }
            }
          }
          return
        }
        this.renderFarmerNumbersChart()
        this.renderFarmerProportionsChart()
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.barLoading = false
      })
    },
    renderFarmerNumbersChart() {
      this.farmerNumbersOptions = {
        textStyle: {
          fontSize: 14
        },
        title: {
          text: '数量(户)',
          top: 20,
          left: 'left',
          textStyle: {
            fontSize: 14,
            color: '#000000',
            fontWeight: 500
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          axisLabel: {
            show: true,
            fontSize: 14
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dashed'
            }
          },
          data: this.chartData.map(item => item.name)
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [
          {
            data: this.chartData.map(item => item.value),
            type: 'bar',
            barWidth: '25%',
              label: {
                show: true,
                position: 'top',
                fontSize: 14
              },
              color: '#27BC55',
              shadowColor: '#91cc75',
              borderType: 'dotted'

          }
        ]
      }
    },
    renderFarmerProportionsChart() {
      const that = this
      const circleColors = ['#5B8FF9', '#5AD8A6', '#FBDB63', '#F36C7A']
      let totalCount = that.chartData.reduce((vs, v) => vs + v.value, 0)
      let formatNumber = (num) => num.toString().replace(/(?=(\B)(\d{3})+$)/g, ',')
      this.farmerProportionsOptions = {
        textStyle: { fontSize: 14 },
        title: [
          {
            text: '占比(%)',
            top: 20,
            left: 'left',
            textStyle: {
              fontSize: 14,
              color: '#000000',
              fontWeight: 500
            }
          },
          {
            text: '总户数',
            subtext: `${formatNumber(totalCount)}户`,
            textAlign: 'center', //图例文字居中显示
            textStyle: {
              fontSize: 18,
              color: '#000000',
              fontWeight: 400
            },
            subtextStyle: {
              fontSize: 20,
              color: '#000000',
              fontWeight: 600
            },
            x: '42%',   //距离左边的距离
            y: '50%'    //距离上边的距离
          }
        ],
        tooltip: {
          trigger: 'item',
          formatter: (params) => `${params.data.name}<br/><div style="text-align: left">
${params.marker}<span style="color:${params.color}">占比: ${params.percent} %</span><br/>
${params.marker}<span style="color:${params.color}">数量: ${params.value} 户</span></div>`
        },
        legend: {
          itemWidth: 15, //图例的宽度
          itemHeight: 15, //图例的高度
          itemGap: 15, //图例各项间距
          orient: 'vertical',
          right: 'right',  //图例水平居右
          top: 'center',  //图例上下居中
          icon: 'circle',
          selectedMode: false, //取消图例上的点击事件
          padding: [0, 0, 0, 0],
          formatter: function (name) {
            let tarValue = 0
            for (let i = 0; i < that.chartData.length; i++) {
              if (that.chartData[i].name === name) {
                tarValue = that.chartData[i].value
              }
            }
            let p = parseFloat((tarValue / totalCount) * 100).toFixed(2)
            if (isNaN(p)) {
              p = 0
            }
            return `${name}：${p}%`
          }
        },
        series: {
          type: 'pie',
          radius: ['40%', '65%'],
          center: ['42%', '58%'],
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 0,
            color: function (params) {
              return circleColors[params.dataIndex]
            }
          },
          label: {
            alignTo: 'labelLine',
            formatter: '{category|{b}}：{value|{d}%}\n{hr|}\n{a|}',
            padding: [0, -70],
            rich: {
              category: {
                lineHeight: 10,//设置最后一行空数据高度，为了能让延长线与hr线对接起来
                align: 'center'
              },
              hr: {//设置hr是为了让中间线能够自适应长度
                borderColor: 'inherit',//hr的颜色为auto时候会主动显示颜色的
                width: '110%',
                borderWidth: 0.5,
                height: 0.5
              },
              value: {//用百分比数据来调整下数字位置，显的好看些。如果不设置，formatter最后一行的空数据就不需要
                padding: [-4, 0]
              }
            }
          },
          labelLine: {
            length: 15,
            length2: 70,
            maxSurfaceAngle: 80
          },
          data: that.chartData
        }
      }
    }
  }
}
</script>

<style scoped>
.el-divider--horizontal {
  margin: 2px 0;
}

.el-divider--vertical {
  display: inline-block;
  weight: 1px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 0;
  position: relative;
}
</style>
