<template>
  <div>
    <!-- map -->
    <distribution v-on:yearChanged="yearChanged"></distribution>
    <survey-crop-summary :startYear="startYear" :endYear="endYear" ref="surveyCropSummary"></survey-crop-summary>
    <!-- 调查作物一览表 -->
    <unit-sowing-area-fertilizer-dosage></unit-sowing-area-fertilizer-dosage>
    <!-- 单位播种面积施用量（公斤/亩） -->
  </div>
</template>
<script>
import SurveyCropSummary from './components/SurveyCropSummary'
import UnitSowingAreaFertilizerDosage from './components/UnitSowingAreaFertilizerDosage'
import Distribution from '@/views/components/Distribution.vue'

const url = require('url')

export default {
  name: 'Main',
  components: {
    Distribution,
    'survey-crop-summary': SurveyCropSummary,
    'unit-sowing-area-fertilizer-dosage': UnitSowingAreaFertilizerDosage
  },
  data() {
    return {
      startYear: 2022,
      endYear: 2024,
    }
  },
  created() {
    // console.log(url)
    // console.log(sessionStorage)
    var parseObj = url.parse(window.location.href, true)
    if (undefined !== parseObj.query.code) {
      let code = parseObj.query.code
      let message = parseObj.query.message
      this.$message({ type: 'error', message: '【' + code + '】' + message })
    }
  },
  methods: {
    yearChanged(startYear, endYear) {
      this.startYear = startYear
      this.endYear = endYear
      this.$refs.surveyCropSummary.bindTableData()
    }
  }
}
</script>
