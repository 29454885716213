<template>
  <div class="wg-box">
    <div class="header uc-header">
      <div class="title">验证手机号</div>
      <div class="wg-clear-fix"></div>
    </div>
    <div class="body">
      <el-form ref="form" :model="form" :rules="formRules" label-width="120px" :show-message="true" v-loading="loading" element-loading-text="等待验证......" element-loading-spinner="el-icon-loading">
        <el-row>
          <el-col :span="8">
            <el-form-item label="验证码：" prop="verifyCode">
              <el-col :span="12">
                <el-input v-model="form.verifyCode"></el-input>
              </el-col>
              <el-col :span="10" :offset="2">
                <el-button class="verify-button" @click="handleSendVerifyCode" :disabled="flag.sendVerifyCodeDisableFlag">{{ sendVerifyButtonText }}</el-button>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-button class="wg-button" @click="handleValidate">验证</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import AccountApi from '@/api/account-api'
import ShortMessageApi from '@/api/short-message-api'

export default {
  name: 'ValidatePhoneNumber',
  data() {
    return {
      flag: {
        sendVerifyCodeDisableFlag: false
      },
      loading: false,
      models: {
        user: null
      },
      sendVerifyButtonText: '发送验证码',
      form: {
        verifyCode: ''
      },
      formRules: {
        verifyCode: [{ required: true, trigger: 'blur', message: '请输入验证码！' }]
      }
    }
  },
  created() {
    AccountApi.getOwnAccount()
      .then(res => {
        this.models.user = res.data
      })
      .catch(err => {
        this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
      })
  },
  methods: {
    handleSendVerifyCode() {
      ShortMessageApi.sendPhoneNumberVerifyCode(this.models.user.phoneNumber)
        .then(res => {
          if (res.data.state === 'SUCCESS') {
            let waitTimes = 61
            this.flag.sendVerifyCodeDisableFlag = true
            this.sendVerifyButtonText = waitTimes + 's后重新获取'
            let timer = setInterval(() => {
              if (waitTimes > 1) {
                waitTimes--
                this.sendVerifyButtonText = waitTimes + 's后重新获取'
              } else {
                clearInterval(timer)
                this.sendVerifyButtonText = '获取验证码'
                this.flag.sendVerifyCodeDisableFlag = false
              }
            }, 1000)
          } else if (res.data.state === 'WARNING') {
            this.$message({ type: 'warning', message: res.data.message })
          } else if (res.data.state === 'ERROR') {
            this.$message({ type: 'error', message: res.data.message })
          } else {
            this.$message({ type: 'error', message: '系统发生异常！' })
          }
        })
        .catch(function (err) {
          this.$message({ type: 'error', message: '【' + err.data.code + '】' + err.data.message })
        })
    },
    handleValidate() {
      this.$refs.form.validate(valid => {
        if (!valid) return false

        this.loading = true
        let theVue = this
        AccountApi.validatePhoneNumber(this.models.user.phoneNumber, this.form.verifyCode)
          .then(() => {
            this.$message({
              type: 'success',
              message: '手机号验证成功！',
              duration: 1000,
              onClose: function () {
                theVue.$router.push({ path: '/own-integration' })
              }
            })
          })
          .catch(function (err) {
            theVue.$message({ type: 'error', message: '【' + err.data.code + '】' + err.data.message })
            theVue.loading = false
          })
      })
    }
  }
}
</script>

<style scoped>
</style>