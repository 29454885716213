import httpRequest from '@/utils/http-request'

export default {
  create(createModel) {
    return httpRequest.post('/after-fertilizers', createModel)
  },
  deleteById(id) {
    return httpRequest.delete('/after-fertilizers/' + id)
  },
  delete(plantCropId) {
    let config = {
      params: {
        plantCropId: plantCropId
      }
    }
    return httpRequest.delete('/after-fertilizers', config)
  },
  update(updateModel) {
    return httpRequest.put('/after-fertilizers', updateModel)
  },
  changeFillInMode(plantCropId, fillInMode) {
    return httpRequest.put('/fill-in-mode-change', { plantCropId: plantCropId, fillInMode: fillInMode })
  },
  disableFillAfterFertilizerTotal(id) {
    return httpRequest.put('/fill-after-fertilizer-total-disable', { id: id })
  },
  getById(id) {
    return httpRequest.get('/after-fertilizers/' + id)
  },
  getList(plantCropId) {
    let config = {
      params: { plantCropId: plantCropId }
    }
    return httpRequest.get('/after-fertilizers', config)
  }
}
