<template>
  <div class="wg-box">
    <div class="header">
      <div class="title">作物名称</div>
      <div class="button-list right">
        <el-button size="small" class="wg-button green" icon="el-icon-plus" @click="handleAdd">新增</el-button>
      </div>
      <div class="wg-clear-fix"></div>
    </div>
    <div class="body">
      <div class="wg-table-box">
        <el-table :data="tableData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)" class="wg-table normal">
          <el-table-column label="序号" prop="sortNumber"></el-table-column>
          <el-table-column prop="subCategoryName" label="分类"></el-table-column>
          <el-table-column prop="name" label="名称"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleEdit(scope.row.id)">编辑</el-button>
              <el-button type="text" size="small" @click="handleDelete(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="wg-pagination-box">
        <el-pagination background layout="prev, pager, next" :total="recordTotal" :page-size="pageSize" :current-page="pageIndex" @current-change="handlePagingChange"></el-pagination>
      </div>
    </div>
    <crop-create ref="createWindow" v-if="isLoadCreateWindow" @reload="handleReloadCreateWindow"></crop-create>
    <crop-modify ref="modifyWindow" v-if="isLoadModifyWindow" @reload="handleReloadModifyWindow"></crop-modify>
  </div>
</template>

<script>
import CropApi from '@/api/crop/crop-api'

import CropCreate from './CropCreate'
import CropModify from './CropModify'

export default {
  name: 'CropManage',
  components: {
    'crop-create': CropCreate,
    'crop-modify': CropModify
  },
  props: {
    rootCategoryId: null
  },
  data() {
    return {
      isLoadCreateWindow: true,
      isLoadModifyWindow: true,
      subCategoryList: [],
      tableData: [],
      recordTotal: 0,
      pageSize: 10,
      pageIndex: 1
    }
  },
  watch: {
    rootCategoryId: {
      handler: function(val) {
        if ('0' === val) {
          this.tableData = []
          this.recordTotal = 0
          this.pageIndex = 1
        } else {
          this.bindTableData()
        }
      }
    }
  },
  methods: {
    handleReloadCreateWindow() {
      this.isLoadCreateWindow = false
      this.$nextTick(() => {
        this.isLoadCreateWindow = true
      })
      this.bindTableData()
    },
    handleReloadModifyWindow() {
      this.isLoadModifyWindow = false
      this.$nextTick(() => {
        this.isLoadModifyWindow = true
      })
      this.bindTableData()
    },
    handlePagingChange(pageIndex) {
      this.pageIndex = pageIndex
    },
    handleAdd() {
      this.$refs.createWindow.open(this.rootCategoryId)
    },
    handleEdit(id) {
      this.$refs.modifyWindow.open(id, this.rootCategoryId)
    },
    handleDelete(id) {
      this.$confirm('此操作将永久删除该数据, 是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          CropApi.deleteById(id)
            .then(() => {
              this.$message({ type: 'success', message: '删除成功！' })
              this.bindTableData()
            })
            .catch(err => {
              this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
            })
        })
        .catch(() => {
          this.$message({ showClose: true, message: '已取消删除！', type: 'info' })
        })
    },
    bindTableData() {
      CropApi.getList(this.rootCategoryId, null, null).then(res => {
        this.tableData = []
        this.recordTotal = res.data.length
        this.pageIndex = 1
        res.data.forEach(el => {
          this.tableData.push({
            id: el.id,
            sortNumber: el.sortNumber,
            subCategoryName: el.subCategoryName,
            name: el.name,
            createdOn: el.createdOn,
            modifiedOn: el.modifiedOn
          })
        })
      }).catch(err => {
        this.$message({ showClose: true, message: '【' + err.data.code + '】' + err.data.message, type: 'error' })
      })
    }
  }
}
</script>

<style scoped>

</style>